import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'summary-card',
  templateUrl: 'summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
  // tolto il change on push per problemi con l'attivazione delle tab
})
export class SummaryCardComponent implements OnInit {
  @Input() user?: any;
  @Input() customAvatarImg: string = '';
  @Input() evaluationTitle: string = '';
  @Input() evaluationText: string | undefined = '';
  @Input() rating: any;
  @Input() ratingValue: string = '';
  @Input() isSelf: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() isEditDisabled: boolean = false;
  @Input() smallAvatar: boolean = false;
  @Input() observationData: { strongPoints: string, developmentAreas: string } | null = null;
  // mosta il summary del feedback del matrix manager, va passato anche observation data
  @Input() isMatrixManagerFeedback: boolean = false;
  // mostra il summary del commento del manager diretto all'insight feedback
  @Input() isInsightManagerComment: boolean = false;
  @Input() matrixManager: any;
  @Input() directManagerComment: string = '';

  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translate: TranslateService
  ) {
  }

  ngOnInit() { }


  emitOnEdit() {
    this.onEdit.emit();
  }
}