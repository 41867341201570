<div class="alloy-button" [ngClass]="type + ' ' + size + ' ' + (disabled ? 'disabled': '')"
  (click)="emitOnClick($event)" tabindex="{{tabIndex}}" (keyup.enter)="emitOnClick($event)">
  <div class="left-icon-wrapper" *ngIf="checkIconLeft()">
    <svg-icon [ngClass]="iconLeftClass" *ngIf="iconLeft" [src]="iconLeft" class="medium-size" [applyClass]="true">
    </svg-icon>
  </div>
  <p>{{label}}</p>
  <div class="right-icon-wrapper" *ngIf="checkIconRight()">
    <svg-icon *ngIf="iconRight" [src]="iconRight" class="medium-size" [applyClass]="true"></svg-icon>
  </div>
</div>