<page-container>

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goToUpdatePeerFeedback(true)" [isBackButton]="true"
      [title]="('peerFeedback.createUpdate.SIDENAV_TITLE' | translate)"
      [description]="userId && userId.length ? ('peerFeedback.createUpdate.SIDENAV_DESCRIPTION' | translate) : ('peerFeedback.createUpdate.SIDENAV_DESCRIPTION_GENERIC' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isLoadingFeedback">
        <div class="form-container">
          <!-- Obiettivo su cui è richiesto il feedback -->
          <input-container [isObbligatory]="false" [isLoading]="isLoadingFeedback"
            [tooltipModalMessage]="objectiveTitleModal" [isValued]="true" [readonly]="true"
            title="{{'peerFeedback.createUpdate.OBJECTIVE_TITLE' | translate}}">
            <!--alloy-text-area [bindValue]="feedbackObject.objectiveTitle">
                        </alloy-text-area-->
            <alloy-input [bindValue]="feedbackObject.goal && feedbackObject.goal.title" [readonly]="true">
            </alloy-input>
          </input-container>
          <!-- Messaggio di richiesta -->
          <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="requestMessageModal" [isValued]="true"
            [readonly]="true" [isObbligatory]="false"
            title="{{'peerFeedback.createUpdate.REQUEST_MESSAGE' | translate}}">
            <!--alloy-text-area [bindValue]="feedbackObject.message"></alloy-text-area-->
            <alloy-input [bindValue]="feedbackObject.request" [readonly]="true"></alloy-input>
          </input-container>
          <!-- Quali sono le tue osservazioni? -->
          <input-container [isLoading]="isLoadingFeedback" [tooltipModalMessage]="observationsModal" [readonly]="false"
            [isObbligatory]="true"
            [isValued]="feedbackObject.approverObservation && feedbackObject.approverObservation.trim().length"
            title="{{'peerFeedback.createUpdate.OBSERVATIONS' | translate}}">
            <alloy-text-area [bindValue]="feedbackObject.approverObservation"
              (onModelChanged)="observationsChanged($event)"
              placeholder="{{'peerFeedback.createUpdate.OBSERVATIONS_PLACEHOLDER' | translate}}">
            </alloy-text-area>
          </input-container>
          <div class="button-container">
            <alloy-button (onButtonClicked)="goToUpdatePeerFeedback()"
              [disabled]="isLoadingFeedback || !isObbligatoryDataInserted()" type="primary default" size="large"
              [label]="isUpdate ? ('peerFeedback.createUpdate.UPDATE_FEEDBACK' | translate) : ('peerFeedback.createUpdate.INSERT_FEEDBACK' | translate)">
            </alloy-button>
          </div>
        </div>
      </ng-container>
      <!-- Loader caricamento appunto -->
      <ng-container *ngIf="isLoadingFeedback">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per la creazione/aggiornamento di un appunto -->
<modal id="modalCreateUpdatePeerFeedback" (onCancel)="goBackBrowser()" (onClose)="closeModalCreateUpdatePeerFeedback()"
  (onConfirm)="answerPeerFeedback()" cancelLabel="{{modalCreateUpdatePeerFeedbackTextCloseTextButton}}"
  confirmLabel="{{modalCreateUpdatePeerFeedbackTitleConfirmTextButton}}">
  <modal-text-content [modalId]="modalCreateUpdatePeerFeedbackId" [title]="modalCreateUpdatePeerFeedbackTitle"
    [subtitle]="modalCreateUpdatePeerFeedbackSubtitle" [text]="modalCreateUpdatePeerFeedbackText">
  </modal-text-content>
</modal>