<div class="page-content-wrapper">
    <!-- Se ho finito di caricare i dati dell'utente -->
    <header-dossier [isAdminHeader]="true" [adminPageTitle]="('peopleAnag.HEADER_TITLE' | translate)" [hasTabs]="false"
        [adminPageSubtitle]="userId ? 'Pinco Sancio Panco' : ('peopleAnag.SUBTITLE' | translate)" [adminBack]="true">
    </header-dossier>
    <div class="main-content-wrapper admin">
        <!-- Home anagrafica -->
        <ng-container *ngIf="!userId">
            <ng-container *ngIf="!isLoadingUserData">
                <div class="admin-table-container column-spacing">
                    <p class="section-title">{{ ('peopleAnag.PEOPLE_LIST' | translate) }}</p>
                    <!-- Persone in anagrafica -->
                    <div class="gray-box-container">
                        <div class="gray-box">
                            <div class="text-info">
                                <p class="number">800</p>
                                <p class="description" translate="peopleAnag.PEOPLE_IN_ANAG"></p>
                            </div>
                            <div class="excels" (click)="downloadList()">
                                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                                </svg-icon>
                                <p translate="peopleAnag.DOWNLOAD_LIST"></p>
                            </div>
                        </div>
                        <!-- Non nella SYNC -->
                        <div class="gray-box">
                            <div class="text-info">
                                <p class="number">25</p>
                                <p class="description" [innerHTML]="('peopleAnag.PEOPLE_NOT_IN_SYNC' | translate)"></p>
                            </div>
                            <div class="excels" (click)="downloadList()">
                                <svg-icon class="file-icon" [applyClass]="true" src="/assets/img/icons/file/xls.svg">
                                </svg-icon>
                                <p translate="peopleAnag.DOWNLOAD_LIST"></p>
                            </div>
                        </div>
                    </div>


                    <!-- Barra di ricerca -->
                    <div class="admin-search-container">
                        <div class="search-wrapper">
                            <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                                (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                                [placeholder]="'generic.SEARCH_BY' | translate" [isSearchBar]="true">
                            </alloy-input>
                            <p (click)="openFiltersModal()" class="filter-recap" translate="process.details.FILTER_BY">
                            </p>
                        </div>
                    </div>

                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.CID' | translate)}}"></th>
                                    <th class="max-width100"
                                         translate="{{ ('adminTable.PROCESS_INVOLVED' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.DEFINITION' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let user of userData.list | 
                            slice:0:userData.numRecords | 
                            paginate: { id: 'userList',
                                    itemsPerPage: userData.numRecords,
                                    currentPage: userData.page,
                                    totalItems: userData.counter }">
                                    <td>{{ user.forename + ' ' + user.surname }}</td>
                                    <td>
                                        <div class="count">
                                            {{ user.cid }}
                                        </div>
                                    </td>
                                    <td class="max-width100">{{ user.processCount || 12 }}</td>
                                    <td>{{ user.denominazioneSO3 || 'Gestione Credito'}}</td>
                                    <td class="link" (click)="goToUserDetails(user.id)">
                                        {{ ('generic.DETAILS' | translate) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bottom-actions">
                        <table-pagination id="userList" (pageChanged)="userPageChanged($event)" [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </alloy-select>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- loader -->
            <ng-container *ngIf="isLoadingUserData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </ng-container>
        <!-- dettaglio utente -->
        <ng-container *ngIf="userId">
            <ng-container *ngIf="!isLoadingUserDetails">
                <div class="admin-table-container column-spacing">
                    <p class="section-title">
                        {{ ('peopleAnag.DETAILS_TITLE' | translate) + (userDetails ? userDetails.forename : 'Pinco
                        Panco')}}
                    </p>
                    <div class="divisor-line"></div>
                    <div class="gray-box-container">
                        <div class="info-container">
                            <p class="title" translate="peopleAnag.infoBox.GENERIC_DATA"></p>
                            <div class="info-section">
                                <!-- nome cognome-->
                                <div class="row">
                                    <p class="key" translate="peopleAnag.infoBox.NAME_SURNAME"></p>
                                    <p class="value"></p>
                                </div>
                                <!-- cid -->
                                <div class="row">
                                    <p class="key" translate="peopleAnag.infoBox.CID"></p>
                                    <p class="value"></p>
                                </div>
                                <!-- Definizione -->
                                <div class="row">
                                    <p class="key" translate="peopleAnag.infoBox.DEFINITION"></p>
                                    <p class="value"></p>
                                </div>
                                <!-- Tipo dipendente -->
                                <div class="row">
                                    <p class="key" translate="peopleAnag.infoBox.DEF_TIPO"></p>
                                    <p class="value"></p>
                                </div>
                            </div>
                        </div>
                        <div class="info-container">
                            <p class="title" translate="peopleAnag.infoBox.ADVANCED_DATA"></p>
                            <div class="info-section">
                                <!-- Approvatore -->
                                <div class="approver-edit-container">
                                    <div class="row">
                                        <p class="key" translate="peopleAnag.infoBox.APPROVER"></p>
                                        <p class="value">AAAAAA bbbbbbbb</p>
                                        <p *ngIf="!approverSelectOpen" (click)="showApproverSelect()" class="link"
                                            translate="peopleAnag.infoBox.EDIT"></p>
                                        <p *ngIf="approverSelectOpen" (click)="showApproverSelect()" class="link"
                                            translate="generic.CLOSE"></p>
                                    </div>
                                    <div class="approver-select-container" *ngIf="approverSelectOpen">
                                        <alloy-select
                                            [placeholder]="'peopleAnag.infoBox.APPROVER_PLACEHOLDER' | translate"
                                            (onSelectedItem)="selectApprover($event)" [selectedItem]="selectedApprover"
                                            [items]="approversList" bindLabel="title" [searchable]="true">
                                        </alloy-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <p class="key" translate="peopleAnag.infoBox.BRANCH"></p>
                                    <p class="value"></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="divisor-line"></div>

                    <div class="gray-box-container">
                        <div class="info-container">
                            <p class="title" translate="peopleAnag.infoBox.PROCESS_INVOLVED"></p>
                            <div class="info-section">
                                <div class="list-row">
                                    <p>2022 - Nome processo</p>
                                    <div class="column-right">
                                        <p class="link right" translate="peopleAnag.infoBox.EDIT_DATA"></p>
                                    </div>
                                </div>
                                <div class="list-row">
                                    <p>2021 - Nome processo</p>
                                    <div class="column-right">
                                        <p class="link right" translate="peopleAnag.infoBox.EDIT_DATA"></p>
                                    </div>

                                </div>
                                <div class="list-row">
                                    <p>2020 - Nome processo</p>
                                    <div class="column-right">
                                        <p class="link right" translate="peopleAnag.infoBox.EDIT_DATA"></p>
                                    </div>

                                </div>
                                <div class="list-row">
                                    <p>2019 - Nome processo</p>
                                    <div class="column-right">
                                        <p class="link right" translate="peopleAnag.infoBox.EDIT_DATA"></p>
                                    </div>

                                </div>
                                <div class="list-row">
                                    <p>2018 - Nome processo</p>
                                    <div class="column-right">
                                        <p class="link right" translate="peopleAnag.infoBox.EDIT_DATA"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="!userDetails || !userDetails.length">
                        <div class="no-data-wrapper">
                            <p translate="adminTable.NO_DATA_USER"></p>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <!-- loader dettaglio -->
            <ng-container *ngIf="isLoadingUserDetails">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>


<!-- Modale filtri -->
<modal id="filtersModal" (onClose)="closeFiltersModal()" (onConfirm)="applyFilters()"
    [confirmLabel]="('generic.APPLY_FILTERS' | translate)" (onCancel)="clearFilters()"
    [cancelLabel]="('generic.DELETE_FILTERS' | translate)">
    <div class="sti-modal-container">
        <p class="title" translate="process.manage.EXCLUDE_TITLE"></p>
        <div class="divisor-line"></div>
        <div class="filter-recap">
            <p class="subtitle" translate="process.manage.APPLY_FILTERS"></p>
            <p>{{ 1 + ('process.manage.FILTER_ACTIVE' | translate ) }}</p>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per sti -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STI"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p>STI ON</p>
                </div>
                <div class="filter-card">
                    <p>STI OFF</p>
                </div>
                <div class="filter-card">
                    <p>NO STI</p>
                </div>
            </div>
        </div>
        <div class="divisor-line"></div>
        <!-- Filtro per stato -->
        <div class="filter-container">
            <p class="name" translate="process.manage.STATUS"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p translate="process.details.STATUS_INCLUDED"></p>
                </div>
                <div class="filter-card">
                    <p translate="process.details.STATUS_EXCLUDED"></p>
                </div>
            </div>
        </div>
        <!-- Filtro per definizione (struttura) -->
        <div class="filter-container">
            <p class="name" translate="process.manage.DEFINITION"></p>
            <p class="actives">{{ 1 + ('process.manage.FILTER_ACTIVE' | translate)}}</p>
            <div class="filter-row">
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
                <div class="filter-card selected">
                    <p>SERVIZIO CLIENTI</p>
                </div>
                <div class="filter-card">
                    <p>Gestione credito</p>
                </div>
                <div class="filter-card">
                    <p>Ammin. prodotti</p>
                </div>
                <div class="filter-card">
                    <p>Risorse umane</p>
                </div>
                <div class="filter-card">
                    <p>SERVIZIO CONT. CLIENTI</p>
                </div>
            </div>
            <p class="more-link" translate="process.manage.SHOW_ALL"></p>
            <p class="more-link" translate="process.manage.SHOW_LESS"></p>
        </div>
    </div>
</modal>