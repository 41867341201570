<div class="evaluation-rating-container">

  <div class="avatar-evaluation-container" [ngClass]="{'more-columns': rating && ratingValue}">
    <avatar-img [user]="user" [isHr]="isHr" [size]=" smallAvatar ? 'small' : 'medium'"></avatar-img>
    <div class="evaluation-container">
      <p class="evaluation-title">{{ evaluationTitle }}</p>
      <star-rating *ngIf="ratingIT" [forceRating]="ratingIT" [grayColor]="isSelf"
        customContainerClass="small-star-size small-star-size-rd no-padding" [direction]="'row'"></star-rating>
      <p class="evaluation-descr">{{ evaluationText || ('generic.NO_COMMENT_INSERTED' | translate) }}</p>
      <ng-container *ngIf="canEdit">
        <a class="custom-link" translate="finalEvaluation.EDIT_COMMENT"
          [ngClass]="{'disabled-edit-link': isEditDisabled}" (click)="emitOnEdit()"></a>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="rating && !isLeadershipTeam">
    <div class="rating-container">
      <star-rating [forceRating]="rating" [isRecapAverage]="true" [grayColor]="isSelf"
        customContainerClass="no-padding"></star-rating>
      <ng-container *ngIf="ratingValue">
        <div class="rating-value" [ngClass]="{'self': isSelf}">{{ ratingValue }}</div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isLeadershipTeam">
    <div class="rating-container leadership">
      <div class="rating-value" [ngClass]="{'self': isSelf}">{{ 'generic.COMPLETED' | translate }}</div>
    </div>
  </ng-container>
</div>