import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, PerfCareApplauseFeedback, GetPerformanceCareTagsResponse, NoteTypes, PerfCareTag } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { UserCard } from 'src/app/utils/classes.utils';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-peopleAppraisal-operations-sendClap',
  templateUrl: './sendClap.component.html',
  styleUrls: ['./sendClap.component.scss']
})
export class SendClapComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any = {};
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  isFetchingCreateClap: boolean = false;
  clap: PerfCareApplauseFeedback = {
    feedbackId: '',
    comment: '',
    evidenceCareTagId: '',
    senderUserId: '',
    recipientUserId: '',
    feedbackType: NoteTypes.PERFCARE_APPLAUSE_FEEDBACK,
  };

  updateClap$: Subscription | null = null;
  isFetchingGetClap: boolean = false;
  getClap$: Subscription | null = null;
  clapCopy: PerfCareApplauseFeedback = {
    feedbackId: '',
    comment: '',
    evidenceCareTagId: '',
    senderUserId: '',
    recipientUserId: '',
    feedbackType: NoteTypes.PERFCARE_APPLAUSE_FEEDBACK,
  };
  modalUpdateClapTitle: string = '';
  modalUpdateClapText: string = '';
  modalUpdateClapTitleConfirmTextButton: string = '';
  modalUpdateClapTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;

  descriptionTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  careTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  isFetchingUpdateClap: boolean = false;

  careCards = [{
    careId: '',
    id: 'c',
    title: 'careCard.C',
    isChecked: false
  },
  {
    careId: '',
    id: 'a',
    title: 'careCard.A',
    isChecked: false
  },
  {
    careId: '',
    id: 'r',
    title: 'careCard.R',
    isChecked: false
  },
  {
    careId: '',
    id: 'e',
    title: 'careCard.E',
    isChecked: false
  }]
  isLoadingTeam: boolean = false;

  userCardData: UserCard[] = [];
  clapId: string = '';
  cardSelected: any;
  cardSelectedCopy: any;
  sendClap$: Subscription = new Subscription;
  isFetchingCareOptions: boolean = false;
  getCareOptions$: Subscription = new Subscription;
  careOptionsCounter: number = 0;
  careOptions: PerfCareTag[] = [];
  selectedUserList: any = [];
  // Ricerca utente
  searchUserText: string = '';
  originalUserCards: any[] = [];
  // Guard
  alreadySaved: boolean = false;
  forceBack: boolean = false;
  modalUpdateId: string = '';
  modalUpdateTitle: any;
  modalUpdateText: any;
  modalUpdateTitleConfirmTextButton: any;
  modalUpdateTextCloseTextButton: any;
  sendClapUsersFromRecord: number = 0;
  sendClapUsersNumRecords: number = 0;
  sendClapUsersCurrentPage: number = 0;
  sendClapUsersCounter: number = 0;
  isFetchingSendClapUsers: boolean = false;
  chargeUsersVariable: boolean = true;
  chargeCareOptionsVariable: boolean = true;
  isImpersonate: boolean = false;

  constructor(
    private sharedService: SharedService,
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.clapId = params.clapId;

              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                
                let subordinates = subordinatesList?.filter((x: any) => {
                  return !x.structureUser
                })

                this.personDetails = subordinates && subordinates.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];

                if (this.userId && !this.personDetails) {
                  // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                  this.chargeSelectedUser = false;
                  this.getPersonDetails();
                }

                if (!this.userId && this.chargeUsersVariable) {
                  this.chargeUsersVariable = false;
                  this.resetSendClapUsersData();
                  this.onGetSendClapUsers();
                }

                if (this.clapId && this.chargeCareOptionsVariable) {
                  this.chargeCareOptionsVariable = false;
                  this.isFetchingGetClap = true;
                  this.getCareOptions(true);
                } else if (this.chargeCareOptionsVariable) {
                  this.chargeCareOptionsVariable = false;
                  this.getCareOptions(false);
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP',
        'feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB',
        'feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR',
        'feedbackSection.sendClapPage.modals.WHICH_CARE',
        'feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR',
      ])
      .subscribe(translations => {
        this.descriptionTooltipModal = {
          modalId: "401",
          title: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO'],
          subtitle: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_SUB'],
          text: translations['feedbackSection.sendClapPage.modals.APPLAUSE_INFO_DESCR'],
        };
        this.whoTooltipModal = {
          modalId: "c014",
          title: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP'],
          text: translations['feedbackSection.sendClapPage.modals.WHO_TO_CLAP_DESCR'],
        }
        this.careTooltipModal = {
          modalId: "c016",
          title: translations['feedbackSection.sendClapPage.modals.WHICH_CARE'],
          text: translations['feedbackSection.sendClapPage.modals.WHICH_CARE_DESCR'],
        };

        this.modalUpdateId = this.getModalSaveId();
        this.modalUpdateTitle = this.getModalSaveTitle();
        this.modalUpdateText = this.getModalSaveDescription();
        this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelUpdateModal();
        this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
      })
  }

  // Ripristina i dai della lista utenti per l'applauso
  resetSendClapUsersData(notDeleteSelected?: boolean) {
    this.sendClapUsersFromRecord = 0;
    this.sendClapUsersNumRecords = 16;
    this.sendClapUsersCurrentPage = 1;
    this.sendClapUsersCounter = 0;
    this.userCardData = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  searchUsers() {
    this.onGetSendClapUsers(true);
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Recupera una lista di utenti per gli applausi
  onGetSendClapUsers(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetSendClapUsersData(true);
    }
    // Avvio il loader
    this.isFetchingSendClapUsers = true;
    this.peopleAppraisalService.countSendClapUsers(this.runningYear.year.toString(), this.searchUserText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.sendClapUsersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.sendClapUsersCurrentPage && this.sendClapUsersNumRecords) {
                fromRecord = (this.sendClapUsersCurrentPage - 1) * this.sendClapUsersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.sendClapUsersCounter) {
                return this.peopleAppraisalService.listSendClapUsers(fromRecord, this.sendClapUsersNumRecords, this.runningYear.year.toString(), this.searchUserText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isFetchingSendClapUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.userCardData = UserDataUtils.getUserCardData(data.response) || [];
          }
          this.isFetchingSendClapUsers = false;
        }
        , (err: any) => {
          this.isFetchingSendClapUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  clapUsersPageChanged(newPage: any) {
    this.sendClapUsersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.onGetSendClapUsers();
  }

  // Recupera i tag Care
  getCareOptions(isEdit: boolean) {
    this.isFetchingCareOptions = true;
    this.getCareOptions$ = this.peopleAppraisalService.getCareOptions('', '', true)
      .subscribe((data: SenecaResponse<GetPerformanceCareTagsResponse>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "028",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response?.rows?.length) {
            this.careCards[0].careId = data.response.rows[0].tagId;
            this.careCards[1].careId = data.response.rows[1].tagId;
            this.careCards[2].careId = data.response.rows[2].tagId;
            this.careCards[3].careId = data.response.rows[3].tagId;
            this.careOptions = data.response.rows;
          }
        }
        if (isEdit) {
          this.getClap();
        }
        this.isFetchingCareOptions = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "029",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCareOptions = false;
        })
  }

  onSelectCareCard(cards: any) {
    this.cardSelected = cards.filter((x: any) => {
      return x.isChecked == true;
    })[0];
  }

  userCardId(index: number, el: UserCard) {
    return el.id;
  }

  getClap() {
    this.isFetchingGetClap = true;
    if (this.getClap$) {
      this.getClap$.unsubscribe();
    }
    this.getClap$ = this.peopleAppraisalService.getClapById(this.runningYear.year, this.runningYear.yearCode, this.clapId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else {
          this.clap = data.response;
          for (let i = 0; i < this.careCards.length; i++) {
            if (this.careCards[i].careId === this.clap.evidenceCareTagId) {
              this.careCards[i].isChecked = true;
              this.cardSelected = this.careCards[i];
            }
          }
          this.clapCopy = JSON.parse(JSON.stringify(this.clap));
          this.cardSelectedCopy = JSON.parse(JSON.stringify(this.cardSelected));
          this.isFetchingGetClap = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c012",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.goBackBrowser();
      });
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    // this.getPersonDetails$ = this.sharedService.getMySubordinateUserData(this.userId, this.runningYear)
    //   .subscribe((personDetailsData: any ) => {
    //     if (personDetailsData.error || !personDetailsData.response) {
    //       const messageObj: ApplicationModalMessage = {
    //         modalId: "075",
    //         text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
    //         title: this.translate.instant("generic.WARNING")
    //       }
    //       this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //     } else {
    //       this.personDetails = personDetailsData.response;
    //     }
    //   }, (err?: any) => {
    //     const messageObj: ApplicationModalMessage = {
    //       modalId: "076",
    //       text: this.translate.instant("errors." + ((err && err.message) || err)),
    //       title: this.translate.instant("generic.WARNING")
    //     }
    //     this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //   });
  }

  // Descrizione applausa cambiata
  clapDescriptionChanged(text: string) {
    this.clap.comment = text;
  }

  goBack() {
    this.redirectService.goBackBrowser();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  sendClap(closeModal?: boolean) {
    if (closeModal) {
      this.alreadySaved = true;
      this.closeModalUpdate();
    }
    if (this.sendClap$) {
      this.sendClap$.unsubscribe();
    }
    this.clap.evidenceCareTagId = this.cardSelected.careId;
    let userIds: string[] = [];
    if (this.userId) {
      userIds = [this.userId]
      this.clap.recipientUser = this.personDetails;
      this.clap.recipientUserId = this.userId;
    } else {
      userIds = this.selectedUserList.map((x: any) => x.id);
    }
    this.sendClap$ = this.peopleAppraisalService.createClap([this.clap], userIds).
      subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.goBackBrowser();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "c006",
            title: this.translate.instant("feedbackSection.sendClapPage.CLAP_SENT"),
            text: this.translate.instant("feedbackSection.sendClapPage.CLAP_SENT_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          for (let i = 0; i < this.careCards.length; i++) {
            if (this.careCards[i].careId === this.clap.evidenceCareTagId) {
              this.careCards[i].isChecked = true;
              this.cardSelected = this.careCards[i];
            }
          }
          this.clapCopy = JSON.parse(JSON.stringify(this.clap));
          this.cardSelectedCopy = JSON.parse(JSON.stringify(this.cardSelected));
          if (!this.userId) {
            this.selectedUserList = [];
            this.redirectService.goToHome();
          } else {
            this.redirectService.goToPersonDetailsFeedback(this.userId, this.runningYear)
          }
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c007",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.goBackBrowser();
      });
  }

  updateClap(closeModal?: boolean) {
    if (closeModal) {
      this.alreadySaved = true;
      this.closeModalUpdate();
    }
    this.isFetchingGetClap = true;
    if (this.updateClap$) {
      this.updateClap$.unsubscribe();
    }

    for (let i = 0; i < this.careOptions.length; i++) {
      if (this.careOptions[i].tagId === this.cardSelected.careId) {
        this.clap.evidenceCareTag = this.careOptions[i];
        this.clap.evidenceCareTagId = this.cardSelected.careId;
      }
    }

    this.updateClap$ = this.peopleAppraisalService.updateClap(this.clap).
      subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "c008",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "c009",
            title: this.translate.instant("feedbackSection.sendClapPage.CLAP_UPDATED"),
            subtitle: this.translate.instant('feedbackSection.sendClapPage.CLAP_SENT_UPDATE_SUB'),
            text: this.translate.instant("feedbackSection.sendClapPage.CLAP_UPDATED_DESCR")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          for (let i = 0; i < this.careCards.length; i++) {
            if (this.careCards[i].careId === this.clap.evidenceCareTagId) {
              this.careCards[i].isChecked = true;
              this.cardSelected = this.careCards[i];
            }
          }
          this.clapCopy = JSON.parse(JSON.stringify(this.clap));
          this.cardSelectedCopy = JSON.parse(JSON.stringify(this.cardSelected));
          this.redirectService.goToPersonDetailsFeedback(this.userId, this.runningYear)
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  openCareCardTooltip() {
    this.modalService.open('careTooltip');
  }

  closeCareCardTooltip() {
    this.modalService.close('careTooltip');
  }

  // Se non hai inserito nessun dato
  noDataInserted() {
    if (!this.clap.comment && (!this.cardSelected || !this.cardSelected.careId) && !this.userId && this.selectedUserList && !this.selectedUserList.length) {
      return true
    }
    return false;
  }

  // Chiude la modale per il salvataggio dell'applauso
  closeModalUpdate(backAnyway?: boolean) {
    if (backAnyway) {
      this.forceBack = true;
      // se non ho user id è applauso multiplo, torno in home
      if (!this.userId) {
        this.redirectService.goToHome()
      } else { // altrimenti torno al feedback
        this.redirectService.goToPersonDetailsFeedback(this.userId, this.runningYear);
      }
    }
    this.modalService.close('modalUpdateForUser');
  }

  checkObbligatoryData() {
    if (this.clap) {
      if (!this.clap.comment || !this.clap.comment.trim().length) {
        return false;
      } else if (!this.cardSelected || !this.cardSelected.careId) {
        return false;
      } else if (!this.userId && this.selectedUserList && !this.selectedUserList.length) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  // Quando si apre la modale history browser o qualcos'altro
  formatDataOpenUpdateModal() {
    this.modalUpdateId = this.getModalSaveId();
    this.modalUpdateTitle = this.getModalSaveTitle();
    this.modalUpdateText = this.getModalSaveDescription();
    this.modalUpdateTitleConfirmTextButton = this.getConfirmLabelUpdateModal();
    this.modalUpdateTextCloseTextButton = this.getCancelLabelUpdateModal();
    this.openModalForUpdate();
  }

  // Apre la modale per l'aggiornamento dell'obiettivo
  openModalForUpdate() {
    this.modalService.open('modalUpdateForUser');
  }

  // Testo per il modale Salva obiettivo
  getModalSaveTitle() {
    if (this.clapId && this.clapId.length) {
      return this.translate.instant('feedbackSection.sendClapPage.UPDATE_CLAP');
    } else {
      return this.translate.instant('feedbackSection.sendClapPage.SEND_CLAP');
    }
  }

  // Testo per il modale Salva obiettivo
  getModalSaveDescription() {
    if (this.clapId && this.clapId.length) {
      return this.translate.instant('feedbackSection.sendClapPage.WANT_TO_UPDATE');
    } else {
      return this.translate.instant('feedbackSection.sendClapPage.WANT_TO_CREATE');
    }
  }

  // Testo modale pulsante conferma
  getConfirmLabelUpdateModal(isBack?: boolean) {
    if (isBack) {
      return this.translate.instant('generic.SAVE_AND_GO_BACK');
    } else {
      if (this.clapId && this.clapId.length) {
        return this.translate.instant('feedbackSection.sendClapPage.YES_UPDATE');
      } else {
        return this.translate.instant('feedbackSection.sendClapPage.YES_CREATE');
      }
    }
  }

  // Testo modale pulsante conferma
  getCancelLabelUpdateModal(isBack?: boolean) {
    return this.translate.instant('goalSetting.createGoal.GO_BACK');
  }

  getModalSaveId() {
    return 'c013';
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.updateClap$) {
      this.updateClap$.unsubscribe();
    }
    if (this.getClap$) {
      this.getClap$.unsubscribe();
    }
    if (this.sendClap$) {
      this.sendClap$.unsubscribe();
    }
  }
}