<ng-select class="europ"
  [ngClass]="{'readonly': readonly, 'state-error': forceError, 'table-pagination': tablePagination, 'ie-min-width': inMinWidthForIe}"
  [clearable]="clearable" [multiple]="multiple" [bindLabel]="bindLabel" [searchable]="searchable" [readonly]="readonly"
  [typeahead]="typeahead" [placeholder]="placeholder" [(ngModel)]="selectedItem" [items]="items"
  placeholder="{{ placeholder }}" (change)="emitOnSelectedItem($event)" [loading]="isLoading">

  <!--ng-option *ngFor="let item of items" [value]="item.id">{{item.name}}</ng-option-->
  <!-- Elementi selezionati -->
  <ng-template class="europ" ng-label-tmp let-item="item" let-clear="clear">
    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon class="icon-select blue-primary" [applyClass]="true" src="{{item.icon}}" *ngIf="item.icon">
      </svg-icon>
      <p class="white-space-normal">{{item.title}}</p>
    </div>
  </ng-template>

  <!-- Template per l'inizio della ricerca -->
  <ng-template class="europ" ng-typetosearch-tmp>
    <!--div class="ng-option disabled">{{ startSearchLabel || ('generic.START_SEARCH' | translate) }}</div-->
    <alloy-dropdown-item class="full-width" [item]='searchDataObject'>
    </alloy-dropdown-item>
  </ng-template>
  <!-- Template per i dati non trovati -->
  <ng-template class="europ" ng-notfound-tmp let-searchTerm="filter">
    <div class="ng-option disabled">
      <alloy-dropdown-item class="full-width" [item]='noDataObject'>
      </alloy-dropdown-item>
    </div>
  </ng-template>
  <!-- Template per il caricamento dei risultati -->
  <ng-template class="europ" ng-loadingtext-tmp>
    <!--div class="ng-option disabled">{{ loadingData || ('generic.LOADING' | translate) }}</div-->
    <alloy-dropdown-item class="full-width" [item]='loadingDataObject'>
    </alloy-dropdown-item>
  </ng-template>
  <!-- Template per i singoli item nelle options -->
  <ng-template class="europ" ng-option-tmp let-item="item" let-search="filter">
    <alloy-dropdown-item [innerHtmlDescription]="innerHtmlDescription" [notSeeDescription]="notSeeDescription"
      (click)="onOptionClicked($event, item.disabled)" class="full-width" [isCheckbox]="isCheckbox" [item]="item">
    </alloy-dropdown-item>
  </ng-template>
</ng-select>