import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import * as fromApp from "../ngrx/app.reducers";
import * as fromPeopleAppraisal from "../peopleAppraisal/ngrx/peopleAppraisal.reducers";
import * as PeopleAppraisalActions from "../peopleAppraisal/ngrx/peopleAppraisal.actions";
import { GetSubordinatesWithPhaseStatusesForManagerResponseFE, UserDataUtils } from 'src/app/utils/user-data.utils';
import { TabDossier, UserCard } from 'src/app/utils/classes.utils';

@Component({
  selector: 'components-example',
  templateUrl: './components-example.component.html',
  styleUrls: ['./components-example.component.scss']
})
export class ComponentExampleComponent implements OnInit {

  textAreaNumberChars: number = 200;
  maxCharsTextArea: number = 200;

  radioList = [
    { id: 1, value: 'Volvo' },
    { id: 2, value: 'Saab' },
    { id: 3, value: 'Opel' },
    { id: 4, value: 'Audi' },
  ];
  radioSelected = {};
  selectedCar = null;
  cars: DropdownItem[] = [
    { id: 1, name: 'Volvo', isChecked: false },
    { id: 2, name: 'Saab', isChecked: false },
    { id: 3, name: 'Opel', isChecked: false },
    { id: 4, name: 'Audi', isChecked: false, disabled: true },
  ];
  noDataPlaceholder: string = '';

  tags = [
    'essere rassicurante',
    'innovation',
    'care',
    'prova tag con titolo lungo',
    'public speaking',
  ]
  tags2 = [
    'prova tag con titolo lungo',
    'care',
    'innovation',
    'essere rassicurante'
  ]
  exmpleUserForHeaderDossier = {
    forename: 'Davide',
    surname: 'Prova'
  }

  isAdminHeader = {
    forename: 'Davide',
    surname: 'Prova'
  }

  careCards = [{
    id: 'c',
    title: 'careCard.C',
    isChecked: true
  },
  {
    id: 'a',
    title: 'careCard.A',
    isChecked: false
  },
  {
    id: 'r',
    title: 'careCard.R',
    isChecked: false
  },
  {
    id: 'e',
    title: 'careCard.E',
    isChecked: false
  }]
  runningYear$: Subscription;
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription | null = null;
  subordinatesWithPhaseStatuses: GetSubordinatesWithPhaseStatusesForManagerResponseFE[] = [];
  isLoadingTeam: boolean;

  userCardData: UserCard[] = [];

  noData = false;

  tabLightTest = [
    {
      id: 'prova1',
      title: 'Prova1',
      isDisabled: false
    },
    {
      id: 'prova2',
      title: 'Prova2',
      isDisabled: true
    },
    {
      id: 'prova3',
      title: 'Prova3',
      isDisabled: true
    },
    {
      id: 'prova4',
      title: 'Prova4',
      isDisabled: true
    }
  ]

  tabDossier: TabDossier[] = [
    {
      id: '1',
      title: "Tab 1",
      attrAriaLabel: "Tab 1",
      isDisabled: false
    },
    {
      id: '2',
      title: "tab con titolo più lungo",
      attrAriaLabel: "Tab 2",
      isDisabled: true
    },
    {
      id: '3',
      title: "Tab 3",
      attrAriaLabel: "Tab 3",
      isDisabled: true,
      badgeNumberVisible: true,
      badgeNumber: 0
    },
    {
      id: '4',
      title: "Tab 4",
      attrAriaLabel: "Tab 4",
      isDisabled: true,
      badgeNumberVisible: true,
      badgeNumber: 10
    }
  ]

  currentTab = '1';

  constructor(
    private appStore: Store<fromApp.AppState>,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
    public translate: TranslateService,
    public modalService: ModalService
  ) {
    this.isLoadingTeam = true;

    // Salvo l'anno corrente
    this.runningYear$ = this.appStore.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;

      if (this.runningYear && this.runningYear.yearCode) {
        // Recupero dai servizi la lista di sottoposti
        this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses({}));
      }
    });
    // Recupero la lista di subordinati con lo stato delle fasi
    this.subordinatesWithPhaseStatuses$ = this.appStore.select(fromApp.getSubordinatesWithPhaseStatuses).
      subscribe((subordinatesWithPhaseStatuses) => {
        this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
        this.userCardData = UserDataUtils.getUserCardData(this.subordinatesWithPhaseStatuses);
        this.isLoadingTeam = false;
      });
  }

  ngOnInit() {

  }

  // Differenza tra limite massimo caratteri textArea e caratteri digitati nella textArea
  changeCountChars(data: string) {
    this.textAreaNumberChars = this.maxCharsTextArea - data.length;
  }

  onItemChecked(item: any) {
    this.selectedCar = item;
  }

  openTestModal() {
    this.modalService.open('test');
  }

  closeTestModal() {
    this.modalService.close('test');
  }

  openTestFullModal() {
    this.modalService.open('testFullPage');
  }

  closeTestFullModal() {
    this.modalService.close('testFullPage');
  }

  openTestBadgeModal() {
    this.modalService.open('badgeModal');
  }

  closeTestBadgeModal() {
    this.modalService.close('badgeModal');
  }

  isSelectValued(item: any) {
    if (item && item.id) {
      return true;
    }
    return false;
  }

  userCardId(index: number, el: UserCard) {
    return el.id;
  }

  changeLightTab(tab: any) {
    for (let i = 0; i < this.tabLightTest.length; i++) {
      this.tabLightTest[i].isDisabled = true;
    }
    tab.isDisabled = false;
  }

  changeCurrentTab(tab: TabDossier) {
    this.currentTab = tab.id;
  }
}