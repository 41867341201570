<div class="page-content-wrapper">

  <header-dossier [user]="loggedUser" [hasTabs]="false" [yearItems]="cppRounds" [selectedYear]="selectedRound"
    (onSelectedYear)="changeSelectedCppRound($event)" [isCppCollaborators]="true" [hideAction]="true">
  </header-dossier>

  <div class="main-content-wrapper">

    <!-- contenitore tabella -->
    <ng-container *ngIf="!isLoadingCppSubordinates">
      <div class="admin-table-container cpp-collaborators">
        <p class="text-title margin-bottom24 full-width" translate="cppCollaborators.TITLE"></p>
        <div class="table-content">
          <table>
            <thead>
              <tr>
                <th translate="{{ ('cppCollaborators.PERSON' | translate)}}"></th>
                <th translate="{{ ('cppCollaborators.ROLE' | translate)}}"></th>
                <th class="th-center-svg">
                  {{'cppCollaborators.ROLE_ALIGNMENT' | translate}}
                  <svg-icon (click)="openRoleAlignmentModal()" src="/assets/img/icons/info.svg"></svg-icon>
                </th>
                <th translate="{{ ('cppCollaborators.DEV_PLAN' | translate)}}"></th>
                <th class="small-col" translate="{{ ('cppCollaborators.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let subordinate of cppSubordinates">
                <td style="width: 230px;" fxLayout="row" fxLayoutAlign="start center">
                  <avatar-img class="margin-right10" [user]="subordinate" size="small"></avatar-img>
                  <p class="full-name">{{ subordinate?.surname + ' ' + subordinate?.forename }}</p>
                  <div class="news-badge" *ngIf="subordinate.isNewCppNotify">{{'generic.NEWS' | translate}}</div>
                </td>
                <td>{{ subordinate?.ruolo }}</td>
                <td>
                  <adequacy-indicator
                    [adequacyPercentage]="subordinate?.adequacyPercentage"></adequacy-indicator>
                </td>
                <td style="width: fit-content">
                  <div fxLayoutAlign="center center" class="cpp-user-status"
                    [ngClass]="subordinate.developmentPlanStatus">
                    <p>{{('cppCollaborators.' + subordinate.developmentPlanStatus) | translate}}</p>
                  </div>
                </td>
                <td class="link" [ngClass]="{'disabled': !subordinate?.surveyCompleted}" (click)="subordinate?.surveyCompleted ?  goToCppCollaboratorDetails(subordinate): ''">
                  {{ 'cppCollaborators.SHOW' | translate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <!-- loader -->
    <ng-container *ngIf="isLoadingCppSubordinates">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>
  </div>
</div>

<!-- Modale Allineamento al ruolo  -->
<modal id="role-alignment-modal" (onClose)="closeRoleAlignmentModal()" (onCancel)="closeRoleAlignmentModal()"
  (onConfirm)="closeRoleAlignmentModal()" [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content [title]="'cppCollaborators.ROLE_ALIGNMENT' | translate"
    [text]="'cppCollaborators.APPROPRIATE_TO_LEVEL' | translate "
    [subtitle]="'cppCollaborators.ROLE_ALIGNMENT' | translate">
  </modal-text-content>
  <div class="adeguact-modal-container">
    <div *ngFor="let adequacy of adequacyModalList">
      <adequacy-indicator *ngIf="adequacy" [status]="adequacy !== 'anomalies'? adequacy:''"></adequacy-indicator>
      <p class="modal-adequacy-text">{{('cppCollaborators.modal.'+ adequacy ) | translate}}</p>
    </div>
  </div>
</modal>