import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../../ngrx/app.reducers";
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { AlloyPerformanceUpwardFeedbackServiceResponses, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-outcomes',
  templateUrl: './upwardFeedback.component.html',
  styleUrls: ['./upwardFeedback.component.scss']
})
export class OutcomesComponent implements OnInit {
  isImpersonate: boolean = false;
  roundId: string = '';
  getManagerResults$: Subscription = new Subscription();
  isLoadingResults: boolean = true;
  listData: any;
  ratingData: any;
  globalAverage: number = 0;
  modulesData: any[] = [];
  chartInstances: any = {};
  showAllAccordion: boolean = false;
  isSendingReminders: boolean = false;
  isSharingManagerResult: boolean = false;
  isSettingManagerStatus: boolean = false;
  titleData?: { managerName: string; completed: any; total: any; };
  chiefMessage: string | undefined;
  userId: any;
  chiefComment: string = '';
  chiefCommentSent: boolean = false;
  peopleInvolvedCount: number = 0;
  completedSurveyCount: number = 0;
  responseRate: number = 0;
  averageScore: number = 0;
  chiefDate: string = '';
  chiefName: string = '';
  sidebarPeriod: string = '';
  managerName: string = '';

  constructor(
    public redirectService: RedirectService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService
  ) {
    this.route.params
      .subscribe((params) => {
        this.roundId = params.roundId;
        if (params.userId) {
          this.userId = params.userId;
        }
        if (this.userId) {
          this.getManagerResultsForChief();
        } else {
          this.getManagerResults();
        }
      })
  }


  ngOnInit(): void {
  }

  getManagerResults() {
    this.isLoadingResults = true;

    if (this.getManagerResults$) {
      this.getManagerResults$.unsubscribe();
    }

    this.getManagerResults$ = this.peopleAppraisalService.getUpwardFeedbackResults(this.roundId)
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // Dati chief
          if (data.response.chiefComment) {
            this.chiefMessage = data.response.chiefComment.replace(/\n/g, '<br>');
            console.log("this.chiefMessage", this.chiefMessage);
            this.chiefDate = data.response.chiefCommentDate || moment().format('DD/MM/YYYY');
            this.chiefName = data.response.chiefCommentName || '';
          }
          // dati sidebar
          this.peopleInvolvedCount = data.response.numberOfEvaluators || 0;
          this.completedSurveyCount = data.response.numberOfFeedbackReceived || 0;

          let currentYear = moment(data.response.roundStartDate).format("YYYY");
          let june = new Date(1, 6, parseInt(currentYear));
          if (data.response.roundStartDate) {
            if (new Date(data.response.roundStartDate) >= june) {
              this.sidebarPeriod = this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_1A') + currentYear + this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_1B') + currentYear;
            } else {
              this.sidebarPeriod = this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_2A') + currentYear + this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_2B') + currentYear;
            }
          }
          let aggregatedData = data.response?.aggregateData;
          if (aggregatedData) {
            // dati sidebar da aggregatedData
            if (aggregatedData.modulesAverage) {
              this.averageScore = aggregatedData.modulesAverage.globalAverage;
            }
            if (this.peopleInvolvedCount) {
              if (this.completedSurveyCount) {
                this.responseRate = (this.completedSurveyCount / this.peopleInvolvedCount) * 100;
              } else {
                this.responseRate = 0;
              }
            }
            this.listData = {};
            this.listData.strengths = [];
            if (aggregatedData?.openAnswers[0]) {
              this.listData.strengths = aggregatedData.openAnswers[0].answersText;
            }
            this.listData.development = [];
            if (aggregatedData?.openAnswers[1]) {
              this.listData.development = aggregatedData.openAnswers[1].answersText;
            }
            this.listData.notes = [];
            if (aggregatedData?.openAnswers[2]) {
              for (let k = 0; k < aggregatedData.openAnswers[2].answersText.length; k++) {
                if (aggregatedData.openAnswers[2].answersText[k].length > 0) {
                  this.listData.notes = aggregatedData.openAnswers[2].answersText;
                }
              }
            }
            this.listData.notes = this.listData.notes.filter((x: string) => x.length > 0)
            // recupero dati per il grafo
            this.ratingData = []
            if (aggregatedData && aggregatedData.modulesAverage && aggregatedData.modulesAverage.modules) {
              for (let i = 0; i < aggregatedData.modulesAverage.modules.length; i++) {
                this.ratingData.push({
                  id: i + '',
                  title: aggregatedData.modulesAverage.modules[i].moduleTitle,
                  value: aggregatedData.modulesAverage.modules[i].moduleAverage
                })
              }
            }
            this.globalAverage = aggregatedData.modulesAverage.globalAverage || 0;

            this.modulesData = aggregatedData.closedAnswers || [];
            for (let i = 0; i < this.modulesData.length; i++) {
              for (let j = 0; j < this.modulesData[i].questions.length; j++) {
                this.modulesData[i].questions[j].graphData = this.getDataForGraph(this.modulesData[i].questions[j].answersByScore)
              }
            }
          }
        }
        this.isLoadingResults = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingResults = false;
      })
  }

  getManagerResultsForChief() {
    this.isLoadingResults = true;

    if (this.getManagerResults$) {
      this.getManagerResults$.unsubscribe();
    }

    this.getManagerResults$ = this.peopleAppraisalService.getUpwardFeedbackResultForChief(this.roundId, this.userId)
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForChief>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response.chiefComment && data.response.chiefComment.length) {
            this.chiefComment = data.response.chiefComment.replace(/\n/g, "<br>");
            this.chiefCommentSent = true;
          }

          // dati sidebar
          this.peopleInvolvedCount = data.response.numberOfEvaluators || 0;
          this.completedSurveyCount = data.response.numberOfFeedbackReceived || 0;

          this.managerName = data.response.managerForename + ' ' + data.response.managerSurname;

          let currentYear = moment(data.response.roundStartDate).format("YYYY");
          let june = new Date(1, 6, parseInt(currentYear));
          if (data.response.roundStartDate) {
            if (new Date(data.response.roundStartDate) >= june) {
              this.sidebarPeriod = this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_1A') + currentYear + this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_1B') + currentYear;
            } else {
              this.sidebarPeriod = this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_2A') + currentYear + this.translate.instant('upwardFeedback.peopleAppraisal.PERIOD_2B') + currentYear;
            }
          }

          let aggregatedData = data.response?.aggregateData;
          if (aggregatedData) {
            if (aggregatedData.modulesAverage) {
              this.averageScore = aggregatedData.modulesAverage.globalAverage;
            }
            if (this.peopleInvolvedCount) {
              if (this.completedSurveyCount) {
                this.responseRate = (this.completedSurveyCount / this.peopleInvolvedCount) * 100;
              } else {
                this.responseRate = 0;
              }
            }
            this.listData = {};
            this.listData.strengths = [];
            if (aggregatedData?.openAnswers[0]) {
              this.listData.strengths = aggregatedData.openAnswers[0].answersText;
            }
            this.listData.development = [];
            if (aggregatedData?.openAnswers[1]) {
              this.listData.development = aggregatedData.openAnswers[1].answersText;
            }
            this.listData.notes = [];
            if (aggregatedData?.openAnswers[2]) {
              for (let k = 0; k < aggregatedData.openAnswers[2].answersText.length; k++) {
                if (aggregatedData.openAnswers[2].answersText[k].length > 0) {
                  this.listData.notes = aggregatedData.openAnswers[2].answersText;
                }
              }
            }
            this.listData.notes = this.listData.notes.filter((x: string) => x.length > 0)
            // recupero dati per il grafo
            this.ratingData = []
            if (aggregatedData && aggregatedData.modulesAverage && aggregatedData.modulesAverage.modules) {
              for (let i = 0; i < aggregatedData.modulesAverage.modules.length; i++) {
                this.ratingData.push({
                  id: i + '',
                  title: aggregatedData.modulesAverage.modules[i].moduleTitle,
                  value: aggregatedData.modulesAverage.modules[i].moduleAverage
                })
              }
            }
            this.globalAverage = aggregatedData.modulesAverage.globalAverage || 0;

            this.modulesData = aggregatedData.closedAnswers || [];
            for (let i = 0; i < this.modulesData.length; i++) {
              for (let j = 0; j < this.modulesData[i].questions.length; j++) {
                this.modulesData[i].questions[j].graphData = this.getDataForGraph(this.modulesData[i].questions[j].answersByScore)
              }
            }
          }
        }
        this.isLoadingResults = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingResults = false;
      })
  }

  onChiefCommentChanged(tmpComment: string) {
    this.chiefComment = tmpComment;
  }

  // Porta nella home se non ci sono state modifiche
  goBack() {
    this.redirectService.goBackBrowser();
  }

  getDataForGraph(data: any) {
    let tmp = [];
    for (let i = 0; i < data.length || i < 5; i++) {
      tmp.push({
        title: i + 1,
        value: data[i] || 0
      })
    }
    return tmp;
  }

  toggleAccordion() {
    this.showAllAccordion = !this.showAllAccordion;
  }

  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
    setTimeout(() => {
      this.chartInstances[name].render();
    }, 500);
  }


  openSendChiefMessage() {
    this.modalService.open('confirm-send');
  }

  closeSendChiefMessage(confirm?: boolean) {
    this.modalService.close('confirm-send');
    if (confirm) {
      this.sendChiefMessage();
    }
  }

  sendChiefMessage() {
    this.isLoadingResults = true;

    this.peopleAppraisalService.sendUpwardFeedbackComment({ roundId: this.roundId, subordinateId: this.userId, comment: this.chiefComment })
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.SendUpwardFeedbackCommentForChief>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.redirectService.goBackBrowser();
        }
        this.isLoadingResults = false;
      }, (err) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf000",
          text: this.translate.instant("errors." + err.message || err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingResults = false;
      })

  }

  getSidenavDescription() {
    let str = '';
    if (!this.isLoadingResults) {
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_COUNT_1') + this.peopleInvolvedCount;
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_COUNT_2') + this.completedSurveyCount;
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_COUNT_3') + this.responseRate.toFixed(0);
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_COUNT_4') + this.averageScore.toFixed(2);
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_COUNT_5');
      str += this.translate.instant('upwardFeedback.peopleAppraisal.SIDENAV_DESC_SCALE');
    }
    return str;
  }

  redrawGraph(module: string) {
    let keys = Object.keys(this.chartInstances);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes(module)) {
        setTimeout(() => {
          this.chartInstances[keys[i]].render();
        }, 100);
      }
    }
  }

  ngOnDestroy() {
    if (this.getManagerResults$) {
      this.getManagerResults$.unsubscribe();
    }
  }
}
