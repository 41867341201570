import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserCard } from 'src/app/utils/classes.utils';

@Component({
  selector: 'user-card',
  templateUrl: 'user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCardComponent implements OnInit {
  @Input() user?: any;
  @Input() isChecked: boolean = false;
  @Input() isFeedbackCard: boolean = false;
  @Input() selectedUserList?: UserCardFe[];
  @Input() fromCppCollabs: boolean = false;
  @Input() radio: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onCheck: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {
    if (this.selectedUserList && this.selectedUserList.length && this.user) {
      for (let i = 0; i < this.selectedUserList.length; i++) {
        let selectedUserId = this.selectedUserList[i].id;
        if (this.user.id == selectedUserId) {
          this.user.isChecked = true;
          break;
        } else {
          this.user.isChecked = false;
        }
      }
    }
  }


  emitOnCheck() {
    if (this.user) {
      this.user.isChecked = !this.user.isChecked;
      this.onCheck.emit(this.user);
    }
  }

  getUserInitials() {
    if (this.user.initials) {
      return this.user.initials;
    } else if (this.user.name && this.user.surname) {
      return this.user.surname[0] + this.user.name[0];
    } else if (this.user.forename && this.user.surname) {
      return this.user.surname[0] + this.user.forename[0];
    }

  }
}

export interface UserCardFe extends UserCard {
  role?: string;
}