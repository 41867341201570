import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../ngrx/app.reducers";
import * as fromPeopleAppraisal from "../peopleAppraisal/ngrx/peopleAppraisal.reducers";
import * as PeopleAppraisalActions from "../peopleAppraisal/ngrx/peopleAppraisal.actions";
import { JwtPayload, SenecaResponse, RequiredAuth } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { PeopleAppraisalService } from '../shared/services/peopleAppraisal.service';
import { Router } from '@angular/router';
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import * as moment from 'moment';
import * as CoreActions from "../core/ngrx/core.actions";
import * as AuthActions from "../auth/ngrx/auth.actions";
import { environment } from 'src/environments/environment';
import { AlloyPerformanceCppServiceResponses, AlloyPerformanceUpwardFeedbackServiceResponses, GetPerformanceAssessmentInfoForManager, GetPerformanceAssessmentInfoForUser, UpwardFeedbackSubordinateEvaluationStatus, User } from 'atfcore-commonclasses';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  // Accesso alla scheda di sviluppo
  canAccessPersonalDevelopment: boolean = environment.canAccessPersonalDevelopment;
  // Accesso alla dashboard
  canAccessTheDashboard: boolean = environment.canAccessTheDashboard;
  // Accesso al CPP
  canAccessTheCpp: boolean = environment.canAccessTheCpp;
  // Accesso al Upward Feedback
  canAccessToUpwardFeedback: boolean = environment.canAccessToUpwardFeedback;
  // Accesso agli appunti privati
  canAccessToPrivateNotes: boolean = environment.canAccessToPrivateNotes;

  runningYear$: Subscription = new Subscription;
  runningYear: any = {};
  phaseName: string = '';
  bannerImage: string = '';


  // Recupero dei raound
  roundListManager: any[] = [];
  roundListUser: any[] = [];
  getRoundList$: Subscription = new Subscription();
  isLoadingRoundList: boolean = false;

  getRoundListManager$: Subscription = new Subscription();
  isLoadingRoundListManager: boolean = false;

  combinedSelected$: Subscription = new Subscription;

  loggedUser: any;
  runningPhaseDate: any;
  userAcknowledges: any;

  intro!: GuidedTour;
  steps?: TourStep[];

  isUpdatingAcks: boolean = false;
  isImpersonate: boolean = false;
  updateUserAck$: Subscription = new Subscription;

  upwardFeedbackData?: AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForManager;
  isLoadingUpwardFeedback: boolean = false;
  //selectedFeedbackManager: any;
  selectedFeedbackManagerToEvaluate: any;
  selectedFeedbackManagerToHelp: any;
  upwardFeedbackManagers: any;
  managersToEvaluate: { user: any, evaluationStatus?: UpwardFeedbackSubordinateEvaluationStatus, isChecked?: boolean }[] = [];
  upwardFeedbackDataSelf?: AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser;

  subordinatesWithPhaseStatuses$: Subscription = new Subscription;
  subordinatesWithPhaseStatuses: any[] = [];

  isPeopleAppraisal: boolean = false;
  isSelfAssessment: boolean = false;

  pasInfo$: Subscription = new Subscription;
  isLoadingPasInfo: boolean = true;
  isLoadingPasInfoUser: boolean = true;
  pasData!: {
    perfYear: number;
    perfYearCode: string;
    isNewPas: boolean,
    endDate: string
  };

  pasDataSelf!: {
    perfYear: number;
    perfYearCode: string;
    isNewPas: boolean,
    endDate: string
  };

  canAccessCpp: boolean = false;
  cppInfo?: any;
  cppInfoForUser?: any;
  isLoadingCppInfo: boolean = false;
  selectedUserList: any[] = [];
  isDownloadingCppReport: boolean = false;
  completedCollaboratorsList: any[] = [];
  searchUserText: string = '';
  isBetweenDatesCpp: boolean = false;
  isBetweenDatesCppForUser: boolean = false;
  today = new Date();
  applicationLang$: Subscription = new Subscription();
  applicationLang: any;
  loadingAllData: boolean = true;
  showUpwardFeedbackSelfTile: boolean = false;
  isManagerInAnyUpwardFeedback$: Subscription = new Subscription();
  insightFeedbackMatrixData: {
    isEnabled: boolean,
    isNew: boolean
  } = {
      isEnabled: false,
      isNew: false
    };
  getInsightFeedbackDetailsForMatrixManager$: Subscription = new Subscription();
  isLoadingInsightFeedback: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
    public translate: TranslateService,
    public router: Router,
    public modalService: ModalService,
    public peopleAppraisalService: PeopleAppraisalService,
    private selfAssessmentService: SelfAssessmentService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private analyticsService: AnalyticsService,
    private guidedTour: GuidedTourService,
    private cdr: ChangeDetectorRef) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear && this.runningYear.yearCode) {
        this.phaseName = this.runningYear.yearCode;

      }
      const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
      const userAck$: Observable<any> = this.store.select(fromApp.getUserAcknowledges);
      const isSelfAssessment$: Observable<boolean> = this.store.select(fromApp.getIsSelfAssessment);
      const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
      const getRunningPhase$: Observable<any> = this.store.select(fromApp.getRunningPhase);
      this.combinedSelected$ = combineLatest([loggedUser$, userAck$, getRunningPhase$, isSelfAssessment$, isPeopleAppraisal$])
        .subscribe(
          ([loggedUser, userAck, runningPhase, isSelfAssessment, isPeopleAppraisal]) => {
            this.loadingAllData = true;
            this.isSelfAssessment = isSelfAssessment;
            this.isPeopleAppraisal = isPeopleAppraisal;
            if (loggedUser?.auths && loggedUser.auths.find((x: any) => x == RequiredAuth.PERFORMANCEMANAGEMENTALLOY_ACCESS)) {
              this.canAccessCpp = true;
            }
            if (runningPhase && loggedUser && loggedUser.user && this.runningYear && this.runningYear.yearCode) {
              this.loggedUser = loggedUser && loggedUser.user;

              this.getCppRoundsForUser();
              this.runningPhaseDate = runningPhase && runningPhase.peopleAppraisalEndDate;
              this.userAcknowledges = userAck;
              this.bannerImage = this.getBannerImage(this.phaseName.toUpperCase());
              // recupero dati insight feedback
              this.getInsightFeedbackDetailsForMatrixManager();
              
              if (isPeopleAppraisal) {
                this.getCppRoundsForManager();
                this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveSuboardinatesWithPhaseStatuses({ getStrucureSubordinates: true }));
                // Recupero la lista di subordinati con lo stato delle fasi
                this.subordinatesWithPhaseStatuses$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesWithPhaseStatuses) => {
                  this.subordinatesWithPhaseStatuses = subordinatesWithPhaseStatuses;
                  this.isLoadingPasInfo = false;
                });

                if (this.subordinatesWithPhaseStatuses) {
                  this.getPasInfo();
                }

                this.getUpwardFeedbackInfoForManager();
              }
              if (this.isSelfAssessment) {
                this.getPasInfoSelf();
                this.getUpwardFeedbackInfoForUser();
              }
            }
            this.loadingAllData = false;
          });
    });
  }

  ngOnInit(): void {
  }

  // Apre il tutorial
  openTutorial() {

    this.translate.get(
      [
        'tutorials.home.1.TITLE',
        'tutorials.home.1.DESC',
        'tutorials.home.2.TITLE',
        'tutorials.home.2.DESC',
        'tutorials.home.3.TITLE',
        'tutorials.home.3.DESC',
        'tutorials.home.4.TITLE',
        'tutorials.home.4.DESC',
        'tutorials.home.5.TITLE',
        'tutorials.home.5.DESC',
        'tutorials.home.6.TITLE',
        'tutorials.home.6.DESC',
        'tutorials.home.7.TITLE',
        'tutorials.home.7.DESC',
        'tutorials.home.8.TITLE',
        'tutorials.home.8.DESC',
        'tutorials.home.9.TITLE',
        'tutorials.home.9.DESC',
        'tutorials.home.10.TITLE',
        'tutorials.home.10.DESC',
        'tutorials.home.11.TITLE',
        'tutorials.home.11.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#home-logo',
          /** Titolo */
          title: translations['tutorials.home.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.1.DESC'],
          orientation: Orientation.Right,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            this.onHomeTutorialClosed();
          }
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
        }, {
          /** Selettore html */
          selector: '#supportTagIcon',
          /** Titolo */
          title: translations['tutorials.home.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notifications',
          /** Titolo */
          title: translations['tutorials.home.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          // Informazioni utente (avatar, nome, ecc.)
          /** Selettore html */
          selector: '#menu-profile',
          /** Titolo */
          title: translations['tutorials.home.10.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.10.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 10
        },
        // }, {
        //   /** Selettore html */
        //   selector: '#bigBannerTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.4.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.4.DESC'],
        //   orientation: Orientation.Bottom,
        // }, {
        //   {/** Selettore html */
        //   selector: '#privateBoxTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.5.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.5.DESC'],
        //   orientation: Orientation.Top,
        //   highlightPadding: 15
        // }
        {
          /** Selettore html */
          selector: '#content-home',
          /** Titolo */
          title: translations['tutorials.home.11.TITLE'],
          /** Tour step text */
          content: translations['tutorials.home.11.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }
        ]

        // // Colloqui
        // if (this.showSeeInterviewBox()) {
        //   this.steps.push({
        //     /** Selettore html */
        //     selector: '#interviewsTag',
        //     /** Titolo */
        //     title: translations['tutorials.home.8.TITLE'],
        //     /** Tour step text */
        //     content: translations['tutorials.home.8.DESC'],
        //     orientation: Orientation.Top,
        //     highlightPadding: 15
        //   })
        // }

        // Dashboard
        // this.steps.push({
        //   /** Selettore html */
        //   selector: '#dashboardBoxTag',
        //   /** Titolo */
        //   title: translations['tutorials.home.6.TITLE'],
        //   /** Tour step text */
        //   content: translations['tutorials.home.6.DESC'],
        //   orientation: Orientation.Top,
        //   highlightPadding: 15
        // });

        const intro = {
          /** Identifier for tour */
          tourId: 'HOME TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            this.onHomeTutorialClosed();
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        setTimeout(() => {
          this.guidedTour.startTour(intro);
        }, 50);
      });
  }

  getCppRoundsForManager() {
    this.isLoadingRoundListManager = true;

    if (this.getRoundListManager$) {
      this.getRoundListManager$.unsubscribe();
    }

    this.getRoundListManager$ = this.peopleAppraisalService.getCppRounds(true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.roundListManager = data.response;
          this.getCppInfoForManager();
          this.isLoadingRoundListManager = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundListManager = false;
        }

      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoundList = false;
      })
  }
  getCppRoundsForUser() {
    this.isLoadingRoundList = true;

    if (this.getRoundList$) {
      this.getRoundList$.unsubscribe();
    }

    this.getRoundList$ = this.selfAssessmentService.getCppRounds(true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.roundListUser = data.response;
          this.getCppInfoForUser();
          this.isLoadingRoundList = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundList = false;
        }

      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoundList = false;
      })
  }


  checkShowTeamUpwardFeedback() {
    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length) {
      return true;
    }
    return false;
  }

  // Gestisce la chiusura del tutorial della home
  onHomeTutorialClosed() {
    sessionStorage.removeItem('openHomeTutorial');

    const updateOnboardingUserAck = sessionStorage.getItem('updateOnboardingUserAck');


    // Se richiesto, aggiorno le user ack dell'utente (significa che era la prima volta che l'utente entrava nell'applicazione e si è aperto da solo il tutorial)
    if (updateOnboardingUserAck) {

      this.isUpdatingAcks = true;
      if (this.updateUserAck$) {
        this.updateUserAck$.unsubscribe();
      }
      let acks = JSON.parse(JSON.stringify(this.userAcknowledges));
      acks.data['ALLOY_PERFORMANCE_ONBOARDING'] = moment().toISOString();

      this.updateUserAck$ = this.authService.updateUserAcknowledges(acks).
        subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "133",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.store.dispatch(AuthActions.SetUserAcknowledges({ payload: acks }));
            this.isUpdatingAcks = false;
            sessionStorage.removeItem('updateOnboardingUserAck');
          }
        })
      //}
    }
  }

  getPasInfo() {
    // Controllo se esiste già il subscribe
    if (this.pasInfo$) {
      this.pasInfo$.unsubscribe();
    }
    // Avvio il loader
    this.isLoadingPasInfo = true;

    // Faccio la chiamata per recuperare le info per il badge PAS
    this.peopleAppraisalService.getPerformanceAsessmentInfoForUser()
      .subscribe((data: SenecaResponse<GetPerformanceAssessmentInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pasInfo001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasInfo = false;
        } else {
          // Salvo i dati
          this.pasData = {
            perfYear: data.response.perfYear,
            perfYearCode: data.response.perfYearCode,
            isNewPas: data.response.isPasNew,
            endDate: moment().isBetween(moment(), data.response.peopleAppraisalEndDate, undefined, '(]') ? moment(data.response.peopleAppraisalEndDate).format("DD.MM.YYYY") : ''
          }
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pasInfo002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPasInfo = false;
      })
  }
  getPasInfoSelf() {
    // Controllo se esiste già il subscribe
    if (this.pasInfo$) {
      this.pasInfo$.unsubscribe();
    }
    // Avvio il loader
    this.isLoadingPasInfoUser = true;

    // Faccio la chiamata per recuperare le info per il badge PAS
    this.selfAssessmentService.getPerformanceAsessmentInfoForUser()
      .subscribe((data: SenecaResponse<GetPerformanceAssessmentInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pasInfo001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPasInfoUser = false;
        } else {
          // Salvo i dati
          this.pasDataSelf = {
            perfYear: data.response.perfYear,
            perfYearCode: data.response.perfYearCode,
            isNewPas: data.response.isSelfNew,
            endDate: moment().isBetween(moment(), data.response.selfAssessmentEndDate, undefined, '(]') ? moment(data.response.selfAssessmentEndDate).format("DD.MM.YYYY") : ''
          }
          this.isLoadingPasInfoUser = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pasInfo002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPasInfoUser = false;
      })
  }



  // Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  getUpwardFeedbackInfoForManager() {
    this.peopleAppraisalService.getUpwardFeedbackInfo()
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.upwardFeedbackData = data.response;
          // this.upwardFeedbackData.secondLevelEvaluation = [{ user: null, resultAvailable: false, alreadyCommented: false }];
          if (this.upwardFeedbackData?.secondLevelEvaluation?.subordinates) {
            this.upwardFeedbackManagers = [];

            for (let i = 0; i < this.upwardFeedbackData.secondLevelEvaluation.subordinates.length; i++) {
              let item = this.upwardFeedbackData.secondLevelEvaluation.subordinates[i];
              this.upwardFeedbackManagers.push({
                ...item.user,
                name: item.user.forename,
                cardStatus: item.alreadyCommented ? 'COMPLETED' : 'TO_HELP',
                resultAvailable: item.resultAvailable
              });
            }

            this.upwardFeedbackManagers = this.upwardFeedbackManagers.filter((user: any) => user.resultAvailable)
          }
        }
        this.isLoadingUpwardFeedback = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpwardFeedback = false;
      })
  }

  // Controllo se ho la tile di Upward Feedback
  hasUpwardFeedback() {
    return (!this.isLoadingUpwardFeedback && this.upwardFeedbackData && moment().isSameOrAfter(this.upwardFeedbackData.startDate) && moment().isSameOrBefore(this.upwardFeedbackData.endDate) && this.upwardFeedbackManagers && this.upwardFeedbackManagers.length > 0);
  }
  // Controllo se ho la tile di Upward Feedback self
  hasUpwardFeedbackSelf() {
    return !this.isLoadingUpwardFeedback && this.upwardFeedbackDataSelf && this.upwardFeedbackDataSelf.showTile;
  }

  //Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  getUpwardFeedbackInfoForUser() {
    this.isLoadingUpwardFeedback = true;
    this.selfAssessmentService.getUpwardFeedbackInfo()
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpwardFeedback = false;
        } else {
          this.managersToEvaluate = [];
          if (data?.response?.managersToEvaluate) {
            this.managersToEvaluate = [...data.response.managersToEvaluate];
            this.managersToEvaluate.forEach((user: any) => user.isChecked = false);
          }
          this.upwardFeedbackDataSelf = data?.response;
        }
        this.isLoadingUpwardFeedback = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf002",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpwardFeedback = false;
      })
  }

  // //Recupera informazioni Upward Feedback e la lista dei manager se è un valutatore
  // getUpwardFeedbackInfoSelf() {
  //   this.isLoadingUpwardFeedback = true;
  //   this.selfAssessmentService.getUpwardFeedbackInfo()
  //     .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForUser>) => {
  //       if (data && data.error) {
  //         const messageObj: ApplicationModalMessage = {
  //           modalId: "upf001",
  //           text: this.translate.instant("errors." + data.error),
  //           title: this.translate.instant("generic.WARNING")
  //         }
  //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //         this.isLoadingUpwardFeedback = false;
  //       } else {
  //         this.upwardFeedbackDataSelf = data.response;
  //       }
  //       this.isLoadingUpwardFeedback = false;
  //     }, (err: any) => {
  //       const messageObj: ApplicationModalMessage = {
  //         modalId: "upf002",
  //         text: this.translate.instant("errors." + ((err && err.message) || err)),
  //         title: this.translate.instant("generic.WARNING")
  //       }
  //       this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
  //       this.isLoadingUpwardFeedback = false;
  //     })
  // }

  openUpwardFeedback(isSelf?: boolean) {
    if (isSelf && this.upwardFeedbackData) {
      // this.redirectService.goToUpwardFeedbackPeopleAppraisal(this.upwardFeedbackData?.roundId)
      this.redirectService.goToUpwardFeedbackListPeopleAppraisal();
    } else if (!isSelf && this.upwardFeedbackData && this.upwardFeedbackData.secondLevelEvaluation) {
      // this.openUpwardFeedbackModal();
      this.redirectService.goToUpwardFeedbackTeamListPeopleAppraisal();
    }
  }
  // MITODO aggiungere la route
  goToPersonDetails(isSelf?: boolean) {
    if (this.loggedUser.userId && ((this.runningYear.year && this.runningYear.yearCode) || (this.pasDataSelf && this.pasDataSelf.perfYear && this.pasDataSelf.perfYearCode))) {
      if (isSelf) {
        this.router.navigate(['selfAssessment/personDetails/' + this.loggedUser.userId + '/' + this.pasDataSelf.perfYear + '/' + this.pasDataSelf.perfYearCode]);
      } else {
        if (this.subordinatesWithPhaseStatuses && this.subordinatesWithPhaseStatuses[0] && this.subordinatesWithPhaseStatuses[0].userId) {
          this.router.navigate(['peopleAppraisal/personDetails/' + this.subordinatesWithPhaseStatuses[0].userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upferr001",
            title: this.translate.instant("errors.DENIED_NOTES_TITLE"),
            text: this.translate.instant("errors.GENERIC_NOT_AVAILABLE_SECTION")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
      }
    }
  }
  openUpwardFeedbackSelf() {
    this.redirectService.goToUpwardFeedbackSubordinateList();
    // this.selectedFeedbackManagerToEvaluate = null;
    // this.managersToEvaluate.forEach((user: any) => user.isChecked = false);
    /*
    if (this.managersToEvaluate && this.managersToEvaluate.length == 1 && this.managersToEvaluate[0].evaluationStatus != 'CLOSED') {
      this.selectedFeedbackManagerToEvaluate = this.managersToEvaluate[0];
      this.goToSendFeedback();
    } else if (this.managersToEvaluate && this.managersToEvaluate.length > 1) {
      this.modalService.open('upf-modal-for-user');
    } else {
      const messageObj: ApplicationModalMessage = {
        modalId: "upferr001",
        title: this.translate.instant("errors.DENIED_NOTES_TITLE"),
        text: this.translate.instant("upwardFeedback.errors.SELF_ASS_DESCR")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    }
    */
  }

  goToSendFeedback() {
    // Assegno l'unica persona selezionata
    if (this.selectedFeedbackManagerToEvaluate && this.upwardFeedbackDataSelf) {
      this.closeUpwardFeedbackModalForUser();
      this.redirectService.goToUpwardFeedbackSelfAssessment(this.upwardFeedbackDataSelf.roundId, this.selectedFeedbackManagerToEvaluate.user.userId);
    }
  }

  goToDashboard() {
    this.router.navigate(['/peopleAppraisal/dashboard/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }

  goToPrivateNotes() {
    this.router.navigate(['/selfAssessment/personDetails/myNotes/' + this.loggedUser.userId + '/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }
  goToTakeNote() {
    this.router.navigate(['/peopleAppraisal/createUpdatePrivateNote']);
  }

  openCollaboratorsCppCompletedModal() {
    this.modalService.open("completed-collaborators-cpp-modal");
  }

  // Vai al cpp dell'utente
  goToUserCPP() {
    if (this.cppInfoForUser) {
      this.router.navigate(['/selfAssessment/cppDetails/' + this.cppInfoForUser?.roundId + '/' + this.loggedUser.userId]);
    } else {
      let activeRound = this.roundListUser.find((el: any) => el.roundStatus == 'RUNNING');
      this.router.navigate(['/selfAssessment/cppDetails/' + activeRound?.roundId + '/' + this.loggedUser.userId]);
    }
    // if (this.cppInfoForUser.progress == 100) {
    //   this.openCompletionCppModal();
    // } else {
    //   this.redirectService.goToUserCpp(this.cppInfoForUser?.roundId);
    // }
  }
  goToTeamCPP() {
    this.router.navigate(['/peopleAppraisal/cppTeam/' + this.cppInfo?.roundId]);
  }

  openCompletionCppModal() {
    this.modalService.open("completion-cpp-modal");
  }

  closeCompletionCppModal(downloadReport?: boolean) {
    if (downloadReport) {
      this.downloadCppReportForUser();
    } else {
      this.modalService.close("completion-cpp-modal");
    }
  }

  closeCollaboratorsCppCompletedModal(downloadReport?: boolean, notRefreshSelectedList?: boolean) {
    if (downloadReport) {
      this.downloadCppReport();
    } else {
      if (!notRefreshSelectedList) {
        this.selectedUserList = [];
        if (this.cppInfo.completedCollaboratorsData?.length) {
          this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
            element.isChecked = false;
          });
        }
        if (this.completedCollaboratorsList?.length) {
          this.completedCollaboratorsList.forEach((element: any) => {
            element.isChecked = false;
          });
        }
      }
      this.modalService.close("completion-cpp-modal");
      this.modalService.close("completed-collaborators-cpp-modal");
    }
  }


  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  getCppInfoForUser() {
    this.isLoadingCppInfo = true;

    this.selfAssessmentService.getCppInfoForUser()
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForUser>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta0012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cppInfoForUser = data.response;
          if (this.cppInfoForUser?.startDate && this.cppInfoForUser?.endDate) {
            this.isBetweenDatesCppForUser = moment(this.today).isBetween(moment(this.cppInfoForUser.startDate), moment(this.cppInfoForUser.endDate));
          } else {
            this.isBetweenDatesCppForUser = true;
          }
        }
        this.isLoadingCppInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta00022",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppInfo = false;
      });
  }

  downloadCppReportForUser() {
    this.isDownloadingCppReport = true;

    this.selfAssessmentService.downloadCppReport(this.cppInfoForUser?.roundId)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingCppReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)

              this.isDownloadingCppReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingCppReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingCppReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingCppReport = false;
      })
  }

  downloadCppReport() {
    this.isDownloadingCppReport = true;

    this.closeCollaboratorsCppCompletedModal(false, true);

    let userIds = this.selectedUserList.map((x: any) => {
      return x.id;
    })

    this.peopleAppraisalService.downloadCppReport(this.cppInfo?.roundId, userIds)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingCppReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.selectedUserList = [];
              if (this.cppInfo.completedCollaboratorsData?.length) {
                this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
                  element.isChecked = false;
                });
              }
              if (this.completedCollaboratorsList?.length) {
                this.completedCollaboratorsList.forEach((element: any) => {
                  element.isChecked = false;
                });
              }
              this.isDownloadingCppReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingCppReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingCppReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingCppReport = false;
      })
  }

  searchUsers() {
    this.selectedUserList = [];
    if (this.cppInfo.completedCollaboratorsData?.length) {
      this.cppInfo.completedCollaboratorsData.forEach((element: any) => {
        element.isChecked = false;
      });
    }
    if (this.completedCollaboratorsList?.length) {
      this.completedCollaboratorsList.forEach((element: any) => {
        element.isChecked = false;
      });
    }
    this.completedCollaboratorsList = this.cppInfo.completedCollaboratorsData.filter((x: any) => {
      let completeName = x.name + ' ' + x.surname;
      return completeName.toLowerCase().includes(this.searchUserText);
    })
  }

  openUpwardFeedbackModal() {
    this.selectedFeedbackManagerToHelp = null;
    if (this.upwardFeedbackManagers && this.upwardFeedbackManagers.length) {
      this.upwardFeedbackManagers.forEach((user: any) => user.isChecked = false);
    }
    this.modalService.open('upf-modal-for-manager');
  }

  changeSearchedTextValue(text: string) {
    this.searchUserText = text;
  }
  getCppInfoForManager() {
    this.isLoadingCppInfo = true;

    this.peopleAppraisalService.getCppInfoForManager()
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForManager>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta0000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cppInfo = data.response;
          if (this.cppInfo?.startDate && this.cppInfo?.endDate) {
            this.isBetweenDatesCpp = moment(this.today).isBetween(moment(this.cppInfo.startDate), moment(this.cppInfo.endDate));
          } else {
            this.isBetweenDatesCpp = true;
          }
          if (this.cppInfo?.completedCollaboratorsData?.length) {
            this.cppInfo.completedCollaboratorsData = UserDataUtils.getUserCardData(this.cppInfo.completedCollaboratorsData) || [];
            this.completedCollaboratorsList = JSON.parse(JSON.stringify(this.cppInfo.completedCollaboratorsData));
          }
        }
        this.isLoadingCppInfo = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta00001",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppInfo = false;
      });
  }

  // Seleziono l'utente per il Upward Feedback
  chooseUpwardFeedbackUser(newUser: any) {
    this.managersToEvaluate.forEach((userData: any) => userData.user.isChecked = false);
    newUser.user.isChecked = true;
    this.selectedFeedbackManagerToEvaluate = newUser;
    this.cdr.detectChanges()
  }

  // Seleziono il manager da aiutare per Upward Feedback
  chooseUpwardFeedbackManager(newUser: any) {
    this.upwardFeedbackManagers.forEach((user: any) => user.isChecked = false);
    newUser.isChecked = true;
    this.selectedFeedbackManagerToHelp = newUser;
  }

  goToCheckFeedbackResults() {
    if (this.upwardFeedbackData) {
      this.closeUpwardFeedbackModalForManager();
      this.redirectService.goToUpwardFeedbackPeopleAppraisal(this.upwardFeedbackData?.roundId, this.selectedFeedbackManagerToHelp.userId)
    }
  }

  closeUpwardFeedbackModal() {
    this.modalService.close('upward-feedback');
  }
  closeUpwardFeedbackModalForUser() {
    this.modalService.close('upf-modal-for-user');
  }
  closeUpwardFeedbackModalForManager() {
    this.modalService.close('upf-modal-for-manager');
  }

  isUpwardFeedbackDateValid() {
    if (!this.isLoadingUpwardFeedback && this.upwardFeedbackData) {
      return moment().isSameOrAfter(this.upwardFeedbackData.startDate) && moment().isSameOrBefore(this.upwardFeedbackData.endDate)
    }
    return false;
  }

  // Recupero l'immagine del banner
  getBannerImage(phaseName: string) {
    if (this.isPeopleAppraisal) {
      return 'assets/img/head/bg-head-people.png';
    }
    return 'assets/img/head/banner-self.png';

  }

  getInsightFeedbackDetailsForMatrixManager() {
    this.isLoadingInsightFeedback = true;

    if (this.getInsightFeedbackDetailsForMatrixManager$) {
      this.getInsightFeedbackDetailsForMatrixManager$.unsubscribe();
    }

    this.getInsightFeedbackDetailsForMatrixManager$ = this.peopleAppraisalService.getInsightFeedbackDetailsForMatrixManager(this.runningYear.year, this.runningYear.yearCode)
      .subscribe((data: any) => {
        if (data && data.response) {
          this.insightFeedbackMatrixData = data.response;
        }
        this.isLoadingInsightFeedback = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta00001",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingInsightFeedback = false;
      })
  }

  goToInsightFeedbackMatrixManager() {
    this.router.navigate(['insightFeedbackMatrixManager/' + this.runningYear.year + '/' + this.runningYear.yearCode]);
  }


  ngAfterViewInit() {
    if (!this.isImpersonate) {
      const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

      if (forceOpenTutorial) {
        this.openTutorial();
      }
    }
  }

  goToCppMonitoring() {
    this.router.navigate(['peopleAppraisal/cppCollaborators/' + this.cppInfo.roundId]);
  }

  ngOnDestroy() {
    // const forceOpenTutorial = sessionStorage.getItem('openHomeTutorial');

    // if (forceOpenTutorial) {
    //   sessionStorage.removeItem('openHomeTutorial');
    // }
    if (this.isManagerInAnyUpwardFeedback$) {
      this.isManagerInAnyUpwardFeedback$.unsubscribe();
    }
    if (this.getInsightFeedbackDetailsForMatrixManager$) {
      this.getInsightFeedbackDetailsForMatrixManager$.unsubscribe();
    }
  }
}
