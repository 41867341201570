/*
 * Guard per il componente di Login. Il servizio verifica dallo State, unica fonte di verità, se l'utente è correttamente autenticato e, in questo caso,
 * salta la procedura di Login andando nella pagina di default, l'home page.
*/

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map, tap } from 'rxjs/operators';

import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>, private route: ActivatedRoute, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      .pipe(take(1),
        map(isAuthenticated => {
          if (isAuthenticated) {
            let lastVisitedPage = this.router.routerState && this.router.routerState.snapshot && this.router.routerState.snapshot.url || '/users/home';
            this.router.navigate([lastVisitedPage]);
            return false;
          } else {
            return true;
          }
        })
      )
  }
}