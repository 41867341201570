<div class="wrapper-layout-peopleAppraisal">
  <team-sidebar *ngIf="!isLoadingUsersList && usersList && usersList.length" class="team-sidebar" [users]="usersList"
    [activeUserId]="userId" (userClicked)="onInsightFeedbackUserClicked($event)" [isLoadingUsers]="isLoadingUsersList"
    [isInsightFeedback]="true" [hasImpersonateBanner]="isImpersonate" [getSubordinatesUserField]="true">
  </team-sidebar>

  <div class="page-content-wrapper">

    <div id="snackbarPersonDetails">
      <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div id="snackbarReminderSent">
      <p translate="generic.REMINDER_SENT"></p>
    </div>

    <!-- Se ho finito di caricare i dati dell'utente -->
    <ng-container *ngIf="!isLoadingYearList ">

      <header-dossier *ngIf="!isLoadingPersonDetails && subordinateUser" [user]="subordinateUser"
        [yearItems]=" yearList" [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
        [hasTabs]="false" [hideAction]="true" [isInsight]="true">
      </header-dossier>

      <div class="main-content-wrapper">
        <!-- dati semestre H1 H2-->

        <!-- Contenitore dati caricati -->
        <child-content>
          <!-- Placeholder caricamento-->
          <ng-container *ngIf="isLoadingPersonDetails">
            <div class="page-header">
              <div class="loader spinner-medium"></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!isLoadingPersonDetails">
            <div class="page-header">
              <div id="insightStepPageHeader">
                <!-- Se sto inviando la richiesta di feedback -->
                <div class="title-container">
                  <p class="title-with-info">
                    {{ ('stepper.INSIGHT_FEEDBACK' | translate) }}
                    <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer"
                      [applyClass]="true" (click)="openHelpModal()">
                    </svg-icon>
                  </p>
                </div>
                <p class="description" *ngIf="personDetails">
                  {{ ('insight.matrix.PAGE_SUBTITLE' | translate)}}
                </p>
              </div>


              <ng-container *ngIf="!isLoadingPersonDetails">

                <ng-container *ngIf="personDetails && !isComplete && insightData">
                  <div class="insight-feedback-container">
                    <div class="insight-title" *ngIf="insightData.requiredByManager && subordinateUser">
                      <span>
                        {{('insight.matrix.SEND_FEEDBACK_TO' | translate)}}
                        <b>{{insightData.requiredByManager.surname + ' ' +
                          insightData.requiredByManager.forename}}</b>
                        {{('insight.matrix.SEND_FEEDBACK_TO_2' | translate)}}
                        <b>{{subordinateUser.surname + ' ' + subordinateUser.forename}}</b>
                      </span>
                    </div>
                    <!-- Richiedente -->
                    <div class="requester-row">
                      <avatar-img [user]="insightData.requiredByManager" size="extra-small"></avatar-img>
                      <span>
                        <b>{{ ('insight.matrix.REQUESTED_BY' | translate)}}</b>
                        {{insightData.requiredByManager.surname + ' ' + insightData.requiredByManager.forename}}
                        <b>{{ ('insight.matrix.REQUESTED_BY_2' | translate)}}</b>
                        {{ insightData.requiredDate | date: 'dd.MM.yyyy'}}
                      </span>
                    </div>
                    <!-- Motivazione della richiesta di feedback-->
                    <div class="request-motivation">
                      <h3 translate="insight.matrix.REQUEST_MOTIVATION"></h3>
                      <p>{{insightData.feedbackMotivation || 'generic.NO_MOTIVATION_INSERTED'}}</p>
                    </div>

                    <div class="divisor-line"></div>

                    <div class="request-motivation">
                      <p [innerHTML]="'insight.matrix.FEEDBACK_IMPORTANCE_MEMO' | translate"></p>
                    </div>

                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="insightData?.developmentAreasComment && insightData?.developmentAreasComment.length && insightData?.developmentAreasComment.trim().length"
                      [isObbligatory]="true" [title]="getDevAreaTitle()" [disabled]="isItemDisabled()"
                      id="developmentAreasComment">
                      <alloy-text-area [bindValue]="insightData.developmentAreasComment" [maxlength]="300"
                        (onModelChanged)="changeDevelopmentAreasComment($event)"
                        placeholder="{{'insight.matrix.INSERT_FEEDBACK_PLH' | translate}}"
                        [readonly]="isItemDisabled()">
                      </alloy-text-area>
                    </input-container>

                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="insightData?.pointsOfStrengthComment && insightData?.pointsOfStrengthComment.length && insightData?.pointsOfStrengthComment.trim().length"
                      [isObbligatory]="true" [title]="getPointOfStrengthTitle()" [disabled]="isItemDisabled()"
                      id="pointsOfStrengthComment">
                      <alloy-text-area [bindValue]="insightData.pointsOfStrengthComment" [maxlength]="300"
                        (onModelChanged)="changePointOfStrengthComment($event)"
                        placeholder="{{'insight.matrix.INSERT_FEEDBACK_PLH' | translate}}"
                        [readonly]="isItemDisabled()">
                      </alloy-text-area>
                    </input-container>

                    <div class="action-bar" *ngIf="!isHistory">
                      <alloy-button type="success" size="large"
                        [disabled]="isSendFeedbackDisabled() || isItemDisabled()"
                        [label]="'insight.matrix.SEND_FEEDBACK' | translate"
                        (onButtonClicked)="openConfirmSendFeedback()">
                      </alloy-button>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="personDetails && isComplete">
                  <!-- Card feedback inviato -->
                  <div class="insight-feedback-card">
                    <!-- lato sinistro avatar utente-->
                    <div class="left">
                      <div class="bubble-container">
                        <avatar-img [user]="loggedUser" size="medium"></avatar-img>
                        <svg-icon src="/assets/img/icons/baloon-vector.svg">
                        </svg-icon>
                      </div>
                    </div>
                    <div class="center">
                      <!-- Header -->
                      <div class="header">
                        <div class="from">
                          <svg-icon class="small-icon" src="/assets/img/icons/message-square.svg" [applyClass]="true">
                          </svg-icon>
                          <p>{{ 'insight.matrix.FEEDBACK_BY' | translate}} <b>{{loggedUser.surname + ' ' +
                              loggedUser.forename}}</b>
                          </p>
                        </div>
                        <div class="time">
                          <p>{{insightData?.answerDate | date: 'dd.MM.yyyy'}}</p>
                          <svg-icon class="small-icon" src=" /assets/img/icons/clock.svg" [applyClass]="true">
                          </svg-icon>
                        </div>
                      </div>
                      <div class="divisor-line"></div>
                      <!-- Commenti ad aree di sviluppo e punti di forza -->
                      <div class="insight-content" *ngIf="insightData?.requiredByManager">
                        <!-- Chi ha richiesto il feedback -->
                        <div class="info-row">
                          <p class="title" [translate]="'insight.matrix.REQUESTED_BY' | translate"></p>
                          <p class="comment">
                            {{insightData.requiredByManager.surname + ' ' + insightData.requiredByManager.forename
                            }}
                          </p>
                        </div>
                        <!-- Aree di sviluppo -->
                        <div class="info-row">
                          <p class="title">{{ ('insight.matrix.DEVELOPMENT_AREAS' | translate) }}</p>
                          <p class="comment">{{ insightData.developmentAreasComment }}</p>
                        </div>
                        <!-- Punti di forza -->
                        <div class="info-row">
                          <p class="title">{{ ('insight.matrix.STRENGTH_POINTS' | translate) }}</p>
                          <p class="comment">{{ insightData.pointsOfStrengthComment }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="right"></div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isLoadingPersonDetails">
                <div class="loader spinner-medium"></div>
              </ng-container>


            </div>
          </ng-container>
        </child-content>

      </div>
    </ng-container>
    <!-- Loader caricamento dati -->
    <ng-container *ngIf="isLoadingPersonDetails || isLoadingYearList">
      <div class="loader spinner-big"></div>
    </ng-container>
  </div>
</div>


<modal id="help-modal" (onClose)="closeHelpModal()" [confirmLabel]="'generic.CLOSE' | translate"
  (onConfirm)="closeHelpModal()">
  <modal-text-content class="list-modal" modalId="hifm001" [title]="'insight.modals.GUIDE_TITLE' | translate"
    [text]="'insight.modals.GUIDE_DESCR' | translate">
  </modal-text-content>
</modal>

<modal id="confirm-send-feedback" (onClose)="closeConfirmSendFeedback()" [confirmLabel]="'generic.CONFIRM' | translate"
  [cancelLabel]="'generic.CANCEL' | translate" (onConfirm)="closeConfirmSendFeedback(true)"
  (onCancel)="closeConfirmSendFeedback()">
  <modal-text-content *ngIf="insightData?.requiredByManager" [modalId]="'sif001'"
    [title]="'insight.matrix.CONFIRM_SEND_FEEDBACK' | translate"
    [text]="('insight.matrix.CONFIRM_SEND_FEEDBACK_DESCR' | translate) + insightData.requiredByManager.surname + ' ' + insightData.requiredByManager.forename + '?'">
  </modal-text-content>
</modal>