<div class="evaluation-rating-container">
  <div class="avatar-evaluation-container" [ngClass]="{'more-columns': rating && ratingValue}">
    <avatar-img *ngIf="user" [user]="user" [size]=" smallAvatar ? 'small' : 'medium'"></avatar-img>

    <div class="evaluation-container">
      <ng-container *ngIf="isMatrixManagerFeedback && user">
        <h3>{{ ('finalEvaluation.FEEDBACK_BY' | translate) + user.forename + ' ' + user.surname}}</h3>
      </ng-container>

      <ng-container *ngIf="isInsightManagerComment && directManagerComment && directManagerComment.length">
        <p class="evaluation-title">{{('finalEvaluation.COMMENT_BY' | translate) + (user?.forename[0] + '. ' + user?.surname)}}
        </p>
        <p class="evaluation-descr" *ngIf="matrixManager"
          [innerHTML]="('finalEvaluation.COMMENT_ON' | translate) + '<b>'+ matrixManager.surname + ' ' + matrixManager.forename + '</b>'">
        </p>
        <p class="evaluation-descr">{{ directManagerComment || ('generic.NO_COMMENT_INSERTED' | translate) }}
        </p>
      </ng-container>

      <ng-container *ngIf="!isInsightManagerComment && observationData">
        <h3 *ngIf="!isMatrixManagerFeedback && user">{{ ('finalEvaluation.COMMENT_BY' | translate) + user.forename[0] +
          '. ' + user.surname}}</h3>
        <!-- Punti di forza -->
        <p class="evaluation-title" translate="finalEvaluation.STRONG_POINTS"></p>
        <p class="evaluation-descr">{{ observationData.strongPoints || ('generic.NO_COMMENT_INSERTED' | translate) }}
        </p>
        <!-- Aree di sviluppo -->
        <p class="evaluation-title" translate="finalEvaluation.DEVELOPMENT_AREAS"></p>
        <p class="evaluation-descr">{{ observationData.developmentAreas || ('generic.NO_COMMENT_INSERTED' | translate)
          }}
        </p>
      </ng-container>


      <ng-container *ngIf="canEdit">
        <a class="custom-link" translate="finalEvaluation.EDIT_OBSERVATION"
          [ngClass]="{'disabled-edit-link': isEditDisabled}" (click)="emitOnEdit()"></a>
      </ng-container>
    </div>
  </div>
</div>