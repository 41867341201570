import { TranslateService } from "@ngx-translate/core";
import moment = require("moment-timezone");

export class BadgeUtils {
  static getProfileBadgeObject(data: any, translations: any) {
    let levels = [1, 2, 3, 100];
    return new Promise((resolve, reject) => {
      let tmpObject: any = {
        1: [],
        2: [],
        3: [],
        100: []
      };

      for (let i = 0; i < levels.length; i++) {
        let levelObjects = data.filter((x: any) => x.level == levels[i]);
        if (levelObjects && levelObjects.length) {
          levelObjects.sort((a: any, b: any) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
        }
        for (let j = 0; j < levelObjects.length; j++) {
          let icon = '';
          if (levelObjects[j].badgeType == 'GOLDEN') {
            icon = 'assets/img/badge/centro.svg';
          } else {
            icon = 'assets/img/badge/' + (levelObjects[j].badgeType.split('_').length > 1 ? levelObjects[j].badgeType.split('_')[1].toLowerCase() : levelObjects[j].badgeType.toLowerCase()) + '.svg';
          }
          let starArray = [];
          if (levels[i] == 100) {
            starArray = [0, 1, 2, 3, 4];
          } else {
            for (let k = 0; k < levelObjects[j].level; k++) {
              starArray.push(k);
            }
          }
          tmpObject[levels[i]].push({
            badgeId: levelObjects[j].badgeId,
            badgeType: levelObjects[j].badgeType,
            title: translations['badges.' + levelObjects[j].badgeType],
            image: icon,
            isActive: levelObjects[j].badgeId.indexOf('MOCK') < 0,
            starNumberArray: starArray,
            date: levelObjects[j].earnedDate ? moment(levelObjects[j].earnedDate).format('DD/MM/YYYY') : null
          })
        }
      }
      resolve(tmpObject);
    })
  }

  static formatBadges(badgeData: any, translate: TranslateService) {
    let tmpList = [];
    if (badgeData && badgeData.length) {
      for (let i = 0; i < badgeData.length; i++) {
        let icon = '';
        if (badgeData[i].badgeType == 'GOLDEN') {
          icon = 'assets/img/badge/centro.svg';
        } else {
          icon = 'assets/img/badge/' + (badgeData[i].badgeType.split('_').length > 1 ? badgeData[i].badgeType.split('_')[1].toLowerCase() : badgeData[i].badgeType.toLowerCase()) + '.svg';
        }
        let starArray = [];
        if (badgeData[i].level == 100) {
          starArray = [0, 1, 2, 3, 4];
        } else {
          for (let k = 0; k < badgeData[i].level; k++) {
            starArray.push(k);
          }
        }
        tmpList.push({
          badgeId: badgeData[i].badgeId,
          badgeType: badgeData[i].badgeType,
          title: translate.instant('badges.' + badgeData[i].badgeType),
          image: icon,
          isActive: badgeData[i].badgeId.indexOf('MOCK') < 0,
          starNumberArray: starArray,
          date: null//;badgeData[i].earnedDate ? moment(badgeData[i].earnedDate).format('DD/MM/YYYY') : null
        });
      }
    }
    return tmpList;
  }
}

