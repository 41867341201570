import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'list-doc-item',
  templateUrl: 'list-doc-item.component.html',
  styleUrls: ['./list-doc-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListDocItemComponent implements OnInit {
  @Input() srcIcon: string = '';
  @Input() fileName: string = '';
  @Input() chargedTime: string | null = '-';
  @Input() toEndParagraph: boolean = false;
  @Input() isLink: boolean = false;
  @Input() canDoActions: boolean = false;
  @Output() onDelete = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDownload = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitOnDelete() {
    this.onDelete.emit();
  }

  emitOnEdit() {
    this.onEdit.emit();
  }

  emitOnDownload() {
    this.onDownload.emit();
  }
}