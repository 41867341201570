/*
* Componente per la paginazione
*/

import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from "@angular/core"
@Component({
  selector: 'table-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() id: string = 'pagination';
  @Input() forceDisabled: boolean = false;
  @Input() smallOrangeLayout: boolean = false;
  @Input() aLotOfResults: boolean = false;
  @Input() noPadding: boolean = false;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  constructor() {
  }

  // Alza l'evento per il componente padre
  onPageChanged(event: number) {
    this.pageChanged.emit(event);
  }
}