<div class="text-goal-container">
  <div id="{{ id + '-title' }}" class="goal-title-container">
    <div class="title-badge-container">
      <p class="title">{{ title }}</p>
      <!-- Sottotitolo -->
      <ng-container *ngIf="subtitle">
        <p class="subtitle" [innerHTML]="subtitle"></p>
      </ng-container>
    </div>
    <!-- Menu edit -->
    <ng-container *ngIf="showMenu">
      <div class="menu-btn" (clickOutside)="isMenuOpen = false;">
        <p *ngIf="isToApprove" class="new-badge" translate="generic.NEW"></p>
        <svg-icon src="assets/img/icons/more-vertical.svg" [applyClass]="true" class="menu-icon" (click)="toggleMenu()">
        </svg-icon>
        <!-- Dropdown -->
        <ng-container *ngIf="isMenuOpen">
          <div class="dropdown-menu">
            <ng-container *ngFor="let option of menuOptions">
              <alloy-dropdown-item (click)="onMenuOptionClicked(option.id)" class="full-width" [item]="option">
              </alloy-dropdown-item>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <!-- IT Rating -->
  <ng-container *ngIf="ITRating">
    <input-container id="{{ id + '-description' }}" [numberCharsTextArea]="300" [isFromTextArea]="true"
      [isWithoutInfoIcon]="true" [isValued]="evaluation && evaluation.trim().length" [isObbligatory]="true"
      title="{{'textGoal.INSERT_LABEL' | translate}}" [disabled]="disabled" [readonly]="readonly">
      <alloy-text-area [bindValue]="evaluation" [maxlength]="300" (onModelChanged)="onEvaluationChanged($event)"
        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="disabled" [disabledRating]="!disabled"
        [ITRating]="ITRating" [ITRatingVote]="ITRatingVote" (onITRatingVoteChanged)="onRatingVoteChanged($event)">
      </alloy-text-area>
    </input-container>
  </ng-container>
  <!-- Text area valutazione -->
  <ng-container *ngIf="isEvaluation && !ITRating">
    <input-container id="{{ id + '-description' }}" [numberCharsTextArea]="300" [isFromTextArea]="true"
      [isWithoutInfoIcon]="true" [isValued]="evaluation && evaluation.trim().length" [isObbligatory]="true"
      title="{{'textGoal.INSERT_LABEL' | translate}}" [disabled]="disabled" [readonly]="readonly">
      <alloy-text-area [bindValue]="evaluation" [maxlength]="300" (onModelChanged)="onEvaluationChanged($event)"
        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="disabled">
      </alloy-text-area>
    </input-container>
  </ng-container>
  <!-- IT RatingReadOnly -->
  <ng-container *ngIf="ITRatingReadOnly">
    <input-container id="{{ id + '-description' }}" [numberCharsTextArea]="300" [isFromTextArea]="true"
      [isWithoutInfoIcon]="true" [isValued]="evaluation && evaluation.trim().length" [isObbligatory]="true"
      title="{{'textGoal.INSERT_LABEL' | translate}}" [disabled]="disabled" [readonly]="readonly">
      <alloy-text-area [bindValue]="evaluation" [maxlength]="300" (onModelChanged)="onEvaluationChanged($event)"
        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="disabled" [disabledRating]="disabled"
        [ITRating]="ITRatingReadOnly" [ITRatingVote]="ITRatingVote"
        (onITRatingVoteChanged)="onRatingVoteChanged($event)">
      </alloy-text-area>
    </input-container>
  </ng-container>
  <!-- Sottotitolo footer-->
  <ng-container *ngIf="subtitleFooter">
    <p class="subtitle-footer" [innerHTML]="subtitleFooter"></p>
  </ng-container>
</div>