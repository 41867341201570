<div class="sidenav-informations-container">
  <div class="sidenav">
    <div class="total-info-container" *ngIf="isBackButton; else noBack">
      <div class="back-button-container">
        <alloy-button (onButtonClicked)="goBack()" label="{{'generic.GO_BACK'| translate}}"
          type="primary link back-sidenav-button arrow-icon" size="medium" iconLeft="/assets/img/icons/arrow-left.svg">
        </alloy-button>
      </div>
      <div class="data-info">
        <ng-container *ngIf="!isLoadingData">
          <h3 [innerHTML]="title"></h3>
          <div class="white-divisor"></div>
          <p fxHide.xs fxHide.sm [innerHTML]="description"></p>
          <div fxHide.xs fxHide.sm class="full-width">
            <!--kaltura-player *ngIf="true" [playerVisible]="true" [kalturaScript]="kalturaScript"
              customId="kalturaScript">
            </kaltura-player-->
          </div>
        </ng-container>
        <ng-container *ngIf="isLoadingData">
          <div class="loader-container">
            <div class="loader spinner-medium"></div>
          </div>
        </ng-container>
        <a *ngIf="false" fxHide fxShow.xs fxShow.sm class="white-link" translate="generic.SEE_TUTORIAL"></a>
      </div>
    </div>

    <ng-template #noBack>
      <div class="container-title">
        <h1>facile.it<br>
          <b>Performance Management</b>
        </h1>
        <div class="white-divisor"></div>
      </div>
    </ng-template>

    <div fxHide.xs fxHide.sm class="logo-info-container">
      <div class="logo">
        <svg-icon class="alloy-logo" [applyClass]="true" src="/assets/img/icons/facile-it-logo-white.svg">
        </svg-icon>
      </div>
      <div class="year-info">
        <div class="info">
          <p>©{{currentYear}} Facile.it</p>
        </div>
      </div>
    </div>
  </div>
</div>