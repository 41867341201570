import { TranslateService } from "@ngx-translate/core";

export interface Performer {
  key: string;
  title: string;
  description: string;
  minValue: number;
  maxValue: number;
  imgSrc: string;
  performerValue?: number;
  abbreviation?: string;
};

export class PerformerClass {
  [code: string]: Performer;
}

export class PerformerEvaluatorUtils {
  // Torna la lista dei performer
  static getPerformers(translate: TranslateService, isToDo?: boolean): any {
    if (isToDo) {
      return new Promise<boolean>((resolve, reject) => {
        translate.get(
          [
            'performerEvaluators.TODO.title',
            'performerEvaluators.TODO.description'
          ])
          .subscribe(translations => {
            const performers: any = {
              TODO: {
                key: "TODO",
                title: translations['performerEvaluators.TODO.title'],
                description: translations['performerEvaluators.TODO.description'],
                minValue: 0,
                maxValue: 0,
                imgSrc: "/assets/img/performer-evaluator/todo.svg"
              }
            };
            resolve(performers);
          })
      })
    } else {
      return new Promise<boolean>((resolve, reject) => {
        translate.get(
          [
            'performerEvaluators.LOW.title',
            'performerEvaluators.LOW.description',
            'performerEvaluators.DEVELOPING.title',
            'performerEvaluators.DEVELOPING.description',
            'performerEvaluators.SOLID.title',
            'performerEvaluators.SOLID.description',
            'performerEvaluators.ADVANCE.title',
            'performerEvaluators.ADVANCE.description',
            'performerEvaluators.EXCEPTIONAL.title',
            'performerEvaluators.EXCEPTIONAL.description',
            'performerEvaluators.LOW.abbreviation',
            'performerEvaluators.DEVELOPING.abbreviation',
            'performerEvaluators.SOLID.abbreviation',
            'performerEvaluators.ADVANCE.abbreviation',
            'performerEvaluators.EXCEPTIONAL.abbreviation'
          ])
          .subscribe(translations => {
            const performers: any = {
              LOW: {
                key: "LOW",
                title: translations['performerEvaluators.LOW.title'],
                abbreviation: translations['performerEvaluators.LOW.abbreviation'],
                description: translations['performerEvaluators.LOW.description'],
                minValue: 1,
                maxValue: 1.99,
                imgSrc: "/assets/img/performer-evaluator/low.svg"
              },
              DEVELOPING: {
                key: "DEVELOPING",
                title: translations['performerEvaluators.DEVELOPING.title'],
                abbreviation: translations['performerEvaluators.DEVELOPING.abbreviation'],
                description: translations['performerEvaluators.DEVELOPING.description'],
                minValue: 2,
                maxValue: 2.99,
                imgSrc: "/assets/img/performer-evaluator/developing.svg"
              },
              SOLID: {
                key: "SOLID",
                title: translations['performerEvaluators.SOLID.title'],
                abbreviation: translations['performerEvaluators.SOLID.abbreviation'],
                description: translations['performerEvaluators.SOLID.description'],
                minValue: 3,
                maxValue: 3.49,
                imgSrc: "/assets/img/performer-evaluator/solid.svg"
              },
              ADVANCE: {
                key: "ADVANCE",
                title: translations['performerEvaluators.ADVANCE.title'],
                abbreviation: translations['performerEvaluators.ADVANCE.abbreviation'],
                description: translations['performerEvaluators.ADVANCE.description'],
                minValue: 3.5,
                maxValue: 3.99,
                imgSrc: "/assets/img/performer-evaluator/advance.svg"
              },
              EXCEPTIONAL: {
                key: "EXCEPTIONAL",
                title: translations['performerEvaluators.EXCEPTIONAL.title'],
                abbreviation: translations['performerEvaluators.EXCEPTIONAL.abbreviation'],
                description: translations['performerEvaluators.EXCEPTIONAL.description'],
                minValue: 4,
                maxValue: 5,
                imgSrc: "/assets/img/performer-evaluator/exceptional.svg"
              },
            };
            resolve(performers);
          })
      })
    }
  }
}