<div class="page-content">

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div id="snackbar-delete">
        <p translate="generic.DELETE_SUCCESSFUL"></p>
    </div>

    <ng-container *ngIf="!isLoadingData">

        <!-- Barra di ricerca -->
        <div class="search-filters-container">
            <div class="admin-search-container bottom-filters">
                <div class="search-wrapper">
                    <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                        (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                        [placeholder]="'setupCompetences.SERACH_BY_DICTIONARY' | translate" [isSearchBar]="true">
                    </alloy-input>
                </div>
            </div>
        </div>

        <!-- Aggiungi dizionario -->
        <div class="excel-action-container">
            <div class="excel-container">
                <alloy-button [label]="'setupCompetences.dictionary.IMPORT' | translate" type="primary black-link"
                    (onButtonClicked)="openImportModal()" iconLeft="assets/img/icons/file/xls.svg"
                    [disabled]="isLoadingData">
                </alloy-button>
            </div>
            <div class="action-container">
                <alloy-button [label]="'setupCompetences.dictionary.ADD' | translate" type="black-border"
                    (onButtonClicked)="goToNewDictionary()" [disabled]="isLoadingData" size="auto-width">
                </alloy-button>
            </div>
        </div>


        <!-- Tabella tutte le competenze - Behavior -->
        <div class="admin-table-container">
            <ng-container *ngIf="!isLoadingData">
                <ng-template #noData>
                    <div class="no-result-container">
                        <p translate="generic.NO_RESULTS"></p>
                    </div>
                </ng-template>

                <ng-container *ngIf="dataObject.list && dataObject.list.length; else noData">

                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="{{ ('adminTable.SCALE_TITLE' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.DESCRIPTION' | translate)}}"></th>
                                    <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of dataObject.list | slice:0:dataObject.numRecords | paginate: { id: 'tableData',
                            itemsPerPage: dataObject.numRecords,
                            currentPage: dataObject.page,
                            totalItems: dataObject.counter }">
                                    <td>{{ data.title }}</td>
                                    <td>{{ data.description }}</td>

                                    <td class="action">
                                        <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(data)"
                                            (clickOutside)="closeMenu(data)"
                                            src="/assets/img/icons/more-horizontal.svg">
                                        </svg-icon>
                                        <ng-container *ngIf="data.isMenuOpen">
                                            <div class="dropdown-menu larger">
                                                <ng-container *ngFor="let option of menuOptions">
                                                    <alloy-dropdown-item (click)="onMenuOptionsClicked(option, data)"
                                                        class="full-width" [item]="option">
                                                    </alloy-dropdown-item>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bottom-actions">
                        <table-pagination id="tableData" (pageChanged)="dictionaryPageChanged($event)"
                            [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </alloy-select>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isLoadingData">
                <div class="loader-container">
                    <div class="loader spinner-big"></div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- loader contenuto tab all -->
    <ng-container *ngIf="isLoadingData">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>

<modal id="confirm-cancel" (onClose)="closeDeleteDataModal()" (onConfirm)="closeDeleteDataModal(true)"
    [confirmLabel]="'generic.CONFIRM' | translate" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeDeleteDataModal()">
    <modal-text-content modalId="dicdel001" [title]="'setupCompetences.modals.DELETE_TITLE' | translate"
        [text]="'setupCompetences.modals.DELETE_DESCR' | translate">
    </modal-text-content>
</modal>

<modal id="cannot-delete" (onClose)="closeCannotDeleteModal()" (onConfirm)="closeCannotDeleteModal()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content modalId="cannnot001"
        [title]="'setupCompetences.modals.CANNOT_DELETE_DICTIONARY_TITLE' | translate"
        [text]="cannotDeleteModalDescription">
    </modal-text-content>
</modal>

<!-- Modale inclusione persone -->
<modal id="import-modal" (onClose)="closeImportModal()" (onConfirm)="closeImportModal(true)"
    [confirmLabel]="'generic.IMPORT' | translate">
    <div class="sti-modal-container">
        <p class="title" translate="setupCompetences.modals.IMPORT_DICTIONARY">
        </p>
        <div class="divisor-line"></div>

        <div class="modal-action-container">
            <alloy-button [label]="'setupCompetences.DOWNLOAD_TRACK' | translate" type="success bordless" size="medium"
                (onButtonClicked)="downloadTrack()">
            </alloy-button>
        </div>

        <!-- Upload lista persone da includere -->
        <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
            [title]="'setupCompetences.modals.IMPORT_DICTIONARY_DESCR' | translate">
            <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"
                [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></alloy-upload>
        </input-container>

        <ng-container *ngIf="importFile">
            <ng-container *ngIf="isLoadingImport">
                <div class="loader spinner-medium"></div>
            </ng-container>

            <ng-container *ngIf="!isLoadingImport">
                <p class="text" [innerHTML]="importNotice"></p>
            </ng-container>
        </ng-container>
    </div>
</modal>