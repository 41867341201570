import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SenecaResponse } from 'src/commonclasses';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { Subordinateservice } from '../services/subordinates.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription } from 'rxjs';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GuidedTourService } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as fromPeopleAppraisal from "../ngrx/peopleAppraisal.reducers";
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import * as PeopleAppraisalActions from "../ngrx/peopleAppraisal.actions";
import { AlloyPerformanceCppServiceResponses, CppDevelopmentPlanStatusesClass } from 'atfcore-commonclasses';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cppTeamDetails',
  templateUrl: './cppTeamDetails.component.html',
  styleUrls: ['./cppTeamDetails.component.scss']
})
export class CppTeamDetailsComponent implements OnInit {
  subordinatesUsers: any;
  // Recupero dati subordinato
  isLoadingPersonDetails = false;
  cppSubordinates$: Subscription = new Subscription();
  cppSubordinates: any;
  translations: any;
  stepList: any[] = [];
  currentStep: any;
  // Recupero dei raound
  roundList: any[] = [];
  getRoundList$: Subscription = new Subscription();
  isLoadingRoundList: boolean = false;
  selectedRound: any;
  // Recupero survey
  isLoadingCppSurveyDeteils: boolean = false;
  getCppUserSurveyCompetenceDetails$: Subscription = new Subscription();
  cppSurvey!: any;

  // Modoli survey
  isFetchingCppSurvey: boolean = false;
  currentModule: any;
  selectedAnswers: any[] = [];
  originalSelectedAnswers: any[] = [];

  userId: string = '';
  roundId: string = '';
  // mostra la sidebar
  showSideNav: boolean = false;
  duration: number = 0.25;
  direction: string = 'right';
  // Tutte le opzioni da selezionare 
  intendedGoalOptions: Array<{ id: string, title: string }> = [{ id: '1', title: '2' }];
  // Dettagli cpp step 1
  cppDetails!: any;
  // Lista competenze selezionate 
  selectedCompetenceList: Array<AlloyPerformanceCppServiceResponses.CppCompetenceGap & { isChecked: boolean }> = [];
  // Piano di sviluppo step 2
  developmentPlan: any;
  // Recupero piano di sviluppo
  isLoadingDevelopment: boolean = false;
  getManagerDevelopment$: Subscription = new Subscription();
  // Recupero dettagli utente cpp
  isLoadingCppSubordinateDetails: boolean = false;
  getCppSubordinateDetails$: Subscription = new Subscription();
  // Recuper i commenti cpp
  isLoadingCompetenceCppComments: boolean = false;
  getListCompetenceUpdateCppComments$: Subscription = new Subscription();
  cppCommentList: Array<AlloyPerformanceCppServiceResponses.CppCompetenceUpdateComment> = [];
  // Recupera la lista degli obiettivi
  getlistCppExpectedObjectives$: Subscription = new Subscription();
  isLoadingCppObjectives: boolean = false;
  listCppExpectedObjectives: any;
  // Recupera la lista degli obiettivi
  updateUpdatesOfActionForManager$: Subscription = new Subscription();
  isLoadingUpdateUpdatesOfAction: boolean = false;
  updateUpdatesOfAction: any;

  // obiettivo selezionato
  intendedGoalSelected?: { id: string, title: string };
  // Creazione / Update commenti
  isLoadingCreateUpdateCppComments: boolean = false;
  createUpdateCppComment$: Subscription = new Subscription();
  // listCppActionTypeForManager
  isLoadingListCppActionType: boolean = false;
  getListCppActionType$: Subscription = new Subscription();
  listCppActionType: Array<{ id: string, title: string, other: boolean }> = [];
  selectedCppActionType: any;
  selectedCppActionTypeText: string = '';
  // Download report
  isDownloadingCppReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  // Creazione piano di sviluppo
  isLoadingCreateDevelopmentPlan: boolean = false;
  createDevelopmentPlan$: Subscription = new Subscription();
  // Per visualizzazione testo
  isMoreText: boolean = false;
  // Creazione action
  isLoadingCreateActionOfCompetence: boolean = false;
  createActionOfCompetence$: Subscription = new Subscription();
  scope: string = '';
  // Aggiornamento action
  isLoadingUpdateActionOfCompetence: boolean = false;
  updateActionOfCompetence$: Subscription = new Subscription();

  // Inserimento commenti
  bindCommentCpp: string = '';
  // Eliminazione azione
  isLoadingDeleteActionOfCompetence: boolean = false;
  deleteActionOfCompetence$: Subscription = new Subscription;
  actionId: string = '';

  // pulsante per abilitare la sezione nuova azione
  openNewAction?: { id: string, isOpen: boolean };
  isAlreadyOpen: boolean = false;

  // lista che verrà utilizzata per la modale
  adequacyModalList: Array<string> = ['min', 'medium', 'max', '', 'anomalies'];

  // Date
  yesterday: Date = new Date();
  endDate: Date = new Date();

  // Variabile per capire che si sta aggiornando un'azione
  isUpdateActionId: string = '';

  // Contenuto per la sidebar
  isActionBar: boolean = false;
  selectedCompetenceSideNav: any;
  selectActionSideNav: any;
  updatesSideNavText: string = '';

  // Aggiorna piano di Sviluppo
  isLoadingUpdateDevelopmentPlan: boolean = false;
  updateDevelopmentPlan$: Subscription = new Subscription();
  // Creazione Aggiornamenti competenze
  isLoadingCreateUpdatesOfCompetence: boolean = false;;
  createUpdatesOfCompetence$: Subscription = new Subscription();
  competenceUpdateText: string = '';
  // Creazione Aggiornamenti Azioni
  isLoadingCreateUpdatesOfAction: boolean = false;;
  createUpdatesOfAction$: Subscription = new Subscription();

  comments: any;

  // Dati utente loggato
  loggedUser!: any;
  loggedUser$: Subscription = new Subscription();
  isRoundActive: boolean = false;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private peopleAppraisalStore: Store<fromPeopleAppraisal.PeopleAppraisalState>,
    private sidebarService: SidebarService,
    private appStore: Store<fromApp.AppState>,
    private authService: AuthService,
  ) {
    this.comments = {
      comment: 'text',
      date: new Date()
    };
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.route.params
      .subscribe(
        (params: Params) => {
          this.roundId = params.roundId;
          this.userId = params.userId;
          if (this.userId && this.roundId) {
            this.stepList = [];
            this.developmentPlan = null;
            this.cppDetails = null;
            this.resetVariables();
            this.getCppSubordinateDetails();
            this.getCppPersonDetails();
            this.getCppRounds();
            this.appStore.dispatch(PeopleAppraisalActions.SetActiveUserId({ payload: this.userId }));
          }
        }
      );
    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        'generic.CONFIRM_AND_CONTINUE'
      ])
      .subscribe(translations => {
        this.translations = translations;
      })
  }

  getCppPersonDetails() {
    this.peopleAppraisalStore.dispatch(PeopleAppraisalActions.RetrieveCppSuboardinates({ roundId: this.roundId }));
    this.cppSubordinates$ = this.appStore.select(fromApp.getCppSubordinates).subscribe((cppSubordinates) => {
      this.cppSubordinates = cppSubordinates;
    })
  }


  onStepClicked(step?: any) {
    this.currentStep = step;
    // Reset array competenze selezionate
    this.selectedCompetenceList = [];
    if (step) {
      if (step.stepId == 1) {
        this.getCppSubordinateDetails();
        this.getListCompetenceUpdateCppComments();
      }
      if (step.stepId == 2) {
        if (this.cppDetails?.developmentPlanCreated) {
          // Recupera il plan
          this.getDevelopmentPlan();
        }
        // Recupera i type
        this.listCppActionTypeForManager();
        // Recupera la lista obiettivi
        this.getlistCppExpectedObjectives();
      }
    }
  }

  setStepList(back?: boolean) {
    this.stepList = [];
    this.intendedGoalSelected = undefined;
    this.openNewAction = undefined;
    this.stepList.push(
      {
        id: 'gap-analysis',
        referenceId: '456566454',
        number: 1,
        stepId: 1,
        title: 'Delta analysis',
        isComplete: this.cppDetails?.developmentPlanCreated
      },
      {
        id: 'dev-plan',
        referenceId: '456566454',
        number: 2,
        stepId: 2,
        title: 'Piano di sviluppo',
        isComplete: this.cppDetails?.developmentPlanCreated && (this.cppDetails?.developmentPlanStatus == CppDevelopmentPlanStatusesClass.STARTED || this.cppDetails?.developmentPlanStatus == CppDevelopmentPlanStatusesClass.COMPLETED)
      }
    );

    let findStep = this.stepList.find(el => !el.isComplete);
    const step = findStep ? findStep : this.stepList[1];
    this.currentStep = back ? this.stepList[0] : step ? step : this.stepList[0];
  }

  changeSelectedYear(year: any) {
  }

  // Seleziono competenza
  selectCompetence(competence: any) {
    competence.isChecked = !competence.isChecked;
    this.addRemoveCompetence(competence);
  }

  getButtonConfirmLabel() {
    // Caso in cui mi trovo allo step 1 ma è già completato
    if (this.cppDetails?.developmentPlanCreated && this.currentStep?.id == 'gap-analysis') {
      return this.translate.instant('generic.CONTINUE');
    }
    return this.translate.instant('generic.CONFIRM_AND_CONTINUE');
  }

  getDisabledConfirmButton() {
    let actions: any[] = [];
    for (let i = 0; i < this.developmentPlan?.competences?.length; i++) {
      actions = actions.concat(this.developmentPlan.competences[i].actions);
    }
    if ((this.cppDetails?.developmentPlanCreated && this.currentStep?.id == 'dev-plan')
      && this.intendedGoalSelected?.id && actions.length >= 2
    ) {
      return false;
    }
    // Primo step prima della conferma
    if (((this.selectedCompetenceList.length > 0 && this.selectedCompetenceList.length <=3) || this.cppDetails?.developmentPlanCreated) && this.currentStep?.id == 'gap-analysis') {
      return false;
    }
    return true;
  }

  buttonConfirmActions() {
    if (this.currentStep?.id == 'gap-analysis' && !this.cppDetails?.developmentPlanCreated) {
      this.openConfirmCompetenceCppModal()
    } else if (this.currentStep?.id == 'dev-plan' && this.cppDetails?.developmentPlanCreated) {
      this.openUpdateDevelopmentPlanModal();
    } else {
      this.onStepClicked(this.stepList[1]);
    }
  }

  getDisabledBackButton() {
    if (this.currentStep?.id == 'gap-analysis') {
      return true;
    }
    return false;
  }
  previousStep() {
    this.setStepList(true);
  }
  //Aggiorno la mia lista competenze
  addRemoveCompetence(competence: AlloyPerformanceCppServiceResponses.CppCompetenceGap & { isChecked: boolean }) {
    if (this.selectedCompetenceList && this.selectedCompetenceList.length) {
      if (!competence.isChecked) {
        this.selectedCompetenceList = this.selectedCompetenceList.filter((el: any) => el.competenceId != competence.competenceId);
      } else {
        this.selectedCompetenceList.push(competence);
      }
    } else {
      this.selectedCompetenceList = [competence];
    }
  }
  // Confermo le competenze selezionate
  confirmCompetence() {

  }

  openCompetenceModal() {
    this.modalService.open('competence');
  }
  closeCompetenceModal() {
    this.modalService.close('competence');
  }

  openCompetenceLevelModal() {
    this.modalService.open('competence-level');
  }
  closeCompetenceLevelModal() {
    this.modalService.close('competence-level');
  }

  openConfirmCompetenceCppModal() {
    this.modalService.open('confirm-competence-cpp');
  }
  closeConfirmCompetenceCppModal(confirm?: boolean) {
    if (confirm) {
      this.createDevelopmentPlan();
    }
    this.modalService.close('confirm-competence-cpp');
  }

  openChangesCommentModal() {
    this.modalService.open('changes-comment');
    this.bindCommentCpp = '';
  }
  closeChangesCommentModal(confirm?: boolean) {
    if (confirm) {
      this.createCompetenceUpdateCppComment();
    } else {
      this.modalService.close('changes-comment');
    }
    this.bindCommentCpp = '';
  }


  closeCompetenceDetailModal(confirm?: boolean) {
    this.modalService.close('competence-detail');
  }

  descriptionChanged(text: string) {
    this.bindCommentCpp = text;
  }
  openCompetenceDetailModal(surveyId: string) {
    this.getCppUserSurveyCompetenceDetails(surveyId)
    this.modalService.open('competence-detail');
  }

  getCppUserSurveyCompetenceDetails(surveyId: string) {
    this.isLoadingCppSurveyDeteils = true;

    if (this.getCppUserSurveyCompetenceDetails$) {
      this.getCppUserSurveyCompetenceDetails$.unsubscribe();
    }

    this.getCppUserSurveyCompetenceDetails$ = this.peopleAppraisalService.getCppUserSurveyCompetenceDetails(this.userId, this.roundId, surveyId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.cppSurvey = data.response[0];
          if (this.cppSurvey?.questions && this.cppSurvey.questions.length) {
            this.selectedAnswers = [];
            this.originalSelectedAnswers = [];
            for (let i = 0; i < this.cppSurvey.questions.length; i++) {
              let question = this.cppSurvey.questions[i];
              let header = question.text.substring(0, question.text.indexOf('\n'));
              let footer = question.text.substring(question.text.lastIndexOf('\n'), question.text.length);
              footer = footer.replaceAll('\n', '');
              let list = question.text.substring(question.text.indexOf('\n\n'), question.text.lastIndexOf('\n'));
              list = list.substring(1);
              list = list.replaceAll('\n', '<br>');

              question.text = list?.length ? '<b>' + header + '</b><br>' + list + '<b><br>' + footer + '*</b>' :
                '<b>' + header + '</b><br><br>' + '<b>' + footer + '*</b>';

              let answersRadioList: any[] = [];
              question.answers.forEach((x: any, index: number) => {
                answersRadioList.push({
                  id: index,
                  value: x
                })
              })
              question.answersRadioList = answersRadioList;
              if (question.selectedAnswerIndex != null && question.selectedAnswerIndex != undefined) {
                this.selectedAnswers.push(
                  {
                    questionId: question.questionId,
                    selectedAnswerIndex: question.selectedAnswerIndex
                  }
                );
                question.selectedAnswer = question.answersRadioList.filter((x: any) => {
                  return x.id == question.selectedAnswerIndex;
                })[0];
              }
            }
            this.originalSelectedAnswers = JSON.parse(JSON.stringify(this.selectedAnswers));
          }
          this.isLoadingCppSurveyDeteils = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppSurveyDeteils = false;
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppSurveyDeteils = false;
        }
      );
  }
  createCompetenceUpdateCppComment() {
    this.isLoadingCreateUpdateCppComments = true;

    if (this.createUpdateCppComment$) {
      this.createUpdateCppComment$.unsubscribe();
    }

    this.createUpdateCppComment$ = this.peopleAppraisalService.createCompetenceUpdateCppComment(this.roundId, this.userId, this.bindCommentCpp)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateUpdateCppComments = false;
        } else {
          this.getListCompetenceUpdateCppComments();
          this.isLoadingCreateUpdateCppComments = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateUpdateCppComments = false;
      })
  }

  openUpdateDevelopmentPlanModal() {
    this.modalService.open('updateDevPlan');
  }

  closeUpdateDevelopmentPlanModal(confirm?: boolean) {
    if (confirm) {
      this.updateDevelopmentPlan();
    }
    this.modalService.close('updateDevPlan');
  }
  updateDevelopmentPlan() {
    this.isLoadingUpdateDevelopmentPlan = true;

    if (this.updateDevelopmentPlan$) {
      this.updateDevelopmentPlan$.unsubscribe();
    }

    let actions: any[] = [];
    for (let i = 0; i < this.developmentPlan.competences?.length; i++) {
      actions = actions.concat(this.developmentPlan.competences[i].actions);
    }

    this.updateDevelopmentPlan$ = this.peopleAppraisalService.updateDevelopmentPlanForManager(this.userId, this.roundId, actions, this.intendedGoalSelected?.title, this.developmentPlan.competences)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateDevelopmentPlan = false;
        } else {
          this.getCppSubordinateDetails();
          this.getListCompetenceUpdateCppComments();
          this.isLoadingUpdateDevelopmentPlan = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateDevelopmentPlan = false;
      })
  }

  openCreateUpdateModal() {
    this.createUpdatesOfCompetence();
    // this.modalService.open('update-comment');
  }

  closeCreateUpdateModal() {
    this.modalService.close('update-comment');
    this.createUpdatesOfCompetence();
  }
  createUpdatesOfCompetence() {
    this.isLoadingCreateUpdatesOfCompetence = true;

    if (this.createUpdatesOfCompetence$) {
      this.createUpdatesOfCompetence$.unsubscribe();
    }

    let updates: Array<{
      updateText: string
    }> = [{ updateText: this.updatesSideNavText }];

    this.createUpdatesOfCompetence$ = this.peopleAppraisalService.createUpdatesOfCompetenceForManager(this.userId, this.roundId, this.selectedCompetenceSideNav.competenceId, updates)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateUpdatesOfCompetence = false;
        } else {
          this.onSidebar(false);
          if (this.cppDetails?.developmentPlanCreated) {
            // Recupera il plan
            this.getDevelopmentPlan();
          }
          // Recupera i type
          this.listCppActionTypeForManager();
          // Recupera la lista obiettivi
          this.getlistCppExpectedObjectives();
          this.getListCompetenceUpdateCppComments();
          this.showSaveSuccessfulToaster();
          this.isLoadingCreateUpdatesOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateUpdatesOfCompetence = false;
      })
  }
  createUpdatesOfAction(action: any) {
    this.isLoadingCreateUpdatesOfAction = true;

    if (this.createUpdatesOfAction$) {
      this.createUpdatesOfAction$.unsubscribe();
    }

    let _action = { ...action, updates: [{ updateText: this.updatesSideNavText }] }

    this.createUpdatesOfAction$ = this.peopleAppraisalService.createUpdatesOfActionForManager(this.userId, this.roundId, _action)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateUpdatesOfAction = false;
        } else {
          this.onSidebar(false);
          if (this.cppDetails?.developmentPlanCreated) {
            // Recupera il plan
            this.getDevelopmentPlan();
          }
          // Recupera i type
          this.listCppActionTypeForManager();
          // Recupera la lista obiettivi
          this.getlistCppExpectedObjectives();
          this.getListCompetenceUpdateCppComments();
          this.showSaveSuccessfulToaster();
          this.isLoadingCreateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateUpdatesOfAction = false;
      })
  }
  listCppActionTypeForManager() {
    this.isLoadingListCppActionType = true;

    if (this.getListCppActionType$) {
      this.getListCppActionType$.unsubscribe();
    }

    this.getListCppActionType$ = this.peopleAppraisalService.listCppActionTypeForManager()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.listCppActionType = [];
          for (let i = 0; i < data.response.length; i++) {
            this.listCppActionType.push(
              { id: data.response[i][0] + i, title: data.response[i], other: data.response[i] == 'Altro (specificare)' }
            )
          }
          this.isLoadingListCppActionType = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingListCppActionType = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingListCppActionType = false;
      })
  }

  onRadioSelect(event: any, questionId: string) {
    let indexToDelete = this.selectedAnswers.findIndex(object => {
      return object.questionId === questionId;
    });

    if (indexToDelete != -1 && indexToDelete != null && indexToDelete != undefined) {
      this.selectedAnswers.splice(indexToDelete, 1);
    }

    this.selectedAnswers.push({
      questionId: questionId,
      selectedAnswerIndex: event.id
    })
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbarPersonDetails");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }
  createActionOfCompetence(competenceId: string) {
    this.isLoadingCreateActionOfCompetence = true;

    if (this.createActionOfCompetence$) {
      this.createActionOfCompetence$.unsubscribe();
    }
    let actions = [{
      competenceId: competenceId,
      type: this.selectedCppActionTypeText.length ? this.selectedCppActionTypeText : this.selectedCppActionType?.title,
      scope: this.scope,
      endDate: this.endDate
    }]

    this.createActionOfCompetence$ = this.peopleAppraisalService.createActionOfCompetenceForManager(this.userId, this.roundId, actions)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.resetVariables();
          this.getDevelopmentPlan();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateActionOfCompetence = false;
      })
  }
  resetVariables() {
    // Reset 
    this.selectedCppActionTypeText = '';
    this.selectedCppActionType = {};
    this.scope = '';
    this.isMoreText =false;
    this.endDate = new Date();
    this.isLoadingCreateActionOfCompetence = false;
  }
  updateActionOfCompetenceForManager(actionid: string, competence: any) {
    this.isLoadingUpdateActionOfCompetence = true;

    if (this.updateActionOfCompetence$) {
      this.updateActionOfCompetence$.unsubscribe();
    }
    let actions = {
      actionId: actionid,
      competenceId: competence.competenceId,
      type: this.selectedCppActionTypeText.length ? this.selectedCppActionTypeText : this.selectedCppActionType?.title,
      scope: this.scope,
      endDate: this.endDate
    }

    this.updateActionOfCompetence$ = this.peopleAppraisalService.updateActionOfCompetenceForManager(this.userId, this.roundId, actions)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateActionOfCompetence = false;
        } else {
          this.getDevelopmentPlan();
          this.isLoadingUpdateActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateActionOfCompetence = false;
      })
  }

  changeScope(text: string) {
    this.scope = text;
  }
  getCppRounds() {
    this.isLoadingRoundList = true;

    if (this.getRoundList$) {
      this.getRoundList$.unsubscribe();
    }

    this.getRoundList$ = this.peopleAppraisalService.getCppRounds(true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.roundList = data.response;
          this.selectedRound = this.roundList.find(el => el.roundId == this.roundId);
          this.isLoadingRoundList = false;
          this.isRoundActive = this.selectedRound?.roundStatus == 'RUNNING'
          this.store.dispatch(CoreActions.SetRound({ payload: this.selectedRound }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundList = false;
        }

      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoundList = false;
      })
  }

  changeSelectedRound(round: any) {
    this.selectedRound = round;
    this.roundId = this.selectedRound.roundId;
    this.router.navigate(['peopleAppraisal/cppDetails/' + this.selectedRound.roundId + '/' + this.userId])
  }

  downloadCppReport() {
    this.isDownloadingCppReport = true;

    this.peopleAppraisalService.downloadCppReport(this.roundId, this.userId)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingCppReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingCppReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingCppReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingCppReport = false;
          })
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingCppReport = false;
      })
  }

  endDateChanged(endDate: any) {
    this.endDate = endDate;
  }
  createDevelopmentPlan() {
    this.isLoadingCreateDevelopmentPlan = true;

    if (this.createDevelopmentPlan$) {
      this.createDevelopmentPlan$.unsubscribe();
    }
    // Recupero gli ids delle competenze selezionate
    let ids: Array<string> = [];
    for (let i = 0; i < this.selectedCompetenceList.length; i++) {
      ids.push(this.selectedCompetenceList[i].competenceId);
    }

    this.createDevelopmentPlan$ = this.peopleAppraisalService.createDevelopmentPlanForManager(this.roundId, this.userId, ids)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.setStepList();
          this.currentStep = this.stepList[1];
          this.getCppSubordinateDetails();
          this.isLoadingCreateDevelopmentPlan = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateDevelopmentPlan = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateDevelopmentPlan = false;
      })
  }

  async onSidebar(open: boolean, surveyId?: string, competence?: any, action?: any, actionIndex?: number) {
    this.updatesSideNavText = '';
    if (surveyId) {
      await this.getCppUserSurveyCompetenceDetails(surveyId)
    }
    if (action) {
      for (let i = 0; i < action.actionUpdates.length; i++) {
        action.actionUpdates[i] = { ...action.actionUpdates[i], isOpen: false }
      }
      this.selectActionSideNav = { ...action, index: actionIndex };
    }
    if (competence) {
      this.selectedCompetenceSideNav = JSON.parse(JSON.stringify(competence));
    }
    this.showSideNav = open;
    this.isActionBar = surveyId ? false : true;
    // Quando una sidebar si apre, blocco lo scroll al <body> dell'html affinché la pagina dell'applicazione non sia più scrollabile
    const appBody = document.getElementsByTagName("body") && document.getElementsByTagName("body")[0];
    if (appBody) {
      appBody.style.overflowY = this.showSideNav ? "scroll" : "visible";
      appBody.style.position = this.showSideNav ? "fixed" : "static";
    }

  }
  changeUpdatesSideNavText(text: string) {
    this.updatesSideNavText = text;
  }
  changeUpdatesActionText(text: string, update: any) {
    update.description = text;
  }

  getSideNavBarStyle(showNav: boolean | null) {
    let navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = window.innerWidth + 'px';
    navBarStyle.maxWidth = (660 + 'px');
    navBarStyle[this.direction] = (showNav ? 0 : (window.innerWidth * -1)) + 'px';

    return navBarStyle;
  }

  getDevelopmentPlan() {
    this.isLoadingDevelopment = true;

    if (this.getManagerDevelopment$) {
      this.getManagerDevelopment$.unsubscribe();
    }

    this.getManagerDevelopment$ = this.peopleAppraisalService.getDevelopmentPlan(this.userId, this.roundId, true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.developmentPlan = data.response;
          this.getActionMap();
          // Faccio il Map del gap che mi arriva nello step 1 perchè non ha senso rifare la complessa logica a BE
          this.getGapCompetence();
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingDevelopment = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDevelopment = false;
      })
  }


  getCppSubordinateDetails() {
    this.isLoadingCppSubordinateDetails = true;

    if (this.getCppSubordinateDetails$) {
      this.getCppSubordinateDetails$.unsubscribe();
    }

    this.getCppSubordinateDetails$ = this.peopleAppraisalService.getCppSubordinateDetails(this.roundId, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          // Assegno i dettagli cpp 
          this.cppDetails = data.response;
          // Contollo lo status del subordinato
          let back = this.stepList[0]?.isComplete;
          this.setStepList(back);
          if (this.cppDetails?.developmentPlanCreated) {
            // Recupera il plan
            this.getDevelopmentPlan();
            // Recupera i type
            this.listCppActionTypeForManager();
            // Recupera la lista obiettivi
            this.getlistCppExpectedObjectives();
          }
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingCppSubordinateDetails = false;
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppSubordinateDetails = false;
      })
  }

  // Map delle actions 
  getActionMap() {
    for (let p = 0; p < this.developmentPlan.competences.length; p++) {
      let res: Array<{ actionId: string, competenceId: string, isOpen: boolean, type: string, scope: string, date: Date, actionUpdates: Array<any> }> = [];
      let type: Array<string> = [];
      let scope: Array<string> = [];
      let actions: Array<any> = [];
      actions = this.developmentPlan.competences[p]?.actions;
      if (actions?.length) {
        for (let i = 0; i < actions?.length; i++) {
          let actionAttributes = actions[i].actionAttributes;
          for (let j = 0; j < actionAttributes.length; j++) {
            type[i] = type[i] ? type[i] : actionAttributes[j].type == 'CPP_ACTION_TYPE' ? actionAttributes[j].value : '';
            scope[i] = scope[i] ? scope[i] : actionAttributes[j].type == 'CPP_ACTION_SCOPE' ? actionAttributes[j].value : '';
          }
          res.push({
            actionId: this.developmentPlan.competences[p].actions[i].actionId,
            competenceId: this.developmentPlan.competences[p].competenceId,
            isOpen: false,
            type: type[i],
            scope: scope[i],
            date: actions[i].endDate,
            actionUpdates: actions[i].actionUpdates
          })
        }
      }
      this.developmentPlan.competences[p].actions = res;
    }
  }


  // Map del gap per essere usato nello step 2
  getGapCompetence() {
    let ids: Array<string> = [];
    //Cerco le mie tre competenze 
    for (let i = 0; i < this.developmentPlan.competences.length; i++) {
      // Assegno il gap
      for (let k = 0; k < this.cppDetails.competencesGap.length; k++) {
        if (this.cppDetails.competencesGap[k].competenceId == this.developmentPlan.competences[i].referenceId) {
          this.developmentPlan.competences[i].gap = this.cppDetails.competencesGap[k].delta;
          this.developmentPlan.competences[i].possessedPerc = this.cppDetails.competencesGap[k].possessedPerc;
        }
      }
    }
  }

  deleteActionOfCompetence(actionId: string) {
    this.isLoadingDeleteActionOfCompetence = true;

    if (this.deleteActionOfCompetence$) {
      this.deleteActionOfCompetence$.unsubscribe
    }

    this.deleteActionOfCompetence$ = this.peopleAppraisalService.deleteActionOfCompetenceForManager(this.userId, actionId, this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.resetVariables();
          this.onStepClicked(this.currentStep);
          this.isLoadingDeleteActionOfCompetence = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDeleteActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteActionOfCompetence = false;
      })
  }

  getListCompetenceUpdateCppComments() {
    this.isLoadingCompetenceCppComments = true;

    if (this.getListCompetenceUpdateCppComments$) {
      this.getListCompetenceUpdateCppComments$.unsubscribe();
    }

    this.getListCompetenceUpdateCppComments$ = this.peopleAppraisalService.getListCompetenceUpdateCppComments(this.roundId, this.userId)
      .subscribe((data: SenecaResponse<Array<AlloyPerformanceCppServiceResponses.CppCompetenceUpdateComment>>) => {
        if (data && data.response) {
          // Assegno solo i commenti
          this.cppCommentList = data.response;
          this.isLoadingCompetenceCppComments = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetenceCppComments = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCompetenceCppComments = false;
      })
  }

  getlistCppExpectedObjectives() {
    this.isLoadingCppObjectives = true;

    if (this.getlistCppExpectedObjectives$) {
      this.getlistCppExpectedObjectives$.unsubscribe();
    }

    this.getlistCppExpectedObjectives$ = this.peopleAppraisalService.listCppExpectedObjectivesForManager()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.listCppExpectedObjectives = [];
          // Assegno gli obiettiv
          for (let i = 0; i < data.response.length; i++) {
            this.listCppExpectedObjectives.push({
              id: data.response[i][0] + i,
              title: data.response[i]
            })
          }
          this.isLoadingCppObjectives = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppObjectives = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppObjectives = false;
      })
  }

  // openNewActionView(competenceId: string) {
  //   for (let index = 0; index < this.openNewActionList.length; index++) {
  //     if (this.openNewActionList[index]?.id == competenceId && this.openNewActionList[index]?.isOpen) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  async openNewActionClicked(id: string) {
    this.openNewAction = undefined;
    this.openNewAction = { id: id, isOpen: true };
    this.isAlreadyOpen = true;

    setTimeout(() => {
      const newActionElement = document.querySelector("#edit-action");
      if (newActionElement) {
        newActionElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      }
    }, 150)
  }
  saveAction(close?: boolean, updateAction?: boolean, action?: any, competenceId?: string) {

    if (action) {
      action.isOpen = false;
      if (updateAction) {
        const competenceId = this.developmentPlan.competences.find((competence: any) => competence.actions.find((el: any) => el.actionId == action.actionId));
        this.updateActionOfCompetenceForManager(action.actionId, competenceId);
      }
      this.isAlreadyOpen = false;
    }

    if (close) {
      this.openNewAction = undefined;
      this.isAlreadyOpen = false;
      this.resetVariables();
    }
    if (competenceId) {
      this.createActionOfCompetence(competenceId);
      this.openNewAction = undefined;
      this.isAlreadyOpen = false;
      this.resetVariables();
    }
  }

  menuAction(item: any, competenceId: string, action: any) {
    // this.actionId = action.actionId;
    if (item && item.id == 'edit') {
      this.isAlreadyOpen = true;
      if (action) {
        action.isOpen = true;
      }
      if (this.isAlreadyOpen) {
        // this.saveAction(competenceId, true)
      }
      let findSelectType = this.listCppActionType.find(el => el.title == action.type);
      this.isMoreText = !findSelectType;
      this.selectedCppActionType = { id: action.type[0], title: findSelectType ? action.type : 'Altro (specificare)', other: !findSelectType ? true : false }
      this.selectedCppActionTypeText = findSelectType ? '' : action.type;
      this.scope = action.scope;
      this.endDate = action.date;
      // this.openNewActionClicked(competenceId)
      // this.isUpdateActionId = action.actionId;
    }
    if (item && item.id == 'delete' && !(this.stepList[1]?.isComplete)) {
      this.actionId = action ? action.actionId : '';
      this.modalService.open('delete-action')
    }
  }

  menuActionSideNav(item: any, update: any) {
    if (item && item.id == 'edit') {
      if (update) {
        update.isOpen = true;
      }
    } else {
    }
  }

  updateUpdatesOfActionForManager(action: any, update: any) {
    this.isLoadingUpdateUpdatesOfAction = true;

    if (this.updateUpdatesOfActionForManager$) {
      this.updateUpdatesOfActionForManager$.unsubscribe();
    }
    let _action = { ...action, updates: [{ updateText: update.description, updateId: update.noteId }] }
    this.updateUpdatesOfActionForManager$ = this.peopleAppraisalService.updateUpdatesOfActionForManager(this.userId, this.roundId, _action)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.listCppExpectedObjectives = [];
          // Assegno gli obiettiv
          for (let i = 0; i < data.response.length; i++) {
            this.onSidebar(false);
            if (this.cppDetails?.developmentPlanCreated) {
              // Recupera il plan
              this.getDevelopmentPlan();
            }
            // Recupera i type
            this.listCppActionTypeForManager();
            // Recupera la lista obiettivi
            this.getlistCppExpectedObjectives();
            this.getListCompetenceUpdateCppComments();
            this.showSaveSuccessfulToaster();
          }
          this.isLoadingUpdateUpdatesOfAction = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateUpdatesOfAction = false;
      })
  }

  updateUpdatesOfCompetenceForManager(action?: any, update?: any) {
    this.isLoadingUpdateUpdatesOfAction = true;

    if (this.updateUpdatesOfActionForManager$) {
      this.updateUpdatesOfActionForManager$.unsubscribe();
    }

    let updatesCompetence: any[] = [];
    for (let i = 0; i < action.competenceUpdates.length; i++) {
      updatesCompetence.push({
        updateId: action.competenceUpdates[i].noteId,
        updateText: action.competenceUpdates[i].description
      });
    }

    this.updateUpdatesOfActionForManager$ = this.peopleAppraisalService.updateUpdatesOfCompetenceForManager(this.userId, this.roundId, action.competenceId, updatesCompetence)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.listCppExpectedObjectives = [];
          // Assegno gli obiettiv
          for (let i = 0; i < data.response.length; i++) {
            this.onSidebar(false);
            if (this.cppDetails?.developmentPlanCreated) {
              // Recupera il plan
              this.getDevelopmentPlan();
            }
            // Recupera i type
            this.listCppActionTypeForManager();
            // Recupera la lista obiettivi
            this.getlistCppExpectedObjectives();
            this.getListCompetenceUpdateCppComments();
            this.showSaveSuccessfulToaster();
          }
          this.isLoadingUpdateUpdatesOfAction = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateUpdatesOfAction = false;
      })
  }

  actionBtnForSideNav(confirm: boolean, action: any, update: any, isCompetenceUpdate?: boolean) {
    if (confirm) {
      if (isCompetenceUpdate) {
        this.updateUpdatesOfCompetenceForManager(action, update)
      } else {
        this.updateUpdatesOfActionForManager(action, update)
      }
    } else {
      this.updatesSideNavText = '';
    }
    update.isOpen = false;
  }

  closeDeleteActionModal(confirm?: boolean) {
    this.modalService.close('delete-action');
    if (confirm) {
      this.deleteActionOfCompetence(this.actionId);
    }
  }



  changeIntendedGoal(selected: any) {
    this.intendedGoalSelected = selected;
  }
  changeTypeCompetence(typeSelected: any) {
    this.selectedCppActionTypeText = '';
    this.selectedCppActionType = typeSelected;
    // Id impostato al recupero dei dati
    if (typeSelected.other) {
      this.isMoreText = true;
    } else {
      this.isMoreText = false;
    }
  }

  // Recupero 
  getCompetenceGap(competenceIds: Array<string>) {

  }

  changeSelectedCppTypeText(text: string) {
    this.selectedCppActionTypeText = text;
  }
  changePurposeText() {
    // this.purposeActionText
  }

  // Toggle della sidebar
  toggleSidebar() {
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }
}