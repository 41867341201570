<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="(roundId ? 'upwardFeedback.admin.crudRound.SIDENAV_TITLE_UPDATE' : 'upwardFeedback.admin.crudRound.SIDENAV_TITLE_CREATE') | translate"
            [description]="'upwardFeedback.admin.crudRound.SIDENAV_DESCR' | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingRound && roundData">
                <div class="form-container">
                    <!-- Nome del round -->
                    <input-container [isObbligatory]="true" [isValued]="roundData.name && roundData.name.trim().length"
                        [isObbligatory]="true" title="{{'upwardFeedback.admin.crudRound.ROUND_NAME' | translate}}"
                        [readonly]="isUpdate">
                        <alloy-input [bindValue]="roundData.name" (onModelChanged)="nameChanged($event)"
                            placeholder="{{'upwardFeedback.admin.crudRound.ROUND_NAME_PLH' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!-- Data di avvio -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [isValued]="roundData.startDate"
                        [disabled]="roundData.selectedStatus?.id == 'RUNNING'" [isObbligatory]="true"
                        title="{{'upwardFeedback.admin.crudRound.START_DATE' | translate}}">
                        <alloy-datepicker [minDate]="today" [value]="roundData.startDate"
                            (dateChanged)="startDateChanged($event)"
                            [disabled]="roundData.selectedStatus?.id == 'RUNNING'"
                            placeholder="{{'upwardFeedback.admin.crudRound.DATE_PLH' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- Data di chiusura -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [isValued]="roundData.endDate"
                        [isObbligatory]="true" title="{{'upwardFeedback.admin.crudRound.END_DATE' | translate}}">
                        <alloy-datepicker [minDate]="roundData.startDate || today" [value]="roundData.endDate"
                            (dateChanged)="endDateChanged($event)"
                            placeholder="{{'upwardFeedback.admin.crudRound.DATE_PLH' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- Stato del round -->
                    <input-container [isObbligatory]="true" [readonly]="!roundId" [isValued]="roundData.selectedStatus"
                        [isObbligatory]="true" title="{{'upwardFeedback.admin.crudRound.ROUND_STATUS' | translate}}">
                        <alloy-select (onSelectedItem)="changeStatus($event)" [selectedItem]="roundData.selectedStatus"
                            [items]="statusOptions" bindLabel="title"></alloy-select>
                    </input-container>

                    <div class="action-container">
                        <alloy-button [label]="'generic.CANCEL' | translate" type="success bordless"
                            (onButtonClicked)="onCancel()"></alloy-button>
                        <alloy-button (onButtonClicked)="createUpdate()"
                            [disabled]="isLoadingRound || !(roundData && roundData.startDate) || !(roundData && roundData.endDate)"
                            type="success default" size="large"
                            [label]="(roundId && roundId.length) ? ('upwardFeedback.admin.crudRound.UPDATE' | translate) : ('upwardFeedback.admin.crudRound.CREATE' | translate)">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingRound">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="create-round" (onClose)="closeCreateRound()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeCreateRound(true)" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeCreateRound()">
    <modal-text-content [title]="'upwardFeedback.admin.crudRound.CREATE' | translate"
        [text]="'upwardFeedback.admin.crudRound.CREATE_DESCR' | translate"></modal-text-content>
</modal>

<modal id="update-round" (onClose)="closeUpdateRound()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeUpdateRound(true)" [cancelLabel]="'generic.CANCEL' | translate" (onCancel)="closeUpdateRound()">
    <modal-text-content [title]="'upwardFeedback.admin.crudRound.UPDATE' | translate"
        [text]="'upwardFeedback.admin.crudRound.UPDATE_DESCR' | translate"></modal-text-content>
</modal>