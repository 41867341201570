<div class="adequacyWrapper" *ngIf="status">
    <div class="adequacyIndicator">
        <div class="adequacyIndicator__notAchieve">
            <svg-icon *ngIf="status == 'min'" class="active" [applyClass]="true"></svg-icon>
        </div>
        <div class="adequacyIndicator__partially">
            <svg-icon *ngIf="status == 'medium'" class="active" [applyClass]="true"></svg-icon>
        </div>
        <div class="adequacyIndicator__achieve">
            <svg-icon *ngIf="status == 'max'" class="active" [applyClass]="true"></svg-icon>
        </div>
    </div>
</div>

<svg-icon *ngIf="!status" src="assets/img/icons/adeguacy-index-empty.svg">