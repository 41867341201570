<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations></sidenav-informations>
  </div>

  <div class="main-content">
    <div class="page-content">
      <svg-icon class="logo-container" [applyClass]="true" src="/assets/img/icons/app-logo.svg">
      </svg-icon>
      <p>
        <span class="small-font-weight" translate="404.WE_ARE_SORRY"></span><br>
        <span *ngIf="!noValidProcess" translate="genericError.PROBLEM_FOUND" class="bold-text"></span>
        <span *ngIf="noValidProcess" translate="genericError.NO_PROCESS_AVAILABLE" class="bold-text"></span>
      </p>
      <div>
        <p *ngIf="!noValidProcess" translate="genericError.TRY_AGAIN_LATER"></p>
        <p *ngIf="noValidProcess" translate="genericError.NO_PROCESS_AVAILABLE_DESCR"></p>
        <div class="error-code-container" *ngIf="errorCode && errorCode.length">
          <p><span class="bold" translate="genericError.ERROR_CODE"></span> {{errorCode}}</p>
        </div>
      </div>
      <div class="back-button-container">
        <alloy-button type="success default" size="large" label="{{'generic.GO_IN_HOME' | translate}}"
          (onButtonClicked)="goToHome()"></alloy-button>
      </div>
    </div>
  </div>
</page-container>