import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})

export class StepperComponent implements OnInit {
  @Input() currentStatus?: any;
  @Input() statuses: any[] = [];
  @Input() date?: string = '';
  @Input() dots?= 5;
  // @Input() Left?: string = "Left";
  // @Input() Center?: string = "Center";
  // @Input() Right?: string = "Right";

  @Output() stepClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  indexOfStatus(status?: any, currentStatus?: any) {
    if (status && this.statuses && this.statuses.length) {
      for (let i = 0; i < this.statuses.length; i++) {
        if (status.id == this.statuses[i].id) {
          return i;
        }
      }
      return -1;
    } else if (currentStatus && this.statuses && this.statuses.length) {
      for (let i = 0; i < this.statuses.length; i++) {
        if (currentStatus.id == this.statuses[i].id) {
          return i;
        }
      }
      return -1;
    } else {
      return -1;
    }
  }

  onStepClicked(status: any) {
    this.stepClicked.emit(status);
  }

  showLabel(isActive: boolean) {
    if (isActive) {
      return true;
    } else {
      if (window.innerWidth >= 960) {
        return true;
      }
    }
    return false;
  }

}

