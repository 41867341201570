<div class="recap-wrapper">
  <div class="section">
    <avatar-img [user]="user" size="medium"></avatar-img>
  </div>
  <div class="section" *ngIf="user">
    <p class="name"> {{ user.forename + ' ' + user.surname }}</p>
    <div class="status" *ngIf="status">
      <svg-icon class="status-icon" [src]="MID_STATUS_SRC[status]" [applyClass]="true"></svg-icon>
      <p translate="midTerm.statuses.{{ status }}"></p>
    </div>
    <p class="date" *ngIf="date">{{ ('midTerm.REVIEWED_IN' | translate) + ' ' + date }}</p>
    <p class="observation" [innerHtml]="observation"></p>
  </div>
</div>