import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
    selector: 'app-selfAssessment-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class SelfAssessmentHomeComponent implements OnInit {


    constructor(
        public translate: TranslateService,
        public redirectService: RedirectService,
        private router: Router
    ) {
        this.router.navigate(['../homepage']);

    }

    ngOnInit() { }

}