import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
@Component({
  selector: 'app-cppTeam',
  templateUrl: './cppTeam.component.html',
  styleUrls: ['./cppTeam.component.scss']
})
export class CppTeamComponent implements OnInit {
  runningYear: number = 0;
  loggedUser: any;
  roundId: string = '';
  isLoadingCppRounds: boolean = false;
  translations: any;
  cppRounds: any;
  selectedRound: any;
  cppUsersCounter: number = 0;
  isLoadingCppUsers: boolean = false;
  cppUsers: any;

  // lista che verrà utilizzata per la modale
  adequacyModalList: Array<string> = ['min', 'medium', 'max', 'anomalies'];

  // Recupero utenti CPP
  isLoadingCppSubordinates: boolean = false;
  cppSubordinates$: Subscription = new Subscription;
  cppSubordinates: any = [];


  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService,
    private modalService: ModalService,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,
    public redirectService: RedirectService) {
    this.store.select(fromApp.getLoggedUser)
      .subscribe((loggedUser: any) => {
        this.loggedUser = loggedUser && loggedUser.user;
        this.route.params
          .subscribe((params: Params) => {
            if (params.roundId) {
              this.roundId = params.roundId;
              this.getMyCppSubordinates();
            }
          });
      });
  }

  ngOnInit(): void {
    this.translate.get([
      'cppCollaborators.NOT_STARTED',
      'cppCollaborators.NEW',
      'cppCollaborators.COMPLETED',
      'cppCollaborators.IN_PROGRESS'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.getCppRounds();
    })
  }

  getMyCppSubordinates() {
    this.isLoadingCppSubordinates = true;

    if (this.cppSubordinates$) {
      this.cppSubordinates$.unsubscribe();
    }

    this.cppSubordinates$ = this.peopleAppraisalService.getMyCppSubordinates(this.roundId, true)
      .subscribe((data: any) => {
        if (data && data.response) {
          this.cppSubordinates = data.response;

          this.isLoadingCppSubordinates = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppSubordinates = false;
        }

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppSubordinates = false;
      });
  }


  getCppRounds() {
    this.isLoadingCppRounds = true;

    this.peopleAppraisalService.getCppRounds(this.loggedUser.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppRounds = false;
        } else {
          this.cppRounds = data.response;

          if (this.cppRounds?.length) {
            for (const round of this.cppRounds) {
              if (round.roundStatus == "RUNNING") {
                this.selectedRound = round;
                break;
              }
            }

            if (this.selectedRound) {
              this.getCppUsers();
            }
          }

          this.isLoadingCppRounds = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppRounds = false;
      });
  }

  getCppUsers() {
    // this.isLoadingCppUsers = true;

    // this.peopleAppraisalService.countCppUsers(this.roundId)
    //   .pipe(
    //     switchMap(
    //       (counter: SenecaResponse<number>) => {
    //         if (counter.error) {
    //           // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
    //           return of(new SenecaResponse(counter.error, null))
    //         } else {
    //           // Salvo il counter
    //           this.cppUsersCounter = counter.response;

    //           if (this.cppUsersCounter) {
    //             return this.peopleAppraisalService.getCppUsers(this.roundId)
    //           } else {
    //             // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
    //             return of(new SenecaResponse(null, []));
    //           }
    //         }
    //       }
    //     ), catchError((err, caught) => {
    //       if (err && err.message) {
    //         // Vedo se c'è la traduzione dell'errore
    //         const messageObj: ApplicationModalMessage = {
    //           modalId: "a002",
    //           text: this.translate.instant("errors." + ((err && err.message) || err)),
    //           title: this.translate.instant("generic.WARNING")
    //         }
    //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //       }
    //       this.isLoadingCppUsers = false;
    //       // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
    //       return throwError(new Error(err.message));
    //     }),
    //     take(1)
    //   ).subscribe(
    //     (data: SenecaResponse<any>) => {
    //       if (data.error) {
    //         // Vedo se c'è la traduzione dell'errore
    //         const messageObj: ApplicationModalMessage = {
    //           modalId: "a003",
    //           text: this.translate.instant("errors." + data.error),
    //           title: this.translate.instant("generic.WARNING")
    //         }
    //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //       } else {
    //         this.cppUsers = data.response;
    //         if (this.cppUsers?.length) {
    //           for (let i = 0; i < this.cppUsers.length; i++) {
    //             this.cppUsers[i].developmentPlanStatusText = this.translations["cppCollaborators." + this.cppUsers[i].developmentPlanStatus];
    //           }
    //         }
    //       }
    //       this.isLoadingCppUsers = false;
    //     }
    //     , (err: any) => {
    //       this.isLoadingCppUsers = false;
    //       if (err && err.message) {
    //         const messageObj: ApplicationModalMessage = {
    //           modalId: "a004",
    //           text: this.translate.instant("errors." + ((err && err.message) || err)),
    //           title: this.translate.instant("generic.WARNING")
    //         }
    //         this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //       }
    //       return throwError(new Error(err.message));
    //     }
    //   );
  }

  openRoleAlignmentModal() {
    this.modalService.open('role-alignment-modal');
  }

  closeRoleAlignmentModal() {
    this.modalService.close('role-alignment-modal');
  }

  changeSelectedCppRound(cppRound: any) {
    this.selectedRound = cppRound;
    this.router.navigate(['/peopleAppraisal/cppTeam/' + cppRound.roundId]);
  }

  goToCppCollaboratorDetails(cppUser: any) {
    this.router.navigate(['/peopleAppraisal/cppDetails/' + this.roundId + '/' + cppUser.userId]);
  }
}