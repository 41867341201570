<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">

  <div class="content" style="padding: 60px;" fxLayout="column" fxLayoutGap="32px">

    <div fxLayout="column" fxLayoutGap="10px">
      <item-list-team-status type="completed" styleItem="fill"></item-list-team-status>
      <item-list-team-status type="completed" styleItem="border"></item-list-team-status>
      <item-list-team-status type="completed" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="completed"></item-list-team-status>
      <item-list-team-status type="toStart" styleItem="border"></item-list-team-status>
      <item-list-team-status type="toStart" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="toStart"></item-list-team-status>
      <item-list-team-status type="inProgress" styleItem="border"></item-list-team-status>
      <item-list-team-status type="inProgress" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="inProgress"></item-list-team-status>
      <item-list-team-status type="disabled" styleItem="border"></item-list-team-status>
      <item-list-team-status type="disabled" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="disabled"></item-list-team-status>
    </div>

    <!-- Ninebox Matrix [x]-->
    <div class="content" fxLayout="column" fxLayoutGap="2px">

      <header-dossier [isAdminHeader]="true" adminPageTitle="Calibration" adminPageSubtitle="Matrice di comparazione"
        [adminBack]="true" [adminActionLabel]="('monitoring.DOWNLOAD' | translate)"></header-dossier>

      <!-- Ricerca -->
      <div class="search-wrapper">
        <div class="search-btn-wrapper">
          <alloy-input typeInput="text" iconRight="assets/img/icons/search.svg"
            [placeholder]="'structureAnag.details.SEARCH_BY' | translate" [isSearchBar]="true">
          </alloy-input>
          <alloy-button type="primary link" size="medium" iconLeft="assets/img/icons/file/xls.svg" label="Report"
            onButtonClicked=""></alloy-button>
        </div>
        <div class="distribution-box">
          <div class="label-wrapper">
            <p>Distibruzione dei box</p>
            <svg-icon src="/assets/img/icons/info.svg" class="info-icon medium-size cursor-pointer" [applyClass]="true">
            </svg-icon>
          </div>
          <div class="percentage-wrapper">
            <div class="box box-1">100%</div>
            <div class="box box-2">100%</div>
            <div class="box box-3">100%</div>
          </div>
        </div>
      </div>

      <!--ninebox-calibration-->
      <div class="ninebox-calibration-wrapper">
        <div class="levels-wrapper">
          <div class="box-level">
            <p>UNSATISFACTORY</p>
          </div>
          <div class="box-level">
            <p>IMPROVABLE</p>
          </div>
          <div class="box-level">
            <p>MEET</p>
          </div>
          <div class="box-level">
            <p>EXCEEDED</p>
          </div>
          <div class="box-level">
            <p>Exeptional</p>
          </div>
        </div>
        <div class="boxes-wrapper">
          <div class="columns-wrapper">
            <div class="box-ninebox box-1">
              <div class="tag-user">
                <avatar-img initials="MM" size="small"></avatar-img>
                <div class="name-payout">
                  <p class="name">Cognome Nome</p>
                  <p class="payout">Payout 110%</p>
                </div>
                <svg-icon src="/assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                  [applyClass]="true">
                </svg-icon>
              </div>
              <div class="tag-user">
                <avatar-img initials="MM" size="small"></avatar-img>
                <div class="name-payout">
                  <p class="name">Cognome Nome</p>
                  <p class="payout">Payout 110%</p>
                </div>
                <svg-icon src="/assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                  [applyClass]="true">
                </svg-icon>
              </div>
              <div class="tag-user">
                <avatar-img initials="MM" size="small"></avatar-img>
                <div class="name-payout">
                  <p class="name">Michelangelo Macchiavelli</p>
                  <p class="payout">Payout 110%</p>
                </div>
                <svg-icon src="/assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                  [applyClass]="true">
                </svg-icon>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-ninebox box-1">
              <p class="no-data-box">Nessuna persona presente</p>
            </div>
            <div class="separator"></div>
            <div class="box-ninebox box-2">
              <p class="no-data-box">Nessuna persona presente</p>
            </div>
            <div class="separator"></div>
            <div class="box-ninebox box-3">
              <div class="tag-user">
                <avatar-img initials="MM" size="small"></avatar-img>
                <div class="name-payout">
                  <p class="name">Michelangelo Macchiavelli</p>
                  <p class="payout">Payout 110%</p>
                </div>
                <svg-icon src="/assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                  [applyClass]="true">
                </svg-icon>
              </div>
              <div class="tag-user">
                <avatar-img initials="MM" size="small"></avatar-img>
                <div class="name-payout">
                  <p class="name">Michelangelo Macchiavelli</p>
                  <p class="payout">Payout 110%</p>
                </div>
                <svg-icon src="/assets/img/icons/move.svg" class="info-icon medium-size cursor-pointer"
                  [applyClass]="true">
                </svg-icon>
              </div>
            </div>
            <div class="separator"></div>
            <div class="box-ninebox box-3">
              <p class="no-data-box">Nessuna persona presente</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <tab-light *ngFor="let tab of tabLightTest" [tabTitle]="tab.title" [isDisabled]="tab.isDisabled"
        (onClick)="changeLightTab(tab)"></tab-light>
    </div>

    <header-dossier [user]="exmpleUserForHeaderDossier" interviewDate="12/03/2020"></header-dossier>
    <!-- Classe definita in general -->
    <div class="main-content-wrapper">
      <child-content [tabList]="tabDossier" (onTabChanged)="changeCurrentTab($event)">

        <ng-container *ngIf="currentTab == '1'">
          <!--goal-card title="Prova obiettivo" weightPercentage="30%" scadenceDate="12.02.2020"
            description="Creazione gestione e monitoraggio di un percorso formativo dedicato ai manager per accrescere la capacità di
      gestione con efficacia un team a distanza. Modulazione del piano di Modulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del 
      piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano di" osservedDate="13.05.2020"
            [feedbackCounter]="0" [evidencesCounter]="0"></goal-card-->
          <!-- Classe definita dentro a child-content -->
          <div class="button-container">
            <alloy-button type="primary" label="Apri modale" (onClick)="openTestModal()"></alloy-button>
            <alloy-button type="secondary" label="Apri badge" (onClick)="openTestBadgeModal()">
            </alloy-button>
          </div>
        </ng-container>

        <ng-container *ngIf="currentTab == '2'">
          <!-- Componente placeholder no data -->
          <no-data></no-data>
          <!-- Classe definita dentro a child-content -->
          <div class="button-container">
            <alloy-button type="primary" label="Apri modale" (onClick)="openTestModal()"></alloy-button>
            <alloy-button type="secondary" label="Apri badge" (onClick)="openTestBadgeModal()">
            </alloy-button>
          </div>
        </ng-container>

      </child-content>

    </div>

  </div>





  <banner-home></banner-home>

  <div class="content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="32px">
    <care-cards [items]="careCards"></care-cards>


    <!-- user card -->
    <input-container title="Chi vuoi applaudire?">
      <!-- ricerca -->
      <alloy-input></alloy-input>

      <p class="text-label-form">Seleziona una o più persone alle quali vuoi inviare un applauso:</p>
      <div *ngIf="!isLoadingTeam" class="user-card-collection-container">
        <div class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start center">
          <user-card *ngFor="let user of userCardData; trackBy userCardId" [user]="user"></user-card>
        </div>
      </div>
    </input-container>




    <!--goal-card title="Prova obiettivo" weightPercentage="30%" scadenceDate="12.02.2020"
      description="Creazione gestione e monitoraggio di un percorso formativo dedicato ai manager per accrescere la capacità di
      gestione con efficacia un team a distanza. Modulazione del piano di Modulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del 
      piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano diModulazione del piano di" osservedDate="13.05.2020"
      [feedbackCounter]="0" [evidencesCounter]="0"></goal-card-->

    <!--goal-card-detail title="Prova obiettivo" weightPercentage="30%" scadenceDate="12.02.2020" description="Creazione gestione e monitoraggio di un percorso formativo dedicato ai manager per accrescere la capacità di
      gestione con efficacia un team a distanza. Modulazione del piano di bhidsibfugsbhsiaobfa" [feedbackCounter]="0"
      [evidencesCounter]="0"></goal-card-detail-->
    <alloy-accordion title="Obiettivi 2021" [badgeNumber]="1" [isNew]="true">
      Prova transition
    </alloy-accordion>
    <div class="box-collection-container">
      <div class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start center">
        <box-home imgPath="../../../../assets/img/icons/homeIcons/goals-img.png"
          title="homeCards.selfAssessment.objectives.TITLE"
          description="homeCards.selfAssessment.objectives.DESCRIPTION" [isNew]="true"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/feedback-img.png"
          title="homeCards.selfAssessment.feedbackCollection.TITLE"
          description="homeCards.selfAssessment.feedbackCollection.DESCRIPTION" [isNew]="false"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/development-plan-img.png"
          title="homeCards.selfAssessment.individualPlan.TITLE"
          description="homeCards.selfAssessment.individualPlan.DESCRIPTION" [isNew]="false"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
          title="homeCards.selfAssessment.applauseSend.TITLE"
          description="homeCards.selfAssessment.applauseSend.DESCRIPTION" [isNew]="false"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/request-feedback-img.png"
          title="homeCards.selfAssessment.feedbackRequest.TITLE"
          description="homeCards.selfAssessment.feedbackRequest.DESCRIPTION" [isNew]="false"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/interview-img.png"
          title="homeCards.selfAssessment.interviews.TITLE"
          description="homeCards.selfAssessment.interviews.DESCRIPTION" [isNew]="false"></box-home>
      </div>
    </div>

    <div class="box-collection-container">
      <div class="row-boxes" fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="start center">
        <box-home imgPath="../../../../assets/img/icons/homeIcons/notes-img.png"
          title="homeCards.peopleAppraisal.notes.TITLE" description="homeCards.peopleAppraisal.notes.DESCRIPTION"
          [isNew]="true"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/applause-img.png"
          title="homeCards.peopleAppraisal.smartFeedback.TITLE"
          description="homeCards.peopleAppraisal.smartFeedback.DESCRIPTION" [isNew]="false"></box-home>
        <box-home imgPath="../../../../assets/img/icons/homeIcons/dashboard-img.png"
          title="homeCards.peopleAppraisal.dashboard.TITLE"
          description="homeCards.peopleAppraisal.dashboard.DESCRIPTION" [isNew]="false"></box-home>
      </div>
    </div>

    <!--tab-dossier tabTitle="Esempio"></tab-dossier>
      <tab-dossier tabTitle="Esempio" [isDisabled]="true"></tab-dossier-->

    <header-dossier [user]="exmpleUserForHeaderDossier" interviewDate="12/03/2020"></header-dossier>

    <card-feedback></card-feedback>

    <div fxLayout="column" fxLayoutGap="10px">
      <item-list-team-status type="completed" styleItem="border"></item-list-team-status>
      <item-list-team-status type="completed" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="completed"></item-list-team-status>
      <item-list-team-status type="toStart" styleItem="border"></item-list-team-status>
      <item-list-team-status type="toStart" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="toStart"></item-list-team-status>
      <item-list-team-status type="inProgress" styleItem="border"></item-list-team-status>
      <item-list-team-status type="inProgress" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="inProgress"></item-list-team-status>
      <item-list-team-status type="disabled" styleItem="border"></item-list-team-status>
      <item-list-team-status type="disabled" styleItem="bordless"></item-list-team-status>
      <item-list-team-status type="disabled"></item-list-team-status>
    </div>

    <!-- Card radio button -->
    <!--div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
              <card-radio></card-radio>
          </div-->
    <!-- apre modali -->
    <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center center" fxLayoutGap="16px grid"
      class="full-width">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <alloy-button type="primary" label="Apri modale" (onClick)="openTestModal()"></alloy-button>
        <alloy-button type="primary bordless" label="Apri badge" (onClick)="openTestBadgeModal()">
        </alloy-button>
        <alloy-button type="secondary" label="Apri modale full" (onClick)="openTestFullModal()"></alloy-button>
      </div>
      <!--div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px grid">
          <avatar-img initials="MM" size="big"></avatar-img>
          <avatar-img src="/assets/img/temp/avatar.svg" size="big" [hasNews]="true"></avatar-img>
          <avatar-img src="/assets/img/temp/avatar.svg" size="big"></avatar-img>
          <avatar-img initials="MM" size="big" [hasNews]="true"></avatar-img>
          <avatar-img src="/assets/img/temp/avatar.svg" size="medium"></avatar-img>
          <avatar-img initials="al" size="medium"></avatar-img>
          <avatar-img initials="MM" size="small"></avatar-img>
          <avatar-img src="/assets/img/temp/avatar.svg" size="small"></avatar-img>
        </div-->
    </div>

    <input-container style="width: 450px;" [fromEuropUpload]="true"
      [instructions]="'Potrai caricare i segunti tipi di file: xls,doc,jpg,jpeg,pdf,png,ppt.'"
      [title]="'Carica il Modulo mensile delle prestazioni e Process Confirmation'">
      <!--alloy-upload></alloy-upload-->
    </input-container>

    <input-container style="width: 450px;" [title]="'Data di scadenza'"
      [instructions]="'Informazioni contestuali del form, descrizione o indizazioni sull’obbligatorietà. Questo spazio sarà dedicato anche agli errori'">
      <alloy-datepicker class="full-width" placeholder="generic.INSERT_DATE"></alloy-datepicker>
    </input-container>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- Select -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container [isValued]="isSelectValued(selectedCar)">
          <alloy-select [items]="cars" [selectedItem]="selectedCar" (onSelectedItem)="onItemChecked($event)">
          </alloy-select>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <!--alloy-select [disabled]="true"></alloy-select-->
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <!--alloy-select [forceError]="true" [items]="cars" [selectedItem]="selectedCar"
                  (onSelectedItem)="onItemChecked($event)"></alloy-select-->
          <alloy-select [forceError]="true" [noDataFoundLabel]=""></alloy-select>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <!--alloy-select [readonly]="true"></alloy-select-->
        </input-container>
      </div>
      <!-- Input -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container>
          <alloy-input></alloy-input>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <alloy-input [disabled]="true"></alloy-input>
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <alloy-input [isInvalid]="true"></alloy-input>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <alloy-input></alloy-input>
        </input-container>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- switch -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <input-container>
          <alloy-switch></alloy-switch>
        </input-container>
        <input-container [disabled]="true">
          <alloy-switch [disabled]="true"></alloy-switch>
        </input-container>
        <input-container [readonly]="true">
          <alloy-switch [readonly]="true"></alloy-switch>
        </input-container>
      </div>
      <!-- Radio -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <input-container [isValued]="radioSelected">
          <alloy-radio [items]="radioList" [bindValue]="radioSelected" radioName="choice"></alloy-radio>
        </input-container>
        <input-container [disabled]="true">
          <alloy-radio [disabled]="true" [items]="radioList" [bindValue]="radioSelected">
          </alloy-radio>
        </input-container>
        <input-container [readonly]="true">
          <alloy-radio [readonly]="true" [items]="radioList" [bindValue]="radioSelected">
          </alloy-radio>
        </input-container>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
      <!-- checkbox -->
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
        <!-- Normale -->
        <input-container [isFromTextArea]="true" [numberCharsTextArea]="textAreaNumberChars" [title]="'Descrizione'">
          <alloy-text-area [maxlength]="200" (onModelChanged)="changeCountChars($event)"></alloy-text-area>
        </input-container>
        <!-- Disabilitato -->
        <input-container style="width: 450px;" [title]="'Feedback di Francesca'"  [readonly]="true">
          <alloy-text-area [readonly]="true"
            [bindValue]="'Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.'">
          </alloy-text-area>
        </input-container>
        <!-- Normale -->
        <input-container>
          <alloy-select></alloy-select>
        </input-container>
        <!-- Disabilitto -->
        <input-container [disabled]="true">
          <alloy-select [disabled]="true"></alloy-select>
        </input-container>
        <!-- Errore -->
        <input-container
          [errorText]="'Informazioni sull’errore del form, indicare il motivo per cui questo campo non è valido'">
          <alloy-select [forceError]="true"></alloy-select>
        </input-container>
        <!-- Readonly -->
        <input-container [readonly]="true">
          <alloy-select [readonly]="true"></alloy-select>
        </input-container>
        <input-container [title]="'Feedback di Francesca'"  [readonly]="true">
          <alloy-text-area [readonly]="true"
            [bindValue]="'Tincidunt morbi ultrices nullam vulputate eget eros, fusce integer morbi. Id lectus proin massa euismod tincidunt arcu risus diam. Ut a volutpat tortor nisl purus etiam sed sit in. Tempus, mauris gravida ut arcu parturient eu id diam enim.'">
          </alloy-text-area>
        </input-container>
        <input-container>
          <alloy-check label="Prova"></alloy-check>
        </input-container>
        <input-container [disabled]="true">
          <alloy-check label="disabilitato" [disabled]="true"></alloy-check>
        </input-container>
        <input-container [readonly]="true">
          <alloy-check label="readonly" [readonly]="true"></alloy-check>
        </input-container>
      </div>
    </div>
    <!-- Buttons -->
    <alloy-button type="primary" iconRight="assets/img/icons/arrow-right.svg"></alloy-button>
    <alloy-button type="primary bordless"></alloy-button>
    <alloy-button type="secondary"></alloy-button>
    <alloy-button type="secondary bordless"></alloy-button>

    <div style="width: 100%; background-color: #000; margin: 10px auto" fxLayout="row" fxLayoutAlign="center center">
      <alloy-button type="btn-white bordless"></alloy-button>
    </div>

  </div>
</div>

<modal id="test" (onClose)="closeTestModal()" confirmLabel="Conferma">
  <modal-text-content></modal-text-content>
</modal>

<modal id="badgeModal" (onClose)="closeTestBadgeModal()" confirmLabel="Chiudi">
  <!--modal-text-content image="/assets/img/temp/badge.svg" badgeDescr="Beginner"></modal-text-content-->
</modal>

<modal id="testFullPage" (onClose)="closeTestFullModal()" [fullPage]="true" confirmLabel="Conferma"
  cancelLabel="Annulla">
  <modal-text-content></modal-text-content>
</modal>