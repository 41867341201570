import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment = require('moment-timezone');

@Component({
  selector: 'goal-card',
  templateUrl: 'goal-card.component.html',
  styleUrls: ['./goal-card.component.scss']
})
export class GoalCardComponent implements OnInit {
  @Input() goal?: any;
  @Input() attrAriaLabel: string = '';
  @Input() isInnerHtml: boolean = false;
  @Input() isOperations: boolean = false;
  @Input() isStructure: boolean = false;
  @Input() showFinalEvaluation: boolean = false;
  @Output() onDetailClicked: EventEmitter<any> = new EventEmitter();
  @Output() finalEvaluationClicked: EventEmitter<any> = new EventEmitter();

  descriptionIsOpened: Boolean = false;
  evaluationMap = {
    'NO_ACHIEVED': 1,
    'ACHIEVED': 2,
    'EXCEEDED': 3,
  }
  today = moment().format('dd/mm/yyyy');
  constructor() { }

  ngOnInit() {
    /* if(this.goal.deadline) {
      this.goal.deadline = moment(this.goal.deadline).utc().format('DD/MM/YYYY');
    } */
    if (this.goal && this.goal.type && this.goal.type == "STRUCTURE") {
      this.isStructure = true;
    }
  }

  getStructureGoalDescription() {
    let descr = '-';
    if (this.goal && this.goal.description && this.goal.description.length) {
      descr = this.goal.description.replace(/\n/g, '<br>');
    }
    return descr
  }


  emitOnDetailClicked(data?: string) {
    this.onDetailClicked.emit(data);
  }

  // Toggle sulla descrizione dell'obiettivo
  toggleDescription() {
    this.descriptionIsOpened = !this.descriptionIsOpened;
  }

  onFinalEvaluationClicked(data: any) {
    this.finalEvaluationClicked.emit(data);
  }
}