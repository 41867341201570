<div class="user-card-wrapper" *ngIf="user" (click)="emitOnCheck()"
  [ngClass]="{'active': user.isChecked, 'radio': radio, 'disabled': disabled}">
  <avatar-img [initials]="getUserInitials()" [src]="user.avatar" size="medium"></avatar-img>
  <div class="name-wrapper" [ngClass]="{'row': isFeedbackCard}">
    <p *ngIf="!fromCppCollabs" class="text-default">{{ user.surname }}</p>
    <p *ngIf="!fromCppCollabs" class="text-default">{{ user.name }}</p>
    <p *ngIf="fromCppCollabs" class="text-default">{{ user.surname }} {{ user.name || user.forename }}</p>
    <p *ngIf="fromCppCollabs" class="text-default">{{ user.role }}</p>
    <ng-container *ngIf="user.cardStatus">
      <item-list-team-status [message]="'upwardFeedback.cardStatus.' + user.cardStatus | translate"
        [type]="user.cardStatus" styleItem="border">
      </item-list-team-status>
    </ng-container>
  </div>
  <div class="check-wrapper" [ngClass]="{'radio': radio}">
    <svg-icon *ngIf="user.isChecked && !radio" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
    </svg-icon>
    <div *ngIf="radio && user.isChecked" class="checked"></div>
  </div>

</div>