/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non 
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
*/

import { Injectable } from '@angular/core';

@Injectable()
export class AdminEffects {
  constructor() {
  }
}
