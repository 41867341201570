import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabDossier } from 'src/app/utils/classes.utils';

@Component({
  selector: 'child-content',
  templateUrl: 'child-content.component.html',
  styleUrls: ['./child-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChildContentComponent implements OnInit {
  @Input() tabList: TabDossier[] = [];
  @Input() isDevelopmentSection: boolean = false;
  @Input() isEditObjective?: boolean = false;
  @Input() peerFeedbackPage?: boolean = false;
  @Output() onTabChanged: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
  }

  emitOnTabChanged(tab: TabDossier) {
    for (let i = 0; i < this.tabList.length; i++) {
      this.tabList[i].isDisabled = true;
    }
    tab.isDisabled = false;
    this.onTabChanged.emit(tab);
  }
}
