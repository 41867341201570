import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'item-list-team-for-table',
  templateUrl: 'item-list-team-for-table.component.html',
  styleUrls: ['./item-list-team-for-table.component.scss']
})
export class ItemListTeamForTableComponent implements OnInit {
  @Input() userObject?: UserTableObject;
  @Output() feedbackClicked: EventEmitter<UserTableObject> = new EventEmitter()
  @Output() onUserDetail: EventEmitter<any> = new EventEmitter()
  @Output() onPhaseInfo: EventEmitter<any> = new EventEmitter()
  compactView: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth <= 1140) {
      this.compactView = true;
    } else {
      this.compactView = false;
    }
  }

  constructor() {
    if (window.innerWidth <= 1140) {
      this.compactView = true;
    } else {
      this.compactView = false;
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 1140) {
      this.compactView = true;
    } else {
      this.compactView = false;
    }
  }

  emitOnFeedbackClicked() {
    this.feedbackClicked.emit(this.userObject?.modalData);
  }

  emitOnUserDetailClicked() {
    this.onUserDetail.emit(this.userObject?.user);
  }

  emitOpenModalInfo(phaseId: string) {
    this.onPhaseInfo.emit(phaseId);
  }
}

export interface UserTableObject {
  user: any;
  goalSettingStatus: string;
  developmentPlanStatus: string;
  midTermReviewStatus: string;
  finalEvaluationStatus: string;
  feedback?: any;
  totalFeedbackCount: number;
  modalData?: any;
}