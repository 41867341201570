import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable()
export class DashboardGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.canAccessTheDashboard) {
      return true;
    } else {
      return this.router.navigate(['/404']);
    }
  }
}
