<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap.gt-xs="16px">
    <div class="banner-container">
        <!-- Banner -->
        <banner-home *ngIf="runningPhaseDate" [phaseName]="phaseName" [backgroundSrc]="bannerImage"
            [endDate]="runningPhaseDate" [runningYear]="runningYear.year"></banner-home>
        <banner-home *ngIf="!runningPhaseDate" [backgroundSrc]="bannerImage"></banner-home>
    </div>

    <ng-container
        *ngIf="isPeopleAppraisal? !isLoadingPasInfo : false || isSelfAssessment? !isLoadingPasInfoUser : false || (roundListManager.length >= 1)">
        <div class="content-container" id="content-home">
            <!-- Contenitore Attività del team -->
            <div class="items-container" *ngIf="isPeopleAppraisal">
                <div class="backgorund-img flip"></div>

                <div class="main-title-section">
                    <div class="icon-container">
                        <svg-icon [src]="'assets/img/icons/users.svg'"></svg-icon>
                    </div>
                    <p class="title" translate="homepage.ACTIVITIES_RELATED">
                        <span class="border" translate="homepage.MY_TEAM"></span>
                    </p>
                </div>

                <!-- separatore -->
                <div class="divisor"></div>

                <!-- Items -->
                <div class="items">
                    <!-- PAS -->
                    <item-home *ngIf="canAccessPersonalDevelopment && pasData?.perfYear && !isLoadingPasInfo"
                        [icon]="'assets/img/icons/briefcase.svg'" [text]="'homepage.items.PAS_TEXT' | translate"
                        [expirationDate]="pasData?.endDate" [hasNew]="pasData?.isNewPas"
                        [title]="'homepage.items.PAS_TITLE' | translate" (onClicked)="goToPersonDetails()"></item-home>

                    <!-- Dashboard -->
                    <item-home *ngIf="canAccessTheDashboard" [icon]="'assets/img/icons/pie-chart.svg'"
                        [text]="'homepage.items.DASHBOARD_TEXT' | translate"
                        [title]="'homepage.items.DASHBOARD_TITLE' | translate" (onClicked)="goToDashboard()">
                    </item-home>
                    <!-- Upward Feedback Personale-->
                    <item-home *ngIf="canAccessToUpwardFeedback && this.upwardFeedbackData?.showSelfTile"
                        [icon]="'assets/img/icons/message-circle.svg'"
                        [text]="'homepage.items.UPWARD_FEEDBACK_TEXT' | translate"
                        [title]="'homepage.items.UPWARD_FEEDBACK_TITLE' | translate"
                        (onClicked)="openUpwardFeedback(true)" [hasNew]="upwardFeedbackData?.selfIsNew"></item-home>
                    <!-- Upward Feedback Team-->
                    <item-home *ngIf="canAccessToUpwardFeedback && upwardFeedbackData?.showTeamTile"
                        [icon]="'assets/img/icons/sprout.svg'"
                        [text]="'homepage.items.UPWARD_FEEDBACK_TEXT_2' | translate" (onClicked)="openUpwardFeedback()"
                        [title]="'homepage.items.UPWARD_FEEDBACK_TITLE' | translate"
                        [hasNew]="upwardFeedbackData?.secondLevelIsNew"></item-home>
                    <!-- CPP -->
                    <!-- <item-home
                        *ngIf="canAccessTheCpp && canAccessCpp && cppInfo?.roundId?.length && isBetweenDatesCpp && cppInfo?.totalCollaborators > 0"
                        [icon]="'assets/img/icons/briefcase.svg'" [text]="'homepage.items.CPP_TEXT_MANAGER' | translate"
                        [title]="'homepage.items.CPP_TITLE' | translate"
                        (onClicked)="openCollaboratorsCppCompletedModal()"></item-home> -->
                    <!-- Carrier Path Program -->
                    <item-home *ngIf="(cppInfo?.totalCollaborators >= 1)" [icon]="'assets/img/icons/clipboard-list.svg'"
                        [text]="'careerPathProgram.ANALYSE_RESULTS' | translate" [hasNew]="cppInfo?.isNewCppNotify"
                        [title]="'careerPathProgram.TITLE' | translate" (onClicked)="goToTeamCPP()"></item-home>
                    <!-- Appunti privati -->
                    <item-home *ngIf="canAccessToPrivateNotes" [icon]="'assets/img/icons/edit.svg'"
                        [text]="'homepage.items.PRIVATE_NOTES_TEXT' | translate"
                        [title]="'homepage.items.PRIVATE_NOTES_TITLE' | translate" (onClicked)="goToTakeNote()">
                    </item-home>
                </div>
            </div>

            <!-- Contenitore Attività personali -->
            <div class="items-container"
                *ngIf="isSelfAssessment || (roundListUser.length >= 1) || (insightFeedbackMatrixData && insightFeedbackMatrixData.isEnabled)">
                <div class="backgorund-img"></div>

                <div class="main-title-section">
                    <div class="icon-container white">
                        <svg-icon [src]="'assets/img/icons/user.svg'"></svg-icon>
                    </div>
                    <p class="title" translate="homepage.MY_ACTIVITIES">
                        <span class="border" translate="homepage.PERSONAL"></span>
                    </p>
                </div>

                <!-- separatore -->
                <div class="divisor"></div>

                <!-- Items -->
                <div class="items white">
                    <!-- PAS -->
                    <item-home *ngIf="canAccessPersonalDevelopment && !isLoadingPasInfoUser && pasDataSelf?.perfYear"
                        [expirationDate]="pasDataSelf?.endDate" [hasNew]="pasDataSelf?.isNewPas"
                        [icon]="'assets/img/icons/briefcase.svg'" [text]="'homepage.items.PAS_TEXT_SELF' | translate"
                        [title]="'homepage.items.PAS_TITLE' | translate" (onClicked)="goToPersonDetails(true)">
                    </item-home>
                    <item-home *ngIf="!isLoadingInsightFeedback && insightFeedbackMatrixData.isEnabled"
                        [hasNew]="insightFeedbackMatrixData?.isNew" [icon]="'assets/img/icons/hard-hat.svg'"
                        [text]="'homepage.items.INSIGHT_FEEDBACK_TEXT' | translate"
                        [title]="'homepage.items.INSIGHT_FEEDBACK_TITLE' | translate"
                        (onClicked)="goToInsightFeedbackMatrixManager()">
                    </item-home>
                    <!-- Appunti privati -->
                    <item-home *ngIf="canAccessToPrivateNotes && isSelfAssessment" [icon]="'assets/img/icons/edit.svg'"
                        [text]="'homepage.items.PRIVATE_NOTES_TEXT_SELF' | translate"
                        [title]="'homepage.items.PRIVATE_NOTES_TITLE' | translate" (onClicked)="goToPrivateNotes()">
                    </item-home>
                    <!-- Upward Feedback-->
                    <item-home *ngIf="canAccessToUpwardFeedback && hasUpwardFeedbackSelf()"
                        [icon]="'assets/img/icons/message-circle.svg'"
                        [text]="'homepage.items.UPWARD_FEEDBACK_TEXT_SELF' | translate"
                        [hasNew]="upwardFeedbackDataSelf && upwardFeedbackDataSelf.isNew ? true : false"
                        (onClicked)="openUpwardFeedbackSelf()"
                        [title]="'homepage.items.UPWARD_FEEDBACK_TITLE' | translate"></item-home>
                    <!-- CPP -->
                    <item-home
                        *ngIf="canAccessTheCpp && canAccessCpp && cppInfoForUser?.roundId?.length && isBetweenDatesCppForUser || (roundListUser.length >= 1)"
                        [icon]="'assets/img/icons/briefcase.svg'" [text]="'homepage.items.CPP_TEXT_COLAB' | translate"
                        [title]="'homepage.items.CPP_TITLE' | translate" (onClicked)="goToUserCPP()"
                        [hasNew]="cppInfoForUser?.isNewCppNotify"></item-home>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isPeopleAppraisal? isLoadingPasInfo : false || isSelfAssessment? isLoadingPasInfoUser : false">
        <div class="loader-container">
            <div class="loader spinner-big"></div>
        </div>
    </ng-container>
</div>


<!-- Modale di scarica report per collaboratori completati del CPP -->
<modal id="completed-collaborators-cpp-modal" (onClose)="closeCollaboratorsCppCompletedModal()"
    (onConfirm)="closeCollaboratorsCppCompletedModal(true)" [isDisabled]="!selectedUserList?.length"
    confirmLabel="{{ 'technicalAssessment.selfAssessment.DOWNLOAD_REPORT' | translate}}"
    [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeCollaboratorsCppCompletedModal()">
    <p class="modal-title black center">
        CPP - Report Technical Self Assessment
    </p>
    <ng-container>
        <div class="margin-top30 margin-bottom30"
            *ngIf="cppInfo && (!cppInfo.completedCollaboratorsData || !cppInfo.completedCollaboratorsData.length)">
            <p class="text-modal bold-text black" translate="technicalAssessment.selfAssessment.NO_COMPLETED_COLLABS">
            </p>
        </div>
        <div *ngIf="cppInfo && cppInfo.completedCollaboratorsData?.length" class="completed-collabs-cpp-container">
            <p class="text-modal bold-text black">
                {{ cppInfo.totalCollaboratorsCppCompleted +
                ('technicalAssessment.selfAssessment.COLLABORATORS_ON' |
                translate)
                + cppInfo.totalCollaborators +
                ('technicalAssessment.selfAssessment.COMPLETED_TECHNICAL_ASSESSMENT' |
                translate)}}</p>
            <p class="text-modal grey margin-bottom24">
                {{ 'technicalAssessment.selfAssessment.SELECT_COLLABS_FOR_REPORT' | translate}}</p>
            <input-container [isWithoutInfoIcon]="true">
                <!-- ricerca -->
                <alloy-input iconRight="assets/img/icons/search.svg" [bindValue]="searchUserText"
                    (onModelChanged)="changeSearchedTextValue($event)" (onIconClicked)="searchUsers()"
                    placeholder="{{'privateNotes.createUpdate.SEARCH_PLACEHOLDER' | translate}}">
                </alloy-input>
                <div class="user-card-collection-container">
                    <!-- Card utente -->
                    <div class="row-boxes" fxLayout="row wrap" fxLayoutAlign="start center" fxLayout.lt-md="column"
                        fxLayoutAlign.lt-md="center center">
                        <user-card class="full-width margin-top16" *ngFor="let user of completedCollaboratorsList"
                            [user]="user" [selectedUserList]="selectedUserList" [isFeedbackCard]="true"
                            (onCheck)="selectUser($event)" [fromCppCollabs]="true"></user-card>
                    </div>
                </div>

            </input-container>
        </div>
    </ng-container>
</modal>

<!-- Upward Feedback modal user - per scegliere il manager da valutare se sono più di 1 -->
<modal id="upf-modal-for-user" (onClose)="closeUpwardFeedbackModalForUser()"
    [isDisabled]="!selectedFeedbackManagerToEvaluate" [confirmLabel]="'upwardFeedback.SEND_FEEDBACK' | translate"
    (onConfirm)="goToSendFeedback()" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeUpwardFeedbackModalForUser()">
    <modal-text-content [title]="'upwardFeedback.TITLE' | translate"
        [subtitle]="'upwardFeedback.modals.SELF_TITLE' | translate"
        [text]="'upwardFeedback.modals.SELF_DESCR' | translate"></modal-text-content>
    <div class="user-list" *ngIf="managersToEvaluate && managersToEvaluate.length">
        <user-card *ngFor="let userData of managersToEvaluate" [user]="userData.user"
            [isChecked]="userData.user.isChecked!" (onCheck)="chooseUpwardFeedbackUser(userData)" [radio]="true"
            [fromCppCollabs]="true"></user-card>
    </div>
</modal>

<!-- Upward Feedback modal manager 2 livello - per scegliere il manager da aiutare -->
<modal id="upf-modal-for-manager" (onClose)="closeUpwardFeedbackModalForManager()"
    [isDisabled]="!selectedFeedbackManagerToHelp" [confirmLabel]="'upwardFeedback.modals.SELECT' | translate"
    (onConfirm)="goToCheckFeedbackResults()" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeUpwardFeedbackModalForManager()">
    <modal-text-content [title]="'upwardFeedback.TITLE' | translate"
        [subtitle]="'upwardFeedback.modals.CHOOSE_MANGER_SUB' | translate"
        [text]="'upwardFeedback.modals.CHOOSE_MANAGER_DESCR' | translate"></modal-text-content>
    <div class="user-list" *ngIf="upwardFeedbackManagers && upwardFeedbackManagers.length">
        <user-card *ngFor="let user of upwardFeedbackManagers" [user]="user" [isChecked]="user.isChecked"
            (onCheck)="chooseUpwardFeedbackManager(user)" [radio]="true" [fromCppCollabs]="true"
            [disabled]="!user.resultAvailable"></user-card>
    </div>
</modal>

<modal id="completion-cpp-modal" (onClose)="closeCompletionCppModal()"
    [confirmLabel]="'technicalAssessment.selfAssessment.DOWNLOAD_REPORT' | translate"
    (onConfirm)="closeCompletionCppModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
    (onCancel)="closeCompletionCppModal()">
    <modal-text-content [title]="'technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT' | translate"
        [subtitle]="('technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT_COMPLETED_SUBTITLE' | translate) + (cppInfoForUser?.completionDate | date: 'dd/MM/YYYY')"
        [text]="'technicalAssessment.selfAssessment.TECHNICAL_SELF_ASSESSMENT_COMPLETED_DESC' | translate">
    </modal-text-content>
</modal>