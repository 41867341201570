<div class="goal-container" *ngIf="goal">
  <div class="title-container">
    <p class="title">{{ goal.title }}</p>
    <item-list-team-status styleItem="border" [message]="goal.statusMessage" [type]="goal.status">
    </item-list-team-status>
  </div>
  <div class="goal-various-info-container">
    <ng-container *ngIf="goal.miniCards">
      <div class="mini-card" *ngFor="let miniCard of goal.miniCards" [ngClass]="miniCard.color">
        <p>{{ miniCard.title | translate }}</p>
      </div>
    </ng-container>
    <div *ngIf="goal.weight" class="weight-container">
      <svg-icon src="./assets/img/icons/pie-chart.svg" class="small-size gray-gray1" [applyClass]="true">
      </svg-icon>
      <p>
        <span fxHide.xs translate="goalSetting.WEIGHT"></span>
        <span class="bold-text">{{ (goal.weight || 0) + '%'}}</span>
      </p>
    </div>
    <div *ngIf="goal.deadline" class="date-container">
      <svg-icon src="./assets/img/icons/calendar.svg" class="small-size gray-gray1" [applyClass]="true">
      </svg-icon>
      <p>
        <span fxHide.xs translate="goalSetting.SCADENCE_AT"></span>
        <!--span class="bold-text">{{scadenceDate}}</span-->
        <span class="bold-text">{{ goal.deadline || '-' }}</span>
      </p>
    </div>
  </div>
  <!-- Descrizione -->
  <ng-container *ngIf="goal.description">
    <div class="description-container">
      <div class="description">
        <!-- Obiettivi di struttura -->
        <ng-container *ngIf="isStructure">
          <p>
            <span class="title" translate="generic.DESCRIPTION"></span>
            <span innerHTML="{{ getStructureGoalDescription() }}"></span>
          </p>
        </ng-container>
        <ng-container *ngIf="!isStructure">
          <p *ngIf="!descriptionIsOpened">
            <span class="title" translate="generic.DESCRIPTION"></span>
            <span innerHTML="{{ goal.description | truncate: [200, '...'] }}"></span>
          </p>
          <p *ngIf="descriptionIsOpened">
            <span class="title" translate="generic.DESCRIPTION"></span>
            <span innerHTML="{{ goal.description}}"></span>
            <span *ngIf="goal.careTitle" class="title padding-top7">{{ goal.careTitle }}</span>
            <span *ngIf="goal.careDescription">{{ goal.careDescription }}</span>
            <span
              *ngIf="isOperations && goal.type != 'OPERATIONS_COMPETENCES' && goal.describeWhatYouIntendToDoForWinChallenge"
              class="title" translate="goal.RAGGIUNGEMENT_GOAL_TEXT"></span>
            <span *ngIf="goal.type != 'OPERATIONS_COMPETENCES' && goal.describeWhatYouIntendToDoForWinChallenge">
              {{ goal.describeWhatYouIntendToDoForWinChallenge }}
            </span>
            <span *ngIf="isOperations && goal.type == 'OPERATIONS_COMPETENCES'">{{ goal.description
              }}</span>
          </p>
        </ng-container>
      </div>
      <div class="action-buttons">
        <div *ngIf="descriptionIsOpened" (click)="toggleDescription()" class="show-less-button">
          <a translate="goalSetting.SHOW_LESS"></a>
          <svg-icon src="./assets/img/icons/chevron-up.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>

        </div>
        <div *ngIf="!descriptionIsOpened && goal.careTitle && goal.careDescription" (click)="toggleDescription()"
          class="show-all-button">
          <a translate="goalSetting.SHOW_ALL"></a>
          <svg-icon src="./assets/img/icons/chevron-down.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>
        </div>
        <div *ngIf="!descriptionIsOpened && goal.describeWhatYouIntendToDoForWinChallenge" (click)="toggleDescription()"
          class="show-all-button">
          <a translate="goalSetting.SHOW_ALL"></a>
          <svg-icon src="./assets/img/icons/chevron-down.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- File per kpi e competenze -->
  <ng-container *ngIf="goal.isOperation">
    <div class="description-container">
      <div class="files">
        <span>
          {{
          goal.fileNumber > 0 ? (goal.fileNumber == 1 ? (goal.fileNumber + ' ' + ('goalSetting.FILE_NUMBER' |
          translate)) : (goal.fileNumber + ' ' + ('goalSetting.FILES_NUMBER' | translate)) ) :
          ('goalSetting.NO_FILE' | translate)

          }}</span>
      </div>
    </div>
  </ng-container>
  <!-- Valutazione finale -->
  <div class="valutation-container" *ngIf="goal.goalEvaluation && goal.goalEvaluation.length">
    <!-- Valutazione utente -->
    <div class="final-evaluation-row">
      <div class="left-side">
        <avatar-img [user]="goal.goalEvaluation[0].user" size="small"></avatar-img>
        <p (click)="onFinalEvaluationClicked(goal.goalEvaluation[0])" class="user-name">{{
          goal.goalEvaluation[0].user.forename[0] + '. ' + goal.goalEvaluation[0].user.surname }}
        </p>
        <evaluation [evaluation]="goal.goalEvaluation[0].evaluationScore" customClass="medium"></evaluation>
      </div>
      <p class="compiled">
        {{ ('final.COMPILED_IN' | translate) + ( goal.goalEvaluation[0].creationDate | date: 'dd.MM.YYYY') }}
      </p>
    </div>
  </div>
  <div class="bottom-container" *ngIf="goal.type != 'COMPANY' && goal.type != 'STRUCTURE'">
    <div class="line-divisor">
      <div class="line"></div>
    </div>
    <div class="bottom-info">
      <div class="info">
        <p>
          <span class="bold-text">{{ goal.feedbackCounter || 0}}</span>&nbsp;
          <span translate="goalSetting.FEEDBACK"></span>
        </p>
        <p>
          <span class="bold-text">{{ goal.evidencesCounter || 0}}</span>&nbsp;
          <span translate="goalSetting.EVIDENCES"></span>
        </p>
        <div class="new" *ngIf="goal.isNew">
          <p translate="generic.NOVITY"></p>
        </div>
      </div>
      <div class="detail-button">
        <alloy-button (onButtonClicked)="emitOnDetailClicked(goal)" iconRight="assets/img/icons/arrow-right.svg"
          type="primary link" size="medium" label="{{'goalSetting.DETAIL' | translate}}"></alloy-button>
      </div>
    </div>
  </div>
</div>