import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from 'src/app/shared/services/url.service';
import { JwtPayload } from '../../../commonclasses';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default-page',
  template: ''
})
export class DefaultPageComponent implements OnDestroy {
  result$: any;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private urlService: UrlService) {
    const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
    const isSelfAssessment$: Observable<boolean> = this.store.select(fromApp.getIsSelfAssessment);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);

    const combinedSelectes$ = combineLatest([loggedUser$, isPeopleAppraisal$, isSelfAssessment$, isAdmin$]);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, isPeopleAppraisal, isSelfAssessment, isAdmin]) => {
        if (loggedUser && loggedUser.user) {
          // Eseguo il redirect in base alle auth dell'utente
          const redirectPage = this.urlService.getDefaultRedirectPage(isPeopleAppraisal, isSelfAssessment, isAdmin);
          this.router.navigate([redirectPage]);
        }
      });
  }

  ngOnDestroy() {
    this.result$.unsubscribe();
  }
}