import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

export interface FeatureState extends fromApp.AppState {
  selfAssessment: SelfAssessmentState
}

export interface SelfAssessmentState {
}

// Store iniziale
const initialState: SelfAssessmentState = {
};


export const createSelfAssessmentReducer = createReducer(
  initialState
)

export function selfAssessmentReducer(state: SelfAssessmentState | undefined, action: Action) {
  return createSelfAssessmentReducer(state, action);
}