import { Store } from "@ngrx/store";
import { Subscription, combineLatest, Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";
import * as fromApp from '../../ngrx/app.reducers';
import { Injectable } from "@angular/core";
import { SenecaResponse, GetPerformanceCareTagsResponse, PerfCareUserPhaseGoal, CreateUserGoalForManagerResponse, UpdateUserGoalForManagerResponse, GetGoalByIdForManagerResponse, CreateUserGoalForUserResponse, GetUserFeedbackDialogueForManagerResponse, PerfCareFeedbackDialogue, UpdateUserFeedbackDialogueForManagerResponse, CreateUserFeedbackDialogueForManagerResponse, DeleteSubordinateGoalForManagerResponse, GetUserGoalWeightSumForManagerResponse, GetBehaviorCareTagsResponse, PerformanceCareRent, ApproveGoalProposedBySubordinateUserResponse, UpdateApproveGoalCommentProposedBySubordinateUserResponse, CancelUserFeedbackDialogueForManagerResponse, SetCareAttributeGoalsAsSeenForManagerResponse, ListDevelopmentActionForManagerResponse, CreateDevelopmentActionForManagerResponse, PerfCareDevelopmentAction, UpdateDevelopmentActionForManagerResponse, CanCreateDevelopmentActionForManagerResponse, CanShareDevelopmentActionToUserResponse, PerfCareApplauseFeedback, ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse, CreateEvidenceFeedbackForManagerResponse, UpdateEvidenceFeedbackForManagerResponse, ListEvidenceFeedbackForManagerResponse, DeleteFileForEvidenceFeedbackForManagerResponse, MapById, CountFeedbacksForManagerResponse, ListPrivateNoteFeedbackForManagerResponse, PerfCarePrivateNoteFeedback, CreatePrivateNoteFeedbackForManagerResponse, GetPrivateNoteFeedbackForManagerResponse, DeletePrivateNoteFeedbackForManagerResponse, EnrichedSenecaResponseForBadges } from "../../../commonclasses";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import * as moment from 'moment';
import { AlloyPerformanceCpp, AlloyPerformanceCppServiceResponses, PerfAlloyBehavior, PerfAlloyUserGoal, AlloyPerformanceUpwardFeedbackServiceResponses, AlloyPerformanceUpwardFeedbackParams, GetPerformanceAssessmentInfoForManager, AlloyPerformanceCppParams, FacileOpenAPI, ListMyCollegueUsersResponse } from "atfcore-commonclasses";

export interface actionUDP {
  actionId: string,
  competenceId: string,
  type: string,
  scope: string,
  endDate: Date,
}

@Injectable()
export class PeopleAppraisalService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }


  // VECCHI SERVIZI INIZIO

  // Recupera i goals del manager
  listUserGoalForPeopleAppraisal(year: number, userId: string, goalTab?: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', year.toString() || '');
    httpParams = httpParams.append('userId', userId || '');
    if (goalTab && goalTab.length && Array.isArray(goalTab)) {
      goalTab && goalTab.forEach(type => {
        httpParams = httpParams.append('goalTab', type);
      });
    } else if (goalTab && goalTab.length && !Array.isArray(goalTab)) {
      httpParams = httpParams.append('goalTab', goalTab);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-user-goals-for-manager/', {
      params: httpParams
    });
  }

  getObjectiveWeightsByProfile(userId: string, perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-max-goal-weights-for-manager-by-profile/', {
      params: httpParams
    });
  }

  // Recupera le Care options per gli obiettivi
  getCareOptions(fromRecord: string, numRecords: string, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-care-tags/', {
      params: httpParams
    });
  }

  // Recupera le Behaviour options per gli obiettivi
  getBehaviourOptions(fromRecord: string, numRecords: string, allData?: boolean, parentTagId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (allData) {
      httpParams = httpParams.append('allData', allData.toString());
    }
    if (parentTagId && parentTagId.length) {
      httpParams = httpParams.append('parentTagId', parentTagId);
    }
    return this.http.get<SenecaResponse<GetBehaviorCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behavior-care-tags/', {
      params: httpParams
    });
  }

  // Crea un obiettivo
  createGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<CreateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-goal-for-manager', {
      perfYear: perfYear,
      perfCareUserPhaseGoals: goal
    });
  }

  // Aggiorna un obiettivo
  updateGoal(perfYear: number, goal: PerfCareUserPhaseGoal) {
    return this.http.post<SenecaResponse<UpdateUserGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-goal-for-manager', {
      perfYear: perfYear,
      perfCareUserPhaseGoal: goal
    });
  }

  // Recupera l'obiettivo per il manager
  getGoalByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, goalId: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('userId', userId);

    return this.http.get<SenecaResponse<GetGoalByIdForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-goal-by-id-for-manager/', {
      params: httpParams
    });
  }

  // Crea un obietttivo
  canShareGoal(perfYear: number, userId: string) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-propose-goals-to-subordinate', {
      perfYear: perfYear,
      subordinateUserId: userId
    });
  }

  shareGoalToSubordinate(perfYear: number, userId: string) {
    return this.http.post<SenecaResponse<CreateUserGoalForUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/propose-goals-to-subordinate', {
      perfYear: perfYear,
      subordinateUserId: userId
    });

  }

  // Restituisce le giornate disponibili e non
  getSlotCards(attendees: string, startDate: string, endDate: string, availabilityViewInterval: string) {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('attendees', attendees);
    httpParams = httpParams.append('startDate', startDate);
    httpParams = httpParams.append('endDate', endDate);
    httpParams = httpParams.append('availabilityViewInterval', availabilityViewInterval);

    return this.http.get<SenecaResponse<CheckExchangeAgendaForFreeSlotsResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-schedules-slots-availability', {
      params: httpParams
    });
  }

  // Recupera il colloquio da suo interviewId
  getInterviewByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackDialogueId', feedbackDialogueId);

    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  // Crea il colloquio
  createInterviewForPeopleAppraisal(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<CreateUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  // Aggiorna il colloquio
  updateInterviewForPeopleAppraisal(perfYear: number, perfYearCode: string, interview: PerfCareFeedbackDialogue) {
    return this.http.post<SenecaResponse<UpdateUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogue: interview
    });
  }

  deleteSubordinateGoal(perfYear: number, userId: string, goalIds: string[]) {
    return this.http.post<SenecaResponse<DeleteSubordinateGoalForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-subordinate-goal-for-manager', {
      perfYear: perfYear,
      subordinateUserId: userId,
      goalIdsToDelete: goalIds
    });
  }

  getInterviewsByUserId(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  getClosestInterviewByUserId(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<GetUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-closest-user-feedback-dialogue-for-manager/', {
      params: httpParams
    });
  }

  getUserGoalWeightSumForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string, macroGoalType?: string, consuntivationOrder?: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('macroGoalType', macroGoalType || '');
    if (consuntivationOrder || consuntivationOrder == 0) {
      httpParams = httpParams.append('consuntivationOrder', consuntivationOrder.toString());
    }

    return this.http.get<SenecaResponse<GetUserGoalWeightSumForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-goal-weight-sum-for-manager/', {
      params: httpParams
    });
  }

  // Servizio approva obiettivo
  approveGoalProposedByUser(perfYear: number, subordinateUserId: string, comment: string) {
    return this.http.post<SenecaResponse<ApproveGoalProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/approve-goal-proposed-by-subordinate-user', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment
    });
  }

  // Servizio riapprova obiettivo
  riApproveGoalProposedByUser(perfYear: number, subordinateUserId: string, comment: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-approve-goal-comment-proposed-by-subordinate-user', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment
    });
  }

  // Annulla il colloquio
  deleteInterview(perfYear: number, perfYearCode: string, feedbackDialogueId: string) {
    return this.http.post<SenecaResponse<CancelUserFeedbackDialogueForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cancel-user-feedback-dialogue-for-manager', {
      perfYear: perfYear,
      feedbackDialogueId: feedbackDialogueId
    });
  }

  // Feedback competenza CARE utente
  setUserCareCompetenceFeedback(perfYear: number, perfYearCode: string, subordinateUserId: string, comment: string, goalId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-user-care-competence-feedback', {
      perfYear: perfYear,
      subordinateUserId: subordinateUserId,
      comment: comment,
      goalId: goalId
    });
  }

  // Aggiunge link ai goal operation
  createLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, nameLink: string, link: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      nameLink: nameLink,
      link: link
    });
  }

  deleteLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId
    });
  }

  updateLinkForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, linkId: string, linkTitle: string, url: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-link-for-goal-for-manager', {
      perfYear: perfYear,
      goalId: goalId,
      goalUserId: userId,
      linkId: linkId,
      nameLink: linkTitle,
      link: url
    });
  }


  uploadFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-goal-for-manager', formData, options);
    return this.http.request(req);
  }

  updateFileForGoal(perfYear: number, perfYearCode: string, goalId: string, userId: string, fileTitle: string, uploadObj: any, uploadId: string) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('goalId', goalId);
    formData.append('userId', userId);
    formData.append('perfYear', perfYear.toString());
    formData.append('uploadId', uploadId)

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-file-for-goal', formData, options);
    return this.http.request(req);
  }

  deleteFileForGoal(perfYear: number, perfYearCode: string, userId: string, uploadId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-goal-for-manager', {
      perfYear: perfYear,
      userId: userId,
      uploadId: uploadId
    });
  }

  // Se l'utente ha già scaricato il STI
  getUserWatchedSTIRule(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/has-user-watched-sti-rule/', {
      params: httpParams
    });
  }
  // Recupero le azioni di sviluppo
  getDevelopmentActions(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('forUserId', userId);
    return this.http.get<SenecaResponse<ListDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-development-action-for-manager/', {
      params: httpParams
    });
  }

  // Recupero il booleano per sapere se si possono creare azioni di sviluppo
  canCreateDevelopmentActions(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<CanCreateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-create-development-action-for-manager/', {
      params: httpParams
    });
  }

  // Crea applauso smart feedback
  createClap(applauseFeedback: PerfCareApplauseFeedback[], userIdsForCreateFeedback: string[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-applause-feedback-for-manager', {
      applauseFeedback: applauseFeedback,
      userIdsForCreateFeedback: userIdsForCreateFeedback
    });
  }
  // Elimina applauso smart feedback
  deleteClap(applauseFeedbackId: string, userId: string, perfYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-applause-feedback-for-manager', {
      applauseFeedbackId: applauseFeedbackId,
      userId: userId,
      perfYear: perfYear
    });
  }

  // Aggiorna applauso smart feedback
  updateClap(applauseFeedback: PerfCareApplauseFeedback) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-applause-feedback-for-manager', {
      applauseFeedback: applauseFeedback
    });
  }

  // Ritorna applauso dato l'id per smart feedback
  getClapById(perfYear: number, perfYearCode: string, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-applause-feedback-for-manager/', {
      params: httpParams
    });
  }
  // Crea un azione di sviluppo
  createDevelopAction(perfYear: number, developmentAction: any, forUserId: string) {
    return this.http.post<SenecaResponse<CreateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-for-manager', {
      perfYear: perfYear,
      developmentAction: developmentAction,
      forUserId: forUserId
    });
  }

  // Aggiorna un azione di sviluppo
  updateDevelopAction(perfYear: number, forUserId: string, developmentAction: PerfCareDevelopmentAction[]) {
    return this.http.post<SenecaResponse<UpdateDevelopmentActionForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-for-manager', {
      perfYear: perfYear,
      developmentAction: developmentAction,
      forUserId: forUserId
    });
  }

  // Recupera l'obiettivo per il manager
  getDevelopActionByIdForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('developmentActionId', developmentActionId);
    httpParams = httpParams.append('forUserId', userId);

    return this.http.get<SenecaResponse<GetGoalByIdForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-development-action-by-id-for-manager/', {
      params: httpParams
    });
  }

  // Aggiunge il commento ad un azione di sviluppo
  addCommentToDevelopPlan(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-action-comment-for-manager', {
      perfYear: perfYear,
      userId: userId,
      developmentActionId: developmentActionId,
      comment: comment
    });
  }

  // Aggiorna il commento ad un azione di sviluppo
  updateCommentForDevelopPlan(perfYear: number, perfYearCode: string, userId: string, developmentActionId: string, commentId: string, comment: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-action-comment-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      userId: userId,
      comment: comment,
      commentId: commentId
    });
  }

  deleteCommentToDevelopPlan(perfYear: number, perfYearCode: string, developmentActionId: string, commentId: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-comment-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      commentId: commentId,
      userId: userId,
    });
  }

  // Condividi le azioni di sviluppo
  shareDevelopActions(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-development-actions-to-user', {
      perfYear: perfYear,
      userId: userId
    });
  }
  // Recupera l'url per fare il download del file
  retrieveNewMaskedUrlForUploadForPeopleAppraisal(uploadId: string, goalId: string, perfYear: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('goalId', goalId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-masked-url-for-upload-for-manager/', {
      params: httpParams
    });
  }

  // Ritorna la lista di applausi di smart feedback
  getClapList(perfYear: number, userIds: string | string[], received?: boolean, sent?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (userIds && userIds.length && Array.isArray(userIds)) {
      userIds && userIds.forEach(type => {
        httpParams = httpParams.append('userIds', type);
      });
    } else if (userIds && userIds.length && !Array.isArray(userIds)) {
      httpParams = httpParams.append('userIds', userIds);
    }

    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }

    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-applause-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Recupera il count degli utenti per applausi
  countSendClapUsers(perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  // Recupera la lista degli utenti per applausi
  listSendClapUsers(fromRecord: number, numRecords: number, perfYear: number, searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords.toString() || '16');
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText);
    }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }
  // Pulisce la novita degli attributi CARE
  setCareAttributeGoalsAsSeenForPeopleAppraisal(perfYear: number, perfYearCode: string, userId: string) {
    return this.http.post<SenecaResponse<SetCareAttributeGoalsAsSeenForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-care-attribute-goals-as-seen-for-manager', {
      perfYear: perfYear,
      userId: userId
    });
  }

  // Elimina l'azione di sviluppo
  deleteDevelopmentActionForPeopleAppraisal(perfYear: number, perfYearCode: string, developmentActionId: string, subordinateUserId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-development-action-for-manager', {
      perfYear: perfYear,
      developmentActionId: developmentActionId,
      subordinateUserId: subordinateUserId
    });
  }

  // Se può condividere le azioni di sviluppo
  canShareDevelopmentActions(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<CanShareDevelopmentActionToUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-share-development-action-to-user/', {
      params: httpParams
    });
  }

  listMyBadges(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-obtained-badges/', {
      params: httpParams
    });
  }

  uploadAvatar(uploadObj: any) {
    let formData = new FormData();
    formData.append('file', uploadObj);
    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-avatar', formData, options);
    return this.http.request(req);
  }

  listTeamMembers(perfYear: number, filterByPhaseStatuses: MapById<string | string[]>, searchedText?: string) {
    return this.http.post<SenecaResponse<GetPerformanceCareTagsResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/monitoring-subordinates-for-manager/', {
      perfYear: perfYear,
      filterByPhaseStatuses: filterByPhaseStatuses,
      searchedText: searchedText
    });
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociateWithEvidence(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-valid-goals-to-associate-for-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Crea l'evidenza
  createEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<CreateEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-evidence-feedback-for-manager', {
      evidenceFeedback: feedbackObject
    });
  }

  // Aggiorna l'evidenza
  updateEvidenceFeedback(feedbackObject: any) {
    return this.http.post<SenecaResponse<UpdateEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-evidence-feedback-for-manager', {
      evidenceFeedback: feedbackObject
    });
  }

  // Lista delle evidenze
  getEvidences(userId: string, perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Recupera l'evidenza
  getEvidenceFeedback(userId: string, perfYear: number, feedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('feedbackId', feedbackId);
    return this.http.get<SenecaResponse<ListEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-evidence-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Upload file in una evidenza
  uploadFileForEvidence(userId: string, perfYear: string, evidenceId: string, fileTitle: string, uploadObj: any) {
    let formData = new FormData();
    formData.append('userId', userId);
    formData.append('file', uploadObj);
    formData.append('fileName', fileTitle);
    formData.append('evidenceId', evidenceId);
    formData.append('perfYear', perfYear.toString());

    let params = new HttpParams();
    const options = {
      params: params
    };
    const req = new HttpRequest('POST', this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/upload-file-for-evidence-feedback-for-manager', formData, options);
    return this.http.request(req);
  }

  // Download file nell'evidenza
  retrieveNewMaskedUrlForUploadForEvidence(userId: string, uploadId: string, evidenceId: string, perfYear: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('uploadId', uploadId);
    httpParams = httpParams.append('evidenceId', evidenceId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-new-evicende-feedback-masked-url-upload-for-manager/', {
      params: httpParams
    });
  }

  // Cancella file nell'evidenza
  deleteFileForEvidence(userId: string, perfYear: string, evidenceId: string, uploadId: string) {
    return this.http.post<SenecaResponse<DeleteFileForEvidenceFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-file-for-evidence-feedback-for-manager', {
      perfYear: perfYear,
      evidenceId: evidenceId,
      uploadId: uploadId,
      userId: userId
    });
  }

  // Recupera i counter delle tab del feedback
  getCounterFeedbackTabs(userId: string, perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<CountFeedbacksForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-feedbacks-for-manager', {
      params: httpParams
    });
  }

  getPrivateNotesList(perfYear: number, perfYearCode: string, userId: string, privateNoteFeedbackId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (privateNoteFeedbackId) {
      httpParams = httpParams.append('privateNoteFeedbackId', privateNoteFeedbackId);
    }
    return this.http.get<SenecaResponse<ListPrivateNoteFeedbackForManagerResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-private-note-feedback-for-manager/', {
      params: httpParams
    });
  }

  createPrivateNote(privateFeedback: any[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-private-note-feedback-for-manager', {
      privateFeedback: privateFeedback
    });
  }

  deletePrivateNote(perfYear: number, perfYearCode: string, privateFeedbackNoteId: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-private-note-feedback-for-manager', {
      privateFeedbackNoteId: privateFeedbackNoteId,
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
    });
  }

  // Lista usenti paginata e ricerca per appunti privati
  countTeamUsers(perfYear: number, perfYearCode: string, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('searchedText', searchUserText);
    httpParams = httpParams.append('onlySubordinatesInProcess', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-subordinate-users/', {
      params: httpParams
    });
  }

  listTeamUsers(perfYear: number, perfYearCode: string, fromRecord: number, numRecords: number, searchUserText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    httpParams = httpParams.append('searchedText', searchUserText);
    httpParams = httpParams.append('onlySubordinatesInProcess', 'true');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-subordinate-users/', {
      params: httpParams
    });
  }


  canShareEditedObjectives(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/can-propose-goals-to-subordinate-after-sharing/', {
      params: httpParams
    });
  }

  afterSharingGoalToSubordinate(perfYear: number, subordinateUserId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/propose-goals-to-subordinate-after-sharing/', {
      params: httpParams
    });
  }

  cancelGoalAfterSharing(perfYear: number, subordinateUserId: string, goalId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('subordinateUserId', subordinateUserId);
    if (goalId) {
      httpParams = httpParams.append('goalId', goalId);
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-draft-goals-for-manager-after-sharing/', {
      params: httpParams
    });
  }

  listPeerFeedback(perfYear: number, userId: string, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-peer-feedback-for-manager/', {
      params: httpParams
    });
  }

  // Dati box home page mid term review
  getMidTermReviewBoxData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-box-data-for-manager/', {
      params: httpParams
    });
  }

  getPeerFeedbackById(perfYear: number, userId: string, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-peer-feedback-for-manager', {
      params: httpParams
    });
  }

  manageMidTermReviewData(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/manage-mid-term-review-data-for-manager/', {
      params: httpParams
    });
  }

  updatePeerFeedback(perfYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-peer-feedback-for-manager', {
      perfYear: perfYear,
      peerFeedback: peerFeedback
    });
  }

  deletePeerFeedback(perfYear: number, userId: string, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-peer-feedback-for-manager', {
      params: httpParams
    });
  }

  // funzioni feedback per manager senza profilo self assessment
  listExternalPeerFeedback(perfYear: number, received: boolean, sent: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    if (received) {
      httpParams = httpParams.append('received', 'true');
    }
    if (sent) {
      httpParams = httpParams.append('sent', 'true');
    }
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-peer-feedback-for-user/', {
      params: httpParams
    });
  }

  updateExternalPeerFeedback(perfYear: number, peerFeedback: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-peer-feedback-for-user', {
      peerFeedback: peerFeedback,
      perfYear: perfYear
    });
  }

  getExternalPeerFeedbackById(perfYear: number, peerFeedbackId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('peerFeedbackId', peerFeedbackId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-peer-feedback-for-user', {
      params: httpParams
    });
  }

  getMidTermGoals(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-mid-term-review-revision-data-for-manager/', {
      params: httpParams
    });
  }

  setMidTermReviewRevision(perfYear: number, userId: string, midTermReviewManagerObservation: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-mid-term-review-revision-data-for-manager', {
      perfYear: perfYear,
      userId: userId,
      midTermReviewManagerObservation: midTermReviewManagerObservation,
    });
  }

  setObservationForSubordinateGoal(perfYear: number, userId: string, goalId: string, observation: string, observationScore: string) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-observation-for-subordinate-goal', {
      perfYear: perfYear,
      userId: userId,
      goalId: goalId,
      observation: observation,
      observationScore: observationScore,
    });
  }

  getUserFinalEvaluation(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-final-evaluation-for-manager', {
      params: httpParams
    });
  }

  setUserFinalEvaluation(perfYear: number, userFinalEvaluation: any) {
    return this.http.post<EnrichedSenecaResponseForBadges<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/set-final-evaluation-observation-for-manager', {
      perfYear: perfYear,
      userFinalEvaluation: userFinalEvaluation
    });
  }

  getPayoutRange() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-payout-range-by-final-evaluation', {});
  }

  getSuggestedFinalEvaluation(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-suggested-final-evaluation-for-manager', {
      params: httpParams
    })
  }

  getFinalEvaluationDate(perfYear: number) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-final-evaluation-dates', {
      perfYear: perfYear
    });
  }

  getPerformanceAssessmentYears(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-years', {
      params: httpParams
    });
  }

  getCalibrationDataForPeopleAppraisal(perfYear: number, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-calibration-data-for-manager', {
      params: httpParams
    })
  }


  confirmPostCalibration(perfYear: number, userId: string, peopleAppraisalObservation: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/confirm-evaluation-observation-post-calibration-for-manager', {
      perfYear: perfYear,
      userId: userId,
      managerObservation: peopleAppraisalObservation
    });
  }

  isProcessClosed(perfYear: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/is-process-closed-by-year', {
      params: httpParams
    })
  }

  // VECCHI SERVIZI FINE

  getUserPhaseDataForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-user-phase-completion-data-for-manager', {
      params: httpParams
    })
  }

  getUserGoalListToEvaluate(perfYear: number, perfYearCode: string, targetUserIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (targetUserIds && targetUserIds.length) {
      for (let i = 0; i < targetUserIds.length; i++) {
        httpParams = httpParams.append('targetUserIds', targetUserIds[i]);
      }
    }
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-to-evaluate-for-manager', {
      params: httpParams
    })
  }

  getUserGoalList(perfYear: number, perfYearCode: string, targetUserIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (targetUserIds && targetUserIds.length) {
      for (let i = 0; i < targetUserIds.length; i++) {
        httpParams = httpParams.append('targetUserIds', targetUserIds[i]);
      }
    }
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-goals-for-manager', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentGoalsForManager(userId: string, goals: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goals-for-manager', {
      userId: userId,
      goals: goals
    });
  }

  updatePerformanceAssessmentGoalsBySubordinateForManager(userId: string, goals: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-performance-assessment-proposed-goals-by-subordinate-for-manager', {
      userId: userId,
      goals: goals
    });
  }

  deletePerformanceAssessmentGoalsForManager(userId: string, goalIdsToDelete: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-performance-assessment-goals-for-manager', {
      userId: userId,
      goalIdsToDelete: goalIdsToDelete
    });
  }

  deletePerformanceAssessmentGoalsBySubordinateForManager(userId: string, goalIdsToDelete: any) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-proposed-goals-by-subordinate-for-manager', {
      userId: userId,
      goalIdsToDelete: goalIdsToDelete
    });
  }

  createOrUpdatePerformanceAssessmentGalEvaluationForManager(userId: string, evaluation: { goalId: string, evaluationText: string }[]) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-goal-evaluation-for-manager', {
      userId: userId,
      evaluation: evaluation
    });
  }

  getBehavioursToEvaluateForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-behaviors-to-evaluate-for-manager', {
      params: httpParams
    })
  }


  createOrUpdatePerformanceAssessmentBehaviorEvaluationForManager(userId: string, behaviorEvaluation?: { behaviorId: string, evaluationScore: string } | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-behavior-evaluation-for-manager', {
      userId: userId,
      behaviorEvaluation: behaviorEvaluation,
      overallComment: overallComment
    });
  }


  getCompetenceToEvaluateForManager(perfYear: number, perfYearCode: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode.toString());
    httpParams = httpParams.append('userId', userId);
    return this.http.get<SenecaResponse<PerfAlloyBehavior[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-competences-to-evaluate-for-manager', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentCompetenceEvaluationForManager(userId: string, competenceEvaluation: { competenceId: string, evaluationScore: number }[] | null, overallComment?: string | null) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-competence-evaluation-for-manager', {
      userId: userId,
      competenceEvaluation: competenceEvaluation,
      overallComment: overallComment
    });
  }

  getPerformanceAssessmentFinalEvaluationDataForManager(perfYear: string, perfYearCode: string, targetUserIds: string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (targetUserIds && targetUserIds.length) {
      for (let i = 0; i < targetUserIds.length; i++) {
        httpParams = httpParams.append('targetUserId', targetUserIds[i]);
      }
    }
    return this.http.get<SenecaResponse<PerfAlloyUserGoal[]>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-final-evaluation-data-for-manager', {
      params: httpParams
    })
  }

  createOrUpdatePerformanceAssessmentFinalEvaluationCommentForManager(userId: string, strongPoints?: string, developmentAreas?: string, finalEvaluationComment?: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-or-update-performance-assessment-final-evaluation-comment-for-manager', {
      userId: userId,
      strongPoints: strongPoints,
      developmentAreas: developmentAreas,
      finalEvaluationComment: finalEvaluationComment
    });
  }


  sendFinalEvaluationFormForManager(userId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-final-evaluation-form-for-manager', {
      userId: userId
    });
  }

  // Condivide con il collaboratore la scheda finale, dopo che l'hr l'ha ritornata al manager
  sharePostCalibrationForManager(userId: string, isFeedbackInterviewDone?: boolean) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/share-post-calibration-form-for-manager', {
      targetUserIds: userId,
      isFeedbackInterviewDone: isFeedbackInterviewDone
    });
  }

  getDashboardSelfAssessment(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-self-assessment-for-manager/', {
      params: httpParams
    });
  }

  getDashboardPeopleAppraisal(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-people-appraisal-for-manager/', {
      params: httpParams
    });
  }

  getDashboardCalibration(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-calibration-for-manager/', {
      params: httpParams
    });
  }

  getDashboardPas(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-restituzione-pas-for-manager/', {
      params: httpParams
    });
  }

  getDashboardBehaviorCompetence(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-behaviour-and-competences-for-manager/', {
      params: httpParams
    });
  }

  getDashboardOverview(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    httpParams = httpParams.append('entireStructure', entireStructure ? 'true' : 'false');

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-final-overview-for-manager/', {
      params: httpParams
    });
  }

  downloadDashboardReport(perfYear: number, perfYearCode: string, filters?: any, entireStructure?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());

    httpParams = httpParams.append('perfYearCode', perfYearCode);

    if (entireStructure) {
      httpParams = httpParams.append('entireStructure', 'true');
    }

    // if (filters) {
    //   if (filters.subcompany && filters.subcompany.value) {
    //     httpParams = httpParams.append('socDistacco', filters.subcompany.value);
    //   }
    //   if (filters.cdcfinance && filters.cdcfinance.value) {
    //     httpParams = httpParams.append('cdcFinance', filters.cdcfinance.value);
    //   }
    //   if (filters.areaReference && filters.areaReference.value) {
    //     httpParams = httpParams.append('ramo', filters.areaReference.value);
    //   }
    //   if (filters.department && filters.department.value) {
    //     httpParams = httpParams.append('defTpDip', filters.department.value);
    //   }
    //   if (filters.team && filters.team.value) {
    //     httpParams = httpParams.append('team', filters.team.value);
    //   }
    //   if (filters.subTeam && filters.subTeam.value) {
    //     httpParams = httpParams.append('subTeam', filters.subTeam.value);
    //   }
    //   if (filters.position && filters.position.value) {
    //     httpParams = httpParams.append('chief', filters.position.value);
    //   }
    //   if (filters.supervisor && filters.supervisor.value) {
    //     httpParams = httpParams.append('supervisor', filters.supervisor.value);
    //   }
    //   if (filters.contractual && filters.contractual.value) {
    //     httpParams = httpParams.append('tipologiaContratto', filters.contractual.value);
    //   }
    //   if (filters.office && filters.office.value) {
    //     httpParams = httpParams.append('sedeLavoro', filters.office.value);
    //   }
    //   if (filters.selfStatus && filters.selfStatus.value) {
    //     httpParams = httpParams.append('selfAssessmentStatus', filters.selfStatus.value);
    //   }
    //   if (filters.peopleStatus && filters.peopleStatus.value) {
    //     httpParams = httpParams.append('appraisalStatus', filters.peopleStatus.value);
    //   }
    //   if (filters.calibrationStatus && filters.calibrationStatus.value) {
    //     for (let i = 0; i < filters.calibrationStatus.value.length; i++) {
    //       httpParams = httpParams.append('postCalibrationStatus', filters.calibrationStatus.value[i]);
    //     }
    //   }
    // }

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-dashboard-report-for-manager', {
      params: httpParams
    })
  }

  getCalculatedStarsCountByAvg(avg: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('avg', avg);
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-calculated-stars-count-by-avg', {
      params: httpParams
    })
  }
  // Recupero dati cpp lato manager
  getCppInfoForManager() {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('getCollaboratorsData', 'true');
    return this.http.get<SenecaResponse<AlloyPerformanceCppServiceResponses.GetCppInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-info-for-manager', {
      params: httpParams
    })
  }

  // Scarico report cpp lato manager
  downloadCppReport(roundId: string, userIds: string | string[]) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    if (userIds && userIds.length && Array.isArray(userIds)) {
      userIds && userIds.forEach(type => {
        httpParams = httpParams.append('userIds', type);
      });
    } else if (userIds && userIds.length && !Array.isArray(userIds)) {
      httpParams = httpParams.append('userIds', userIds);
    }

    return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/export-cpp-survey-for-manager', {
      params: httpParams
    })
  }

  // Informazioni Upward Feedback per la home
  getUpwardFeedbackInfo() {
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-info-for-manager')
  }

  // Recupera i risultati personali del manager
  getUpwardFeedbackResults(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-manager', {
      params: httpParams
    })
  }

  // Recupera i dati del manager per i manager di secondo livello
  getUpwardFeedbackResultForChief(roundId: string, subordinateId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("subordinateId", subordinateId);
    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackResultsForChief>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-chief', {
      params: httpParams
    })
  }


  forcePostCheck(userId: string, assessmentId: string, perfYear: string, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("assessmentId", assessmentId);
    httpParams = httpParams.append("perfYear", perfYear);
    httpParams = httpParams.append("perfYearCode", perfYearCode);
    let isManager = false;

    if (isManager) {
      return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/process-post-calibration-data-for-manager-internal', {
        params: httpParams
      })
    } else {
      return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/process-post-calibration-data', {
        params: httpParams
      })
    }
  }

  getDevelopmentPlan(userId: string, roundId: string, withActionsLoaded: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("withActionsLoaded", withActionsLoaded ? 'true' : 'false');
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-development-plan-for-manager', {
      params: httpParams
    })
  }

  getCppUserSurveyCompetenceDetails(userId: string, roundId: string, competenceId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('competenceId', competenceId);
    return this.http.get<SenecaResponse<AlloyPerformanceCppParams.CppSurveyCompetenceDetails>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cpp-subordinate-survey-competence-details/', {
      params: httpParams
    })
  }

  // Invia il commento del chief al manager di cui è responsabile
  sendUpwardFeedbackComment(params: AlloyPerformanceUpwardFeedbackParams.SendUpwardFeedbackCommentForChief) {
    return this.http.post<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.SendUpwardFeedbackCommentForChief>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-upwardfeedback-comment-for-chief', {
      roundId: params.roundId,
      subordinateId: params.subordinateId,
      comment: params.comment,
    });
  }

  getPerformanceAsessmentInfoForUser() {
    return this.http.get<SenecaResponse<GetPerformanceAssessmentInfoForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-asessment-info-for-manager/', {
    })
  }

  countCppUsers(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    /* return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-results-for-chief', {
      params: httpParams
    }) */

    return of(new SenecaResponse(null, 2));
  }

  updateDevelopmentPlanForManager(userId: string, roundId: string, actions: Array<any>, growthTarget?: string, competenceUpdates?: string) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-development-plan-for-manager', {
      userId: userId,
      roundId: roundId,
      growthTarget: growthTarget,
      competenceUpdates: competenceUpdates,
      actions: actions
    })
  }

  createUpdatesOfCompetenceForManager(userId: string, roundId: string, competenceId: string, updates: Array<{ updateText: string }>) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-updates-of-competence-for-manager', {
      userId: userId,
      roundId: roundId,
      updates: updates,
      competenceId: competenceId,
    })
  }

  createUpdatesOfActionForManager(userId: string, roundId: string, action: Array<any>) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-updates-of-action-for-manager', {
      userId: userId,
      roundId: roundId,
      action: action
    })
  }

  createActionOfCompetenceForManager(userId: string, roundId: string, actions:
    Array<{
      competenceId: string;
      type: string;
      scope: string;
      endDate: Date;
    }>) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-action-of-competence-for-manager', {
      userId: userId,
      roundId: roundId,
      actions: actions
    })
  }

  updateActionOfCompetenceForManager(userId: string, roundId: string, action: {
    competenceId: string,
    actionId: string,
    type: string,
    scope: string,
    endDate: Date
  }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-action-of-competence-for-manager', {
      userId: userId,
      roundId: roundId,
      action: action
    })
  }

  deleteActionOfCompetenceForManager(userId: string, actionId: string, roundId: string) {
    return this.http.post<SenecaResponse<UpdateApproveGoalCommentProposedBySubordinateUserResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/delete-action-of-competence-for-manager', {
      userId: userId,
      actionId: actionId,
      roundId: roundId
    });
  }

  createDevelopmentPlanForManager(roundId: string, userId: string, competenceIds: Array<string>) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/create-development-plan-for-manager', {
      userId: userId,
      roundId: roundId,
      competenceIds: competenceIds
    });
  }

  getMyCppSubordinates(roundId: string, calcAdequacy: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("calcAdequacy", calcAdequacy ? 'true' : 'false');

    return this.http.get<SenecaResponse<AlloyPerformanceCppParams.ListMyCppSubordinates>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-cpp-subordinates', {
      params: httpParams
    })
  }

  getCppSubordinateDetails(roundId: string, userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("userId", userId);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cpp-subordinate-details', {
      params: httpParams
    })
  }

  createCompetenceUpdateCppComment(roundId: string, userId: string, comment: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    httpParams = httpParams.append("userId", userId);
    httpParams = httpParams.append("comment", comment);

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cpp-create-competence-update-comment', {
      params: httpParams
    })
  }

  getListCompetenceUpdateCppComments(roundId: string, userId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("roundId", roundId);
    if (userId) {
      httpParams = httpParams.append("userId", userId);
    }
    return this.http.get<SenecaResponse<Array<AlloyPerformanceCppServiceResponses.CppCompetenceUpdateComment>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/cpp-list-competence-update-comments', {
      params: httpParams
    })
  }

  getCppRounds(allData: boolean, fromRecord?: string, numRecords?: string, roundIds?: Array<string>) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord || '0');
    httpParams = httpParams.append('numRecords', numRecords || '0');
    httpParams = httpParams.append('allData', allData ? 'true' : 'false')

    if (roundIds?.length) {
      for (let i = 0; i < roundIds.length; i++) {
        httpParams = httpParams.append('roundIds', roundIds[i]);
      }
    }
    return this.http.get<SenecaResponse<Array<AlloyPerformanceCpp.CppRound>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-cpp-rounds-for-manager/', {
      params: httpParams
    });
  }

  // list-cpp-action-type-for-manager
  listCppActionTypeForManager() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-cpp-action-type-for-manager/', {
    })
  }

  listCppExpectedObjectivesForManager() {
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-cpp-expected-objectives-for-manager/', {
    })
  }

  updateUpdatesOfCompetenceForManager(userId: string, roundId: string, competenceId: string, updates: {
    updateId: string;
    updateText: string;
  }[]) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-updates-of-competence-for-manager', {
      roundId: roundId,
      competenceId: competenceId,
      updates: updates,
      userId: userId
    });
  }
  updateUpdatesOfActionForManager(userId: string, roundId: string, action: {
    competenceId: string;
    actionId: string;
    type: string;
    scope: string;
    endDate: Date;
    updates: {
      updateId: string;
      updateText: string;
    };
  }) {
    return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/update-updates-of-action-for-manager', {
      roundId: roundId,
      action: action,
      userId: userId
    });
  }
  // Ritorna il numero di round di UpwardFeedback a cui il manager ha partecipato
  countUpwardFeedbackRoundsForManager() {
    let httpParams = new HttpParams();
    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-upwardfeedback-rounds-for-manager/', {
      params: httpParams
    });
  }

  // Ritorna la lista dei round di UpwardFeedback a cui il manager ha partecipato
  getUpwardFeedbackRoundsForManager(fromRecord?: number, numRecords?: number, allData?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString() || '0');
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString() || '0');
    httpParams = httpParams.append('allData', allData ? 'true' : 'false')

    return this.http.get<SenecaResponse<Array<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackRoundsForManager>>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-rounds-for-manager/', {
      params: httpParams
    });
  }

  // ritorna i subordinati per l'upward feedback con lo stato della valutazione
  getUpwardFeedbackTeamList(roundId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('roundId', roundId);

    return this.http.get<SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackTeamForManager>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-upwardfeedback-team-for-manager/', {
      params: httpParams
    });
  }

  getInsightFeedbackDetailsForManager(userId: string, perfYear: string, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode)

    return this.http.get<SenecaResponse<FacileOpenAPI.InsightFeedbackPhase>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-insight-feedback-details-for-manager/', {
      params: httpParams
    });
  }


  countMyColleagueUsers(searchedText: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);

    return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/count-my-collegue-users/', {
      params: httpParams
    });
  }

  listMyColleagueUsers(searchedText: string, fromRecord: string, numRecords: string, limitToPerformanceAdvisor?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText);
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    if (limitToPerformanceAdvisor) {
      httpParams = httpParams.append('limitToPerformanceAdvisor', 'true');
    }

    return this.http.get<SenecaResponse<ListMyCollegueUsersResponse>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-my-collegue-users/', {
      params: httpParams
    });
  }

  // Richiede l'insight feedback al matrix manager
  requestInsightFeedbackForManager(perfYear: number, perfYearCode: string, userId: string, managerUserId: string, motivation: string) {
    return this.http.post<SenecaResponse<FacileOpenAPI.InsightFeedbackPhase>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/request-insight-feedback-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      managerUserId: managerUserId,
      motivation: motivation,
    });
  }

  sendInsightFeedbackReminder(perfYear: number, perfYearCode: string, userId: string, insightRentId: string) {
    return this.http.post<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/send-insight-feedback-reminder-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      insightRentId: insightRentId
    });
  }


  getInsightFeedbackDetailsForMatrixManager(perfYear: string, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode)

    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-insight-feedback-info-for-matrix-manager/', {
      params: httpParams
    });
  }

  // ritorna la lista di insight feedback per il matrix manager con lo stato della valutazione
  // Lo userId serve solo per avere i dati riguardanti lo storico
  listInsightFeedbacksForMatrixManager(perfYear: string, perfYearCode: string, userId?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear);
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (userId) {
      httpParams = httpParams.append('userId', userId);
    }
    return this.http.get<SenecaResponse<{ insightFeedbacks: FacileOpenAPI.InsightFeedbackPhase[] }>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/list-insight-feedbacks-for-matrix-manager/', {
      params: httpParams
    });
  }

  answerInsightFeedbackForMatrixManager(perfYear: string, perfYearCode: string, userId: string, developmentAreasComment: string, pointsOfStrengthComment: string) {
    return this.http.post<SenecaResponse<FacileOpenAPI.InsightFeedbackPhase>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/answer-insight-feedback-for-matrix-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      developmentAreasComment: developmentAreasComment,
      pointsOfStrengthComment: pointsOfStrengthComment,
    });
  }
  // Ritorna gli anni dei subordinati per il matrix manager, saltando il controllo sul subordinato
  getPerformanceAssessmentYearsForMatrixManager(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId.toString());
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-performance-assessment-years-for-matrix-manager', {
      params: httpParams
    });
  }

  // Salva commento intergrativo all'insight feedback del manager
  commentInsightFeedbackForManager(perfYear: string, perfYearCode: string, userId: string, insightRentId: string, comment: string) {
    return this.http.post<SenecaResponse<FacileOpenAPI.InsightFeedbackPhase>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/comment-insight-feedback-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      insightRentId: insightRentId,
      comment: comment
    });
  }

  // Rende o meno obbligatorio lo step di richiesta dell'insight feedback del manager
  skipInsightFeedbackForManager(perfYear: string, perfYearCode: string, userId: string, skip: boolean) {
    return this.http.post<SenecaResponse<FacileOpenAPI.InsightFeedbackPhase>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/skip-insight-feedback-for-manager', {
      perfYear: perfYear,
      perfYearCode: perfYearCode,
      userId: userId,
      skip: skip
    });
  }
}



export interface CheckExchangeAgendaForFreeSlotsResponse {
  start?: Date;
  end?: Date;
  startLabel?: string;
  startTimeLabel?: string;
  endTimeLabel?: string;
  persons: number;
  percentage: number;
  unavailablePersonList: Array<string>;
}