import { createAction, props } from '@ngrx/store';
import * as CommonClasses from "../../../../commonclasses";

export const UpdateUser = createAction(
  '[auth] update user',
  props<{ loggedUser: any }>()
);

export const SaveAvailableLangs = createAction(
  '[auth] save available langs',
  props<{ payload: CommonClasses.Lang[] }>()
);

export const CancelLoggedUser = createAction(
  '[auth] cancel logged user'
);

export const DecodeToken = createAction(
  '[auth] decode token',
  props<{ payload: string }>()
);

export const UpdateUserOptions = createAction(
  '[auth] update user options',
  props<{ payload: any }>()
);

export const SetIsChief = createAction(
  '[auth] set is chief',
  props<{ payload: boolean }>()
)