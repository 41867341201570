<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="('verifySend.PAGE_TITLE' | translate) + (selfAssessmentData && selfAssessmentData.forename ? selfAssessmentData.forename : '')"
            [description]="('verifySend.PAGE_DESCR' | translate)">
        </sidenav-informations>
    </div>
    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingUserData">
                <div class="form-container">
                    <!-- Obiettivi valutati -->
                    <div class="evaluated-goals-container">
                        <ng-container *ngFor="let goal of evaluatedGoalList; let i = index">
                            <div class="goal-container">
                                <p class="title">{{ ('verifySend.OBJECTIVE_NUM' | translate ) + (i + 1) }}</p>
                                <p class="text">{{ goal.text }}</p>
                                <div class="goal-actions">
                                    <p translate="menu.MANAGER" (click)="readGoalEvaluation(goal, 'manager')"></p>
                                    <p translate="menu.SELFASSESSMENT"
                                        (click)="readGoalEvaluation(goal, 'subordinate')">
                                    </p>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Comportamenti organizzativi-->
                    <div class="competence-behavior-container" *ngIf="behaviorData">
                        <div class="title-container">
                            <p class="title" translate="verifySend.BEHAVIOR_EVALUATION_TITLE"></p>
                            <p class="link" translate="generic.DETAILS"
                                (click)="openBehaviorCompetenceDetailsModal(false)">
                            </p>
                        </div>
                        <!-- valutazione people appraisal -->
                        <div class="behavior-competence-row"
                            *ngIf="behaviorData && behaviorData.managerValue && behaviorData.managerRating">
                            <div class="section" *ngIf="!isLeadershipTeam">
                                <p class="value">{{ behaviorData.managerValue}}</p>
                                <star-rating customContainerClass="small-star-size min-width-auto"
                                    [forceRating]="behaviorData.managerRating" [disabled]="true" [hideLabel]="true"
                                    [isRecapAverage]="true">
                                </star-rating>
                            </div>
                            <div class="section">
                                <p class="link" translate="verifySend.READ_COMMENTS"
                                    (click)="openBehaviorCompetenceCommentsModal('manager', 'behavior')"></p>
                                <avatar-img [user]="peopleAppraisalData" size="small"></avatar-img>
                                <p class="user-type" translate="menu.MANAGER"></p>
                            </div>
                        </div>
                        <!-- valutazione self assessment -->
                        <div class="behavior-competence-row self">
                            <div class="section" *ngIf="!isLeadershipTeam">
                                <p class="value">{{ behaviorData.subordinateValue}}</p>
                                <star-rating customContainerClass="small-star-size min-width-auto"
                                    [forceRating]="behaviorData.subordinateRating" [disabled]="true" [hideLabel]="true"
                                    [grayColor]="true" [isRecapAverage]="true">
                                </star-rating>
                            </div>
                            <div class="section">
                                <p class="link" translate="verifySend.READ_COMMENTS"
                                    (click)="openBehaviorCompetenceCommentsModal('subordinate', 'behavior')"></p>
                                <avatar-img [user]="selfAssessmentData" size="small"></avatar-img>
                                <p class="user-type" translate="menu.SELFASSESSMENT"></p>
                            </div>
                        </div>
                    </div>
                    <div class="divisor-line" *ngIf="!isLeadershipTeam && !isIT"></div>
                    <!-- Competenze tecniche -->
                    <div class="competence-behavior-container" *ngIf="!isLeadershipTeam && !isIT && competenceData">
                        <div class="title-container">
                            <p class="title" translate="verifySend.COMPETENCE_EVALUATION_TITLE"></p>
                            <p class="link" translate="generic.DETAILS"
                                (click)="openBehaviorCompetenceDetailsModal(true)">
                            </p>
                        </div>

                        <!-- valutazione people appraisal -->
                        <div class="behavior-competence-row">
                            <div class="section">
                                <p class="value">{{ competenceData.managerValue}}</p>
                                <star-rating customContainerClass="small-star-size min-width-auto"
                                    [forceRating]="competenceData.managerRating" [disabled]="true" [hideLabel]="true"
                                    [isRecapAverage]="true">
                                </star-rating>
                            </div>
                            <div class="section">
                                <p class="link" translate="verifySend.READ_COMMENTS"
                                    (click)="openBehaviorCompetenceCommentsModal('manager', 'competence')"></p>
                                <avatar-img [user]="peopleAppraisalData" size="small"></avatar-img>
                                <p class="user-type" translate="menu.MANAGER"></p>
                            </div>
                        </div>
                        <!-- valutazione self assessment -->
                        <div class="behavior-competence-row self">
                            <div class="section">
                                <p class="value">{{ competenceData.subordinateValue}}</p>
                                <star-rating customContainerClass="small-star-size min-width-auto"
                                    [forceRating]="competenceData.subordinateRating" [disabled]="true"
                                    [hideLabel]="true" [grayColor]="true" [isRecapAverage]="true"></star-rating>
                            </div>
                            <div class="section">
                                <p class="link" translate="verifySend.READ_COMMENTS"
                                    (click)="openBehaviorCompetenceCommentsModal('subordinate', 'competence')"></p>
                                <avatar-img [user]="selfAssessmentData" size="small"></avatar-img>
                                <p class="user-type" translate="menu.SELFASSESSMENT"></p>
                            </div>
                        </div>
                    </div>
                    <div class="divisor-line"></div>

                    <!-- Obiettivi di sviluppo -->
                    <div class="generic-container" *ngIf="!isLeadershipTeam">
                        <p *ngIf="!isIT" class="title">{{ ('verifySend.DEVELOPMENT_OBJECTIVE' | translate ) + ' ' +
                            getYearName() }}</p>
                        <p *ngIf="isIT" class="title">{{ "Obiettivi tecnico specialistici" + ' ' + getYearName() }}</p>
                        <ng-container *ngFor="let data of developmentObjectives">
                            <text-goal
                                [subtitle]="data.proposedGoals?.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + data.proposedGoals?.creationUser?.forename + ' ' + data.proposedGoals?.creationUser?.surname) : ''"
                                [title]="data.proposedGoals.text" [showMenu]="false" [canEdit]="false"></text-goal>
                        </ng-container>
                    </div>
                    <div class="divisor-line" *ngIf="!isLeadershipTeam"></div>

                    <!-- commento finale -->
                    <ng-container *ngIf="!hasNewObservations()">
                        <!-- Contenitore per vecchi commenti-->
                        <div class="generic-container">
                            <p class="title" translate="generic.NOTES_COMMENTS"></p>
                            <evaluation-rating *ngIf="peopleAppraisalData" [user]="peopleAppraisalData"
                                [smallAvatar]="true"
                                [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + peopleAppraisalData.forename"
                                [evaluationText]="managerFinalEvaluationComment">
                            </evaluation-rating>
                            <evaluation-rating *ngIf="selfAssessmentData" [user]="selfAssessmentData"
                                [smallAvatar]="true"
                                [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + selfAssessmentData.forename"
                                [evaluationText]="subordinateFinalEvaluationComment" [isSelf]="true">
                            </evaluation-rating>
                        </div>
                        <div class="divisor-line" *ngIf="!isLeadershipTeam"></div>
                    </ng-container>

                    <!-- Punti di forza e aree di sviluppo -->
                    <div class="generic-container" *ngIf="hasNewObservations()">
                        <p class="title" translate="finalEvaluation.STRENGTH_DEVELOPMENT_TITLE"></p>
                        <summary-card [user]="peopleAppraisalData" [smallAvatar]="true"
                            [observationData]="managerObservations">
                        </summary-card>
                        <summary-card [user]="selfAssessmentData" [smallAvatar]="true"
                            [observationData]="subordinateObservations" [isSelf]="true">
                        </summary-card>
                    </div>



                    <div class="divisor-line" *ngIf="insightFeedback?.length"></div>

                    <div class="generic-container" *ngIf="insightFeedback?.length">
                        <p class="title" translate="insight.NAME"></p>
                        <ng-container *ngFor="let insight of insightFeedback">
                            <summary-card [user]="insight.matrixManager"
                                [observationData]="insight.matrixManagerObservations" [isMatrixManagerFeedback]="true"
                                [isEditDisabled]="true">
                            </summary-card>

                            <summary-card *ngIf="insight?.directManagerComment?.length"
                                [user]="insight.requiredByManager" [matrixManager]="insight.matrixManager"
                                [directManagerComment]="insight.directManagerComment" [isInsightManagerComment]="true"
                                [isEditDisabled]="true">
                            </summary-card>
                        </ng-container>
                    </div>



                    <div class="divisor-line" *ngIf="!isLeadershipTeam"></div>

                    <!-- Profilo PAS -->
                    <div class="generic-container" *ngIf="!isLeadershipTeam">
                        <p class="title" translate="verifySend.RESULTING_PROFILE"></p>
                        <!-- manager -->
                        <div class="profile-container" *ngIf="managerPas">
                            <div class="data">
                                <div class="profile">
                                    <p>{{ managerPas.title }}</p>
                                </div>
                                <p class="description">{{ managerPas.description }}</p>
                                <div class="rating">
                                    <p class="value">{{ managerPas.value }}</p>
                                    <star-rating customContainerClass="small-star-size min-width-auto"
                                        [forceRating]="managerPas.value" [disabled]="true" [hideLabel]="true"
                                        [isRecapAverage]="true">
                                    </star-rating>
                                </div>
                            </div>
                            <avatar-img [user]="peopleAppraisalData" size="small"></avatar-img>
                        </div>
                        <!-- Subordinate -->
                        <div class="profile-container" *ngIf="subordinatePas">
                            <div class="data self">
                                <div class="profile">
                                    <p>{{ subordinatePas.title }}</p>
                                </div>
                                <p class="description">{{ subordinatePas.description }}</p>
                                <div class="rating">
                                    <p class="value">{{ subordinatePas.value }}</p>
                                    <star-rating customContainerClass="small-star-size min-width-auto"
                                        [forceRating]="subordinatePas.value" [disabled]="true" [hideLabel]="true"
                                        [grayColor]="true" [isRecapAverage]="true">
                                    </star-rating>
                                </div>
                            </div>
                            <avatar-img [user]="selfAssessmentData" size="small"></avatar-img>
                        </div>
                    </div>

                    <!-- Commento dell'HR -->
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="hrComment && hrComment.trim().length" [isObbligatory]="false"
                        title="{{'verifySend.COMMENT_FOR_MANAGER' | translate}}">
                        <alloy-text-area [bindValue]="hrComment" [maxlength]="300"
                            (onModelChanged)="hrCommentChanged($event)"
                            placeholder="{{'verifySend.COMMENT_FOR_MANAGER_DESCR' | translate}}">
                        </alloy-text-area>
                    </input-container>

                    <div class="action-container">
                        <alloy-button (onButtonClicked)="openSaveScheduleModal()" [disabled]="isLoadingUserData"
                            type="success bordless" size="large" [label]="'generic.SAVE' | translate">
                        </alloy-button>
                        <alloy-button (onButtonClicked)="openSendScheduleModal()" [disabled]="isLoadingUserData"
                            type="success default" size="large" [label]="'verifySend.SEND' | translate">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="isLoadingUserData">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>

</page-container>

<!-- valutazione obiettivi -->
<modal id="goal-evaluation" modalId="ge001" (onClose)="closeReadGoalEvaluation()">
    <p class="modal-title center">
        {{ ('verifySend.GOAL_EVALUATION' | translate)}}</p>
    <ng-container *ngIf="goalEvaluationModalData && goalEvaluationModalData.user">
        <div class="goal-evaluation-modal-container">
            <p class="subtitle">{{ ('verifySend.EVALUATION_BY' | translate ) + goalEvaluationModalData.user.forename + '
                ' + goalEvaluationModalData.user.surname }}
            </p>
            <ng-container *ngIf="goalEvaluationModalData.rating">
                <div class="container-rating">
                    <p class="text">{{ goalEvaluationModalData.evaluation }}</p>
                    <star-rating [forceRating]="goalEvaluationModalData.rating"
                        customContainerClass="small-star-size small-star-size-rd" [scale]="5" [disabled]="true"
                        [direction]="'row'"></star-rating>
                </div>
            </ng-container>
            <ng-container *ngIf="!goalEvaluationModalData.rating">
                <p class="text">{{ goalEvaluationModalData.evaluation }}</p>
            </ng-container>
        </div>
    </ng-container>

    <div class="modal-action-container single-button">
        <alloy-button [label]="'generic.CLOSE' | translate" type="success"
            (onButtonClicked)="closeReadGoalEvaluation()">
        </alloy-button>
    </div>
</modal>

<!-- Commenti comportamento -->
<modal id="behavior-competence-comments-modal" modalId="bc001" (onConfirm)="closeBehaviorCompetenceCommentsModal()"
    (onClose)="closeBehaviorCompetenceCommentsModal()" confirmLabel="{{'generic.CLOSE' | translate}}">
    <ng-container *ngIf="behaviorCompetenceComment && behaviorCompetenceComment.user">
        <modal-text-content [title]="'verifySend.EVALUATION_COMMENTS' | translate"
            [subtitle]="('verifySend.COMMENT_BY' | translate) + behaviorCompetenceComment.user"
            [text]="behaviorCompetenceComment.text">
        </modal-text-content>
    </ng-container>
</modal>

<!-- Moale dettaglio competenze e comportamenti  -->
<modal id="behavior-competence-details-modal" (onClose)="closeBehaviorCompetenceDetailsModal()"
    (onConfirm)="closeBehaviorCompetenceDetailsModal()" [confirmLabel]="'generic.CLOSE' | translate">
    <ng-container *ngIf="modalDetailList && modalDetailList.length">
        <p class="modal-title center">
            {{ ('verifySend.' + (isCompetenceDetails ? 'COMPETENCE_EVALUATION_TITLE' : 'BEHAVIOR_EVALUATION_TITLE')) |
            translate}}
        </p>
        <div class="scroll-container">
            <div class="rating-container">
                <p></p>
                <p class="header collab" translate="verifySend.COLLABORATOR"></p>
                <p class="header manager" translate="verifySend.MANAGER"></p>
            </div>
            <ng-container *ngFor="let tag of modalDetailList">
                <div class="rating-container">
                    <ng-container *ngIf="tag?.title">
                        <p>{{ tag.title }}</p>
                    </ng-container>
                    <ng-container *ngIf="tag?.description">
                        <p>{{ tag.description }}</p>
                    </ng-container>
                    <div class="rating-star-content">
                        <!-- Leadership -->
                        <ng-container *ngIf="isLeadershipTeam">
                            <!-- subordinato -->
                            <div class="plus-minus-button">
                                <div *ngIf="tag.subordinateEvaluation == '0'" class="round-btn"
                                    [ngClass]="{'clicked': tag.subordinateEvaluation == '0' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/minus.svg">
                                    </svg-icon>
                                </div>
                                <div *ngIf="tag.subordinateEvaluation == '3'" class="round-btn"
                                    [ngClass]="{'clicked': tag.subordinateEvaluation == '3' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/equal.svg">
                                    </svg-icon>
                                </div>
                                <div *ngIf="tag.subordinateEvaluation == '5'" class="round-btn"
                                    [ngClass]="{'clicked': tag.subordinateEvaluation == '5' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/plus.svg">
                                    </svg-icon>
                                </div>
                            </div>
                            <div class="divisor-vertical"></div>
                            <!-- manager -->
                            <div class="plus-minus-button">
                                <div *ngIf="tag.managerEvaluation == '0'" class="round-btn"
                                    [ngClass]="{'clicked': tag.managerEvaluation == '0' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/minus.svg">
                                    </svg-icon>
                                </div>
                                <div *ngIf="tag.managerEvaluation == '3'" class="round-btn"
                                    [ngClass]="{'clicked': tag.managerEvaluation == '3' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/equal.svg">
                                    </svg-icon>
                                </div>
                                <div *ngIf="tag.managerEvaluation == '5'" class="round-btn"
                                    [ngClass]="{'clicked': tag.managerEvaluation == '5' }">
                                    <svg-icon [applyClass]="true" class="icon" src="assets/img/icons/plus.svg">
                                    </svg-icon>
                                </div>
                            </div>
                        </ng-container>
                        <!-- comportamenti e comopetenze classici -->
                        <ng-container *ngIf="!isLeadershipTeam">
                            <star-rating [forceRating]="tag.subordinateEvaluation"
                                customContainerClass="small-star-size" [grayColor]="true" [scale]="5" [disabled]="true">
                            </star-rating>
                            <!-- [rating]="tag.rating" [rating]="tag.selfRating" -->
                            <div class="divisor-vertical"></div>
                            <star-rating [forceRating]="tag.managerEvaluation" customContainerClass="small-star-size"
                                [scale]="5" [disabled]="true"></star-rating>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</modal>

<!-- Modale conferma salva scheda -->
<modal id="save-schedule-modal" (onClose)="closeSaveScheduleModal()" (onConfirm)="closeSaveScheduleModal(true)"
    [confirmLabel]="'verifySend.modals.SAVE_ACTION' | translate" (onCancel)="closeSaveScheduleModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content modalId="vs001" [title]="'verifySend.modals.SAVE_TITLE' | translate"
        [subtitle]="'verifySend.modals.SAVE_SUBTITLE' | translate" [text]="'verifySend.modals.SAVE_DESCR' | translate">
    </modal-text-content>
</modal>

<!-- Modale conferma invio scheda-->
<modal id="send-schedule-modal" (onClose)="closeSendScheduleModal()" (onConfirm)="closeSendScheduleModal(true)"
    [confirmLabel]="'verifySend.modals.SEND_ACTION' | translate" (onCancel)="closeSendScheduleModal()"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content modalId="vs001" [title]="'verifySend.modals.SEND_TITLE' | translate"
        [subtitle]="'verifySend.modals.SEND_SUBTITLE' | translate" [text]="'verifySend.modals.SEND_DESCR' | translate">
    </modal-text-content>
</modal>