<page-container>

    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>

    <div class="sidenav-information">
        <sidenav-informations (onBackFunction)="redirectService.goBackBrowser()" [isBackButton]="true"
            [title]="(roundId ? 'cppAdmin.create.SIDENAV_TITLE_UPDATE' : 'cppAdmin.create.SIDENAV_TITLE_CREATE') | translate"
            [description]="(roundId ? 'cppAdmin.create.SIDENAV_DESCR_UPDATE' : 'cppAdmin.create.SIDENAV_DESCR_CREATE') | translate">
        </sidenav-informations>
    </div>

    <div class="main-content with-fixed-sidenav">
        <div class="content">
            <ng-container *ngIf="!isLoadingRound">
                <div class="form-container">
                    <!-- Nome del round -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [isValued]="roundName && roundName.trim().length"
                        [isObbligatory]="true" title="{{'cppAdmin.create.ROUND_NAME' | translate}}">
                        <alloy-input [bindValue]="roundName" (onModelChanged)="nameChanged($event)"
                            placeholder="{{'cppAdmin.create.ROUND_NAME_PLACEHOLDER' | translate}}">
                        </alloy-input>
                    </input-container>
                    <!-- Data di avvio -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [isValued]="startDate" [disabled]="selectedStatusOriginal?.id == 'RUNNING'"
                        [isObbligatory]="true" title="{{'cppAdmin.create.START_DATE_CPP_ROUND' | translate}}">
                        <alloy-datepicker [minDate]="minStartDate" [value]="startDate"
                            (dateChanged)="startDateChanged($event)" [disabled]="selectedStatusOriginal?.id == 'RUNNING'"
                            placeholder="{{'cppAdmin.create.START_DATE_CPP_ROUND_PLACEHOLDER' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- Data di chiusura -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [isValued]="endDate" [disabled]="selectedStatusOriginal?.id == 'RUNNING'"
                        [isObbligatory]="true" title="{{'cppAdmin.create.END_DATE_CPP_ROUND' | translate}}">
                        <alloy-datepicker [minDate]="yesterday" [value]="endDate"
                            (dateChanged)="endDateChanged($event)" [disabled]="selectedStatusOriginal?.id == 'RUNNING'"
                            placeholder="{{'cppAdmin.create.END_DATE_CPP_ROUND_PLACEHOLDER' | translate}}">
                        </alloy-datepicker>
                    </input-container>
                    <!-- Stato del round -->
                    <input-container [isWithoutInfoIcon]="true" [isObbligatory]="true" [readonly]="!roundId" [isValued]="selectedStatus"
                        [isObbligatory]="true" title="{{'cppAdmin.create.ROUND_STATUS' | translate}}">
                        <alloy-select (onSelectedItem)="changeStatus($event)" [selectedItem]="selectedStatus"
                            [items]="statusOptions" bindLabel="title"></alloy-select>
                    </input-container>

                    <div fxLayout="row" class="full-width" fxLayoutAlign="center center">
                        <alloy-button (onButtonClicked)="createUpdate()" [disabled]="isLoadingRound || !roundName || !startDate || !endDate || !selectedStatus"
                            type="success default" size="large"
                            [label]="(roundId && roundId.length) ? ('cppAdmin.create.UPDATE_ROUND' | translate) : ('cppAdmin.create.CREATE_ROUND' | translate)">
                        </alloy-button>
                    </div>
                </div>
            </ng-container>
            <!-- Loader caricamento processo -->
            <ng-container *ngIf="isLoadingRound">
                <div class="loader spinner-big"></div>
            </ng-container>
        </div>
    </div>
</page-container>

<modal id="create-process" (onClose)="closeCreateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeCreateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeCreateProcess()">
    <modal-text-content [title]="'cppAdmin.create.CREATE_ROUND' | translate"
        [text]="isThereActiveRound ? ('cppAdmin.create.CREATE_ROUND_WITH_ACTIVE_DESCR' | translate) : ('cppAdmin.create.CREATE_ROUND_DESCR' | translate)"></modal-text-content>
</modal>

<modal id="update-process" (onClose)="closeUpdateProcess()" [confirmLabel]="'generic.CONFIRM' | translate"
    (onConfirm)="closeUpdateProcess(true)" [cancelLabel]="'generic.CANCEL' | translate"
    (onCancel)="closeUpdateProcess()">
    <modal-text-content [title]="'cppAdmin.create.UPDATE_ROUND' | translate"
        [text]="'cppAdmin.create.UPDATE_ROUND_DESCR' | translate"></modal-text-content>
</modal>