import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'adequacy-indicator',
  templateUrl: './adequacy-indicator.component.html',
  styleUrls: ['./adequacy-indicator.component.scss']
})
export class AdequacyIndicatorComponent implements OnInit {

  @Input() adequacyPercentage?: number;
  @Input() status: string = '';
  constructor() { }

  ngOnInit(): void {
    if (this.adequacyPercentage !== undefined && this.adequacyPercentage !== null) {
      if (this.adequacyPercentage == 100) {
        this.status = 'max';
      } else if (this.adequacyPercentage >= 75 && this.adequacyPercentage < 100) {
        this.status = 'medium';
      } else if (this.adequacyPercentage >= 1 && this.adequacyPercentage < 75) {
        this.status = 'min';
      } else {
        this.status = '';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.adequacyPercentage !== undefined && this.adequacyPercentage !== null) {
      if (this.adequacyPercentage == 100) {
        this.status = 'max';
      } else if (this.adequacyPercentage >= 75 && this.adequacyPercentage < 100) {
        this.status = 'medium';
      } else if (this.adequacyPercentage >= 1 && this.adequacyPercentage < 75) {
        this.status = 'min';
      } else {
        this.status = '';
      }
    }
  }

}
