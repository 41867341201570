import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareCareAttributesAreaTypes } from 'src/commonclasses';


export class CardElement {
  id: string = '';
  icon: string = '';
  title: string = '';
}

@Component({
  selector: 'card-performance',
  templateUrl: 'card-performance-level.component.html',
  styleUrls: ['./card-performance-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPerformanceComponent implements OnInit {
  items: CardElement[] = [
    {
      id: 'EXCEPTIONAL',
      icon: 'assets/img/pl-evaluation/exceptional.svg',
      title: 'evaluations.EXCEPTIONAL'
    },
    {
      id: 'EXCEEDED',
      icon: 'assets/img/pl-evaluation/exceeded.svg',
      title: 'evaluations.EXCEEDED'
    },
    {
      id: 'MEET',
      icon: 'assets/img/pl-evaluation/meet.svg',
      title: 'evaluations.MEET'
    },
    {
      id: 'IMPROVABLE',
      icon: 'assets/img/pl-evaluation/improvable.svg',
      title: 'evaluations.IMPROVABLE'
    },
    {
      id: 'UNSATISFACTORY',
      icon: 'assets/img/pl-evaluation/unsatisfactory.svg',
      title: 'evaluations.UNSATISFACTORY'
    }
  ]
  @Input() bindValue: string = '';
  @Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  setValue(item: any) {
    this.bindValue = item.id;
    this.cdr.detectChanges();
    this.emitOnItemChange();
  }


  emitOnItemChange() {
    this.onItemChange.emit(this.bindValue);
  }

}