import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'card-peer',
  templateUrl: 'card-peer.component.html',
  styleUrls: ['./card-peer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPeerComponent implements OnInit {
  @Input() object: any;
  @Input() loggedUser?: any;
  @Input() isPeopleAppraisal?: boolean;
  @Input() isReceived?: boolean;
  @Input() isHistory: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFeedbackSend: EventEmitter<any> = new EventEmitter();
  @Output() onShowRequest: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService,
  ) {
  }

  ngOnInit() {
  }

  emitOnEdit(id: any) {
    this.onEdit.emit(id);
  }

  emitOnDelete(id: any) {
    this.onDelete.emit(id);
  }

  emitOnFeedbackSend(id: any) {
    this.onFeedbackSend.emit(id);
  }

  emitOnShowRequest() {
    this.onShowRequest.emit(this.object);
  }
}