<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon src="assets/img/icons/bell.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
        <p translate="notifications.GENERIC_NOTIFY_TITLE"></p>
        <div *ngIf="isNew" class="new">
          <p translate="generic.NEWS"></p>
        </div>
      </div>
      <div class="period-time">
        <!-- <p *ngIf="!isNew">{{notify.creationDate | date: 'dd/MM/YYYY'}}</p> -->
        <p>{{notify.creationDateAgo}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <svg-icon *ngIf="notify.fromApplication" class="regular-size-avatar-img" src="/assets/img/icons/logomark.svg"
      [applyClass]="true"></svg-icon>
    <svg-icon *ngIf="!notify.fromApplication && notify.notificationType == 'PERFCARE_OBTAINED_BADGE'"
      class="regular-size-avatar-img" src="/assets/img/icons/notification-logo.svg" [applyClass]="true"></svg-icon>
    <avatar-img size="medium" [user]="notify.sender"
      *ngIf="!notify.fromApplication && notify.notificationType != 'PERFCARE_OBTAINED_BADGE' && notify.sender">
    </avatar-img>
    <svg-icon *ngIf="!notify.fromApplication && notify.notificationType != 'PERFCARE_OBTAINED_BADGE' && !notify.sender"
      class="regular-size-avatar-img" src="/assets/img/icons/notification-logo.svg" [applyClass]="true"></svg-icon>


    <div class="information-text-container">
      <div class="specific-information">
        <p *ngIf="notify.fromApplication" [innerHTML]="'Inviata dalla <b>Piattaforma</b>'"></p>
        <p *ngIf="!notify.fromApplication && notify.sender"
          [innerHTML]="'Inviata da <b>' + notify.sender.forename + ' ' + notify.sender.surname + '</b>'"></p>
      </div>
      <p class="information-text">
        <span translate="notifications.GENERIC_NOTIFY" class="bold-text italic-text"></span>
        <span [innerHTML]="notify.text"></span>
      </p>
      <div class="action-footer" [ngClass]="{'to-end': isNew && notify.action}">
        <div class="feedback-actions" [ngClass]="{'to-end': isNew && notify.action}">
          <alloy-button *ngIf="isNew && !notify.action" (onButtonClicked)="emitOnDelete(notify)"
            label="{{'notifications.SIGN_AS_READ' | translate}}" type="primary link" size="medium"
            iconLeft="assets/img/icons/eye.svg"></alloy-button>
          <alloy-button *ngIf="!isNew" (onButtonClicked)="emitOnDelete(notify)" label="{{'generic.DELETE' | translate}}"
            type="primary link" size="medium" iconLeft="assets/img/icons/trash-2.svg"></alloy-button>
          <alloy-button *ngIf="notify.action" (onButtonClicked)="emitOnAction(notify)"
            label="{{'notifications.GO_TO_DETAIL' | translate}}" type="black-link arrow-icon" size="medium"
            iconRight="assets/img/icons/arrow-right.svg"></alloy-button>
        </div>
      </div>
    </div>
  </div>
</div>