<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations *ngIf="myPeopleAppraisal" [isBackButton]="true"
      (onBackFunction)="(interviewId && interviewId.length) ? goToUpdateInterview(true) : goToCreateInterview(true)"
      [title]="(interviewId && interviewId.length) ? ('goalSetting.organizeInterview.SIDENAV_TITLE_UPDATE' | translate) + ' ' + myPeopleAppraisal.forename + ' ' + myPeopleAppraisal.surname : ('goalSetting.organizeInterview.SIDENAV_TITLE' | translate) + ' ' + myPeopleAppraisal.forename + ' ' + myPeopleAppraisal.surname"
      description="{{('goalSetting.organizeInterview.SIDENAV_DESCRIPTION_SELFASSESSMENT' | translate)}}">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingUpdateInterview && !isFetchingCreateInterview">
        <ng-container *ngIf="!isWhereSection">
          <div class="form-container-when">
            <div class="tabs-container">
              <tab-section *ngFor="let tab of tabSectionsList; trackBy: tabId" (onClick)="onTabClicked($event)"
                [tab]="tab"></tab-section>
            </div>
            <div class="select-period-info">
              <p translate="goalSetting.organizeInterview.SELECT_PERIOD"></p>
              <svg-icon (click)="openModalInfoSelectPeriod()" src="./assets/img/icons/info.svg"
                class="medium-size blue-primary" [applyClass]="true"></svg-icon>
            </div>
            <div class="dates-container">
              <input-container
                [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                [isWithoutInfoIcon]="true" [isValued]="interview.startDate" [isObbligatory]="true"
                title="{{'goalSetting.organizeInterview.INTERVIEW_FROM_DAY' | translate}}">
                <alloy-datepicker [minDate]="yesterday" [value]="interview.startDate"
                  (dateChanged)="interviewStartDateChanged($event)"
                  placeholder="{{'goalSetting.organizeInterview.INTERVIEW_FROM_DAY_PLACEHOLDER' | translate}}">
                </alloy-datepicker>
              </input-container>
              <input-container
                [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                [isWithoutInfoIcon]="true" [isValued]="interview.endDate" [isObbligatory]="true"
                title="{{'goalSetting.organizeInterview.INTERVIEW_TO_DAY' | translate}}">
                <alloy-datepicker [minDate]="yesterday" [value]="interview.endDate"
                  (dateChanged)="interviewEndDateChanged($event)"
                  placeholder="{{'goalSetting.organizeInterview.INTERVIEW_TO_DAY_PLACEHOLDER' | translate}}">
                </alloy-datepicker>
              </input-container>
            </div>
            <div *ngIf="checkDatesValidation()" class="dates-error">
              <p translate="goalSetting.organizeInterview.NOT_VALID_DATES"></p>
            </div>
            <div class="full-width">
              <input-container
                [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                [tooltipModalMessage]="interviewDurationTooltipModal" [isValued]="selectedInterviewDuration"
                [isObbligatory]="true" title="{{'goalSetting.organizeInterview.SELECT_SLOT_DURATION' | translate}}">
                <alloy-select (onSelectedItem)="changeInterviewDuration($event)"
                  [selectedItem]="selectedInterviewDuration" [items]="durationOptions" [searchable]="true"
                  bindLabel="title"
                  placeholder="{{'goalSetting.createGoal.SELECT_SLOT_DURATION_PLACEHOLDER' | translate}}">
                </alloy-select>
              </input-container>
            </div>
            <div class="button-container">
              <alloy-button (onButtonClicked)="getSlotHours()"
                [disabled]="isFetchingSlotHours || checkDatesValidation() || !interview.startDate || !interview.endDate || isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                type="primary bordless" size="medium"
                label="{{'goalSetting.organizeInterview.VERIFY_DISPONIBILITY' | translate}}">
              </alloy-button>
            </div>
            <div class="hour-slots" *ngIf="calledSlotHoursService">
              <p *ngIf="hourSlots && hourSlots.length && hourSlotsFE && hourSlotsFE.length && !isFetchingSlotHours"
                translate="goalSetting.organizeInterview.SLOT_HOURS_TO_DISPONIBILITY" class="title"></p>
              <div *ngIf="hourSlots && hourSlots.length && hourSlotsFE && hourSlotsFE.length && !isFetchingSlotHours"
                class="slots-container">
                <div class="slot" *ngFor="let slot of hourSlotsFE; trackBy: trackByStart"
                  [ngClass]="{'active': (slotSelected && slotSelected.start) == slot.start}" (click)="selectSlot(slot)">
                  <div class="radio-empty">
                    <div *ngIf="(slotSelected && slotSelected.start) == slot.start" class="checked">
                    </div>
                  </div>
                  <p>
                    <span [innerHTML]="slot.startLabel"></span>
                    <span class="bold-text">- {{slot.startTimeLabel}} · {{slot.endTimeLabel}}
                      -</span>
                  </p>
                </div>
                <a *ngIf="getNumberToChargeSlots() > 1"
                  (click)="chargeMoreSlots()">{{('goalSetting.organizeInterview.CHARGE_OTHERS' |
                  translate) + ' ' +
                  getNumberToChargeSlots()}}</a>
                <a *ngIf="getNumberToChargeSlots() == 1"
                  (click)="chargeMoreSlots()">{{('goalSetting.organizeInterview.CHARGE' | translate) +
                  ' ' +
                  getNumberToChargeSlots() + ' ' + ('goalSetting.organizeInterview.OTHER' |
                  translate)}}</a>
              </div>
              <p class="no-slots-text" translate="goalSetting.organizeInterview.NO_SLOTS"
                *ngIf="(!hourSlots || !hourSlots.length || !hourSlotsFE || !hourSlotsFE.length) && !isFetchingSlotHours">
              </p>
              <div *ngIf="isFetchingSlotHours" class="slots-container">
                <div class="slot placeholder" *ngFor="let number of [0,1,2,3,4]">

                </div>
              </div>
            </div>
            <div class="hour-slots" *ngIf="slotInterviewSelected && slotInterviewSelected.startLabel">
              <div class="slot active">
                <div class="radio-empty">
                  <div class="checked"></div>
                </div>
                <p>
                  <span [innerHTML]="slotInterviewSelected.startLabel"></span>
                  <span class="bold-text">- {{slotInterviewSelected.startTimeLabel}} ·
                    {{slotInterviewSelected.endTimeLabel}} -</span>
                </p>
              </div>
            </div>
            <div class="end-button-container">
              <alloy-button
                [disabled]="isFetchingSlotHours || checkDatesValidation() || ((!slotSelected || !slotSelected.start) && (!slotInterviewSelected || !slotInterviewSelected.startLabel)) || isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                (onButtonClicked)="goToWhereSection()" type="primary default" size="large"
                iconRight="assets/img/icons/arrow-right.svg" label="{{'generic.WHERE' | translate}}">
              </alloy-button>
            </div>
          </div>
        </ng-container>


        <ng-container *ngIf="isWhereSection">
          <div class="form-container-where">
            <div class="tabs-container">
              <tab-section *ngFor="let tab of tabSectionsList; trackBy: tabId" (onClick)="onTabClicked($event)"
                [tab]="tab"></tab-section>
            </div>
            <input-container
              instructions="{{'goalSetting.organizeInterview.SELECT_INTERVIEW_INSTRUCTIONS' | translate}}"
              [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
              [tooltipModalMessage]="whereTooltipModal" [isValued]="interviewTypeSelected" [isObbligatory]="true"
              title="{{'goalSetting.organizeInterview.WHERE_INTERVIEW' | translate}}">
              <alloy-select (onSelectedItem)="changeInterviewType($event)" [selectedItem]="interviewTypeSelected"
                [items]="interviewTypesOptions" [searchable]="true" bindLabel="title"
                placeholder="{{'goalSetting.updatePMGoal.OBJECTIVE_VALUTATION_PLACEHOLDER' | translate}}">
              </alloy-select>
            </input-container>
            <!-- Inserisci link online o presenza -->
            <input-container [isWithoutInfoIcon]="true"
              [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
              *ngIf="interview.type == 'ONLINE' || interview.type == 'PRESENCE'"
              [tooltipModalMessage]="linkTooltipModal" [isValued]="interview.link && interview.link.trim().length"
              [isObbligatory]="true"
              [title]="interview.type == 'ONLINE' ? ('goalSetting.organizeInterview.INSERT_LINK' | translate) : ('goalSetting.organizeInterview.INSERT_PLACE_TEXT' | translate)">
              <alloy-input [bindValue]="interview.link" (onModelChanged)="interviewLinkChanged($event)"
                [placeholder]="interview.type == 'ONLINE' ? ('goalSetting.organizeInterview.INSERT_LINK_EXAMPLE' | translate) : ('goalSetting.organizeInterview.INSERT_PLACE_EXAMPLE' | translate)">
              </alloy-input>
            </input-container>
            <input-container [isWithoutInfoIcon]="true" *ngIf="interview.type == 'MS_TEAMS'"
              [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
              [tooltipModalMessage]="teamsTooltipModal" [readonly]="true"
              title="{{'goalSetting.organizeInterview.TEAMS_TITLE' | translate}}">
              <alloy-text-area [readonly]="true" bindValue="{{'goalSetting.organizeInterview.TEAMS_TEXT' | translate}}"
                placeholder="">
              </alloy-text-area>
            </input-container>
            <!-- Argomento colloquio -->
            <input-container
              [isLoading]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
              [tooltipModalMessage]="argumentTooltipModal" [readonly]="false"
              [isValued]="interview.argument && interview.argument.trim().length"
              title="{{'goalSetting.organizeInterview.ARGUMENT' | translate}}">
              <alloy-input [bindValue]="argument" (onModelChanged)="argumentChanged($event)"
                [placeholder]="('goalSetting.organizeInterview.ARGUMENT_PLACEHOLDER' | translate)">
              </alloy-input>
            </input-container>
            <div class="end-buttons-container">
              <alloy-button
                [disabled]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                (onButtonClicked)="goToWhenSection()" type="primary default arrow-icon" size="large"
                iconLeft="assets/img/icons/arrow-left.svg" label="{{'generic.WHEN' | translate}}">
              </alloy-button>
              <alloy-button
                [disabled]="isFetchingGetInterview || isFetchingUpdateInterview || isFetchingCreateInterview"
                (onButtonClicked)="(interviewId && interviewId.length) ? goToUpdateInterview() : goToCreateInterview()"
                type="primary default" size="large"
                [disabled]="isDisabledSaveInterviewButton() || ((!slotSelected || !slotSelected.start) && (!slotInterviewSelected || !slotInterviewSelected.startLabel))"
                label="{{'goalSetting.organizeInterview.ORGANIZE_AND_SEND_CALENDAR' | translate}}">
              </alloy-button>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isFetchingUpdateInterview || isFetchingCreateInterview">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</page-container>

<!-- Modale per l'aggiornamento/creazione di un colloquio -->
<modal id="modalUpdateInterviewForUser" (onCancel)="goBackBrowser()" (onClose)="closeModalInterviewUpdate()"
  (onConfirm)="(interviewId && interviewId.length) ? updateInterviewHandle() : createInterviewHandle()"
  cancelLabel="{{modalUpdateInterviewTextCloseTextButton}}"
  confirmLabel="{{modalUpdateInterviewTitleConfirmTextButton}}">
  <modal-text-content [title]="modalUpdateInterviewTitle" [subtitle]="''" [text]="modalUpdateInterviewText">
  </modal-text-content>
</modal>