<div class="circle-wrap" [ngClass]="{'from-sidebar': isFromSidebar, 'from-header-dossier': isFromHeaderDossier, 'is-button-active': isButtonActive}">
  <div class="circle">
    <div class="mask full" [ngStyle]="{'transform': 'rotate(' + percentage + ')'}">
      <div class="fill" [ngStyle]="{'transform': 'rotate(' + percentage + ')'}"></div>
    </div>
    <div class="mask half">
      <div class="fill" [ngStyle]="{'transform': 'rotate(' + percentage + ')'}"></div>
    </div>
    <div class="inside-circle">{{title}}</div>
  </div>
</div>