import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { SenecaResponse } from 'src/commonclasses';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { AdminService } from '../../services/admin.service';
import { GetUsersDistinctFieldFilterForAdminResponseType } from 'atfcore-commonclasses';
import { Observable } from 'rxjs';

type FilterKeys = (keyof GetUsersDistinctFieldFilterForAdminResponseType) | 'selfStatus' | 'peopleStatus' | 'calibrationStatus' | 'upwardEvaluationStatus';

@Component({
  selector: 'anag-filters',
  templateUrl: 'anag-filters.component.html',
  styleUrls: ['./anag-filters.component.scss']
})
export class AnagFiltersComponent implements OnInit, OnDestroy {
  @Input() processYear?: any;
  @Input() processYearCode?: any;
  @Input() roundId?: any;
  @Input() selectedFilters: Record<FilterKeys, {
    id: string;
    title: string;
    value: string | string[] | null;
  }> = <any>{}
  @Input() searchByTextPlaceholder?: string;
  @Input() noActiveFilterText?: string;
  @Input() oneFilterText?: string;
  @Input() moreFiltersText?: string;
  @Input() deleteFiltersText?: string;
  @Input() manageFiltersText?: string;
  @Input() applyFiltersText?: string;
  @Input() isLoadingUsers: boolean = false;
  @Input() showSubCompany: boolean = true;
  @Input() showCdc: boolean = true;
  @Input() showAreaRef: boolean = true;
  @Input() showDepartment: boolean = true;
  @Input() showTeam: boolean = true;
  @Input() showSubTeam: boolean = false;
  @Input() showPosition: boolean = true;
  @Input() showSupervisor: boolean = true;
  @Input() showContractual: boolean = true;
  @Input() showOffice: boolean = true;
  @Input() showSelfAssessmentStatus: boolean = true;
  @Input() showAppraisalStatus: boolean = true;
  @Input() showCalibrationStatus: boolean = true;
  @Input() showUpwardFeedbackStatus: boolean = false;
  @Input() searchEnabled: boolean = true;
  @Input() keepFilters: boolean = false; // salvare selectedFilters in 'savedFilters' nel session storage alla ricerca

  @Output() searchedTextChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateSelectedFiltersNumber: EventEmitter<any> = new EventEmitter<any>();
  @Output() startSearch: EventEmitter<any> = new EventEmitter<any>();

  filtersObject: Record<FilterKeys, Array<{
    id: string;
    title: string;
    value: string | string[] | null;
  }>> = <any>{};

  isFilterOpened: boolean = false;
  isLoadingFilters: boolean = true;
  getFiltersData$: any;
  translations: any;
  selectedFiltersNumber: number = 0;
  searchedText: string = '';
  isPeopleAppraisal: boolean = false;

  @Input() isCppDashboard?: boolean = false;
  @Input() isCpp?: boolean = false;
  @Input() isUpwardFeedback?: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private sharedService: SharedService,
    private adminService: AdminService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.translate.get([
      'filter.ALL',
      'filter.M',
      'filter.F',
      'monitoring.filters.SENT',
      'monitoring.filters.NOT_SENT',
      'monitoring.WAITING',
      'monitoring.TO_SEND',
      'monitoring.SENT',
      'upwardFeedback.userAdminStatus.TO_CHECK',
      'upwardFeedback.userAdminStatus.WAITING',
      'upwardFeedback.userAdminStatus.CHECKED',
      'upwardFeedback.userAdminStatus.SHARED',
    ]).subscribe((translations) => {
      this.translations = translations;

      this.route.params
        .subscribe(
          (params: Params) => {
            if (window.location.href.indexOf('peopleAppraisal') > -1) {
              this.isPeopleAppraisal = true;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYear) {
              this.processYear = params.processYear;
            }
            // Se non mi arrivano i dati in input, li prendo dall'url
            if (!this.processYearCode) {
              this.processYearCode = params.processYearCode;
            }
            this.getFilters();
          })
    })
  }

  onSearch() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendSearchEvent(this.searchedText || '');
    this.emitStartSearch(true);
  }

  openFiltersSection() {
    this.isFilterOpened = !this.isFilterOpened;
  }

  applyFilters() {
    this.isFilterOpened = false;
    this.emitStartSearch(true);
  }

  // recupera il distinct dei filtri disponibili
  getFilters() {
    this.isLoadingFilters = true;

    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }
    let service: Observable<SenecaResponse<GetUsersDistinctFieldFilterForAdminResponseType>>;
    if (this.isCpp || this.isCppDashboard) {
      service = <any>this.adminService.getCppUserFilters(this.roundId);
    } else if (this.isUpwardFeedback) {
      service = <any>this.adminService.getUpwardFeedbackFilters(this.roundId);
    } else {
      service = this.sharedService.getDistinctFilters(this.processYear, this.processYearCode, this.isPeopleAppraisal);
    }
    this.getFiltersData$ = service
      .subscribe((data: SenecaResponse<GetUsersDistinctFieldFilterForAdminResponseType>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let all = { id: 'all', title: this.translations['filter.ALL'] as string, value: null }
          let statuses = [
            all,
            {
              id: 'sent',
              title: this.translations['monitoring.filters.SENT'],
              value: 'sent',
            },
            {
              id: 'not-sent',
              title: this.translations['monitoring.filters.NOT_SENT'],
              value: 'not-sent',
            }
          ];

          let calibrationStatuses = [
            all,
            {
              id: 'WAITING',
              title: this.translations['monitoring.WAITING'],
              value: ['WAITING'],
            },
            {
              id: 'TO_CHECK',
              title: this.translations['monitoring.TO_SEND'],
              value: ['TO_CHECK', 'TO_SEND'],
            },
            {
              id: 'SENT',
              title: this.translations['monitoring.SENT'],
              value: ['SENT'],
            }
          ]

          let upwardEvaluationStatus = [
            all,
            {
              id: 'WAITING',
              title: this.translations['upwardFeedback.userAdminStatus.WAITING'],
              value: ['WAITING'],
            },
            {
              id: 'TO_CHECK',
              title: this.translations['upwardFeedback.userAdminStatus.TO_CHECK'],
              value: ['TO_CHECK'],
            },
            {
              id: 'CHECKED',
              title: this.translations['upwardFeedback.userAdminStatus.CHECKED'],
              value: ['CHECKED'],
            },
            {
              id: 'SHARED',
              title: this.translations['upwardFeedback.userAdminStatus.SHARED'],
              value: ['SHARED'],
            }
          ]

          this.filtersObject = {
            socDistacco: data.response.socDistacco?.length ? [...[all], ...data.response.socDistacco.map(item => { return { id: item, title: item, value: item } })] : [],
            cdcFinance: data.response.cdcFinance?.length ? [...[all], ...data.response.cdcFinance.map(item => { return { id: item, title: item, value: item } })] : [],
            chief: data.response.chief?.length ? [...[all], ...data.response.chief.map(item => { return { id: item, title: item, value: item } })] : [],
            ramo: data.response.ramo?.length ? [...[all], ...data.response.ramo.map(item => { return { id: item, title: item, value: item } })] : [],
            team: data.response.team?.length ? [...[all], ...data.response.team.map(item => { return { id: item, title: item, value: item } })] : [],
            ruolo: data.response.ruolo?.length ? [...[all], ...data.response.ruolo.map(item => { return { id: item, title: item, value: item } })] : [],
            tipologiaContratto: data.response.tipologiaContratto?.length ? [...[all], ...data.response.tipologiaContratto.map(item => { return { id: item, title: item, value: item } })] : [],
            sedeLavoro: data.response.sedeLavoro?.length ? [...[all], ...data.response.sedeLavoro.map(item => { return { id: item, title: item, value: item } })] : [],
            supervisor: data.response.supervisor?.length ? [...[all], ...data.response.supervisor.map(item => { return { id: (item.surname + ' ' + item.forename), title: (item.surname + ' ' + item.forename), value: item.userId } })] : [],

            selfStatus: statuses,
            peopleStatus: statuses,
            calibrationStatus: calibrationStatuses,
            upwardEvaluationStatus: upwardEvaluationStatus
          }
          if (this.keepFilters) {
            let savedFilters = sessionStorage.getItem('savedFilters');
            let savedFiltersNumber = sessionStorage.getItem('savedFiltersNumber') || '0';
            if (savedFilters && savedFilters.length) {
              this.selectedFilters = JSON.parse(savedFilters);
              this.selectedFiltersNumber = parseInt(savedFiltersNumber);
            } else {
              this.selectedFilters = {
                socDistacco: this.filtersObject.socDistacco[0] || null,
                cdcFinance: this.filtersObject.cdcFinance[0] || null,
                chief: this.filtersObject.chief[0] || null,
                ramo: this.filtersObject.ramo[0] || null,
                team: this.filtersObject.team[0] || null,
                ruolo: this.filtersObject.ruolo[0] || null,
                tipologiaContratto: this.filtersObject.tipologiaContratto[0] || null,
                sedeLavoro: this.filtersObject.sedeLavoro[0] || null,
                supervisor: this.filtersObject.supervisor[0] || null,

                selfStatus: this.filtersObject.selfStatus[0] || null,
                peopleStatus: this.filtersObject.peopleStatus[0] || null,
                calibrationStatus: this.filtersObject.calibrationStatus[0] || null,
                upwardEvaluationStatus: this.filtersObject.upwardEvaluationStatus[0] || null
              }
              this.selectedFiltersNumber = 0;
            }
          } else {
            this.clearFilters(false);
          }
        }
        this.isLoadingFilters = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingFilters = false;
      });
  }

  clearFilters(resetData: boolean) {
    this.selectedFilters = {
      socDistacco: this.filtersObject.socDistacco[0] || null,
      cdcFinance: this.filtersObject.cdcFinance[0] || null,
      chief: this.filtersObject.chief[0] || null,
      ramo: this.filtersObject.ramo[0] || null,
      team: this.filtersObject.team[0] || null,
      ruolo: this.filtersObject.ruolo[0] || null,
      tipologiaContratto: this.filtersObject.tipologiaContratto[0] || null,
      sedeLavoro: this.filtersObject.sedeLavoro[0] || null,
      supervisor: this.filtersObject.supervisor[0] || null,

      selfStatus: this.filtersObject.selfStatus[0] || null,
      peopleStatus: this.filtersObject.peopleStatus[0] || null,
      calibrationStatus: this.filtersObject.calibrationStatus[0] || null,
      upwardEvaluationStatus: this.filtersObject.upwardEvaluationStatus[0] || null
    }
    this.selectedFiltersNumber = 0;
    this.updateSelectedFiltersNumber.emit(0);
    this.updateSelectedFilters.emit(this.selectedFilters);
    this.emitStartSearch(resetData);
  }

  emitSearchedTextChanged(data?: any) {
    this.searchedTextChanged.emit(data);
  }

  emitStartSearch(data: any) {
    this.startSearch.emit(data);
  }

  selectFilter(data: any, id: FilterKeys) {
    if (data.id === "all") {
      this.selectedFiltersNumber = this.selectedFiltersNumber - 1;
      this.updateSelectedFiltersNumber.emit(this.selectedFiltersNumber);
    }

    if (this.selectedFilters[id] && !this.selectedFilters[id].value && this.selectedFilters[id] != data && data.id != 'all') {
      this.selectedFiltersNumber = this.selectedFiltersNumber + 1;
      this.updateSelectedFiltersNumber.emit(this.selectedFiltersNumber);
    }
    this.selectedFilters[id] = data;

    this.updateSelectedFilters.emit(this.selectedFilters);
  }

  ngOnDestroy() {
    if (this.getFiltersData$) {
      this.getFiltersData$.unsubscribe();
    }
  }
}