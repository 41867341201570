import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'item-home',
  templateUrl: './item-home.component.html',
  styleUrls: ['./item-home.component.scss']
})
export class ItemHomeComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() expirationDate?: string = ''; //da passare in ISOString
  @Input() hasNew?: boolean = false;
  @Input() white: boolean = false;

  @Output() onClicked: EventEmitter<Date> = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
  }

  emitOnClick() {
    this.onClicked.emit();
  }
}
