import { createReducer, on, Action } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as MangerActions from './peopleAppraisal.actions';
import { FacileOpenAPI } from 'atfcore-commonclasses';

export interface FeatureState extends fromApp.AppState {
  peopleAppraisal: PeopleAppraisalState
}

export interface PeopleAppraisalState {
  subordinatesWithPhaseStatuses: any[],
  cppSubordinates: any[],
  structureSubordinatesWithPhaseStatuses: any[],
  runningPhaseId: string,
  activeUserId: string,
  buttonTeamClicked: boolean,
  isLoadingSubordinates: boolean,
}

// Store iniziale
const initialState: PeopleAppraisalState = {
  subordinatesWithPhaseStatuses: [],
  cppSubordinates: [],
  structureSubordinatesWithPhaseStatuses: [],
  runningPhaseId: '',
  activeUserId: '',
  buttonTeamClicked: false,
  isLoadingSubordinates: false,
};

export const createPeopleAppraisalReducer = createReducer(
  initialState,
  on(MangerActions.SetIsLoadingSubordinates, (state, action) => {
    return {
      ...state,
      isLoadingSubordinates: action.payload
    };
  }),
  on(MangerActions.SetSuboardinatesWithPhaseStatuses, (state, action) => {
    return {
      ...state,
      subordinatesWithPhaseStatuses: action.payload
    };
  }),
  on(MangerActions.SetCppSuboardinates, (state, action) => {
    return {
      ...state,
      cppSubordinates: action.payload
    };
  }),
  on(MangerActions.SetStructureSuboardinatesWithPhaseStatuses, (state, action) => {
    return {
      ...state,
      structureSubordinatesWithPhaseStatuses: action.payload
    };
  }),
  on(MangerActions.SetHeaderTeamClickedButton, (state, action) => {
    return {
      ...state,
      buttonTeamClicked: action.payload
    };
  }),
  on(MangerActions.UpdateSelfAssessmentUserStatusType, (state, action) => {
    let userToUpdate: any = null;
    let index = 0;
    if (state.subordinatesWithPhaseStatuses && state.subordinatesWithPhaseStatuses.length) {
      for (let i = 0; i < state.subordinatesWithPhaseStatuses.length; i++) {
        if (state.subordinatesWithPhaseStatuses[i].userId === action.payload.userId) {
          userToUpdate = state.subordinatesWithPhaseStatuses[i];
          index = i;
          break;
        }
      }
    }
    const updatedUser = {
      ...userToUpdate,
      status: action.payload.userStatus
    }

    let updatedSubordinatesWithPhaseStatuses = JSON.parse(JSON.stringify(state.subordinatesWithPhaseStatuses));
    updatedSubordinatesWithPhaseStatuses[index] = updatedUser;
    return {
      ...state,
      subordinatesWithPhaseStatuses: updatedSubordinatesWithPhaseStatuses
    };
  }),
  on(MangerActions.SetUpdatedSelfAssessmentData, (state, action) => {
    let index = 0;
    if (state.subordinatesWithPhaseStatuses && state.subordinatesWithPhaseStatuses.length) {
      for (let i = 0; i < state.subordinatesWithPhaseStatuses.length; i++) {
        if (state.subordinatesWithPhaseStatuses[i].userId === action.payload[0].userId) {
          index = i;
          break;
        }
      }
    }
    let updatedSubordinatesWithPhaseStatuses = JSON.parse(JSON.stringify(state.subordinatesWithPhaseStatuses));
    updatedSubordinatesWithPhaseStatuses[index] = action.payload[0];

    return {
      ...state,
      subordinatesWithPhaseStatuses: updatedSubordinatesWithPhaseStatuses
    };
  }),
  on(MangerActions.SetActiveUserId, (state, action) => {
    return {
      ...state,
      activeUserId: action.payload
    };
  }),
  on(MangerActions.RemoveActiveUserId, (state, action) => {
    return {
      ...state,
      activeUserId: ''
    };
  })
)

export function peopleAppraisalReducer(state: PeopleAppraisalState | undefined, action: Action) {
  return createPeopleAppraisalReducer(state, action);
}

export const getActiveUserId = (state: PeopleAppraisalState) => state.activeUserId;
export const getIsButtonTeamClicked = (state: PeopleAppraisalState) => state.buttonTeamClicked;
export const getIsLoadingSubordinates = (state: PeopleAppraisalState) => state.isLoadingSubordinates;