import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'alloy-input',
  templateUrl: 'alloy-input.component.html',
  styleUrls: ['./alloy-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropInputComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() bindValue: string | null = '';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isInvalid: boolean = false;
  @Input() iconRight: string = '';
  @Input() typeInput: string = '';
  @Input() isSearchBar: boolean = false;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onIconClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  emitOnIconClicked(data?: any) {
    if (this.onIconClicked && this.onIconClicked instanceof EventEmitter) {
      this.onIconClicked.emit(data);
    }
  }
}