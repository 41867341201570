<div class="box-container" (click)="emitOnButtonClicked()" [ngClass]="{'is-new': isNew, 'cpp-style': withPercentage }">
  <ng-container *ngIf="!iconBox && imgPath && imgPath.length">
    <div class="img-content" style='background-image:url({{imgPath}})'></div>
  </ng-container>
  <ng-container *ngIf="iconBox && iconSrc && iconSrc.length">
    <div class="img-content icon" [ngStyle]="{'background-color': backgroundColor ? backgroundColor : '#fff'}">
      <svg-icon [src]="iconSrc" class="box-icon" [applyClass]="true"></svg-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="withPercentage">
    <circle-percentage *ngIf="!fromCppManager" [title]="percentageValue + '%'" [percentageCircle]="percentageValue">
    </circle-percentage>
    <circle-percentage *ngIf="fromCppManager" [title]="completedCollaboratosCpp + '/' + totalCollaboratosCpp"
      [percentageCircle]="percentageValue"></circle-percentage>
  </ng-container>
  <div class="info-container">
    <div class="info">
      <p class="title-text">{{title}}</p>
      <p class="description">{{description | translate}}</p>
    </div>
    <div class="new" *ngIf="isNew">
      <p translate="generic.NOVITY"></p>
    </div>
  </div>
</div>