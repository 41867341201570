import { AfterContentChecked, AfterContentInit, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";
import { RedirectService } from './shared/services/redirect.service';
import { AuthService } from './auth/services/auth.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ModalService } from './shared/components/modal/modal.service';
import { ApplicationModalMessage } from './core/ngrx/core.reducers';
import { DropdownItem } from './shared/models/dropdown.model';
import { TranslateService } from '@ngx-translate/core';
import { JwtPayload, SenecaResponse } from '../commonclasses';
import { Router } from '@angular/router';
import * as PeopleAppraisalActions from "../app/peopleAppraisal/ngrx/peopleAppraisal.actions";
import { BadgeObject } from './profilePage/profilePage.component';
import { LangsService } from './core/services/langs.service';
import * as moment from 'moment';
import { SelfAssessmentService } from './shared/services/selfAssessment.service';

const SELF_PAS_OPTIONS: string[] = [
  "pas",
  "sa-notes"
]
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterContentChecked {
  showApplicationLoader: boolean = false;
  applicationModalMessageText: string = '';
  applicationModalMessageTitle: string = '';
  withScrolledBody: boolean = true;
  applicationModalButtonCloseText: string = '';
  isFetchingLangs: boolean = true;
  loggedUser: any;
  checkSelect: boolean = true;
  initials: string = '';
  isPeopleAppraisal: boolean = false;
  isSelfAssessment: boolean = false;
  isAdmin: boolean = false;
  isCppAdmin: boolean = false;

  isTeamPage: boolean = false;
  // Flag per la visualizzazione del tutorial
  applicationModalMessageSubTitle: string = '';
  modalWithKalturaScript: string = '';
  kalturaCustomId: string = '';
  applicationModalBadge: BadgeObject[] = [];
  applicationModalMessageImage: string = '';
  applicationModalMessageNoBackground: boolean = false;
  noConfirmButton: boolean = false;
  confirmLabel: string = '';
  basicMenuItems: DropdownItem[] = [];
  selectedRole: any = {};
  rolesMenuItems: any[] = []
  peopleAppraisalMenuItems: DropdownItem[] = [];
  peopleAppraisalSelfAssessmentMenuItems: DropdownItem[] = [];
  isLoadingMenuItems: boolean = false;
  applicationModalId: string = '';
  isTeamButtonClicked: boolean = false;
  isClickedTeamButton$: Subscription;

  headerTooltip: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  profileDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  };
  dashboardDisabledModal: ApplicationModalMessage = {
    modalId: '',
    title: '',
    text: ''
  }
  selfAssessmentRedirectMenuItem: any;
  peopleAppraisalRedirectMenuItem: any;
  isOperations: boolean = false;
  isSti: boolean = false;
  closeBadgeModalLabel: string = '';
  isAdminModule: boolean = false;
  isSelfAssessmentModule: boolean = false;
  isPeopleAppraisalModule: boolean = false;

  showImpersonateBanner: boolean = false;
  runningYear$: any;
  runningYear: any = {};
  isLoadingProcessData: boolean = false;
  disableAccessToPas: boolean = false;
  getYearsList$: any;

  isAvailableMenu: boolean = false;

  // Accesso alla scheda di sviluppo
  canAccessPersonalDevelopment: boolean = environment.canAccessPersonalDevelopment;
  // Accesso alla dashboard
  canAccessTheDashboard: boolean = environment.canAccessTheDashboard;
  // Accesso al CPP
  canAccessTheCpp: boolean = environment.canAccessTheCpp;
  // Accesso al Upward Feedback
  canAccessToUpwardFeedback: boolean = environment.canAccessToUpwardFeedback;
  // Accesso agli appunti privati
  canAccessToPrivateNotes: boolean = environment.canAccessToPrivateNotes;

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router,
    private langsService: LangsService,
    private renderer: Renderer2,
    private selfAssessmentService: SelfAssessmentService
  ) {
    // Creo e carico lo script di Google Tag Manager per Google Analytics
    /* DA TENERE NECESSARIAMENTE COMMENTATO FINCHÈ NON SI VORRÀ EFFETTIVMENTE USARE ANALYTICS
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.googleTagManagerCode}')`
    document.head.insertBefore(script, null);*/

    this.isClickedTeamButton$ = this.store.select(fromApp.getIsButtonTeamClicked).subscribe((isButtonClicked) => {
      this.isTeamButtonClicked = isButtonClicked;
    })

    this.isLoadingMenuItems = true;

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.showImpersonateBanner = showImpersonateBanner;
      })

    this.runningYear$ = this.store.select(fromApp.getRunningYear)
      .subscribe((runningYear) => {
        this.runningYear = runningYear;
      });

    // Sto in ascolto del potenziale messaggio generico da mostrare nella modale
    const applicationModalMessage$: Observable<ApplicationModalMessage> = this.store.select(fromApp.getApplicationModalMessage);
    const applicationModalBadge$: Observable<BadgeObject[]> = this.store.select(fromApp.getApplicationModalBadge);
    const isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const showApplicationLoader$: Observable<boolean> = this.store.select(fromApp.showApplicationLoader);
    combineLatest([applicationModalMessage$, applicationModalBadge$, isFetchingLangs$, showApplicationLoader$]).subscribe(
      ([applicationModalMessage, applicationModalBadge, isFetchingLangs, showApplicationLoader]) => {
        this.isFetchingLangs = isFetchingLangs;
        this.showApplicationLoader = showApplicationLoader;
        this.applicationModalId = applicationModalMessage && applicationModalMessage.modalId || '';
        this.applicationModalMessageText = applicationModalMessage && applicationModalMessage.text;
        this.applicationModalMessageTitle = applicationModalMessage && applicationModalMessage.title;
        this.applicationModalMessageSubTitle = applicationModalMessage && applicationModalMessage.subtitle || '';
        this.applicationModalMessageImage = applicationModalMessage && applicationModalMessage.image || '';
        this.applicationModalMessageNoBackground = applicationModalMessage && applicationModalMessage.noBackground || false;
        this.withScrolledBody = applicationModalMessage && applicationModalMessage.withScrolledBody || false;
        this.applicationModalButtonCloseText = applicationModalMessage && applicationModalMessage.applicationModalButtonCloseText || '';
        this.modalWithKalturaScript = applicationModalMessage && applicationModalMessage.modalWithKalturaScript || '';
        this.kalturaCustomId = applicationModalMessage && applicationModalMessage.kalturaCustomId || '';
        this.noConfirmButton = applicationModalMessage && applicationModalMessage.noConfirmButton || false;
        this.confirmLabel = applicationModalMessage && applicationModalMessage.confirmLabel || '';
        // oggetto badge modale
        this.applicationModalBadge = applicationModalBadge || [];

        if (this.applicationModalBadge && this.applicationModalBadge.length) {
          for (let i = 0; i < this.applicationModalBadge.length; i++) {
            setTimeout(() => { this.modalService.open('modalBadge' + this.applicationModalBadge[i].badgeId); }, 150)
          }
        }

        if (this.applicationModalMessageText && this.applicationModalMessageText.length) {
          this.renderer.addClass(document.body, 'modal-open');
          // Ho un nuovo messaggio da mostrare, quindi apro lo modale per visualizzarlo
          this.modalService.open('modalMessage');
        }
      });
  }

  ngOnInit() {
    const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
    const isSelfAssessment$: Observable<boolean> = this.store.select(fromApp.getIsSelfAssessment);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isCppAdmin$: Observable<boolean> = this.store.select(fromApp.getIsCppAdmin);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const combinedSelectes$ = combineLatest([loggedUser$, isPeopleAppraisal$, isSelfAssessment$, isAdmin$, isCppAdmin$])
      .subscribe(
        ([loggedUser, isPeopleAppraisal, isSelfAssessment, isAdmin, isCppAdmin]) => {
          this.basicMenuItems = [];
          this.peopleAppraisalSelfAssessmentMenuItems = [];
          this.peopleAppraisalMenuItems = [];
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.getPerformanceAssessmentYears();
            let language = this.langsService.getUserLang(this.loggedUser);
            moment.locale(language || 'it');
            this.isPeopleAppraisal = isPeopleAppraisal;
            this.isSelfAssessment = isSelfAssessment;
            this.isAdmin = isAdmin;
            this.isCppAdmin = isCppAdmin;
            this.rolesAvailable();
            this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
            if (this.loggedUser && !(!this.isPeopleAppraisal && !this.isSelfAssessment && !this.isAdmin)) {
              this.initials = this.loggedUser.surname[0] + this.loggedUser.forename[0];
              this.translate.get(
                [
                  'generic.WELCOME',
                  'generic.CLOSE',

                ])
                .subscribe(translations => {
                  this.closeBadgeModalLabel = translations['generic.CLOSE'];
                  // Nuova modale
                  // peopleAppraisal modalId: "001"; self assessment modalId: "002"
                  this.headerTooltip = {
                    modalId: isPeopleAppraisal ? "001" : "002",
                    title: translations['generic.WELCOME'],
                    text: 'test',
                    kalturaCustomId: 'kalturaVideoHeaderId',
                    modalWithKalturaScript: '<script src="https://cdnapisec.kaltura.com/p/1936311/sp/193631100/embedIframeJs/uiconf_id/42700011/partner_id/1936311?autoembed=true&entry_id=1_wmmq4is7&playerId=kalturaVideoHeaderId&cache_st=1545652499&width=533&height=122"></script>',
                    applicationModalButtonCloseText: translations['generic.CLOSE'],
                    noConfirmButton: true
                  }
                })
            }
          }
        });
    // Recupero le lingue disponibili
    this.store.dispatch(CoreActions.GetAvailableLangs());
  }
  getPerformanceAssessmentYears() {
    this.isLoadingProcessData = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }

    this.getYearsList$ = this.selfAssessmentService.getPerformanceAssessmentYears(this.loggedUser.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingProcessData = false;
        } else {
          if (data.response.length) {
            let test = data.response.find((process: any) => process.isInProcess);
            if (!test) {
              this.disableAccessToPas = true;
            }
          } else {
            this.disableAccessToPas = true;
          }

          this.isLoadingProcessData = false;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingProcessData = false;
      });
  }


  ngAfterContentChecked() {
    this.isPeopleAppraisalModule = window.location.href.indexOf('/peopleAppraisal/') >= 0;
    this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
    this.isSelfAssessmentModule = window.location.href.indexOf('/selfAssessment/') >= 0;
    this.isLoadingMenuItems = true;
    this.basicMenuItems = [];
    // Voci menu selfAssessment
    if (this.isSelfAssessment) {
      if (this.canAccessPersonalDevelopment) {
        this.basicMenuItems.push({
          id: 'pas',
          name: this.translate.instant('menu.PAS_CARD'),
          icon: '/assets/img/icons/clipboard-list.svg'
        });
      }
      if (this.canAccessToPrivateNotes) {
        this.basicMenuItems.push({
          id: 'sa-notes',
          name: this.translate.instant('menu.NOTES'),
          icon: '/assets/img/icons/edit.svg'
        })
      }
    }
    if (this.isPeopleAppraisal) {
      if (this.canAccessTheDashboard) {
        this.basicMenuItems.push({
          id: 'dashboard',
          name: this.translate.instant('menu.DASHBOARD'),
          icon: '/assets/img/icons/pie-chart.svg'
        });
      }
    }
    if (this.isAdmin || this.isCppAdmin) {
      this.basicMenuItems.push({
        id: 'admin',
        name: this.translate.instant('menu.ENTER_ADMIN'),
        icon: '/assets/img/icons/settings.svg'
      });
    }
    this.basicMenuItems.push({
      id: 'notifications',
      name: this.translate.instant('menu.NOTIFICATIONS'),
      icon: '/assets/img/icons/bell.svg'
    });
    this.basicMenuItems.push({
      id: 'profile',
      name: this.translate.instant('menu.PERSONAL_PROFILE'),
      avatar: this.loggedUser && this.loggedUser.userOptions && this.loggedUser.userOptions.avatarImage,
      initials: this.initials
    });

    if (this.isAdminModule) {
      this.basicMenuItems = [];
      // Nel caso l'utente sia solo cppAdmin non vedrà questi menu
      if (this.isAdmin) {
        this.basicMenuItems.push(
          {
            id: 'process_setup',
            name: this.translate.instant('adminHome.cards.PROCESS_SETUP'),
            icon: '../../../../assets/img/icons/settings.svg'
          });
        this.basicMenuItems.push({
          id: 'people_anag',
          name: this.translate.instant('adminHome.cards.ANAG'),
          icon: '../../../../assets/img/icons/users.svg'
        });
        this.basicMenuItems.push({
          id: 'impersonate',
          name: this.translate.instant('adminHome.cards.IMPERSONATE'),
          icon: '../../../../assets/img/icons/user.svg'
        });
        //{
        //  id: 'structure_anag',
        //  name: this.translate.instant('adminHome.cards.STRUCTURE_ANAG'),
        //  icon: '../../../../assets/img/icons/building.svg'
        //},
        this.basicMenuItems.push({
          id: 'monitoring',
          name: this.translate.instant('adminHome.cards.CALIBRATION'),
          icon: '../../../../assets/img/icons/balance.svg'
        }
        )

        if (this.canAccessTheDashboard) {
          this.basicMenuItems.push({
            id: 'dashboard-admin',
            name: this.translate.instant('adminHome.cards.DASHBOARD'),
            icon: '../../../../assets/img/icons/pie-chart.svg'
          });
        }
        // Nel caso l'utente sia admin cpp vedrà questo menu item
        if (this.isCppAdmin) {
          this.basicMenuItems.push(
            {
              id: 'cpp',
              name: this.translate.instant('adminHome.cards.CPP'),
              icon: '../../../../assets/img/icons/clipboard-list.svg'
            });
        }
      }
      this.isLoadingMenuItems = false;
    }
  }

  onToggleSidebar() {
    this.rolesAvailable();
  }

  rolesAvailable() {
    this.isPeopleAppraisalModule = window.location.href.indexOf('/peopleAppraisal/') >= 0;
    this.isAdminModule = window.location.href.indexOf('/admin/') >= 0;
    this.isSelfAssessmentModule = window.location.href.indexOf('/selfAssessment/') >= 0;

    if ((this.isAdminModule && this.isSelfAssessment) || (this.isAdmin || this.isCppAdmin)) {
      this.rolesMenuItems = [
        { id: 0, value: 'Account Amministratore' },
      ]
      if (this.isPeopleAppraisal || this.isSelfAssessment) {
        this.rolesMenuItems.push({ id: 2, value: 'Home' });
      }
      if (this.isAdminModule) {
        this.selectedRole = this.rolesMenuItems[0]
      } else {
        this.selectedRole = this.rolesMenuItems[1]
      }
    }
  }

  onRadioSelect(event: any) {
    this.selectedRole = event
    if (this.selectedRole.value == 'Account Amministratore') {
      this.router.navigate(['/admin/home']);
    }
    if (this.selectedRole.value == 'Home') {
      this.router.navigate(["/homepage"]);
    }
  }
  // Per il cambio vista Admin
  adminView() {
    return window.location.href.indexOf('/admin/') >= 0;
  }

  isSidebarOpened() {
    this.rolesAvailable()
  }
  // Chiude la modale contenente un errore
  closeModalMessage() {
    this.store.dispatch(CoreActions.DeleteApplicationModalMessage())
    this.renderer.removeClass(document.body, 'modal-open');
    this.modalService.close('modalMessage');
  }

  // Chiude la modale dei badge
  closeModalBadge(badgeId: string) {
    this.store.dispatch(CoreActions.DeleteApplicationModalBadge({ payload: badgeId }));
    this.modalService.close('modalBadge' + badgeId);
  }

  goToAdminHome() {
    this.router.navigate(['/admin/home'])
  }

  // Gestione redirect sidebar header
  onHeaderOptionClicked(item: DropdownItem) {
    if (item.id == 'pas') {
      if (this.disableAccessToPas) {
        const messageObj: ApplicationModalMessage = {
          modalId: "year00TN",
          title: this.translate.instant("errors.DENIED_NOTES_TITLE"),
          text: this.translate.instant("errors.GENERIC_NOT_AVAILABLE_SECTION")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.router.navigate(['selfAssessment/personDetails/', this.loggedUser.userId, this.runningYear.year, this.runningYear.yearCode])
      }
    } else if (item.id == 'people-appraisal') {
      this.router.navigate(['/peopleAppraisal/homepage']);
    } else if (item.id == 'profile') {
      if (this.isPeopleAppraisal) {
        this.router.navigate(["/peopleAppraisal/profile"]);
      } else {
        this.router.navigate(["/selfAssessment/profile"]);
      }
    } else if (item.id === 'notifications') {
      this.redirectService.goToNotifications();
    }
    else if (item.id == 'dashboard') {
      this.router.navigate(['/peopleAppraisal/dashboard', this.runningYear.year, this.runningYear.yearCode]);
    } else if (item.id == 'pa-notes') {
      this.redirectService.goToCreatePrivateNoteForPeopleAppraisal();
    } else if (item.id == 'sa-notes') {
      if (this.disableAccessToPas) {
        const messageObj: ApplicationModalMessage = {
          modalId: "year00TN",
          title: this.translate.instant("errors.DENIED_NOTES_TITLE"),
          text: this.translate.instant("errors.GENERIC_NOT_AVAILABLE_SECTION")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.router.navigate(['selfAssessment/personDetails/myNotes/', this.loggedUser.userId, this.runningYear.year, this.runningYear.yearCode])
      }
    } else if (item.id == 'self-assessment') {
      this.router.navigate(['/selfAssessment/home'])
    } else if (item.id == 'admin') {
      this.router.navigate(['/admin/home']);
    } else if (item.id == 'dashboard-admin') {
      this.router.navigate(['/admin/dashboard']);
    } else if (item.id == 'impersonate') {
      this.router.navigate(['/admin/impersonate']);
    } else if (item.id == 'monitoring') {
      this.router.navigate(['/admin/monitoring']);
    } else if (item.id === 'cpp') {
      this.router.navigate(['/admin/cpp']);
    }
    else {
      this.dashboardDisabledModal = {
        modalId: "156",
        title: "Sezione non disponibile",
        subtitle: "Come mai non posso accedervi?",
        text: "Questa sezione non è al momento disponibile. Quando verrà aperta riceverai un avviso e potrai usufruire delle nuove funzionalità."
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.dashboardDisabledModal }));
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showHeader();
  }

  // Verifica se far vedere l'instazione
  showHeader() {
    return !this.showApplicationLoader && this.loggedUser && !window.location.href.toLocaleLowerCase().includes('login')
      && !this.redirectService.isThisCurrentPage('/404')
      && !this.redirectService.isThisCurrentPage('/403') && !this.redirectService.isThisCurrentPage('compatibilityError')
      && !this.redirectService.isThisCurrentPage('organizeInterview') && !this.redirectService.isThisCurrentPage('errorPage')
      && !this.redirectService.isThisCurrentPage('notifications') && !this.redirectService.isThisCurrentPage('qualifyCompetences')
      && !this.redirectService.isThisCurrentPage('sendApplause')
      && !this.redirectService.isThisCurrentPage('profile')
      && !this.redirectService.isThisCurrentPage('createUpdateEvidence')
      && !this.redirectService.isThisCurrentPage('createUpdatePrivateNote')
      && !this.redirectService.isThisCurrentPage('createUpdateProcess')
      && !this.redirectService.isThisCurrentPage('defineObjectives')
      && !this.redirectService.isThisCurrentPage('createUpdatePeerFeedback')
      && !this.redirectService.isThisCurrentPage('addMidTermReview')
      && !this.redirectService.isThisCurrentPage('finalEvaluation')
      && !this.redirectService.isThisCurrentPage('definePerformanceLevel')
      && !this.redirectService.isThisCurrentPage('postCalibrationEvaluation')
      && !this.redirectService.isThisCurrentPage('support')
      && !this.redirectService.isThisCurrentPage('verifySendSchedule')
      && !this.redirectService.isThisCurrentPage('createUpdateCompetence')
      && !this.redirectService.isThisCurrentPage('createUpdateBehavior')
      && !this.redirectService.isThisCurrentPage('manageRoles')
      && !this.redirectService.isThisCurrentPage('manageScale')
      && !this.redirectService.isThisCurrentPage('editDates')
      && !this.redirectService.isThisCurrentPage('noValidProcess')
      && !this.redirectService.isThisCurrentPage('cppSurvey')
      && !this.redirectService.isThisCurrentPage('createUpdateCpp')
      && !this.redirectService.isThisCurrentPage('manageUpwardFeedback/createUpdate')
      && !this.redirectService.isThisCurrentPage('manageUpwardFeedback/verify')
      && !this.redirectService.isThisCurrentPage('upwardFeedback')
      && !(this.redirectService.isThisCurrentPage('monitoringMatrix') && window.innerWidth <= 980);
  }

  onGoToMySection() {
    this.isTeamPage = !this.isTeamPage;
    this.router.navigate(["/selfAssessment/personDetails/", this.loggedUser.userId, this.runningYear.year, this.runningYear.yearCode]);
  }

  goToMyTeam() {
    this.isTeamPage = !this.isTeamPage;
    this.isTeamButtonClicked = !this.isTeamButtonClicked;
    this.store.dispatch(PeopleAppraisalActions.SetHeaderTeamClickedButton({ payload: this.isTeamButtonClicked }));
    this.router.navigate(["/peopleAppraisal/myTeam"]);
  }

  // Per il cambio vista dei self assessment
  selfAssessmentView() {
    return window.location.href.indexOf('/selfAssessment') > -1;
  }

  // Per il cambio vista dei peopleAppraisal
  peopleAppraisalView() {
    return window.location.href.indexOf('/peopleAppraisal') > -1;
  }

  menuId(index: number, el: any) {
    return el.id;
  }

  forceDisableMenuItem(item: any) {
    return this.disableAccessToPas && SELF_PAS_OPTIONS.indexOf(item.id) >= 0
  }

  ngOnDestroy() {
    if (this.isClickedTeamButton$) {
      this.isClickedTeamButton$.unsubscribe();
    }
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
  }
}