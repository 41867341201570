<div class="page-content-wrapper">
  <header-dossier [isAdminHeader]="true" [hasTabs]="false"
    [adminPageTitle]="'upwardFeedback.admin.MANAGE_TITLE' | translate"
    [adminPageSubtitle]="'upwardFeedback.admin.ROUND_LIST' |translate" [adminBack]="true"
    [adminActionLabel]="'upwardFeedback.admin.CREATE_ROUND' | translate" (onAdminAction)="goToCreateNewRound()"
    adminActionLabelType="success">
  </header-dossier>

  <!-- lista round -->
  <div class="main-content-wrapper admin">
    <!-- contenitore tabella -->
    <ng-container *ngIf="!isLoadingRoundData">
      <div class="admin-table-container">
        <div class="table-content">
          <table>
            <thead>
              <tr>
                <th translate="{{ ('adminTable.UPWARD_ROUND' | translate)}}"></th>
                <th translate="{{ ('adminTable.OPENING_UPWARD_DATE' | translate)}}"></th>
                <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let round of roundData.list | 
                              slice:0:roundData.numRecords | 
                              paginate: { id: 'roundList',
                                          itemsPerPage: roundData.numRecords,
                                          currentPage: roundData.page,
                                          totalItems: roundData.counter }">
                <td>{{ round.name }}</td>
                <td>{{ round.startDate | date: 'dd/MM/YYYY'}}</td>
                <td>
                  {{ round.totalParticipants || 0 }}
                </td>
                <td>
                  <item-list-team-status [type]="round.statusType" [message]="round.statusMessage" styleItem="fill">
                  </item-list-team-status>
                </td>
                <td class="action">
                  <!-- class="link" (click)="goToManageRound(round)">
                  {{ 'adminTable.ACCESS' | translate }}-->

                  <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(round)"
                    (clickOutside)="closeMenu(round)" src="/assets/img/icons/more-horizontal.svg">
                  </svg-icon>
                  <ng-container *ngIf="round.isMenuOpen">
                    <div class="dropdown-menu larger">
                      <ng-container *ngFor="let option of menuOptions">
                        <alloy-dropdown-item (click)="onMenuOptionsClicked(option, round)" class="full-width"
                          [item]="option">
                        </alloy-dropdown-item>
                      </ng-container>
                    </div>
                  </ng-container>

                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="bottom-actions">
          <table-pagination id="roundList" (pageChanged)="roundPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </alloy-select>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- loader -->
    <ng-container *ngIf="isLoadingRoundData">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>
  </div>
</div>