import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'card-interview',
  templateUrl: 'card-interview.component.html',
  styleUrls: ['./card-interview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardInterviewComponent implements OnInit {
  @Input() interview: any;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

  emitOnCancelInterview(id: string) {
    this.onCancel.emit(id);
  }

  emitOnLinkClicked(url: string) {
    if (url.indexOf('http') < 0) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

}