<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon [src]="'assets/img/icons/calendar.svg'" class="small-size gray-gray2" [applyClass]="true">
        </svg-icon>
        <p>{{('interview.card.' + interview.status) | translate}}</p>
      </div>
      <div class="period-time">
        <p>{{interview.formattedCreationDate}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <avatar-img [user]="interview.user" size="medium"></avatar-img>
    <div class="information-text-container">
      <div class="specific-information">
        <p
          [innerHTML]="('interview.card.SET_BY' | translate) + ' <b>' + interview.user.forename + ' ' + interview.user.surname + '</b>'">
        </p>
      </div>
      <p class="information-text">
        <span class="bold-text italic-text label" translate="interview.card.WHEN"></span>
        <span [innerHTML]="interview.when"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text label" translate="interview.card.WHERE"></span>
        <ng-container *ngIf="!interview.isPlaceLink">
          <span [innerHTML]="interview.where"></span>
        </ng-container>
        <ng-container *ngIf="interview.isPlaceLink && interview.status == 'PROGRAMMED'">
          <span class="link" (click)="emitOnLinkClicked(interview.where)"
            translate="interview.card.{{interview.type}}"></span>
        </ng-container>
        <ng-container *ngIf="interview.isPlaceLink && interview.status != 'PROGRAMMED'">
          <span translate="interview.card.{{interview.type}}"></span>
        </ng-container>
      </p>
      <ng-container *ngIf="interview.argument">
        <p class="information-text">
          <span class="bold-text italic-text label">{{ ('interview.card.ARGUMENT'| translate ) + ':'}}</span>
          <span [innerHTML]="interview.argument"></span>
        </p>
      </ng-container>

      <div class="action-footer" *ngIf="interview.status === 'PROGRAMMED' && interview.canCancel">
        <p class="text-default-link primary-text-color cursor-pointer" translate="interview.card.CANCEL"
          (click)="emitOnCancelInterview(interview.feedbackDialogueId)">
        </p>
      </div>
    </div>
  </div>
</div>