import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'card-evidence',
  templateUrl: 'card-evidence.component.html',
  styleUrls: ['./card-evidence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardEvidenceComponent implements OnInit {
  @Input() object: any;
  @Input() loggedUser?: any;
  @Input() isInChallengeDetail?: boolean;
  @Input() goalTitle?: boolean;
  @Input() isHistory: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onUploadFile: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteFile: EventEmitter<any> = new EventEmitter();
  @Output() onFileDownload: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

  emitOnDeleteFile(id: string) {
    this.onDeleteFile.emit(id);
  }

  emitOnFileDownload(file: any) {
    this.onFileDownload.emit(file);
  }

  // Recupera la stringa per l'estensione del file
  getExtensionString(fileName: string) {
    let extension = fileName.slice(fileName.lastIndexOf('.'), fileName.length);
    extension = extension.replace('.', '');
    if (extension == 'pptx' || extension == 'PPTX') {
      extension = 'ppt';
    } else if (extension == 'jpg' || extension == 'JPG') {
      extension = 'jpeg';
    } else if (extension == 'xlsx' || extension == 'XLSX') {
      extension = 'xls';
    } else if (extension == 'docx' || extension == 'DOCX') {
      extension = 'doc';
    } else if (extension == 'eml' || extension == 'EML') {
      extension = 'eml';
    } else if (extension == 'msg' || extension == 'MSG') {
      extension = 'msg';
    } else if (extension == 'txt' || extension == 'TXT') {
      extension = 'txt';
    } else if (extension == 'rtf' || extension == 'RTF') {
      extension = 'rtf';
    } else if (extension == 'png' || extension == 'PNG') {
      extension = 'png';
    }
    return extension;
  }

  // Se può fare le azioni nei file
  canDoActions(object: any) {
    if ((this.loggedUser && this.loggedUser.userId) == object.creationUserId && !this.isInChallengeDetail) {
      return true;
    } else {
      return false;
    }
  }

  emitOnEditAction(id: any) {
    this.onEdit.emit(id);
  }

  emitOnUploadFile() {
    this.onUploadFile.emit();
  }
}