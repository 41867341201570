import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';

@Component({
  selector: 'badge-wrapper',
  templateUrl: 'badge-wrapper.component.html',
  styleUrls: ['./badge-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeWrapperComponent implements OnInit {
  @Input() badge: BadgeObject = {
    badgeId: '',
    badgeType: '',
    title: '',
    image: '',
    isActive: false,
    starNumberArray: [],
    date: null
  };
  @Input() isInModal: boolean = false;
  @Output() onClick: EventEmitter<BadgeObject> = new EventEmitter<BadgeObject>();

  constructor() {
  }

  ngOnInit() {
  }

  onBadgeClick() {
    this.onClick.emit(this.badge);
  }
}