import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse } from 'src/commonclasses';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';

@Component({
  selector: 'app-peopleAppraisal-createUpdatePeerFeedback',
  templateUrl: './createUpdatePeerFeedback.component.html',
  styleUrls: ['./createUpdatePeerFeedback.component.scss']
})
export class CreateUpdatePeerFeedbackPeopleAppraisalComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any = {};
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  modalCreateUpdatePeerFeedbackId: string = '';
  modalCreateUpdatePeerFeedbackTitle: string = '';
  modalCreateUpdatePeerFeedbackSubtitle: string = '';
  modalCreateUpdatePeerFeedbackText: string = '';
  modalCreateUpdatePeerFeedbackTitleConfirmTextButton: string = '';
  modalCreateUpdatePeerFeedbackTextCloseTextButton: string = '';
  observationsModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  feedbackId: string = '';
  isLoadingFeedback: boolean = false;
  feedbackObject: any = {};
  getFeedback$: Subscription = new Subscription;
  feedbackObjectCopy: any;
  answerPeerFeedback$: Subscription = new Subscription;
  isUpdate: boolean = false;
  isExt: boolean = false;

  constructor(
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    if (window.location.href.indexOf('/ext') > -1) {
      this.isExt = true;
    }
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.feedbackId = params.feedbackId;
              this.getFeedback();
            }
          );
      }
    });
  }


  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'peerFeedback.createUpdate.OBJECTIVE_TITLE',
        'peerFeedback.modals.OBJECTIVE_TITLE_INFO',
        'peerFeedback.createUpdate.REQUEST_MESSAGE',
        'peerFeedback.modals.REQUEST_MESSAGE_INFO',
        'peerFeedback.createUpdate.OBSERVATIONS',
        'peerFeedback.modals.OBSERVATIONS_INFO',
      ])
      .subscribe(translations => {
        this.objectiveTitleModal = {
          modalId: "pf001",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.OBJECTIVE_TITLE'],
          text: translations['peerFeedback.modals.OBJECTIVE_TITLE_INFO']
        };
        this.requestMessageModal = {
          modalId: "pf002",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.REQUEST_MESSAGE'],
          text: translations['peerFeedback.modals.REQUEST_MESSAGE_INFO']
        };
        this.observationsModal = {
          modalId: "pf003",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.OBSERVATIONS'],
          text: translations['peerFeedback.modals.OBSERVATIONS_INFO']
        };
      })
  }

  getFeedback() {
    this.isLoadingFeedback = true;

    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }

    let callToService;
    if (this.isExt) {
      callToService = this.peopleAppraisalService.getExternalPeerFeedbackById(this.runningYear.year.toString(), this.feedbackId)
    } else {
      callToService = this.peopleAppraisalService.getPeerFeedbackById(this.runningYear.year.toString(), this.userId, this.feedbackId)
    }

    this.getFeedback$ = callToService
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackObject = data.response;
          if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.length > 0) {
            this.isUpdate = true;
          }
          this.feedbackObjectCopy = JSON.parse(JSON.stringify(this.feedbackObject));
        }
        this.isLoadingFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        })
  }


  answerPeerFeedback() {
    this.isLoadingFeedback = true;
    if (this.answerPeerFeedback$) {
      this.answerPeerFeedback$.unsubscribe();
    }
    let peerFeedback = {
      feedbackId: this.feedbackId,
      request: this.feedbackObject.request,
      goalId: this.feedbackObject.goal && this.feedbackObject.goal.goalId,
      approverObservation: this.feedbackObject.approverObservation,
      recipientUserId: this.feedbackObject.recipientUser && this.feedbackObject.recipientUser.userId
    }

    let callToService;
    if (this.isExt) {
      callToService = this.peopleAppraisalService.updateExternalPeerFeedback(this.runningYear.year.toString(), peerFeedback)
    } else {
      callToService = this.peopleAppraisalService.updatePeerFeedback(this.runningYear.year.toString(), peerFeedback)
    }

    this.answerPeerFeedback$ = callToService
      .subscribe(
        (data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf020",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.obtainedBadges && data.obtainedBadges.length) {
              let badges: any[] = [];
              if (data.obtainedBadges.length > 1) {
                badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
              } else {
                badges = data.obtainedBadges;
              }
              let tmpBadgesList: BadgeObject[] = [];
              if (badges && badges.length) {
                tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
              }
            }
          }
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
  }


  observationsChanged(text: string) {
    this.feedbackObject.approverObservation = text;
  }

  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdatePeerFeedback(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdatePeerFeedback(isBack);
      this.openModalForCreateUpdatePeerFeedback();
    }
  }

  formatModalCreateUpdatePeerFeedback(isBack?: boolean) {
    this.modalCreateUpdatePeerFeedbackId = this.getModalId(isBack);
    this.modalCreateUpdatePeerFeedbackTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdatePeerFeedbackSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdatePeerFeedbackText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdatePeerFeedbackTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
    this.modalCreateUpdatePeerFeedbackTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
  }


  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'pf005';
    } else {
      if (isBack) {
        if (this.isUpdate) {
          return 'pf006';
        } else {
          return 'pf007';
        }
      } else {
        if (this.isUpdate) {
          return 'pf008';
        } else {
          return 'pf009';
        }
      }
    }
  }

  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('generic.GO_BACK');
      } else {
        if (this.isUpdate) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.createUpdate.INSERT_FEEDBACK');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.isUpdate && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_INSERTING_A_FEEDBACK');
    } else if (this.isUpdate && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_UPDATING_A_FEEDBACK');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.isUpdate) {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      } else {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      }
    } else {
      if (isBack) {
        if (this.isUpdate) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK_BACK');
        }
      } else {
        if (this.isUpdate) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateNoteModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.isUpdate) {
          return this.translate.instant('peerFeedback.modals.UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.INSERT_FEEDBACK_BACK');
        }
      } else {
        if (this.isUpdate) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.createUpdate.INSERT_FEEDBACK');
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.trim().length) {
      return true;
    }
    return false;
  }

  checkIfDataIsChanged() {
    return this.feedbackObject && this.feedbackObjectCopy && this.feedbackObject.approverObservation != this.feedbackObjectCopy.approverObservation;
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdatePeerFeedback() {
    this.modalService.open('modalCreateUpdatePeerFeedback');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdatePeerFeedback() {
    this.modalService.close('modalCreateUpdatePeerFeedback');
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }
  }
}