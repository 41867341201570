<div class="title-code-wrapper full-width">
  <ng-container *ngIf="!badge">
    <div class="title-container">{{ title }}</div>
    <p *ngIf="showModalCode && modalId" class="modal-code-wrapper">{{ 'CODICE MODALE: ' + modalId}}</p>
  </ng-container>
  <ng-container *ngIf="badge">
    <div class="title-container">{{ ('badges.modals.TITLE' | translate) + badge.title }}</div>
    <p *ngIf="showModalCode && badge.badgeType" class="modal-code-wrapper">{{ 'CODICE MODALE: ' + badge.badgeType}}
    </p>
  </ng-container>
</div>
<!-- contenuto modale testo -->
<ng-container *ngIf="!badge">
  <div *ngIf="image" class="badge-container" [ngClass]="{'no-background': noBackground}">
    <svg-icon class="badge" [src]="image"></svg-icon>
  </div>
  <div class="text-wrapper"
    [ngClass]="{'body-scrolled': withScrolledBody, 'riepilogue-develop-action': isRiepilogueDevelopAction }">
    <div class="subtitle">{{ subtitle }}</div>
    <div *ngIf="modalWithKalturaScript && modalWithKalturaScript.length" class="video-content">
      <!--kaltura-player [inModal]="true" #playerVideoKaltura class="object-wrapper" [playerVisible]="true"
        [kalturaScript]="modalWithKalturaScript" [customId]="kalturaCustomId || ''">
      </kaltura-player-->
      <div class="subtitle margin-top15">{{ 'generic.SUPPORT' | translate }}</div>
      <div class="text" [innerHTML]="'generic.SUPPORT_DESCRIPTION' | translate"></div>
    </div>
    <div *ngIf="!modalWithKalturaScript || !modalWithKalturaScript.length" class="text"
      [ngClass]="{'scrollable': isScrollable}" [innerHTML]="text"></div>
  </div>
</ng-container>
<!-- contentuto modale badge -->
<div *ngIf="badge" class="badge-container" [ngClass]="{'no-background': noBackground}">
  <badge-wrapper [isInModal]="true" [badge]="badge"></badge-wrapper>
</div>
<ng-container *ngIf="badge">
  <div class="text-wrapper">
    <ng-container *ngIf="badge.isActive">
      <div class="subtitle" translate=" {{('badges.modals.ACTIVE' | translate )}}"></div>
      <ng-container *ngIf="badge.date">
        <div class="text">{{ ('badges.modals.CONGRATULATIONS' | translate ) + ' ' + badge.date + ' ' +
          ('badges.notifications.' + badge.badgeType | translate)}}</div>
      </ng-container>
      <ng-container *ngIf="!badge.date">
        <div class="text">{{ ('badges.modals.CONGRAT_NO_DATE' | translate ) + ' ' +('badges.notifications.' +
          badge.badgeType | translate)}}</div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!badge.isActive">
      <div class="subtitle" translate="{{('badges.modals.DISABLED' | translate )}}"></div>
      <div class="text" translate="{{('badges.modals.' + badge.badgeType | translate) }}"></div>
    </ng-container>

  </div>
</ng-container>