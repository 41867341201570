import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";
export function switchOnce<T, R>(project: (value: T, index?: number) => ObservableInput<R>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<R> {
    return source.pipe(take(1), switchMap(project))
  }
}
export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}