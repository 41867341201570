import { createAction, props } from '@ngrx/store';

export const GetMatrixData = createAction(
  '[admin] get stored matrix data with peopleAppraisal or users list'
);

export const SetMatrixDataPeopleAppraisal = createAction(
  '[admin] set peopleAppraisal for matrix data',
  props<{ payload: any }>()
);

export const SetMatrixDataUserList = createAction(
  '[admin] set user list for matrix data',
  props<{ payload: any[] }>()
);


export const SetFiltersForMatrix = createAction(
  '[admin] set filter text for matrix',
  props<{ payload: string }>()
)
