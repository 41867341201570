import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfAlloyPeerFeedback } from 'atfcore-commonclasses';
import * as moment from 'moment';

@Component({
  selector: 'card-peer-insight',
  templateUrl: 'card-peer-insight.component.html',
  styleUrls: ['./card-peer-insight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardPeerInsightComponent implements OnInit {
  @Input() object!: PerfAlloyPeerFeedback;
  @Input() loggedUser?: any;
  @Input() isPeopleAppraisal?: boolean;
  @Input() isReceived?: boolean;
  @Input() isHistory: boolean = false;
  @Input() userIdSelected: string = '';
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFeedbackSend: EventEmitter<any> = new EventEmitter();
  @Output() onShowRequest: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService,
  ) {
  }
  creationDateText: string = '';
  commentDateText: string = '';
  items: Array<{ title: string, text?: string }> = [];
  type: 'completed' | 'inProgress' | 'pending' | 'toStart' | 'disabled' | '' = '';
  isPosted: boolean = false;
  showMore: boolean = false;
  label: string = '';
  requestTitle: string = '';
  responseTitle: string = '';
  requestIcon: string = '';
  responseIcon: string = '';


  ngOnInit() {
    this.creationDateText = moment(this.object.creationDate).fromNow();
    this.commentDateText = moment(this.object.commentDate).fromNow();

    if (this.object.recipientUser?.userId !== this.object.senderUser?.userId && this.userIdSelected !== this.object.recipientUser?.userId) {
      this.items.push(
        { title: this.translate.instant('feedbackSection.peerFeedback.TOWARDS'), text: this.object.recipientUser?.surname + ' ' + this.object.recipientUser?.forename }
      );
    }

    if (this.object.targetGoal) {
      this.items.push(
        { title: this.translate.instant('feedbackSection.peerFeedback.REFERRED_TO'), text: this.object.targetGoal.text }
      );
    }

    this.items.push(
      { title: this.translate.instant('feedbackSection.peerFeedback.MOTIVATION'), text: this.object.motivation }
    );


    if (this.object.senderUserId) {
      this.isPosted = window.location.href.indexOf(this.object.senderUserId) > -1 || this.object.senderUserId == this.loggedUser.userId;
    }

    if (this.object.recipientUser?.userId === this.object.senderUser?.userId) {
      this.label = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK');
    } else {
      this.label = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB');
    }

    // Imposto il titolo
    // Controllo se si tratta di un peer Feedback o feedback al responsabile
    if (this.object.recipientUser?.userId == this.object.senderUser?.userId) {
      // Assegno l'icona 
      this.requestIcon = 'assets/img/icons/hard-hat.svg';
      this.responseIcon = 'assets/img/icons/message-circle-black.svg';

      // Controllo se l'utente visualizzato è il sender
      if (this.userIdSelected == this.object.senderUserId) {
        // Controllo se esiste il commento
        if (this.object.comment) {
          // Assegno l'icona della response
          this.requestTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_REQUEST_FROM') + '<b> ' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
          this.responseTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_OF') + '<b> ' + this.object.intermediateUser?.surname + ' ' + this.object.intermediateUser?.forename;
        } else {
          this.requestTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_REQUEST') + '<b> ' + this.object.intermediateUser?.surname + ' ' + this.object.intermediateUser?.forename;
        }
      } else {
        this.requestTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_REQUEST_OF') + '<b> ' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
        this.responseTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SENT') + '<b> ' + this.object.recipientUser?.surname + ' ' + this.object.recipientUser?.forename;
      }
    } else {
      // Assegno l'icona 
      this.requestIcon = 'assets/img/icons/merge.svg';
      this.responseIcon = 'assets/img/icons/merge.svg';
      // Controllo se l'utente visualizzato è il sender
      if (this.userIdSelected == this.object.intermediateUser.userId) {
        this.requestTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_FROM') + '<b> ' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
        this.responseTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_SENT') + '<b>' + this.object.recipientUser?.surname + ' ' + this.object.recipientUser?.forename + '</b> ' + this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_SENT_2') + '<b> ' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
      } else {
        this.requestTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_REQUEST') + '<b> ' + this.object.intermediateUser?.surname + ' ' + this.object.intermediateUser?.forename;
        this.responseTitle = this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_SENT') + '<b>' + this.object.recipientUser?.surname + ' ' + this.object.recipientUser?.forename + '</b> ' + this.translate.instant('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB_SENT_2') + '<b> ' + this.object.senderUser?.surname + ' ' + this.object.senderUser?.forename;
      }

    }


    // Imposto il type 
    if (!this.object.comment) {
      this.type = 'inProgress';
      if (!this.isPosted || (this.isPeopleAppraisal && this.userIdSelected !== this.object.recipientUser?.userId)) {
        this.type = 'toStart';
      }
    }
  }

  emitOnFeedbackSend(id: any) {
    this.onFeedbackSend.emit(id);
  }

  showMoreText() {
    this.showMore = true;
  }

  emitOnShowRequest() {
    this.onShowRequest.emit(this.object);
  }
}