<div *ngIf="runningYear" class="wrapper-layout-peopleAppraisal">
  <!-- Sidebar con il menù principale -->
  <!-- [ngClass]="{'open-on-mobile': isTeamButtonClicked}" -->
  <team-sidebar class="team-sidebar" *ngIf="showSidebar()" [users]="subordinatesWithPhaseStatuses"
    [activeUserId]="activeUserId" (userClicked)="onUserClicked($event)" [isLoadingUsers]="isLoadingSubordinates"
    [hasImpersonateBanner]="showImpersonateBanner" [structureUsers]="structureSubordinatesWithPhaseStatuses"
    [isOpenedSecondLevel]="isOpenedSecondLevel" (secondLevelOpen)="secondLevelOpen($event)">
  </team-sidebar>

  <!-- Team sidebar per cpp -->
  <team-sidebar class="team-sidebar" *ngIf="showSidebarCpp()" [isCppSubordinates]="true" [users]="cppSubordinates"
    [activeUserId]="activeUserId" (userClicked)="onCppUserClicked($event)" [isLoadingUsers]="isLoadingSubordinates"
    [hasImpersonateBanner]="showImpersonateBanner">
  </team-sidebar>

  <div class="router-outlet-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- Modale utente struttura disabilitato -->
<modal id="disabled-structure-user-modal" (onClose)="closeDisabledStructureUserModal()"
  (onConfirm)="closeDisabledStructureUserModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE' | translate"
    [subtitle]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE_SUBTITLE' | translate"
    [text]="'homeCards.peopleAppraisal.ASSET_NOT_FRUIBLE_DESCR' | translate">
  </modal-text-content>
</modal>