<div class="item-list-container"
  [ngClass]="{'disabled': _user && !_user.hasPasCompleted && _user?.structureUser, 'active': activeUserId === (_user && _user.userId), 'expand': onExpand, 'collapse': !onExpand}"
  (mouseenter)="expand()" (mouseleave)="collapse()" (click)="emitOnUserClicked()">
  <avatar-img [secondStructure]="_user?.structureUser" [user]="_user" size="medium"></avatar-img>
  <div class="more-info-container">
    <p>{{ userName }}</p>
    <!-- Stato PAS -->
    <ng-container *ngIf="!isCppUsers && !isInsightFeedback">
      <item-list-team-status *ngIf="!circlePercentage" [type]="userPhaseStatus"
        [message]="('itemListTeamStatus.' + userPhaseStatus)" styleItem="fill">
      </item-list-team-status>
      <ng-container *ngIf="circlePercentage">
        <div fxLayout="row" class="percentage-container">
          <circle-percentage [isFromSidebar]="true" [title]="persentage"></circle-percentage>
          <p *ngIf="title">{{title}}</p>
        </div>
      </ng-container>
    </ng-container>
    <!-- Stato CPP -->
    <item-list-team-status *ngIf="isCppUsers"
      [type]="typeDevelopmentPlanStatus == 'toStart'? 'disabled' : typeDevelopmentPlanStatus" [styleItem]="'fill'"
      [message]="('cppCollaborators.' + developmentPlanStatus) | translate">
    </item-list-team-status>

    <!-- Stato Insight feedback -->
    <item-list-team-status *ngIf="isInsightFeedback" [type]="insightStatus" styleItem="fill"
      [message]="('insight.matrix.status.' + managerStatusLabel) | translate">
    </item-list-team-status>
  </div>
  <div class="arrow"></div>
</div>