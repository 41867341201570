/*
 * Componente per l'errore 404, pagina richiesta non trovata
*/

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from '../../ngrx/app.reducers';

@Component({
  selector: 'app-page-not-authorized',
  templateUrl: './page-not-authorized.component.html',
  styleUrls: ['./page-not-authorized.component.scss']
})
export class PageNotAuthorizedComponent {
  isImpersonate: boolean = false;

  constructor(
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
  }

}
