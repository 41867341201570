<div class="header-container">
  <div class="left-side">
    <!-- Se lato utente -->
    <ng-container >
      <div class="teams" id="home-logo" (click)="emitOnAppLogoClicked()">
        <div class="logo-description-wrapper">
          <div class="home-logo-wrapper">
            <svg-icon src="/assets/img/icons/contact.svg" class="smaller-size white-svg" [applyClass]="true">
            </svg-icon>
          </div>
          <div class="user-logo-description">
            <p translate="header.HOME"></p>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="logo-app" (click)="emitOnAppLogoClicked()">
      <svg-icon [applyClass]="true" src="/assets/img/icons/app-logo.svg" class="logo"></svg-icon>
    </div>
  </div>
  <div class="right-side">

    <!-- Lato destro desktop -->
    <ng-container *ngIf="!isMobile">
      <ng-container *ngIf="!isAdminModule && !disableAccessToPas">
        <svg-icon id="supportTagIcon" src="/assets/img/icons/help-circle.svg"
          class="medium-size blue-primary black-icons" [applyClass]="true" (click)="goToSupport()"></svg-icon>
      </ng-container>
      <div class="notification-container" id="notifications" (click)="emitOnNotificationClicked($event)">
        <svg-icon src="/assets/img/icons/bell.svg" class="medium-size blue-primary black-icons" [applyClass]="true">
        </svg-icon>
        <div class="number-container" *ngIf="notificationsCounter > 0">
          <badge-notifications [badgeNumber]="notificationsCounter" type="active"></badge-notifications>
        </div>
      </div>
      <div id="menu-profile" class="one-role-switch-container">
        <div class="switch-avatar-wrapper">
          <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
          <div class="user-data-wrapper">
            <p class="full-name">{{userName}}</p>
            <!-- Manager (non admin) -->

            <p *ngIf="selfAssessmentView()" class="role" translate="header.SELFASSESSMENT"></p>
            <p *ngIf="peopleAppraisalView()" class="role" translate="header.MANAGER"></p>
            <p *ngIf="adminView()" class="role" translate="header.ADMIN"></p>

          </div>
        </div>
        <svg-icon class="medium-size hamburger-menu-icon black-icons"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>
      </div>
    </ng-container>
    <!-- Lato destro mobile -->
    <ng-container *ngIf="isMobile">
      <ng-container *ngIf="!isAdminModule && !disableAccessToPas">
        <svg-icon id="supportTagIcon" src="/assets/img/icons/help-circle.svg"
          class="medium-size blue-primary black-icons" [applyClass]="true" (click)="goToSupport()"></svg-icon>
      </ng-container>
      <div class="user-data-container">
        <avatar-img size="small" [src]="avatar" [initials]="initials"></avatar-img>
        <svg-icon class="medium-size blue-primary black-icons"
          [src]="isSidebarOpened() ? '/assets/img/icons/x.svg' : '/assets/img/icons/menu.svg'" [applyClass]="true"
          (click)="toggleSidebar()"></svg-icon>
      </div>
    </ng-container>
  </div>
</div>