import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alloy-card',
  templateUrl: './alloy-card.component.html',
  styleUrls: ['./alloy-card.component.scss']
})
export class AlloyCardComponent implements OnInit {

  @Input() title: string = '';
  @Input() srcIcon: string = '../../../assets/img/icons/sprout.svg';
  @Input() type: string = '';
  @Input() scope: string = '';
  @Input() date!: Date;
  @Input() status: string = '';
  @Input() updatesNumber: number = 0;
  @Input() disabledForUser: boolean = false;
  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdatesClicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() disabled: boolean = false;
  @Input() disabledDeleteOption: boolean = false;
  isMenuOpen: boolean = false;
  menuObject: Array<{ id: string, title: string, icon?: string, isChecked?: boolean, disabled: boolean }> = [];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    // this.translate.get([
    //   "generic.EDIT",
    //   "generic.DELETE",
    // ]).subscribe((translations) => {
    //   this.menuObject = [
    //     {
    //       id: 'edit',
    //       title: translations['generic.EDIT'],
    //       icon: '/assets/img/icons/edit.svg',
    //       disabled: this.disabledForUser
    //     },
    //     {
    //       id: 'delete',
    //       title: translations['generic.DELETE'],
    //       icon: '/assets/img/icons/trash-2.svg',
    //       disabled: this.disabledForUser || true
    //     }
    //   ]
    // })
  }
  ngOnChanges(changes: SimpleChanges) {
    this.translate.get([
      "generic.EDIT",
      "generic.DELETE",
    ]).subscribe((translations) => {
      this.menuObject = [
        {
          id: 'edit',
          title: translations['generic.EDIT'],
          icon: '/assets/img/icons/edit.svg',
          disabled: this.disabledForUser
        },
        {
          id: 'delete',
          title: translations['generic.DELETE'],
          icon: '/assets/img/icons/trash-2.svg',
          disabled: this.disabledForUser || this.disabledDeleteOption
        }
      ]
    })
  }
  openMenu(open: boolean) {
    this.isMenuOpen = open;
  }
  emitOnOptionClicked(item: any) {
    this.onMenuClicked.emit(item);
  }

  emitOnUpdatesClicked() {
    this.onUpdatesClicked.emit();
  }
}
