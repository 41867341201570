import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() creationDate: string = '';
  @Input() hasAvatar: boolean = false;
  @Input() avatarRight: boolean = false;
  @Input() type: 'completed' | 'inProgress' | 'pending' | 'toStart' | 'disabled' | '' = '';
  @Input() user: any;
  @Input() disabled: boolean = false;
  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();

  isMenuOpen: boolean = false;
  menuObject: any;
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.get([
      "generic.EDIT",
      "generic.DELETE",
    ]).subscribe((translations) => {
      this.menuObject = [
        {
          id: 'edit',
          title: translations['generic.EDIT'],
          icon: '/assets/img/icons/edit.svg',
          disabled: !this.avatarRight
        }
      ]
    })
  }
  openMenu(open: boolean) {
    this.isMenuOpen = open;
  }

  emitOnOptionClicked(item: any) {
    this.onMenuClicked.emit(item);
  }
}
