<div class="linear-rating-wrapper">
  <ng-container *ngFor="let rating of ratings; let last = last">
    <div class="rating-row">
      <!-- Titolo rating -->
      <p class="label">{{ rating.title }}</p>
      <div class="rating-bar-wrapper">
        <!-- Barra percentuale-->
        <div class="rating-bar">
          <!-- Filling-->
          <div class="fill" [ngStyle]="{'width': getRatingPercentage(rating) + '%'}"></div>
        </div>
        <p class="numeric">{{ rating.value | number : '1.2-2'}}</p>
      </div>
    </div>
  </ng-container>
  <div class="rating-row">
    <p class="label"></p>
    <div class="rating-bar-wrapper">
      <!-- Scala utilizzata-->
      <div class="scale-bar">
        <ng-container *ngFor="let num of scaleArray">
          <p>{{num}}</p>
        </ng-container>
      </div>
      <p class="numeric"></p>
    </div>
  </div>
  <!-- media -->
  <div class="average-row">
    <p class="label" translate="generic.AVERAGE"></p>
    <div class="value-row">
      <p class="average-label">{{average || getAverage() | number : '1.2-2'}}</p>
      <svg-icon src="assets/img/icons/star.svg" [applyClass]="true" class="average-icon">
      </svg-icon>
    </div>
  </div>

</div>