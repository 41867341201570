<div class="recap-wrapper">
  <div class="section">
    <avatar-img [user]="user" size="medium" [whiteBackground]="whiteBackground"></avatar-img>
  </div>
  <div class="section" *ngIf="user || isCalibrationUser">
    <p class="name"> {{ user.forename + ' ' + user.surname }}</p>
    <ng-container *ngIf="midTermData && midTermData.status">
      <p class="ghost-title" translate="calibration.MID_TERM_REVIEW"></p>
      <div class="status" *ngIf="midTermData.status">
        <svg-icon class="status-icon" [src]="MID_STATUS_SRC[midTermData.status]" [applyClass]="true"></svg-icon>
        <p translate="midTerm.statuses.{{ midTermData.status }}"></p>
      </div>
      <p class="date" *ngIf="midTermData.date">{{ ('midTerm.REVIEWED_IN' | translate) + ' ' + midTermData.date }}</p>
      <p class="observation" [innerHtml]="midTermData.observations"></p>
    </ng-container>
    <ng-container *ngIf="finalEvaluationData && finalEvaluationData.level">
      <p class="ghost-title" translate="calibration.PERFORMANCE_LEVEL"></p>
      <p class="observation" [innerHtml]="finalEvaluationData.observations"></p>
      <div class="status" *ngIf="finalEvaluationData.level">
        <evaluation [evaluation]="finalEvaluationData.level" [scale]="5"></evaluation>
      </div>
      <div class="payout" *ngIf="finalEvaluationData.payout">
        <p>{{ finalEvaluationData.payout }}</p>
        <p translate="performance.modals.OF_PAYOUT"></p>
      </div>
    </ng-container>
  </div>
</div>