import { RentStatusTypes, GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';
import { CppDevelopmentPlanStatusesClass, PerfAlloyUserGoal } from 'atfcore-commonclasses';

export class SelfAssessmentUtils {
  // Ritorna lo stato dell'utente
  static getUserStatus(user: any | null) {
    if (user && user.status) {
      switch (user.status.statusType) {
        case RentStatusTypes.TO_START:
          return 'toStart';
        case RentStatusTypes.STARTED:
          return 'inProgress';
        case RentStatusTypes.WAITING_FOR_CONFIRM:
          return 'waiting';
        case RentStatusTypes.PENDING:
          return 'pending';
        case RentStatusTypes.COMPLETED:
          return 'completed';
        default:
          return 'disabled';
      }
    }
    return '';
  }

  static getCppUserStatus(user: any | null) {
    if (user && user.developmentPlanStatus) {
      switch (user.developmentPlanStatus) {
        case CppDevelopmentPlanStatusesClass.NOT_STARTED:
          return 'toStart';
        case CppDevelopmentPlanStatusesClass.STARTED:
          return 'inProgress';
        case CppDevelopmentPlanStatusesClass.COMPLETED:
          return 'completed';
        default:
          return 'disabled';
      }
    }
    return ''
  }
}