<div class="badge-wrapper" [ngClass]="{'disabled': !badge.isActive, 'no-pointer': isInModal}" (click)="onBadgeClick()">
  <div class="badge-icon" [ngClass]="{'golden': badge.badgeType == 'GOLDEN'}">
    <svg-icon [src]="badge.image" [applyClass]="true" class="icon-size"></svg-icon>
  </div>
  <p class="title">{{ badge.title }}</p>
  <div class="star-container">
    <ng-container *ngIf="badge.isActive">
      <svg-icon *ngFor="let number of badge.starNumberArray" src="assets/img/icons/star-filled.svg" [applyClass]="true"
        class="star-icon">
      </svg-icon>
    </ng-container>
    <ng-container *ngIf="!badge.isActive">
      <svg-icon *ngFor="let number of badge.starNumberArray" src="assets/img/icons/star-filled-gray.svg"
        [applyClass]="true" class="star-icon">
      </svg-icon>
    </ng-container>
  </div>
</div>