import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'tag-user',
  templateUrl: 'tag-user.component.html',
  styleUrls: ['./tag-user.component.scss']
  // tolto il change on push per problemi con l'attivazione delle tab
})
export class TagUserComponent implements OnInit {
  @Input() user: any;

  constructor() { }

  ngOnInit() { }
}