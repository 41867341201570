<table>
  <thead>
    <tr>
      <th class="objective-title">
        <p translate="table.OBJECTIVE"></p>
      </th>
      <th class="weight">
        <p translate="table.WEIGHT"></p>
      </th>
      <th class="target">
        <p translate="table.TARGET"></p>
      </th>
      <th class="evaluation">
        <p translate="table.EVALUATION"></p>
      </th>
      <th *ngIf="isEdit"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let objective of objectives">
      <td class="objective-title">
        <p [ngClass]="{'excluded': objective.ratingStatus == 'EXCLUDED' && objective.targetStatus == 'EXCLUDED'}">
          {{ objective.title }}</p>
      </td>
      <td class="weight">
        <p [ngClass]="{'excluded': objective.ratingStatus == 'EXCLUDED' && objective.targetStatus == 'EXCLUDED'}">
          {{ objective.weight + '%'}}</p>
      </td>
      <td class="target">
        <p *ngIf="objective.targetStatus == 'DEFINED'">{{objective.target}}</p>
        <item-list-team-status *ngIf="objective.targetStatus != 'DEFINED'" [message]="objective.targetMessage"
          [type]="objective.targetStatusForComponent" styleItem="vuoto">
        </item-list-team-status>
      </td>
      <td class="evaluation">
        <ng-container *ngIf="objective.ratingStatus == 'EVALUATED'">
          <p>{{ (('goalSetting.updatePMGoal.' + objective.rating) | translate) | titlecase }}</p>
          <p
            [ngClass]="{'green': objective.rating == 'EXCEEDED' || objective.rating == 'ACHIEVED', 'red': objective.rating == 'NOT_ACHIEVED'}">
            {{ objective.result }}</p>
        </ng-container>
        <ng-container *ngIf="objective.ratingStatus != 'EVALUATED'">
          <item-list-team-status *ngIf="objective.ratingStatus != 'EVALUATED'" [message]="objective.resultMessage"
            [type]="objective.resultStatusForComponent" styleItem="vuoto">
          </item-list-team-status>
        </ng-container>
      </td>
      <td *ngIf="isEdit">
        <p class="text-default-link manage" translate="table.MANAGE" (click)="emitOnManageObjective(objective.id)"></p>
      </td>
    </tr>
  </tbody>
</table>