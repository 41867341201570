import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PeopleAppraisalGuard } from '../shared/services/peopleAppraisal-guard.service';
import { NotificationsComponent } from '../core/notifications/notifications.component';
import { PeopleAppraisalComponent } from './peopleAppraisal.component';
import { PeopleAppraisalPersonDetailsComponent } from './personDetails/personDetails.component';
import { PersonDetailsGuard } from '../shared/services/person-details-guard.service';
import { OrganizeInterviewComponent } from './organizeInterview/organizeInterview.component';
import { CanDeactivateUpdateInterviewPeopleAppraisalGuardService } from './services/can-deactivate-update-interview-peopleAppraisal-guard.component';
import { ProfilePageComponent } from '../profilePage/profilePage.component';
import { TeamListComponent } from './teamList/teamList.component';
import { CreateUpdatePrivateNotePeopleAppraisalComponent } from './createUpdatePrivateNote/createUpdatePrivateNote.component';
import { SupportComponent } from '../core/support/support.component';
import { OnBoardingGuard } from '../shared/services/onboarding-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivateNoteGuard } from '../shared/services/private-note-guard.service';
import { OutcomesComponent } from './upwardFeedback/upwardFeedback.component';
import { DashboardGuard } from '../shared/services/dashboard-guard.service';
import { PAHomeComponent } from './home/home.component';
import { CppTeamComponent } from './cppTeam/cppTeam.component';
import { CppTeamDetailsComponent } from './cppTeamDetails/cppTeamDetails.component';
import { UpwardFeedbackListComponent } from './upwardFeedbackList/upwardFeedbackList.component';
import { UpwardFeedbackTeamListComponent } from './upwardFeedbackTeamList/upwardFeedbackTeamList.component';

const peopleAppraisalRoutes: Routes = [
  {
    path: '', component: PeopleAppraisalComponent, children: [
      { path: 'profile', component: ProfilePageComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'home', component: PAHomeComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'myTeam', component: TeamListComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'cppTeam/:roundId', component: CppTeamComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'cppDetails/:roundId/:userId', component: CppTeamDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // colloqui
      { path: 'organizeInterview/:userId', component: OrganizeInterviewComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewPeopleAppraisalGuardService] },
      { path: 'organizeInterview/:userId/:interviewId', component: OrganizeInterviewComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateInterviewPeopleAppraisalGuardService] },
      // dettagli utente
      { path: 'personDetails/:userId/:perfYear/:perfYearCode', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      { path: 'personDetails/myNotes/:userId/:perfYear/:perfYearCode', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard, PrivateNoteGuard], canDeactivate: [PersonDetailsGuard] },

      { path: 'createUpdatePrivateNote', component: CreateUpdatePrivateNotePeopleAppraisalComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard, PrivateNoteGuard] },
      { path: 'createUpdatePrivateNote/:userId', component: CreateUpdatePrivateNotePeopleAppraisalComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard, PrivateNoteGuard] },
      { path: 'createUpdatePrivateNote/:userId/:noteId', component: CreateUpdatePrivateNotePeopleAppraisalComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard, PrivateNoteGuard] },
      // Dashboard
      { path: 'dashboard/:processYear/:processYearCode', component: DashboardComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard, DashboardGuard] },

      // { path: 'upwardFeedback', component: OutcomesComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'ufRoundList', component: UpwardFeedbackListComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'ufTeamList', component: UpwardFeedbackTeamListComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'upwardFeedback/:roundId', component: OutcomesComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'upwardFeedback/:roundId/:userId', component: OutcomesComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },

      { path: 'notifications', component: NotificationsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: 'support', component: SupportComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      { path: "", redirectTo: "home", pathMatch: "full" },
      // route feedback da tenere pro futuro
      // { path: 'personDetails/feedback/smartFeedback/:userId', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/smartFeedback/:userId/:perfCareYear', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/evidences/:userId', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/evidences/:userId/:perfCareYear', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/privateNotes/:userId', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/privateNotes/:userId/:perfCareYear', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/interview/:userId', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/interview/:userId/:perfCareYear', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/peerFeedback/:userId', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'personDetails/feedback/peerFeedback/:userId/:perfCareYear', component: PeopleAppraisalPersonDetailsComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [PersonDetailsGuard] },
      // { path: 'sendApplause', component: SendClapComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapPeopleAppraisalGuard, OnBoardingGuardService] },
      // { path: 'sendApplause/:userId', component: SendClapComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapPeopleAppraisalGuard, OnBoardingGuardService] },
      // { path: 'sendApplause/:userId/:clapId', component: SendClapComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard], canDeactivate: [CanDeactivateUpdateClapPeopleAppraisalGuard, OnBoardingGuardService] },
      // { path: 'createUpdateEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidence/:userId/:goalId', component: CreateUpdateEvidenceComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidenceOnEvidence/:userId', component: CreateUpdateEvidenceComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'createUpdateEvidenceOnEvidence/:userId/:evidenceId', component: CreateUpdateEvidenceComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'createUpdatePeerFeedback/:userId', component: CreateUpdatePeerFeedbackPeopleAppraisalComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'createUpdatePeerFeedback/:userId/:feedbackId', component: CreateUpdatePeerFeedbackPeopleAppraisalComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
      // { path: 'feedbackRequest/:year', component: PeerFeedbackRequestComponent, canActivate: [PeopleAppraisalGuard, OnBoardingGuard] },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(peopleAppraisalRoutes)
  ],
  providers: [
  ],
  exports: [RouterModule]
})
export class PeopleAppraisalRoutingModule { }
