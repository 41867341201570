<div class="arrow-top-container visible">
  <svg-icon class="small-icon-size" [applyClass]="true" src="assets/img/icons/arrow-top.svg"></svg-icon>
</div>
<div class="pop-up-container">
  <div class="pop-up-title">
    <p>{{title}}</p>
    <div>
      <svg-icon src="assets/img/icons/x.svg" class="cursor-pointer medium-size close-icon-pop-up" [applyClass]="true">
      </svg-icon>
    </div>
  </div>

  <div class="divisor-title"></div>

  <div class="first-subtitle-container">
    <p class="title">{{firstSubtitleTitle}}</p>
    <p class="description">{{firstSubtitleDescription}}</p>
  </div>
  <div class="second-subtitle-container">
    <p class="title">{{secondSubtitleTitle}}</p>
    <p class="description" [innerHTML]="secondSubtitleDescription"></p>
  </div>
</div>

<div class="arrow-bottom-container">
  <svg-icon src="assets/img/icons/arrow-bottom.svg" class="small-icon-size" [applyClass]="true"></svg-icon>
</div>