import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import jwtDecode from 'jwt-decode';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UrlService } from 'src/app/shared/services/url.service';
import { SenecaResponse } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';

@Component({
  selector: 'app-login-sso',
  templateUrl: './login-sso.component.html',
  styleUrls: ['./login-sso.component.scss']
})
export class LoginSsoComponent {
  development: boolean = false;
  staging: boolean = false;
  production: boolean = false;
  applicationData: any;

  constructor(private store: Store<fromApp.AppState>,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private urlService: UrlService,
    private deviceService: DeviceDetectorService,
    public translate: TranslateService) {

    this.store.select(fromApp.getGlobalApplicationData).subscribe((globalApplicationData) => {
      this.applicationData = globalApplicationData;
    });

    let ssortqpToken = this.activatedRoute.snapshot.queryParamMap.get('ssortkqp');
    if (ssortqpToken) {
      const ssortqp = decodeURIComponent(ssortqpToken);
      if (ssortqp) {

        const getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortqp);
        getTokenFromKeyPromise.then((token: string) => {
          if (token && token.length) {
            // Salvo il tiny token nel session storage
            // Recupero il token completo
            const getFullTokenPromise = this.getFullToken(token);
            getFullTokenPromise.then((fullToken: any) => {
              if (fullToken.error) {
                this.router.navigateByUrl('/403');
              } else if (fullToken.response) {
                const decodedJwt = jwtDecode(fullToken.response);
                sessionStorage.setItem("token", token);
                // Ho il token con i dati dell'utente, sono loggato (?)
                this.router.navigate(['/defaultPage']);
              }
            })
          } else {
            this.router.navigateByUrl('/403');
          }
        })
      }
    } else {

      const deviceType: string = this.deviceService.isMobile() && "P"
        || this.deviceService.isTablet() && "T"
        || this.deviceService.isDesktop() && "D"
        || "";
      const deviceInfo = this.deviceService.getDeviceInfo();
      const userAgent = deviceInfo && deviceInfo.userAgent;
      const ssoUrl: string = urlService.getSSOUrl(deviceType, userAgent);

      window.location.href = ssoUrl;

    }


    // // Come prima cosa controllo se mi è arrivato il codice "code" che viene appeso durante il ritorno dal sistema remoto di autenticazione OAuth2
    // const oauthCode = this.activatedRoute.snapshot.queryParamMap.get('code');
    // if (oauthCode) {
    //   this.store.dispatch(AuthActions.DoLogin({ oauthCode: oauthCode }));
    // }
    // else {
    //   // Devo indirizzare l'utente alla pagina di SSO, quindi chiamo il servizio che genera l'url
    //   this.authService.getSSOUrl()
    //     .subscribe((data: SenecaResponse<string>) => {
    //       if (data.error) {
    //         // this.toastr.error('Si è verificato un errore: ' + data.error);
    //       } else {
    //         window.location.href = data.response;
    //       }
    //     });
    // }
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string): any {
    return new Promise((resolve, reject) => {
      this.retrieveTokenAfterLogin(key).subscribe((senecaResponse: SenecaResponse<any>) => {
        if (senecaResponse.error) {
          reject(senecaResponse);
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err: any) => {
          reject(err);
        })
    })
  }

  getFullToken(key: string) {
    return new Promise((resolve, reject) => {
      this.getJWTToken(key).subscribe((senecaResponse: SenecaResponse<any>) => {
        resolve(senecaResponse);
      },
        (err: any) => {
          reject(err);
        })
    })
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(token: string): any {
    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-full-jwt-token', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<any>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }
}