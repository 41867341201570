/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import { createReducer, on, Action } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import jwt_decode from "jwt-decode";
import { JwtPayload, RequiredAuth, User } from '../../../../commonclasses';

export interface ProfileState {
  loggedUser: any;
  editedUser: any;
  isPeopleAppraisal: boolean;
  isSelfAssessment: boolean;
  /** Indica se l'utente è un admin comune */
  isAdmin: boolean;
  /** Indica se l'utente è un admin CPP. Nota: le due auth da admin sono separate tra loro */
  isCppAdmin: boolean;
  isStaff: boolean;
  isOperation: boolean;
  isSti: boolean;
  isChief: boolean;
  peerFeedbackAllowed: boolean;
  langs: any[];
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  isPeopleAppraisal: false,
  isSelfAssessment: false,
  isAdmin: false,
  isCppAdmin: false,
  isStaff: false,
  isOperation: false,
  isSti: false,
  isChief: false,
  peerFeedbackAllowed: false,
  langs: []
};

export const createProfileReducer = createReducer(
  initialState,
  on(ProfileActions.UpdateUser, (state, action) => {
    const oldUserInfo: User = state.loggedUser;
    const updatedUser: User = {
      ...oldUserInfo,
      ...action.loggedUser
    };
    const user = updatedUser;
    return {
      ...state,
      loggedUser: user,
      editedUser: null
    };
  }),
  on(ProfileActions.CancelLoggedUser, (state, action) => {
    // Cancello le informazioni sull'utente loggato
    return {
      ...state,
      loggedUser: null
    };
  }),
  on(ProfileActions.SaveAvailableLangs, (state, action) => {
    // Salva le lingue disponibili a sistema recuperate tramite servizi
    return {
      ...state,
      langs: action.payload
    };
  }),
  on(ProfileActions.UpdateUserOptions, (state, action) => {
    let loggedUserCopy = JSON.parse(JSON.stringify(state.loggedUser));
    loggedUserCopy.user.userOptions = action
    return {
      ...state,
      loggedUser: loggedUserCopy
    };
  }),
  on(ProfileActions.DecodeToken, (state, action) => {
    const loggedUser: JwtPayload = jwt_decode(action.payload);
    let isPeopleAppraisal: boolean = false;
    let isSelfAssessment: boolean = false;
    let isAdmin: boolean = false;
    let isCppAdmin: boolean = false;
    let isStaff: boolean = false;
    let isSti: boolean = false;
    let isOperation: boolean = false;
    let peerFeedbackAllowed: boolean = false;
    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
      for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
        if (loggedUser.auths[i].includes(RequiredAuth.PERFORMANCEMANAGEMENTALLOY_MANAGER_COMMON)) {
          isPeopleAppraisal = true;
        } else if (loggedUser.auths[i].includes(RequiredAuth.PERFORMANCEMANAGEMENTALLOY_SUBORDINATE)) {
          isSelfAssessment = true;
        } else if (loggedUser.auths[i] === RequiredAuth.PERFORMANCEMANAGEMENTALLOY_ADMIN) {
          isAdmin = true;
        } else if (loggedUser.auths[i] === RequiredAuth.PERFORMANCEMANAGEMENTALLOY_CPP_ADMIN) {
          isCppAdmin = true;
        }
      }
    }
    return {
      ...state,
      isPeopleAppraisal: isPeopleAppraisal,
      isSelfAssessment: isSelfAssessment,
      isAdmin: isAdmin,
      isCppAdmin: isCppAdmin,
      isStaff: isStaff,
      isSti: isSti,
      isOperation: isOperation,
      peerFeedbackAllowed: peerFeedbackAllowed,
      loggedUser: {
        ...state.loggedUser,
        ...loggedUser
      }
    };
  }),
  on(ProfileActions.SetIsChief, (state, action) => {
    // Distingue i profili self assessment dei dirigenti
    return {
      ...state,
      isChief: action.payload
    };
  }),
)

export function profileReducer(state: ProfileState | undefined, action: Action) {
  return createProfileReducer(state, action);
}

export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getIsPeopleAppraisal = (state: ProfileState) => state.isPeopleAppraisal;
export const getIsSelfAssessment = (state: ProfileState) => state.isSelfAssessment;
export const getIsAdmin = (state: ProfileState) => state.isAdmin;
export const getIsCppAdmin = (state: ProfileState) => state.isCppAdmin;
export const getIsStaff = (state: ProfileState) => state.isStaff;
export const getIsOperation = (state: ProfileState) => state.isOperation;
export const getIsSti = (state: ProfileState) => state.isSti;
export const getIsChief = (state: ProfileState) => state.isChief;
export const getAvailableLangs = (state: ProfileState) => state.langs;
export const getPeerFeedbackAllowed = (state: ProfileState) => state.peerFeedbackAllowed;