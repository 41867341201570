import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'badge-notifications',
  templateUrl: 'badge-notifications.component.html',
  styleUrls: ['./badge-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeNotificationsComponent implements OnInit {
  @Input() type: string = ''; // active, disabled
  @Input() badgeNumber: number = 0;

  constructor() { }

  ngOnInit() { }
}