<div class="main-container">
  <!-- Peerfeedback -->
  <ng-container *ngIf="object">
    <div class="rectangle-container">
      <!-- Card-->
      <div class="card-container">
        <!-- response del peerfeedback -->

        <!-- [title]="userIdSelected == object.senderUserId ? ('feedbackSection.peerFeedback.PEER_FEEDBACK_OF' | translate) + '<b> ' + object.senderUser?.surname + ' '+ object.senderUser?.forename
          :('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB' | translate) + '<b> ' + object.recipientUser?.surname + ' '+ object.recipientUser?.forename" -->
        <ng-container *ngIf="object.comment">

          <!-- Peer feedback -->
          <card-feedback-header [icon]="responseIcon" [type]="type" [creationDate]="commentDateText" [hasAvatar]="true"
            [avatarRight]="!isPosted" [user]="object.intermediateUser" [title]="responseTitle">
          </card-feedback-header>

          <!-- Linea -->
          <div class=" divisor-line">
          </div>

          <div class="information-container">
            <div class="information-text-container">
              <!-- Text -->
              <div class="margin-bottom-20">
                <p class="information-title" translate="feedbackSection.peerFeedback.OSSERVATION"></p>
                <p class="information-desc" *ngIf="!showMore">{{object.comment | truncate: [300, '...']}}</p>
                <p class="information-desc" *ngIf="showMore">{{object.comment}}</p>
                <ng-container *ngIf="object.comment.length > 300 && !showMore">
                  <div class="show-peer-feedback" (click)="showMoreText()">
                    <p translate="feedbackSection.peerFeedback.SHOW_MORE_PEER_FEEDBACK"></p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- richiesta del peerfeedback -->
        <ng-container>
          <!-- Peer feedback -->
          <card-feedback-header [icon]="requestIcon" [title]="requestTitle" [type]="type"
            [creationDate]="creationDateText" [hasAvatar]="true" [avatarRight]="isPosted" [user]="object.senderUser">
          </card-feedback-header>

          <!-- Linea -->
          <div class="divisor-line"></div>
          <!-- Card profile -->
          <div class="information-container">
            <div class="information-text-container">
              <!-- Titolo e descrizione -->
              <ng-container *ngFor="let item of items; let last = last;">
                <ng-container *ngIf="item && item.text">
                  <div class="margin-bottom-20" [ngClass]="{'last': last}">
                    <p class="information-title">{{item.title}}</p>
                    <p class="information-desc">{{item.text}}</p>
                  </div>
                </ng-container>
              </ng-container>
              <!-- Incona e testo -->
              <ng-container *ngIf="!(isPosted || object.comment) && !isPeopleAppraisal">
                <div class="send-peer-feedback" (click)="emitOnFeedbackSend($event)">
                  <svg-icon src="assets/img/icons/message-circle.svg" class="small-size" [applyClass]="true"></svg-icon>
                  <p translate="feedbackSection.peerFeedback.SEND_PEER_FEEDBACK"></p>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
</div>