import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { PerfCarePeerFeedback } from 'src/commonclasses';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription } from 'rxjs';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as moment from 'moment';


@Component({
  selector: 'app-peopleAppraisal-peerFeedbackRequest',
  templateUrl: './peerFeedbackRequest.component.html',
  styleUrls: ['./peerFeedbackRequest.component.scss']
})
export class PeerFeedbackRequestComponent implements OnInit, OnDestroy {
  getPersonDetails$: any;
  personDetails$!: Subscription;
  personDetails: any;
  isLoadingPersonDetails = false;
  isSelfAssessment: boolean = false;
  userId: string = '';
  runningYear$: any;
  loggedUser$: any;
  runningYear: any = {};
  isPeerFeedback: boolean = false;
  isLoadingPeerFeedback: boolean = false;
  peerFeedbackList: PerfCarePeerFeedback[] = [];
  peerFeedbackPlaceholder: string = '';
  peerFeedbackToDelete: string = '';
  getPeerFeedbackList$: Subscription = new Subscription;
  feedbackForRequestModal: any;
  loggedUser: any;
  isOperations: boolean = false;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private modalService: ModalService,
    private peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.getPeerFeedbackList();
      }
    });

    this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      moment.locale(applicationLang);
    })

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    })
    if (window.location.href.toLowerCase().indexOf('operations') > -1) {
      this.isOperations = true;
    }
  }

  ngOnInit() {
  }



  // Peer feedback
  getPeerFeedbackList() {
    this.isLoadingPeerFeedback = true;
    this.peerFeedbackList = [];
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }

    this.getPeerFeedbackList$ = this.peopleAppraisalService.listExternalPeerFeedback(this.runningYear.year.toString(), true, false)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.peerFeedbackList = [];
          if (data.response && data.response.length) {
            for (let i = 0; i < data.response.length; i++) {
              this.peerFeedbackList.push({
                ...data.response[i],
                formattedCreationDate: moment(data.response[i].creationDate).fromNow()
              });
            }
          }
        }
        this.isLoadingPeerFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPeerFeedback = false;
        })
  }

  openShowFeedbackRequest(feedback: any) {
    this.feedbackForRequestModal = feedback;
    this.modalService.open('modalShowRequest')
  }

  closeShowFeedbackRequest() {
    this.modalService.close('modalShowRequest')
    this.feedbackForRequestModal = null;
  }

  goToPeerFeedbackAnswer(feedbackId: string) {
    this.redirectService.goToCreatePeerFeedbackPeopleAppraisal(this.userId, feedbackId);
  }

  ngOnDestroy() {
    if (this.getPeerFeedbackList$) {
      this.getPeerFeedbackList$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
  }


}