<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon [src]="'assets/img/icons/clipboard.svg'" class="small-size gray-gray2" [applyClass]="true">
        </svg-icon>
        <p>{{('privateNotes.TITLE') | translate}}</p>
      </div>
      <div class="period-time">
        <p>{{note.formattedCreationDate}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <avatar-img [user]="loggedUser" size="medium"></avatar-img>
    <div class="information-text-container">
      <div class="specific-information">
        <p
          [innerHTML]="('privateNotes.INSERT_BY' | translate) + '<b>' + loggedUser.forename + ' ' + loggedUser.surname + '</b>'">
        </p>
      </div>
      <p class="information-text">
        <span class="bold-text italic-text label" translate="privateNotes.WHY"></span>
        <span [innerHTML]="note.motivation"></span>
      </p>
      <p class="information-text">
        <span [innerHTML]="note.comment"></span>
      </p>

      <div class="action-footer">
        <ng-container *ngIf="!isHistory">
          <p class="text-default-link primary-text-color cursor-pointer" translate="generic.EDIT"
            (click)="emitOnEditNote(note.noteId)"></p>
          <p class="text-default-link primary-text-color cursor-pointer" translate="generic.DELETE"
            (click)="emitOnDeleteNote(note.noteId)">
          </p>
        </ng-container>
      </div>
    </div>
  </div>
</div>