/*
*    Servizio che gestisce i vari redirect
*/

import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { JwtPayload } from "../../../commonclasses";
import { Router } from "@angular/router";

@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean = false;
  loggedUser: any;
  isPeopleAppraisal: boolean = false;
  isSelfAssessment: boolean = false;
  isAdmin: boolean = false;
  combinedSelectes$: Subscription;
  isSti: boolean = false;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    const isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    const isPeopleAppraisal$: Observable<boolean> = this.store.select(fromApp.getIsPeopleAppraisal)
    const isSelfAssessment$: Observable<boolean> = this.store.select(fromApp.getIsSelfAssessment);
    const isAdmin$: Observable<boolean> = this.store.select(fromApp.getIsAdmin);
    const isSti$: Observable<any> = this.store.select(fromApp.getIsSti);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelectes$ = combineLatest([isMainMenuSidebarOpened$, loggedUser$, isPeopleAppraisal$, isSelfAssessment$, isAdmin$, isSti$])
      .subscribe(
        ([isMainMenuSidebarOpened, loggedUser, isPeopleAppraisal, isSelfAssessment, isAdmin, isSti]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
            this.isPeopleAppraisal = isPeopleAppraisal;
            this.isSelfAssessment = isSelfAssessment;
            this.isAdmin = isAdmin;
            this.isSti = isSti;
            this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
          }
        });
  }

  // Verifica se sono in una particolare pagina
  isThisCurrentPage(page: string) {
    if (page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }
    return false;
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(CoreActions.CloseMainMenuSidebar());
  }

  // Redirect alla home page dell'utente loggato
  goToHome(fromHeader?: boolean) {
    if (fromHeader) {
      // riporta alla home del modulo in cui sono, per risolvere problemi di redirect
      // per i profili con più auth
      if (window.location.href.indexOf('/admin/') >= 0) {
        this.router.navigate(['/admin/home']);
      } else {
        this.router.navigate(['/homepage']);
      }
    } else {
      if (this.isPeopleAppraisal || this.isSelfAssessment) {
        this.router.navigate(['/homepage']);
      } else if (this.isAdmin) {
        this.router.navigate(['/admin/home']);
      }
    }
  }

  // Vai al feedback section
  goToPersonDetailsFeedback(userId: string, perfYear?: number | string) {
    this.router.navigate(['/peopleAppraisal/personDetails/feedback/peerFeedback/' + userId + (perfYear ? ('/' + perfYear) : '')]);
  }

  goToPersonDetailsFeedbackSelfAssessment(userId: string, perfYear?: number) {
    this.router.navigate(['/selfAssessment/personDetails/feedback/peerFeedback/' + userId + (perfYear ? ('/' + perfYear) : '')]);
  }

  goToPrivateNotes(userId: string, perfYear: { year: number, yearCode: string }) {
    this.router.navigate(['/selfAssessment/personDetails/myNotes/' + userId + (perfYear ? ('/' + perfYear.year + '/' + perfYear.yearCode) : '')]);
  }

  // Vai alla pagine crea/aggiorna applauso
  goToSendClapPageSelfAssessment(userId?: string, id?: string) {
    if (userId) {
      if (id) {
        this.router.navigate(['/selfAssessment/sendApplause/' + userId + '/' + id]);
      } else {
        this.router.navigate(['/selfAssessment/sendApplause/' + userId]);
      }
    } else {
      this.router.navigate(['/selfAssessment/sendApplause']);
    }
  }

  goToFeedbackInterview(userId: string) {
    this.router.navigate(['/selfAssessment/personDetails/feedback/interview/' + userId]);
  }

  // Vai alla pagine crea/aggiorna applauso
  goToSendClapPagePeopleAppraisal(userId?: string, id?: string) {
    if (userId) {
      if (id) {
        this.router.navigate(['/peopleAppraisal/sendApplause/' + userId + '/' + id]);
      } else {
        this.router.navigate(['/peopleAppraisal/sendApplause/' + userId]);
      }
    } else {
      this.router.navigate(['/peopleAppraisal/sendApplause']);
    }
  }

  goToCreatePrivateNoteForPeopleAppraisal(userId?: string, noteId?: string) {
    if (userId) {
      if (noteId) {
        this.router.navigate(['/peopleAppraisal/createUpdatePrivateNote/' + userId + '/' + noteId]);
      } else {
        this.router.navigate(['/peopleAppraisal/createUpdatePrivateNote/' + userId]);
      }
    } else {
      this.router.navigate(['/peopleAppraisal/createUpdatePrivateNote']);
    }
  }

  goToCreatePrivateNote(noteId?: string) {
    if (noteId) {
      this.router.navigate(['/selfAssessment/createUpdatePrivateNote/', noteId]);
    } else {
      this.router.navigate(['/selfAssessment/createUpdatePrivateNote/']);
    }
  }

  // Vai alle notifiche
  goToNotifications() {
    if (window.location.href.includes('/admin')) {
      this.router.navigate(['/admin/notifications']);
    } else if (this.isPeopleAppraisal) {
      this.router.navigate(['/peopleAppraisal/notifications']);
    } else {
      this.router.navigate(['/selfAssessment/notifications']);
    }
  }

  // Porta alla pagina di supporto
  goToSupport() {
    if (window.location.href.indexOf('/peopleAppraisal/') >= 0) {
      this.router.navigate(['/peopleAppraisal/support']);
    } else {
      this.router.navigate(['/selfAssessment/support']);
    }
  }

  // Apre l'home page con il tutorial
  openHomePageTutorial(updateOnboardingUserAck?: boolean) {
    sessionStorage.setItem('openHomeTutorial', 'true');
    if (updateOnboardingUserAck) {
      sessionStorage.setItem('updateOnboardingUserAck', 'true');
    }
    if (window.location.href.indexOf('/peopleAppraisal/') >= 0) {
      this.router.navigate(['/peopleAppraisal/homepage']);
    } else if (window.location.href.indexOf('/selfAssessment/') >= 0) {
      this.router.navigate(['/selfAssessment/home']);
    } else if (window.location.href.indexOf('/admin/') >= 0) {
      this.router.navigate(['/admin/home']);
    } else {
      if (this.isAdmin) {
        this.router.navigate(['/admin/home']);
      } else if (this.isPeopleAppraisal) {
        this.router.navigate(['/peopleAppraisal/homepage']);
      } else if (this.isPeopleAppraisal) {
        this.router.navigate(['/peopleAppraisal/homepage']);
      } else if (this.isSelfAssessment) {
        this.router.navigate(['/selfAssessment/home']);
      }
    }
  }

  // Vai alla pagina personDetails obiettivi
  goToPersonDetails(userId?: string, perfYear?: number | string) {
    if (userId) {
      this.router.navigate(['/peopleAppraisal/personDetails/' + userId + (perfYear ? ('/' + perfYear) : '')]);
    } else {
      this.router.navigate(['/peopleAppraisal/personDetails' + (perfYear ? ('/' + perfYear) : '')]);
    }
  }

  // Vai alla pagina personDetails piano di sviluppo
  goToPersonDetailsDevelopmentPlan(userId: string, perfYear?: number) {
    this.router.navigate(['/peopleAppraisal/personDetails/developmentPlan/' + userId + (perfYear ? ('/' + perfYear) : '')]);
  }

  // Vai alla pagina personDetails selfAssessmente
  goToPersonDetailsSelfAssessment(userId?: string, perfYear?: any) {
    this.router.navigate(['/selfAssessment/personDetails/' + userId + '/' + perfYear.year + '/' + (perfYear.yearCode || perfYear.code)]);
  }
  goToPersonDetailsPeopleAppraisal(userId?: string, perfYear?: any) {
    this.router.navigate(['/peopleAppraisal/personDetails/' + userId + '/' + perfYear.year + '/' + (perfYear.yearCode || perfYear.code)]);
  }

  goToPersonDetailsDevelopmentPlanSelfAssessment(userId: string, perfYear?: number) {
    this.router.navigate(['/selfAssessment/personDetails/developmentPlan/' + userId + (perfYear ? ('/' + perfYear) : '')]);
  }

  goToCreatePeerFeedbackPeopleAppraisal(userId: string, feedbackId: string, isNoSelfAssessmentPeopleAppraisal?: boolean) {
    if (isNoSelfAssessmentPeopleAppraisal) {
      this.router.navigate(['/peopleAppraisal/createUpdatePeerFeedback/ext/' + userId + '/' + feedbackId]);
    } else {
      this.router.navigate(['/peopleAppraisal/createUpdatePeerFeedback/' + userId + '/' + feedbackId]);
    }
  }

  // Peer Feedback selfAssessmenti
  goToCreatePeerFeedback(userId?: string, feedbackId?: string) {
    if (userId) {
      if (feedbackId) {
        this.router.navigate(['/selfAssessment/createUpdatePeerFeedback/' + userId + '/' + feedbackId]);
      } else {
        this.router.navigate(['/selfAssessment/createUpdatePeerFeedback/' + userId]);
      }
    } else {
      this.router.navigate(['/selfAssessment/createUpdatePeerFeedback']);
    }
  }
  goToMidTermSelfAssessment() {
    this.router.navigate(['/selfAssessment/addMidTermReview']);
  }

  goToMidTermPeopleAppraisal(userId: string) {
    this.router.navigate(['/peopleAppraisal/addMidTermReview/' + userId]);
  }

  // Tora indietro history browser
  goBackBrowser() {
    window.history.back();
  }

  // E' nella pagina passata
  isInThisPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // Pagina di errore generico
  goToErrorPage(errorCode?: string) {
    if (errorCode) {
      this.router.navigate(['/errorPage/' + errorCode]);
    } else {
      this.router.navigate(['/errorPage']);
    }
  }

  goToNoValidProcess() {
    this.router.navigate(['/noValidProcess']);
  }

  // Vai alla survey cpp
  goToUserCpp(roundId?: string) {
    this.router.navigate(['/selfAssessment/cppSurvey/' + roundId]);
  }

  goToUpwardFeedbackSelfAssessment(roundId: string, managerId: string) {
    this.router.navigate(['/selfAssessment/upwardFeedback/', roundId, managerId]);
  }

  goToUpwardFeedbackPeopleAppraisal(roundId: string, userId?: string) {
    this.router.navigate(['/peopleAppraisal/upwardFeedback/', roundId, userId || '']);
  }

  goToUpwardFeedbackListPeopleAppraisal() {
    this.router.navigate(['/peopleAppraisal/ufRoundList']);
  }

  goToUpwardFeedbackTeamListPeopleAppraisal() {
    this.router.navigate(['/peopleAppraisal/ufTeamList']);
  }

  goToUpwardFeedbackSubordinateList() {
    this.router.navigate(['/selfAssessment/ufEvaluationList']);
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.combinedSelectes$) {
      this.combinedSelectes$.unsubscribe();
    }
  }
}
