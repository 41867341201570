import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sidenav-informations',
  templateUrl: 'sidenav-informations.component.html',
  styleUrls: ['./sidenav-informations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavInformationsComponent implements OnInit {
  @Input() isBackButton: boolean = false;
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() bindValue: string = '';
  @Input() readonly: boolean = false;
  @Input() isLoadingData: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() maxlength: number = 0;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onBackFunction: EventEmitter<any> = new EventEmitter();
  currentYear: number = new Date().getFullYear();
  kalturaScript: string = '<script src="https://cdnapisec.kaltura.com/p/1936311/sp/193631100/embedIframeJs/uiconf_id/42700011/partner_id/1936311?autoembed=true&entry_id=1_pa20csqg&playerId=introKalturaVideo&cache_st=1545652499&width=733&height=322"></script>';

  constructor() { }

  ngOnInit() { }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  // Torna indietro
  goBack() {
    this.onBackFunction.emit();
  }
}