<div class="card-container">
  <div class="header-type-container">
    <div class="type-container">
      <div class="type-container-text-icon">
        <svg-icon [src]="object.iconTop" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
        <p>{{('feedbackType.' + object.type) | translate}}</p>
      </div>
      <div class="period-time">
        <p>{{object.formattedCreationDate}}</p>
        <svg-icon src="assets/img/icons/clock.svg" class="small-size gray-gray2" [applyClass]="true"></svg-icon>
      </div>
    </div>
    <item-list-team-status styleItem="border" [message]="object.statusObject.statusMessage"
      [type]="object.statusObject.status"></item-list-team-status>
  </div>
  <div class="divisor-line"></div>
  <div class="information-container">
    <avatar-img [user]="object.creationUser" size="medium"></avatar-img>
    <div class="information-text-container">
      <div class="specific-information">
        <p *ngIf="!object.isDevelopmentAction"
          [innerHTML]="('feedback.SENDED_FROM' | translate) +  '<b>' +object.user.forename + ' ' + object.user.surname + '</b>'">
        </p>
        <p *ngIf="!object.isDevelopmentAction"
          [innerHTML]="('feedback.SENDED_FROM' | translate) +  '<b>' +object.user.forename + ' ' + object.user.surname + '</b>'">
        </p>
      </div>
      <p class="information-text">
        <span class="bold-text italic-text" translate="developmentPlan.TITLE_CARD"></span>
        <span [innerHTML]="object.title"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text" translate="developmentPlan.TYPE_CARD"></span>
        <span [innerHTML]="object.typeText"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text" translate="developmentPlan.DESCRIPTION_CARD"></span>
        <span [innerHTML]="object.description"></span>
      </p>
      <p class="information-text">
        <span class="bold-text italic-text" translate="developmentPlan.TO_START_CARD"></span>
        <span [innerHTML]="object.toStartDate"></span>
      </p>
      <ng-container *ngIf="object.comment && object.comment.length">
        <!-- Commento approvazione manager -->
        <div class="comment-container" *ngFor="let comment of object.comment">
          <!-- Lato sinisto con avatar, nome-->
          <div class="comment-left">
            <!-- Leggi commento + dati manager -->
            <div class="text-content">
              <div class="first-container-text">
                <avatar-img [user]="comment.creationUser" size="small"></avatar-img>
                <p class="read-comment" (click)="emitOnReadComment(comment)">
                  {{'goalSetting.READ_COMMENT' | translate }}
                </p>
              </div>
              <div class="second-container-text">
                <p>{{'goalSetting.BY' | translate }}</p>
                <p>
                  {{ comment.creationUser.forename && comment.creationUser.forename[0] + '. ' +
                  comment.creationUser.surname
                  }}
                </p>
              </div>
            </div>
            <p class="date-text">{{ ('goalSetting.COMMENT_INSERTED' | translate) + ' ' +
              (comment.creationDate | date:
              'dd/MM/YYYY') }}</p>
          </div>
          <!-- Lato destro con data e azione sul commento-->
          <div class="comment-right" *ngIf="comment.creationUser.userId == loggedUserId">
            <p class="action-text" (click)="emitOnDeleteComment(object, comment.commentId)">
              {{'generic.DELETE' |
              translate}}</p>
            <p class="action-text" (click)="emitOnEditComment(object, comment)">{{'generic.EDIT' |
              translate}}
            </p>
          </div>
        </div>
      </ng-container>

      <div class="action-footer">
        <div class="feedback-actions">
          <ng-container *ngIf="object.isDevelopmentAction">
            <alloy-button (onButtonClicked)="insertComment(object)"
              label="{{'developmentPlan.INSERT_COMMENT' | translate}}" type="primary link" size="medium"
              iconLeft="assets/img/icons/message-square.svg"></alloy-button>
            <!-- Se storico -->
            <ng-container *ngIf="!isHistory">
              <alloy-button *ngIf="isOnlyEdit" (onButtonClicked)="emitOnEditAction(object)"
                label="{{'generic.EDIT' | translate}}" type="primary link" size="medium"
                iconLeft="assets/img/icons/edit.svg"></alloy-button>

              <alloy-button *ngIf="isOnlyChangeStatus" (onButtonClicked)="updateStatus(object)"
                label="{{'developmentPlan.UPDATE_STATUS' | translate}}" type="primary link" size="medium"
                iconLeft="assets/img/icons/refresh-cw.svg"></alloy-button>

              <!-- Gestisci -->
              <ng-container *ngIf="actionMenuObject && actionMenuObject.length">
                <div class="manage-action-container">
                  <alloy-button (onButtonClicked)="openActionManageMenu($event)"
                    label="{{'generic.MANAGE' | translate}}" type="primary link" size="medium"
                    iconLeft="assets/img/icons/more-horizontal.svg"></alloy-button>
                  <div class="manage-action-menu-wrapper" *ngIf="actionMenuOpened" (clickOutside)="closeActionMenu()">
                    <alloy-dropdown-item *ngFor="let item of actionMenuObject" [item]="item"
                      (click)="emitOnActionOptionClicked(item, object)">
                    </alloy-dropdown-item>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>