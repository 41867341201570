<div class="tag-wrapper" (click)="toggleMenu()" [ngClass]="{'completed': isComplete}" #tagMenu>
  <div *ngIf="!leftIcon" class="tag-status"></div>
  <svg-icon *ngIf="leftIcon" class="small-size left-icon" [src]="leftIcon" [applyClass]="true"></svg-icon>
  <p class="tag-title">{{ title }}</p>
  <svg-icon #tagMenuButton src="/assets/img/icons/more-vertical.svg" class="small-size right-icon" [applyClass]="true">
  </svg-icon>
  <ng-container *ngIf="isMenuOpen">
    <div class="menu-container">
      <alloy-dropdown-item *ngFor="let item of menuObject" [item]="item" (click)="emitOnOptionClicked(item)">
      </alloy-dropdown-item>
    </div>
  </ng-container>
</div>