<div class="page-content-wrapper">
    <ng-container *ngIf="!isLoadingUser && !isLoadingRoundList && loggedUser">
        <header-dossier [isUpwardFeedbackHeader]="true" [isAdminHeader]="false" [user]="loggedUser"
            [selectedRound]="selectedRound" [roundList]="roundList" (onSelectedRound)="changeRound($event)">
        </header-dossier>


        <div class="main-content-wrapper admin">
            <div class="table-container">
                <div class="header-section">
                    <p class="section-title" translate="upwardFeedback.peopleAppraisal.TEAM_UPF"></p>
                    <p class="section-subtitle" translate="upwardFeedback.peopleAppraisal.TEAM_UPF_SUB"></p>
                </div>


                <!-- contenitore tabella -->
                <ng-container *ngIf="!isLoadingTeamData && teamData && teamData.length">
                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.PERSON' | translate)}}">
                                    </th>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.SENT' | translate)}}">
                                    </th>
                                    <th class="center"
                                        translate="{{ ('upwardFeedback.peopleAppraisal.table.STATUS' | translate)}}">
                                    </th>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.ACTION' | translate)}}">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let userData of teamData">
                                    <td>
                                        <div class="table-avatar-container">
                                            <avatar-img [user]="userData.user" size="small"></avatar-img>
                                            <p>{{ userData.user.surname + ' ' + userData.user.forename }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {{ userData.dateShared ? (userData.dateShared | date: 'dd/MM/YYYY') : "--" }}
                                    </td>
                                    <td>
                                        <div class="status-evaluation">
                                            <item-list-team-status styleItem="fill" [type]="userData.statusType"
                                                [message]="userData.statusMessage">
                                            </item-list-team-status>
                                        </div>
                                    </td>
                                    <td>
                                        <p (click)="goToResults(userData.user.userId)" class="link"
                                            [ngClass]="{'disabled': userData.actionDisabled}">
                                            {{ userData.actionText}}
                                        </p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isLoadingTeamData && (!teamData || !teamData.length)">
                    <div class="admin-no-data-wrapper">
                        <p translate="generic.NO_DATA_FOUND"></p>
                    </div>
                </ng-container>
                <!-- loader -->
                <ng-container *ngIf="isLoadingTeamData">
                    <div class="loader-container">
                        <div class="loader spinner-medium"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="isLoadingUser || isLoadingRoundList">
        <div class="loader spinner-big"></div>
    </ng-container>
</div>