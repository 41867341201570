import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'calibration-level',
  templateUrl: 'calibration-level.component.html',
  styleUrls: ['./calibration-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalibrationLevelComponent implements OnInit {
  @Input() year: any;
  @Input() description: string | null = null;
  @Input() calibrationLevelValue: string | null = null;
  @Input() calibrationLevelObservations: string | null = null;
  @Input() payout: any = null;
  @Input() sti: number = 0;
  @Input() isComplete: boolean = false;
  @Input() canConfirm: boolean = false;
  @Input() dateEnd: any;

  @Output() onReadObservations: EventEmitter<any> = new EventEmitter<any>();
  @Output() onConfirmEvaluation: EventEmitter<any> = new EventEmitter<any>();


  phaseDescr: string = '';

  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.dateEnd) {
      this.phaseDescr = this.translate.instant('calibration.post.PHASE_ENDED') + ' <b>' + this.dateEnd + '</b>';
    }
  }

  showDivider() {
    return (this.calibrationLevelObservations && this.calibrationLevelObservations.length && this.payout && this.payout.id >= 0) || (this.payout && this.payout.id && this.sti >= 0) || (this.calibrationLevelObservations && this.calibrationLevelObservations.length && this.sti >= 0);
  }

  emitOnReadObservation(data: any) {
    this.onReadObservations.emit(data);
  }

  emitConfirmEvaluation(data: any) {
    this.onConfirmEvaluation.emit(data);
  }

}
