import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerformerEvaluatorUtils } from 'src/app/utils/performer-evaluator';

@Component({
  selector: 'performer-evaluator',
  templateUrl: 'performer-evaluator.component.html',
  styleUrls: ['./performer-evaluator.component.scss']
})
export class PerformerEvaluatorComponent implements OnInit {
  performers: any;
  currentPerformer: any;
  @Input() isHrRemarksVisible: boolean = false;

  // Eventuale valore del performer (verranno mostrati i dati del performer in base a tale valore)
  _performerValue?: number;
  @Input() set performerValue(value: number | undefined) {
    this._performerValue = value || 0;

    if (this.performers) {
      this.initComponent();
    }
  }

  // Eventuale chiave del performer da mostrare direttamente
  _performerKey?: string;
  @Input() set performerKey(value: string | undefined) {
    this._performerKey = value || '';

    if (this.performers) {
      this.initComponent();
    }
  }

  @Input() sentToHr?: boolean = false; // Scheda già inviata all'hr
  @Input() enableHrRemarks?: boolean = false; // Mostra osservazioni HR
  @Input() hrRemarks?: string = ''; // Osservazioni HR
  @Input() enablePerformerInfo?: boolean = false; // Abilita il pulsante "i" per vedere le informazioni sul performer corrente
  @Input() hasToSetVal?: boolean = false; // Non c'è ancora la valutazione (quindi compare il punto di domanda al posto del valore)
  @Input() hideValue: boolean = false; // Nasconde il valore numerico
  @Input() hideTitle: boolean = false; // Nasconde il titolo del profilo
  @Output() openPerformerModal: EventEmitter<string> = new EventEmitter<string>(); // Apre la modale per avere informazioni sui performer

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    const getPerformersPromise = PerformerEvaluatorUtils.getPerformers(this.translate, this.hasToSetVal);
    getPerformersPromise.then((res: any) => {
      this.performers = res;

      this.initComponent();
    })
  }

  // Inizializza i dati del componente
  initComponent() {
    this.currentPerformer = this.performers["LOW"];
    // Se mi è arrivata una chiave (_performerKey) significa che devo mostrare le informazioni attinenti ad una specifica chiave (significa che, ad es., sono nella modale che spiega i vari livelli)
    if (this._performerKey && this._performerKey.length) {
      this.currentPerformer = this.performers[this._performerKey];
    } else if (this._performerValue) {
      // Se mi è arrivato il numero, significa che devo mostrare l'oggetto corrispondente al valore
      for (const key in this.performers) {
        const currentPerformer = this.performers[key];
        if (this._performerValue >= currentPerformer.minValue && this._performerValue <= currentPerformer.maxValue) {
          this.currentPerformer = currentPerformer;
          break;
        }
      }
    } else if (this.hasToSetVal) {
      this.currentPerformer = this.performers["TODO"];
    }
  }

  // Apre la modale con la spiegazione dei performer
  emitOpenPerformerModal() {
    let keyToOpen = this.currentPerformer.key;
    if (keyToOpen === "TODO") {
      // Se sto aprendo l'info del placeholder (quindi quando nessuna valutazione è stata fatta) apro di default l'info della prima vallutazione
      keyToOpen = "LOW";
    }
    this.openPerformerModal.emit(keyToOpen);
  }

  // Apre le osservazioni HR
  toggleHrRemarks() {
    this.isHrRemarksVisible = !this.isHrRemarksVisible;
  }
}