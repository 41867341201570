<div class="page-content-wrapper">

  <div id="snackbarPersonDetails">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails">
    <header-dossier (onMenuButtonClicked)="changeButtonHeaderDossier($event)" [user]="loggedUser" [yearItems]="yearList"
      [selectedYear]="selectedYear" (onSelectedYear)="changeSelectedYear($event)"
      (onNewInterview)="createPrivateNote()">
    </header-dossier>

    <div class="main-content-wrapper">
      <!-- dati semestre H1 H2-->
      <ng-container *ngIf="!isNoteSection">
        <!-- Contenitore dati caricati -->
        <ng-container *ngIf="!isLoadingTab">
          <child-content *ngIf="currentStep">
            <stepper id="stepper" [ngClass]="{'central-stepper': isLeadershipTeam}" [statuses]="stepList" [dots]="5"
              [currentStatus]="currentStep" (stepClicked)="onStepClicked($event)"></stepper>

            <!-- 1 - Valutazione obiettivi di sviluppo -->
            <ng-container *ngIf="currentStep.id == 'objectiveEvaluation'">
              <!-- Placeholder caricamento-->
              <ng-container *ngIf="isLoadingToEvaluateGoals">
                <div class="page-header">
                  <div class="loader spinner-medium"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!isLoadingToEvaluateGoals">
                <!-- Se non ho obiettivi mostro placeholder -->
                <ng-container *ngIf="!toEvaluateList || !toEvaluateList.length">
                  <no-data [title]="('generic.NO_OBJECTIVE_TO_EVALUATE' | translate)"
                    [description]="('generic.NO_OBJECTIVE_TO_EVALUATE_DESCR' | translate)" [hideIcon]="true">
                  </no-data>
                </ng-container>
                <!-- se ho obiettivi -->
                <ng-container *ngIf="toEvaluateList && toEvaluateList.length">
                  <div class="page-header">
                    <div id="firstStepPageHeader">
                      <div class="title-container">
                        <p *ngIf="isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE_SPECIALIST' | translate)
                          }}</p>
                        <p *ngIf="!isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate) + ' ' +
                          selectedYear.code + ' ' + selectedYear.id}}
                        </p>
                        <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                          (click)="openHelpModal(currentStep.id)">
                        </svg-icon>
                        <svg-icon *ngIf="!isLoadingToEvaluateGoals" src="/assets/img/icons/flag.svg" class="title-icon"
                          [applyClass]="true" (click)="openPersonalObjectiveEvaluationTutorial()">
                        </svg-icon>
                      </div>
                      <p *ngIf="!isIT" class="description"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_SELF' | translate"></p>
                      <p *ngIf="isIT" class="description"
                        [innerHTML]="'stepper.OBJECTIVE_EVALUATION_DESCR_SELF_TECH' | translate"></p>
                    </div>
                  </div>

                  <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                  <ng-container *ngFor="let objective of toEvaluateList; let toEvaluateItemIndex = index;">
                    <ng-container *ngIf="!isIT">
                      <text-goal
                        [subtitleFooter]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.text" [evaluation]="objective.evaluation" [isEvaluation]="true"
                        (evaluationChanged)="onEvaluationChanged($event, objective)" [showMenu]="false"
                        [disabled]="isItemDisabled()" [id]="'toEvaluateItemIndex' + toEvaluateItemIndex">
                      </text-goal>
                    </ng-container>
                    <ng-container *ngIf="isIT">
                      <text-goal
                        [subtitleFooter]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.text" [evaluation]="objective.evaluation" [isEvaluation]="true"
                        (evaluationChanged)="onEvaluationChanged($event, objective)" [showMenu]="false"
                        [disabled]="isItemDisabled()" [id]="'toEvaluateItemIndex' + toEvaluateItemIndex"
                        [ITRating]="isIT" [ITRatingVote]="objective.evaluationVote"
                        (ITRatingVoteChanged)="onITRatingVoteChanged($event, objective)">
                      </text-goal>
                    </ng-container>

                  </ng-container>
                </ng-container>
              </ng-container>

              <div class="person-details-main-actions-banner selfAssessment" id="saveGoalToEvaluateBtn">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button [disabled]="true" type="black-border" size="medium"
                      label="{{'generic.BACK' | translate}}" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs
                      fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button [disabled]="true" type="black-border with-just-left-icon" size="medium" label=""
                      iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <ng-container>
                      <!-- Salva e continua (se ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="isOptionAvailable() && (toEvaluateList && toEvaluateList.length)"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveObjectiveEvaluation()">
                      </alloy-button>
                      <!-- Va avanti di step (se non ci sono obiettivi da valutare) -->
                      <alloy-button *ngIf="isOptionAvailable() && (!toEvaluateList || !toEvaluateList.length)"
                        [disabled]="isLoadingToEvaluateGoals" type="primary" size="medium"
                        label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                      </alloy-button>
                      <!-- Va avanti di step (tutto disabilitato) -->
                      <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                        size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                      </alloy-button>
                    </ng-container>
                  </div>
                </div>
              </div>

            </ng-container>


            <!-- 2 - Valutazione dei comportamenti organizzativi -->
            <ng-container *ngIf="currentStep?.id == 'behaviourEvaluation'">
              <div class="page-header" id="secondStepPageHeader">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                  <svg-icon *ngIf="!isLoadingBehaviours && !isLeadershipTeam" src="/assets/img/icons/flag.svg"
                    class="title-icon" [applyClass]="true" (click)="openOrganizationalBehaviorsTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!isLeadershipTeam" class="description"
                  [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR_SELF' | translate"></p>
                <p *ngIf="isLeadershipTeam" class="description" [innerHTML]="getLeadershipSubtitleDescr()"></p>

              </div>
              <ng-container *ngIf="!isLoadingBehaviours">

                <!-- Lista comportamenti leadership -->
                <ng-container *ngIf="isLeadershipTeam">
                  <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [competenceList]="competence" [id]="'competenceIndex' + competenceIndex"
                      [isLeadershipRating]="true" [disabled]="isItemDisabled()"
                      (onPlusMinusClicked)="leadershipBehaviorChanged($event)"
                      [isProcessBeforeH22023]="processIsBeforeH2023Leadership">
                    </competence-evaluation>
                  </ng-container>
                </ng-container>

                <!-- Lista comportamenti organizzativi -->
                <ng-container *ngIf="!isLeadershipTeam">
                  <!-- Esempio competenze -->
                  <ng-container *ngFor="let competence of competenceArray; let competenceIndex = index;">
                    <competence-evaluation [competenceList]="competence" [titlesRightSection]="titlesRightSection"
                      (infoClicked)="openCompetenceDictionaryModal($event)" [id]="'competenceIndex' + competenceIndex"
                      (ratingChanged)="behaviourRatingChanged($event)" [ratingScale]="competence.ratingScale"
                      [disabled]="isItemDisabled()">
                    </competence-evaluation>
                  </ng-container>

                </ng-container>
                <!-- Media finale -->
                <competence-average
                  *ngIf="!isLeadershipTeam && softSkillsFinalAvg && softSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="soft-skill-final-avg" [competenceAvg]="softSkillsFinalAvg"
                  [titlesRightSection]="titlesRightSection" [ratingScale]="softSkillsFinalAvg.ratingScale">
                </competence-average>

                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="behaviorComments && behaviorComments.trim().length" [isObbligatory]="false"
                  title="{{'generic.NOTES_COMMENTS' | translate}}" [disabled]="isItemDisabled()"
                  id="competenceEvaluationNotes">
                  <alloy-text-area [bindValue]="behaviorComments" [maxlength]="300"
                    (onModelChanged)="onManageBehaviorCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="isItemDisabled()">
                  </alloy-text-area>
                </input-container>
              </ng-container>

              <!-- Loader comportamenti -->
              <ng-container *ngIf="isLoadingBehaviours">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner selfAssessment">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <ng-container *ngIf="!isLeadershipTeam">
                      <alloy-button *ngIf="isOptionAvailable()" [disabled]="isLoadingBehaviours" type="primary"
                        size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveBehaviourEvaluation()">
                      </alloy-button>
                    </ng-container>
                    <ng-container *ngIf="isLeadershipTeam">
                      <alloy-button *ngIf="isOptionAvailable()" [disabled]="isInCompletedBehaviours" type="primary"
                        size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                        (onButtonClicked)="saveBehaviourEvaluation()">
                      </alloy-button>
                    </ng-container>
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingBehaviours" type="primary" size="medium"
                      label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="saveBehaviourEvaluation()">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>

            <!-- 3 - Valutazione competenze tecniche -->
            <ng-container *ngIf="currentStep?.id == 'techEvaluation'">
              <div class="page-header" id="thirdStepPageHeader">
                <div class="title-container">
                  <p *ngIf="!isIT" class="title" translate="stepper.TECH_SKILL_EVALUATION"></p>
                  <p *ngIf="isIT" class="title" translate="stepper.TECH_OBJECTIVES"></p>
                  <svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                    (click)="openHelpModal(currentStep.id)">
                  </svg-icon>
                  <svg-icon *ngIf="!isLoadingTechSkills" src="/assets/img/icons/flag.svg" class="title-icon"
                    [applyClass]="true" (click)="openTechSkillEvaluationTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!isIT" class="description"
                  [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR_SELF' | translate"></p>
                <p *ngIf="isIT" class="description" [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR' | translate"></p>
              </div>

              <!-- techSkill -->
              <ng-container *ngIf="!isLoadingTechSkills">

                <ng-container *ngFor="let techSkill of techSkillArray; let techSkillIndex = index;">
                  <competence-evaluation [competenceList]="techSkill" [titlesRightSection]="titlesRightSection"
                    [id]="'competenceIndex' + techSkillIndex" (infoClicked)="openCompetenceDictionaryModal($event)"
                    (ratingChanged)="onTechSkillRatingChanged($event)" [ratingScale]="techSkill.ratingScale"
                    [disabled]="isScheduleShared || isFinalStepCompleted || isHistory">
                  </competence-evaluation>
                </ng-container>

                <!-- Media finale -->
                <competence-average *ngIf="techSkillsFinalAvg && techSkillsFinalAvg.allBehavioursValuated"
                  [disabled]="true" id="tech-skill-final-avg" [competenceAvg]="techSkillsFinalAvg"
                  [titlesRightSection]="titlesRightSection" [ratingScale]="techSkillsFinalAvg.ratingScale">
                </competence-average>

                <input-container id="techEvaluationNotes" [numberCharsTextArea]="300" [isFromTextArea]="true"
                  [isWithoutInfoIcon]="true" [isValued]="techSkillComment && techSkillComment.trim().length"
                  [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                  [disabled]="isItemDisabled()">
                  <alloy-text-area [bindValue]="techSkillComment" [maxlength]="300"
                    (onModelChanged)="onTechSkillCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="isItemDisabled()">
                  </alloy-text-area>
                </input-container>
              </ng-container>
              <!-- loader competenze tecniche -->
              <ng-container *ngIf="isLoadingTechSkills">
                <div class="loader spinner-medium"></div>
              </ng-container>

              <div class="person-details-main-actions-banner selfAssessment">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button *ngIf="isOptionAvailable()" [disabled]="isLoadingTechSkills" type="primary"
                      size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                      (onButtonClicked)="saveTechSkills()">
                    </alloy-button>
                    <!-- Va avanti di step (tutto disabilitato) -->
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                      size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>

            <!-- 4 - Proponi obiettivi -->
            <ng-container *ngIf="currentStep?.id == 'proposeObjectives'">

              <div class="page-header white-bg">
                <div class="title-container" id="fourthStepPageHeader">
                  <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate && !isIT" class="title"
                    translate="stepper.PROPOSE_NEW_OBJECTIVES"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate && selectedYear.id == 2023 || isIT" class="title"
                    translate="stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES"></p>
                  <p *ngIf="yearsWithSpecialisticGoalsToEvaluate && selectedYear.id < 2023" class="title"
                    translate="stepper.DEFINE_NEW_OBJECTIVES"></p>
                  <svg-icon *ngIf="!isLoadingDefinedObjectives && !isLoadingUserGoalToDefine"
                    src="/assets/img/icons/flag.svg" class="title-icon" [applyClass]="true"
                    (click)="openSetGoalsTutorial()">
                  </svg-icon>
                </div>
                <p *ngIf="!isIT && !yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.PROPOSE_OBJECTIVES_DESCR' | translate"></p>
                <p *ngIf="isIT || yearsWithSpecialisticGoalsToEvaluate" class="description"
                  [innerHTML]="'stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES_DESCR' | translate"></p>
                <!-- Loader -->
                <ng-container *ngIf="isLoadingDefinedObjectives">
                  <div class="loader spinner-small"></div>
                </ng-container>

                <!-- Lista obiettivi -->
                <ng-container *ngIf="!isLoadingDefinedObjectives">
                  <ng-container *ngFor="let objective of newObjectiveList; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal
                        [subtitle]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.title" [canEdit]="isOptionAvailable()" [showMenu]="isOptionAvailable()"
                        (menuOptionClicked)="onMenuOptionClicked($event, objective)">
                      </text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isCreateObjective]="true" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{ (isIT?('defineObjectives.DEVELOPMENT_SPECIALIST_OBJECTIVE' | translate) : ('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate)) + ' ' + (i + 1)}}"
                        (deleteObjective)="onMenuOptionClicked('delete', objective)" [disabled]="isItemDisabled()">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'defineObjectives.INSERT_OBJECTIVE' | translate}}"
                          [readonly]="isItemDisabled()">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <!-- Aggiungi obiettivo -->
                  <ng-container *ngIf="isOptionAvailable()">
                    <alloy-button id="addGoalBtn" type="add-objective link" size="large"
                      label="{{'defineObjectives.ADD_OBJECTIVE' | translate}}" iconRight="assets/img/icons/plus.svg"
                      (onButtonClicked)="addNewObjective()"></alloy-button>
                  </ng-container>
                </ng-container>
              </div>

              <div class="person-details-main-actions-banner selfAssessment">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn">
                    <alloy-button *ngIf="isOptionAvailable()" id="saveGoalBtn"
                      [disabled]="isLoadingDefinedObjectives || !canSaveDefinedObjectives()" type="primary"
                      size="medium" label="{{'generic.SAVE_AND_CONTINUE' | translate}}"
                      (onButtonClicked)="saveDefinedObjective()">
                    </alloy-button>
                    <!-- Va avanti di step (tutto disabilitato) -->
                    <alloy-button *ngIf="isItemDisabled()" [disabled]="isLoadingToEvaluateGoals" type="primary"
                      size="medium" label="{{'generic.CONTINUE' | translate}}" (onButtonClicked)="goToNextStep()">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>

            <!-- 5 - Valutazione finale -->
            <ng-container *ngIf="currentStep?.id == 'finalEvaluation'">
              <ng-container
                *ngIf="!isLoadingFinalEvaluationData && !isLeadershipTeam && finalEvaluation && finalEvaluation.pasPerformer">
                <!-- con la valutazione e con la modale con le informazioni -->
                <ng-container *ngIf="finalEvaluation.pasPerformer.key">
                  <ng-container *ngIf="!isPostCalibration && !isFinalStepCompleted">
                    <performer-evaluator [performerKey]="finalEvaluation.pasPerformer.key" [enablePerformerInfo]="false"
                      [hideTitle]="true" (openPerformerModal)="openPerformerModal($event)" [sentToHr]="isScheduleShared"
                      [hideValue]="true" class="white-box-with-shadow">
                    </performer-evaluator>
                  </ng-container>

                  <ng-container *ngIf="isPostCalibration || isFinalStepCompleted">
                    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutAlign="space-between start"
                      fxLayoutAlign="start center" class="grafics-final-evaluation-container">
                      <!-- Riepilogo -->
                      <div class="performer-avaluator-container" [ngClass.gt-sm]="{'border-right': true}"
                        fxLayout="column">
                        <p class="text-title" translate="stepper.RIEPILOGUE"></p>
                        <performer-evaluator [performerValue]="managerFinalEvaluation.pasPerformer.value"
                          [enablePerformerInfo]="true" (openPerformerModal)="openPerformerModal($event)"
                          [sentToHr]="isScheduleShared" [hrRemarks]="managerFinalEvaluation.adminComment"
                          [enableHrRemarks]="false" [isHrRemarksVisible]="false"
                          [hideTitle]="managerFinalEvaluation.pasPerformer.value == 0">
                        </performer-evaluator>
                      </div>

                      <!-- Grafico dei progressi -->
                      <div class="progress-grafic-container full-width">
                        <p class="text-title margin-bottom40" translate="stepper.PROGRESS_GRAFIC"></p>

                        <div *ngIf="isIT" fxLayout="row" class="full-width margin-bottom24"
                          fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="generic.TECHNIC_OBJECTIVES"></p>
                          <div class="width-115 margin-right8" fxLayout="column">
                            <div class="first-star-row"
                              [ngStyle]="{'width': (115*finalEvaluation.goalCurrentAvg/5) + 'px'}"></div>
                            <div class="second-star-row"
                              [ngStyle]="{'width': (115*finalEvaluation.goalPastAvg/5) + 'px'}"></div>
                          </div>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{finalEvaluation.goalCurrentAvgLabel}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="finalEvaluation.goalCurrentStars" [hideLabel]="true"
                                [isRecapAverage]="true"></star-rating>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{finalEvaluation.goalPastAvgLabel}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="finalEvaluation.goalPastStars" [hideLabel]="true"
                                [isRecapAverage]="true"></star-rating>
                            </div>
                          </div>
                        </div>

                        <!-- Comportamenti organizzativi -->
                        <div fxLayout="row" class="full-width margin-bottom24" fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="generic.ORGANIZED_BEHAVIORS"></p>
                          <div class="width-115 margin-right8" fxLayout="column">
                            <div class="first-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.behaviorCurrentAvg/5) + 'px'}"></div>
                            <div class="second-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.behaviorPastAvg/5) + 'px'}"></div>
                          </div>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.behaviorCurrentAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.behaviorCurrentStars" [hideLabel]="true"
                                [isRecapAverage]="true">
                              </star-rating>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.behaviorPastAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.behaviorPastStars" [hideLabel]="true"
                                [isRecapAverage]="true">
                              </star-rating>
                            </div>
                          </div>
                        </div>

                        <!-- Competenze tecniche -->
                        <div *ngIf="!isIT" fxLayout="row" class="full-width margin-bottom24"
                          fxLayoutAlign="start center">
                          <p *ngIf="!yearsWithSpecialisticGoalsToEvaluate" class="text-desc margin-right16"
                            translate="dashboard.COMPETENCES"></p>
                          <p *ngIf="yearsWithSpecialisticGoalsToEvaluate" class="text-desc margin-right16"
                            translate="dashboard.SPECIALIST_COMPETENCES">
                          </p>
                          <div class="width-115 margin-right8" fxLayout="column">
                            <div class="first-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.competenceCurrentAvg/5) + 'px'}"></div>
                            <div class="second-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.competencePastAvg/5) + 'px'}"></div>
                          </div>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.competenceCurrentAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.competenceCurrentStars" [hideLabel]="true"
                                [isRecapAverage]="true">
                              </star-rating>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.competencePastAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.competencePastStars" [hideLabel]="true"
                                [isRecapAverage]="true">
                              </star-rating>
                            </div>
                          </div>
                        </div>

                        <!-- PAS (valutazione finale) -->
                        <div fxLayout="row" class="full-width margin-bottom24 overflow-hidden"
                          fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="stepper.FINAL_EVAL_PAS"></p>
                          <div class="width-115 margin-right8" fxLayout="column">
                            <div class="first-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.pasCurrentAvg/5) + 'px'}">
                            </div>
                            <div class="second-star-row"
                              [ngStyle]="{'width': (115*managerFinalEvaluation.pasPastAvg/5) + 'px'}">
                            </div>
                          </div>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.pasCurrentAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.pasCurrentStars" [hideLabel]="true"></star-rating>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start center">
                              <p class="star-value-text bold-text">{{managerFinalEvaluation.pasPastAvg}}</p>
                              <star-rating [customContainerClass]="'from-progress-grafic'" [disabled]="true"
                                [forceRating]="managerFinalEvaluation.pasPastStars" [hideLabel]="true"></star-rating>
                            </div>
                          </div>
                        </div>

                        <!-- Delta (rispetto al semestre precedente) -->
                        <div fxLayout="row" class="full-width margin-bottom24 overflow-hidden"
                          fxLayoutAlign="start center">
                          <p class="text-desc margin-right16" translate="stepper.FINAL_EVA_DELTA"></p>
                          <div class="margin-right8" fxLayout="column">
                            <div fxLayout="row">
                              <p class="star-value-text bold-text">{{finalEvaluation.deltaAvg}}</p>
                              <svg-icon src="assets/img/icons/star.svg" [applyClass]="true" class="star-icon-green">
                              </svg-icon>
                            </div>
                            <!-- <p class="star-value-text" translate="stepper.UP_PAS_VALUE"></p> -->
                          </div>
                        </div>

                        <!-- Footer -->
                        <div fxLayout="row" fxLayoutAlign="center center" class="legend-grafic full-width">
                          <div class="current-circle"></div>
                          <p class="text-desc margin-right50" [ngClass.xs]="'margin-right5'">{{selectedYear?.name}}</p>
                          <div class="past-circle"></div>
                          <p translate="stepper.PRECEDENT_SEMESTER" class="text-desc"></p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

                <!-- valutazione ancora da impostare e con la modale con le informazioni -->
                <ng-container *ngIf="!finalEvaluation.pasPerformer.key">
                  <performer-evaluator [hasToSetVal]="true" [enablePerformerInfo]="false" [hideTitle]="true"
                    (openPerformerModal)="openPerformerModal($event)" [hideValue]="true" class="white-box-with-shadow">
                  </performer-evaluator>
                </ng-container>
              </ng-container>

              <!-- Sezione da nascondere se step completo (post ri condivisione scheda manager)-->
              <!-- <ng-container *ngIf="true"> -->
              <!-- Recap fasi precedenti -->
              <!-- Placeholder obiettivi valutati -->
              <ng-container *ngIf="isLoadingFinalEvaluationData">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="stepper.OBJECTIVE_EVALUATION_TITLE"></p>
                  </div>
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </div>
              </ng-container>
              <!-- Recap valutazione obiettivi -->
              <ng-container *ngIf="!isLoadingFinalEvaluationData && !isLeadershipTeam">
                <ng-container *ngIf="finalEvaluation.evaluatedData && finalEvaluation.evaluatedData.length">
                  <!-- Recap valutazione obiettivi di sviluppo -->
                  <div class="page-header white-bg">
                    <div class="title-container">
                      <p *ngIf="isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE_SPECIALIST' | translate) }}
                      </p>
                      <p *ngIf="!isIT" class="title"> {{ ('stepper.OBJECTIVE_EVALUATION_TITLE' | translate) + ' ' +
                        selectedYear.code
                        + ' ' + selectedYear.id}}
                      </p>
                    </div>

                    <!-- Componente nuovo obiettivi composti di solo titolo, con valutazione -->
                    <div class="objective-tag-container">
                      <!-- Placeholder nessun obiettivo -->
                      <ng-container *ngIf="!selectedObjective || finalEvaluation.emptyEvaluatedObjective">
                        <p class="placeholder margin" translate="defineObjectives.OBJECTIVE_NOT_EVALUATED"></p>
                      </ng-container>

                      <!-- recap obiettivi -->
                      <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                        <ng-container *ngFor="let objective of finalEvaluation.evaluatedData; let i = index">
                          <div class="objective-tag" (click)="selectObjectiveFinalEvaluation(objective)"
                            [ngClass]="{ 'selected': selectedObjective && selectedObjective.goalId && selectedObjective.goalId == objective.goalId }">
                            <p>{{ ('finalEvaluation.OBJECTIVE' | translate) + ' ' + ( i + 1 )}}</p>
                          </div>
                        </ng-container>

                      </ng-container>
                    </div>

                    <!-- Recap obiettivo selezionato -->
                    <ng-container *ngIf="selectedObjective && !finalEvaluation.emptyEvaluatedObjective">
                      <div class="objective-evaluation-content">
                        <!-- Obiettivo -->
                        <text-goal
                          [subtitle]="selectedObjective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + selectedObjective.creationUser?.forename + ' ' + selectedObjective.creationUser?.surname) : ''"
                          [title]="selectedObjective.text" [showMenu]="false"></text-goal>
                        <evaluation-rating *ngIf="isIT" [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                          [evaluationText]="selectedObjective.evaluation" [ratingIT]="selectedObjective.evaluationVote"
                          [isEditDisabled]="true" [isSelf]="true">
                        </evaluation-rating>
                        <!-- Valutazione obiettivo people appraisal -->
                        <ng-container *ngIf="!selectedObjective.editObjectiveEvaluation">
                          <ng-container *ngIf="!isIT">
                            <evaluation-rating [user]="loggedUser"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                              [evaluationText]="selectedObjective.evaluation || ('generic.EVALUATION_INCOMPLETE' | translate)"
                              [canEdit]="false">
                            </evaluation-rating>
                          </ng-container>
                        </ng-container>
                        <!--ng-container *ngIf="selectedObjective.editObjectiveEvaluation">
                          <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                            [isWithoutInfoIcon]="true"
                            [isValued]="selectedObjective.evaluation && selectedObjective.evaluation.trim().length"
                            [isObbligatory]="false" title="{{'textGoal.INSERT_LABEL' | translate}}">
                            <alloy-text-area [bindValue]="selectedObjective.evaluation" [maxlength]="300"
                              (onModelChanged)="onObjectiveEvaluationEdited($event)"
                              placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                            </alloy-text-area>
                          </input-container>
                        </ng-container-->

                        <!-- Valutazione obiettivo people-->
                        <ng-container
                          *ngIf="(isPostCalibration || isFinalStepCompleted) && selectedObjective.managerEvaluation">
                          <ng-container *ngIf="!isIT">
                            <evaluation-rating [user]="managerData"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + managerData.forename"
                              [evaluationText]="selectedObjective.managerEvaluation">
                            </evaluation-rating>
                          </ng-container>
                          <ng-container *ngIf="isIT">
                            <evaluation-rating [user]="managerData"
                              [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + managerData.forename"
                              [evaluationText]="selectedObjective.managerEvaluation"
                              [ratingIT]="selectedObjective.managerEvaluationVote">
                            </evaluation-rating>
                          </ng-container>
                        </ng-container>
                        <!-- Valutazione obiettivo self-assessment TODO Ripristina post HR-->
                        <!--evaluation-rating [user]="personDetails"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + personDetails.forename"
                        [evaluationText]="testString">
                      </evaluation-rating-->
                      </div>
                    </ng-container>

                    <competence-average *ngIf="goalsAverage && isIT" [ratingScale]="goalsAverage.ratingScale"
                      [competenceAvg]="goalsAverage" [titlesRightSection]="titlesRightSection">
                    </competence-average>
                  </div>
                </ng-container>
              </ng-container>

              <!-- Box titolo riepilogo per comportamenti leadership -->
              <ng-container *ngIf="isLeadershipTeam">
                <div class="page-header white-bg">
                  <div class="title-container" [ngClass]="{'margin-bottom0': !isScheduleShared}">
                    <p class="title" translate="stepper.RECAP"></p>
                  </div>
                  <!-- Scheda leadership inviata all'HR -->
                  <div class="sent-to-hr" *ngIf="isScheduleShared">
                    <div class="sent-to-hr-label">
                      <p translate="performerEvaluators.SENT_TO_HR"></p>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Recap valutazione comporamenti -->
              <div class="page-header white-bg">
                <div class="title-container">
                  <p class="title" translate="stepper.BEHAVIOUR_EVALUATION"></p>
                </div>
                <p class="description" [innerHTML]="'stepper.BEHAVIOUR_EVALUATION_DESCR_FE' | translate"></p>


                <ng-container *ngIf="!isLoadingFinalEvaluationData">
                  <!-- placeholder valutazione incompleta -->
                  <ng-container *ngIf="!finalEvaluation.behaviorData || !finalEvaluation.behaviorData.rating ||
                    !finalEvaluation.behaviorData.ratingValue">
                    <star-rating [disabled]="true" [forceRating]="0"
                      [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                  </ng-container>

                  <ng-container
                    *ngIf="finalEvaluation.behaviorData && finalEvaluation.behaviorData.rating && finalEvaluation.behaviorData.ratingValue">
                    <ng-container *ngIf="editBehaviorComment">
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                        [isValued]="finalEvaluation.behaviorData.comment && finalEvaluation.behaviorData.comment.trim().length"
                        [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                        [disabled]="isItemDisabled()">
                        <alloy-text-area [bindValue]="finalEvaluation.behaviorData.comment" [maxlength]="300"
                          (onModelChanged)="onBehaviorCommentEdited($event)" [readonly]="isItemDisabled()"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>

                    <!-- Rating comportamenti self -->
                    <ng-container *ngIf="!editBehaviorComment ">
                      <!-- MITODO -->
                      <!-- Rating comportamenti people -->
                      <ng-container
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.behaviorData.selfRating && finalEvaluation.behaviorData.selfRatingValue">
                        <evaluation-rating [user]="managerData"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + managerData.forename"
                          [evaluationText]="managerFinalEvaluation.behaviorData.comment"
                          [rating]="managerFinalEvaluation.behaviorData.rating" [canEdit]="false"
                          [isEditDisabled]="isScheduleShared" (onEdit)="onEditBehaviourComment()"
                          [isLeadershipTeam]="isLeadershipTeam"
                          [ratingValue]="managerFinalEvaluation.behaviorData.ratingValue">
                        </evaluation-rating>
                      </ng-container>
                      <!-- Rating comportamenti self -->

                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                        [evaluationText]="finalEvaluation.behaviorData.comment"
                        [rating]="finalEvaluation.behaviorData.rating" [isSelf]="true"
                        [ratingValue]="isPostCalibration || isFinalStepCompleted?finalEvaluation.behaviorData.ratingValue : ''"
                        [canEdit]="false" [isLeadershipTeam]="isLeadershipTeam">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="objective-tag-container">
                    <div class="loader spinner-medium"></div>
                  </div>
                </ng-container>
              </div>

              <!-- Note e commenti leadership team -->
              <ng-container *ngIf="isLeadershipTeam && !isFinalStepCompleted">
                <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                  [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length" [isObbligatory]="false"
                  title="{{'generic.NOTES_COMMENTS' | translate}}" [disabled]="isItemDisabled()">
                  <alloy-text-area
                    [bindValue]="isItemDisabled() && (!finalEvaluation.comment || !finalEvaluation.comment.length) ? ('generic.NO_COMMENT_INSERTED' | translate) : finalEvaluation.comment"
                    [maxlength]="300" (onModelChanged)="onFinalEvaluationCommentChanged($event)"
                    placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}" [readonly]="isItemDisabled()">
                  </alloy-text-area>
                </input-container>
              </ng-container>

              <!-- Recap valutazione competenze tecniche -->
              <ng-container *ngIf="!isManagement && !isLeadershipTeam">
                <div class="page-header white-bg">
                  <div class="title-container">
                    <p *ngIf="!isIT" class="title" translate="stepper.TECH_SKILL_EVALUATION"></p>
                    <p *ngIf="isIT" class="title" translate="stepper.TECH_OBJECTIVES">
                    </p>
                  </div>
                  <p *ngIf="!isIT" class="description"
                    [innerHTML]="'stepper.TECH_SKILL_EVALUATION_DESCR_FE' | translate"></p>
                  <p *ngIf="isIT" class="description" [innerHTML]="'stepper.TECH_OBJECTIVES_DESCR_FE' | translate"></p>

                  <ng-container *ngIf="!isLoadingFinalEvaluationData">
                    <!-- placeholder valutazione incompleta -->
                    <ng-container
                      *ngIf="!finalEvaluation.competenceData || !finalEvaluation.competenceData.rating || !finalEvaluation.competenceData.ratingValue">
                      <star-rating [disabled]="true" [forceRating]="0"
                        [forceLabel]="'generic.EVALUATION_INCOMPLETE' | translate"></star-rating>
                    </ng-container>

                    <ng-container
                      *ngIf="finalEvaluation.competenceData && finalEvaluation.competenceData.rating && finalEvaluation.competenceData.ratingValue">

                      <!-- Edit commento competenze -->
                      <ng-container *ngIf="editCompetenceComment">
                        <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                          [isValued]="finalEvaluation.competenceData.comment && finalEvaluation.competenceData.comment.trim().length"
                          [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}">
                          <alloy-text-area [bindValue]="finalEvaluation.competenceData.comment" [maxlength]="300"
                            (onModelChanged)="onCompetenceCommentEdited($event)"
                            placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                          </alloy-text-area>
                        </input-container>
                      </ng-container>

                      <ng-container *ngIf="!editCompetenceComment">
                        <!-- MITODO -->
                        <!-- Rating competenze people -->
                        <ng-container
                          *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.behaviorData.selfRating && finalEvaluation.behaviorData.selfRatingValue">
                          <evaluation-rating [user]="managerData"
                            [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + managerData.forename"
                            [evaluationText]="managerFinalEvaluation.competenceData.comment"
                            [ratingValue]="managerFinalEvaluation.competenceData.ratingValue"
                            [rating]="managerFinalEvaluation.competenceData.rating" [canEdit]="false"
                            [isEditDisabled]="isScheduleShared" (onEdit)="onEditCompetenceComment($event)">
                          </evaluation-rating>
                        </ng-container>
                        <!-- Rating competenze self -->
                        <evaluation-rating [user]="loggedUser"
                          [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                          [evaluationText]="finalEvaluation.competenceData.comment" [isSelf]="true"
                          [rating]="finalEvaluation.competenceData.rating" [canEdit]="false"
                          [isLeadershipTeam]="isLeadershipTeam"
                          [ratingValue]="isPostCalibration || isFinalStepCompleted? finalEvaluation.competenceData.ratingValue : ''">
                        </evaluation-rating>

                      </ng-container>

                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="isLoadingFinalEvaluationData">
                    <div class="objective-tag-container">
                      <div class="loader spinner-medium"></div>
                    </div>
                  </ng-container>

                </div>
              </ng-container>
              <!-- </ng-container> -->
              <!-- Fine Sezione da nascondere se step completo (post ri condivisione scheda manager) -->

              <!-- Recap obiettivi di sviluppo -->
              <div class="page-header white-bg" *ngIf="!isLeadershipTeam">
                <div class="title-container">
                  <p *ngIf="!isIT && !yearsWithSpecialisticGoalsToEvaluate" class="title"> {{
                    ('finalEvaluation.OBJECTIVE_DEVELOPMENT' | translate) + ('headerDossier.CARD_MIN'
                    | translate) + getYearName()}}</p>
                  <p *ngIf="isIT || yearsWithSpecialisticGoalsToEvaluate" class="title"> {{
                    ('finalEvaluation.TECHNICAL_SPECIALISED_OBJECTIVES' | translate) +
                    ('headerDossier.CARD_MIN' |
                    translate) + getYearName()}}</p>
                </div>
                <ng-container *ngIf="!yearsWithSpecialisticGoalsToEvaluate && !isIT">
                  <p *ngIf="personDetails" class="description" [innerHTML]="recapDevelopmentDescription">
                    {{
                    ('finalEvaluation.ASSIGNED_LIST_1' | translate) +
                    this.personDetails.forename
                    ('finalEvaluation.ASSIGNED_LIST_2' |
                    translate)}}
                  </p>
                </ng-container>
                <ng-container *ngIf="yearsWithSpecialisticGoalsToEvaluate || isIT">
                  <p *ngIf="personDetails" class="description" [innerHTML]="recapDevelopmentDescription">
                    {{
                    ('finalEvaluation.ASSIGNED_SPECIALISED_LIST_1' | translate) +
                    this.personDetails.forename
                    ('finalEvaluation.ASSIGNED_SPECIALISED_LIST_2' |
                    translate)}}
                  </p>

                </ng-container>



                <!-- Lista obiettivi per il semestre successivo -->
                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <ng-container *ngFor="let objective of finalEvaluation.definedObjectives; let i = index">
                    <!-- Obiettivo salvato -->
                    <ng-container *ngIf="objective.id && !objective.isEdit">
                      <text-goal
                        [subtitle]="objective.creationUser?.forename ? (('defineObjectives.INSERTED_BY' | translate) + objective.creationUser?.forename + ' ' + objective.creationUser?.surname) : ''"
                        [title]="objective.title" [showMenu]="isOptionAvailable()" [canEdit]="isOptionAvailable()"
                        (menuOptionClicked)="onEditMenuOptionClicked($event, objective, true)"></text-goal>
                    </ng-container>
                    <!-- Obiettivo non salvato -->
                    <ng-container *ngIf="!objective.id || (objective.id && objective.isEdit)">
                      <input-container [numberCharsTextArea]="300" [isWithoutInfoIcon]="true"
                        [isValued]="objective.title && objective.title.trim().length" [isObbligatory]="true"
                        title="{{ ( (isIT || yearsWithSpecialisticGoalsToEvaluate) ?('defineObjectives.DEVELOPMENT_SPECIALIST_OBJECTIVE' | translate) : ('defineObjectives.DEVELOPMENT_OBJECTIVE' | translate)) + ' ' + (i + 1)}}">
                        <alloy-text-area [bindValue]="objective.title" [maxlength]="300"
                          (onModelChanged)="onObjectiveTitleChanged($event, objective)"
                          placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!finalEvaluation.definedObjectives || !finalEvaluation.definedObjectives.length">
                    <p class="placeholder margin" translate="defineObjectives.NO_OBJECTIVE"></p>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>
              </div>

              <!-- Rimossa in seguito a CR 11/2024 -->
              <div class="page-header white-bg"
                *ngIf="false && !isLoadingFinalEvaluationData && finalEvaluation.insightFeedback && finalEvaluation.insightFeedback.matrixManager && finalEvaluation.insightFeedback.matrixManagerObservations?.strongPoints?.length && finalEvaluation.insightFeedback.matrixManagerObservations?.developmentAreas?.length">
                <div class="title-container">
                  <p class="title" translate="insight.NAME"></p>
                </div>

                <summary-card [user]="finalEvaluation.insightFeedback.matrixManager"
                  [observationData]="finalEvaluation.insightFeedback.matrixManagerObservations"
                  [isMatrixManagerFeedback]="true" [isEditDisabled]="true">
                </summary-card>

                <summary-card *ngIf="finalEvaluation?.insightFeedback?.directManagerComment?.length"
                  [user]="finalEvaluation.insightFeedback.requiredByManager"
                  [matrixManager]="finalEvaluation.insightFeedback.matrixManager"
                  [directManagerComment]="finalEvaluation.insightFeedback.directManagerComment"
                  [isInsightManagerComment]="true" [isEditDisabled]="true">
                </summary-card>

              </div>

              <!-- Recap valutazione finale + aree di forza e di miglioramento -->
              <div class="page-header white-bg" *ngIf="!isLeadershipTeam && !processIsBeforeH2023">
                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <!-- Edit punti di forza e aree di sviluppo-->
                  <ng-container *ngIf="!processIsBeforeH2023">
                    <ng-container *ngIf="!isFinalStepCompleted && (!isScheduleShared || editFinalEvaluationComment)">
                      <!-- Titolo sezione punti di forza / aree di sviluppo-->
                      <div class="title-container">
                        <p class="title" translate="finalEvaluation.STRENGTH_DEVELOPMENT_TITLE"></p>
                      </div>
                      <p class="description"
                        [innerHtml]="'finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_SELF' | translate">
                      </p>
                      <!-- Punti di forza -->
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                        [tooltipModalMessage]="strongPointTooltip"
                        [isValued]="finalEvaluation.ownObservations && finalEvaluation.ownObservations.strongPoints && finalEvaluation.ownObservations.strongPoints.trim().length"
                        [whiteBackground]="true" [isObbligatory]="false"
                        [title]="'finalEvaluation.STRONG_POINTS' | translate">
                        <alloy-text-area [bindValue]="finalEvaluation.ownObservations.strongPoints" [maxlength]="300"
                          (onModelChanged)="onStrongPointChanged($event)"
                          [placeholder]="'finalEvaluation.STRONG_POINTS_PLACEHOLDER' | translate">
                        </alloy-text-area>
                      </input-container>
                      <!-- Aree di sviluppo -->
                      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true"
                        [tooltipModalMessage]="developmentAreaTooltip"
                        [isValued]="finalEvaluation.ownObservations.developmentAreas && finalEvaluation.ownObservations.developmentAreas.trim().length"
                        [whiteBackground]="true" [isObbligatory]="false"
                        [title]="'finalEvaluation.DEVELOPMENT_AREAS' | translate">
                        <alloy-text-area [bindValue]="finalEvaluation.ownObservations.developmentAreas"
                          [maxlength]="300" (onModelChanged)="onDevelopmentAreaChanged($event)"
                          [placeholder]="'finalEvaluation.DEVELOPMENT_AREAS_PLACEHOLDER' | translate">
                        </alloy-text-area>
                      </input-container>
                    </ng-container>
                    <!-- Commenti readonly (vecchi commenti + punti forti e aree di sviluppo)-->
                    <ng-container *ngIf="isItemDisabled()">
                      <!-- Titolo note e commenti -->
                      <ng-container
                        *ngIf="((isPostCalibration || isFinalStepCompleted) && finalEvaluation.selfComment && finalEvaluation.selfComment.length) || finalEvaluation.comment && finalEvaluation.comment.length; else strongDevelopmentTitle">
                        <div class="title-container">
                          <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                        </div>
                      </ng-container>
                      <ng-template #strongDevelopmentTitle>
                        <!-- Titolo punti di forza / aree di sviluppo-->
                        <div class="title-container">
                          <p class="title" translate="finalEvaluation.STRENGTH_DEVELOPMENT_TITLE"></p>
                        </div>
                        <p class="description"
                          [innerHtml]="'finalEvaluation.STRENGTH_DEVELOPMENT_DESCR_SELF' | translate">
                        </p>
                      </ng-template>


                      <!-- Commento valutazione finale manager -->
                      <evaluation-rating
                        *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.selfComment && finalEvaluation.selfComment.length"
                        [user]="managerData"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + managerData.forename"
                        [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>

                      <!-- Commento valutazione finale da utente -->
                      <ng-container *ngIf="!editFinalEvaluationComment">
                        <!-- Note e commenti -->
                        <ng-container *ngIf="finalEvaluation.comment && finalEvaluation.comment.length">
                          <evaluation-rating [user]="loggedUser"
                            [evaluationTitle]="'finalEvaluation.NOTES_COMMENTS' | translate"
                            [evaluationText]="finalEvaluation.comment || ('finalEvaluation.NO_OBSERVATION_PLACEHOLDER' | translate)">
                          </evaluation-rating>
                        </ng-container>

                        <!-- Summary card punti di forza e aree di sviluppo, se non c'è il commento -->
                        <ng-container
                          *ngIf="!(finalEvaluation.comment && finalEvaluation.comment.length) && !(finalEvaluation.selfComment && finalEvaluation.selfComment.length)">
                          <ng-container *ngIf="finalEvaluation.ownObservations">
                            <summary-card [user]="loggedUser" [observationData]="finalEvaluation.ownObservations">
                            </summary-card>
                          </ng-container>

                          <ng-container
                            *ngIf="(isPostCalibration || isFinalStepCompleted) && finalEvaluation.colleagueObservations">
                            <summary-card [user]="managerData"
                              [observationData]="finalEvaluation.colleagueObservations">
                            </summary-card>
                          </ng-container>
                        </ng-container>

                      </ng-container>
                    </ng-container>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <!-- Recap valutazione finale -->
              <div class="page-header white-bg" *ngIf="!isLeadershipTeam && processIsBeforeH2023">
                <div class="title-container">
                  <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                </div>

                <ng-container *ngIf="!isLoadingFinalEvaluationData">

                  <ng-container *ngIf="!isFinalStepCompleted && (!isScheduleShared || editFinalEvaluationComment)">
                    <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
                      [isValued]="finalEvaluation.comment && finalEvaluation.comment.trim().length"
                      [isObbligatory]="false" title="{{'generic.NOTES_COMMENTS' | translate}}"
                      [disabled]="isItemDisabled()">
                      <alloy-text-area [bindValue]="finalEvaluation.comment" [maxlength]="300"
                        (onModelChanged)="onFinalEvaluationCommentChanged($event)" [readonly]="isItemDisabled()"
                        placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
                      </alloy-text-area>
                    </input-container>
                  </ng-container>

                  <ng-container *ngIf="isItemDisabled()">

                    <!-- Commento valutazione finale manager -->
                    <ng-container>
                      <evaluation-rating *ngIf="isPostCalibration || isFinalStepCompleted" [user]="managerData"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + managerData.forename"
                        [evaluationText]="finalEvaluation.selfComment" [isSelf]="true">
                      </evaluation-rating>
                    </ng-container>

                    <!-- Commento valutazione finale da utente -->
                    <ng-container *ngIf="!editFinalEvaluationComment">
                      <evaluation-rating [user]="loggedUser"
                        [evaluationTitle]="('finalEvaluation.COMMENTS_BY' | translate) + loggedUser.forename"
                        [evaluationText]="finalEvaluation.comment">
                      </evaluation-rating>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="isLoadingFinalEvaluationData">
                  <div class="loader spinner-medium"></div>
                </ng-container>

              </div>

              <!-- Box finale post ri condivisione scheda manager per leadership team -->
              <ng-container *ngIf="isLeadershipTeam && isFinalStepCompleted">

                <div class="page-header white-bg">
                  <div class="leadership-completed">
                    <h3 translate="leadership.SELF_ASSESSMENT_COMPELETE"></h3>
                    <p translate="leadership.SELF_ASSESSMENT_COMPLETE_DESCR"></p>
                  </div>
                </div>

                <div class="page-header white-bg">
                  <div class="title-container">
                    <p class="title" translate="finalEvaluation.NOTES_COMMENTS"></p>
                  </div>
                  <evaluation-rating *ngIf="managerData" [user]="managerData"
                    [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + managerData.forename"
                    [evaluationText]="finalEvaluation.managerComment" [canEdit]="false">
                  </evaluation-rating>
                  <evaluation-rating [user]="loggedUser"
                    [evaluationTitle]="('finalEvaluation.EVALUATION_BY' | translate) + loggedUser.forename"
                    [evaluationText]="finalEvaluation.comment" [canEdit]="false">
                  </evaluation-rating>
                </div>

              </ng-container>

              <div class="person-details-main-actions-banner selfAssessment">
                <div class="row page-content-wrapper">
                  <div class="left-btn">
                    <!-- Pulsante desktop -->
                    <alloy-button type="black-border" size="medium" label="{{'generic.BACK' | translate}}"
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow.gt-xs fxHide>
                    </alloy-button>
                    <!-- Pulsante mobile -->
                    <alloy-button type="black-border with-just-left-icon" size="medium" label=""
                      (click)="goToPrevStep()" iconLeft="/assets/img/icons/arrow-left.svg" fxShow fxHide.gt-xs>
                    </alloy-button>
                  </div>
                  <div class="right-btn" *ngIf="!isScheduleShared && !isFinalStepCompleted && !isHistory">
                    <!-- Salva -->
                    <alloy-button *ngIf="!isSendEvalBtnVisibleAndEnabled()" [disabled]="isLoadingFinalEvaluationData"
                      type="primary" size="medium" label="{{'generic.SAVE' | translate}}"
                      (onButtonClicked)="saveFinalEvaluationStep()">
                    </alloy-button>
                    <!-- Proponi valutazione -->
                    <alloy-button *ngIf="isSendEvalBtnVisibleAndEnabled()" type="primary" size="medium"
                      label="{{'finalEvaluation.SEND_EVAL' | translate}}"
                      [disabled]="isLoadingFinalEvaluationData || !allStepCompleted()"
                      (onButtonClicked)="openSendFinalEvaluation()">
                    </alloy-button>
                  </div>
                </div>
              </div>

            </ng-container>


          </child-content>

        </ng-container>
        <!-- Caricamento tab child content -->
        <ng-container *ngIf="isLoadingTab">
          <div class="loader spinner-big"></div>
        </ng-container>

      </ng-container>

      <!-- I miei appunti -->
      <ng-container *ngIf="isNoteSection">
        <child-content>
          <div class="page-header">
            <ng-container *ngIf="privateNotesList && privateNotesList.length">
              <div class="title-container">
                <p class="title" translate="headerDossier.MY_NOTES"></p>
                <!--<svg-icon src="/assets/img/icons/info.svg" class="title-icon" [applyClass]="true"
                (click)="openHelpModal('notes')">
              </svg-icon>-->
              </div>
              <p class="description" [innerHTML]="'headerDossier.MY_NOTES_DESCR' | translate"></p>
            </ng-container>

            <ng-container *ngIf="!isLoadingPrivateNotes">
              <div class="card-list-container">
                <!-- Lista appunti privati -->
                <ng-container *ngIf="privateNotesList && privateNotesList.length">
                  <card-notes *ngFor="let note of privateNotesList" [note]="note" [loggedUser]="loggedUser"
                    (onDelete)="openDeleteNoteModal($event)" (onEdit)="goToEditPrivateNote($event)"></card-notes>
                </ng-container>

                <!-- Placeholder se non ho appunti privati -->
                <ng-container *ngIf="!privateNotesList || !privateNotesList.length">
                  <no-data [title]="('privateNotes.TITLE' | translate)" [hideIcon]="true"
                    [description]="('privateNotes.PLACEHOLDER' | translate)">
                  </no-data>
                </ng-container>
              </div>
              <!-- Pulsanti -->
              <div class="button-container development">
                <alloy-button type="success" [label]="('privateNotes.INSERT_NOTE' | translate)"
                  (onButtonClicked)="createPrivateNote()"></alloy-button>
              </div>
            </ng-container>


            <!-- Loader contenuto -->
            <ng-container *ngIf="isLoadingPrivateNotes">
              <div class="loader spinner-medium"></div>
            </ng-container>

          </div>
        </child-content>
      </ng-container>

    </div>
  </ng-container>
  <!-- Loader caricamento dati utente -->
  <ng-container *ngIf="">
    <div class="loader spinner-big"></div>
  </ng-container>
</div>

<!-- Modale di aiuto per le competenze -->
<modal id="competence-dictionary" (onClose)="closeCompetenceDictionaryModal()"
  (onConfirm)="closeCompetenceDictionaryModal()" confirmLabel="{{ 'generic.CLOSE' | translate}}">
  <p class="modal-title black center" *ngIf="competenceForModal && !competenceForModal.isBehaviour">
    {{ (currentStep.id == 'techEvaluation' && yearsWithSpecialisticGoalsToEvaluate ? 'manageBehaviour.SPEC_TITLE' :
    'manageBehaviour.COMPETENCE_TITLE')
    |translate}}
  </p>
  <p class="modal-title black center" *ngIf="competenceForModal && competenceForModal.isBehaviour">
    {{ 'manageBehaviour.DICTIONARY_TITLE' | translate }}
  </p>
  <ng-container *ngIf="competenceForModal">
    <div class="competence-dictionary-container">
      <ng-container *ngIf="competenceForModal.category">
        <div class="competence-description">
          <p class="title">{{ competenceForModal.category}}</p>
          <p class="subtitle">{{ competenceForModal.title}}</p>
          <p class="descr">{{ competenceForModal.descr }}</p>
        </div>
      </ng-container>
      <div class="competence-ratings">
        <ng-container *ngFor="let rating of competenceForModal.evaluationScale">
          <div class="rating-section">
            <star-rating [forceRating]="rating.scale"></star-rating>
            <p class="descr"> {{ rating.text }}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</modal>


<!-- Conferma eliminazione obiettivo -->
<modal id="confirm-delete-saved-objective" (onClose)="closeDeleteSavedObjective()"
  (onConfirm)="closeDeleteSavedObjective(true)" confirmLabel="{{ 'generic.DELETE' | translate}}"
  modalId="confirm-delete-saved-objective">
  <modal-text-content [title]="'defineObjectives.modals.CONFIRM_DELETE_TITLE' | translate"
    [text]="'defineObjectives.modals.CONFIRM_DELETE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale che mostra le informazioni del Performer -->
<modal id="performerEvaluatorInfoModal" (onClose)="closePerformerModal()" [confirmLabel]="('generic.CLOSE' | translate)"
  (onConfirm)="closePerformerModal()">
  <performer-evaluator-info-modal-content [selectedInfoTabPerformerKey]="selectedInfoTabPerformerKey">
  </performer-evaluator-info-modal-content>
</modal>

<!-- Modale elimina appunto privato -->
<modal id="deleteNoteModal" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeDeleteNoteModal(false)"
  (onClose)="closeDeleteNoteModal(false)" (onConfirm)="closeDeleteNoteModal(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="pn014" [title]="'privateNotes.modals.DELETE_NOTE_TITLE' | translate"
    [text]="'privateNotes.modals.DELETE_NOTE_TEXT' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi valutati -->
<modal id="save-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveObjectivesModal(false,true)" (onClose)="closeSaveObjectivesModal(true, true)"
  (onConfirm)="closeSaveObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe001" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione comportamenti -->
<modal id="save-behaviours-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveBehavioursModal(false,true)" (onClose)="closeSaveBehavioursModal(true, true)"
  (onConfirm)="closeSaveBehavioursModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe002" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione tech skill -->
<modal id="save-tech-skills-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveTechSkillsModal(false,true)" (onClose)="closeSaveTechSkillsModal(true, true)"
  (onConfirm)="closeSaveTechSkillsModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe003" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva riepilogo -->
<modal id="save-final-evaluation-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveFinalEvaluationModal(false,true)" (onClose)="closeSaveFinalEvaluationModal(true, true)"
  (onConfirm)="closeSaveFinalEvaluationModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe004" [title]="'objectiveEvaluation.EXIT_UNSAVED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale salva valutazione obiettivi definiti -->
<modal id="save-defined-objective-modal" [cancelLabel]="('generic.CANCEL' | translate)"
  (onCancel)="closeSaveDefinedObjectivesModal(false,true)" (onClose)="closeSaveDefinedObjectivesModal(true, true)"
  (onConfirm)="closeSaveDefinedObjectivesModal(false)" confirmLabel="{{ 'objectiveEvaluation.CONTINUE' | translate}}">
  <modal-text-content modalId="oe005" [title]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_TITLE' | translate"
    [subtitle]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_SUBTITLE' | translate"
    [text]="'objectiveEvaluation.EXIT_UNSAVED_DEFINED_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modale conferma invio HR -->
<modal id="send-hr-schedule" [cancelLabel]="('generic.CANCEL' | translate)" (onCancel)="closeSendFinalEvaluation()"
  (onClose)="closeSendFinalEvaluation()" (onConfirm)="closeSendFinalEvaluation(true)"
  confirmLabel="{{ 'generic.CONFIRM' | translate}}">
  <modal-text-content modalId="oe006" [title]="'finalEvaluation.SHARE_SCHEDULE' | translate"
    [text]="'finalEvaluation.SHARE_SCHEDULE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Modifica obiettivo -->
<modal id="edit-objective-modal" (onClose)="closeEditObjectiveModal()" [confirmLabel]="'generic.EDIT' | translate"
  (onConfirm)="closeEditObjectiveModal(true)" (onCancel)="closeEditObjectiveModal()"
  [cancelLabel]="'generic.CANCEL' | translate">
  <div class="custom-modal">
    <!-- Titolo, subtitolo-->
    <modal-text-content modalId="oed001" [title]="'finalEvaluation.modals.EDIT_SUB' | translate"
      [subtitle]="'finalEvaluation.modals.EDIT_SUB' | translate" text=''>
    </modal-text-content>
    <!-- Area di testo-->
    <ng-container *ngIf="editObjectiveData">
      <input-container [numberCharsTextArea]="300" [isFromTextArea]="true" [isWithoutInfoIcon]="true"
        [isValued]="editObjectiveData.title && editObjectiveData.title.trim().length" [isObbligatory]="false">
        <alloy-text-area [bindValue]="editObjectiveData.title" [maxlength]="300"
          (onModelChanged)="onObjectiveEdit($event)" placeholder="{{'textGoal.INSERT_PLACEHOLDER' | translate}}">
        </alloy-text-area>
      </input-container>
    </ng-container>
  </div>
</modal>

<!-- Elimina obiettivo -->
<modal id="delete-objective-modal" (onClose)="closeDeleteObjectiveModal()"
  [confirmLabel]="'generic.CONFIRM' | translate" (onConfirm)="closeDeleteObjectiveModal(true)"
  (onCancel)="closeDeleteObjectiveModal()" [cancelLabel]="'generic.CANCEL' | translate">
  <modal-text-content modalId="oe007" [title]="'finalEvaluation.modals.DELETE_TITLE' | translate"
    [subtitle]="'finalEvaluation.modals.DELETE_SUB' | translate" [text]="getDeleteModalText()">
  </modal-text-content>
</modal>