<textarea *ngIf="!readonly && !upwardDisabled" [ngClass]="{'grey':grey, 'error':error}" [(ngModel)]="bindValue" [placeholder]="placeholder"
  attr.aria-label="{{ attrAriaLabel }}" (ngModelChange)="emitOnModelChanged($event)" #textareaEl="ngModel"></textarea>

<p *ngIf="readonly && !isInnerHTML">
  {{bindValue}}
</p>

<p [innerHTML]="bindValue" *ngIf="readonly && isInnerHTML">
</p>

<p [innerHTML]="bindValue" *ngIf="upwardDisabled">
</p>

<ng-container *ngIf="ITRating">

  <star-rating [direction]="'row'" (onStarClicked)="onStarClicked($event)" [forceRating]="ITRatingVote"
    [forceDisabled]="disabledRating" [customContainerClass]="'p-tb'"></star-rating>
</ng-container>