<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('calibration.TITLE' | translate)"
    [adminPageSubtitle]="('calibration.PROCESS_LIST' | translate)" [adminBack]="true" [hasTabs]="false">
  </header-dossier>
  <div class="main-content-wrapper admin">
    <!-- Home processi -->
    <!-- contenitore tabella -->
    <ng-container *ngIf="!isLoadingProcessData">
      <div class="admin-table-container">
        <div class="table-content">
          <table>
            <thead>
              <tr>
                <th translate="{{ ('adminTable.SEMESTER' | translate)}}"></th>
                <th translate="{{ ('adminTable.PROCESS_NAME' | translate)}}"></th>
                <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let process of processData.list | 
                            slice:0:processData.numRecords | 
                            paginate: { id: 'processList',
                                        itemsPerPage: processData.numRecords,
                                        currentPage: processData.page,
                                        totalItems: processData.counter }">
                <td>{{ process.processYearCode + ' ' + process.processYear }}</td>
                <td>{{ process.name }}</td>
                <td>
                  {{ process.totalParticipants || 0 }}
                </td>
                <td>
                  <item-list-team-status [type]="process.statusType" [message]="process.statusMessage" styleItem="fill">
                  </item-list-team-status>
                </td>
                <td class="link" (click)="goToProcessDetails(process)">
                  {{ 'adminTable.ACCESS' | translate }}
                </td>
                <!-- 
                  <td *ngIf="process.menuOptions && process.menuOptions.length && process.menuOptions.length > 1"
                    class="action">
                    <svg-icon class="more-icon" src="assets/img/icons/more-horizontal.svg" [applyClass]="true"
                      (click)="openOptionsMenu(process)">
                    </svg-icon>
                    <ng-container *ngIf="process.isMenuOpen">
                      <div class="dropdown-menu">
                        <alloy-dropdown-item (click)="onMenuOptionsClicked(option, process)" [ngClass]="{'last': last}"
                          class="full-width" *ngFor="let option of process.menuOptions; let last = last"
                          [item]="option">
                        </alloy-dropdown-item>
                      </div>
                    </ng-container>
                  </td>
                  <td *ngIf="process.menuOptions && process.menuOptions.length && process.menuOptions.length === 1"
                    class="link" (click)="onMenuOptionsClicked(process.menuOptions[0], process)">{{
                    process.menuOptions[0].title || '' }}</td>
                    -->
              </tr>

            </tbody>
          </table>
        </div>
        <div class="bottom-actions">
          <table-pagination id="processList" (pageChanged)="processPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </alloy-select>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- loader -->
    <ng-container *ngIf="isLoadingProcessData">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>
  </div>
</div>