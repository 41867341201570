import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { CountUsersForImpersonateResponse, JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-admin-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnDestroy {
  runningYear$: Subscription;
  runningYear: any;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  isLoadingUsersData: boolean = false;
  getUsersData$: Subscription = new Subscription;
  usersData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 50,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
    {
      id: 150,
      title: 150
    },
    {
      id: 200,
      title: 200
    }
  ]
  selectedRows: { id: number, title: number };
  processYear: string = '';
  structureForModal: any;
  searchedText: string = '';
  userProcessModalData: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    public urlService: UrlService,
    private analyticsService: AnalyticsService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Admin - Impersonifica");

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });
    this.selectedRows = this.rowPerPageOptions[0];
    // const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // const getRunningPhase$: Observable<any> = this.store.select(fromApp.getRunningPhase);
    // this.combinedSelected$ = combineLatest([loggedUser$, getRunningPhase$])
    //   .subscribe(
    //     ([loggedUser, runningPhase]) => {
    //       if (runningPhase && loggedUser && loggedUser.user) {
    //         this.loggedUser = loggedUser && loggedUser.user;
    //       }
    //     });
  }

  ngOnInit() {
    this.store.select(fromApp.getLoggedUser)
      .subscribe((loggedUser) => {
        if (loggedUser && loggedUser.user) {
          this.getUsersData();
        }
      })
  }


  getUsersData() {
    this.isLoadingUsersData = true;
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
    this.getUsersData$ = this.adminService.countUserImpersonate(this.searchedText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<CountUsersForImpersonateResponse>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersData.counter = counter.response;

              // Calcolo la paginazione
              this.usersData.fromRecord = (this.usersData.page - 1) * this.usersData.numRecords;

              if (this.usersData.counter) {
                return this.adminService.listUserImpersonate(this.usersData.fromRecord, this.usersData.numRecords, this.searchedText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a009",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsersData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a010",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.usersData.list = data.response || [];
          }
          this.isLoadingUsersData = false;
        }
        , (err: any) => {
          this.isLoadingUsersData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a011",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );

  }

  processPageChanged(page: number) {
    this.usersData.page = page;
    this.usersData.list = [];
    this.getUsersData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersData.numRecords = item.id;
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 0;
    this.getUsersData();
  }

  getModalDescription(feedback: any, translations: any) {
    let text = '';
    if (feedback) {
      text += translations['monitoring.modals.PEER_REC'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.PEER_REQ'] + ' <b>' + '-' + '</b><br>';
      text += translations['monitoring.modals.CLAP_SENT'] + ' <b>' + (feedback.createdApplauseFeedbacks || '-') + '</b><br>';
      text += translations['monitoring.modals.INTERVIEW'] + ' <b>' + (feedback.feedbackDialogueRentCount || '-') + '</b><br>';
      text += translations['monitoring.modals.EVIDENCES'] + ' <b>' + (feedback.createdEvidenceFeedbacks || '-') + '</b><br>';
    }
    return text;
  }

  orderBy(fieldName: string) {
  }

  onSearch() {
    this.usersData.list = [];
    this.usersData.fromRecord = 0;
    this.usersData.page = 0;
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendSearchEvent(this.searchedText || '');
    this.getUsersData();
  }

  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  impersonate(userId: string) {
    this.isLoadingUsersData = true;
    this.adminService.impersonateUserForAdmin(userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let url = this.urlService.getApplicationUrl();
          // const redirectUrl = 
          let redirectUrl = '';
          if (window.location.href.indexOf('localhost') >= 0) {
            redirectUrl = 'http://localhost:4200/#/impersonateRedirect?token=' + data.response;
          } else {
            redirectUrl = url.url + '/#/impersonateRedirect?token=' + data.response;
          }

          window.open(redirectUrl, '_blank');
        }
        this.isLoadingUsersData = false;
      },
        (err) => {
          this.isLoadingUsersData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a013",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        })
  }

  openUserProcessModal(user: any) {
    this.userProcessModalData = user;
    this.modalService.open('userProcessInfo');
  }

  closeUserProcessModal() {
    this.modalService.close('userProcessInfo')
    this.userProcessModalData = null;
  }


  getIsProcessActive(process: any) {
    if (process && process.phases && process.phases) {
      let activePhases = process.phases.filter((phase: any) => phase.statusType !== "PHASE_INACTIVE");
      if (activePhases && activePhases.length) {
        return this.translate.instant('impersonate.modals.ACTIVE');
      }
    }
    return this.translate.instant('impersonate.modals.INACTIVE');
  }

  ngOnDestroy() {
    if (this.getUsersData$) {
      this.getUsersData$.unsubscribe();
    }
  }
}
