import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../../ngrx/app.reducers";
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { AlloyPerformanceUpwardFeedbackServiceResponses, SenecaResponse, User } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription, throwError } from 'rxjs';

@Component({
  selector: 'app-upward-feedback-team-list',
  templateUrl: './upwardFeedbackTeamList.component.html',
  styleUrls: ['./upwardFeedbackTeamList.component.scss']
})
export class UpwardFeedbackTeamListComponent implements OnInit {
  isLoadingUser: boolean = true;
  loggedUser$: Subscription;
  loggedUser: User | null = null;
  isLoadingRoundList: boolean = true;
  translations: any;
  roundList: { id: string, name: string, roundStatus: string, startDate: string }[] = [];
  selectedRound: { id: string, name: string, roundStatus: string, startDate: string } | null = null;
  getTeamData$: Subscription = new Subscription();
  isLoadingTeamData: boolean = false;
  teamData: any;

  constructor(
    public redirectService: RedirectService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService
  ) {
    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
        this.isLoadingUser = false;
      }
    })
  }


  ngOnInit(): void {
    this.translate.get([
      'upwardFeedback.peopleAppraisal.table.TO_HELP',
      'upwardFeedback.peopleAppraisal.table.COMPLETED',
      'upwardFeedback.peopleAppraisal.table.NOT_SENT',
      'upwardFeedback.peopleAppraisal.table.SEND',
      'upwardFeedback.peopleAppraisal.table.DETAIL',
    ]).subscribe((translations) => {
      this.translations = translations;
      this.getRoundList();
    })
  }


  // Recupera una lista di utenti
  getRoundList() {
    this.isLoadingRoundList = true;

    this.peopleAppraisalService.getUpwardFeedbackRoundsForManager(undefined, undefined, true)
      .subscribe((data: any) => {
        if (data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "upfl004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.roundList = [];
          if (data.response && data.response.length > 0) {
            // Prendere il primo round in corso se c'è
            for (let i = 0; i < data.response.length; i++) {
              let round = data.response[i];
              this.roundList.push({
                id: round.roundId,
                name: round.name,
                roundStatus: round.roundStatus,
                startDate: round.startDate
              })
            }
            if (this.roundList && this.roundList.length > 0) {
              let runningRound = this.roundList.find(round => round.roundStatus == 'RUNNING');
              // se c'è un round in corso seleziono quello, così se ho il badge novità in home, accedo al round corretto
              if (runningRound) {
                this.selectedRound = runningRound;
              } else {
                this.selectedRound = this.roundList[0];
              }
              this.getTeamData();
            }
          }
        }
        this.isLoadingRoundList = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upfl005",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundList = false;
        }
        return throwError(new Error(err.message));
      })
  }


  // Recupero la lista di utenti per il round
  getTeamData() {
    this.isLoadingTeamData = true;

    if (this.getTeamData$) {
      this.getTeamData$.unsubscribe();
    }

    this.getTeamData$ = this.peopleAppraisalService.getUpwardFeedbackTeamList(this.selectedRound?.id || '')
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackTeamForManager>) => {
        if (data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "upfl006",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.teamData = [];
          if (data.response && data.response.length > 0) {
            for (let i = 0; i < data.response.length; i++) {
              let userData = data.response[i];
              let status = {
                statusType: "",
                statusMessage: ""
              };
              let actionDisabled = false;
              let actionText = this.translations['upwardFeedback.peopleAppraisal.table.DETAIL'];
              if (userData.alreadyCommented) {
                status.statusType = 'completed';
                status.statusMessage = this.translations['upwardFeedback.peopleAppraisal.table.COMPLETED'];
              } else if (!userData.alreadyCommented && userData.resultAvailable) {
                status.statusType = 'inProgress';
                status.statusMessage = this.translations['upwardFeedback.peopleAppraisal.table.TO_HELP'];
                actionText = this.translations['upwardFeedback.peopleAppraisal.table.SEND'];
                if (userData.isRoundClosed) {
                  actionDisabled = true;
                }
              } else {
                status.statusType = 'disabled';
                status.statusMessage = this.translations['upwardFeedback.peopleAppraisal.table.NOT_SENT'];
                actionDisabled = true;
              }

              this.teamData.push({
                user: userData.user,
                statusType: status.statusType,
                statusMessage: status.statusMessage,
                actionDisabled: actionDisabled,
                actionText: actionText,
                dateShared: userData.dateShared
              })
            }
          }
        }
        this.isLoadingTeamData = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upfl007",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundList = false;
        }
        return throwError(new Error(err.message));
      })
  }

  changeRound(roundItem: { id: string, name: string }) {
    let newRound = this.roundList.find(round => round.id == roundItem.id);
    if (newRound) {
      this.selectedRound = newRound
    } else {
      this.selectedRound = this.roundList[0];
    }
    this.getTeamData();
  }

  // vai ai risultati
  goToResults(userId: string) {
    this.redirectService.goToUpwardFeedbackPeopleAppraisal(this.selectedRound?.id || '', userId);
  }


  ngOnDestroy() {
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
  }
}
