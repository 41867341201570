import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { SenecaResponse, ListPrivateNoteFeedbackForUserResponse, parseBoolean } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { TabDossier } from 'src/app/utils/classes.utils';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { ParseUtils, PerfAlloyAssessmentPhaseKeys, PerfAlloyUserGoal, PerfAlloyYearCodes, RentStatusTypes } from 'atfcore-commonclasses';
import { GuidedTour, GuidedTourService, Orientation, TourStep } from 'ngx-guided-tour';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RoundAverage } from 'src/app/utils/round-average.utils'

@Component({
  selector: 'app-selfAssessment-personDetails',
  templateUrl: './personDetails.component.html',
  styleUrls: ['./personDetails.component.scss']
})
export class SelfAssessmentPersonDetailsComponent implements OnInit, OnDestroy {
  personDetails: any;
  routeParams$!: Subscription;
  userId: string = '';
  runningYear$: any;
  loggedUser$: any;
  runningYear: any;

  getUserProfileTab$!: Subscription;
  currentContentTab!: TabDossier;
  competenceFeedbackChars: number = 600;
  maxTextAreaChars: number = 600;

  getRunningPhase$: Subscription = new Subscription();
  runningPhase: any;

  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  // Variabili gestione stepper
  stepList: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean }[] = [];
  currentStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } | any = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };

  toEvaluateList: any[] = []
  isLoadingToEvaluateGoals: boolean = false;
  toEvaluateListCopy: any;

  behaviorComments: string = '';

  insertByUser: string = '';
  insertByManager: string = '';


  starsModel: any = {
    5: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      },
      {
        id: 3,
        isClicked: false
      },
      {
        id: 4,
        isClicked: false
      }
    ],
    3: [
      {
        id: 0,
        isClicked: false
      },
      {
        id: 1,
        isClicked: false
      },
      {
        id: 2,
        isClicked: false
      }
    ]
  }
  techSkillRatingScale: any;

  techSkills: any = []
  techSkillComment: string = '';

  newObjectiveList: { id: string, title: string, isEdit?: boolean, isSelected?: boolean, tmpId?: string, creationUser?: { forename: string, surname: string, userId: string } }[] = [];
  isLoadingDefinedObjectives: boolean = false;
  objectiveToDelete: any;
  selectedObjective: any;

  isPostCalibration: boolean = false;

  testString: string = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt non nunc pretium placerat. Suspendisse quis efficitur purus, ut auctor enim. Phasellus venenatis venenatis egestas. Curabitur metus ante, bibendum sit amet lacus quis, rutrum mollis ipsum. Morbi vehicula dapibus metus, eu tristique enim interdum id.'
  testRating = [
    {
      id: 0,
      isChecked: true
    },
    {
      id: 1,
      isChecked: true
    },
    {
      id: 2,
      isChecked: true
    },
    {
      id: 3,
      isChecked: false
    },
    {
      id: 4,
      isChecked: false
    }
  ]
  testRatingValue = '3,60';

  recapDevelopmentDescription: any;
  isNoteSection: boolean = false;
  translations: any;
  loggedUser: any;
  competenceForModal: any;

  selectedInfoTabPerformerKey: string | undefined = '';
  hrRemarks: string = 'Sed scelerisque nisl laoreet ex aliquam, sed scelerisque lorem rutrum. Donec bibendum neque a rhoncus tristique. Vestibulum in felis risus. Pellentesque euismod lectus ac sem faucibus rutrum. Proin tincidunt ipsum risus, vitae porttitor odio gravida ut. Maecenas ut purus dolor.';
  noteIdToDelete: string = '';
  isLoadingPrivateNotes: boolean = false;
  privateNotesList: any;
  getPrivateNotesList$: Subscription = new Subscription();
  isPeopleAppraisal: any;
  noDataPlaceholder: string = '';
  noDataImage: string = '';
  isLoadingPersonDetails: boolean = false;
  isLoadingTab: boolean = true;
  collaboratorService: any;
  getUserGoalToEvaluate$: Subscription = new Subscription();
  getUserGoalToDefine$: Subscription = new Subscription();
  isLoadingUserGoalToDefine: boolean = true;
  createOrUpdatePerformanceAssessmentGoals$: Subscription = new Subscription();
  deletePerformanceAssessmentGoals$: Subscription = new Subscription();

  intro!: GuidedTour;
  steps?: TourStep[];
  isLoadingBehaviours: boolean = false;
  getBehavioursToEvaluate$: Subscription = new Subscription();
  behaviorCommentsCopy: string = '';
  parentBehaviourIds: any;
  competenceArray: any[] = [];

  techSkillCommentCopy: string = '';
  isLoadingTechSkills: boolean = false;
  getCompetenceToEvaluateForSubordinate$: Subscription = new Subscription;
  skipObjectiveCheck: boolean = false;
  isLoadingFinalEvaluationData: boolean = false;
  getFinalEvaluationData$: Subscription = new Subscription();
  finalEvaluation: any = {};
  managerFinalEvaluation: any = {};
  tempStep: any;
  newObjectiveListCopy: { id: string; title: string; isEdit?: boolean | undefined; isSelected?: boolean | undefined; tmpId?: string | undefined; }[] = [];
  textAreaActions: any[] = [];

  isScheduleShared: boolean = false;
  editFinalEvaluationComment: boolean = false;
  editBehaviorComment: boolean = false;
  editCompetenceComment: boolean = false;
  objectiveToDeleteFromEdit: boolean | undefined;
  isFinalStepCompleted: boolean = false;
  managerData: any;
  isManagement: any;
  yearsWithSpecialisticGoalsToEvaluate: boolean = false;
  isIT: boolean = false;
  forcePasStructureTutorial: any;
  forcePasTutorialStep: { id: string; referenceId: string; number: number; stepId: number; title: any; isWaiting?: boolean, isComplete?: boolean } = { id: '', referenceId: '', number: 0, stepId: 0, title: '' };
  runningPhaseEndDate: any;
  isHistory: boolean = false;
  finalEvaluationCommentCopy: any;
  finalEvaluationBehaviourCommentCopy: any;
  finalEvaluationDataCopy: any;
  finalEvaluationCompetenceCommentCopy: any;
  finalDefinedObjectivesCopy: any;
  finalDefinedObjectivesToDelete: any;
  behaviourCompetenceToChange: any;

  objectivesToDelete: any[] = [];
  competenceArrayCopy: any;

  techSkillArray: any;
  techSkillArrayCopy: any;
  techSkillParentBehaviourIds: any;
  techSkillCompetenceToChange: any;
  techSkillsFinalAvg: any;
  softSkillsFinalAvg: any;
  techSkillsCopy: any;

  // Fa parte della valutazione leadership team
  isLeadershipTeam: boolean = false;
  leadershipTeamData: any;
  leadershipTeamComment: string = '';
  leadershipTeamCommentCopy: string = '';
  createOrUpdatePerLeadershipSkill$: Subscription = new Subscription;
  // dati obiettivi modali
  editObjectiveData: any;
  objectiveToApprove: any;

  titlesRightSection = {
    left: 'Tua valutazione',
    right: 'People appraisal'
  }
  isInCompletedBehaviours: boolean = false;
  strongPointTooltip!: ApplicationModalMessage;
  developmentAreaTooltip!: ApplicationModalMessage;
  finalEvaluationOwnObservationCopy: any;
  goalsAverage: any;
  processIsBeforeH2023 = false;
  processIsBeforeH2023Leadership = false;
  isLoaderStep = false;
  insightFeedbackData: any;

  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private selfAssessmentService: SelfAssessmentService,
    private router: Router,
    private modalService: ModalService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private analyticsService: AnalyticsService) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Scheda utente - SelfAssessment");
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    const openPersonalObjectiveEvaluationTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');
    this.forcePasStructureTutorial = openPasStructureTutorial || openSetGoalsTutorial || openTechSkillEvaluationTutorial || openOrganizationalBehaviorsTutorial || openPersonalObjectiveEvaluationTutorial;
    this.forcePasStructureTutorial = parseBoolean(this.forcePasStructureTutorial);

  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        "modals.CHALLENGE.TITLE",
        "modals.CHALLENGE.SUBTITLE",
        "modals.CHALLENGE.TEXT",
        "modals.ROLE.TITLE",
        "modals.ROLE.TEXT",
        "peopleAppraisalOperations.CARE_TITLE",
        "peopleAppraisalOperations.SHARED_COMPETENCE",
        'peopleAppraisalOperations.SHARED_COMPETENCE',
        "modals.OPERATIONS.TITLE",
        "modals.OPERATIONS.SUBTITLE",
        "modals.OPERATIONS.TEXT",
        'accordion.GUIDELINES_STI',
        "performance.ACCORDION_TITLE",
        "performance.ACCORDION_TOOLTIP",
        "performance.statuses.TO_DEFINE",
        "performance.statuses.TO_CONFIRM",
        "performance.statuses.COMPLETED",
        "calibration.statuses.TO_CONFIRM",
        'finalEvaluation.ASSIGNED_LIST_1',
        'finalEvaluation.ASSIGNED_LIST_2',
        "stepper.OBJECTIVE_EVALUATION",
        "stepper.BEHAVIOUR_EVALUATION",
        "stepper.TECH_SKILL_EVALUATION",
        "stepper.PROPOSE_OBJECTIVES_SELF",
        "stepper.PROPOSE_SPECIALIST_NEW_OBJECTIVES",
        "stepper.FINAL_EVALUATION",
        "stepper.VERIFY_TECH_OBJECTIVES",
        "stepper.DEFINE_TECH_OBJECTIVES",
        "objectiveEvaluation.MODAL_TITLE",
        "objectiveEvaluation.MODAL_SUB",
        "objectiveEvaluation.MODAL_TEXT",
        "generic.NO_COMMENT_INSERTED",
        "generic.SAVE",
        "generic.DELETE",
        "stepper.TECH_OBJECTIVES",
        'finalEvaluation.SELF_ASSIGNED_LIST_1',
        'finalEvaluation.SELF_ASSIGNED_LIST_2',
        'finalEvaluation.modals.DELETE_DESCR_1',
        'finalEvaluation.modals.DELETE_DESCR_2',
        'finalEvaluation.modals.APPROVE_DESCR_1',
        'finalEvaluation.modals.APPROVE_DESCR_2',
        'finalEvaluation.modals.SUGGESTION',
        'finalEvaluation.modals.SUGGESTION_SUB',
        'finalEvaluation.modals.STRONG_DESCR_SELF',
        'finalEvaluation.modals.DEVELOPMENT_DESC_SELF',
        "stepper.DEFINE_OBJECTIVES"
      ])
      .subscribe(translations => {
        this.translations = translations;

        this.isLoadingPersonDetails = true;

        this.selectedObjective = this.toEvaluateList[0]

        this.strongPointTooltip = {
          modalId: 'str001',
          title: this.translations['finalEvaluation.modals.SUGGESTION'],
          subtitle: this.translations['finalEvaluation.modals.SUGGESTION_SUB'],
          text: this.translations['finalEvaluation.modals.STRONG_DESCR_SELF']
        }

        this.developmentAreaTooltip = {
          modalId: 'deva001',
          title: this.translations['finalEvaluation.modals.SUGGESTION'],
          subtitle: this.translations['finalEvaluation.modals.SUGGESTION_SUB'],
          text: this.translations['finalEvaluation.modals.DEVELOPMENT_DESC_SELF']
        }

        this.textAreaActions = [
          {
            id: 'delete',
            label: "generic.DELETE",
            class: 'secondary'
          },
          {
            id: 'save',
            label: "generic.SAVE",
            class: 'primary'
          }
        ];

        this.getRunningPhase$ = this.store.select(fromApp.getRunningPhase).subscribe((runningPhase) => {
          this.runningPhase = runningPhase;
        })

        // Salvo l'anno corrente
        this.runningYear$ = this.store.select(fromApp.getRunningYear)
          .subscribe((runningYear) => {
            this.runningYear = runningYear;
            if (this.runningYear) {
              this.routeParams$ = this.route.params
                .subscribe(
                  (params: Params) => {
                    // Se ho cambiato utente (dalla sidebar) il componente non entrerà nell'onDestroy; quindi devo eliminare qui ogni referenza all'apertura automatica del tutorial dal session storage
                    if (this.userId && params.userId && this.userId !== params.userId) {
                      this.removeTutorialsRefFromSessionStorage();
                    }

                    this.userId = params.userId;

                    if (params.perfYear) {
                      this.selectedYear = {
                        id: params.perfYear,
                        code: params.perfYearCode,
                        name: params.perfYearCode + ' ' + params.perfYear
                      }

                    } else {
                      this.selectedYear = {
                        id: 2021,
                        name: 'H1 ' + 2021
                      }
                    }

                    if (this.runningYear && this.runningYear.year != 0 && !(this.selectedYear.id.toString() == this.runningYear.year.toString() && this.selectedYear.code == this.runningYear.yearCode)) {
                      this.isHistory = true;
                    } else {
                      this.isHistory = false;
                    }

                    this.isNoteSection = window.location.href.indexOf('myNotes') > -1;

                    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
                      if (loggedUser && loggedUser.user) {
                        this.loggedUser = loggedUser && loggedUser.user;
                        if (this.selectedYear && loggedUser.params.yearsWithLeadershipTeam) {
                          let leadershipYear = loggedUser.params.yearsWithLeadershipTeam.find((data: any) => data.year == this.selectedYear.id && data.yearCode == this.selectedYear.code);
                          if (leadershipYear) {
                            this.isLeadershipTeam = true;
                          }
                        }

                        this.isLoadingPersonDetails = false;
                        this.isThisSpecialTechYear(loggedUser?.params?.yearsWithSpecialisticGoalsToEvaluate);
                        this.getPerformanceAssessmentYears();
                      }
                    })
                  })
            }
          });
      })
  };

  isThisSpecialTechYear(techYears: any) {
    if (techYears && techYears.length) {
      for (let i = 0, yearsLength = techYears.length; i < yearsLength; i++) {
        const currentSpecialYear = techYears[i];

        if (currentSpecialYear.year && currentSpecialYear.year === this.selectedYear.id.toString() && currentSpecialYear.yearCode === this.selectedYear.code) {
          this.yearsWithSpecialisticGoalsToEvaluate = true;
          break;
        }
      }
    }
  }

  // Cambio scheda header dossier
  changeButtonHeaderDossier(id: string) {
    switch (id) {
      case 'pas':
        this.router.navigate(['/selfAssessment/personDetails/', this.userId, this.selectedYear.id, this.selectedYear.code])
        break;
      case 'notes':
        this.router.navigate(['/selfAssessment/personDetails/myNotes/', this.userId, this.selectedYear.id, this.selectedYear.code])
        break;
    }
  }

  // Ritorna le tab con numero massimo obiettivi e peso
  getUserProfileTab(updateOnly?: boolean) {
    if (!updateOnly) {
      this.isLoadingTab = true;
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }

    this.isPostCalibration = false;
    this.processIsBeforeH2023 = ((this.selectedYear.id < 2023 || this.selectedYear.id <= 2023 && this.selectedYear.code == 'H1') && !this.isLeadershipTeam) ? true : false;
    this.processIsBeforeH2023Leadership = ((this.selectedYear.id < 2023 || this.selectedYear.id <= 2023 && this.selectedYear.code == 'H1') && this.isLeadershipTeam) ? true : false;
    this.getUserProfileTab$ = this.selfAssessmentService.getUserPhaseDataForSubordinate(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && (data.error || !data.response)) {
          this.getUserProfileTab$.unsubscribe();
          if (window.location.href.indexOf('personDetails') > -1) {
            this.redirectService.goToErrorPage(data && data.error);
          }
        } else {
          let stepNumber = 0;
          this.stepList = [];
          let tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.isIT = tempStep?.isIT || false;
            this.stepList.push(
              {
                id: 'objectiveEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.isIT ? this.translations["stepper.VERIFY_TECH_OBJECTIVES"] : this.translations["stepper.OBJECTIVE_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED || tempStep.statusType == "SYSTEM_COMPLETED"
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.BEHAVIORS_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'behaviourEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.BEHAVIOUR_EVALUATION"],
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.COMPETENCES_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            if (this.yearsWithSpecialisticGoalsToEvaluate) {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: stepNumber,
                  title: this.translations["stepper.TECH_OBJECTIVES"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            } else {
              this.stepList.push(
                {
                  id: 'techEvaluation',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: stepNumber,
                  title: this.translations["stepper.TECH_SKILL_EVALUATION"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            }
          } else {
            this.isManagement = true;
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.GOAL_DEFINITION)
          if (tempStep) {
            stepNumber += 1;
            if (this.yearsWithSpecialisticGoalsToEvaluate || this.isIT) {
              let title;
              if (this.isIT) {
                title = this.translations["stepper.DEFINE_TECH_OBJECTIVES"]
              } else {
                title = (this.selectedYear.id < 2023) ? this.translations["stepper.DEFINE_OBJECTIVES"] : this.translations["stepper.DEFINE_TECH_OBJECTIVES"];
              }
              this.stepList.push(
                {
                  id: 'proposeObjectives',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: stepNumber,
                  title: title,
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            } else {
              this.stepList.push(
                {
                  id: 'proposeObjectives',
                  referenceId: tempStep.referenceId,
                  number: stepNumber,
                  stepId: stepNumber,
                  title: this.translations["stepper.PROPOSE_OBJECTIVES_SELF"],
                  isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
                }
              )
            }
          }
          tempStep = data.response.find((step: any) => step.phaseKey == PerfAlloyAssessmentPhaseKeys.FINAL_EVALUATION)
          if (tempStep) {
            stepNumber += 1;
            this.stepList.push(
              {
                id: 'finalEvaluation',
                referenceId: tempStep.referenceId,
                number: stepNumber,
                stepId: stepNumber,
                title: this.translations["stepper.FINAL_EVALUATION"],
                isWaiting: tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM,
                isComplete: tempStep.statusType == RentStatusTypes.COMPLETED
              }
            )
            this.isScheduleShared = tempStep.statusType == RentStatusTypes.SHARED_FINAL_FORM;
            this.isFinalStepCompleted = tempStep.statusType == RentStatusTypes.COMPLETED;
            this.isPostCalibration = tempStep.statusType == 'RECEIVED_FINAL_FORM';
          }
          this.hasToOpenTutorial();
          if (!updateOnly && !this.forcePasStructureTutorial) {
            for (let i = 0; i < this.stepList.length; i++) {
              if (!this.stepList[i].isComplete) {
                this.currentStep = this.stepList[i];
                break;
              }
            }
            if (!this.currentStep.stepId) {
              this.currentStep = this.stepList.find((step: any) => step.id == 'finalEvaluation');
            }
          } else if (this.forcePasStructureTutorial) {
            // Nel caso del tutorial della valutazione obiettivi bisogna prima caricare i dati, quindi passo direttamente lo step anche se non è stato preselezionato
            if (!this.forcePasTutorialStep.id) {
              this.currentStep = this.stepList[0];
            } else {
              this.currentStep = this.forcePasTutorialStep;
            }
          }
        }
        if (!updateOnly && !this.forcePasStructureTutorial && this.currentStep) {
          this.onStepClicked(this.currentStep, this.forcePasStructureTutorial === "true");
        }
        this.isLoadingTab = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "112",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTab = false;
      });
  }

  // Cambio tab
  onContentTabChanged(tab: TabDossier) {
    this.currentContentTab = tab;
  }

  // Cambio step
  onStepClicked(step: any, skipCheck?: boolean) {
    if (this.isLoaderStep) {
      return;
    }
    this.isLoaderStep = true;
    this.tempStep = step;
    if (!skipCheck && this.currentStep.id == 'objectiveEvaluation' && this.anyEvaluationChangesToSave()) {
      this.modalService.open('save-objective-modal');
    } else if (!skipCheck && this.currentStep.id == 'proposeObjectives' && this.anyBehaviourEvaluationChangesToSave()) {
      this.modalService.open('save-behaviours-modal');
    } else if (!skipCheck && this.currentStep.id == 'behaviourEvaluation' && this.anyTechSkillEvaluationChangesToSave()) {
      this.modalService.open('save-tech-skills-modal');
    } else if (!skipCheck && this.currentStep.id == 'proposeObjectives' && this.anyDefineObjectiveChangesToSave()) {
      this.modalService.open('save-defined-objective-modal');
    } else if (!skipCheck && this.currentStep.id == 'finalEvaluation' && this.anythingToSaveInFinalEvaluation()) {
      this.modalService.open('save-final-evaluation-modal');
    } else {
      this.currentStep = step;
      if (this.currentStep.id == 'objectiveEvaluation') {
        this.getUserGoalListToEvaluate();
      } else if (this.currentStep.id == 'behaviourEvaluation') {
        this.getBehavioursToEvaluateForSubordinate();
      } else if (this.currentStep.id == 'techEvaluation') {
        this.getCompetenceToEvaluateForSubordinate();
      } else if (this.currentStep.id == 'proposeObjectives') {
        this.getUserGoalListToDefine();
      } else if (this.currentStep.id == 'finalEvaluation') {
        this.getFinalEvaluationData()
      }
    }

    setTimeout(() => {
      this.isLoaderStep = false;
    }, 1000);
  }

  closeSaveObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveObjectiveEvaluation();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.stepId == 1);
      }
    }
  }

  closeSaveBehavioursModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-behaviours-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveBehavioursRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 2);
      }
    }
  }

  closeSaveTechSkillsModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-tech-skills-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveTechSkillsRatings();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 3);
      }
    }
  }

  anyDefineObjectiveChangesToSave() {
    if (this.newObjectiveList && this.newObjectiveListCopy) {
      if (this.newObjectiveList.length != this.newObjectiveListCopy.length) {
        return true;
      } else {
        for (let i = 0; i < this.newObjectiveList.length; i++) {
          if (this.newObjectiveList[i].title != this.newObjectiveListCopy[i].title) {
            return true;
          }
        }
      }
    }
    return false;
  }

  closeSaveDefinedObjectivesModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-defined-objective-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveDefinedObjective();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.stepId == 4);
      }
    }
  }

  closeSaveFinalEvaluationModal(confirm: boolean, closeOnly?: boolean) {
    this.modalService.close('save-final-evaluation-modal');
    if (!closeOnly) {
      if (confirm) {
        this.saveFinalEvaluationStep();
      } else {
        this.currentStep = this.tempStep;
        this.onStepClicked(this.tempStep, this.tempStep.number == 5);
      }
    }
  }


  getEvaluationYear() {
    let currentIndex = this.yearList.findIndex((yearItem: any) => yearItem.id.toString() == this.selectedYear.id.toString() && yearItem.code == this.selectedYear.code);
    // Devo permettere di recuperare al massimo 2 semestri fa, altrimenti torno sempre l'anno in corso
    // se sono nel semestre H1 posso recuperare H1 o H2 dell'anno prima
    // se sono nel semestre H2 posso recuperare H1 dello stesso anno o H2 dell'anno prima
    if (currentIndex - 1 >= 0 && !!this.yearList[currentIndex - 1] &&
      (
        (this.selectedYear.code == "H1" && parseInt(this.yearList[currentIndex - 1].id) == parseInt(this.selectedYear.id) - 1) ||
        (this.selectedYear.code == "H2" &&
          (
            (this.yearList[currentIndex - 1].code == "H1" && parseInt(this.yearList[currentIndex - 1].id) == parseInt(this.selectedYear.id)) ||
            (this.yearList[currentIndex - 1].code == "H1" && parseInt(this.yearList[currentIndex - 1].id) <= (parseInt(this.selectedYear.id) - 1)) ||
            (this.yearList[currentIndex - 1].code == "H2" && parseInt(this.yearList[currentIndex - 1].id) <= (parseInt(this.selectedYear.id) - 1))
          )
        )
      )) {
      return this.yearList[currentIndex - 1]
    } else {
      return this.yearList[currentIndex];
    }
  }

  openCompetenceDictionaryModal(competence: any) {
    this.competenceForModal = competence;
    this.competenceForModal.isBehaviour = this.currentStep && this.currentStep.id == 'behaviourEvaluation';
    this.modalService.open("competence-dictionary");
  }

  closeCompetenceDictionaryModal() {
    this.modalService.close("competence-dictionary");
  }

  // generica per i commenti nelle textarea, prende testo e variabile dove salvarlo
  onTextAreaChanged(text: string, object: string) {
    object = text
  }

  showSaveSuccessfulToaster() {
    let x: any = document.getElementById("snackbarPersonDetails");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }

  // Definizione obiettivi
  // Aggiungi nuovo obiettivo
  addNewObjective() {
    this.newObjectiveList.push({
      tmpId: this.newObjectiveList.length.toString(),
      id: '',
      title: '',
      isEdit: false
    })
  }


  canSaveDefinedObjectives() {
    if (this.newObjectiveList && this.newObjectiveList.length) {
      for (let i = 0; i < this.newObjectiveList.length; i++) {
        if (!this.newObjectiveList[i].title || !this.newObjectiveList[i].title.trim().length) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  // salva gli obiettivi aggiunti
  saveDefinedObjective() {
    this.isLoadingDefinedObjectives = true;
    /* for (let i = 0; i < this.newObjectiveList.length; i++) {
       this.newObjectiveList[i].id = 'saved' + (this.newObjectiveList.length + i);
       this.newObjectiveList[i].isEdit = false;
     }*/

    let goals = [];
    for (let i = 0; i < this.newObjectiveList.length; i++) {
      goals.push({
        goalId: this.newObjectiveList[i].id,
        text: this.newObjectiveList[i].title
      });
    }

    if (this.createOrUpdatePerformanceAssessmentGoals$) {
      this.createOrUpdatePerformanceAssessmentGoals$.unsubscribe();
    }
    this.createOrUpdatePerformanceAssessmentGoals$ = this.selfAssessmentService.createOrUpdatePerformanceAssessmentGoals(this.userId, goals)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (this.newObjectiveList && this.newObjectiveList.length) {
            this.newObjectiveList.length = 0;
          }

          this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];

          this.getUserGoalListToDefine();
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);

          // Vado allo step successivo
          this.goToNextStep();
        }
        this.isLoadingDefinedObjectives = false;
      }, (err?: any) => {
        this.isLoadingDefinedObjectives = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm012",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  deleteObjective(objective: any) {
    // momentaneo per testare
    if (!objective.id && objective.tmpId) {
      // Se non ha un id, significa che non è ancora stato salvato, quindi mi baso sul tmpId
      this.newObjectiveList = this.newObjectiveList.filter((x: any) => x.tmpId != objective.tmpId);
    } else {
      // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
      if (this.deletePerformanceAssessmentGoals$) {
        this.deletePerformanceAssessmentGoals$.unsubscribe();
      }
      this.deletePerformanceAssessmentGoals$ = this.selfAssessmentService.deletePerformanceAssessmentGoals(this.userId, [objective.id])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm013",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (this.newObjectiveList && this.newObjectiveList.length) {
              this.newObjectiveList.length = 0;
            }
            this.showSaveSuccessfulToaster();
            if (this.currentStep.stepId == this.stepList[this.stepList.length - 1].stepId) {
              this.getFinalEvaluationData();
            } else {
              this.getUserGoalListToDefine();
            }
            this.getUserProfileTab(true);
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm014",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  onObjectiveTitleChanged(text: string, objective: any) {
    objective.title = text
  }

  onMenuOptionClicked(option: string, objective: any) {
    if (option == 'edit') {
      objective.isEdit = true;
    } else {
      if (!objective.id && !objective.isEdit) {
        this.deleteObjective(objective);
      } else {
        this.openDeleteSavedObjective(objective);
      }
    }
  }

  openDeleteSavedObjective(objective: any, fromEdit?: boolean) {
    this.objectiveToDelete = objective;
    this.objectiveToDeleteFromEdit = fromEdit;
    this.modalService.open('confirm-delete-saved-objective');
  }

  closeDeleteSavedObjective(confirm?: boolean) {
    this.modalService.close('confirm-delete-saved-objective');
    if (confirm) {
      this.isLoadingDefinedObjectives = true;
      this.deleteObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
      setTimeout(() => { this.isLoadingDefinedObjectives = false; }, 2000);
    }
  }

  // Apre la modale con la spiegazione dei performer
  openPerformerModal(performerKey?: string) {
    this.selectedInfoTabPerformerKey = performerKey;
    this.modalService.open("performerEvaluatorInfoModal");
  }

  // Chiude la modale con la spiegazione dei performer
  closePerformerModal() {
    this.modalService.close("performerEvaluatorInfoModal");
    this.selectedInfoTabPerformerKey = '';
  }

  selectObjectiveFinalEvaluation(objective: any) {
    this.selectedObjective = objective;
  }


  getPrivateNotesList() {
    this.isLoadingPrivateNotes = true;
    this.privateNotesList = [];

    if (this.getPrivateNotesList$) {
      this.getPrivateNotesList$.unsubscribe();
    }

    this.getPrivateNotesList$ = this.selfAssessmentService.getPrivateNotesList(this.selectedYear.id, this.selectedYear.code)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response) {
          for (let i = 0; i < data.response.length; i++) {
            this.privateNotesList.push({
              noteId: data.response[i].itemId,
              comment: data.response[i].comment,
              motivation: data.response[i].motivation,
              formattedCreationDate: moment(data.response[i].creationDate).fromNow()
            })
          }
        }
        this.isLoadingPrivateNotes = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn015",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPrivateNotes = false;
        })
  }

  openDeleteNoteModal(id: string) {
    this.noteIdToDelete = id;
    this.modalService.open('deleteNoteModal');
  }

  closeDeleteNoteModal(confirmDelete?: boolean) {
    this.modalService.close('deleteNoteModal');
    if (confirmDelete) {
      this.isLoadingPrivateNotes = true;
      this.selfAssessmentService.deletePrivateNote(this.selectedYear.id, this.selectedYear.code, this.noteIdToDelete)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn023",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn026",
              title: this.translate.instant("privateNotes.modals.NOTE_DELETED"),
              text: this.translate.instant("privateNotes.modals.NOTE_DELETED_DESCR")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.getPrivateNotesList();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "pn024",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  goToEditPrivateNote(noteId: string) {
    this.redirectService.goToCreatePrivateNote(noteId);
  }


  createPrivateNote() {
    this.redirectService.goToCreatePrivateNote();
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;
    this.isLoadingTab = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    this.getYearsList$ = this.selfAssessmentService.getPerformanceAssessmentYears(this.userId)
      .subscribe((data: SenecaResponse<any[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
          this.isLoadingTab = false;
        } else {
          this.yearList = (data.response || []).map((yearData: { assessmentYear: number, assessmentYearCode: string, isInProcess: boolean }) => ({
            id: yearData.assessmentYear,
            code: yearData.assessmentYearCode,
            name: yearData.assessmentYearCode + ' ' + yearData.assessmentYear,
            disabled: !yearData.isInProcess
          }));
          //  this.getPeopleAppraisalData();
          this.getUserProfileTab();
          // Sono nella sezione delle note, quindi devo recuperarle
          this.getPrivateNotesList();
          this.isLoadingYearList = false;
        }
      }, (err?: any) => {
        this.isLoadingTab = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    let url = this.router.url.split(('/' + previous))[0];
    this.isLeadershipTeam = false;
    this.router.navigate([url, this.selectedYear.id, this.selectedYear.code])
  }


  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  // Recupera gli obiettivi da definire
  getUserGoalListToDefine() {
    this.isLoadingUserGoalToDefine = true;

    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }

    this.newObjectiveList = [];
    this.getUserGoalToDefine$ = this.selfAssessmentService.getUserGoalList(this.selectedYear.id, this.selectedYear.code)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm015",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          for (let m = 0, resLength = data.response.length; m < resLength; m++) {
            const currentRes = data.response[m];
            this.newObjectiveList.push({
              id: currentRes.goalId,
              title: currentRes.text,
              creationUser: currentRes.creationUser
            });
          }
        }
        this.hasToOpenTutorial();
        this.newObjectiveListCopy = this.newObjectiveList.length ? JSON.parse(JSON.stringify(this.newObjectiveList)) : [];
        if (this.currentStep.isComplete == true) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.isLoadingUserGoalToDefine = false;
      }, (err?: any) => {
        this.isLoadingUserGoalToDefine = false;
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm016",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  getUserGoalListToEvaluate(isFinalEvaluation?: boolean) {
    this.isLoadingToEvaluateGoals = true;
    if (this.getUserGoalToEvaluate$) {
      this.getUserGoalToEvaluate$.unsubscribe();
    }
    let serviceYear = this.getEvaluationYear();
    this.getUserGoalToEvaluate$ = this.selfAssessmentService.getUserGoalListToEvaluate(serviceYear.id, serviceYear.code)
      .subscribe((data: SenecaResponse<PerfAlloyUserGoal[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else {
          this.toEvaluateList = []
          for (let i = 0; i < data.response.length; i++) {
            this.toEvaluateList.push({
              ...data.response[i],
              isSelected: false
            })

            if (this.toEvaluateList[i].evaluationVote === undefined)
              this.toEvaluateList[i].evaluationVote = 0;
          }


          if (isFinalEvaluation && this.toEvaluateList && this.toEvaluateList.length) {
            this.selectedObjective = this.toEvaluateList[0];
          }
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          if (this.currentStep.isComplete == true) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          }
          this.isLoadingToEvaluateGoals = false;
        }
        // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
        this.hasToOpenTutorial(true);
        // aggiungere tutorial this.checkPassStructureTutorial();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  openHelpModal(stepId: string) {
    switch (stepId) {
      case 'objectiveEvaluation':
        const messageObjEva: ApplicationModalMessage = {
          modalId: "infoObjEv",
          title: this.translations["objectiveEvaluation.MODAL_TITLE"],
          subtitle: this.translations["objectiveEvaluation.MODAL_SUB"],
          text: this.translations["objectiveEvaluation.MODAL_TEXT"],
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObjEva }));
        break;
      case 'techEvaluation':
        this.modalService.open('competence-dictionary');
        break;
      default:
        break;
    }
  }

  anyEvaluationChangesToSave() {
    if (this.toEvaluateList && this.toEvaluateList.length && this.toEvaluateListCopy.length) {
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        if (this.toEvaluateList[i].evaluation != this.toEvaluateListCopy[i].evaluation) {
          this.skipObjectiveCheck = false;
          return true;
        }

        if (this.isIT) {
          if (this.toEvaluateList[i].evaluationVote != this.toEvaluateListCopy[i].evaluationVote) {
            this.skipObjectiveCheck = false;
            return true;
          }
        }
      }
    }
    return false;
  }

  // Da aggioranre passando l'oggetto dell'obiettivo
  onEvaluationChanged(text: string, objective: any) {
    objective.evaluation = text
  }

  onITRatingVoteChanged(value: any, objective: any) {
    objective.evaluationVote = Number(value);
  }

  // Salva la valutazione degli obiettivi 
  saveObjectiveEvaluation() {
    this.isLoadingToEvaluateGoals = true;
    let evaluationForService: { goalId: string, evaluationText: string, evaluationVote?: number }[] = [];
    if (this.toEvaluateList && this.toEvaluateList.length) {
      for (let i = 0; i < this.toEvaluateList.length; i++) {
        evaluationForService.push({
          goalId: this.toEvaluateList[i].goalId,
          evaluationText: this.toEvaluateList[i].evaluation?.trim()
        })

        if (this.isIT) {
          evaluationForService[i].evaluationVote = this.toEvaluateList[i].evaluationVote;
        }
      }
    }

    this.selfAssessmentService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
      .subscribe((data: SenecaResponse<boolean>) => {
        this.isLoadingToEvaluateGoals = false;
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.toEvaluateListCopy = JSON.parse(JSON.stringify(this.toEvaluateList));
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
          this.goToNextStep();
        }
      })
  }

  getBehavioursToEvaluateForSubordinate() {
    this.behaviorComments = '';
    this.behaviorCommentsCopy = '';
    this.behaviourCompetenceToChange = null;
    this.softSkillsFinalAvg = null;
    this.isLoadingBehaviours = true;

    if (this.getBehavioursToEvaluate$) {
      this.getBehavioursToEvaluate$.unsubscribe();
    }

    this.getBehavioursToEvaluate$ = this.selfAssessmentService.getBehavioursToEvaluateForSubordinate(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {

          let ratingCounter = 0;
          let ratingSum = 0;
          let ratingDoneCounter = 0;
          let selfRatingCounter = 0;
          let selfRatingSum = 0;

          let parentBehaviours = data.response.behaviors.map((x: any) => {
            return {
              behaviorId: x.parentBehavior.behaviorId,
              title: x.parentBehavior.text
            }
          })
          this.parentBehaviourIds = parentBehaviours.map((item: any) => item.behaviorId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.behaviors.length; j++) {
              let behavior = data.response.behaviors[j];
              if (behavior.parentBehavior.behaviorId == this.parentBehaviourIds[i]) {
                if (tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && !tempBehaviour[this.parentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.parentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.parentBehaviourIds[i]] || !tempBehaviour[this.parentBehaviourIds[i]].length) {
                  tempBehaviour[this.parentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }
          this.competenceArray = [];
          let isPostCalibrationOrFinalStepCompleted = this.isFinalStepCompleted;

          for (let i = 0; i < this.parentBehaviourIds.length; i++) {
            let currentCompetenceRatingCounter = 0;
            let currentCompetenceRatingSum = 0;
            let currentCompetenceSelfRatingCounter = 0;
            let currentCompetenceSelfRatingSum = 0;

            let currentCompetenceRatingAvgNum = 0;
            let currentCompetenceSelfRatingAvgNum = 0;
            let currentCompetenceRatingAvgString = null;
            let currentCompetenceSelfRatingAvgString = null;
            let currentCompetenceRatingDoneCounter = 0;

            let parent = tempBehaviour[this.parentBehaviourIds[i]] && tempBehaviour[this.parentBehaviourIds[i]].length && tempBehaviour[this.parentBehaviourIds[i]][0].parentBehavior;
            let tempCompetence = [];
            if (isPostCalibrationOrFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].colleagueEvaluationRating;
                const currentSelfRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;


                let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }
                // setto la valutazione dei comportamenti leadership
                if (this.isLeadershipTeam) {
                  tempCompetence.push({
                    ...tempBehaviour[this.parentBehaviourIds[i]][j],
                    title: formattedTitle
                  })
                } else {
                  tempCompetence.push({
                    ...tempBehaviour[this.parentBehaviourIds[i]][j],
                    title: formattedTitle,
                    rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length),
                    selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
                  })
                }

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);
                selfRatingCounter++;
                selfRatingSum = selfRatingSum + (currentSelfRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            } else {
              if (this.isLeadershipTeam) {
                this.isInCompletedBehaviours = false;
              }
              for (let j = 0; j < tempBehaviour[this.parentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.parentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                if (this.isLeadershipTeam) {
                  if (tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating === undefined || tempBehaviour[this.parentBehaviourIds[i]][j].evaluationRating == null) {
                    this.isInCompletedBehaviours = true;
                  }
                  tempCompetence.push({
                    ...tempBehaviour[this.parentBehaviourIds[i]][j],
                    title: formattedTitle
                  });
                } else {
                  tempCompetence.push({
                    ...tempBehaviour[this.parentBehaviourIds[i]][j],
                    title: formattedTitle,
                    rating: this.getBehaviourRating(currentRating, tempBehaviour[this.parentBehaviourIds[i]][j].evaluationScale.length)
                  });
                }

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            }
            // Alla fine, aggiungo la media parziale
            if (tempCompetence && tempCompetence.length) {
              currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
              currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

              const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

              currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
              if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
              }

              currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
              if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
              }

              /* tempCompetence.push({
                disabled: true,
                isPartialAverage: true,
                avgTitle: this.translate.instant("generic.PARTIL_AVG"),
                ratingScale: currentCompetenceRatingEvalScale || 5,
                ratingAvgNum: currentCompetenceRatingAvgNum,
                selfRatingAvgNum: currentCompetenceSelfRatingAvgNum,
                ratingAvgString: currentCompetenceRatingAvgString,
                selfRatingAvgString: currentCompetenceSelfRatingAvgString,
                allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
              }); */
            }
            if (parent.text && parent.text.length && parent.text.includes('|')) {
              parent.text = parent.text.split('|')[0];
            }

            this.competenceArray.push({
              category: parent.text,
              competences: tempCompetence,
              ratingScale: tempCompetence[0].evaluationScale.length || 5
            })
          }

          // Media finale
          let ratingAvgNum;
          let ratingAvgString;
          let selfRatingAvgNum;
          let selfRatingAvgString;

          ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
          selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

          const ratingEvalScale = this.competenceArray && this.competenceArray[0] && this.competenceArray[0].evaluationScale && this.competenceArray[0].evaluationScale.length || 5;

          ratingAvgString = ratingAvgNum.toString();
          if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
            ratingAvgString = ratingAvgString.replace(".", ",");
          }

          selfRatingAvgString = selfRatingAvgNum.toString();
          if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
            selfRatingAvgString = selfRatingAvgString.replace(".", ",");
          }
          // Indica se tutti i comportamenti hanno avuto almeno una valutazione
          let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

          this.softSkillsFinalAvg = {
            allBehavioursValuated: allBehavioursValuated,
            title: this.translate.instant('generic.FINAL_AVG'),
            subtitle: this.translate.instant('generic.ORGANIZED_BEHAVIORS'),
            ratingAvgNum: ratingAvgNum,
            ratingAvgString: ratingAvgString,
            ratingScale: ratingEvalScale,
            selfRatingAvgNum: selfRatingAvgNum,
            selfRatingAvgString: selfRatingAvgString,
            isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted,
            selfRatingStars: (selfRatingAvgNum) || null,
            ratingStars: (ratingAvgNum) || null,
          }


          this.behaviorComments = data.response && data.response.overallComment || '';
          if (this.behaviorComments) {
            this.behaviorCommentsCopy = JSON.parse(JSON.stringify(this.behaviorComments));
          }

          this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
          this.isLoadingBehaviours = false;

          // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
          this.hasToOpenTutorial();

          // Calcolo le medie parziali di ogni comportamento
          /*
          let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.competenceArray);
          getCalculatedStarsCountByAvgPromise.then(() => {

            if (isPostCalibrationOrFinalStepCompleted) {
              this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));

              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  // Calcolo la media finale del self assessment
                  let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg, true);
                  getFinalAvgStarRatingSelfPromise.then(() => {
                    this.isLoadingBehaviours = false;

                    // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                    this.hasToOpenTutorial();
                  });
                });
              } else {
                this.isLoadingBehaviours = false;

                // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                this.hasToOpenTutorial();
              }
            } else {
              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.softSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
                  this.isLoadingBehaviours = false;

                  // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                  this.hasToOpenTutorial();
                });
              } else {
                this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
                this.isLoadingBehaviours = false;

                // Controllo se devo aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
                this.hasToOpenTutorial();
              }
            }
          });
          */
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  // Controlla se aprire automaticamente un tutorial (siamo nel caso in cui provengo dalla pagina di tutorial)
  hasToOpenTutorial(checkPersonalObjectiveEvaluationTutorial?: boolean) {
    this.checkPassStructureTutorial();
    //if (checkPersonalObjectiveEvaluationTutorial) {
    this.checkPersonalObjectiveEvaluationTutorial();
    //}
    this.checkOrganizationalBehaviorsTutorial();
    this.checkTechSkillEvaluationTutorial();
    this.checkSetGoalsTutorial();
  }

  // inizializza il rating dei comportamenti 
  getBehaviourRating(rating: number, ratingScale: number) {
    let starRating = JSON.parse(JSON.stringify(this.starsModel[ratingScale]));
    for (let i = 0; i < rating; i++) {
      starRating[i].isClicked = true;
    }
    return starRating;
  }

  // Aggiornamento valutazione comportamento
  behaviourRatingChanged(competence: any) {
    let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    behaviorEvaluation = { behaviorId: competence.behaviorId, evaluationScore: score.toString() };
    let comment;
    if (this.behaviorComments != this.behaviorCommentsCopy) {
      comment = this.behaviorComments;
    }

    this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, behaviorEvaluation, comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  onManageBehaviorCommentChanged(text: string) {
    this.behaviorComments = text
  }


  getCompetenceToEvaluateForSubordinate() {
    this.isLoadingTechSkills = true;
    this.techSkillCompetenceToChange = null;
    this.techSkillsFinalAvg = null;

    if (this.getCompetenceToEvaluateForSubordinate$) {
      this.getCompetenceToEvaluateForSubordinate$.unsubscribe();
    }

    this.getCompetenceToEvaluateForSubordinate$ = this.selfAssessmentService.getCompetenceToEvaluateForSubordinate(this.selectedYear.id, this.selectedYear.code, this.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm009",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // Parte aggiunta per modificare le competenze tech come le competenze soft
          this.competenceForModal = {};
          this.competenceForModal.evaluationScale = data.response.competences[0].evaluationScale;
          let ratingCounter = 0;
          let ratingSum = 0;
          let ratingDoneCounter = 0;
          let selfRatingCounter = 0;
          let selfRatingSum = 0;

          this.techSkillParentBehaviourIds = data.response?.competences.map((item: any) => item.competenceId)
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index);
          let tempBehaviour: any = {};
          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            for (let j = 0; j < data.response.competences.length; j++) {
              let behavior = data.response.competences[j];
              if (behavior.competenceId == this.techSkillParentBehaviourIds[i]) {
                if (tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && !tempBehaviour[this.techSkillParentBehaviourIds[i]].includes(behavior)) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]].push(behavior);
                } else if (!tempBehaviour[this.techSkillParentBehaviourIds[i]] || !tempBehaviour[this.techSkillParentBehaviourIds[i]].length) {
                  tempBehaviour[this.techSkillParentBehaviourIds[i]] = [behavior];
                }
              }
            }
          }

          this.techSkillArray = [];
          let isPostCalibrationOrFinalStepCompleted = this.isFinalStepCompleted;
          let allCompetences = [];
          for (let i = 0; i < this.techSkillParentBehaviourIds.length; i++) {
            let currentCompetenceRatingCounter = 0;
            let currentCompetenceRatingSum = 0;

            let currentCompetenceRatingAvgNum = 0;
            let currentCompetenceRatingAvgString = null;
            let currentCompetenceRatingDoneCounter = 0;

            let parent = tempBehaviour[this.techSkillParentBehaviourIds[i]] && tempBehaviour[this.techSkillParentBehaviourIds[i]].length && tempBehaviour[this.techSkillParentBehaviourIds[i]][0];
            let tempCompetence = [];
            if (isPostCalibrationOrFinalStepCompleted) {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].colleagueEvaluationRating;
                const currentSelfRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length),
                  selfRating: this.getBehaviourRating(currentSelfRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                selfRatingCounter++;
                ratingSum = ratingSum + (currentRating || 0);
                selfRatingSum = selfRatingSum + (currentSelfRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            } else {
              for (let j = 0; j < tempBehaviour[this.techSkillParentBehaviourIds[i]].length; j++) {
                const currentRating = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationRating;

                let formattedTitle = tempBehaviour[this.techSkillParentBehaviourIds[i]][j].title || '';
                if (formattedTitle && formattedTitle.length && formattedTitle.includes('|')) {
                  formattedTitle = formattedTitle.split('|')[0];
                }

                tempCompetence.push({
                  ...tempBehaviour[this.techSkillParentBehaviourIds[i]][j],
                  title: formattedTitle,
                  rating: this.getBehaviourRating(currentRating, tempBehaviour[this.techSkillParentBehaviourIds[i]][j].evaluationScale.length)
                });

                // Media finale
                ratingCounter++;
                ratingSum = ratingSum + (currentRating || 0);

                // Media parziale
                currentCompetenceRatingCounter++;
                currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);

                if (currentRating && currentRating > 0) {
                  currentCompetenceRatingDoneCounter++;
                  ratingDoneCounter++;
                }
              }
            }

            // Alla fine, aggiungo la media parziale
            if (tempCompetence && tempCompetence.length) {
              currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;

              const currentCompetenceRatingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

              currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
              if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
                currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
              }

              /* tempCompetence.push({
                disabled: true,
                isPartialAverage: true,
                avgTitle: this.translate.instant("generic.PARTIL_AVG"),
                ratingScale: currentCompetenceRatingEvalScale || 5,
                ratingAvgNum: currentCompetenceRatingAvgNum,
                ratingAvgString: currentCompetenceRatingAvgString,
                allCompetencesValuated: currentCompetenceRatingDoneCounter && currentCompetenceRatingDoneCounter === currentCompetenceRatingCounter
              }); */

              // Per visualizzare tutti gli elementi attaccati li inserisco in un Array unico 
            }
            if (tempCompetence) {
              allCompetences.push(...tempCompetence)
            }
          }
          this.techSkillArray.push({
            category: '',
            competences: allCompetences,
            ratingScale: allCompetences[0].evaluationScale.length || 5
          });

          // Media finale
          let ratingAvgNum;
          let ratingAvgString;
          let selfRatingAvgNum;
          let selfRatingAvgString;

          ratingAvgNum = +(ratingSum / ratingCounter).toFixed(2) || 0;
          selfRatingAvgNum = +(selfRatingSum / selfRatingCounter).toFixed(2) || 0;

          const ratingEvalScale = this.techSkillArray && this.techSkillArray[0] && this.techSkillArray[0].evaluationScale && this.techSkillArray[0].evaluationScale.length || 5;

          ratingAvgString = ratingAvgNum.toString();
          if (ratingAvgString && ratingAvgString.indexOf(".") >= 0) {
            ratingAvgString = ratingAvgString.replace(".", ",");
          }

          selfRatingAvgString = selfRatingAvgNum.toString();
          if (selfRatingAvgString && selfRatingAvgString.indexOf(".") >= 0) {
            selfRatingAvgString = selfRatingAvgString.replace(".", ",");
          }

          // Indica se tutti i comportamenti hanno avuto almeno una valutazione
          let allBehavioursValuated = ratingDoneCounter && ratingDoneCounter === ratingCounter;

          this.techSkillsFinalAvg = {
            allBehavioursValuated: allBehavioursValuated,
            title: this.translate.instant('generic.FINAL_AVG'),
            subtitle: this.yearsWithSpecialisticGoalsToEvaluate ? this.translate.instant('generic.TECHNIC_OBJECTIVES') : this.translate.instant('generic.TECHNICAL_SKILLS'),
            ratingAvgNum: ratingAvgNum,
            ratingAvgString: ratingAvgString,
            ratingScale: ratingEvalScale,
            selfRatingAvgString: selfRatingAvgString,
            selfRatingAvgNum: selfRatingAvgNum,
            isPostCalibrationOrFinalStepCompleted: isPostCalibrationOrFinalStepCompleted,
            selfRatingStars: (selfRatingAvgNum) || null,
            ratingStars: (ratingAvgNum) || null,
          }

          this.techSkillComment = data.response.overallComment || '';
          if (this.isFinalStepCompleted && !this.techSkillComment) {
            //   this.techSkillComment = this.translations['generic.NO_COMMENT_INSERTED']
          }

          if (this.techSkillComment) {
            this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
          }

          this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
          if (this.techSkills) {
            this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
          }

          this.isLoadingTechSkills = false;
          this.hasToOpenTutorial();
          // Calcolo le medie parziali di ogni comportamento
          /*
          let getCalculatedStarsCountByAvgPromise = this.getCalculatedStarsCountByAvg(this.techSkillArray);
          getCalculatedStarsCountByAvgPromise.then(() => {
            if (isPostCalibrationOrFinalStepCompleted) {
              this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));

              if (this.techSkills) {
                this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
              }

              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  // Calcolo la media finale del self assessment
                  let getFinalAvgStarRatingSelfPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg, true);
                  getFinalAvgStarRatingSelfPromise.then(() => {
                    this.isLoadingTechSkills = false;
                    this.hasToOpenTutorial();
                  });
                });
              } else {

                this.isLoadingTechSkills = false;
                this.hasToOpenTutorial();
              }
            } else {
              // Se tutti i comportamentis sono stati valorizzati, calcolo la media finale
              if (allBehavioursValuated) {
                let getFinalAvgStarRatingPromise = this.getFinalAvgStarRating(this.techSkillsFinalAvg);
                getFinalAvgStarRatingPromise.then(() => {
                  this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                  if (this.techSkills) {
                    this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                  }

                  this.isLoadingTechSkills = false;
                  this.hasToOpenTutorial();
                });
              } else {
                this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
                if (this.techSkills) {
                  this.techSkillsCopy = JSON.parse(JSON.stringify(this.techSkills));
                }

                this.isLoadingTechSkills = false;
                this.hasToOpenTutorial();
              }
            }
          });
          */
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm010",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingTechSkills = false;
      });
  }

  onTechSkillCommentChanged(text: string) {
    this.techSkillComment = text
  }

  // Aggiornamento valutazione comportamento
  techSkillRatingChanged(competence: any) {
    let competenceEvaluation: { competenceId: string, evaluationScore: number };
    let score = 0;
    for (let i = 0; i < competence.rating.length; i++) {
      if (competence.rating[i].isClicked) {
        score = competence.rating[i].id + 1;
      }
    }
    competenceEvaluation = { competenceId: competence.competenceId, evaluationScore: score };
    let comment;
    if (this.techSkillComment != this.techSkillCommentCopy) {
      comment = this.techSkillComment;
    }

    this.selfAssessmentService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(this.userId, [competenceEvaluation], comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }
  toFixedWithoutZeros(num: any, precision: any) {
    let number = num || 0;
    return `${1 * number.toFixed(precision)}`;
  }


  getFinalEvaluationData() {
    this.isLoadingFinalEvaluationData = true;

    if (this.getFinalEvaluationData$) {
      this.getFinalEvaluationData$.unsubscribe();
    }

    this.getFinalEvaluationData$ = this.selfAssessmentService.getPerformanceAssessmentFinalEvaluationDataForSubordinate(this.selectedYear.id, this.selectedYear.code)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm005",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingToEvaluateGoals = false;
        } else {
          this.insertByManager = data.response?.evaluationManager?.forename || '';
          this.insertByUser = this.loggedUser.forename;
          this.finalEvaluation = {};
          this.finalEvaluation.evaluatedData = [];
          this.managerData = data.response.evaluationManager;
          // valutazione obiettivi Se prima della condivisione finale
          if (data.response.evaluatedSubordinateUserGoals && data.response.evaluatedSubordinateUserGoals.length) {
            let evaluated = data.response.evaluatedSubordinateUserGoals;
            const managerEvaluated = data.response.evaluatedManagerUserGoals;
            for (let i = 0; i < evaluated.length; i++) {
              let managerEvaluation = null;
              let managerEvaluationVote = null;
              if (managerEvaluated && managerEvaluated.length) {
                const managerObjective = managerEvaluated.find((goal: any) => goal.goalId == evaluated[i].goalId);
                managerEvaluation = managerObjective.evaluation || this.translations['generic.NO_COMMENT_INSERTED'];
                if (this.isIT) {
                  managerEvaluationVote = managerObjective?.evaluationVote || 0;
                }
              }
              if (this.isIT) {
                this.finalEvaluation.evaluatedData.push({
                  ...evaluated[i],
                  isSelected: false,
                  managerEvaluation: managerEvaluation,
                  managerEvaluationVote: managerEvaluationVote,
                })
              } else {
                this.finalEvaluation.evaluatedData.push({
                  ...evaluated[i],
                  managerEvaluation: managerEvaluation,
                  isSelected: false
                })
              }
            }
            if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
              this.selectedObjective = this.finalEvaluation.evaluatedData[0];
            }
          }

          this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
          // valutazione comporamenti
          this.finalEvaluation.behaviorData = {};
          if (data.response.subordinateBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.comment = data.response.subordinateBehaviorEvaluation.overallComment;
            // this.finalEvaluation.behaviorData.rating = data.response.subordinateBehaviorEvaluation.stars;
            this.finalEvaluation.behaviorData.rating = (data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation);
            this.finalEvaluation.behaviorData.ratingValue = RoundAverage.getAverage(data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation);
          }

          if (data.response.progress) {
            this.finalEvaluation.behaviorCurrentStars = (data.response.progress.behavior?.currentSubordinateBehaviorEvaluation?.averageBehaviorManagerEvaluation) || 0;
            this.finalEvaluation.behaviorCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.behavior?.currentSubordinateBehaviorEvaluation?.averageBehaviorManagerEvaluation, 1)) || 0;
            this.finalEvaluation.behaviorPastStars = (data.response.progress.behavior?.pastSubordinateBehaviorEvaluation?.averageBehaviorManagerEvaluation) || 0;
            this.finalEvaluation.behaviorPastAvg = Number(this.toFixedWithoutZeros(data.response.progress.behavior?.pastSubordinateBehaviorEvaluation?.averageBehaviorManagerEvaluation, 1)) || 0;

            this.finalEvaluation.competenceCurrentStars = (data.response.progress.competence?.currentSubordinateCompetenceEvaluation?.averageCompetenceManagerEvaluation) || 0;
            this.finalEvaluation.competenceCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.competence?.currentSubordinateCompetenceEvaluation?.averageCompetenceManagerEvaluation, 1)) || 0;
            this.finalEvaluation.competencePastStars = (data.response.progress.competence?.pastSubordinateCompetenceEvaluation?.averageCompetenceManagerEvaluation) || 0;
            this.finalEvaluation.competencePastAvg = Number(this.toFixedWithoutZeros(data.response.progress.competence?.pastSubordinateCompetenceEvaluation?.averageCompetenceManagerEvaluation, 1)) || 0;

            this.finalEvaluation.pasCurrentStars = Number(this.toFixedWithoutZeros(data.response.progress.pas?.currentPasStars, 1)) || 0;
            this.finalEvaluation.pasCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.pas?.currentPasAvgEvaluation, 1)) || 0;
            this.finalEvaluation.pasPastStars = Number(this.toFixedWithoutZeros(data.response.progress.pas?.previousPasStars, 1)) || 0;
            this.finalEvaluation.pasPastAvg = Number(this.toFixedWithoutZeros(data.response.progress.pas?.previousPasAvgEvaluation, 1)) || 0;

            this.finalEvaluation.deltaAvg = Number(this.toFixedWithoutZeros((this.finalEvaluation.pasCurrentAvg - this.finalEvaluation.pasPastAvg), 1)) || 0;

            if (this.isIT) {
              this.finalEvaluation.goalCurrentStars = (data.response.progress.goals?.currentManagerGoalEvaluation?.averageGoalEvaluation) || 0;
              this.finalEvaluation.goalCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.goals?.currentManagerGoalEvaluation?.averageGoalEvaluation, 1)) || 0;
              this.finalEvaluation.goalCurrentAvgLabel = (Object.keys(data.response.progress.goals?.currentManagerGoalEvaluation).length === 0 && data.response.progress.goals?.currentManagerGoalEvaluation.constructor === Object) ? "-" : this.finalEvaluation.goalCurrentAvg;
              this.finalEvaluation.goalPastStars = (data.response.progress.goals?.pastManagerGoalEvaluation?.averageGoalEvaluation) || 0;
              this.finalEvaluation.goalPastAvg = Number(this.toFixedWithoutZeros(data.response.progress.goals?.pastManagerGoalEvaluation?.averageGoalEvaluation, 1)) || 0;
              this.finalEvaluation.goalPastAvgLabel = (Object.keys(data.response.progress.goals?.pastManagerGoalEvaluation).length === 0 && data.response.progress.goals?.pastManagerGoalEvaluation.constructor === Object) ? "-" : this.finalEvaluation.goalPastAvg;
            }

            // Manager
            this.managerFinalEvaluation.behaviorCurrentStars = (data.response.progress.behavior?.currentManagerBehaviorEvaluation?.averageBehaviorManagerEvaluation) || 0;
            this.managerFinalEvaluation.behaviorCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.behavior?.currentManagerBehaviorEvaluation?.averageBehaviorManagerEvaluation, 1)) || 0;
            this.managerFinalEvaluation.behaviorPastStars = (data.response.progress.behavior?.pastManagerBehaviorEvaluation?.averageBehaviorManagerEvaluation) || 0;
            this.managerFinalEvaluation.behaviorPastAvg = Number(this.toFixedWithoutZeros(data.response.progress.behavior?.pastManagerBehaviorEvaluation?.averageBehaviorManagerEvaluation, 1)) || 0;

            this.managerFinalEvaluation.competenceCurrentStars = (data.response.progress.competence?.currentManagerCompetenceEvaluation?.averageCompetenceManagerEvaluation) || 0;
            this.managerFinalEvaluation.competenceCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.competence?.currentManagerCompetenceEvaluation?.averageCompetenceManagerEvaluation, 1)) || 0;
            this.managerFinalEvaluation.competencePastStars = (data.response.progress.competence?.pastManagerCompetenceEvaluation?.averageCompetenceManagerEvaluation) || 0;
            this.managerFinalEvaluation.competencePastAvg = Number(this.toFixedWithoutZeros(data.response.progress.competence?.pastManagerCompetenceEvaluation?.averageCompetenceManagerEvaluation, 1)) || 0;

            this.managerFinalEvaluation.pasCurrentStars = Number(this.toFixedWithoutZeros(data.response.progress.pas?.currentPasStars, 1)) || 0;
            this.managerFinalEvaluation.pasCurrentAvg = Number(this.toFixedWithoutZeros(data.response.progress.pas?.currentPasAvgEvaluation, 1)) || 0;
            this.managerFinalEvaluation.pasPastStars = Number(this.toFixedWithoutZeros(data.response.progress.pas?.previousPasStars, 1)) || 0;
            this.managerFinalEvaluation.pasPastAvg = Number(this.toFixedWithoutZeros(data.response.progress.pas?.previousPasAvgEvaluation, 1)) || 0;

            this.finalEvaluation.deltaAvg = Number(this.toFixedWithoutZeros((this.finalEvaluation.pasCurrentAvg - this.finalEvaluation.pasPastAvg), 1)) || 0;
          }

          if (this.finalEvaluation.behaviorData.comment) {
            this.finalEvaluationBehaviourCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.behaviorData.comment));
          }

          if (data.response.subordinateBehaviorEvaluation) {
            this.finalEvaluation.behaviorData.selfComment = data.response.subordinateBehaviorEvaluation.overallComment;
            this.finalEvaluation.behaviorData.selfRating = (data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation);
            this.finalEvaluation.behaviorData.selfRatingValue = RoundAverage.getAverage(data.response.subordinateBehaviorEvaluation.averageBehaviorManagerEvaluation);
          }

          // valutazione competenze
          this.finalEvaluation.competenceData = {};
          if (data.response.subordinateCompetenceEvaluation) {
            this.finalEvaluation.competenceData.comment = data.response.subordinateCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.rating = (data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation);
            this.finalEvaluation.competenceData.ratingValue = RoundAverage.getAverage(data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation);
          }

          this.finalEvaluation.comment = data.response.finalEvaluationSubordinateComment || '';
          this.finalEvaluation.managerComment = data.response.finalEvaluationManagerComment || '';
          this.finalEvaluation.ownObservations = data.response.ownObservations;
          this.finalEvaluation.colleagueObservations = data.response.colleagueObservations;


          if (this.currentStep.isComplete) {
            this.finalEvaluation.selfComment = data.response.finalEvaluationManagerComment || '';
          }

          if (this.finalEvaluation.competenceData.comment) {
            this.finalEvaluationCompetenceCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.competenceData.comment));
          }

          if (data.response.subordinateCompetenceEvaluation) {
            this.finalEvaluation.competenceData.selfComment = data.response.subordinateCompetenceEvaluation.overallComment;
            this.finalEvaluation.competenceData.selfRating = (data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation);
            this.finalEvaluation.competenceData.selfRatingValue = RoundAverage.getAverage(data.response.subordinateCompetenceEvaluation.averageCompetenceManagerEvaluation);
          }

          // valutazione comportamenti manager
          this.managerFinalEvaluation.behaviorData = {};
          if (data.response.managerBehaviorEvaluation) {
            this.managerFinalEvaluation.behaviorData.comment = data.response.managerBehaviorEvaluation.overallComment;
            this.managerFinalEvaluation.behaviorData.rating = (data.response.managerBehaviorEvaluation.averageBehaviorManagerEvaluation);
            this.managerFinalEvaluation.behaviorData.ratingValue = RoundAverage.getAverage(data.response.managerBehaviorEvaluation.averageBehaviorManagerEvaluation);
          }

          // valutazione comportamenti manager
          this.managerFinalEvaluation.competenceData = {};
          if (data.response.managerCompetenceEvaluation) {
            this.managerFinalEvaluation.competenceData.comment = data.response.managerCompetenceEvaluation.overallComment;
            this.managerFinalEvaluation.competenceData.rating = (data.response.managerCompetenceEvaluation.averageCompetenceManagerEvaluation);
            this.managerFinalEvaluation.competenceData.ratingValue = RoundAverage.getAverage(data.response.managerCompetenceEvaluation.averageCompetenceManagerEvaluation);
          }

          // definizione obiettivi
          this.finalEvaluation.definedObjectives = [];
          if (data.response.definedSubordinateUserGoals && data.response.definedSubordinateUserGoals.length) {
            let defined = data.response.definedSubordinateUserGoals;
            for (let m = 0, resLength = defined.length; m < resLength; m++) {
              const currentRes = defined[m];
              this.finalEvaluation.definedObjectives.push({
                id: currentRes.goalId,
                title: currentRes.text,
                creationUser: currentRes.creationUser
              });
            }
          }

          if (this.currentStep.isComplete) {
            if (data.response.definedManagerUserGoals && data.response.definedManagerUserGoals.length) {
              let defined = data.response.definedManagerUserGoals;
              for (let m = 0, resLength = defined.length; m < resLength; m++) {
                const currentRes = defined[m];
                this.finalEvaluation.definedObjectives.push({
                  id: currentRes.goalId,
                  title: currentRes.text,
                  creationUser: currentRes.creationUser
                });
              }
            }
          }
          // Pas performer
          this.finalEvaluation.pasPerformer = {};
          if (data.response.pasProfile) {
            this.finalEvaluation.pasPerformer.key = data.response.pasProfile.split('_')[0];
          }

          if (data.response.pasAvgEvaluation) {
            this.finalEvaluation.pasPerformer.value = RoundAverage.getAverage(data.response.pasAvgEvaluation);
          } else {
            this.finalEvaluation.pasPerformer.value = 0;
          }

          if (this.finalEvaluation.comment) {
            this.finalEvaluationCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.comment));
          }

          if (this.finalEvaluation.ownObservations) {
            this.finalEvaluationOwnObservationCopy = JSON.parse(JSON.stringify(this.finalEvaluation.ownObservations));
          }

          if (this.finalEvaluation.evaluatedData) {
            this.finalEvaluationDataCopy = JSON.parse(JSON.stringify(this.finalEvaluation.evaluatedData));
          }

          if (this.finalEvaluation.definedObjectives) {
            this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
          }

          // Pas performer MANAGER
          this.managerFinalEvaluation.pasPerformer = {};
          if (data.response.managerPasProfilepasProfile) {
            this.managerFinalEvaluation.pasPerformer.key = data.response.managerPasProfilepasProfile.split('_')[0];
          }

          if (data.response.managerPasAvgEvaluation) {
            this.managerFinalEvaluation.pasPerformer.value = RoundAverage.getAverage(data.response.managerPasAvgEvaluation);
          } else {
            this.managerFinalEvaluation.pasPerformer.value = 0;
          }
          this.setAvgGoal();

          // Recupero le informazioni dell'insight feedback
          // Commentato a seguito della CR 11/2024
          // if (data.response.insightFeedback) {
          //   this.finalEvaluation.insightFeedback = data.response.insightFeedback;
          //   this.finalEvaluation.insightFeedback.matrixManagerObservations = {
          //     strongPoints: this.finalEvaluation.insightFeedback.pointsOfStrengthComment,
          //     developmentAreas: this.finalEvaluation.insightFeedback.developmentAreasComment
          //   }
          // } else {
          //   this.finalEvaluation.insightFeedback = null;
          // }
        }
        this.isLoadingFinalEvaluationData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm006",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingToEvaluateGoals = false;
      });
  }

  onFinalEvaluationCommentChanged(text: string) {
    this.finalEvaluation.comment = text
  }

  onStrongPointChanged(text: string) {
    this.finalEvaluation.ownObservations.strongPoints = text
  }

  onDevelopmentAreaChanged(text: string) {
    this.finalEvaluation.ownObservations.developmentAreas = text
  }

  openSendFinalEvaluation() {
    this.modalService.open('send-hr-schedule')
  }

  closeSendFinalEvaluation(confirm?: boolean) {
    this.modalService.close('send-hr-schedule');
    if (confirm) {
      this.isLoadingFinalEvaluationData = true;
      if ((this.finalEvaluation.comment && this.finalEvaluation.comment.length) || ((this.finalEvaluation.ownObservations.strongPoints && this.finalEvaluation.ownObservations.strongPoints.length) || (this.finalEvaluation.ownObservations.developmentAreas && this.finalEvaluation.ownObservations.developmentAreas.length))) {
        let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
        saveFinalEvaluationStepPromise.then(() => {
          this.selfAssessmentService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForSubordinate(this.userId, this.finalEvaluation.ownObservations.strongPoints, this.finalEvaluation.ownObservations.developmentAreas, this.finalEvaluation.comment)
            .subscribe((data: SenecaResponse<any>) => {
              if (data && data.error) {
                const messageObj: ApplicationModalMessage = {
                  modalId: "em001",
                  text: this.translate.instant("errors." + data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              } else {
                this.sendFinalEvaluation();
              }
              this.editFinalEvaluationComment = false;
            })
        })
      } else {
        let saveFinalEvaluationStepPromise = this.saveFinalEvaluationStep(true);
        saveFinalEvaluationStepPromise.then(() => {
          this.sendFinalEvaluation();
        })
      }
    }
  }

  sendFinalEvaluation() {
    this.isLoadingFinalEvaluationData = true;
    this.selfAssessmentService.sendFinalEvaluationFormForSubordinate()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "fe001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab();
        }
      })
  }

  manageFinalEvaluationAction(id: string) {
    if (id == 'save') {
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForSubordinate(this.userId, this.finalEvaluation.ownObservations.strongPoints, this.finalEvaluation.ownObservations.developmentAreas)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    } else if (id == 'delete') {
      this.finalEvaluation.ownObservations.strongPoints = '';
      this.finalEvaluation.ownObservations.developmentAreas = '';
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForSubordinate(this.userId)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editFinalEvaluationComment = false;
        })
    }
  }

  onEditFinalEvaluationComment() {
    this.editFinalEvaluationComment = !this.editFinalEvaluationComment;
  }

  onEditBehaviourComment() {
    this.editBehaviorComment = !this.editBehaviorComment;
  }

  onBehaviorCommentEdited(text: string) {
    this.finalEvaluation.behaviorData.comment = text;
  }


  onEditCompetenceComment(text: string) {
    this.editCompetenceComment = !this.editCompetenceComment;
  }

  onCompetenceCommentEdited(text: string) {
    this.finalEvaluation.competenceData.comment = text;
  }


  manageBehaviorEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              this.showSaveSuccessfulToaster();
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            }
          }
          this.editBehaviorComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.behaviorData.comment = '';
      } else {
        this.behaviorComments = '';
      }
      comment = '';
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, null, null)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    }
  }

  manageCompetenceEvaluationAction(id: string, comment?: string, isFinal?: boolean, preventToastr?: boolean) {
    if (id == 'save') {
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em005",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!preventToastr) {
              window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              this.showSaveSuccessfulToaster();
            }
          }
          this.editCompetenceComment = false;
        });
    } else if (id == 'delete') {
      if (isFinal) {
        this.finalEvaluation.competenceData.comment = '';
      } else {
        this.techSkillComment = '';
      }
      comment = '';
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(this.userId, null, null)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em006",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editCompetenceComment = false;
        })
    }
  }


  editCurrentObjective(objective: any) {
    objective.editObjectiveEvaluation = !objective.editObjectiveEvaluation;
  }

  onObjectiveEvaluationEdited(text: string) {
    this.selectedObjective.evaluation = text;
  }

  anyEmptyEvaluation() {
    if (this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        if (!this.finalEvaluation.evaluatedData[i].evaluation || !this.finalEvaluation.evaluatedData[i].evaluation.length) {
          return true;
        }

        if (this.yearsWithSpecialisticGoalsToEvaluate && (this.selectedYear.id > 2023 || (this.selectedYear.id == 2023 && this.selectedYear.code == 'H2'))) {
          if (this.finalEvaluation.evaluatedData[i].evaluationVote == undefined || this.finalEvaluation.evaluatedData[i].evaluationVote == 0) {
            return true;
          }
        }
      }
      return false;
    }
    return true;
  }

  manageObjectiveEvaluationAction(id: string) {
    if (id == 'save') {
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em007",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    } else if (id == 'delete') {
      this.selectedObjective.evaluation = null;
      let evaluationForService: { goalId: string, evaluationText: string }[] = [];
      for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
        evaluationForService.push({
          goalId: this.finalEvaluation.evaluatedData[i].goalId,
          evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
        })
      }
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em008",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.getUserProfileTab();
            this.showSaveSuccessfulToaster();
          }
          this.selectedObjective.editObjectiveEvaluation = false;
        })
    }
  }


  // aggiorna obiettivi da valutazione finale 
  manageDefineObjectiveActions(id: string, objective?: any) {
    if (id == 'save') {
      let goals = [];
      for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
        goals.push({
          goalId: this.finalEvaluation.definedObjectives[i].id,
          text: this.finalEvaluation.definedObjectives[i].title
        });
      }

      if (this.createOrUpdatePerformanceAssessmentGoals$) {
        this.createOrUpdatePerformanceAssessmentGoals$.unsubscribe();
      }
      this.createOrUpdatePerformanceAssessmentGoals$ = this.selfAssessmentService.createOrUpdatePerformanceAssessmentGoals(this.userId, goals)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.getFinalEvaluationData();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    } else if (id == 'delete') {
      // In caso contrario devo chiamare i servizi per l'eliminazione vera e propria
      if (this.deletePerformanceAssessmentGoals$) {
        this.deletePerformanceAssessmentGoals$.unsubscribe();
      }
      this.deletePerformanceAssessmentGoals$ = this.selfAssessmentService.deletePerformanceAssessmentGoals(this.userId, [objective.id])
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm013",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
            this.getUserProfileTab(true);
            this.getFinalEvaluationData();
          }
          this.isLoadingDefinedObjectives = false;
        }, (err?: any) => {
          this.isLoadingDefinedObjectives = false;
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm014",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }



  checkPassStructureTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');
    if (forcePasStructureTutorial) {
      this.forcePasTutorialStep = this.stepList[0];
      this.openPasStructureTutorial();
    }
  }

  checkPersonalObjectiveEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');
    if (forcePasStructureTutorial) {
      if (!this.currentStep.id && this.stepList && this.stepList[0]) {
        this.forcePasTutorialStep = this.stepList[0];
        this.onStepClicked(this.stepList[0], true);
      } else {
        this.forcePasTutorialStep = this.stepList[0];
        this.openPersonalObjectiveEvaluationTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openPersonalObjectiveEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.personObjectiveEvaluation.1.TITLE',
        'tutorials.personObjectiveEvaluation.1.DESC',
        'tutorials.personObjectiveEvaluation.2.TITLE',
        'tutorials.personObjectiveEvaluation.2.DESC',
        'tutorials.personObjectiveEvaluation.3.TITLE',
        'tutorials.personObjectiveEvaluation.3.DESC',
        'tutorials.personObjectiveEvaluation.4.TITLE',
        'tutorials.personObjectiveEvaluation.4.DESC',
        'tutorials.personObjectiveEvaluation.5.TITLE',
        'tutorials.personObjectiveEvaluation.5.DESC',
        'tutorials.personObjectiveEvaluation.6.TITLE',
        'tutorials.personObjectiveEvaluation.6.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#firstStepPageHeader',
          /** Titolo */
          title: !this.isIT ? translations['tutorials.personObjectiveEvaluation.1.TITLE'] : translations['tutorials.personObjectiveEvaluation.5.TITLE'],
          /** Tour step text */
          content: !this.isIT ? translations['tutorials.personObjectiveEvaluation.1.DESC'] : translations['tutorials.personObjectiveEvaluation.5.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.toEvaluateList && this.toEvaluateList.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-title',
            /** Titolo */
            title: !this.isIT ? translations['tutorials.personObjectiveEvaluation.2.TITLE'] : translations['tutorials.personObjectiveEvaluation.6.TITLE'],
            /** Tour step text */
            content: !this.isIT ? translations['tutorials.personObjectiveEvaluation.2.DESC'] : translations['tutorials.personObjectiveEvaluation.6.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#toEvaluateItemIndex0-description',
            /** Titolo */
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#saveGoalToEvaluateBtn',
            /** Titolo */
            title: translations['tutorials.personObjectiveEvaluation.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.personObjectiveEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'PERSONAL OBJECTIVE EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        // aggiunto il timeout altrimenti partiva prima che terminasse il loader dei dati
        setTimeout(() => this.guidedTour.startTour(intro), 500);
      });
  }

  checkOrganizationalBehaviorsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 2 && this.stepList && this.stepList[1]) {
        this.forcePasTutorialStep = this.stepList[1];
        this.onStepClicked(this.stepList[1]);
      } else {
        this.openOrganizationalBehaviorsTutorial();
      }
    }
  }

  // Apre il tutorial della valutazione obiettivi di sviluppo personale
  openOrganizationalBehaviorsTutorial() {
    this.translate.get(
      [
        'tutorials.organizationalBehaviors.1.TITLE',
        'tutorials.organizationalBehaviors.1.DESC',
        'tutorials.organizationalBehaviors.2.TITLE',
        'tutorials.organizationalBehaviors.2.DESC',
        'tutorials.organizationalBehaviors.3.TITLE',
        'tutorials.organizationalBehaviors.3.DESC',
        'tutorials.organizationalBehaviors.4.TITLE',
        'tutorials.organizationalBehaviors.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#secondStepPageHeader',
          /** Titolo */
          title: translations['tutorials.organizationalBehaviors.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.organizationalBehaviors.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.competenceArray && this.competenceArray.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.2.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.3.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceEvaluationNotes',
            /** Titolo */
            title: translations['tutorials.organizationalBehaviors.4.TITLE'],
            /** Tour step text */
            content: translations['tutorials.organizationalBehaviors.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'ORGANIZATIONAL BEHAVIOURS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  checkTechSkillEvaluationTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 3 && this.stepList && this.stepList[2]) {
        this.forcePasTutorialStep = this.stepList[2];
        this.onStepClicked(this.stepList[2], forcePasStructureTutorial === "true");
      } else {
        this.openTechSkillEvaluationTutorial();
      }
    }
  }

  checkSetGoalsTutorial() {
    const forcePasStructureTutorial = sessionStorage.getItem('openSetGoalsTutorial');
    if (forcePasStructureTutorial) {
      // Prima devo entrare nel secondo step
      if (this.currentStep.stepId !== 4 && this.stepList && (this.stepList.length > 4 ? this.stepList[3] : this.stepList[2])) {
        if (this.stepList.length > 4) {
          this.forcePasTutorialStep = this.stepList[3];
        } else {
          this.forcePasTutorialStep = this.stepList[2];
        }
        this.onStepClicked(this.forcePasTutorialStep, forcePasStructureTutorial === "true");
      } else {
        this.openSetGoalsTutorial();
      }
    }
  }

  // Apre il tutorial delle competenze tecniche
  openSetGoalsTutorial() {
    this.translate.get(
      [
        'tutorials.setGoals.1.TITLE',
        'tutorials.setGoals.1.DESC',
        'tutorials.setGoals.1_SPECIALIST.TITLE',
        'tutorials.setGoals.1_SPECIALIST.DESC',
        'tutorials.setGoals.2.TITLE',
        'tutorials.setGoals.2.DESC',
        'tutorials.setGoals.3.TITLE',
        'tutorials.setGoals.3.DESC',
        'tutorials.setGoals.3_SPECIALIST.TITLE',
        'tutorials.setGoals.3_SPECIALIST.DESC',
        'tutorials.setGoals.4.TITLE',
        'tutorials.setGoals.4.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#fourthStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate || this.isIT ? translations['tutorials.setGoals.1_SPECIALIST.TITLE'] : translations['tutorials.setGoals.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate || this.isIT ? translations['tutorials.setGoals.1_SPECIALIST.DESC'] : translations['tutorials.setGoals.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#addGoalBtn',
          /** Titolo */
          title: translations['tutorials.setGoals.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.setGoals.2.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#saveGoalBtn',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.3_SPECIALIST.TITLE'] : translations['tutorials.setGoals.3.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.setGoals.3_SPECIALIST.DESC'] : translations['tutorials.setGoals.3.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 15
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'SET GOALS TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openSetGoalsTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial delle competenze tecniche
  // Apre il tutorial delle competenze tecniche
  openTechSkillEvaluationTutorial() {
    this.translate.get(
      [
        'tutorials.techSkillEvaluation.1.TITLE',
        'tutorials.techSkillEvaluation.1.DESC',
        'tutorials.techSkillEvaluation.2.TITLE',
        'tutorials.techSkillEvaluation.2.DESC',
        'tutorials.techSkillEvaluation.3.TITLE',
        'tutorials.techSkillEvaluation.3.DESC',
        'tutorials.techSkillEvaluation.4.TITLE',
        'tutorials.techSkillEvaluation.4.DESC',
        'tutorials.otsEvaluation.1.TITLE',
        'tutorials.otsEvaluation.1.DESC',
        'tutorials.otsEvaluation.2.TITLE',
        'tutorials.otsEvaluation.2.DESC',
        'tutorials.otsEvaluation.3.TITLE',
        'tutorials.otsEvaluation.3.DESC',
        'tutorials.otsEvaluation.4.TITLE',
        'tutorials.otsEvaluation.4.DESC',
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#thirdStepPageHeader',
          /** Titolo */
          title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.TITLE'] : translations['tutorials.techSkillEvaluation.1.TITLE'],
          /** Tour step text */
          content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.1.DESC'] : translations['tutorials.techSkillEvaluation.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }];

        if (this.techSkills && this.techSkills.competences && this.techSkills.competences.length) {
          this.steps.push({
            /** Selettore html */
            selector: '#competenceIndex0-container',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.TITLE'] : translations['tutorials.techSkillEvaluation.2.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.2.DESC'] : translations['tutorials.techSkillEvaluation.2.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#competenceIndex0-stars',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.TITLE'] : translations['tutorials.techSkillEvaluation.3.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.3.DESC'] : translations['tutorials.techSkillEvaluation.3.DESC'],
            orientation: Orientation.Bottom,
            highlightPadding: 15
          }, {
            /** Selettore html */
            selector: '#techEvaluationNotes',
            /** Titolo */
            title: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.TITLE'] : translations['tutorials.techSkillEvaluation.4.TITLE'],
            /** Tour step text */
            content: this.yearsWithSpecialisticGoalsToEvaluate ? translations['tutorials.otsEvaluation.4.DESC'] : translations['tutorials.techSkillEvaluation.4.DESC'],
            orientation: Orientation.Top,
            highlightPadding: 15
          }
          )
        }

        const intro = {
          /** Identifier for tour */
          tourId: 'TECH SKILL EVALUATION TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openTechSkillEvaluationTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }

  // Apre il tutorial della struttura PAS
  openPasStructureTutorial() {
    this.translate.get(
      [
        'tutorials.pasStructure.1.TITLE',
        'tutorials.pasStructure.1.DESC',
        'tutorials.pasStructure.2.TITLE',
        'tutorials.pasStructure.2.DESC',
        'tutorials.pasStructure.3.TITLE',
        'tutorials.pasStructure.3.DESC',
        'tutorials.pasStructure.4.TITLE',
        'tutorials.pasStructure.4.DESC',
        'tutorials.pasStructure.5.TITLE',
        'tutorials.pasStructure.5.DESC'
      ])
      .subscribe(translations => {
        this.steps = [{
          /** Selettore html */
          selector: '#userAvatar',
          /** Titolo */
          title: translations['tutorials.pasStructure.1.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.1.DESC'],
          orientation: Orientation.Bottom,
          // /** Action that happens when the step is opened */
          // action?: () => void;
          // /** Action that happens when the step is closed */
          closeAction: () => {
            sessionStorage.removeItem('openPasStructureTutorial');
          },
          // /** Skips this step, this is so you do not have create multiple tour configurations based on user settings/permissions */
          // skipStep?: boolean;
          // /** Adds some padding for things like sticky headers when scrolling to an element */
          // scrollAdjustment?: number;
          // /** Adds default padding around tour highlighting. Does not need to be true for highlightPadding to work */
          // useHighlightPadding?: boolean;
          // /** Adds padding around tour highlighting in pixels, this overwrites the default for this step. Is not dependent on useHighlightPadding being true */
          // highlightPadding?: number;
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#takeNoteBtn',
          /** Titolo */
          title: translations['tutorials.pasStructure.2.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.2.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#pas',
          /** Titolo */
          title: translations['tutorials.pasStructure.3.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.3.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#notes',
          /** Titolo */
          title: translations['tutorials.pasStructure.4.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.4.DESC'],
          orientation: Orientation.Bottom,
          highlightPadding: 15
        }, {
          /** Selettore html */
          selector: '#stepper',
          /** Titolo */
          title: translations['tutorials.pasStructure.5.TITLE'],
          /** Tour step text */
          content: translations['tutorials.pasStructure.5.DESC'],
          orientation: Orientation.Top,
          highlightPadding: 25
        }];

        const intro = {
          /** Identifier for tour */
          tourId: 'PAS STRUCTURE TOUR',
          /** Use orb to start tour */
          useOrb: false,
          /** Steps for the tour */
          steps: this.steps,
          /** Function will be called when tour is skipped */
          skipCallback: (stepSkippedOn: number) => {
            sessionStorage.removeItem('openPasStructureTutorial');
          }
          // /** Function will be called when tour is completed */
          // completeCallback?: () => void;
          // /** Minimum size of screen in pixels before the tour is run, if the tour is resized below this value the user will be told to resize */
          // minimumScreenSize?: number;
          // /** Dialog shown if the window width is smaller than the defined minimum screen size. */
          // resizeDialog?: {
          //   /** Resize dialog title text */
          //   title?: string;
          //   /** Resize dialog text */
          //   content: string;
          // }
        }
        this.guidedTour.startTour(intro);
      });
  }


  // Elimina ogni riferimento al tutorial dal session storage
  removeTutorialsRefFromSessionStorage() {
    const openPasStructureTutorial = sessionStorage.getItem('openPasStructureTutorial');

    if (openPasStructureTutorial) {
      sessionStorage.removeItem('openPasStructureTutorial');
    }

    const openPersonalObjectiveEvaluationTutorial = sessionStorage.getItem('openPersonalObjectiveEvaluationTutorial');

    if (openPersonalObjectiveEvaluationTutorial) {
      sessionStorage.removeItem('openPersonalObjectiveEvaluationTutorial');
    }

    const openOrganizationalBehaviorsTutorial = sessionStorage.getItem('openOrganizationalBehaviorsTutorial');

    if (openOrganizationalBehaviorsTutorial) {
      sessionStorage.removeItem('openOrganizationalBehaviorsTutorial');
    }

    const openTechSkillEvaluationTutorial = sessionStorage.getItem('openTechSkillEvaluationTutorial');

    if (openTechSkillEvaluationTutorial) {
      sessionStorage.removeItem('openTechSkillEvaluationTutorial');
    }

    const openSetGoalsTutorial = sessionStorage.getItem('openSetGoalsTutorial');

    if (openSetGoalsTutorial) {
      sessionStorage.removeItem('openSetGoalsTutorial');
    }
  }


  allStepCompleted() {
    if (this.isLeadershipTeam) {
      let leadershipStep = this.stepList.find((step: any) => step.id == 'behaviourEvaluation');
      if (leadershipStep && leadershipStep.isComplete) {
        return true;
      }
      return false;
    } else {
      if (this.isManagement) {
        for (let i = 0; i < 3; i++) {
          if (!this.stepList[i].isComplete) {
            return false;
          }
        }
        return true;
      } else {
        for (let i = 0; i < 4; i++) {
          if (!this.stepList[i].isComplete) {
            return false;
          }
        }
        return true;
      }
    }
  }


  getYearName() {
    let yearName = '';
    if (this.selectedYear.code == 'H1') {
      yearName = 'H2 ' + this.selectedYear.id
    } else {
      yearName = 'H1 ' + (parseInt(this.selectedYear.id) + 1);
    }
    return yearName;
  }

  // Salva le note e i commenti della valutazione
  saveBehaviourEvaluation() {
    this.isLoadingBehaviours = true;
    let saveBehavioursRatingsPromise = this.saveBehavioursRatings();
    saveBehavioursRatingsPromise.then(() => {
      this.competenceArrayCopy = JSON.parse(JSON.stringify(this.competenceArray));
      this.behaviorCommentsCopy = JSON.parse(JSON.stringify(this.behaviorComments));
      this.showSaveSuccessfulToaster();
      this.getUserProfileTab(true);
      this.isLoadingBehaviours = false;

      // Vado allo step successivo
      this.goToNextStep(true);
    })
      .catch((e?) => {
        console.log(e);
        this.isLoadingBehaviours = false;
      })
  }

  // Salva le note e i commenti della valutazione
  saveBehavioursRatings() {
    // Salvo il commento
    this.manageBehaviorEvaluationAction("save", this.behaviorComments, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, competenceArrayLength = this.competenceArray.length; j < competenceArrayLength; j++) {
      let competenceArray = this.competenceArray[j];

      if (competenceArray.competences && competenceArray.competences.length) {
        for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = competenceArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {
            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
              let score = 0;
              if (this.isLeadershipTeam) {
                score = currentCompetence.evaluationRating;
              } else {
                for (let i = 0; i < currentCompetence.rating.length; i++) {
                  if (currentCompetence.rating[i].isClicked) {
                    score = currentCompetence.rating[i].id + 1;
                  }
                }
              }
              behaviorEvaluation = { behaviorId: currentCompetence.behaviorId, evaluationScore: score.toString() };
              let comment;
              if (this.behaviorComments != this.behaviorCommentsCopy) {
                comment = this.behaviorComments;
              }

              this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, behaviorEvaluation, comment)
                .subscribe((data: SenecaResponse<boolean>) => {
                  if (data && data.error) {
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm017",
                      text: this.translate.instant("errors." + data.error),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    resolve();
                  } else {
                    this.behaviourCompetenceToChange = null;
                    resolve();
                  }
                }, (err?: any) => {
                  resolve();
                  const messageObj: ApplicationModalMessage = {
                    modalId: "pdm018",
                    text: this.translate.instant("errors." + err?.message),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  this.isLoadingBehaviours = false;
                });
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  onBehaviourRatingChanged(competence: any) {
    if (competence) {
      let softSkill;

      for (let i = 0, competencesLength = this.competenceArrayCopy.length; i < competencesLength; i++) {
        let currentCompetence = this.competenceArrayCopy[i];
        let competenceFound;

        if (currentCompetence.competences && !competenceFound) {
          for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
            let originalCompetence = currentCompetence.competences[m];

            if (originalCompetence.behaviorId && originalCompetence.behaviorId === competence.behaviorId) {
              competenceFound = true;
              softSkill = currentCompetence;

              // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
              let originalScore = 0;
              for (let t = 0; t < originalCompetence.rating.length; t++) {
                if (originalCompetence.rating[t].isClicked) {
                  originalScore = originalCompetence.rating[t].id + 1;
                }
              }
              let newScore = 0;
              for (let y = 0; y < competence.rating.length; y++) {
                if (competence.rating[y].isClicked) {
                  newScore = competence.rating[y].id + 1;
                }
              }

              if (originalScore !== newScore) {
                this.behaviourCompetenceToChange = originalCompetence;
              } else {
                this.behaviourCompetenceToChange = null;
              }

              break;
            }
          }
        }
      }
      this.updateSoftSkillsPartialAvg(softSkill);
    }
  }

  // Aggiorna in real time la media parziale delle soft skill
  updateSoftSkillsPartialAvg(softSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.competenceArray.length; k < skillsLength; k++) {
      const currentSoftkill = this.competenceArray[k];

      if (currentSoftkill.category === softSkill.category) {
        for (let q = 0, competencesLength = currentSoftkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentSoftkill.competences[q];
          if (currentCompetence.isPartialAverage) {
            currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
            currentCompetence.selfRatingAvgNum = currentCompetenceSelfRatingAvgNum;
            currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            currentCompetence.selfRatingAvgString = currentCompetenceSelfRatingAvgString;
            break;
          }
        }

        break;
      }
    }
  }

  // Aggiorna in real time la media parziale delle tech skill
  updateTechSkillsPartialAvg(techSkill: any) {
    let currentCompetenceRatingCounter = 0;
    let currentCompetenceRatingSum = 0;
    let currentCompetenceSelfRatingCounter = 0;
    let currentCompetenceSelfRatingSum = 0;

    let currentCompetenceRatingAvgNum = 0;
    let currentCompetenceSelfRatingAvgNum = 0;
    let currentCompetenceRatingAvgString = null;
    let currentCompetenceSelfRatingAvgString = null;

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];

      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (!currentCompetence.isPartialAverage) {
            let currentRating = 0;
            for (let i = 0; i < currentCompetence.rating.length; i++) {
              if (currentCompetence.rating[i].isClicked) {
                currentRating++;
              }
            }

            // Media parziale
            currentCompetenceRatingCounter++;
            currentCompetenceSelfRatingCounter++;
            currentCompetenceRatingSum = currentCompetenceRatingSum + (currentRating || 0);
          }
        }
        break;
      }
    }

    // Calcolo la media
    currentCompetenceRatingAvgNum = +(currentCompetenceRatingSum / currentCompetenceRatingCounter).toFixed(2) || 0;
    currentCompetenceSelfRatingAvgNum = +(currentCompetenceSelfRatingSum / currentCompetenceSelfRatingCounter).toFixed(2) || 0;

    currentCompetenceRatingAvgString = currentCompetenceRatingAvgNum.toString();
    if (currentCompetenceRatingAvgString && currentCompetenceRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceRatingAvgString = currentCompetenceRatingAvgString.replace(".", ",");
    }

    currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgNum.toString();
    if (currentCompetenceSelfRatingAvgString && currentCompetenceSelfRatingAvgString.indexOf(".") >= 0) {
      currentCompetenceSelfRatingAvgString = currentCompetenceSelfRatingAvgString.replace(".", ",");
    }

    for (let k = 0, skillsLength = this.techSkillArray.length; k < skillsLength; k++) {
      const currentTechSkill = this.techSkillArray[k];

      if (currentTechSkill.category === techSkill.category) {
        for (let q = 0, competencesLength = currentTechSkill.competences.length; q < competencesLength; q++) {
          const currentCompetence = currentTechSkill.competences[q];
          if (currentCompetence.isPartialAverage) {
            currentCompetence.ratingAvgNum = currentCompetenceRatingAvgNum;
            currentCompetence.selfRatingAvgNum = currentCompetenceSelfRatingAvgNum;
            currentCompetence.ratingAvgString = currentCompetenceRatingAvgString;
            currentCompetence.selfRatingAvgString = currentCompetenceSelfRatingAvgString;
            break;
          }
        }

        break;
      }
    }
  }

  onTechSkillRatingChanged(competence: any) {
    if (competence) {
      let techSkill;

      for (let i = 0, competencesLength = this.techSkillArrayCopy.length; i < competencesLength; i++) {
        let currentCompetence = this.techSkillArrayCopy[i];
        let competenceFound;

        if (currentCompetence.competences && !competenceFound) {
          for (let m = 0, competencesLength = currentCompetence.competences.length; m < competencesLength; m++) {
            let originalCompetence = currentCompetence.competences[m];

            if (originalCompetence.competenceId && originalCompetence.competenceId === competence.competenceId) {
              competenceFound = true;
              techSkill = currentCompetence;

              // Competenza trovata. Ora verifico lo score selezionato e quello nuovo
              let originalScore = 0;
              for (let t = 0; t < originalCompetence.rating.length; t++) {
                if (originalCompetence.rating[t].isClicked) {
                  originalScore = originalCompetence.rating[t].id + 1;
                }
              }
              let newScore = 0;
              for (let y = 0; y < competence.rating.length; y++) {
                if (competence.rating[y].isClicked) {
                  newScore = competence.rating[y].id + 1;
                }
              }

              if (originalScore !== newScore) {
                this.techSkillCompetenceToChange = originalCompetence;
              } else {
                this.techSkillCompetenceToChange = null;
              }

              break;
            }
          }
        }
      }
      this.updateTechSkillsPartialAvg(techSkill);
    }
  }

  // Verifica se qualcosa nello step 2 (valutazione comportamenti organizzativi) è cambiato e non è stato salvato
  anyBehaviourEvaluationChangesToSave() {
    if ((this.behaviorComments != this.behaviorCommentsCopy)
      || this.behaviourCompetenceToChange) {
      return true;
    }
    return false;
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkills() {
    this.isLoadingTechSkills = true;
    let saveTechSkillsRatingsPromise = this.saveTechSkillsRatings();

    saveTechSkillsRatingsPromise.then(() => {
      this.techSkillArrayCopy = JSON.parse(JSON.stringify(this.techSkillArray));
      this.techSkillCommentCopy = JSON.parse(JSON.stringify(this.techSkillComment));
      this.techSkillCompetenceToChange = null;
      this.isLoadingTechSkills = false;
      this.showSaveSuccessfulToaster();
      this.getUserProfileTab(true);

      // Vado allo step successivo
      this.goToNextStep(true);
    })
      .catch((e?) => {
        console.log(e);
        this.isLoadingTechSkills = false;
      })
  }

  // Salva le note e i commenti della valutazione delle competenze tecniche
  saveTechSkillsRatings() {
    // Salvo il commento
    this.manageCompetenceEvaluationAction("save", this.techSkillComment, false, true);

    // Salvo i valori del rating
    let promises = [];
    for (let j = 0, techSkillArrayLength = this.techSkillArray.length; j < techSkillArrayLength; j++) {
      let techSkillArray = this.techSkillArray[j];

      if (techSkillArray.competences && techSkillArray.competences.length) {
        for (let t = 0, competencesLength = techSkillArray.competences.length; t < competencesLength; t++) {
          let currentCompetence = techSkillArray.competences[t];
          promises.push(new Promise((resolve: Function, reject: Function) => {
            if (currentCompetence.isPartialAverage) {
              resolve();
            } else {
              let competenceEvaluation: { competenceId: string, evaluationScore: number };
              let score = 0;

              for (let i = 0; i < currentCompetence.rating.length; i++) {
                if (currentCompetence.rating[i].isClicked) {
                  score = currentCompetence.rating[i].id + 1;
                }
              }

              if (score > 0) {
                // Se lo score fosse a 0, il servizio tornerebbe "INVALID_OBJECT"
                competenceEvaluation = { competenceId: currentCompetence.competenceId, evaluationScore: score };
                let comment;
                if (this.techSkillComment != this.techSkillCommentCopy) {
                  comment = this.techSkillComment;
                }

                this.selfAssessmentService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(this.userId, [competenceEvaluation], comment)
                  .subscribe((data: SenecaResponse<boolean>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                    this.isLoadingTechSkills = false;
                  });
              } else {
                resolve();
              }
            }
          }));
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare in base alla votazione
  getCalculatedStarsCountByAvg(arrayRef: any, isSelfRating?: boolean) {
    let promises = [];
    if (arrayRef && arrayRef.length) {
      for (let j = 0, competenceArrayLength = arrayRef.length; j < competenceArrayLength; j++) {
        let competenceArray = arrayRef[j];

        if (competenceArray.competences && competenceArray.competences.length) {
          for (let t = 0, competencesLength = competenceArray.competences.length; t < competencesLength; t++) {
            let currentCompetence = competenceArray.competences[t];
            promises.push(new Promise((resolve: Function, reject: Function) => {

              if (!currentCompetence.isPartialAverage) {
                resolve();
              } else {
                const avg = isSelfRating ? currentCompetence.selfRatingAvgNum : currentCompetence.ratingAvgNum;

                this.selfAssessmentService.getCalculatedStarsCountByAvg(avg)
                  .subscribe((data: SenecaResponse<number>) => {
                    if (data && data.error) {
                      const messageObj: ApplicationModalMessage = {
                        modalId: "pdm017",
                        text: this.translate.instant("errors." + data.error),
                        title: this.translate.instant("generic.WARNING")
                      }
                      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                      resolve();
                    } else {
                      if (isSelfRating) {
                        currentCompetence.selfRatingStars = data.response;
                      } else {
                        currentCompetence.ratingStars = data.response;
                      }

                      resolve();
                    }
                  }, (err?: any) => {
                    resolve();
                    const messageObj: ApplicationModalMessage = {
                      modalId: "pdm018",
                      text: this.translate.instant("errors." + err?.message),
                      title: this.translate.instant("generic.WARNING")
                    }
                    this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                  });
              }
            }));
          }
        }
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Recupera il numero di stelline da valorizzare per la media finale
  getFinalAvgStarRating(objRef: any, isSelfRating?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      const avg = isSelfRating ? objRef.selfRatingAvgNum : objRef.ratingAvgNum;

      this.selfAssessmentService.getCalculatedStarsCountByAvg(avg)
        .subscribe((data: SenecaResponse<number>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm017",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          } else {
            if (isSelfRating) {
              objRef.selfRatingStars = data.response;
            } else {
              objRef.ratingStars = data.response;
            }

            resolve();
          }
        }, (err?: any) => {
          resolve();
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm018",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    });
  }

  // Verifica se sono cambiate le valutazioni delle competenze tech
  anyTechSkillChangedToSave() {
    let somethingChanged = false;
    if (this.techSkills && this.techSkills.competences && this.techSkills.competences.length && this.techSkillsCopy && this.techSkillsCopy.competences && this.techSkillsCopy.competences.length) {
      for (let o = 0, originalSkillLength = this.techSkills.competences.length; o < originalSkillLength; o++) {
        if (somethingChanged) {
          break;
        }

        const newTechSkill = this.techSkills.competences[o];

        let newScore = 0;
        for (let i = 0; i < newTechSkill.rating.length; i++) {
          if (newTechSkill.rating[i].isClicked) {
            newScore = newTechSkill.rating[i].id + 1;
          }
        }

        for (let u = 0, newSkillLength = this.techSkillsCopy.competences.length; u < newSkillLength; u++) {
          const originalTechSkill = this.techSkillsCopy.competences[u];

          if (newTechSkill.competenceId === originalTechSkill.competenceId) {
            let originalScore = 0;
            for (let w = 0; w < originalTechSkill.rating.length; w++) {
              if (originalTechSkill.rating[w].isClicked) {
                originalScore = originalTechSkill.rating[w].id + 1;
              }
            }

            if (originalScore !== newScore) {
              somethingChanged = true;
            }

            break;
          }
        }
      }
    }

    return somethingChanged;
  }

  // Verifica se qualcosa nello step 3 è cambiato e non è stato salvato
  anyTechSkillEvaluationChangesToSave() {
    if ((this.techSkillComment != this.techSkillCommentCopy)
      || this.techSkillCompetenceToChange) {
      return true;
    }
    return false;
  }

  saveFinalBehaviourComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
        this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  saveFinalBehaviourCompetenceComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalBehaviourCompetenceCommentChanged()) {
        resolve();
      } else {
        const comment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
        this.selfAssessmentService.createOrUpdatePerformanceAssessmentCompetenceEvaluationForSubordinate(this.userId, null, comment?.trim())
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  saveFinalEvaluationData() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalEvaluationDataChanged()) {
        resolve();
      } else {
        let evaluationForService: { goalId: string, evaluationText: string }[] = [];
        for (let i = 0; i < this.finalEvaluation.evaluatedData.length; i++) {
          evaluationForService.push({
            goalId: this.finalEvaluation.evaluatedData[i].goalId,
            evaluationText: this.finalEvaluation.evaluatedData[i].evaluation
          })
        }
        this.selfAssessmentService.createOrUpdatePerformanceAssessmentGalEvaluationForManager(this.userId, evaluationForService)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  deleteFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (this.finalDefinedObjectivesToDelete && this.finalDefinedObjectivesToDelete.length) {
        let goalIds = [];
        for (let t = 0, finalDefinedObjectivesToDeleteLength = this.finalDefinedObjectivesToDelete.length; t < finalDefinedObjectivesToDeleteLength; t++) {
          let currentObj = this.finalDefinedObjectivesToDelete[t];
          goalIds.push(currentObj.id);
        }

        this.selfAssessmentService.deletePerformanceAssessmentGoals(this.userId, goalIds)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "pdm003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            this.finalDefinedObjectivesToDelete.length = 0;

            resolve();
          }, (err?: any) => {
            resolve();
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm004",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          });
      } else {
        resolve();
      }
    })
  }

  saveFinalDefinedObjectives() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalDefinedObjectivesChanged()) {
        resolve();
      } else {
        let deleteFinalDefinedObjectivesPromise = this.deleteFinalDefinedObjectives();

        deleteFinalDefinedObjectivesPromise.then(() => {
          if (this.finalEvaluation.definedObjectives && this.finalEvaluation.definedObjectives.length) {
            let goals = [];
            for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
              if (!this.finalEvaluation.definedObjectives[i].cloned) {
                goals.push({
                  goalId: this.finalEvaluation.definedObjectives[i].id,
                  text: this.finalEvaluation.definedObjectives[i].title
                });
              }
            }

            this.selfAssessmentService.createOrUpdatePerformanceAssessmentGoals(this.userId, goals)
              .subscribe((data: SenecaResponse<any>) => {
                if (data && data.error) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "em001",
                    text: this.translate.instant("errors." + data.error),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }

                resolve();
              }, (err?: any) => {
                const messageObj: ApplicationModalMessage = {
                  modalId: "pdm006",
                  text: this.translate.instant("errors." + err?.message),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                resolve();
              });
          } else {
            resolve();
          }
        })
      }
    })
  }

  saveFinalEvaluationComment() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isFinalCommentChanged()) {
        resolve();
      } else {
        this.selfAssessmentService.createOrUpdatePerformanceAssessmentFinalEvaluationCommentForSubordinate(this.userId, this.finalEvaluation.ownObservations.strongPoints, this.finalEvaluation.ownObservations.developmentAreas)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              const messageObj: ApplicationModalMessage = {
                modalId: "em001",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }

            resolve();
          }, (err?: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "pdm006",
              text: this.translate.instant("errors." + err?.message),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            resolve();
          });
      }
    })
  }

  // Salva i dati dell'utimo step
  saveFinalEvaluationStep(preventReload?: boolean) {
    return new Promise((resolve: Function, reject: Function) => {
      // Textarea delle note e dei commenti
      this.isLoadingFinalEvaluationData = true;
      let saveFinalEvaluationCommentPromise = this.saveFinalEvaluationComment();

      saveFinalEvaluationCommentPromise.then(() => {
        this.finalEvaluationCommentCopy = JSON.parse(JSON.stringify(this.finalEvaluation.comment));
        this.finalEvaluationOwnObservationCopy = JSON.parse(JSON.stringify(this.finalEvaluation.ownObservations));

        // Valutazione comportamenti organizzativi
        let saveFinalBehaviourCommentPromise = this.saveFinalBehaviourComment();
        saveFinalBehaviourCommentPromise.then(() => {
          this.editBehaviorComment = false;
          const comment = this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment || '';
          this.finalEvaluationBehaviourCommentCopy = JSON.parse(JSON.stringify(comment));

          // Valutazione degli obiettivi
          let saveFinalEvaluationDataPromise = this.saveFinalEvaluationData();
          saveFinalEvaluationDataPromise.then(() => {
            this.finalEvaluation.emptyEvaluatedObjective = this.anyEmptyEvaluation();
            this.finalEvaluationDataCopy = JSON.parse(JSON.stringify(this.finalEvaluation.evaluatedData));

            // Valutazione competenze tecniche
            let saveFinalBehaviourCompetenceCommentPromise = this.saveFinalBehaviourCompetenceComment();
            saveFinalBehaviourCompetenceCommentPromise.then(() => {
              this.editCompetenceComment = false;
              const techComment = this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment || '';
              this.finalEvaluationCompetenceCommentCopy = JSON.parse(JSON.stringify(techComment));

              // Obiettivi di sviluppo personale
              let saveFinalDefinedObjectivesPromise = this.saveFinalDefinedObjectives();
              saveFinalDefinedObjectivesPromise.then(() => {
                this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
                if (!preventReload) {
                  this.getFinalEvaluationData();
                  this.showSaveSuccessfulToaster();
                }

                this.isLoadingFinalEvaluationData = false;
                resolve();
              });
            });
          });
        });
      });
    });
  }

  isSendEvalBtnVisibleAndEnabled() {
    return !this.isScheduleShared && !this.isFinalStepCompleted
      && !this.isLoadingFinalEvaluationData && this.allStepCompleted();
  }

  isConfirmShareBtnVisibleAndEnabled() {
    return !this.isFinalStepCompleted && !this.isLoadingFinalEvaluationData;
  }

  isFinalEvaluationDataChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.evaluatedData && this.finalEvaluation.evaluatedData.length) {
      for (let i = 0, dataLength = this.finalEvaluation.evaluatedData.length; i < dataLength; i++) {
        const currentData = this.finalEvaluation.evaluatedData[i];

        if (isChanged) {
          break;
        }

        if (currentData && currentData.evaluationItemId) {
          for (let t = 0, originalDataLength = this.finalEvaluationDataCopy.length; t < originalDataLength; t++) {
            const currentOriginalData = this.finalEvaluationDataCopy[t];

            if (currentOriginalData && currentOriginalData.evaluationItemId
              && currentOriginalData.evaluationItemId === currentData.evaluationItemId) {

              if (currentOriginalData.evaluation !== currentData.evaluation) {
                isChanged = true;
              }
              break;
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalDefinedObjectivesChanged() {
    let isChanged = false;

    // Controllo prima la lunghezza (magari qualche obiettivo è stato eliminato)
    const definedObjectivesCounter = this.finalEvaluation && this.finalEvaluation.definedObjectives && this.finalEvaluation.definedObjectives.length || 0;
    const originalDefinedObjectivesCounter = this.finalDefinedObjectivesCopy && this.finalDefinedObjectivesCopy.length || 0;

    if (definedObjectivesCounter != originalDefinedObjectivesCounter) {
      isChanged = true;
    } else {
      if (definedObjectivesCounter) {
        for (let i = 0; i < definedObjectivesCounter; i++) {
          const currentData = this.finalEvaluation.definedObjectives[i];

          if (isChanged) {
            break;
          }

          if (currentData && currentData.id) {
            for (let t = 0; t < originalDefinedObjectivesCounter; t++) {
              const currentOriginalData = this.finalDefinedObjectivesCopy[t];

              if (currentOriginalData && currentOriginalData.id
                && currentOriginalData.id === currentData.id) {

                if (currentOriginalData.title !== currentData.title) {
                  isChanged = true;
                }
                break;
              }
            }
          }
        }
      }
    }

    return isChanged;
  }

  isFinalBehaviourCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.behaviorData && this.finalEvaluation.behaviorData.comment
      && this.finalEvaluation.behaviorData.comment !== this.finalEvaluationBehaviourCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }

  isFinalBehaviourCompetenceCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.competenceData && this.finalEvaluation.competenceData.comment
      && this.finalEvaluation.competenceData.comment !== this.finalEvaluationCompetenceCommentCopy) {
      isChanged = true;
    }

    return isChanged;
  }
  isFinalCommentChanged() {
    let isChanged = false;

    if (this.finalEvaluation && this.finalEvaluation.ownObservations && this.finalEvaluation.ownObservations.strongPoints && this.finalEvaluation.ownObservations.strongPoints !== this.finalEvaluationOwnObservationCopy.strongPoints) {
      isChanged = true;
    }
    if (this.finalEvaluation && this.finalEvaluation.ownObservations && this.finalEvaluation.ownObservations.developmentAreas && this.finalEvaluation.ownObservations.developmentAreas !== this.finalEvaluationOwnObservationCopy.developmentAreas) {
      isChanged = true;
    }

    return isChanged;
  }

  // Verifica se qualcosa nello step 5 (riepilogo) è cambiato e non è stato salvato
  anythingToSaveInFinalEvaluation() {
    if (this.isFinalCommentChanged() || this.isFinalBehaviourCommentChanged()
      || this.isFinalEvaluationDataChanged() || this.isFinalBehaviourCompetenceCommentChanged()
      || this.isFinalDefinedObjectivesChanged()) {
      return true;
    }
    return false;
  }

  // Porta indietro di uno step
  goToPrevStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number - 2], skipCheck);
  }

  goToNextStep(skipCheck?: boolean) {
    this.onStepClicked(this.stepList[this.currentStep.number], skipCheck);
  }

  // controlla se a card goal, le competenze o i comportamenti sono disabilitati
  isItemDisabled() {
    return this.isScheduleShared || this.isFinalStepCompleted || this.isHistory;
  }

  // controlla se l'azione è disponibile (edit, condividi ecc)
  isOptionAvailable() {
    return !this.isScheduleShared && !this.isFinalStepCompleted && !this.isHistory;
  }

  // cambia commento behavior 
  onLeadershipCommentChanged(newText: string) {
    this.leadershipTeamComment = newText;
  }


  // Aggiornamento valutazione comportamento
  leadershipBehaviorChanged(tempBehaviour: any) {
    let behaviorEvaluation: { behaviorId: string, evaluationScore: string };
    let score = 0;
    if (tempBehaviour.evaluationRating != null) {
      score = tempBehaviour.evaluationRating;
    }

    behaviorEvaluation = { behaviorId: tempBehaviour.behaviorId, evaluationScore: score.toString() };

    let comment;
    if (this.leadershipTeamComment != this.leadershipTeamCommentCopy) {
      comment = this.leadershipTeamComment;
    }

    const behavior = this.competenceArray.find((el: any) => {
      return el.competences.find((item: any) => {
        if (item.evaluationRating === undefined || item.evaluationRating == null) {
          return el;
        }
      })
    });
    this.isInCompletedBehaviours = (behavior != undefined) ? true : false;

    this.createOrUpdatePerLeadershipSkill$ = this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, behaviorEvaluation, comment)
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "pdm017",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.showSaveSuccessfulToaster();
          this.getUserProfileTab(true);
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "pdm018",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingBehaviours = false;
      });
  }

  manageLeadershipBehaviorEvaluationAction(id: string, comment?: string) {
    if (id == 'save') {
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, null, comment?.trim())
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    } else if (id == 'delete') {
      if (false) { // if (isFinal) { TODO aggiungere se serve (serve?)ß
        this.finalEvaluation.behaviorData.comment = '';
      } else {
        this.leadershipTeamComment = '';
      }
      comment = '';
      this.selfAssessmentService.createOrUpdatePerformanceAssessmentBehaviorEvaluationForSubordinate(this.userId, null, null)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "em004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.showSaveSuccessfulToaster();
          }
          this.editBehaviorComment = false;
        });
    }
  }

  onEditMenuOptionClicked(option: string, objective: any, isSelfDefined?: boolean) {
    if (option == 'edit') {
      this.editObjectiveData = JSON.parse(JSON.stringify(objective));
      this.editObjectiveData.isSelfDefined = isSelfDefined;
      this.modalService.open('edit-objective-modal');
    } else if (option == 'delete') {
      this.objectiveToDelete = objective;
      this.objectiveToDelete.isSelfDefined = isSelfDefined;
      if (this.finalEvaluation?.definedObjectives?.length == 1) {
        const messageObj: ApplicationModalMessage = {
          modalId: "delobj001",
          title: this.translate.instant('finalEvaluation.modals.WARNING'),
          subtitle: this.translate.instant('finalEvaluation.modals.CANT_DELETE_SUB'),
          text: this.translate.instant('finalEvaluation.modals.CANT_DELETE_DESCR')
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.modalService.open('delete-objective-modal');
      }
    }
  }

  // Chiudi modale edit obiettivo
  closeEditObjectiveModal(confirm?: boolean) {
    this.modalService.close('edit-objective-modal');
    if (confirm) {
      for (let i = 0; i < this.finalEvaluation.definedObjectives.length; i++) {
        if (!this.finalEvaluation.definedObjectives[i].cloned) {
          if (this.finalEvaluation.definedObjectives[i].id == this.editObjectiveData.id) {
            this.finalEvaluation.definedObjectives[i].title = this.editObjectiveData.title;
          }
        }
      }
      this.saveFinalDefinedObjectives().then(() => {
        if (this.finalEvaluation.definedObjectives) {
          this.finalDefinedObjectivesCopy = JSON.parse(JSON.stringify(this.finalEvaluation.definedObjectives));
        }
      });
    }
    this.editObjectiveData = {};
  }


  // Chiudi modale cancellazione obiettivo
  closeDeleteObjectiveModal(confirm?: boolean) {
    this.modalService.close('delete-objective-modal');
    if (confirm) {
      this.isLoadingFinalEvaluationData = true;
      this.deleteObjective(this.objectiveToDelete);
      this.objectiveToDelete = null;
      setTimeout(() => { this.isLoadingFinalEvaluationData = false; }, 2000);
    }
  }

  // Descrizione modale cancellazione obiettivo 
  getDeleteModalText() {
    if (this.objectiveToDelete && this.objectiveToDelete.title) {
      return this.translations['finalEvaluation.modals.DELETE_DESCR_1'] + ' <b>' + this.objectiveToDelete.title + '</b> ' + this.translations['finalEvaluation.modals.DELETE_DESCR_2'];
    } else {
      return '';
    }
  }

  onObjectiveEdit(newText: any) {
    this.editObjectiveData.title = newText;
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.routeParams$) {
      this.routeParams$.unsubscribe();
    }
    if (this.getUserProfileTab$) {
      this.getUserProfileTab$.unsubscribe();
    }
    if (this.getRunningPhase$) {
      this.getRunningPhase$.unsubscribe();
    }
    if (this.getUserGoalToDefine$) {
      this.getUserGoalToDefine$.unsubscribe();
    }
    if (this.createOrUpdatePerformanceAssessmentGoals$) {
      this.createOrUpdatePerformanceAssessmentGoals$.unsubscribe();
    }
    if (this.deletePerformanceAssessmentGoals$) {
      this.deletePerformanceAssessmentGoals$.unsubscribe();
    }
    if (this.getBehavioursToEvaluate$) {
      this.getBehavioursToEvaluate$.unsubscribe();
    }
    if (this.createOrUpdatePerLeadershipSkill$) {
      this.createOrUpdatePerLeadershipSkill$.unsubscribe();
    }

    this.removeTutorialsRefFromSessionStorage();
  }


  setAvgGoal() {
    if ((this.isPostCalibration || this.isFinalStepCompleted) && this.isIT) {
      let avgSelfEvaluationVote = 0;
      let avgSelfEvaluationVoteStar = 0;
      let avgManagerEvaluationVoteStar = 0;
      for (let i = 0; i <= this.finalEvaluation.evaluatedData.length - 1; i++) {
        avgSelfEvaluationVote += this.finalEvaluation.evaluatedData[i].evaluationVote;
      }
      avgSelfEvaluationVote = avgSelfEvaluationVote / this.finalEvaluation.evaluatedData.length;

      if ((avgSelfEvaluationVote % 1) >= 0.5) {
        avgSelfEvaluationVoteStar = Math.ceil(avgSelfEvaluationVote);
      } else {
        avgSelfEvaluationVoteStar = Math.floor(avgSelfEvaluationVote);
      }

      if ((this.finalEvaluation.goalCurrentStars % 1) >= 0.5) {
        avgManagerEvaluationVoteStar = Math.ceil(this.finalEvaluation.goalCurrentStars);
      } else {
        avgManagerEvaluationVoteStar = Math.floor(this.finalEvaluation.goalCurrentStars);
      }

      this.goalsAverage = {
        allBehavioursValuated: true,
        title: this.translate.instant("generic.FINAL_AVG"),
        subtitle: this.translate.instant("generic.TECHNIC_OBJECTIVES"),
        ratingAvgNum: avgManagerEvaluationVoteStar,
        ratingAvgString: this.finalEvaluation.goalCurrentAvg.toFixed(2),
        ratingScale: 5,
        selfRatingAvgNum: avgSelfEvaluationVoteStar,
        selfRatingAvgString: avgSelfEvaluationVote.toFixed(2).toString(),
        isPostCalibrationOrFinalStepCompleted: true,
        selfRatingStars: avgSelfEvaluationVoteStar,
        ratingStars: avgManagerEvaluationVoteStar
      }
    } else {
      this.goalsAverage = undefined;
    }
  }

  getLeadershipSubtitleDescr() {
    if (ParseUtils.parseInt(this.selectedYear.id) > 2023 || (ParseUtils.parseInt(this.selectedYear.id) == 2023 && this.selectedYear.code == "H2")) {
      return this.translate.instant('stepper.LEADERSHIP_EVALUATION');
    } else {
      return this.translate.instant('stepper.LEADERSHIP_EVALUATION_OLD');
    }
  }
}