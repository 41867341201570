import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, filter } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { RequiredAuth } from 'atfcore-commonclasses';

@Injectable()
export class AdminUpwardFeedbackGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.store.select(fromApp.getLoggedUser)
      .pipe(
        filter((loggedUser) => loggedUser && loggedUser?.auths?.length),
        onceIfNotEmpty(),
        map((loggedUser) => {
          const isUpwardFeedbackAdmin = loggedUser.auths.find((auth: any) => auth == RequiredAuth.PERFORMANCEMANAGEMENTALLOY_UPWARDFEEDBACK_ADMIN);
          if (isUpwardFeedbackAdmin) {
            return true;
          } else {
            this.router.navigate(["/403"]);
            return false;
          }
        })
      )
  }
}
