<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBackBrowser()" [isBackButton]="true"
      title="{{('notifications.SIDENAV_TITLE' | translate)}}"
      description="{{('notifications.SIDENAV_DESCRIPTION' | translate)}}"></sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content" [ngClass]="{'height100': isActiveGeneralPlaceholder()}">
      <ng-container
        *ngIf="(notificationsToRead && notificationsToRead.length || notificationsReaded && notificationsReaded.length) && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded">
        <div class="to-read-container">
          <div class="info">
            <p *ngIf="notificationsToRead && notificationsToRead.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded"
              translate="notifications.TO_READ" class="title"></p>
            <p *ngIf="notificationsToRead && notificationsToRead.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded"
              translate="notifications.TO_READ_LIST" class="description"></p>
            <a *ngIf="notificationsToRead && notificationsToRead.length"
              (click)="openReadAllNotificationsModalNotification()" translate="notifications.SIGN_ALL_READED"></a>
            <ng-container
              *ngIf="notificationsToRead && notificationsToRead.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded">
              <div class="notify-container">
                <card-notify [isNew]="true" (onGoToDetail)="goToDetail($event)"
                  (onDelete)="openDeleteModalNotification($event)" [notify]="notify"
                  *ngFor="let notify of notificationsToRead"></card-notify>
              </div>
            </ng-container>
            <!-- <ng-container
              *ngIf="(!notificationsToRead || !notificationsToRead.length) && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded">
              <no-data [title]="''" src="/assets/img/icons/notify_placeholder.svg"
                [description]="('notifications.NO_NOTIFICATIONS_TO_READ' | translate)">
              </no-data>
            </ng-container> -->
            <ng-container
              *ngIf="isFetchingMarkNotificationAsRead || isFetchingMarkNotificationAllAsRead || isFetchingDeleteNotification || isFetchingDeleteNotificationAll || isFetchingNotificationsToRead || isFetchingNotificationsReaded">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </div>

        <div class="readed-container">
          <div class="info">
            <p *ngIf="notificationsReaded && notificationsReaded.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded"
              translate="notifications.READED" class="title"></p>
            <p *ngIf="notificationsReaded && notificationsReaded.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded"
              translate="notifications.READED_LIST" class="description"></p>
            <a (click)="openDeleteModalNotificationReadedAll()"
              *ngIf="notificationsReaded && notificationsReaded.length" translate="notifications.DELETE_ALL_READED"></a>
            <ng-container
              *ngIf="notificationsReaded && notificationsReaded.length && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded">
              <div class="notify-container">
                <card-notify (onGoToDetail)="goToDetail($event)" (onDelete)="openDeleteModalNotificationReaded($event)"
                  [notify]="notify" *ngFor="let notify of notificationsReaded"></card-notify>
              </div>
            </ng-container>
            <!-- <ng-container
              *ngIf="(!notificationsReaded || !notificationsReaded.length) && !isFetchingMarkNotificationAsRead && !isFetchingMarkNotificationAllAsRead && !isFetchingDeleteNotification && !isFetchingDeleteNotificationAll && !isFetchingNotificationsToRead && !isFetchingNotificationsReaded">
              <no-data [title]="''" src="/assets/img/icons/notify_placeholder.svg"
                [description]="('notifications.NO_NOTIFICATIONS_READED' | translate)">
              </no-data>
            </ng-container> -->
            <ng-container
              *ngIf="isFetchingMarkNotificationAsRead || isFetchingMarkNotificationAllAsRead || isFetchingDeleteNotification || isFetchingDeleteNotificationAll || isFetchingNotificationsToRead || isFetchingNotificationsReaded">
              <div class="loader spinner-medium"></div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="isActiveGeneralPlaceholder()">
        <no-data [inNotifyPage]="true" [title]="('notifications.NO_NOTIFY' | translate)" [hideIcon]="true"
          [description]="('notifications.NO_NOTIFY_DESCRIPTION' | translate)">
        </no-data>
      </ng-container>
      <ng-container
        *ngIf="isFetchingMarkNotificationAsRead || isFetchingMarkNotificationAllAsRead || isFetchingDeleteNotification || isFetchingDeleteNotificationAll || isFetchingNotificationsToRead || isFetchingNotificationsReaded">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<modal id="modalDeleteNotification" (onConfirm)="markNotificationAsRead()" (onClose)="closeDeleteModalNotification()"
  (onCancel)="closeDeleteModalNotification()" cancelLabel="{{'generic.CANCEL' | translate}}"
  confirmLabel="{{'notifications.YES_DELETE_NOTIFICATION' | translate}}">
  <modal-text-content [modalId]="'201'" [title]="'notifications.DELETE_NOTIFICATION' | translate"
    [text]="'notifications.DO_YOU_WANT_DELETE_NOTIFICATION' | translate">
  </modal-text-content>
</modal>

<modal id="modalDeleteNotificationReaded" (onConfirm)="deleteNotification()"
  (onClose)="closeDeleteModalNotificationReaded()" (onCancel)="closeDeleteModalNotificationReaded()"
  cancelLabel="{{'generic.CANCEL' | translate}}"
  confirmLabel="{{'notifications.YES_DELETE_NOTIFICATION_READED' | translate}}">
  <modal-text-content [modalId]="'202'" [title]="'notifications.DELETE_NOTIFICATION_READED' | translate"
    [text]="'notifications.DO_YOU_WANT_DELETE_NOTIFICATION_READED' | translate">
  </modal-text-content>
</modal>

<modal id="modalReadAllNotifications" (onConfirm)="markNotificationAllAsRead()"
  (onClose)="closeReadAllNotificationsModalNotification()" (onCancel)="closeReadAllNotificationsModalNotification()"
  cancelLabel="{{'generic.CANCEL' | translate}}"
  confirmLabel="{{'notifications.YES_DELETE_ALL_NOTIFICATION' | translate}}">
  <modal-text-content [modalId]="'203'" [title]="'notifications.DELETE_NOTIFICATION_ALL' | translate"
    [text]="'notifications.DO_YOU_WANT_DELETE_NOTIFICATION_ALL' | translate">
  </modal-text-content>
</modal>

<modal id="modalDeleteAllNotificationsReaded" (onConfirm)="deleteNotificationsAll()"
  (onClose)="closeDeleteModalNotificationReadedAll()" (onCancel)="closeDeleteModalNotificationReadedAll()"
  cancelLabel="{{'generic.CANCEL' | translate}}"
  confirmLabel="{{'notifications.YES_DELETE_ALL_NOTIFICATION_READED' | translate}}">
  <modal-text-content [modalId]="'204'" [title]="'notifications.DELETE_NOTIFICATION_ALL_READED' | translate"
    [text]="'notifications.DO_YOU_WANT_DELETE_NOTIFICATION_ALL_READED' | translate">
  </modal-text-content>
</modal>