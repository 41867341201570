<div class="objective-container">
  <p class="info-date">
    {{('midTerm.addMidTermReview.ASSIGNED_AT' | translate) + goal.creationDate}}
  </p>
  <p class="objective-title">
    {{goal.title}}
  </p>

  <!-- Descrizione -->
  <ng-container *ngIf="goal.description">
    <div class="description-container">
      <div class="description">
        <p *ngIf="!descriptionIsOpened">
          <span class="title" translate="generic.DESCRIPTION"></span>
          <span *ngIf="isInnerHtml" innerHTML="{{ goal.description | truncate: [200, '...'] }}"></span>
          <span *ngIf="!isInnerHtml">{{ goal.description | truncate: [200, '...'] }}</span>
        </p>
        <p *ngIf="descriptionIsOpened">
          <span class="title" translate="generic.DESCRIPTION"></span>
          <span *ngIf="!isInnerHtml">{{ goal.description }}</span>
          <span *ngIf="isInnerHtml" innerHTML="{{ goal.description}}"></span>
          <span *ngIf="!isOperations" class="title padding-top7">{{ goal.careTitle }}</span>
          <span *ngIf="!isOperations">{{ goal.careDescription }}</span>
          <span *ngIf="isOperations && goal.type != 'OPERATIONS_COMPETENCES'" class="title"
            translate="goal.RAGGIUNGEMENT_GOAL_TEXT"></span>
          <span *ngIf="isOperations && goal.type != 'OPERATIONS_COMPETENCES'"
            [innerHTML]="goal.describeWhatYouIntendToDoForWinChallenge">
          </span>
          <span *ngIf="isOperations && goal.type == 'OPERATIONS_COMPETENCES'" [innerHTML]="goal.description"></span>
        </p>
      </div>
      <div class="action-buttons">
        <div *ngIf="descriptionIsOpened" (click)="toggleDescription()" class="show-less-button">
          <a translate="goalSetting.SHOW_LESS"></a>
          <svg-icon src="./assets/img/icons/chevron-up.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>

        </div>
        <div *ngIf="!descriptionIsOpened && !isInnerHtml && goal.careTitle && goal.careDescription"
          (click)="toggleDescription()" class="show-all-button">
          <a translate="goalSetting.SHOW_ALL"></a>
          <svg-icon src="./assets/img/icons/chevron-down.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>
        </div>
        <div *ngIf="!descriptionIsOpened && isInnerHtml" (click)="toggleDescription()" class="show-all-button">
          <a translate="goalSetting.SHOW_ALL"></a>
          <svg-icon src="./assets/img/icons/chevron-down.svg" class="small-size blue-primary" [applyClass]="true">
          </svg-icon>
        </div>
      </div>
    </div>
  </ng-container>
</div>