import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as CoreActions from "../../core/ngrx/core.actions";
import { AlloyPerformanceCpp } from 'atfcore-commonclasses';

@Component({
  selector: 'admin-cpp-details',
  templateUrl: './cpp-details.component.html',
  styleUrls: ['./cpp-details.component.scss']
})
export class CppDetailsComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  usersFromRecord: number = 0;
  usersNumRecords: number = 5;
  usersCurrentPage: number = 0;
  usersCounter: number = 0;
  isLoadingUsers: boolean = true;
  selectedUserList: any[] = []
  searchedText: string = '';

  usersList: any[] = [];
  translations: any;
  allChecked: boolean = false;
  selectedFilters: any = {};

  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  runningPhase: any;
  selectedFiltersNumber: number = 0;
  loggedUser: any;

  roundId: string = '';
  isLoadingCppUserFilters: boolean = false;
  isLoadingCppTemplate: boolean = false;
  importFile: any;
  resetIncludeUpload: boolean = false;
  isLoadingCppRound: boolean = false;
  cppRound?: AlloyPerformanceCpp.CppRound;
  isPassedCppRoundStartDate: boolean = false;
  today = new Date();
  selectedUsersToInclude: number = 0;
  selectedUsersToExclude: number = 0;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Lista degli utenti");

    this.selectedRows = this.rowPerPageOptions[2];
    this.usersNumRecords = this.selectedRows.id;

    const runningYear$: Observable<any> = this.store.select(fromApp.getRunningYear);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([runningYear$, loggedUser$])
      .subscribe(
        ([runningYear, loggedUser]) => {
          this.runningYear = runningYear;
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
          this.route.params
            .subscribe(
              (params: Params) => {
                this.roundId = params.roundId;
                this.getCppRound();
              })
        });
  }

  ngOnInit() {
  }

  getCppRound() {
    this.isLoadingCppRound = true;
    this.adminService.getCppRounds('0', '1', [this.roundId])
      .subscribe((data: SenecaResponse<AlloyPerformanceCpp.CppRound[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "save001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.cppRound = data.response[0];
          this.isPassedCppRoundStartDate = this.today > new Date(this.cppRound.startDate);
        }
        this.isLoadingCppRound = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "save002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppRound = false;
      });
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    if (this.selectedUserList && this.selectedUserList.length) {
      this.selectedUserList.length = 0;
    }
    if (this.usersList && this.usersList.length > 0) {
      for (let i = 0; i < this.usersList.length; i++) {
        this.usersList[i].isChecked = this.allChecked;
        if (this.allChecked) {
          this.addRemoveUser(this.usersList[i]);
        }
      }
    }
  }

  // ricerca
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.resetUserData();
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.usersList = [];
  }

  // Recupera una lista di utenti
  getUsersList(fromSearch: any) {
    if (fromSearch) {
      this.resetUserData();
    }
    sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    // Avvio il loader
    this.isLoadingUsers = true;
    this.adminService.countCppRoundUsers(this.roundId, this.searchedText, this.selectedFilters)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.usersCounter) {
                return this.adminService.getCppRoundUsers(this.roundId, this.searchedText, fromRecord, this.usersNumRecords, this.selectedFilters);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let user = data.response[i];
              let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);
              if (selected) {
                user.isChecked = true;
              }
            }
            this.usersList = data.response;
          }
          this.isLoadingUsers = false;
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista 
  usersPageChanged(newPage: any) {
    this.usersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsersList(false);
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  backCheckedUsers() {
    let usersChecked = this.selectedUserList.filter((x: any) => {
      return x.isChecked
    })

    let userIdsChecked = usersChecked.map((x: any) => {
      return x.userId
    })

    return userIdsChecked;
  }


  downloadReport(userId?: string) {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }

    let service = userId?.length ? this.adminService.downloadCppReport(this.roundId, [userId]) : this.adminService.downloadCppReport(this.roundId, this.backCheckedUsers())

    this.downloadReport$ = service
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  includeUsers(userId?: string, notCallOther?: boolean) {
    this.isLoadingUsers = true;
    let userIds: any[];
    if (userId) {
      userIds = [userId];
    } else {
      let toIncludeUsers = this.selectedUserList.filter((x: any) => {
        return !x.isInRound;
      })
      let userIdsToInclude = toIncludeUsers?.length ? toIncludeUsers.map((x: any) => {
        return x.userId
      }) : [];

      userIds = userIdsToInclude?.length ? userIdsToInclude : []
    }

    if (userIds?.length) {
      this.adminService.includeCppUsers(this.roundId, userIds)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "save001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!userId) {
              if (this.areThereSelectedUsersIncluded() && !notCallOther) {
                this.excludeUsers('', true);
              } else {
                this.selectedUserList = [];
                this.getUsersList(false);
              }
            } else {
              this.selectedUserList = [];
              this.getUsersList(false);
            }

          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "save002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  excludeUsers(userId?: string, notCallOther?: boolean) {
    this.isLoadingUsers = true;
    let userIds: any[];
    if (userId) {
      userIds = [userId];
    } else {
      let toExcludeUsers = this.selectedUserList.filter((x: any) => {
        return x.isInRound;
      })
      let userIdsToExclude = toExcludeUsers?.length ? toExcludeUsers.map((x: any) => {
        return x.userId
      }) : [];

      userIds = userIdsToExclude?.length ? userIdsToExclude : [];
    }

    if (userIds?.length) {
      this.adminService.excludeCppUsers(this.roundId, userIds)
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "save001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (!userId) {
              if (this.areThereSelectedUsersExcluded() && !notCallOther) {
                this.includeUsers('', true);
              } else {
                this.selectedUserList = [];
                this.getUsersList(false);
              }
            } else {
              this.selectedUserList = [];
              this.getUsersList(false);
            }
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "save002",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        });
    }
  }

  getCppTemplate() {
    this.isLoadingCppTemplate = true;

    this.adminService.downloadCppTemplate(this.roundId)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "save001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppTemplate = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
                this.isLoadingCppTemplate = false;
              }, 500)
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingCppTemplate = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingCppTemplate = false;
          })
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "save002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCppTemplate = false;
      });
  }

  openImportModal() {
    this.resetIncludeUpload = true;
    this.importFile = null;
    this.modalService.open('import-modal');
  }


  closeImportModal(confirm?: boolean) {
    this.modalService.close('import-modal');
    if (confirm) {
      this.isLoadingCppTemplate = true;
      this.adminService.setCppTemplate(this.roundId, this.importFile)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingCppTemplate = false;
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.isLoadingCppTemplate = false;
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingCppTemplate = false;
          });
    }
  }


  assignFile(file: any) {
    this.importFile = file;
  }

  areThereSelectedUsersIncluded() {
    let included = this.selectedUserList?.filter((x: any) => {
      return x.isInRound;
    })

    return included?.length;
  }

  areThereSelectedUsersExcluded() {
    let excluded = this.selectedUserList?.filter((x: any) => {
      return !x.isInRound;
    })

    return excluded?.length;
  }

  openIncludeExcludeUsers() {
    this.modalService.open('include-exclude-modal');
    this.selectedUsersToInclude = this.areThereSelectedUsersExcluded();
    this.selectedUsersToExclude = this.areThereSelectedUsersIncluded();
  }

  closeIncludeExcludeUsers(confirm?: boolean) {
    this.modalService.close('include-exclude-modal');
    if(confirm) {
    if(this.selectedUsersToInclude) {
      this.includeUsers();
    } else {
      this.excludeUsers();
    }
  }
    this.selectedUsersToInclude = 0;
    this.selectedUsersToExclude = 0;
  }

  ngOnDestroy() {
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
  }
}


