import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mid-term-recap',
  templateUrl: 'mid-term-recap.component.html',
  styleUrls: ['./mid-term-recap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MidTermRecapComponent implements OnInit {
  @Input() user: any;
  @Input() observation: string = '';
  @Input() status: string = '';
  @Input() date: string = '';
  @Input() isPeopleAppraisal: boolean = false;
  MID_STATUS_SRC = {
    'LATE': "assets/img/icons/red-face.svg",
    'ON_LINE': "assets/img/icons/yellow-face.svg",
    'WELL_ADVANCED': "assets/img/icons/green-face.svg"
  }
  constructor() {
  }

  ngOnInit() {
  }

}