<div class="page-content-wrapper">
    <ng-container *ngIf="!isLoadingUser && loggedUser">
        <header-dossier [isUpwardFeedbackHeader]="true" [isAdminHeader]="false" [user]="loggedUser">
        </header-dossier>

        <div class="main-content-wrapper admin">
            <div class="table-container">
                <div class="header-section">
                    <p class="section-title" translate="upwardFeedback.peopleAppraisal.SENT_UPF"></p>
                </div>

                <!-- contenitore tabella -->
                <ng-container *ngIf="!isLoadingRoundList && roundData.list && roundData.list.length">

                    <div class="table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.SENT_TO' | translate)}}">
                                    </th>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.SENT_DAY' | translate)}}">
                                    </th>
                                    <th class="center"
                                        translate="{{ ('upwardFeedback.peopleAppraisal.table.STATUS' | translate)}}">
                                    </th>
                                    <th translate="{{ ('upwardFeedback.peopleAppraisal.table.ACTION' | translate)}}">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let round of roundData.list |
                                        slice:0:roundData.numRecords |
                                        paginate: { id: 'roundList',
                                            itemsPerPage: roundData.numRecords,
                                            currentPage: roundData.page,
                                            totalItems: roundData.counter 
                                        }">
                                    <td>
                                        <div class="table-avatar-container">
                                            <avatar-img [user]="round.user" size="small"></avatar-img>
                                            <p>{{ round.user.surname + ' ' + round.user.forename }}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {{ round.sent ? (round.sent | date: 'dd/MM/YYYY') : "--" }}
                                    </td>
                                    <td>
                                        <div class="status-evaluation">
                                            <item-list-team-status styleItem="fill" [type]="round.statusType"
                                                [message]="round.statusMessage">
                                            </item-list-team-status>
                                        </div>
                                    </td>
                                    <td>
                                        <p (click)="goToResults(round)" class="link"
                                            [ngClass]="{'disabled': round.actionDisabled}">
                                            {{ round.actionText }}
                                        </p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div class="table-footer">
                        <table-pagination id="roundList" (pageChanged)="pageChanged($event)" [noPadding]="true">
                        </table-pagination>
                        <div class="per-page-selector">
                            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false"
                                bindLabel="id" [tablePagination]="true">
                            </alloy-select>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!isLoadingRoundList && (!roundData.list || !roundData.list.length)">
                    <div class="admin-no-data-wrapper">
                        <p translate="generic.NO_DATA_FOUND"></p>
                    </div>
                </ng-container>
                <!-- loader -->
                <ng-container *ngIf="isLoadingRoundList">
                    <div class="loader-container">
                        <div class="loader spinner-medium"></div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="isLoadingUser">
        <div class="loader spinner-big"></div>
    </ng-container>
</div>