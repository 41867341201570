import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SenecaResponse } from 'src/commonclasses';
import { UserCard } from 'src/app/utils/classes.utils';
import { catchError, switchMap, take } from 'rxjs/operators';
import { UserDataUtils } from 'src/app/utils/user-data.utils';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';

@Component({
  selector: 'app-selfAssessment-createUpdatePeerFeedback',
  templateUrl: './createUpdatePeerFeedback.component.html',
  styleUrls: ['./createUpdatePeerFeedback.component.scss']
})
export class CreateUpdatePeerFeedbackComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  modalCreateUpdatePeerFeedbackId: string = '';
  modalCreateUpdatePeerFeedbackTitle: string = '';
  modalCreateUpdatePeerFeedbackSubtitle: string = '';
  modalCreateUpdatePeerFeedbackText: string = '';
  modalCreateUpdatePeerFeedbackTitleConfirmTextButton: string = '';
  modalCreateUpdatePeerFeedbackTextCloseTextButton: string = '';
  observationsModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  objectiveTitleModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  requestMessageModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  whoTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };

  feedbackId: string = '';
  isLoadingFeedback: boolean = false;
  feedbackObject: any = {};
  // Dati utenti
  isLoadingTeam: boolean = false;
  userCardData: {
    counter: number,
    fromRecord: number,
    numRecords: number,
    list: UserCard[],
    page: number
  } = {
      counter: 0,
      fromRecord: 0,
      numRecords: 10,
      list: [],
      page: 1
    };
  cardSelected: any;
  chargeUsersVariable: boolean = true;
  isFetchingUsers: boolean = false;
  searchUserText: string = '';
  selectedUserList: any[] = [];
  // Obiettivi da associare
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToFeedback$: any;
  objectiveOptions: any[] = [];
  selectedObjective: any;

  requestMessage: string = '';

  isResponse: boolean = false;
  createPeerFeedback$: Subscription = new Subscription;
  getFeedback$: Subscription = new Subscription;
  feedbackObjectCopy: any;
  isUpdate: boolean = false;
  answerPeerFeedback$: Subscription = new Subscription;
  isChief: boolean = false;
  isChief$: Subscription = new Subscription;

  constructor(
    public translate: TranslateService,
    public selfAssessmentService: SelfAssessmentService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService
  ) {
    this.isChief$ = this.store.select(fromApp.getIsChief).subscribe((isChief: boolean) => {
      this.isChief = isChief;
    })
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              // this.userId = params.userId;
              this.feedbackId = params.feedbackId;
              if (window.location.href.indexOf('response') > -1) {
                this.isResponse = true;
              }
              if (this.isResponse) {
                this.getFeedbackRequest();
              } else {
                this.getGoalsToAssociate()
                if (!this.feedbackId) {
                  this.chargeUsersVariable = false;
                  this.resetUsers();
                  this.getUsers();
                } else if (this.feedbackId) {
                  // Recupero la persona selezionata, se è presente nello store
                  // this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                  //     this.personDetails = subordinatesList && subordinatesList.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  //         return person.userId === this.userId;
                  //     })[0];
                  //     
                  // });
                  this.getFeedback();
                }
              }
            }
          );
      }
    });
  }


  ngOnInit() {
    this.translate.get(
      [
        'generic.INPUT_INFO',
        'peerFeedback.createUpdate.OBJECTIVE_TITLE',
        'peerFeedback.modals.OBJECTIVE_TITLE_INFO',
        'peerFeedback.createUpdate.REQUEST_MESSAGE',
        'peerFeedback.modals.REQUEST_MESSAGE_INFO',
        'peerFeedback.createUpdate.WHO',
        'peerFeedback.modals.WHO_INFO',
      ])
      .subscribe(translations => {
        this.objectiveTitleModal = {
          modalId: "pf001",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.OBJECTIVE_TITLE'],
          text: translations['peerFeedback.modals.OBJECTIVE_TITLE_INFO']
        };
        this.requestMessageModal = {
          modalId: "pf002",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.REQUEST_MESSAGE'],
          text: translations['peerFeedback.modals.REQUEST_MESSAGE_INFO']
        };
        this.whoTooltipModal = {
          modalId: "pf010",
          title: translations['generic.INPUT_INFO'],
          subtitle: translations['peerFeedback.createUpdate.WHO'],
          text: translations['peerFeedback.modals.WHO_INFO']
        };
      })
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociate() {
    this.isLoadingGoalsToAssociate = true;
    if (this.getGoalsToAssociateToFeedback$) {
      this.getGoalsToAssociateToFeedback$.unsubscribe();
    }
    this.getGoalsToAssociateToFeedback$ = this.selfAssessmentService.listGoalForPeerFeedback()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.objectiveOptions = data.response;
        } else {
          this.objectiveOptions.length = 0;
        }
        this.isLoadingGoalsToAssociate = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsToAssociate = false;
      });
  }

  // Recupera il feedback per l'edit (o risposta)
  getFeedback() {
    this.isLoadingFeedback = true;

    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }

    this.getFeedback$ = this.selfAssessmentService.getPeerFeedbackById(this.runningYear.year.toString(), this.feedbackId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackObject = data.response;
          this.selectedObjective = this.feedbackObject.goal;

          this.feedbackObjectCopy = JSON.parse(JSON.stringify(this.feedbackObject));
        }
        this.isLoadingFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        })
  }


  createPeerFeedback() {
    this.closeModalCreateUpdatePeerFeedback();
    this.isLoadingFeedback = true;
    if (this.createPeerFeedback$) {
      this.createPeerFeedback$.unsubscribe();
    }

    if (this.isObbligatoryDataInserted()) {
      let peerFeedback = {
        goalId: this.selectedObjective && this.selectedObjective.goalId,
        request: this.requestMessage
      };
      let userIds = this.selectedUserList.map((x) => x.id);

      this.createPeerFeedback$ = this.selfAssessmentService.createPeerFeedback(userIds, peerFeedback)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf020",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.obtainedBadges && data.obtainedBadges.length) {
              let badges: any[] = [];
              if (data.obtainedBadges.length > 1) {
                badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
              } else {
                badges = data.obtainedBadges;
              }

              let tmpBadgesList: BadgeObject[] = [];
              if (badges && badges.length) {
                tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
              }
            }
          }
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
    }
  }

  updatePeerFeedback() {
    this.closeModalCreateUpdatePeerFeedback();
    this.isLoadingFeedback = true;
    if (this.createPeerFeedback$) {
      this.createPeerFeedback$.unsubscribe();
    }
    let peerFeedback = {
      feedbackId: this.feedbackId,
      request: this.feedbackObject.request,
      goalId: this.selectedObjective && this.selectedObjective.goalId,
      recipientUserId: this.feedbackObject.recipientUser && this.feedbackObject.recipientUser.userId
    }
    if (this.isObbligatoryDataInserted()) {
      this.createPeerFeedback$ = this.selfAssessmentService.updatePeerFeedback(this.runningYear.year.toString(), peerFeedback)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf020",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.obtainedBadges && data.obtainedBadges.length) {
              let badges: any[] = [];
              if (data.obtainedBadges.length > 1) {
                badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
              } else {
                badges = data.obtainedBadges;
              }

              let tmpBadgesList: BadgeObject[] = [];
              if (badges && badges.length) {
                tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
              }
            }
          }
          this.closeConfirmRequest();
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
    }
  }


  observationsChanged(text: string) {
    this.feedbackObject.approverObservation = text;
  }

  searchUsers() {
    this.getUsers(true);
  }


  // Ripristina i dai della lista utenti per l'applauso
  resetUsers(notDeleteSelected?: boolean) {
    this.userCardData.fromRecord = 0;
    this.userCardData.numRecords = 16;
    this.userCardData.page = 1;
    this.userCardData.counter = 0;
    this.userCardData.list = [];
    if (!notDeleteSelected) {
      this.selectedUserList = [];
    }
  }

  // Recupera una lista di utenti
  getUsers(fromSearch?: boolean) {
    if (fromSearch) {
      this.resetUsers(true);
    }
    // Avvio il loader
    this.isFetchingUsers = true;
    this.selfAssessmentService.countUserForPeerFeedback(this.runningYear.year.toString(), this.searchUserText)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.userCardData.counter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.userCardData.page && this.userCardData.numRecords) {
                fromRecord = (this.userCardData.page - 1) * this.userCardData.numRecords;
              } else {
                fromRecord = 0;
              }

              if (this.userCardData.counter) {
                return this.selfAssessmentService.listUserForPeerFeedback(fromRecord, this.userCardData.numRecords, this.runningYear.year.toString(), this.searchUserText);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pf011",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isFetchingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "pf011",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.userCardData.list = UserDataUtils.getUserCardData(data.response) || [];
          }
          this.isFetchingUsers = false;
        }
        , (err: any) => {
          this.isFetchingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf012",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  changeSearchedTextValue(name: string) {
    this.searchUserText = name;
  }

  // Cambia la paginazione alla lista degli utenti per applausi aggiunti
  usersPageChanged(newPage: any) {
    this.userCardData.page = newPage;
    // Avvio una nuova ricerca
    this.getUsers();
  }

  selectUser(user: any) {
    let isPresent = this.selectedUserList.filter((x: any) => x.id == user.id);
    if (isPresent && isPresent.length) {
      this.selectedUserList = this.selectedUserList.filter((x: any) => x.id != user.id);
    } else {
      this.selectedUserList.push(user);
    }
  }

  changeAssociatedObjective(objective: any) {
    this.selectedObjective = objective;
  }

  requestMessageChanged(text: string) {
    if (this.feedbackId) {
      this.feedbackObject.request = text;
    } else {
      this.requestMessage = text;
    }
  }

  // Gestisce quando crei/aggiorni un'evidenza0
  goToUpdatePeerFeedback(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdatePeerFeedback(isBack);
      this.openModalForCreateUpdatePeerFeedback();
    }
  }

  formatModalCreateUpdatePeerFeedback(isBack?: boolean) {
    this.modalCreateUpdatePeerFeedbackId = this.getModalId(isBack);
    this.modalCreateUpdatePeerFeedbackTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdatePeerFeedbackSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdatePeerFeedbackText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdatePeerFeedbackTitleConfirmTextButton = this.getConfirmLabelCreateUpdateNoteModal(isBack);
    this.modalCreateUpdatePeerFeedbackTextCloseTextButton = this.getCancelLabelCreateUpdateNoteModal();
  }


  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'pf005';
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return 'pf006';
        } else {
          return 'pf007';
        }
      } else {
        if (this.feedbackId) {
          return 'pf008';
        } else {
          return 'pf009';
        }
      }
    }
  }

  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('generic.GO_BACK');
      } else {
        if (this.feedbackId) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK_REQUEST');
        } else {
          return this.translate.instant('peerFeedback.createUpdate.INSERT_FEEDBACK_REQUEST');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.feedbackId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_INSERTING_A_FEEDBACK');
    } else if (this.feedbackId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('peerFeedback.modals.YOU_ARE_UPDATING_A_FEEDBACK');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.feedbackId && this.feedbackId.length) {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      } else {
        return this.translate.instant('peerFeedback.modals.OBBLIGATORY_DATA_NOT_INSERTED');
      }
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK_BACK');
        }
      } else {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.modals.DO_YOU_WANT_CREATE_FEEDBACK');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateNoteModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateNoteModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.modals.UPDATE_FEEDBACK_BACK');
        } else {
          return this.translate.instant('peerFeedback.modals.INSERT_FEEDBACK_BACK');
        }
      } else {
        if (this.feedbackId && this.feedbackId.length) {
          return this.translate.instant('peerFeedback.createUpdate.UPDATE_FEEDBACK');
        } else {
          return this.translate.instant('peerFeedback.createUpdate.INSERT_FEEDBACK');
        }
      }
    }
  }

  answerPeerFeedback() {
    this.isLoadingFeedback = true;
    if (this.answerPeerFeedback$) {
      this.answerPeerFeedback$.unsubscribe();
    }
    let peerFeedback = {
      feedbackId: this.feedbackId,
      request: this.feedbackObject.request,
      goalId: this.feedbackObject.goal && this.feedbackObject.goal.goalId,
      approverObservation: this.feedbackObject.approverObservation,
      recipientUserId: this.feedbackObject.recipientUser && this.feedbackObject.recipientUser.userId
    }
    this.answerPeerFeedback$ = this.selfAssessmentService.updatePeerFeedback(this.runningYear.year.toString(), peerFeedback)
      .subscribe(
        (data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pf020",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            if (data.obtainedBadges && data.obtainedBadges.length) {
              let badges: any[] = [];
              if (data.obtainedBadges.length > 1) {
                badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
              } else {
                badges = data.obtainedBadges;
              }
              let tmpBadgesList: BadgeObject[] = [];
              if (badges && badges.length) {
                tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
                this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
              }
            }
          }
          this.goBackBrowser();
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "058",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        });
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    if (!this.isResponse) {
      if (this.feedbackId) {
        if (this.feedbackObject && this.feedbackObject.request && this.feedbackObject.request.trim().length) {
          return true;
        }
      } else {
        if (this.selectedUserList && this.selectedUserList.length && this.requestMessage && this.requestMessage.trim().length) {
          return true;
        }
      }
      return false;
    } else {
      if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.trim().length > 0) {
        return true;
      }
      return false;
    }
  }

  checkIfDataIsChanged() {
    // return this.note && this.noteCopy && ((this.note.comment != this.noteCopy.comment) || (this.note.motivation != this.noteCopy.motivation));
    if (this.isResponse) {
      return this.feedbackObject && this.feedbackObjectCopy && this.feedbackObject.approverObservation != this.feedbackObjectCopy.approverObservation;
    } else {
      if (this.feedbackId) {
        if ((this.feedbackObject.goal && this.selectedObjective && this.feedbackObject.goal.goalId !== this.selectedObjective.goalId)) {
          return true;
        }
        if (this.feedbackObject.request !== this.feedbackObjectCopy.request) {
          return true;
        }
        return false;
      }
      // else {
      //     return this.requestMessage
      // }
    }
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdatePeerFeedback() {
    this.modalService.open('modalCreateUpdatePeerFeedback');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdatePeerFeedback() {
    this.modalService.close('modalCreateUpdatePeerFeedback');
  }

  openConfrimRequest() {
    this.closeModalCreateUpdatePeerFeedback()
    this.modalService.open('modalConfrimRequest')
  }

  closeConfirmRequest() {
    this.modalService.close('modalConfrimRequest')
  }

  getFeedbackRequest() {
    this.isLoadingFeedback = true;

    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }

    this.getFeedback$ = this.selfAssessmentService.getPeerFeedbackById(this.runningYear.year.toString(), this.feedbackId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "i001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.feedbackObject = data.response;
          if (this.feedbackObject && this.feedbackObject.approverObservation && this.feedbackObject.approverObservation.length > 0) {
            this.isUpdate = true;
          }
          this.feedbackObjectCopy = JSON.parse(JSON.stringify(this.feedbackObject));
        }
        this.isLoadingFeedback = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "i002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingFeedback = false;
        })
  }



  ngOnDestroy() {
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getFeedback$) {
      this.getFeedback$.unsubscribe();
    }
  }
}