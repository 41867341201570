<ng-container *ngIf="!plainItems">
  <div class="alloy-radio-wrapper" *ngFor="let item of items"
    (click)="setValue(item)" [ngClass]="{ 'disabled': disabled, 'readonly': readonly, 'switch': switch}">
    <div tabindex="{{tabIndex}}" class="radio-empty"
      [ngClass]="{'checked-radio': bindValue && bindValue.id === item.id , 'alloy-check-index' : tabIndex != -1}"  (keyup.enter)="setValue(item)">
      <div *ngIf="bindValue && bindValue.id === item.id" class="checked"></div>
    </div>
    <p *ngIf="withLabel">{{ item.value }}</p>
  </div>
</ng-container>

<ng-container *ngIf="plainItems ">
  <div class="alloy-radio-wrapper" *ngFor="let item of plainItems; let index = index;" (click)="setValue(item, index)"
    [ngClass]="{ 'disabled': disabled, 'readonly': readonly, 'switch': switch}">
    <div class="radio-empty" [ngClass]="{'checked-radio': bindValueIndex === index}">
      <div *ngIf="bindValueIndex === index" class="checked"></div>
    </div>
    <p *ngIf="withLabel">{{ item }}</p>
  </div>
</ng-container>