import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from '../../ngrx/app.reducers';

@Component({
  selector: 'app-compatibility-error',
  templateUrl: './compatibility-error.component.html',
  styleUrls: ['./compatibility-error.component.scss']
})
export class CompatibilityErrorComponent {
  isImpersonate: boolean = false;
  constructor(
    private store: Store<fromApp.AppState>,
    public redirectService: RedirectService
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })
  }

}
