<div class="accordion-container" [ngClass]="{'disabled': isDisabled, 'gray-background': grayBackground}">
  <div class="accordion-header">
    <div class="accordion" [ngClass]="{'restore-margin': !isAccordionOpened}">
      <div class="title-accordion">
        <p class="title-info">
          {{ title }}
        </p>
      </div>
      <!-- barra percentuale desktop -->
      <ng-container *ngIf="!isMobile && !hidePercentage">
        <ng-container *ngIf="isLoading">
          <div class="loader spinner-small"></div>
        </ng-container>

        <ng-container *ngIf="!isLoading">
          <div class="percentage-container">
            <p translate="generic.PROGRESS"></p>
            <div class="progress-bar">
              <div class="progress" [ngStyle]="{'width': percentage + '%'}"></div>
            </div>
            <p><b>{{ percentage }} %</b></p>
          </div>
        </ng-container>


      </ng-container>
      <!-- Icona accordion -->
      <svg-icon *ngIf="!withOutRightIcon" (click)="openAccordion()" src="./assets/img/icons/chevron-down.svg"
        class="medium-accordion-size-transition blue-primary"
        [ngClass]="{'gray-gray2': isDisabled, 'opened': isAccordionOpened}" [applyClass]="true">
      </svg-icon>
    </div>
    <!-- barra percentuale mobile -->
    <ng-container *ngIf="isMobile && !hidePercentage">
      <ng-container *ngIf="isLoading">
        <div class="loader spinner-small"></div>
      </ng-container>
      <ng-container *ngIf="!isLoading">
        <div class="percentage-container mobile">
          <p translate="generic.PROGRESS"></p>
          <div class="progress-bar">
            <div class="progress" [ngStyle]="{'width': percentage + '%'}"></div>
          </div>
          <p><b>{{ percentage }} %</b></p>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isAccordionOpened">
      <div class="border-divider margin-top20"></div>
    </ng-container>
  </div>
  <div [@showIf] *ngIf="isAccordionOpened" class="full-width">
    <ng-content></ng-content>
  </div>
</div>