import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  isFetchingLangs: boolean = false;
  langs: any;
  result$: any;
  usernameModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  passwordModalMessage: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  }
  loginForm = {
    email: '',
    password: ''
  }
  showLoader: boolean = false;

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: any = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const combinedSelectes$ = combineLatest([availableLangs$, isFetchingLangs$]);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
      });
  }

  ngOnInit() {
    this.usernameModalMessage = {
      modalId: "143",
      title: this.translate.instant('login.modals.EMAIL'),
      text: this.translate.instant('login.modals.EMAIL_DESCR')
    }
    this.passwordModalMessage = {
      modalId: "144",
      title: this.translate.instant('login.modals.PASSWORD'),
      text: this.translate.instant('login.modals.PASSWORD_DESCR')
    }
  }

  ngOnDestroy() {
    this.result$.unsubscribe();
  }

  // Quando scrivi sull'email
  emailChanged(text: string) {
    this.loginForm.email = text;
  }

  // Quando scrivi sulla password
  passwordChanged(text: string) {
    this.loginForm.password = text;
  }

  // Schiaccio pulsante accedi
  onLogin() {
    this.showLoader = true;
    setTimeout(() => {
      this.showLoader = false;
    }, 1300)
    const email = this.loginForm.email;
    const password = this.loginForm.password;
    this.store.dispatch(AuthActions.DoLogin({ email: email, password: password, isLdap: true }));
  }

  // Pulsante login è disabilitato
  loginButtonIsActive() {
    if (this.loginForm.email && this.loginForm.email.trim().length && this.loginForm.password && this.loginForm.password.trim().length) {
      return true;
    }
    return false;
  }

  // Valida l'email
  emailValidate() {
    let regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{1,4})+$/;
    if (regex.test(this.loginForm.email.trim())) {
      return true;
    }
    return false;
  }
}