/*
* Componente per i materiali Azure
*/

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AzurePlayerService } from './azure-player.service';
import { parseBoolean, VideoSubtitle } from '../../../commonclasses';
import { UrlService } from "../../shared/services/url.service";
declare let amp: any;

@Component({
  selector: 'azure-player',
  templateUrl: './azure-player.component.html',
  styleUrls: ['./azure-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AzurePlayerComponent implements OnInit {
  @ViewChild('video') videoPlayer: any;
  @Input() isDisabled: boolean = false;
  @Input() itemId: any;
  @Input() playerVisible: boolean = false;
  @Input() seekTo: any;
  @Input() mimeType: string = '';
  @Input() objectUrl: string = '';
  @Input() fullScreen: boolean = false;
  @Input() adminMode: boolean = false;
  @Input() subtitles: VideoSubtitle[] = [];
  lastInProgressUpdate: any;
  watchInterval: any;
  azureMediaPlayer: any;
  @ViewChild("contentPlayer") contentPlayerElement: any;
  @Output() itemInProgress = new EventEmitter<any>();
  @Output() itemConsumed = new EventEmitter<any>();
  @Output() playerStatusChange = new EventEmitter<any>();

  constructor(protected _sanitizer: DomSanitizer,
    private playerService: AzurePlayerService,
    private urlService: UrlService) {
  }

  ngOnInit() {
    this.playerVisible = parseBoolean(this.playerVisible);
    this.adminMode = parseBoolean(this.adminMode);

    if (!this.objectUrl) {
      this.playerVisible = false;
    }
    if (this.playerVisible) {
      this.initVideoPlayer();
    }
  }

  createWatchInterval() {
    this.watchInterval = setInterval(() => {
      let now = new Date().getTime();
      // Se sono passati più di 5 secondi dall'ultima volta che sono passato per questo evento, invio lo stato di avanzamento al sistema
      let totalTime = this.azureMediaPlayer.duration();
      let currentTime = this.azureMediaPlayer.currentTime();
      // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
      let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
      // Evito di salvarmi le informazione di avanzamento quando sono troppo prossimo alla fine per evitare conflitti con il completato
      if ((!this.lastInProgressUpdate || this.lastInProgressUpdate + 5000 < now) && currentTime < safeLimit) {
        let viewedSeconds = 0;
        if (this.lastInProgressUpdate) {
          viewedSeconds = (now - this.lastInProgressUpdate) / 1000;
        }
        this.lastInProgressUpdate = now;
        // Non mi interessa l'esito di questa operazione (se va male, amen)
        this.playerService.updatePlayerStatus(this.itemId, "viewed", totalTime, currentTime, viewedSeconds).subscribe();
      }
    }, 500);
  }

  disposeWatchInterval = () => {
    if (this.watchInterval) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
  }
  /*
      ngOnDestroy() {
          this.disposeWatchInterval();
          // Dato che Azure sembra non eliminare i listener correttamente, elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
          this.itemId = null;
          // Distruggo la widget di Azure
          if (this.azureMediaPlayer) {
              this.azureMediaPlayer.dispose();
          }
      } */

  initVideoPlayer() {
    let _this = this;
    // E chiamo il metodo che registra gli hooks e inizializza il contenuto multimediale
    this.azureMediaPlayer = amp('contentPlayer', {
      /* Options */
      techOrder: ["azureHtml5JS", "flashSS", "html5FairPlayHLS", "silverlightSS", "html5"],
      nativeControlsForTouch: false,
      autoplay: false,
      controls: true,
      fluid: true,
      logo: { enabled: false }
      /*
      width: "640",
      height: "400",
      poster: ""
      */
    }, function () {
      /*
      Altri possibili eventi da gestire: http://amp.azure.net/libs/amp/latest/docs/index.html#amp.eventname
      */

      /* if (_this.itemId && !_this.adminMode) {
         let ignoreFirstSeek = false;
 
         // Aggiungo gli Event Listener per tracciare i vari eventi
         this.addEventListener('loadeddata', () => {
           // Se devo quindi riprendo da dov'ero, ma dato che il player di Azure gestisce molto male la fine del filmato, se ci sono vicino, non riporto l'dipendente lì
           let totalTime = _this.azureMediaPlayer.duration();
           if (_this.seekTo && (_this.seekTo < totalTime - 3 /* sec)) {
             ignoreFirstSeek = true;
             _this.azureMediaPlayer.currentTime(_this.seekTo);
             _this.seekTo = null;
           }
         });
 
         this.addEventListener('pause', () => {
           _this.disposeWatchInterval();
           let now = new Date().getTime();
           let totalTime = _this.azureMediaPlayer.duration();
           let currentTime = _this.azureMediaPlayer.currentTime();
           let viewedSeconds = 0;
           if (_this.lastInProgressUpdate) {
             viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
           }
           // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi come area tengo l'1% max 1 secondo
           let safeLimit = (totalTime * 0.01) < 1 ? totalTime * 0.99 : totalTime - 1;
           // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo o superiore a totalTime, quindi questa combinazione la ignoro
           if (!(currentTime >= safeLimit)) { // Il player di Azure gestisce molto male la fine del filmato, quindi tengo un lasco grande
             // Nel caso in cui questa pausa sia dovuta al termine del video non azzero il tempo, sennò lo perderei dall'evento end
             _this.lastInProgressUpdate = 0; // Forzo a 0 così l'eventuale seek da pausa non genera tempo consumato
             // Non mi interessa l'esito di questa operazione (se va male, amen)
             _this.playerService.updatePlayerStatus(_this.itemId, "paused", totalTime, currentTime, viewedSeconds).subscribe();
 
             _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
           }
         });
 
         this.addEventListener('playing', () => {
           if (!_this.watchInterval) {
             let now = new Date().getTime();
             let totalTime = _this.azureMediaPlayer.duration();
             let currentTime = _this.azureMediaPlayer.currentTime();
             let viewedSeconds = 0;
             if (_this.lastInProgressUpdate) {
               viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
             }
             _this.lastInProgressUpdate = now;
             _this.playerService.updatePlayerStatus(_this.itemId, "playing", totalTime, currentTime, viewedSeconds).subscribe();
             _this.itemInProgress.emit({ currentTime: currentTime, totalTime: totalTime });
             _this.createWatchInterval();
           }
         });
 
         this.addEventListener('ended', () => {
           _this.disposeWatchInterval();
           let now = new Date().getTime();
           // Segnalo il termine della fruizione
           _this.itemConsumed.emit(true);
           let totalTime = _this.azureMediaPlayer.duration();
           let currentTime = _this.azureMediaPlayer.currentTime();
           let viewedSeconds = 0;
           if (_this.lastInProgressUpdate) {
             viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
           }
           _this.lastInProgressUpdate = 0; // Ho finito di vederlo, fino al prossimo play non devo tracciare niente
           // Non mi interessa l'esito di questa operazione (se va male, amen)
           _this.playerService.updatePlayerStatus(_this.itemId, "end", totalTime, currentTime, viewedSeconds).subscribe();
         });
 
         this.addEventListener('seeked', () => {
           if (ignoreFirstSeek) {
             ignoreFirstSeek = false;
           }
           else {
             let now = new Date().getTime();
             let totalTime = _this.azureMediaPlayer.duration();
             let currentTime = _this.azureMediaPlayer.currentTime();
             let viewedSeconds = 0;
             if (_this.lastInProgressUpdate) {
               viewedSeconds = (now - _this.lastInProgressUpdate) / 1000;
             }
             // Dato che non so il media è in play o meno e che se l'dipendente facesse un seek, poi aspettasse ore e poi un altro seek il tutto in pausa
             // verrebbero tracciate le ore, quindi forzo a zero. So che così l'dipendente perderà il tempo visto tra un seek e l'altro se lo fa entro 5 secondi
             // ma tutto sommato è un tempo sacrificabile e ci può stare: premiamo chi vede di fila, non ci salta di continuo.
             _this.lastInProgressUpdate = 0;
             // Non mi interessa l'esito di questa operazione (se va male, amen)
             _this.playerService.updatePlayerStatus(_this.itemId, "seeked", totalTime, currentTime, viewedSeconds).subscribe();
           }
         });
 
         this.addEventListener('disposing', () => {
           _this.disposeWatchInterval();
         });
       } */

      // Ora aggiungo l'URL dell'oggetto multimediale
      let mediaUrl = [{
        src: _this.objectUrl,
        type: _this.mimeType || "application/vnd.ms-sstr+xml"
      }];
      // Se devo ci aggiungo anche i sottotitoli
      let subtitleUrls = null;
      /* if (_this.subtitles && _this.subtitles.length) {
         subtitleUrls = _this.subtitles.map(s => {
           return {
             src: _this.urlService.getApplicationUrl().baseUrl + "rest-api/alloyperformance-mediator/proxy-url/" + s.lang + ".vtt?requestUrl=" + encodeURIComponent(s.url),
             srclang: s.lang,
             kind: "subtitles",
             label: s.title
           };
         });
       }*/
      // E infine imposto i dati nel player così da caricarli
      if (subtitleUrls) {
        _this.azureMediaPlayer.src(mediaUrl, subtitleUrls);
      }
      else {
        _this.azureMediaPlayer.src(mediaUrl);
      }
    });
  }

  ngOnDestroy() {
    this.disposeWatchInterval();
    // Dato che Azure sembra non eliminare i listener correttamente, elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
    this.itemId = null;
    // Distruggo la widget di Azure
    if (this.azureMediaPlayer) {
      this.azureMediaPlayer.dispose();
    }
  }
}