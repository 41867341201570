<div class="star-rating-wrapper" [ngClass]="getWrapperClass()">

  <div tabindex="0" attr.aria-label="{{'competence.VALUTATE_COMPETENCE' | translate}}" fxLayout="row"
    fxLayoutAlign="end end" fxLayoutAlign.xs="start start" fxLayoutGap="4px">

    <button attr.aria-label="{{('competenceMark.' + (i + 1)) | translate }}" (click)="buttonClicked(star.id)"
      *ngFor="let star of rating; let i = index" (mouseover)="onStarMouseOver(star.id)" (mouseout)="onStarMouseOut()"
      class="button-star"
      [ngClass]="{ 'hovered' : star.isHovered || star.isClicked, 'disabled': disabled, 'gray': grayColor, 'forced-gray': avgForceGray}">
      <svg-icon src="assets/img/icons/star.svg" [applyClass]="true" class="star-icon">
      </svg-icon>
    </button>
  </div>

  <ng-container *ngIf="!hideLabel">
    <div class="mark-label" fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'rating': direction == 'row'}">
      <ng-container *ngIf="forceLabel && forceLabel.length">
        <p>{{ forceLabel }}</p>
      </ng-container>
      <ng-container *ngIf="!forceLabel || !forceLabel.length">
        <p>{{ markLabel }}</p>
      </ng-container>
    </div>
    <div *ngIf="showFullNumber" class="full-number" fxLayout="row" fxLayoutAlign="center center">
      <p>{{ fullNumber || 0 }}</p>
    </div>
  </ng-container>
</div>