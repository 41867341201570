import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../ngrx/app.reducers";
import * as CoreActions from "../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { JwtPayload } from 'src/commonclasses';

@Component({
  selector: 'app-impersonate-home-redirect',
  templateUrl: './impersonate-home-redirect.component.html',
  styleUrls: ['./impersonate-home-redirect.component.scss']
})
export class ImpersonateRedirectHomeComponent implements OnDestroy {
  //combinedSelected$: Subscription;
  runningYear: any;
  loggedUser: any;
  currentYear = new Date().getFullYear();


  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    public redirectService: RedirectService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    public urlService: UrlService
  ) {
    this.store.dispatch(CoreActions.SetImpersonificationBanner({ payload: true }));

    this.store.select(fromApp.getLoggedUser).subscribe(
      (loggedUser) => {
        if (loggedUser && loggedUser.user) {
          this.redirectService.goToHome();
        }
      })
  }

  ngOnInit() {
  }


  ngOnDestroy() {

  }
}