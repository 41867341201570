<div class="page-content-wrapper admin-cpp">

  <header-dossier [user]="loggedUser" [isAdminHeader]="true" [adminBack]="true" [adminActionLabelType]="'success'"
    (onAdminAction)="exportCppDashboardReport()" [showLoader]="isDownloadingReport"
    [adminPageTitle]="'dashboardCpp.TITLE' | translate" [hasTabs]="false"
    [adminActionLabel]="isCppDashboardView()? ('dashboard.DOWNLOAD_REPORT' | translate): ''"
    [adminPageSubtitle]="roundId ? ('Round ' + (cppRoundName ? cppRoundName : '')) : ('dashboardCpp.WHAT_CPP_ROUND' | translate)">
  </header-dossier>

  <!-- Home processi -->
  <ng-container *ngIf="!roundId">
    <div class="main-content-wrapper admin">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingCppRoundsData">
        <div class="admin-table-container content">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('cppAdmin.CPP_ROUND' | translate)}}"></th>
                  <th translate="{{ ('cppAdmin.OPEN_DATE_CPP' | translate)}}"></th>
                  <th translate="{{ ('adminTable.PEOPLE_INVOLVED' | translate)}}"></th>
                  <th translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                  <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let process of cppRoundData.list | 
                                slice:0:cppRoundData.numRecords | 
                                paginate: { id: 'roundList',
                                            itemsPerPage: cppRoundData.numRecords,
                                            currentPage: cppRoundData.page,
                                            totalItems: cppRoundData.counter }">
                  <td>{{ process.name }}</td>
                  <td>{{ process.startDate | date: 'dd/MM/YYYY' }}</td>
                  <td>{{ process.totalParticipants || 0 }}</td>
                  <td>
                    <item-list-team-status [type]="process.statusType" styleItem="fill"
                      [message]="process.statusMessage"></item-list-team-status>
                  </td>
                  <td class="link" (click)="goToRoundDetails(process)">
                    {{ 'adminTable.ACCESS' | translate }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="roundList" (pageChanged)="roundPageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingCppRoundsData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <ng-container *ngIf="roundId">
    <div class="main-content-wrapper admin-cpp">

      <div class="dashboard-tabs-container">
        <ng-container *ngFor="let tab of dashboardTabs">
          <tab-light [tabTitle]="tab.title" [isDisabled]="tab.isDisabled" (onClick)="changeCurrentTab(tab)"
            [blackHighlight]="true"></tab-light>
        </ng-container>
      </div>

      <!--Contenitore degli indicatori-->
      <div class="content">
        <!-- Filtri -->
        <div class="filters-recap">
          <!-- Contenuto filtri -->
          <div class="filter-content">
            <div class="title-container">
              <div class="info-title">
                <p class="title">
                  {{ ('dashboardCpp.FILTERS') | translate }}
                </p>
              </div>
            </div>


            <!-- Filtri -->
            <anag-filters [showAreaRef]="true" [showDepartment]="true" [searchEnabled]="false" [isCppDashboard]="true"
              [roundId]="roundId" (updateSelectedFilters)="updateSelectedFilters($event)" [keepFilters]="false"
              (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getGraphData($event)"
              [showTeam]="false" [showSupervisor]="false" [showSubCompany]="false" [showCdc]="false"
              [showPosition]="false" [showContractual]="false">
            </anag-filters>

          </div>
        </div>

        <div class="accordion-list" *ngIf="selectedTab?.id == 'avancement-dashboard'">

          <!-- Self assessment -->
          <percentage-accordion [title]="'dashboard.accordion.SELF_ASSESSMENT' | translate"
            [percentage]="selfAssessmentPercentage" [isAccordionOpened]="selfAssessmentOpen"
            [isLoading]="isLoadingSelfAssessmentData" (onAccordionOpen)="redrawGraphs('self-assessment')">
            <div class="accordion-content">

              <ng-container *ngIf="!isLoadingSelfAssessmentData">

                <div class="dashboard" *ngIf="selfAssessmentProgress">
                  <!-- Se ho dati -->
                  <ng-container *ngIf="!selfAssessmentNoData; else selfPlaceholder">
                    <!-- Indicatore self -->
                    <div class="section">
                      <dx-circular-gauge id="selfAssessmentGauge" [value]="selfAssessmentProgress.completedPercentage"
                        (onInitialized)="saveInstance($event, 'selfAssessmentGauge')">
                        <dxo-title [text]="'dashboard.PROGRESS' | translate">
                          <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                          </dxo-font>
                          <dxo-subtitle>
                            <dxo-font color="#767676" weight="300" size="12px">
                            </dxo-font>
                          </dxo-subtitle>
                        </dxo-title>

                        <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                        <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                        </dxo-value-indicator>
                        <dxo-range-container palette="pastel">
                          <dxi-range [startValue]="0" [endValue]="50" color="#FF6600"></dxi-range>
                          <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                        </dxo-range-container>
                        <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                        <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                          <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                        </dxo-scale>
                      </dx-circular-gauge>


                      <div class="more-graph-info">
                        <p class="number">{{ selfAssessmentProgress.sent + '/' + selfAssessmentProgress.total}}</p>
                      </div>
                    </div>

                    <!-- Chart self -->
                    <div class="section right">
                      <dx-chart id="selfAssessmentChart" [dataSource]="selfAssessmentProgress.chartData"
                        [palette]="careColors" (onInitialized)="saveInstance($event, 'selfAssessmentChart')"
                        [rotated]="true">

                        <dxo-title>
                          <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                          </dxo-font>
                          <dxo-subtitle [text]="'dashboard.SELF_NUM_DESCR' | translate">
                            <dxo-font color="#767676" weight="300" size="12px">
                            </dxo-font>
                          </dxo-subtitle>
                        </dxo-title>

                        <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                        <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                          [ignoreEmptyPoints]="true" [barPadding]="0.5">
                        </dxo-common-series-settings>
                        <dxo-series-template nameField="attribute"></dxo-series-template>
                        <dxo-legend [visible]="true" position="outside" horizontalAlignment="center"
                          verticalAlignment="bottom">
                        </dxo-legend>
                        <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                        <dxo-argument-axis>
                          <dxo-label [visible]="false"></dxo-label>
                        </dxo-argument-axis>
                      </dx-chart>

                      <div class="more-graph-info"></div>
                    </div>
                  </ng-container>

                  <!-- Placeholder se non ho dati -->
                  <ng-template #selfPlaceholder>
                    <div class="section">
                      <dx-circular-gauge id="selfAssessmentGauge" [value]="selfAssessmentProgress.completedPercentage"
                        (onInitialized)="saveInstance($event, 'selfAssessmentGauge')">
                        <dxo-title [text]="'dashboard.PROGRESS' | translate">
                          <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                          </dxo-font>
                          <dxo-subtitle>
                            <dxo-font color="#767676" weight="300" size="12px">
                            </dxo-font>
                          </dxo-subtitle>
                        </dxo-title>

                        <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                        <dxo-value-indicator type="triangleNeedle" color="#fff">
                        </dxo-value-indicator>
                        <dxo-range-container palette="pastel">
                          <dxi-range [startValue]="0" [endValue]="50" color="#dbdbdb"></dxi-range>
                          <dxi-range [startValue]="50" [endValue]="100" color="#dbdbdb"></dxi-range>
                        </dxo-range-container>
                        <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                        <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                          <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                        </dxo-scale>
                      </dx-circular-gauge>


                      <div class="more-graph-info">
                        <p class="number">0/0</p>
                        <p>{{('generic.NO_RESULTS' | translate)}}</p>
                      </div>
                    </div>
                    <div class="section right">

                      <dx-chart id="selfAssessmentChart" [dataSource]="selfAssessmentProgress.chartData"
                        [palette]="careColors" (onInitialized)="saveInstance($event, 'selfAssessmentChart')"
                        [rotated]="true">

                        <dxo-title>
                          <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                          </dxo-font>
                          <dxo-subtitle [text]="'dashboard.SELF_NUM_DESCR' | translate">
                            <dxo-font color="#767676" weight="300" size="12px">
                            </dxo-font>
                          </dxo-subtitle>
                        </dxo-title>

                        <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                        <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                          [ignoreEmptyPoints]="true" [barPadding]="0.5">
                        </dxo-common-series-settings>
                        <dxo-series-template nameField="attribute"></dxo-series-template>
                        <dxo-legend [visible]="false">
                        </dxo-legend>
                        <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                        <dxo-argument-axis>
                          <dxo-label [visible]="false"></dxo-label>
                          <dxo-grid [visible]="true"></dxo-grid>
                        </dxo-argument-axis>
                      </dx-chart>

                      <div class="more-graph-info">
                        <p>{{('generic.NO_RESULTS' | translate)}}</p>
                      </div>
                    </div>
                  </ng-template>

                </div>

              </ng-container>

              <ng-container *ngIf="isLoadingSelfAssessmentData">
                <div class="loader spinner-big"></div>
              </ng-container>
            </div>
          </percentage-accordion>

          <!-- Piano di sviluppo -->

          <ng-container>
            <percentage-accordion [title]="'dashboardCpp.accordion.DEV_PLAN' | translate" [percentage]="developmentPlan"
              [isAccordionOpened]="developmentPlanOpen" [isLoading]="isLoadingDevelopmentPlanData"
              (onAccordionOpen)="redrawGraphs('dev-plan')">
              <div class="accordion-content">

                <ng-container *ngIf="!isLoadingDevelopmentPlanData && developmentPlanData">
                  <div class="dashboard" *ngIf="developmentPlanData">

                    <!-- se ho dati -->
                    <ng-container *ngIf="!developmentPlanNoData; else devPlanPlaceholder">
                      <!-- Indicatore piano di sviluppo -->
                      <div class="section">
                        <dx-circular-gauge id="developmentPlanGauge" [value]="developmentPlanData.completedPercentage"
                          (onInitialized)="saveInstance($event, 'developmentPlanGauge')">

                          <dxo-title [text]="'dashboard.PROGRESS' | translate">
                            <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                            </dxo-font>
                            <dxo-subtitle>
                              <dxo-font color="#767676" weight="300" size="12px">
                              </dxo-font>
                            </dxo-subtitle>
                          </dxo-title>

                          <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                          <dxo-value-indicator type="triangleNeedle" color="#6E6F71">
                          </dxo-value-indicator>
                          <dxo-range-container palette="pastel">
                            <dxi-range [startValue]="0" [endValue]="50" color="#FF6600"></dxi-range>
                            <dxi-range [startValue]="50" [endValue]="100" color="#25BB48"></dxi-range>
                          </dxo-range-container>
                          <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                          <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                            <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                          </dxo-scale>
                        </dx-circular-gauge>


                        <div class="more-graph-info">
                          <p class="number">{{ developmentPlanData.sent + '/' + developmentPlanData.total}}<span
                              class="title-plan-shared" translate="dashboard.SHARED_PLAN"></span></p>
                        </div>
                      </div>

                      <!-- Chart piano di sviluppo -->
                      <div class="section right">
                        <dx-chart id="developmentPlanChart" [dataSource]="developmentPlanData.chartData"
                          [rotated]="true" [palette]="careColors"
                          (onInitialized)="saveInstance($event, 'developmentPlanChart')">
                          <dxo-title>
                            <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                            </dxo-font>
                            <dxo-subtitle [text]="'dashboard.PEOPLE_NUM_DESCR' | translate">
                              <dxo-font color="#767676" weight="300" size="12px">
                              </dxo-font>
                            </dxo-subtitle>
                          </dxo-title>
                          <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                          <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                            [ignoreEmptyPoints]="true" [barPadding]="0.5">
                          </dxo-common-series-settings>
                          <dxo-series-template nameField="attribute"></dxo-series-template>
                          <dxo-legend [visible]="true" position="outside" horizontalAlignment="center"
                            verticalAlignment="bottom"></dxo-legend>
                          <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                          <dxo-argument-axis>
                            <dxo-label [visible]="false"></dxo-label>
                          </dxo-argument-axis>
                        </dx-chart>

                        <div class="more-graph-info"></div>
                      </div>
                    </ng-container>


                    <!-- se non ho dati -->
                    <ng-template #devPlanPlaceholder>
                      <div class="section">
                        <dx-circular-gauge id="developmentPlanGauge"
                          [value]="selfAssessmentProgress.completedPercentage"
                          (onInitialized)="saveInstance($event, 'developmentPlanGauge')">

                          <dxo-title [text]="'dashboard.PROGRESS' | translate">
                            <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                            </dxo-font>
                            <dxo-subtitle>
                              <dxo-font color="#767676" weight="300" size="12px">
                              </dxo-font>
                            </dxo-subtitle>
                          </dxo-title>

                          <dxo-export [enabled]="true" [formats]="allowedFormats"></dxo-export>
                          <dxo-value-indicator type="triangleNeedle" color="#ffffff">
                          </dxo-value-indicator>
                          <dxo-range-container palette="pastel">
                            <dxi-range [startValue]="0" [endValue]="50" color="#dbdbdb"></dxi-range>
                            <dxi-range [startValue]="50" [endValue]="100" color="#dbdbdb"></dxi-range>
                          </dxo-range-container>
                          <dxo-geometry [startAngle]="180" [endAngle]="0"> </dxo-geometry>

                          <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="50">
                            <dxo-label [customizeText]="customizePercentageText"></dxo-label>
                          </dxo-scale>
                        </dx-circular-gauge>


                        <div class="more-graph-info">
                          <p class="number">0/0</p>
                          <p>{{('generic.NO_RESULTS' | translate)}}</p>
                        </div>
                      </div>

                      <div class="section right">
                        <dx-chart id="developmentPlanChart" [dataSource]="developmentPlanData.chartData"
                          [rotated]="true" [palette]="careColors"
                          (onInitialized)="saveInstance($event, 'developmentPlanChart')">
                          <dxo-title>
                            <dxo-font color="#333333" family="Roboto" weight="600" size="20px">
                            </dxo-font>
                            <dxo-subtitle [text]="'dashboard.PEOPLE_NUM_DESCR' | translate">
                              <dxo-font color="#767676" weight="300" size="12px">
                              </dxo-font>
                            </dxo-subtitle>
                          </dxo-title>
                          <dxo-export class="menu-export" [enabled]="true" [formats]="allowedFormats"></dxo-export>
                          <dxo-common-series-settings argumentField="attribute" type="bar" valueField="number"
                            [ignoreEmptyPoints]="true" [barPadding]="0.5">
                          </dxo-common-series-settings>
                          <dxo-series-template nameField="attribute"></dxo-series-template>
                          <dxo-legend [visible]="false"></dxo-legend>
                          <dxo-tooltip [enabled]="true" [shared]="true"></dxo-tooltip>
                          <dxo-argument-axis>
                            <dxo-grid [visible]="true"></dxo-grid>
                            <dxo-label [visible]="false"></dxo-label>
                          </dxo-argument-axis>
                        </dx-chart>

                        <div class="more-graph-info">
                          <p>{{('generic.NO_RESULTS' | translate)}}</p>
                        </div>
                      </div>
                    </ng-template>
                  </div>

                </ng-container>

                <ng-container *ngIf="isLoadingDevelopmentPlanData">
                  <div class="loader spinner-big"></div>
                </ng-container>
              </div>
            </percentage-accordion>
          </ng-container>
        </div>

        <div *ngIf="selectedTab?.id == 'analytic-dashboard'" class="accordion-list">
          <div class="filterFeedbackPanel">
            <div class="row">
              <p class="bold-text full-width" translate="dashboardCpp.SELECT_ROLE"></p>
              <ng-select class="header-dossier-select custom-select" [clearable]="false" [multiple]="false"
                [searchable]="false" [placeholder]="'Seleziona il ruolo'" [items]="rolesList"
                (change)="emitOnSelectedItem($event)">

                <!-- Elementi selezionati -->
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                  <p>{{item.label || item.name}}</p>
                </ng-template>

                <!-- Template per l'inizio della ricerca -->
                <ng-template ng-typetosearch-tmp>
                  <div class="ng-option disabled"></div>
                </ng-template>
                <!-- Template per i dati non trovati -->
                <ng-template ng-notfound-tmp let-searchTerm="filter">
                  <div class="ng-option disabled">
                    <alloy-dropdown-item class="full-width">
                    </alloy-dropdown-item>
                  </div>
                </ng-template>
                <!-- Template per i singoli item nelle options -->
                <ng-template ng-option-tmp let-item="item">
                  <alloy-dropdown-item [item]="item" class="full-width"></alloy-dropdown-item>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <!-- Sezione senza risultati -->
          <div *ngIf="!profileId" class="indicator-wrapper">
            <div class="col">
              <div class="title">
                <h4 translate="dashboardCpp.FIRST_SELECT_ROLE"></h4>
                <h5 translate="dashboardCpp.SHOW_RESULTS"></h5>
              </div>
            </div>
            <svg-icon src="../../../assets/img/placeholder/waiting.svg" class="info-icon" [applyClass]="true">
            </svg-icon>
          </div>

          <!-- Distribuzione dei Gap nelle competenze -->
          <div *ngIf="GetCppProfileDashboardDataForAdmin" class="indicator-wrapper" id="chart-competence">
            <div class="title">
              <h4>
                {{ ('dashboardCpp.DISTRIBUTION_ROLE_COMPETENCE' | translate)}} {{profileName}}
              </h4>
              <h5>{{('dashboardCpp.ANALYSING_SKILLS_GAPS_OF' | translate)}}<span
                  class="bold-text">{{(userData.numRecords ==
                  GetCppProfileDashboardDataForAdmin?.usersListWithDetails?.length ? userData.numRecords :
                  GetCppProfileDashboardDataForAdmin?.usersListWithDetails?.length
                  )}}</span>&nbsp;{{('dashboardCpp.PERSONS' | translate)}}
              </h5>
            </div>

            <div class="chartWrapper">
              <div class="chartGap">

                <!--Header chart-->
                <div class="header-chart">
                  <div class="celCompetences">
                    <p translate="dashboardCpp.COMPETENCE"></p>
                  </div>
                  <div class="rowGap">
                    <div class="celNegativeGap">
                      <p translate="dashboardCpp.NEGATIVE_GAP_TITLE"></p>
                    </div>
                    <div class="celNeutralGap">
                      <p>0</p>
                    </div>
                    <div class="celPositiveGap">
                      <p translate="dashboardCpp.POSITIVE_GAP_TITLE"></p>
                    </div>
                  </div>
                  <div class="celIncident">
                    <p translate="dashboardCpp.ANOMALIES"></p>
                  </div>
                  <div class="celUnit">
                    <p translate="dashboardCpp.NUMBER_HEADS"></p>
                  </div>
                </div>

                <!--Item chart-->
                <div class="itemChart"
                  *ngFor="let item of GetCppProfileDashboardDataForAdmin?.competenceListWithDelta; index as i "
                  [ngClass]="{'hidden' : i >= 10 && !showAllCompentences }">
                  <div class="celCompetences">
                    <p>{{item.competenceName}}</p>
                  </div>
                  <div class="rowGap">
                    <div class="celNegativeGap">
                      <div class="value-negative" [ngStyle]="{ width : setWidth(item.negativeCount, 'delta') }">
                        <div class="counter-negative active">
                          <p>{{item.negativeCount}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="counter-neutral" [ngClass]="{'active' : item.okCount > 0  }">
                      <p>{{item.okCount}}</p>
                    </div>
                    <div class="celPositiveGap">
                      <div class="value-positive" [ngStyle]="{ width : setWidth(item.positiveCount, 'delta') }">
                        <div class="counter-positive active">
                          <p>{{item.positiveCount}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="celIncident">
                    <div class="counter-incident" [ngClass]="item.anomaliesCount > 0 ? 'active' : 'transparent'">
                      <p>{{item.anomaliesCount}}</p>
                    </div>
                  </div>
                  <div class="celUnit">
                    <p>{{item.userCount}}</p>
                  </div>
                </div>
              </div>

              <div *ngIf="!showAllCompentences" class="rowActions">
                <alloy-button iconRight="assets/img/icons/plus.svg"
                  label="{{'dashboardCpp.SHOW_ALL_COMPETENCES' | translate}}" onButtonClicked="" type="primary blu-link"
                  size="medium" (onButtonClicked)="showCompentences()">
                </alloy-button>
              </div>
              <div *ngIf="showAllCompentences" class="rowActions">
                <alloy-button iconRight="assets/img/icons/minus.svg"
                  label="{{'dashboardCpp.SHOW_LESS_COMPETENCES' | translate}}" onButtonClicked=""
                  type="primary blu-link" size="medium" (onButtonClicked)="showCompentences()">
                </alloy-button>
              </div>
              <div class="legend-wrapper">
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #E4002B;"></div>
                  <p translate="dashboardCpp.NEGATIVE_GAP"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #72DB23;"></div>
                  <p translate="dashboardCpp.POSITIVE_GAP"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #68AC35;"></div>
                  <p>Delta 0</p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #0E83CD;"></div>
                  <p translate="dashboardCpp.ANOMALY"></p>
                </div>
              </div>
            </div>
          </div>

          <!-- Elenco persone -->
          <div id="user-list" *ngIf="GetCppProfileDashboardDataForAdmin != undefined" class="indicator-wrapper">
            <div class="title">
              <h4 translate="dashboardCpp.PERSON_LIST"></h4>
              <h5 [innerHTML]="'dashboardCpp.PERSON_LIST_DESC' | translate"></h5>
            </div>
            <!-- Tabella -->
            <div class="admin-table-container no-border">
              <div class="table-content">
                <table>
                  <thead>
                    <tr>
                      <th translate="dashboardCpp.PERSON" style="width: 15%">
                      </th>
                      <th class="expertise-container" translate="adminTable.ROLE" style="width: 25%">
                      </th>
                      <th translate="{{ ('dashboardCpp.EVALUATOR' | translate)}}">
                      </th>
                      <th class="expertise-container" style="width: fit-content">{{
                        ('dashboardCpp.ALIGNMENT_ROLE' |
                        translate)}}<svg-icon src="../../../assets/img/icons/info.svg"
                          (click)="openRoleAlignmentModal()"></svg-icon>
                      </th>
                      <th translate="{{ ('dashboardCpp.ACTIONS' | translate)}}" style="width: 1%">
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let user of GetCppProfileDashboardDataForAdmin.usersListWithDetails | 
                    paginate: { id: 'userList',
                    itemsPerPage: userData.numRecords,
                    currentPage: userData.page,
                    totalItems: userData.counter }">
                      <td style=" width: 180px;" fxLayout="row" fxLayoutAlign="start center">
                        <avatar-img *ngIf="user.userOptions.avatarImage != null" class="margin-right10"
                          [src]="user.userOptions.avatarImage" size="small"></avatar-img>
                        <avatar-img *ngIf="user.userOptions.avatarImage == null" class="margin-right10"
                          [initials]="user.surname[0] + user.forename[0]" size="small"></avatar-img>
                        <p>{{ user.surname + ' ' + user.forename }}</p>
                      </td>
                      <td>{{user.ruolo}}</td>
                      <td>{{user.manager}}</td>
                      <td class="expertise-anomalies-container">
                        <adequacy-indicator [adequacyPercentage]="user.adequacyPercentage"></adequacy-indicator>
                      </td>
                      <td class="link" (click)="openCompetenceDetailModal(user)"
                        [ngClass]="{'link-disabled': user.surveyCompleted == false }">
                        {{ 'generic.DETAIL' | translate }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="bottom-actions">
                <table-pagination id="userList" (pageChanged)="userPageChanged($event)" [noPadding]="true">
                </table-pagination>
                <div class="per-page-selector">
                  <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
                  <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeUserNumRecords($event)"
                    [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                    [tablePagination]="true">
                  </alloy-select>
                </div>
              </div>
            </div>
          </div>

          <!-- Livello posseduto vs livello atteso -->
          <div id="user-detail" *ngIf="userDetail" class="indicator-wrapper">
            <div class="title">
              <h4 translate="dashboardCpp.TITLE_USER_DETAIL"></h4>
              <h5>
                {{'dashboardCpp.ANALYZE_COMPETENCE' | translate}} <span class="bold-text"> {{userDetail.surname}}
                  {{userDetail.forename}}</span>
              </h5>
              <div class="rowActions">
                <alloy-button iconRight="assets/img/icons/x.svg" label="{{ 'dashboardCpp.CLOSE_DETAIL' | translate}}"
                  (onButtonClicked)="closeCompetenceDetailModal()" type="primary blu-link" size="medium">
                </alloy-button>
              </div>
            </div>

            <div class="chartWrapper">
              <div class="chartGap">

                <!--Header chart-->
                <div class="header-chart">
                  <div class="celCompetences">
                    <p translate="dashboardCpp.COMPETENCE"></p>
                  </div>
                  <div class="rowLevel">
                    <div class="celLevelTitle">
                      <p>{{ ('dashboardCpp.LEVEL' | translate) + "&nbsp;" + 1}}</p>
                    </div>
                    <div class="celLevelTitle">
                      <p>{{ ('dashboardCpp.LEVEL' | translate) + "&nbsp;" + 2}}</p>
                    </div>
                    <div class="celLevelTitle">
                      <p>{{ ('dashboardCpp.LEVEL' | translate) + "&nbsp;" + 3}}</p>
                    </div>
                    <div class="celLevelTitle">
                      <p>{{ ('dashboardCpp.LEVEL' | translate) + "&nbsp;" + 4}}</p>
                    </div>
                    <div class="celLevelTitle">
                      <p>{{ ('dashboardCpp.LEVEL' | translate) + "&nbsp;" + 5}}</p>
                    </div>
                  </div>
                  <div class="celIncident">
                    <p translate="dashboardCpp.ANOMALIES"></p>
                  </div>
                  <div class="celUnit">
                    <p translate="dashboardCpp.DELTA"></p>
                  </div>
                </div>

                <!--Item chart-->
                <div class="itemChart" *ngFor="let item of userDetail.competencesGap">
                  <div class="celCompetences">
                    <p>{{item.competenceName}}</p>
                  </div>
                  <div class="rowLevel">
                    <div class="level-compare-wrapper">
                      <!-- Livello posseduto/atteso: value-positive value-negative value-neutral-->
                      <div class="celLevel">
                        <div [ngStyle]="{ width : setWidth(item.possessedLevel, 'user-detail') }"
                          [ngClass]="{'value-positive': item.delta > 0 , 'value-negative' :item.delta < 0, 'value-neutral': item.delta == 0 }">
                          <div class="counter active">
                            <p>{{item.possessedLevel}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="celLevel">
                        <div [ngStyle]="{ width : setWidth(item.requiredLevel, 'user-detail') }" class="value-expected">
                          <div class="counter active">
                            <p>{{item.requiredLevel}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="celIncident">
                    <div *ngIf="item.withAnomaly" class="counter-incident active">
                      <p>A</p>
                    </div>
                  </div>
                  <div class="celUnit">
                    <!-- Il Gap può essere counter-negative, counter-neutral, counter-positive-->
                    <div class="active"
                      [ngClass]="{'counter-positive': item.delta > 0 , 'counter-negative' :item.delta < 0, 'counter-neutral': item.delta == 0 }">
                      <p>{{item.delta}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="legend-wrapper">
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #E4002B;"></div>
                  <p translate="dashboardCpp.LEVEL_BELOW"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #68AC35;"></div>
                  <p translate="dashboardCpp.LEVEL_OK"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #72DB23;"></div>
                  <p translate="dashboardCpp.LEVEL_ABOVE"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #CCCCCC;"></div>
                  <p translate="dashboardCpp.LEVEL_EXPECTED"></p>
                </div>
                <div class="legendItem">
                  <div class="legend-indicator" style="background: #0E83CD;"></div>
                  <p translate="dashboardCpp.ANOMALY"></p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--Fine contenitore degli indicatori-->
    </div>
  </ng-container>
</div>

<!-- Modale Allineamento al ruolo  -->
<modal id="role-alignment-modal" (onClose)="closeRoleAlignmentModal()" (onCancel)="closeRoleAlignmentModal()"
  (onConfirm)="closeRoleAlignmentModal()" [confirmLabel]="'generic.CLOSE' | translate">
  <modal-text-content [title]="'cppCollaborators.ROLE_ALIGNMENT' | translate"
    [text]="'cppCollaborators.APPROPRIATE_TO_LEVEL' | translate "
    [subtitle]="'cppCollaborators.ROLE_ALIGNMENT' | translate">
  </modal-text-content>
  <div class="adeguact-modal-container">
    <div *ngFor="let adequacy of adequacyModalList">
      <adequacy-indicator *ngIf="adequacy" [status]="adequacy !== 'anomalies'? adequacy:''"></adequacy-indicator>
      <p class="modal-adequacy-text">{{('cppCollaborators.modal.'+ adequacy ) | translate}}</p>
    </div>
  </div>
</modal>