import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'alloy-pop-up',
  templateUrl: 'alloy-pop-up.component.html',
  styleUrls: ['./alloy-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EuropPopUpComponent implements OnInit {
  @Input() title: string = 'Gestire la nostra disponibilità';
  @Input() firstSubtitleTitle: string = 'Cluster';
  @Input() firstSubtitleDescription: string = 'Available';
  @Input() secondSubtitleTitle: string = 'Comportamento specifico e esempi di citazione​';
  @Input() secondSubtitleDescription: string = 'Sono in grado di gestire in modo autonomo e appropriato il mio tempo e i miei obiettivi facendo fronte al Business EPEE. Metto le persone nella condizione di potermi parlare in modo semplice, senza filtri. Essere coinvolti a pieno nell’interazione con i clienti (interni ed esterni).';

  constructor() { }

  ngOnInit() { }
}