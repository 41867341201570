import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { SelfAssessmentOrganizeInterviewComponent } from '../organizeInterview/organizeInterview.component';

@Injectable()
export class CanDeactivateUpdateInterviewSelfAssessmentGuardService implements CanDeactivate<SelfAssessmentOrganizeInterviewComponent> {
  constructor(private modalService: ModalService) { }

  canDeactivate(component: SelfAssessmentOrganizeInterviewComponent): boolean | Promise<boolean> {
    if (!component.goToGoalObjectiveList) {
      this.modalService.open("modalUpdateInterviewForUser");
      return new Promise<boolean>((resolve, reject) => {
        let clickedButton = document.getElementById("confirmBtn");
        if (clickedButton) {
          clickedButton.onclick = ((e: any) => {
            resolve(false);
          });
        }
      });
    } else {
      component.goToGoalObjectiveList = false;
    }
    return true;
  }
}