<div class="page-content-wrapper">
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('peerFeedback.FEEDBACK_REQUEST' | translate)"
    [adminPageSubtitle]="('peerFeedback.FEEDBACK_LIST' | translate)" [adminBack]="true">
  </header-dossier>


  <child-content [peerFeedbackPage]="true">
    <!-- Loader contenuto -->
    <ng-container *ngIf="isLoadingPeerFeedback">
      <div class="loader spinner-medium"></div>
    </ng-container>

    <ng-container *ngIf="!isLoadingPeerFeedback">
      <!-- Lista peer feedback -->
      <ng-container *ngIf="peerFeedbackList && peerFeedbackList.length">
        <card-peer *ngFor="let feedback of peerFeedbackList" [object]="feedback" [loggedUser]="loggedUser"
          [isPeopleAppraisal]="true" [isReceived]="false" (onEdit)="goToPeerFeedbackAnswer($event)"
          (onShowRequest)="openShowFeedbackRequest($event)" (onFeedbackSend)="goToPeerFeedbackAnswer($event)">
        </card-peer>
      </ng-container>

      <!-- Placeholder se non ho feedback -->
      <ng-container *ngIf="!peerFeedbackList || !peerFeedbackList.length">
        <no-data [title]="('peerFeedback.TITLE' | translate)" src="/assets/img/section/private-notes-placeholder.svg"
          [description]="peerFeedbackPlaceholder">
        </no-data>
      </ng-container>
    </ng-container>
  </child-content>
</div>



<!-- Modale mostra richiesta peer feedback -->
<modal id="modalShowRequest" (onClose)="closeShowFeedbackRequest()" (onConfirm)="closeShowFeedbackRequest()"
  [confirmLabel]="'generic.CLOSE' | translate">
  <div class="peer-feedback-modal-request">
    <div class="title-code-wrapper full-width">
      <div class="title-container" translate="peerFeedback.card.REQUEST_TITLE"></div>
    </div>
    <div class="confirm-request-content" *ngIf="feedbackForRequestModal">
      <div class="section users">
        <div class="title" translate="peerFeedback.modals.REQUEST_FROM"></div>
        <div class="content">
          <div class="mini-user-card">
            <avatar-img size="small" [user]="feedbackForRequestModal.recipientUser"></avatar-img>
            <p class="name">{{ feedbackForRequestModal.recipientUser.forename + ' ' +
              feedbackForRequestModal.recipientUser.surname}}</p>
          </div>
        </div>
      </div>
      <div class="section" *ngIf="feedbackForRequestModal.goal">
        <div class="title" translate="peerFeedback.modals.REFERS_TO"></div>
        <div class="content">{{ feedbackForRequestModal.goal && feedbackForRequestModal.goal.title}}</div>
      </div>
      <div class="section">
        <div class="title" translate="peerFeedback.modals.WHY"></div>
        <div class="content">{{ feedbackForRequestModal.request }}</div>
      </div>
    </div>
  </div>
</modal>