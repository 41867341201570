import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SidebarService implements OnInit {

  private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {
    router.events.subscribe(() => {
      this.setShowNav(false);
    });
  }

  ngOnInit() {
  }

  getShowNav() {
    return this.showNav$.asObservable();
  }

  setShowNav(showHide: boolean) {
    this.showNav$.next(showHide);
    // Quando una sidebar si apre, blocco lo scroll al <body> dell'html affinché la pagina dell'applicazione non sia più scrollabile
    const appBody = document.getElementsByTagName("body") && document.getElementsByTagName("body")[0];
    if (appBody) {
      appBody.style.overflowY = showHide ? "scroll" : "visible";
      appBody.style.position = showHide ? "fixed" : "static";
    }
  }

  toggleNavState() {
    this.showNav$.next(!this.showNav$.value);
  }

  isNavOpen() {
    return this.showNav$.value;
  }
}
