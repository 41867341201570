import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


export class CareElement {
  id: string = '';
  title: string = '';
  isChecked: boolean = false;
  icon?: string;
}

@Component({
  selector: 'care-cards',
  templateUrl: 'care-cards.component.html',
  styleUrls: ['./care-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareCardsComponent implements OnInit {
  @Input() items: CareElement[] = []
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isFromMidTermReview: boolean = false;
  @Input() isForFinalEvaluation: boolean = false;
  @Output() onCheck: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit() {
  }


  emitOnCheck(card: CareElement) {
    this.items.forEach((x: any) => {
      x.isChecked = false;
    })
    card.isChecked = !card.isChecked;
    this.onCheck.emit(this.items);
  }


  cardId(index: number, el: CareElement) {
    return el.id;
  }
}