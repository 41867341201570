import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { FacileOpenAPI, JwtPayload, SenecaResponse, User } from "atfcore-commonclasses";
import { Observable, Subscription, combineLatest } from "rxjs";
import { PeopleAppraisalService } from "../shared/services/peopleAppraisal.service";
import { ModalService } from "../shared/components/modal/modal.service";
import { RedirectService } from "../shared/services/redirect.service";
import { AnalyticsService } from "../shared/services/analytics.service";
import * as fromApp from "../ngrx/app.reducers";
import { ApplicationModalMessage } from "../core/ngrx/core.reducers";
import * as CoreActions from '../core/ngrx/core.actions';

@Component({
  selector: 'app-insight-feedback-personDetails',
  templateUrl: './insightFeedbackMatrixManager.component.html',
  styleUrls: ['./insightFeedbackMatrixManager.component.scss']
})
export class InsightFeedbackMatrixManagerComponent implements OnInit, OnDestroy {
  personDetails: any;
  isLoadingPersonDetails = false;
  userId: string = '';
  loggedUser$: any;
  runningYear$: any;
  runningYear: any = {};

  // Anno selezionato per storico
  selectedYear: any;
  getYearsList$: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;

  isHistory: boolean = false;
  isLoadingTranslations: boolean = false;
  translations: any;
  combinedSelected$: any;
  loggedUser!: User;
  usersList: any[] = [];
  isLoadingUsersList: boolean = false;
  getUsersList$: Subscription = new Subscription();
  isComplete: boolean = false;
  isImpersonate: boolean = false;
  insightData: any;
  subordinateUser: any;


  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService,
    private router: Router,
    private modalService: ModalService,
    public redirectService: RedirectService,
  ) {
    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.isHistory = false;
    this.isLoadingPersonDetails = true;
    this.isLoadingTranslations = true;
    this.store.select(fromApp.getToken).subscribe((token) => {
      if (token) {
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
        const runningYear$: Observable<any> = this.store.select(fromApp.getRunningYear);
        this.combinedSelected$ = combineLatest([loggedUser$, runningYear$])
          .subscribe(
            ([loggedUser, runningYear]) => {
              if (loggedUser && loggedUser.user) {
                this.loggedUser = loggedUser && loggedUser.user;
              }
              this.runningYear = runningYear;
              if (this.runningYear) {
                this.route.params
                  .subscribe(
                    (params: Params) => {
                      this.userId = params.userId;

                      if (params.perfYear) {
                        this.selectedYear = {
                          id: params.perfYear,
                          code: params.perfYearCode,
                          name: params.perfYearCode + ' ' + params.perfYear
                        }
                      } else {
                        this.selectedYear = {
                          id: 2024,
                          name: 'H1 ' + 2024
                        }
                      }

                      if (this.runningYear && this.runningYear.year != 0 && !(this.selectedYear.id.toString() == this.runningYear.year.toString() && this.selectedYear.code == this.runningYear.yearCode)) {
                        this.isHistory = true;
                      } else {
                        this.isHistory = false;
                      }

                      if (this.selectedYear && this.selectedYear.id) {
                        this.listInsightFeedbacksForMatrixManager(!this.userId);
                      }
                    });
              }
            });
      }
    })
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    // this.analyticsService.sendVirtualPageViewEvent(this.router.url, "PeopleAppraisal - Scheda utente");
  }

  ngOnInit() {
    this.isHistory = false;

    this.translate.get(
      [
        'insight.matrix.DEVELOPMENT_AREA_TITLE_1',
        'insight.matrix.DEVELOPMENT_AREA_TITLE_2',
        'insight.matrix.STRONG_POINT_TITLE_1',
        'insight.matrix.STRONG_POINT_TITLE_2',
      ])
      .subscribe(translations => {
        this.translations = translations;
        this.isLoadingTranslations = false;
      })
  }

  getPerformanceAssessmentYears() {
    this.isLoadingYearList = true;

    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }

    this.getYearsList$ = this.peopleAppraisalService.getPerformanceAssessmentYearsForMatrixManager(this.userId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "year000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingYearList = false;
        } else {
          this.yearList = [];
          for (let i = (data.response.length - 1); i >= 0; i--) {
            this.yearList.push({
              id: data.response[i].assessmentYear,
              code: data.response[i].assessmentYearCode,
              name: data.response[i].assessmentYearCode + ' ' + data.response[i].assessmentYear,
              disabled: !data.response[i].hasFeedbacks
            });
          }
          this.isLoadingYearList = false;
          if (this.runningYear && this.runningYear.year != 0 && !(this.selectedYear.id.toString() == this.runningYear.year.toString() && this.selectedYear.code == this.runningYear.yearCode)) {
            this.isHistory = true;
          }

        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "year000",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingYearList = false;
      });
  }

  isInCurrentYear() {
    return this.runningYear == this.selectedYear.id;
  }

  changeSelectedYear(year: any) {
    let previous = this.selectedYear.id;
    this.selectedYear = year;
    this.isHistory = false;
    if (this.selectedYear.id != this.runningYear.year || this.selectedYear.code != this.runningYear.yearCode) {
      this.isHistory = true;
    }
    // let url = this.router.url.split(('/' + previous))[0];
    // this.router.navigate([url, this.selectedYear.id, this.selectedYear.code])
    this.listInsightFeedbacksForMatrixManager();
  }

  getYearName() {
    let yearName = '';
    if (this.selectedYear.code == 'H1') {
      yearName = 'H2 ' + this.selectedYear.id
    } else {
      yearName = 'H1 ' + (parseInt(this.selectedYear.id) + 1);
    }
    return yearName;
  }


  listInsightFeedbacksForMatrixManager(selectFirstUser?: boolean) {
    this.isLoadingUsersList = true;

    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }

    let serviceCall;
    if (this.isHistory) {
      serviceCall = this.peopleAppraisalService.listInsightFeedbacksForMatrixManager(this.selectedYear.id, this.selectedYear.code, this.userId)
    } else {
      serviceCall = this.peopleAppraisalService.listInsightFeedbacksForMatrixManager(this.selectedYear.id, this.selectedYear.code)
    }

    this.getUsersList$ = serviceCall
      .subscribe((data: SenecaResponse<{ insightFeedbacks: FacileOpenAPI.InsightFeedbackPhase[] }>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.usersList = data.response?.insightFeedbacks;
          // per avere gli stati corretti nella sidebar devo sovrascrivere managerStatus con insightStatus contenuto dentro agli insight
          this.usersList.forEach(userInsight => {
            let insightItem = userInsight.insights.find((data: FacileOpenAPI.InsightFeedbackDetails) => data.matrixManager.userId == this.loggedUser.userId)
            if (insightItem) {
              userInsight.managerStatus = insightItem.insightStatus;
            }
          });
          if (this.isHistory) {
            this.personDetails = data.response?.insightFeedbacks.find(user => user?.subordinateUser?.userId == this.userId);
          } else {
            this.usersList = data.response?.insightFeedbacks;
            if (selectFirstUser && this.usersList && this.usersList.length) {
              this.router.navigate(['insightFeedbackMatrixManager/' + this.runningYear.year + '/' + this.runningYear.yearCode + '/' + data.response?.insightFeedbacks[0].subordinateUser?.userId]);
            } else if (this.usersList && this.usersList.length && this.userId) {
              this.personDetails = data.response?.insightFeedbacks.find(user => user?.subordinateUser?.userId == this.userId);
              this.isLoadingPersonDetails = false;
            } else {
              this.redirectService.goToHome();
            }
          }
          if (this.personDetails) {
            // insightData è composto da: response del servizio (che ha dati subordinato, aree di sviluppo e punti di forza) e la richiesta di insight stessa
            // contenuta in insights
            this.insightData = {
              ...this.personDetails,
              ...this.personDetails.insights.find((data: FacileOpenAPI.InsightFeedbackDetails) => data.matrixManager.userId == this.loggedUser.userId)
            };

            this.subordinateUser = this.personDetails.subordinateUser;

          }
          this.getPerformanceAssessmentYears();
          this.isLoadingUsersList = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsersList = false;
      })
  }

  changeDevelopmentAreasComment(text: string) {
    this.insightData.developmentAreasComment = text;
  }

  changePointOfStrengthComment(text: string) {
    this.insightData.pointsOfStrengthComment = text;
  }

  getDevAreaTitle() {
    let text = '';
    if (!this.isLoadingTranslations && this.insightData?.requiredByManager) {
      text = this.translations['insight.matrix.DEVELOPMENT_AREA_TITLE_1'];
      text += this.insightData.requiredByManager.surname + ' ' + this.insightData.requiredByManager.forename;
      text += this.translations['insight.matrix.DEVELOPMENT_AREA_TITLE_2'];
      text += this.subordinateUser?.surname + ' ' + this.subordinateUser?.forename + '.';
    }
    return text;
  }

  getPointOfStrengthTitle() {
    let text = '';
    if (!this.isLoadingTranslations && this.insightData?.requiredByManager) {
      text = this.translations['insight.matrix.STRONG_POINT_TITLE_1'];
      text += this.insightData.requiredByManager.surname + ' ' + this.insightData.requiredByManager.forename;
      text += this.translations['insight.matrix.STRONG_POINT_TITLE_2'];
      text += this.subordinateUser?.surname + ' ' + this.subordinateUser?.forename + '.';
    }
    return text;
  }

  isSendFeedbackDisabled() {
    return !this.insightData || !this.insightData?.developmentAreasComment || !this.insightData?.developmentAreasComment?.trim()?.length
      || !this.insightData?.pointsOfStrengthComment || !this.insightData?.pointsOfStrengthComment?.trim()?.length;
  }

  openConfirmSendFeedback() {
    this.modalService.open('confirm-send-feedback')
  }


  closeConfirmSendFeedback(confirm?: boolean) {
    this.modalService.close('confirm-send-feedback')
    if (confirm) {
      this.isLoadingPersonDetails = true;

      this.peopleAppraisalService.answerInsightFeedbackForMatrixManager(this.selectedYear.id, this.selectedYear.code, this.subordinateUser.userId, this.insightData.developmentAreasComment, this.insightData.pointsOfStrengthComment)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "pn017",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingPersonDetails = false;
          } else {
            window.location.reload();
          }
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "109",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingPersonDetails = false;
        });
    }
  }

  openHelpModal() {
    this.modalService.open("help-modal");
  }

  closeHelpModal() {
    this.modalService.close("help-modal");
  }


  isItemDisabled() {
    return this.isHistory || (this.insightData && !this.insightData.canAnswerMatrixManager) || (this.insightData && !!this.insightData.answerDate);
  }

  setPersonDetails() {
    let person = this.usersList.find(user => user?.subordinateUser?.userId == this.userId);
    this.personDetails = JSON.parse(JSON.stringify(person));
    this.isComplete = this.personDetails.managerStatus == 'COMPLETED';
    this.isLoadingPersonDetails = false;
  }

  onInsightFeedbackUserClicked(user: any) {
    this.userId = user?.subordinateUser?.userId;
    this.router.navigate(['insightFeedbackMatrixManager/' + this.runningYear.year + '/' + this.runningYear.yearCode + '/' + this.userId]);
  }

  ngOnDestroy(): void {
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe();
    }
    if (this.getYearsList$) {
      this.getYearsList$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }

  }

}