import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import * as fromApp from "../../ngrx/app.reducers";
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { AlloyPerformanceUpwardFeedbackServiceResponses, SenecaResponse, UPWARD_FEEDBACK_EVALUATION_STATUS, User } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription, of, throwError } from 'rxjs';
import * as moment from 'moment';
import { catchError, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-upward-feedback-list',
  templateUrl: './upwardFeedbackList.component.html',
  styleUrls: ['./upwardFeedbackList.component.scss']
})
export class UpwardFeedbackListComponent implements OnInit {
  isLoadingUser: boolean = true;
  loggedUser$: Subscription;
  loggedUser: User | null = null;
  // Paginazione
  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  isLoadingRoundList: boolean = true;
  translations: any;
  roundData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]
  } = {
      fromRecord: 0,
      numRecords: 0,
      page: 1,
      counter: 0,
      list: []
    };

  constructor(
    public redirectService: RedirectService,
    private modalService: ModalService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private peopleAppraisalService: PeopleAppraisalService
  ) {
    this.selectedRows = this.rowPerPageOptions[0];
    this.roundData.numRecords = this.rowPerPageOptions[0].id;

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
        this.isLoadingUser = false;

      }
    })
  }


  ngOnInit(): void {
    this.getRoundList();
  }


  // Recupera una lista di utenti
  getRoundList() {
    this.isLoadingRoundList = true;

    // Avvio il loader
    this.peopleAppraisalService.countUpwardFeedbackRoundsForManager()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.roundData.counter = counter.response;
              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.roundData.page && this.roundData.numRecords) {
                fromRecord = (this.roundData.page - 1) * this.roundData.numRecords;
              } else {
                fromRecord = 0;
              }

              if (this.roundData.counter) {
                return this.peopleAppraisalService.getUpwardFeedbackRoundsForManager(fromRecord, this.roundData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "upfl001",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingRoundList = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "upfl002",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let round = data.response[i];

              round.statusMessage = round.status == UPWARD_FEEDBACK_EVALUATION_STATUS.SHARED ? this.translate.instant('upwardFeedback.peopleAppraisal.table.STATUS_RECEIVED') : this.translate.instant('upwardFeedback.peopleAppraisal.table.STATUS_TO_RECEIVE');
              round.statusType = round.status == UPWARD_FEEDBACK_EVALUATION_STATUS.SHARED ? 'completed' : 'toStart';
            }
            this.roundData.list = data.response;
          }
          this.isLoadingRoundList = false;
        }
        , (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "upfl003",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingRoundList = false;
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // vai ai risultati
  goToResults(round: any) {
    this.redirectService.goToUpwardFeedbackPeopleAppraisal(round?.roundId)
  }



  // Ripristina i dai della lista utenti
  resetRoundData() {
    this.roundData.fromRecord = 0;
    this.roundData.page = 1;
    this.roundData.counter = 0;
    this.roundData.list = [];

  }

  // Cambia la paginazione alla lista
  pageChanged(newPage: any) {
    this.roundData.page = newPage;
    // Avvio una nuova ricerca
    this.getRoundList();
  }

  // cambia il numero di round recuperati per pagina
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.roundData.numRecords = item.id;
    this.resetRoundData();
    this.getRoundList();
  }



  ngOnDestroy() {
    if (this.loggedUser$) {
      this.loggedUser$.unsubscribe();
    }
  }
}
