import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'objective-table',
  templateUrl: 'objective-table.component.html',
  styleUrls: ['./objective-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectiveTableComponent implements OnInit {
  @Input() objectives?: any;
  @Input() isEdit: boolean = false;
  showPoint: boolean = false;
  @Output() onManage: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public translate: TranslateService
  ) {
  }

  ngOnInit() {
  }

  emitOnManageObjective(id: string) {
    this.onManage.emit(id);
  }
}