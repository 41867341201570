import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'evaluation',
  templateUrl: 'evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnChanges {
  @Input() evaluation: string | null = null;
  @Input() scale: number = 3;
  @Input() customClass: string = '';
  evaluationArray: number[] = [];
  evaluationNumber: number = 0;
  isLoadingEvaluation: boolean;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.isLoadingEvaluation = true;
  }

  ngOnInit() {
    if (this.scale == 3) {
      this.evaluationArray = [0, 1, 2];
    } else {
      this.evaluationArray = [0, 1, 2, 3, 4];
    }
    this.evaluationNumber = this.evalToNumber();
    this.isLoadingEvaluation = false;
  }

  evalToNumber() {
    if (this.evaluation) {
      if (this.scale == 3) {
        switch (this.evaluation) {
          case "NOT_ACHIEVED":
            return 0;
          case "ACHIEVED":
            return 1;
          case "EXCEEDED":
            return 2;
          case "BEYOND_EXPECTATIONS":
            return 2;
          default:
            return 0;
        }
      } else {
        switch (this.evaluation) {
          case "UNSATISFACTORY":
            return 0;
          case "IMPROVABLE":
            return 1;
          case "MEET":
            return 2;
          case "EXCEEDED":
            return 3;
          case "BEYOND_EXPECTATIONS":
            return 3;
          case "EXCEPTIONAL":
            return 4;
          default:
            return 0;
        }
      }
    }
    return 0;
  }

  ngOnChanges() {
    this.evaluationNumber = this.evalToNumber();
  }
}