import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subordinateservice } from 'src/app/peopleAppraisal/services/subordinates.service';
import { SelfAssessmentService } from 'src/app/shared/services/selfAssessment.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { GuidedTourService } from 'ngx-guided-tour';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import { AlloyPerformanceCppParams, AlloyPerformanceCppServiceResponses, SenecaResponse, User } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-cpp-details',
  templateUrl: './cppDetails.component.html',
  styleUrls: ['./cppDetails.component.scss']
})
export class CppDetailsComponent implements OnInit {
  subordinatesUsers: any;
  personDetails: any = { forename: '7gbfi387itf3w4' };
  isLoadingPersonDetails = false;
  translations: any;
  stepList: any[] = [];
  currentStep: any;
  yearList: any[] = [];
  isLoadingYearList: boolean = false;
  selectedYear: any;
  actionId: string = '';

  // mostra la sidebar
  showSideNav: boolean = false;
  duration: number = 0.25;
  direction: string = 'right';
  // obiettivo selezionato
  intendedGoalSelected: { id: string, title: string } = { id: '1', title: '2' };
  // Tutte le opzioni da selezionare 
  intendedGoalOptions: Array<{ id: string, title: string }> = [{ id: '1', title: '2' }];
  // Lista di competenze selezionate
  competenceList: Array<{ id: string, actions: Array<{ id: string, list: Array<any> }> }> = [{ id: '1', actions: [{ id: '1', list: [1, 2, 3] }] }];

  isLoadingDevelopment: boolean = false;
  getUserDevelopment$: Subscription = new Subscription();
  developmentPlan: any;
  // pulsante per abilitare la sezione nuova azione
  openNewAction?: { id: string, isOpen: boolean };
  isAlreadyOpen: boolean = false;
  // Per visualizzazione testo
  isMoreText: boolean = false;
  // listCppActionTypeForManager
  isLoadingListCppActionType: boolean = false;
  getListCppActionType$: Subscription = new Subscription();
  listCppActionType: Array<{ id: string, title: string, other: boolean }> = [];
  selectedCppActionType: any;
  selectedCppActionTypeText: string = '';
  scope: string = '';
  // Date
  yesterday: Date = new Date();
  endDate: Date = new Date();

  // lista che verrà utilizzata per la modale
  adequacyModalList: Array<string> = ['min', 'medium', 'max','', 'anomalies'];
  // Salvo id round
  roundId: string = '';
  // Dati utente loggato
  loggedUser!: any;
  loggedUser$: Subscription = new Subscription();

  // Modoli survey
  isFetchingCppSurvey: boolean = false;
  currentModule: any;
  selectedAnswers: any[] = [];
  selectedAnswersCopy: any[] = [];
  originalSelectedAnswers: any[] = [];

  // Download report
  isDownloadingCppReport: boolean = false;
  downloadReport$: Subscription = new Subscription;

  // Recupero dettagli cpp
  isLoadingCppUserDetails: boolean = false;
  getCppUserDetails$: Subscription = new Subscription();
  cppDetails!: any;

  // Recupero survey
  isLoadingCppSurveyDeteils: boolean = false;
  getCppUserSurveyCompetenceDetails$: Subscription = new Subscription();
  cppSurvey!: any;
  // Creazione action
  isLoadingCreateActionOfCompetence: boolean = false;
  createActionOfCompetence$: Subscription = new Subscription();
  // Recupera la lista degli obiettivi
  updateUpdatesOfActionForManager$: Subscription = new Subscription();
  isLoadingUpdateUpdatesOfAction: boolean = false;
  updateUpdatesOfAction: any;

  // Recuper i commenti cpp
  isLoadingCompetenceCppComments: boolean = false;
  getListCompetenceUpdateCppComments$: Subscription = new Subscription();
  cppCommentList: Array<AlloyPerformanceCppServiceResponses.CppCompetenceUpdateComment> = [];

  // Recupero dei raound
  roundList: any[] = [];
  getRoundList$: Subscription = new Subscription();
  isLoadingRoundList: boolean = false;
  selectedRound: any;
  // Contenuto per la sidebar
  isActionBar: boolean = false;
  selectedCompetenceSideNav: any;
  selectActionSideNav: any;
  updatesSideNavText: string = '';
  // Aggiornamento action
  isLoadingUpdateActionOfCompetence: boolean = false;
  updateActionOfCompetence$: Subscription = new Subscription();

  // Creazione Aggiornamenti Azioni
  isLoadingCreateUpdatesOfAction: boolean = false;;
  createUpdatesOfAction$: Subscription = new Subscription();
  userId: string = '';

  // Creazione Aggiornamenti competenze
  isLoadingCreateUpdatesOfCompetence: boolean = false;;
  createUpdatesOfCompetence$: Subscription = new Subscription();
  competenceUpdateText: string = '';

  // Eliminazione azione
  isLoadingDeleteActionOfCompetence: boolean = false;
  deleteActionOfCompetence$: Subscription = new Subscription;
  isRoundActive: boolean = false;

  titleComment!: string;
  placeholderComment!: string;
  showErrorCommentField: boolean = false;
  constructor(private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private translate: TranslateService,
    private subordinatesService: Subordinateservice,
    private selfAssessmentService: SelfAssessmentService,
    private router: Router,
    private modalService: ModalService,
    private guidedTour: GuidedTourService,
    public redirectService: RedirectService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private sidebarService: SidebarService,
    private authService: AuthService,
    private elementRef: ElementRef
  ) {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.roundId = params.roundId;
          this.userId = params.userId;
          if (this.roundId) {
            this.stepList = [];
            this.developmentPlan = undefined;
            this.cppDetails = undefined;
            this.resetVariables();
            this.getCppUserDetails();
            this.getListCompetenceUpdateCppComments();
            this.getCppRounds();
            this.setStepList();
          }
        });

    this.loggedUser$ = this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
      if (loggedUser && loggedUser.user) {
        this.loggedUser = loggedUser && loggedUser.user;
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'accordion.CHALLENGE',
        'careerPathProgram.DELTA_ANALYSIS'
      ])
      .subscribe(translations => {
        this.translations = translations;
      })
  }
  openCreateUpdateModal() {
    if (!this.checkEqualCompetenceObject() && this.updatesSideNavText === '') {
      this.modalService.open('update-competence-comment');
      this.titleComment = this.translate.instant("developmentPlanCpp.WRITE_UPDATE_REQUIRED");
      this.placeholderComment = this.translate.instant("developmentPlanCpp.INSERT_COMMENT_REQUIRED");
      this.showErrorCommentField = true;
      return;
    }
    this.modalService.open('update-competence')
  }
  closeCreateUpdateModale(confirm?: boolean) {
    if (confirm) {
      this.createUpdatesOfCompetence(true);
    }
    this.modalService.close('update-competence')
  }

  createUpdatesOfCompetence(createUpdate: boolean) {
    this.isLoadingCreateUpdatesOfCompetence = true;

    if (this.createUpdatesOfCompetence$) {
      this.createUpdatesOfCompetence$.unsubscribe();
    }

    let updates: Array<{
      updateText: string
    }> = [{ updateText: this.updatesSideNavText }];

    this.createUpdatesOfCompetence$ = this.selfAssessmentService.createUpdatesOfCompetenceForUser(this.roundId, this.selectedCompetenceSideNav.competenceId, updates)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateUpdatesOfCompetence = false;
        } else {
          if (createUpdate) {
            this.updateCurrentCppSurvey();
          } else {
            this.modalService.open('save-successful');
          }
          this.onSidebar(false);
          if (this.cppDetails?.developmentPlanCreated) {
            // Recupera il plan
            this.getDevelopmentPlan();
          }
          // Recupera i type
          this.getListCppActionType();
          this.getListCompetenceUpdateCppComments();
          this.modalService.open('save-successful');
          this.isLoadingCreateUpdatesOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateUpdatesOfCompetence = false;
      })
  }
  onStepClicked(step?: any, getCppUserDetails?: boolean) {
    // Reset array competenze selezionate
    // this.selectedCompetenceList = [];
    if (step) {
      // if (!getCppUserDetails) {
      //   this.getCppUserDetails();
      // }
      if (step.stepId == 1) {
        this.getListCompetenceUpdateCppComments();
        this.getCppUserDetails(false, true);
        this.currentStep = step;
      }
      if (step.stepId == 2) {
        if (!this.cppDetails?.isSurveyCompleted || !this.developmentPlan?.developmentPlan?.developmentPlanId) {
          this.modalService.open('disabled-developmentPlan');
        } else {
          this.getDevelopmentPlan();
          // Recupera i type
          this.getListCppActionType();
          this.currentStep = step;
        }
        // Recupera i type
        // this.listCppActionTypeForManager();
        // // Recupera la lista obiettivi
        // this.getlistCppExpectedObjectives();
      }
    }
  }
  changeUpdatesSideNavText(text: string) {
    this.updatesSideNavText = text;
    this.showErrorCommentField = false;
  }

  getListCppActionType() {
    this.isLoadingListCppActionType = true;

    if (this.getListCppActionType$) {
      this.getListCppActionType$.unsubscribe();
    }

    this.getListCppActionType$ = this.selfAssessmentService.listCppActionTypeForUser()
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.listCppActionType = [];
          for (let i = 0; i < data.response.length; i++) {
            this.listCppActionType.push(
              { id: data.response[i][0] + i, title: data.response[i], other: data.response[i] == 'Altro (specificare)' }
            )
          }
          this.isLoadingListCppActionType = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingListCppActionType = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingListCppActionType = false;
      })
  }
  createUpdatesOfAction(action: any) {
    this.isLoadingCreateUpdatesOfAction = true;

    if (this.createUpdatesOfAction$) {
      this.createUpdatesOfAction$.unsubscribe();
    }

    let _action = { ...action, updates: [{ updateText: this.updatesSideNavText }] }

    this.createUpdatesOfAction$ = this.selfAssessmentService.createUpdatesOfActionForUser(this.roundId, _action)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateUpdatesOfAction = false;
        } else {
          this.onSidebar(false);

          this.getCppUserDetails();
          // Recupera i type
          // this.listCppActionTypeForManager();
          // Recupera la lista obiettivi
          // this.getlistCppExpectedObjectives();
          this.getListCompetenceUpdateCppComments();
          this.showSaveSuccessfulToaster();
          this.isLoadingCreateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateUpdatesOfAction = false;
      })
  }

  setStepList(skipSet?:boolean) {
    this.stepList = [];
    this.stepList.push(
      {
        id: 'gap-analysis',
        referenceId: '456566454',
        number: 1,
        stepId: 1,
        title: 'Delta analysis',
        isComplete: this.cppDetails?.isSurveyCompleted
      },
      {
        id: 'dev-plan',
        referenceId: '456566454',
        number: 2,
        stepId: 2,
        title: 'Piano di sviluppo',
        isComplete: this.cppDetails?.developmentPlanStatus == 'COMPLETED' ? true : false,
        disabled: !this.developmentPlan?.developmentPlan?.developmentPlanId
      }
    );
    // const step = this.stepList.find(el => !el.isComplete && el == 'ciao');
    // if (this.stepList[1].stepId == 2 && this.stepList[1].disabled) {
    //   this.currentStep = this.stepList[0];
    // } else {
    //   this.currentStep = back ? this.stepList[0] : step ? step : this.stepList[0];
    // }
    if (this.developmentPlan?.developmentPlan?.developmentPlanId && this.cppDetails?.isSurveyCompleted && !skipSet) {
      this.currentStep = this.stepList[1];
    } else {
      this.currentStep = this.stepList[0];
    }
    // this.onStepClicked(this.currentStep, getCppUserDetails);
  }

  changeSelectedYear(round: any) {
    this.selectedRound = round;
    this.roundId = this.selectedRound.roundId;
    this.router.navigate(['selfAssessment/cppDetails/' + this.selectedRound.roundId + '/' + this.userId])
  }

  selectCompetence(id: string) {

  }

  openCompetenceModal() {
    this.modalService.open('competence');
  }
  closeCompetenceModal() {
    this.modalService.close('competence');
  }

  openCompetenceLevelModal() {
    this.modalService.open('competence-level');
  }
  closeCompetenceLevelModal() {
    this.modalService.close('competence-level');
  }

  openChangesCommentModal() {
    this.modalService.open('changes-comment');
  }
  closeChangesCommentModal() {
    this.modalService.close('changes-comment');
  }

  openCompetenceDetailModal(surveyId: string) {
    this.getCppUserSurveyCompetenceDetails(surveyId)
    this.modalService.open('competence-detail');
  }

  closeCompetenceDetailModal(confirm?: boolean) {
    if (confirm && this.isRoundActive) {
      this.updateCurrentCppSurvey(true);
    }
    this.modalService.close('competence-detail');
  }

  closeDisabledDevelopmentPlanlModal() {
    this.modalService.close('disabled-developmentPlan');
  }

  closeSaveSuccessfulModal() {
    this.modalService.close('save-successful');
  }

  descriptionChanged(text: string) {

  }

  menuActionSideNav(item: any, update: any) {
    if (item && item.id == 'edit') {
      if (update) {
        update.isOpen = true;
      }
    } else {
    }
  }

  async onSidebar(open: boolean, surveyId?: string, competence?: any, action?: any, actionIndex?: number) {
    this.showErrorCommentField = false;
    this.updatesSideNavText = '';
    if (surveyId) {
      await this.getCppUserSurveyCompetenceDetails(surveyId)
    }
    if (action) {
      for (let i = 0; i < action.actionUpdates.length; i++) {
        action.actionUpdates[i] = { ...action.actionUpdates[i], isOpen: false }
      }
      this.selectActionSideNav = { ...action, index: actionIndex };
    }
    if (competence) {
      this.selectedCompetenceSideNav = JSON.parse(JSON.stringify(competence));
    }
    this.showSideNav = open;
    this.isActionBar = surveyId ? false : true;
    // Quando una sidebar si apre, blocco lo scroll al <body> dell'html affinché la pagina dell'applicazione non sia più scrollabile
    const appBody = document.getElementsByTagName("body") && document.getElementsByTagName("body")[0];
    if (appBody) {
      appBody.style.overflowY = this.showSideNav ? "scroll" : "visible";
      appBody.style.position = this.showSideNav ? "fixed" : "static";
    }

    if (!this.isActionBar) {
      this.titleComment = this.translate.instant("developmentPlanCpp.WRITE_UPDATE");
      this.placeholderComment = this.translate.instant("developmentPlanCpp.INSERT_COMMENT");
    }

  }
  showToaster() {
    let x: any = document.getElementById("snackbar");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }
  updateCurrentCppSurvey(skipSet?:boolean) {
    this.isFetchingCppSurvey = true;

    this.selfAssessmentService.updateCppSurveyForUser(this.roundId, this.cppSurvey.id, this.selectedAnswers)
      .subscribe((data: SenecaResponse<any>) => {

        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCppSurvey = false;
        } else {
          this.getCppUserDetails(skipSet);
          this.showToaster();
          this.isFetchingCppSurvey = false;
        }


      }, (err: string) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "ta002",
          text: this.translate.instant("errors." + err),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isFetchingCppSurvey = false;
      })

  }

  getCppRounds() {
    this.isLoadingRoundList = true;

    if (this.getRoundList$) {
      this.getRoundList$.unsubscribe();
    }

    this.getRoundList$ = this.selfAssessmentService.getCppRounds(true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.roundList = data.response;
          this.selectedRound = this.roundList.find(el => el.roundId == this.roundId);
          this.isRoundActive = this.selectedRound?.roundStatus == 'RUNNING'
          this.isLoadingRoundList = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingRoundList = false;
        }

      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRoundList = false;
      })
  }
  downloadCppReport() {
    this.isDownloadingCppReport = true;

    this.selfAssessmentService.downloadCppReport(this.roundId)
      .subscribe((data: SenecaResponse<string>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingCppReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingCppReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingCppReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingCppReport = false;
          })
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingCppReport = false;
      })
  }

  getDevelopmentPlan(skipSet?:boolean) {
    this.isLoadingDevelopment = true;

    if (this.getUserDevelopment$) {
      this.getUserDevelopment$.unsubscribe();
    }

    this.getUserDevelopment$ = this.selfAssessmentService.getDevelopmentPlan(this.roundId, true)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.developmentPlan = data.response;
          this.getActionMap();
          // Faccio il Map del gap che mi arriva nello step 1 perchè non ha senso rifare la complessa logica a BE
          this.getGapCompetence();
          this.setStepList(skipSet);
          this.isLoadingDevelopment = false;
        } else {
          this.isLoadingDevelopment = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.developmentPlan = undefined;
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDevelopment = false;
      })
  }
  actionBtnForSideNav(confirm: boolean, action: any, update: any, isCompetenceUpdate?: boolean) {
    if (confirm) {
      if (isCompetenceUpdate) {
        this.updateUpdatesOfCompetenceForManager(action, update)
      } else {
        this.updateUpdatesOfActionForManager(action, update)
      }
    } else {
      this.updatesSideNavText = '';
    }
    update.isOpen = false;
  }
  updateUpdatesOfCompetenceForManager(action?: any, update?: any) {
    this.isLoadingUpdateUpdatesOfAction = true;

    if (this.updateUpdatesOfActionForManager$) {
      this.updateUpdatesOfActionForManager$.unsubscribe();
    }

    let updatesCompetence: any[] = [];
    for (let i = 0; i < action.competenceUpdates.length; i++) {
      updatesCompetence.push({
        updateId: action.competenceUpdates[i].noteId,
        updateText: action.competenceUpdates[i].description
      });
    }

    this.updateUpdatesOfActionForManager$ = this.selfAssessmentService.updateUpdatesOfCompetenceForUser(this.roundId, action.competenceId, updatesCompetence)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          // Assegno gli obiettiv
          this.onSidebar(false);
          this.getCppUserDetails();
          this.getListCompetenceUpdateCppComments();
          this.showSaveSuccessfulToaster();

          this.isLoadingUpdateUpdatesOfAction = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateUpdatesOfAction = false;
      })
  }
  changeUpdatesActionText(text: string, update: any) {
    update.description = text;
  }
  updateUpdatesOfActionForManager(action: any, update: any) {
    this.isLoadingUpdateUpdatesOfAction = true;

    if (this.updateUpdatesOfActionForManager$) {
      this.updateUpdatesOfActionForManager$.unsubscribe();
    }
    let _action = { ...action, updates: [{ updateText: update.description, updateId: update.noteId }] }
    this.updateUpdatesOfActionForManager$ = this.selfAssessmentService.updateUpdatesOfActionForUser(this.roundId, _action)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          // Assegno gli obiettiv
          for (let i = 0; i < data.response.length; i++) {
            this.onSidebar(false);
            if (this.cppDetails?.developmentPlanCreated) {
              // Recupera il plan
              this.getDevelopmentPlan();
            }
            // Recupera la lista obiettivi
            this.getListCompetenceUpdateCppComments();
            this.showSaveSuccessfulToaster();
          }
          this.isLoadingUpdateUpdatesOfAction = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateUpdatesOfAction = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateUpdatesOfAction = false;
      })
  }

  // Map delle actions 
  getActionMap() {
    for (let p = 0; p < this.developmentPlan.competences.length; p++) {
      let res: Array<{ actionId: string, competenceId: string, isOpen: boolean, type: string, scope: string, date: Date, actionUpdates: Array<any>, creationUserId: string }> = [];
      let type: Array<string> = [];
      let scope: Array<string> = [];
      let actions: Array<any> = [];
      actions = this.developmentPlan.competences[p]?.actions;
      if (actions?.length) {
        for (let i = 0; i < actions?.length; i++) {
          let actionAttributes = actions[i].actionAttributes;
          for (let j = 0; j < actionAttributes.length; j++) {
            type[i] = type[i] ? type[i] : actionAttributes[j].type == 'CPP_ACTION_TYPE' ? actionAttributes[j].value : '';
            scope[i] = scope[i] ? scope[i] : actionAttributes[j].type == 'CPP_ACTION_SCOPE' ? actionAttributes[j].value : '';
          }
          res.push({
            actionId: this.developmentPlan.competences[p].actions[i].actionId,
            competenceId: this.developmentPlan.competences[p].competenceId,
            isOpen: false,
            type: type[i],
            scope: scope[i],
            date: actions[i].endDate,
            actionUpdates: actions[i].actionUpdates,
            creationUserId: actions[i].creationUserId
          })
        }
      }
      this.developmentPlan.competences[p].actions = res;
    }
  }

  // Map del gap per essere usato nello step 2
  getGapCompetence() {
    let ids: Array<string> = [];
    //Cerco le mie tre competenze 
    for (let i = 0; i < this.developmentPlan.competences.length; i++) {
      // Assegno il gap
      for (let k = 0; k < this.cppDetails?.competencesGap.length; k++) {
        if (this.cppDetails.competencesGap[k].competenceId == this.developmentPlan.competences[i].referenceId) {
          this.developmentPlan.competences[i].gap = this.cppDetails.competencesGap[k].delta;
          this.developmentPlan.competences[i].possessedPerc = this.cppDetails.competencesGap[k].possessedPerc;
        }
      }
    }
  }


  getListCompetenceUpdateCppComments() {
    this.isLoadingCompetenceCppComments = true;

    if (this.getListCompetenceUpdateCppComments$) {
      this.getListCompetenceUpdateCppComments$.unsubscribe();
    }

    this.getListCompetenceUpdateCppComments$ = this.selfAssessmentService.getListCompetenceUpdateCppComments(this.roundId)
      .subscribe((data: SenecaResponse<Array<AlloyPerformanceCppServiceResponses.CppCompetenceUpdateComment>>) => {
        if (data && data.response) {
          // Assegno solo i commenti
          this.cppCommentList = data.response;
          this.isLoadingCompetenceCppComments = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCompetenceCppComments = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCompetenceCppComments = false;
      })
  }

  getSideNavBarStyle(showNav: boolean | null) {
    let navBarStyle: any = {};

    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = window.innerWidth + 'px';
    navBarStyle.maxWidth = (660 + 'px');
    navBarStyle[this.direction] = (showNav ? 0 : (window.innerWidth * -1)) + 'px';

    return navBarStyle;
  }

  changeIntendedGoal(goalSelected: any) {
    this.intendedGoalSelected = goalSelected
  }

  // Toggle della sidebar
  toggleSidebar() {
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }


  getCppUserDetails(skipSet?:boolean, skipDevelopment?:boolean) {
    this.isLoadingCppUserDetails = true;

    if (this.getCppUserDetails$) {
      this.getCppUserDetails$.unsubscribe();
    }

    this.getCppUserDetails$ = this.selfAssessmentService.getCppUserDetails(this.roundId)
      .subscribe((data: SenecaResponse<AlloyPerformanceCppServiceResponses.MyCppUserDetails>) => {
        if (data && data.response) {
          this.cppDetails = data.response;
          if ((this.cppDetails?.developmentPlanStatus == 'COMPLETED' || this.cppDetails?.developmentPlanStatus == 'STARTED') && !skipDevelopment) {
            this.getDevelopmentPlan(skipSet);
          }
          // Recupera i type
          this.getListCppActionType();
          this.isLoadingCppUserDetails = false;
        } else if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors.MISSING_REQUIRED_DATA"),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppUserDetails = false;
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppUserDetails = false;
        }
      );
  }


  getCppUserSurveyCompetenceDetails(surveyId: string) {
    this.isLoadingCppSurveyDeteils = true;

    if (this.getCppUserSurveyCompetenceDetails$) {
      this.getCppUserSurveyCompetenceDetails$.unsubscribe();
    }

    this.getCppUserSurveyCompetenceDetails$ = this.selfAssessmentService.getCppUserSurveyCompetenceDetails(this.roundId, surveyId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.cppSurvey = data.response[0];
          if (this.cppSurvey?.questions && this.cppSurvey.questions.length) {
            this.selectedAnswers = [];
            this.originalSelectedAnswers = [];
            for (let i = 0; i < this.cppSurvey.questions.length; i++) {
              let question = this.cppSurvey.questions[i];
              let header = question.text.substring(0, question.text.indexOf('\n'));
              let footer = question.text.substring(question.text.lastIndexOf('\n'), question.text.length);
              footer = footer.replaceAll('\n', '');
              let list = question.text.substring(question.text.indexOf('\n\n'), question.text.lastIndexOf('\n'));
              list = list.substring(1);
              list = list.replaceAll('\n', '<br>');

              question.text = list?.length ? '<b>' + header + '</b><br>' + list + '<b><br>' + footer + '*</b>' :
                '<b>' + header + '</b><br><br>' + '<b>' + footer + '*</b>';

              let answersRadioList: any[] = [];
              question.answers.forEach((x: any, index: number) => {
                answersRadioList.push({
                  id: index,
                  value: x
                })
              })
              question.answersRadioList = answersRadioList;
              if (question.selectedAnswerIndex != null && question.selectedAnswerIndex != undefined) {
                this.selectedAnswers.push(
                  {
                    questionId: question.questionId,
                    selectedAnswerIndex: question.selectedAnswerIndex
                  }
                );
                question.selectedAnswer = question.answersRadioList.filter((x: any) => {
                  return x.id == question.selectedAnswerIndex;
                })[0];
              }
            }
            this.originalSelectedAnswers = JSON.parse(JSON.stringify(this.selectedAnswers));
          }
          this.selectedAnswersCopy = [...this.selectedAnswers];
          this.isLoadingCppSurveyDeteils = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppSurveyDeteils = false;
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCppSurveyDeteils = false;
        }
      );
  }
  menuAction(item: any, competenceId: string, action: any) {
    // this.actionId = action.actionId;
    this.isAlreadyOpen = true;
    if (item && item.id == 'edit') {
      if (action) {
        action.isOpen = true;
      }
      if (this.isAlreadyOpen) {
        // this.saveAction(competenceId, true)
      }
      let findSelectType = this.listCppActionType.find(el => el.title == action.type);
      this.isMoreText = !findSelectType;
      this.selectedCppActionType = { id: action.type[0], title: findSelectType ? action.type : 'Altro (specificare)', other: !findSelectType ? true : false }
      this.selectedCppActionTypeText = findSelectType ? '' : action.type;
      this.scope = action.scope;
      this.endDate = action.date;
      // this.openNewActionClicked(competenceId)
      // this.isUpdateActionId = action.actionId;
    }
    // MI l'utente non può mai eliminare un'azione
    if (item && item.id == 'delete' && false) {
      this.actionId = action ? action.actionId : '';
      this.modalService.open('delete-action')
    }
  }

  closeDeleteActionModal(confirm?: boolean) {
    this.modalService.close('delete-action');
    if (confirm) {
      this.deleteActionOfCompetence(this.actionId);
    }
  }

  deleteActionOfCompetence(actionId: string) {
    this.isLoadingDeleteActionOfCompetence = true;

    if (this.deleteActionOfCompetence$) {
      this.deleteActionOfCompetence$.unsubscribe
    }

    this.deleteActionOfCompetence$ = this.selfAssessmentService.deleteActionOfCompetenceForUser(actionId, this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.onStepClicked(this.currentStep);
          this.isLoadingDeleteActionOfCompetence = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDeleteActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingDeleteActionOfCompetence = false;
      })
  }
  changeTypeCompetence(typeSelected: any) {
    this.selectedCppActionTypeText = '';
    this.selectedCppActionType = typeSelected;
    // Id impostato al recupero dei dati
    if (typeSelected.other) {
      this.isMoreText = true;
    } else {
      this.isMoreText = false;
    }
  }
  changeSelectedCppTypeText(text: string) {
    this.selectedCppActionTypeText = text;
  }

  changeScope(text: string) {
    this.scope = text;
  }

  endDateChanged(endDate: any) {
    this.endDate = endDate;
  }
  saveAction(close?: boolean, updateAction?: boolean, action?: any, competenceId?: string) {
    if (action) {
      action.isOpen = false;
      if (updateAction) {
        const competenceId = this.developmentPlan.competences.find((competence: any) => competence.actions.find((el: any) => el.actionId == action.actionId));
        this.updateActionOfCompetenceForManager(action.actionId, competenceId);
      }
      this.isAlreadyOpen = false;
    }

    if (close) {
      this.openNewAction = undefined;
      this.isAlreadyOpen = false;
      this.resetVariables();
    }
    if (competenceId) {
      this.createActionOfCompetence(competenceId);
      this.openNewAction = undefined;
      this.isAlreadyOpen = false;
      this.resetVariables();
    }
  }
  async openNewActionClicked(id: string) {
    this.openNewAction = undefined;
    this.openNewAction = { id: id, isOpen: true };
    this.isAlreadyOpen = true;

    setTimeout(() => {
      const newActionElement = document.querySelector("#edit-action");
      if (newActionElement) {
        newActionElement.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      }
    }, 150)
  }

  resetVariables() {
    // Reset 
    this.selectedCppActionTypeText = '';
    this.selectedCppActionType = {};
    this.scope = '';
    this.isMoreText = false;
    this.endDate = new Date();
  }

  updateActionOfCompetenceForManager(actionid: string, competence: any) {
    this.isLoadingUpdateActionOfCompetence = true;

    if (this.updateActionOfCompetence$) {
      this.updateActionOfCompetence$.unsubscribe();
    }
    let actions = {
      actionId: actionid,
      competenceId: competence.competenceId,
      type: this.selectedCppActionTypeText.length ? this.selectedCppActionTypeText : this.selectedCppActionType?.title,
      scope: this.scope,
      endDate: this.endDate
    }

    this.updateActionOfCompetence$ = this.selfAssessmentService.updateActionOfCompetenceForUser(this.roundId, actions)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingUpdateActionOfCompetence = false;
        } else {
          this.getDevelopmentPlan();
          this.isLoadingUpdateActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUpdateActionOfCompetence = false;
      })
  }

  showSaveSuccessfulToaster() {
    // toaster 
    let x: any = document.getElementById("snackbar-reminder");
    if (x) {
      x.className = "show";
      setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
    }
  }
  // Recupero dati cpp
  getSurveyInfoCPP(competenceId: string) {
    this.isFetchingCppSurvey = true;

    // if (moduleId) {
    //   this.closePrevModuleModal();
    // }

    this.selfAssessmentService.getCppUserSurveyCompetenceDetails(this.roundId, competenceId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingCppSurvey = false;
        } else if (data.response) {
          this.currentModule = data.response;
          this.currentModule.totalPercentage = Math.floor(this.currentModule.totalPercentage);
          if (this.currentModule.questions && this.currentModule.questions.length) {
            this.selectedAnswers = [];
            this.originalSelectedAnswers = [];
            for (let i = 0; i < this.currentModule.questions.length; i++) {
              let question = this.currentModule.questions[i];
              let header = question.text.substring(0, question.text.indexOf('\n'));
              let footer = question.text.substring(question.text.lastIndexOf('\n'), question.text.length);
              footer = footer.replaceAll('\n', '');
              let list = question.text.substring(question.text.indexOf('\n\n'), question.text.lastIndexOf('\n'));
              list = list.substring(1);
              list = list.replaceAll('\n', '<br>');

              question.text = list?.length ? '<b>' + header + '</b><br>' + list + '<b><br>' + footer + '*</b>' :
                '<b>' + header + '</b><br><br>' + '<b>' + footer + '*</b>';

              let answersRadioList: any[] = [];
              question.answers.forEach((x: any, index: number) => {
                answersRadioList.push({
                  id: index,
                  value: x
                })
              })
              question.answersRadioList = answersRadioList;
              if (question.selectedAnswerIndex != null && question.selectedAnswerIndex != undefined) {
                this.selectedAnswers.push(
                  {
                    questionId: question.questionId,
                    selectedAnswerIndex: question.selectedAnswerIndex
                  }
                );
                question.selectedAnswer = question.answersRadioList.filter((x: any) => {
                  return x.id == question.selectedAnswerIndex;
                })[0];
              }
            }
            this.originalSelectedAnswers = JSON.parse(JSON.stringify(this.selectedAnswers));
          }
          this.isFetchingCppSurvey = false;
        }
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "ta002",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          // this.goBackBrowser();
          this.isFetchingCppSurvey = false;
        })
  }
  createActionOfCompetence(competenceId: string) {
    this.isLoadingCreateActionOfCompetence = true;

    if (this.createActionOfCompetence$) {
      this.createActionOfCompetence$.unsubscribe();
    }
    let actions = [{
      competenceId: competenceId,
      type: this.selectedCppActionTypeText.length ? this.selectedCppActionTypeText : this.selectedCppActionType?.title,
      scope: this.scope,
      endDate: this.endDate
    }]

    this.createActionOfCompetence$ = this.selfAssessmentService.createActionOfCompetenceForUser(this.roundId, actions)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.response) {
          this.getDevelopmentPlan();
          this.getCppUserDetails();
          this.resetVariables();
          this.isLoadingCreateActionOfCompetence = false;
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf000",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingCreateActionOfCompetence = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf010",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCreateActionOfCompetence = false;
      })
  }

  onRadioSelect(event: any, questionId: string) {
    let checkEqual = true;
    let indexToDelete = this.selectedAnswers.findIndex(object => {
      return object.questionId === questionId;
    });

    if (indexToDelete != -1 && indexToDelete != null && indexToDelete != undefined) {
      this.selectedAnswers.splice(indexToDelete, 1);
    }

    this.selectedAnswers.push({
      questionId: questionId,
      selectedAnswerIndex: event.id
    })
  }

  closeUpdateCompetenceCommentModale() {
    this.modalService.close('update-competence-comment');
  }

  checkEqualCompetenceObject() {
    let checkEqual = true;
    this.selectedAnswers.forEach((el) => {
      this.selectedAnswersCopy.forEach((el1) => {
        if (el.questionId == el1.questionId) {
          if (el.selectedAnswerIndex != el1.selectedAnswerIndex) {
            checkEqual = false;
          }
        }
      });
    });
    return checkEqual;
  }
}
