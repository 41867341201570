import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'card-feedback',
  templateUrl: 'card-feedback.component.html',
  styleUrls: ['./card-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardFeedbackComponent implements OnInit {
  @Input() object: any;
  @Input() placeholder: string = 'Inserisci una descrizione';
  @Input() bindValue: string = '';
  @Input() isReceiving: boolean = false;
  @Input() loggedUser: any;
  @Input() readonly: boolean = false;
  @Input() isClap: boolean = false;
  @Input() isHistory: boolean = false;
  @Input() attrAriaLabel: string = '';
  @Input() srcIcon: string = 'assets/img/icons/hard-hat.svg'
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onEditObject: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteObject: EventEmitter<any> = new EventEmitter();

  constructor(private store: Store<fromApp.AppState>) {
    this.store.select(fromApp.getApplicationLang).subscribe((applicationLang) => {
      moment.locale(applicationLang);
    })
  }

  ngOnInit() {
    if (this.object) {
      this.object.creationDateAgo = moment(this.object?.creationDate).fromNow();
    }
  }


  emitOnModelChanged(data?: string) {
    this.onModelChanged.emit(data);
  }

  editObject(id: string) {
    this.onEditObject.emit(id);
  }

  deleteObject(id: string) {
    this.onDeleteObject.emit(id);
  }
}