<div class="page-content-wrapper" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap.gt-xs="16px">

  <banner-home [isAdminBanner]="true"></banner-home>
  <!-- [ngClass]="isCppAdmin && !isAdmin? 'only-cpp-box': ''" -->
  <div class="box-collection-container">
    <div class="row-boxes" fxLayout.xs="column" fxLayoutGap.xs="16px" fxLayout.gt-xs="row wrap"
      fxLayoutGap.gt-xs="16px grid" fxLayoutAlign="start center">

      <!-- Container box per admin normale -->
      <ng-container *ngIf="isAdmin">
        <!-- Setup processi [isDisabled]="true"
          [disabledModalMessage]="boxDisabledModal" -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/settings.svg"
          title="{{'adminHome.cards.PROCESS_SETUP' | translate}}" description="adminHome.cards.PROCESS_SETUP_DESCR"
          [isNew]="false" (onButtonClicked)="goToProcessSetup()">
        </box-home>
        <!-- Setup delle competenze  [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/settings.svg"
          title="{{'adminHome.cards.PROCESS_COMPETENCES' | translate}}"
          description="adminHome.cards.PROCESS_COMPETENCES_DESCR" [isNew]="false"
          (onButtonClicked)="goToSetupCompetences()">
        </box-home>
        <!-- Anagrafica delle persone  (onButtonClicked)="goToPeopleAnag()" -->
        <!--box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" [isDisabled]="true"
          [disabledModalMessage]="boxDisabledModal" iconSrc="../../../../assets/img/icons/users.svg"
          backgroundColor="#FFD1D3" title="{{'adminHome.cards.ANAG' | translate}}"
          description="adminHome.cards.ANAG_DESCR" [isNew]="false"></box-home-->
        <!-- Impersonifica [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" (onButtonClicked)="goToImpersonate()"
          iconSrc="../../../../assets/img/icons/user.svg" title="{{'adminHome.cards.IMPERSONATE' | translate}}"
          description="adminHome.cards.IMPERSONATE_DESCR" [isNew]="false"></box-home>
        <!-- Dashboard [isDisabled]="true" [disabledModalMessage]="boxDisabledModal" -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/pie-chart.svg"
          title="{{'adminHome.cards.DASHBOARD' | translate}}" description="adminHome.cards.DASHBOARD_DESCR"
          [isNew]="false" (onButtonClicked)="goToDashboard()"></box-home>
        <!-- Monitoraggio -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/balance.svg"
          title="{{'adminHome.cards.CALIBRATION' | translate}}" (onButtonClicked)="goToMonitoring()"
          description="adminHome.cards.CALIBRATION_DESCR" [isNew]="false">
        </box-home>
        <!-- Upward Feedback -->
          <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
          iconSrc="../../../../assets/img/icons/message-circle.svg" title="{{'upwardFeedback.TITLE' | translate}}"
          (onButtonClicked)="goToUpwardFeedback()" description="upwardFeedback.TILE_DESCRIPTION_ADMIN" [isNew]="false">
        </box-home>
      </ng-container>

      <!-- CPP -->
      <ng-container *ngIf="isCppAdmin">
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
          iconSrc="../../../../assets/img/icons/clipboard-list.svg" title="{{'adminHome.cards.CPP' | translate}}"
          (onButtonClicked)="goToCpp()" description="adminHome.cards.CPP_DESCR" [isNew]="false">
        </box-home>
        <!-- CPP dashboard -->
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50"
        iconSrc="../../../../assets/img/icons/pie-chart.svg" title="{{'adminHome.cards.CPP_DASHBOARD' | translate}}"
        (onButtonClicked)="goToCppDashboard()" description="adminHome.cards.CPP_DASHBOARD_DESCR" [isNew]="false">
      </box-home>
      </ng-container>

      <!-- Monitoraggio (vecchia calibration) -->
      <!--ng-container *ngIf="canAccessCalibration">
        <box-home [iconBox]="true" fxFlex.gt-sm="33" fxFlex.sm="50" iconSrc="../../../../assets/img/icons/balance.svg"
          backgroundColor="#E1F1FD" title="{{'adminHome.cards.CALIBRATION' | translate}}"
          (onButtonClicked)="goToCalibration()" description="adminHome.cards.CALIBRATION_DESCR" [isNew]="false">
        </box-home>
      </ng-container-->
    </div>
  </div>
</div>