<div class="page-content-wrapper">
    <!-- Sidebar -->
    <div class="side-nav-bar" [ngClass]="{ 'side-nav-bar-collapsed': !(showSideNav) }">
        <!-- Overlay -->
        <div class="side-nav-bar-overlay"
            [ngStyle]="{ 'transition': 'background-color ' + duration + 's, visibility ' + duration + 's'}"
            [ngClass]="{ 'side-nav-bar-overlay-collapsed': !(showSideNav) }" (click)="onSidebar(false)"></div>

        <!-- side bar-->
        <div class="side-nav-bar-menu-container" fxLayout="column" [ngStyle]="getSideNavBarStyle((showSideNav))">

            <!-- Pulsante di chiusura -->
            <div class="close-button" (click)="onSidebar(false)">
                <svg-icon src="assets/img/icons/x.svg" class="side-icon-close" [applyClass]="true">
                </svg-icon>
                <p translate="generic.CLOSE"></p>
            </div>

            <!-- Contenuto -->
            <div class="side-nav-bar-content-container">
                <ng-container *ngTemplateOutlet="isActionBar? actionBar : surveyNav"
                    (clickOutside)="onSidebar(false)"></ng-container>
            </div>
        </div>
    </div>

    <ng-template #actionBar>
        <ng-container *ngIf="!isLoadingRoundList">
            <!-- Intestazione Sidebar -->
            <div class="anag-container">
                <p class="anag-title" translate="generic.UPDATES"></p>
                <!--info anagrafiche -->
                <div class="anag-info-container">
                    <p class="title">{{selectedCompetenceSideNav?.competenceAttributes[0]?.value}}</p>
                    <div class="header__description">
                        <p>{{'developmentPlanCpp.COMPETENCE_TO_DEVELOPED' | translate}}</p>
                        <div class="gap_number">Delta
                            <div>></div> <span
                                [ngClass]="{'positive':selectedCompetenceSideNav.gap > 0 }">{{selectedCompetenceSideNav.gap}}</span>
                        </div>
                        <adequacy-indicator
                            [adequacyPercentage]="selectedCompetenceSideNav.possessedPerc"></adequacy-indicator>
                    </div>

                    <!-- Riepilogo Azione -->

                    <div class="card-container">
                        <div class="header-type-container">
                            <div class="type-container">
                                <div class="type-container-text-icon">
                                    <svg-icon src="../../../assets/img/icons/sprout.svg" class="small-size gray-gray2"
                                        [applyClass]="true"></svg-icon>
                                    <p><span class="bold-text">{{('developmentPlanCpp.ACTION_DEV' | translate) + ' '+
                                            (selectActionSideNav?.index + 1)}}</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="divisor-line"></div>
                        <div class="information-container">
                            <div class="information-text-container">
                                <div class="specific-information">
                                    <!-- Card applauso -->
                                </div>
                                <p class="information-text">
                                    <span class="bold-text"> {{('developmentPlanCpp.TYPE' | translate) + ': '}}</span>
                                    <span>{{selectActionSideNav?.type}}</span>
                                </p>
                                <p class="information-text">
                                    <span class="bold-text">{{('developmentPlanCpp.SCOPE' | translate) + ': '}}</span>
                                    <span>{{selectActionSideNav?.scope}}</span>
                                </p>
                                <p class="information-text">
                                    <span class="bold-text">
                                        {{('developmentPlanCpp.DATE_END' | translate) + ':'}}</span>
                                    <span>{{selectActionSideNav?.date | date: 'dd/MM/YYYY'}}</span>
                                </p>
                            </div>
                        </div>
                        <ng-container *ngFor="let update of selectActionSideNav?.actionUpdates">
                            <card-comments *ngIf="!update.isOpen" [loggedUser]="loggedUser" [disabled]="!isRoundActive"
                                [object]="update" (onMenuClicked)="menuActionSideNav($event, update)">
                            </card-comments>
                            <div class="updates-text-area-container" *ngIf="update.isOpen">
                                <alloy-text-area (onModelChanged)="changeUpdatesActionText($event, update)"
                                    [bindValue]="update.description"
                                    [placeholder]="'developmentPlanCpp.INSERT_COMMENT' | translate"></alloy-text-area>
                                <div class="btn-container">
                                    <p class="btn-update"
                                        (click)="actionBtnForSideNav(false, selectActionSideNav, update)">
                                        {{'generic.CANCEL' | translate}}</p>
                                    <p class="btn-update green"
                                        (click)="actionBtnForSideNav(true, selectActionSideNav, update)">
                                        {{'generic.SAVE' | translate}}</p>
                                </div>
                            </div>
                        </ng-container>
                        <div class="updates-text-area-container" *ngIf="isRoundActive">
                            <p translate="developmentPlanCpp.WRITE_UPDATE"></p>
                            <alloy-text-area (onModelChanged)="changeUpdatesSideNavText($event)"
                                [bindValue]="updatesSideNavText"
                                [placeholder]="'developmentPlanCpp.INSERT_COMMENT' | translate"></alloy-text-area>
                        </div>
                        <div class="no-updates" *ngIf="!selectActionSideNav?.actionUpdates?.length">
                            <p> {{'developmentPlanCpp.NO_UPDATES'| translate}}</p>
                            <svg-icon class="placeholder-plan-svg"
                                src="../../../assets/img/placeholder/waiting.svg"></svg-icon>
                        </div>
                    </div>
                    <div class="row-section" *ngIf="isRoundActive">
                        <alloy-button [label]="'generic.CANCEL' | translate" (onButtonClicked)="onSidebar(false)"
                            type="secondary bordless black" size="large">
                        </alloy-button>
                        <alloy-button [disabled]="updatesSideNavText? false : true"
                            [label]="'generic.INSERT' | translate" onButtonClicked="" type="success" size="large"
                            (onButtonClicked)="createUpdatesOfAction(selectActionSideNav)">
                        </alloy-button>
                    </div>

                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingRoundList">
            <div class="loader spinner-big"></div>
        </ng-container>
    </ng-template>
    <ng-template #surveyNav>
        <ng-container *ngIf="!isLoadingDevelopment && !isLoadingRoundList">
            <div class="anag-container">
                <p class="anag-title" translate="generic.UPDATES"></p>
                <!--info anagrafiche -->
                <div class="anag-info-container">
                    <p class="title">{{selectedCompetenceSideNav?.competenceAttributes[0]?.value}}</p>
                    <div class="header__description">
                        <p>{{'developmentPlanCpp.COMPETENCE_TO_DEVELOPED' | translate}}</p>
                        <div class="gap_number">Delta
                            <div>></div> <span
                                [ngClass]="{'positive':selectedCompetenceSideNav?.gap > 0 }">{{selectedCompetenceSideNav?.gap}}</span>
                        </div>
                        <adequacy-indicator
                            [adequacyPercentage]="selectedCompetenceSideNav?.possessedPerc"></adequacy-indicator>
                    </div>
                </div>
                <ng-container *ngIf="!isLoadingCppSurveyDeteils">
                    <!-- <p class="module-title">{{cppSurvey?.title}}</p> -->
                    <div class="question-container" *ngFor="let question of cppSurvey?.questions; let i = index">
                        <div fxLayout="row" fxLayoutAlign="start end">
                            <p class="position-relative">
                                <span class="text-question" [innerHTML]="question.text"></span>
                            </p>
                        </div>
                        <alloy-radio [disabled]="!isRoundActive"
                            (onItemChange)="onRadioSelect($event, question.questionId)" fxLayout="column"
                            fxLayoutAlign="start start" [items]="question.answersRadioList"
                            [bindValue]="question.selectedAnswer" radioName="question-cpp-radio-{{i}}"></alloy-radio>
                    </div>
                    <ng-container *ngFor="let update of selectedCompetenceSideNav?.competenceUpdates">
                        <card-comments *ngIf="!update.isOpen" [loggedUser]="loggedUser" [disabled]="!isRoundActive"
                            [object]="update" (onMenuClicked)="menuActionSideNav($event, update)">
                        </card-comments>
                        <div class="updates-text-area-container" *ngIf="update.isOpen">
                            <alloy-text-area (onModelChanged)="changeUpdatesActionText($event, update)"
                                [bindValue]="update.description"
                                [placeholder]="'developmentPlanCpp.INSERT_COMMENT' | translate"></alloy-text-area>
                            <div class="btn-container">
                                <p class="btn-update"
                                    (click)="actionBtnForSideNav(false, selectedCompetenceSideNav, update, true)">
                                    {{'generic.CANCEL' | translate}}</p>
                                <p class="btn-update green"
                                    (click)="actionBtnForSideNav(true, selectedCompetenceSideNav, update, true)">
                                    {{'generic.SAVE' | translate}}</p>
                            </div>
                        </div>
                    </ng-container>
                    <div class="updates-text-area-container" *ngIf="isRoundActive">
                        <p>{{titleComment}}</p>
                        <alloy-text-area [error]="showErrorCommentField" (onModelChanged)="changeUpdatesSideNavText($event)"
                            [bindValue]="updatesSideNavText" [placeholder]="placeholderComment"></alloy-text-area>
                        <p *ngIf="showErrorCommentField" class="form-error"
                            translate="developmentPlanCpp.ERROR_COMMENT_REQUIRED"></p>
                    </div>
                </ng-container>
                <div class="row-section" *ngIf="!isLoadingCppSurveyDeteils && isRoundActive">
                    <alloy-button [label]="'generic.CANCEL' | translate" (onButtonClicked)="onSidebar(false)"
                        type="secondary bordless black" size="large">
                    </alloy-button>
                    <alloy-button [label]="'developmentPlanCpp.UPDATE_COMPETENCE' | translate" onButtonClicked=""
                        type="success" size="large" (onButtonClicked)="openCreateUpdateModal()">
                    </alloy-button>
                </div>
                <ng-container *ngIf="isLoadingCppSurveyDeteils">
                    <div class="loader spinner-medium"></div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoadingDevelopment || isLoadingRoundList">
            <div class="loader spinner-big"></div>
        </ng-container>
    </ng-template>
    <div id="snackbar">
        <p translate="generic.SAVE_SUCCESSFUL"></p>
    </div>
    <!-- Se ho finito di caricare i dati dell'utente -->
    <ng-container *ngIf="!isLoadingYearList && !isLoadingPersonDetails && personDetails">
        <header-dossier [user]="loggedUser" [hideAction]="true" [hasTabs]="false" [yearItems]="roundList"
            [selectedYear]="selectedRound" (onSelectedYear)="changeSelectedYear($event)">
        </header-dossier>

        <div class="main-content-wrapper">
            <ng-container>
                <!-- Contenitore dati caricati -->
                <ng-container
                    *ngIf="currentStep && !isLoadingCppUserDetails && !isLoadingRoundList && !isLoadingDevelopment && !isFetchingCppSurvey && !isLoadingListCppActionType && !isLoadingUpdateActionOfCompetence && !isLoadingCreateActionOfCompetence">
                    <child-content>
                        <div class="main-title">
                            <p class="title" translate="careerPathProgram.TITLE"></p>
                            <div class="subtitle-container">
                                <p class="subtitle">{{('careerPathProgram.SUBTITLE_SELF' | translate)}}
                                    <adequacy-indicator
                                        [adequacyPercentage]="cppDetails?.overallGapPerc"></adequacy-indicator>
                            </div>
                        </div>
                        <stepper [statuses]="stepList" [dots]="2" [currentStatus]="currentStep"
                            (stepClicked)="onStepClicked($event)"></stepper>

                        <!-- 1 - Gap analisi -->
                        <ng-container *ngIf="currentStep.id == 'gap-analysis'">
                            <div class="page-header">
                                <!-- In caso in cui non si è compilato le domande del modulo -->
                                <ng-container *ngIf="!cppDetails?.isSurveyCompleted">
                                    <div class="fillOut-questionnaire-container">
                                        <div>
                                            <div class="title-container">
                                                <p class="title" translate="careerPathProgram.DELTA_ANALYSIS"></p>
                                            </div>
                                            <p class="description"
                                                [innerHTML]="'careerPathProgram.DELTA_ANALYSIS_DESC' | translate"></p>
                                        </div>
                                        <svg-icon class="placeholder-plan-svg"
                                            src="../../../assets/img/placeholder/waiting.svg"></svg-icon>

                                        <alloy-button *ngIf="isRoundActive"
                                            [label]="'careerPathProgram.FILL_OUT_QUESTIONNAIRE' | translate"
                                            type="success" size="medium"
                                            (onButtonClicked)="redirectService.goToUserCpp(roundId)">
                                        </alloy-button>
                                    </div>
                                </ng-container>


                                <div class="idp"
                                    *ngIf="cppDetails?.isSurveyCompleted && cppDetails?.competencesGap?.length">
                                    <div class="idpTitleWrapper">
                                        <!-- Titolo -->
                                        <div class="idp__title">{{'careerPathProgram.DELTA_ANALYSIS' | translate}}
                                        </div>
                                        <!-- Descrizione, inserire almeno 2 azioni -->
                                        <div class="idp__description">
                                            {{'careerPathProgram.LIST_OF_COMPETNECE' | translate}}</div>
                                    </div>
                                    <div class="divisor-link-cpp"></div>

                                    <!-- Sezione data, variazioni commenti e scarica report -->
                                    <div class="action-deltaAnalysis">
                                        <p class="comment-link" (click)="openChangesCommentModal()">
                                            {{'careerPathProgram.COMMENT'
                                            | translate}}<svg-icon src="assets/img/icons/message-circle.svg"></svg-icon>
                                            <span class="count-wrap">
                                                <p>{{cppDetails.competenceUpdateCommentCount }}</p>
                                            </span>
                                        </p>
                                        <alloy-button iconRight="assets/img/icons/file/xls.svg"
                                            [label]="'careerPathProgram.DOWNLOAD_REPORT' | translate"
                                            [disabled]="isDownloadingCppReport" type="secondary bordless black"
                                            size="small" (onButtonClicked)="downloadCppReport()">
                                        </alloy-button>
                                    </div>


                                    <!-- Tabella -->
                                    <div class="admin-table-container no-border">
                                        <div class="table-content">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            translate="{{ ('careerPathProgram.COMPETENCE' | translate)}}">
                                                        </th>
                                                        <th class="expertise-container">{{ ('careerPathProgram.LEVEL' |
                                                            translate)}}
                                                            <svg-icon src="../../../assets/img/icons/info.svg"
                                                                (click)="openCompetenceLevelModal()"></svg-icon>
                                                        </th>
                                                        <th translate="{{ ('careerPathProgram.DELTA' | translate)}}">
                                                        </th>
                                                        <th translate="{{ ('careerPathProgram.CHANGES' | translate)}}">
                                                        </th>
                                                        <th class="expertise-container">{{
                                                            ('careerPathProgram.EXPERTISE_COVERAGE' |
                                                            translate)}}<svg-icon
                                                                src="../../../assets/img/icons/info.svg"
                                                                (click)="openCompetenceModal()"></svg-icon></th>
                                                        <th translate="{{ ('careerPathProgram.ACTIONS' | translate)}}">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let competence of cppDetails.competencesGap">
                                                        <td>{{competence.competenceName}}</td>
                                                        <td>{{competence.possessedLevel + '/'+
                                                            competence.requiredLevel}}</td>
                                                        <td>{{competence.delta}}</td>
                                                        <td style="width: fit-content">
                                                            <div fxLayoutAlign="center center" class="cpp-user-status"
                                                                [ngClass]="competence.modified?'CHANGES':'NO_CHANGES'">
                                                                <p>{{competence.modified?
                                                                    ( 'careerPathProgram.VARIED' |
                                                                    translate):('careerPathProgram.NONE' | translate)}}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td class="expertise-anomalies-container"><adequacy-indicator
                                                                [adequacyPercentage]="competence.possessedPerc"></adequacy-indicator>
                                                            <svg-icon *ngIf="competence.withAnomaly"
                                                                src="../../../assets/img/icons/anomalia.svg"></svg-icon>
                                                        </td>
                                                        <td class="link"
                                                            (click)="openCompetenceDetailModal(competence.competenceId)">
                                                            {{ 'generic.DETAILS' | translate }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- 2 - Piano di sviluppo -->
                        <ng-container *ngIf="currentStep.id == 'dev-plan'">
                            <div class="page-header">
                                <!-- In caso in cui non si è selezionato nello step precendente almeno 3 competenze -->
                                <ng-container
                                    *ngIf="!(developmentPlan && developmentPlan.competences) && !isLoadingDevelopment">
                                    <div class="title-container">
                                        <p class="title" translate="stepper.DEVELOPMENT_PLAN"></p>
                                    </div>
                                    <p class="description-noPlan"
                                        [innerHTML]="'stepper.DEVELOPMENT_PLAN_DESC' | translate"></p>
                                    <svg-icon class="placeholder-plan-svg"
                                        src="../../../assets/img/placeholder/waiting.svg"></svg-icon>
                                </ng-container>

                                <!-- Mostrare solo nel caso si hanno 3 competenze selezionate -->
                                <ng-container
                                    *ngIf="developmentPlan && developmentPlan.competences?.length && !isLoadingDevelopment">
                                    <div class="idp">
                                        <div class="idpTitleWrapper">
                                            <!-- Titolo -->
                                            <div class="idp__title">{{'developmentPlanCpp.DEVELOPMENT_PLAN' |
                                                translate}}</div>
                                            <!-- Descrizione, inserire almeno 2 azioni -->
                                            <div class="idp__description" [innerHTML]="'developmentPlanCpp.DEVELOPMENT_PLAN_TEXT_SUB'|
                                            translate"></div>
                                            <p class="selected_goal_text">
                                                {{developmentPlan.developmentPlan.growthTarget}}</p>
                                        </div>

                                        <ng-container
                                            *ngFor="let competence of developmentPlan.competences; let competenceIndex = index">
                                            <div class="idpCompetence">
                                                <!-- Competenza completata -->
                                                <div class="idpCompletedStatus-container"
                                                    *ngIf="competence?.competenceStatuses[0]?.status == 'COMPLETED'">
                                                    <p class="idpCompletedStatus">
                                                        {{'Complimenti hai aggiornato questa competenza!'}}</p>
                                                </div>
                                                <!--Competenza da sviluppare-->
                                                <div class="header"
                                                    [ngClass]="{'completed': competence?.competenceStatuses[0]?.status == 'COMPLETED'}">
                                                    <div class="header__title">
                                                        {{competence.competenceAttributes[0].value}}</div>
                                                    <div class="header__description">
                                                        <p>{{'developmentPlanCpp.COMPETENCE_TO_DEVELOPED' | translate}}
                                                        </p>
                                                        <div>></div>
                                                        <div class="gap_number">Delta <span
                                                                [ngClass]="{'positive':competence.gap > 0 }">{{competence.gap}}</span>
                                                        </div>
                                                        <adequacy-indicator
                                                            [adequacyPercentage]="competence.possessedPerc"></adequacy-indicator>
                                                    </div>

                                                    <!--Pulsante aggiornamenti-->
                                                    <div class="rowActions"
                                                        (click)="onSidebar(true, competence.referenceId, competence)">
                                                        <div class="position-relative">
                                                            <alloy-button
                                                                iconRight="assets/img/icons/message-circle.svg"
                                                                label="Aggiorna il livello della competenza" onButtonClicked=""
                                                                type="primary blu-link" size="medium">
                                                            </alloy-button>
                                                            <div class="rowActions__notify"
                                                                [ngClass]="{'news' :competence.competenceUpdates.length}">
                                                                <p>{{competence.competenceUpdates.length}}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- Sezioni obiettivi inseriti e associati alle competenze -->
                                                <ng-container
                                                    *ngFor="let action of competence.actions; let actionIndex = index">
                                                    <div class="idp-card"
                                                        [ngClass]="{'uppder': action.creationUserId !== loggedUser.userId}"
                                                        *ngIf="!action.isOpen">
                                                        <alloy-card [type]="action.type"
                                                            [updatesNumber]="action?.actionUpdates?.length" [disabledDeleteOption]="true"
                                                            [status]="developmentPlan.shared ? competence.competenceStatuses[0].status : ''"
                                                            [title]="('developmentPlanCpp.ACTION_DEV'| translate) + ' ' + (actionIndex+1)"
                                                            [date]="action.date" [scope]="action.scope"
                                                            [disabled]="!isRoundActive"
                                                            [disabledForUser]="action.creationUserId !== loggedUser.userId"
                                                            (onUpdatesClicked)="onSidebar(true, '',competence, action, actionIndex)"
                                                            (onMenuClicked)="menuAction($event, competence.competenceId, action)"></alloy-card>
                                                    </div>

                                                    <div id="edit-action" class="add-new-action-container"
                                                        *ngIf="action.isOpen">
                                                        <p class="title-new-action"
                                                            translate="developmentPlanCpp.ADD_NEW_ACTION"></p>
                                                        <input-container [isObbligatory]="true"
                                                            [isValued]="selectedCppActionType?.id"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.TYPE' | translate}}">
                                                            <alloy-select
                                                                (onSelectedItem)="changeTypeCompetence($event)"
                                                                [placeholder]="'Nessuna tipologia selezionato'"
                                                                [selectedItem]="selectedCppActionType"
                                                                [items]="listCppActionType"
                                                                bindLabel="title"></alloy-select>
                                                            <ng-container *ngIf="isMoreText">
                                                                <input-container [isWithoutInfoIcon]="true"
                                                                    [isCpp]="true" [isObbligatory]="true"
                                                                    [isValued]="selectedCppActionType.id == 'CUSTOM'"
                                                                    title="{{'developmentPlanCpp.SPECIFIC_TYPE' | translate}}">
                                                                    <alloy-input
                                                                        (onModelChanged)="changeSelectedCppTypeText($event)"
                                                                        [bindValue]="selectedCppActionTypeText">
                                                                    </alloy-input>
                                                                </input-container>

                                                            </ng-container>
                                                        </input-container>
                                                        <input-container [isObbligatory]="true" [isValued]="scope"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.PURPOSE' | translate}}">
                                                            <alloy-text-area (onModelChanged)="changeScope($event)"
                                                                [bindValue]="scope"></alloy-text-area>
                                                        </input-container>

                                                        <input-container [isObbligatory]="true" [isValued]="true"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.DATE_END_DEVELOPMENT'| translate}}">
                                                            <alloy-datepicker [minDate]="yesterday" [value]="endDate"
                                                                (dateChanged)="endDateChanged($event)"></alloy-datepicker>
                                                        </input-container>

                                                        <div class="save-button">
                                                            <alloy-button [label]="'generic.CANCEL' | translate"
                                                                type="black-border" size="medium"
                                                                (onButtonClicked)="saveAction(true, false, action)"></alloy-button>
                                                            <alloy-button [label]="'generic.SAVE' | translate"
                                                                type="success" size="medium"
                                                                (onButtonClicked)="saveAction(false, true, action)"></alloy-button>
                                                        </div>
                                                    </div>
                                                </ng-container>





                                                <ng-container>
                                                    <!--Nessuna azione inserita-->
                                                    <div *ngIf="!competence.actions?.length || (competence.competenceId == openNewAction?.id && !openNewAction?.isOpen)"
                                                        class="noDataActions">
                                                        <p>{{'Nessuna azione inserita'}}</p>
                                                    </div>

                                                    <div id="edit-action" class="add-new-action-container"
                                                        *ngIf="competence.competenceId == openNewAction?.id && openNewAction?.isOpen">
                                                        <p class="title-new-action"
                                                            translate="developmentPlanCpp.ADD_NEW_ACTION"></p>
                                                        <input-container [isObbligatory]="true"
                                                            [isValued]="selectedCppActionType?.id"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.TYPE' | translate}}">
                                                            <alloy-select
                                                                (onSelectedItem)="changeTypeCompetence($event)"
                                                                [placeholder]="'Nessuna tipologia selezionato'"
                                                                [selectedItem]="selectedCppActionType"
                                                                [items]="listCppActionType"
                                                                bindLabel="title"></alloy-select>
                                                            <ng-container *ngIf="isMoreText">
                                                                <input-container [isWithoutInfoIcon]="true"
                                                                    [isCpp]="true" [isObbligatory]="true"
                                                                    [isValued]="selectedCppActionType.id == 'CUSTOM'"
                                                                    title="{{'developmentPlanCpp.SPECIFIC_TYPE' | translate}}">
                                                                    <alloy-input
                                                                        (onModelChanged)="changeSelectedCppTypeText($event)"
                                                                        [bindValue]="selectedCppActionTypeText">
                                                                    </alloy-input>
                                                                </input-container>

                                                            </ng-container>
                                                        </input-container>
                                                        <input-container [isObbligatory]="true" [isValued]="scope"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.PURPOSE' | translate}}">
                                                            <alloy-text-area (onModelChanged)="changeScope($event)"
                                                                [bindValue]="scope"></alloy-text-area>
                                                        </input-container>

                                                        <input-container [isObbligatory]="true" [isValued]="true"
                                                            [isWithoutInfoIcon]="true"
                                                            title="{{'developmentPlanCpp.DATE_END_DEVELOPMENT'| translate}}">
                                                            <alloy-datepicker [minDate]="yesterday" [value]="endDate"
                                                                (dateChanged)="endDateChanged($event)"></alloy-datepicker>
                                                        </input-container>

                                                        <div class="save-button">
                                                            <alloy-button [label]="'generic.CANCEL' | translate"
                                                                type="black-border" size="medium"
                                                                (onButtonClicked)="saveAction(true)"></alloy-button>
                                                            <alloy-button [label]="'generic.SAVE' | translate"
                                                                type="success" size="medium"
                                                                (onButtonClicked)="saveAction(false, false, undefined, competence.competenceId)"></alloy-button>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- Actions -->
                                                <div class="actionRow" *ngIf="isRoundActive">
                                                    <alloy-button iconRight="assets/img/icons/plus-square.svg"
                                                        [label]="'Nuova azione'" [disabled]="isAlreadyOpen"
                                                        (onButtonClicked)="openNewActionClicked(competence.competenceId)"
                                                        type="secondary bordless black" size="medium">
                                                    </alloy-button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </child-content>

                </ng-container>
                <!-- Caricamento tab child content -->
                <ng-container
                    *ngIf="!currentStep || isLoadingCppUserDetails || isLoadingRoundList || isFetchingCppSurvey || isLoadingCppUserDetails || isLoadingListCppActionType || isLoadingUpdateActionOfCompetence || isLoadingCreateActionOfCompetence">
                    <div class="loader spinner-big"></div>
                </ng-container>

            </ng-container>


        </div>
    </ng-container>
    <!-- Loader caricamento dati -->
    <ng-container *ngIf="false">
        <div class="loader spinner-big"></div>
    </ng-container>
</div>


<modal id="competence" (onClose)="closeCompetenceModal()" (onCancel)="closeCompetenceModal()"
    (onConfirm)="closeCompetenceModal()" [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content [title]="'careerPathProgram.EXPERTISE_COVERAGE' | translate"
        [text]="'careerPathProgram.modal.EXPERTISE_COVERAGE_SUBTITLE' | translate "
        [subtitle]="'careerPathProgram.EXPERTISE_COVERAGE' | translate">
    </modal-text-content>
    <div class="adeguact-modal-container">
        <div *ngFor="let adequacy of adequacyModalList">
            <adequacy-indicator *ngIf="adequacy !== 'anomalies'" [status]="adequacy"></adequacy-indicator>
            <svg-icon *ngIf="adequacy == 'anomalies'" src="../../../assets/img/icons/anomalia.svg"></svg-icon>
            <p class="modal-adequacy-text">{{(adequacy? 'careerPathProgram.modal.'+ adequacy:'careerPathProgram.modal.NO_COMPETENCE') | translate}}</p>
        </div>
    </div>
</modal>

<!-- Modale livello competenza -->
<modal id="competence-level" (onClose)="closeCompetenceLevelModal()" (onCancel)="closeCompetenceLevelModal()"
    (onConfirm)="closeCompetenceLevelModal()" [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content [title]="'careerPathProgram.modal.COMPETENCE_LEVEL' | translate"
        [text]="'careerPathProgram.modal.POSSESSED_EXPECTED_LEVEL_SUBTITLE' | translate "
        [subtitle]="'careerPathProgram.modal.POSSESSED_EXPECTED_LEVEL' | translate">
    </modal-text-content>
</modal>

<!-- Modale commenta variazione -->
<modal id="changes-comment" (onClose)="closeChangesCommentModal()" (onCancel)="closeChangesCommentModal()"
    (onConfirm)="closeChangesCommentModal()" [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content
        [title]="('careerPathProgram.modal.COMMENT_OF' | translate) + loggedUser.surname + ' ' + loggedUser.forename "
        [subtitle]="'careerPathProgram.modal.MANAGER_COMMENT_YOUR_CHANGES' | translate">
    </modal-text-content>
    <div class="changes-desc-container" *ngIf="cppCommentList?.length">
        <ng-container *ngFor="let comment of cppCommentList">
            <p class="date">{{ ('careerPathProgram.modal.DATE_COMMENT' | translate) + ' ' + (comment.creationDate |
                date:
                'dd/MM/YYYY' )}}
            </p>
            <p class="text">{{comment.comment}}</p>
        </ng-container>
    </div>
    <p *ngIf="!(cppCommentList?.length)" class="text-no-commnet">{{'Nessun commento disponibile'}}</p>
</modal>

<!-- Modale commenta variazione -->
<modal id="competence-detail" (onClose)="closeCompetenceDetailModal()" (onCancel)="closeCompetenceDetailModal()"
    (onConfirm)="closeCompetenceDetailModal(true)"
    [confirmLabel]="isRoundActive? ('generic.SAVE' | translate):('generic.CLOSE' | translate)"
    [cancelLabel]="isRoundActive? ('generic.CANCEL' | translate): ''" [tabIndex]="0">
    <modal-text-content [title]="'careerPathProgram.modal.COMPETENCE_DETAILS' | translate">
    </modal-text-content>
    <ng-container *ngIf="!isLoadingCppSurveyDeteils">
        <p class="module-title">{{cppSurvey?.title}}</p>
        <div class="question-container" *ngFor="let question of cppSurvey?.questions; let i = index">
            <div fxLayout="row" fxLayoutAlign="start end">
                <p class="position-relative">
                    <span class="text-question" [innerHTML]="question.text"></span>
                </p>
            </div>
            <alloy-radio (onItemChange)="onRadioSelect($event, question.questionId)" fxLayout="column"
                [disabled]="!isRoundActive" fxLayoutAlign="start start" [items]="question.answersRadioList"
                [bindValue]="question.selectedAnswer" radioName="question-cpp-radio-{{i}}" [tabIndex]="0"></alloy-radio>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoadingCppSurveyDeteils">
        <div class="loader spinner-medium"></div>
    </ng-container>
</modal>


<!-- Modale blocco accesso step 2 -->
<modal id="disabled-developmentPlan" (onClose)="closeDisabledDevelopmentPlanlModal()"
    (onCancel)="closeDisabledDevelopmentPlanlModal()" (onConfirm)="closeDisabledDevelopmentPlanlModal()"
    [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content [title]="'developmentPlanCpp.DEVELOPMENT_PLAN' | translate"
        [subtitle]="'developmentPlanCpp.modal.TEMPORARILY_UNVAILABLE' | translate"
        [text]="'developmentPlanCpp.modal.TEMPORARILY_UNVAILABLE_DESC' | translate">
    </modal-text-content>
</modal>

<!-- Modale elimina azione -->
<modal id="delete-action" (onClose)="closeDeleteActionModal()" (onCancel)="closeDeleteActionModal()"
    (onConfirm)="closeDeleteActionModal(true)" [confirmLabel]="'generic.CONFIRM' | translate"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content [title]="'developmentPlanCpp.DELETE' | translate"
        [subtitle]="'developmentPlanCpp.DELETE_SUB' | translate" [text]="'developmentPlanCpp.DELETE_TEXT' | translate">
    </modal-text-content>
</modal>

<!-- Modale salvato con successo -->
<modal id="save-successful" (onClose)="closeSaveSuccessfulModal()" (onCancel)="closeSaveSuccessfulModal()"
    (onConfirm)="closeSaveSuccessfulModal()" [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content [title]="'generic.SAVE_SUCCESSFUL' | translate"
        [subtitle]="'generic.SAVE_SUCCESSFUL_SUB' | translate" [text]="'generic.SAVE_SUCCESSFUL_TEXT' | translate">
    </modal-text-content>
</modal>

<modal id="update-competence" (onClose)="closeCreateUpdateModale()" (onCancel)="closeCreateUpdateModale()"
    (onConfirm)="closeCreateUpdateModale(true)" [confirmLabel]="'generic.CONFIRM' | translate"
    [cancelLabel]="'generic.CANCEL' | translate">
    <modal-text-content [title]="'developmentPlanCpp.modal.UPDATE_COMPETENCE_TITLE' | translate"
        [subtitle]="selectedCompetenceSideNav?.competenceAttributes[0]?.value"
        [text]="'developmentPlanCpp.modal.UPDATE_COMPETENCE_DESC' | translate">
    </modal-text-content>
</modal>

<modal id="update-competence-comment" (onClose)="closeUpdateCompetenceCommentModale()"
    (onConfirm)="closeUpdateCompetenceCommentModale()" [confirmLabel]="'generic.CLOSE' | translate">
    <modal-text-content [title]="'developmentPlanCpp.modal.UPDATE_COMPETENCE_TITLE' | translate"
        [subtitle]="selectedCompetenceSideNav?.competenceAttributes[0]?.value"
        [text]="'developmentPlanCpp.modal.UPDATE_COMPETENCE_COMMENT_DESC' | translate">
    </modal-text-content>
</modal>