import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../ngrx/app.reducers";

@Component({
  selector: 'app-selfAssessment',
  templateUrl: './selfAssessment.component.html',
  styleUrls: ['./selfAssessment.component.scss']
})
export class SelfAssessmentComponent implements OnDestroy {
  runningYear$: Subscription;
  runningYear: any;
  applicationLang: string = '';
  applicationLang$: Subscription;
  loggedUser: any;

  constructor(
    public translate: TranslateService,
    private appStore: Store<fromApp.AppState>,
    private store: Store<fromApp.AppState>) {
    // Recupero la lingua dell'applicazione
    this.applicationLang$ = this.appStore.select(fromApp.getApplicationLang).subscribe((applicationLang: any) => {
      if (applicationLang) {
        this.applicationLang = applicationLang;
        this.translate.use(applicationLang);
      }
    });

    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
    });

    this.appStore.select(fromApp.getLoggedUser)
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;

          }
        });
  }

  ngOnDestroy(): void {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.applicationLang$) {
      this.applicationLang$.unsubscribe();
    }
  }
}
