import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'objective-for-mid-term-review',
  templateUrl: 'objective-for-mid-term-review.component.html',
  styleUrls: ['./objective-for-mid-term-review.component.scss']
})
export class ObjectiveForMidTermReview implements OnInit {
  @Input() goal?: any;
  descriptionIsOpened: boolean = false;
  @Input() isInnerHtml: boolean = false;
  @Input() isOperations: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  // Toggle sulla descrizione dell'obiettivo
  toggleDescription() {
    this.descriptionIsOpened = !this.descriptionIsOpened;
  }
}


