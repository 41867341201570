import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { GetUsersDistinctFieldFilterForAdminResponseType, GetUsersDistinctFieldFilterForManagerResponseType } from 'atfcore-commonclasses';
import * as moment from 'moment';
import { combineLatest, Observable, Subscription } from "rxjs";
import { GlobalApplicationData } from "src/app/shared/models/global-application-data.model";
import { SenecaResponse } from "../../../commonclasses";
import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class SharedService {
  applicationData: any;
  result$: Subscription;
  loggedUser: any;

  constructor(private store: Store<fromApp.AppState>,
    private http: HttpClient) {
    let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    const loggedUser$ = this.store.select(fromApp.getLoggedUser);
    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);

    const combinedSelectes$ = combineLatest([globalApplicationData$, loggedUser$, applicationLang$]);
    this.result$ = combinedSelectes$.subscribe(
      ([globalApplicationData, loggedUser, applicationLang]) => {
        this.loggedUser = loggedUser;
        this.applicationData = globalApplicationData;
        if (applicationLang) {
          moment.locale(applicationLang);
        }
      });
  }

  // Recupera il dettaglio di un subordinato
  getMySubordinateUserData(userId: string, perfYear: number, perfYearCode: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-my-subordinate-user-data', {
      params: httpParams
    });
  }

  getDistinctFilters(perfYear: number, perfYearCode: string, isPeopleAppraisal?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('perfYear', perfYear.toString());
    httpParams = httpParams.append('perfYearCode', perfYearCode);
    if (isPeopleAppraisal) {
      return this.http.get<SenecaResponse<GetUsersDistinctFieldFilterForManagerResponseType>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-distinct-field-filter-for-manager', {
        params: httpParams
      });
    } else {
      return this.http.get<SenecaResponse<GetUsersDistinctFieldFilterForAdminResponseType>>(this.applicationData.applicationContext + 'rest-api/alloyperformance-mediator/get-users-distinct-field-filter-for-admin', {
        params: httpParams
      });
    }
  }
}