import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, GetSubordinatesWithPhaseStatusesForManagerResponse, ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse, UpdateEvidenceFeedbackForManagerResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { PeopleAppraisalService } from 'src/app/shared/services/peopleAppraisal.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../core/ngrx/core.actions";
import { Params, ActivatedRoute } from '@angular/router';
import { Subordinateservice } from 'src/app/peopleAppraisal/services/subordinates.service';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';
import { BadgeUtils } from 'src/app/utils/badge.utils';
import { evidenceObjectForCreate } from 'src/app/selfAssessment/createUpdateEvidence/createUpdateEvidence.component';

@Component({
  selector: 'app-peopleAppraisalOperations-createUpdateEvidence',
  templateUrl: './createUpdateEvidence.component.html',
  styleUrls: ['./createUpdateEvidence.component.scss']
})
export class CreateUpdateEvidenceComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any = {};
  subordinatesWithPhaseStatuses$: Subscription = new Subscription();
  subordinatesWithPhaseStatuses: any[] = [];
  userId: string = '';
  getPersonDetails$: Subscription = new Subscription();
  personDetails$: Subscription = new Subscription();
  personDetails: any;
  isFetchingCreateDevelopmentAction: boolean = false;
  isFetchingUpdateDevelopmentAction: boolean = false;
  createDevelopAction$: Subscription | null = null;
  updateDevelopAction$: Subscription | null = null;
  isFetchingGetDevelopAction: boolean = false;
  getDevelopAction$: Subscription | null = null;
  modalCreateUpdateEvidenceTitle: string = '';
  modalCreateUpdateEvidenceSubtitle: string = '';
  modalCreateUpdateEvidenceText: string = '';
  modalCreateUpdateEvidenceTitleConfirmTextButton: string = '';
  modalCreateUpdateEvidenceTextCloseTextButton: string = '';
  chargeSelectedUser: boolean = true;
  today = new Date();

  objectiveToAssociateTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  contestTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  osservationsTooltipModal: ApplicationModalMessage = {
    modalId: '---',
    title: '',
    text: ''
  };
  modalCreateUpdateEvidenceId: string = '';

  goalId: string = '';

  evidenceId: string = '';
  isFetchingCreateEvidence: boolean = false;
  isFetchingUpdateEvidence: boolean = false;
  isFetchingGetEvidence: boolean = false;
  evidence = {
    goalId: '',
    comment: '',
    observations: '',
    feedbackId: ''
  }
  evidenceCopy = {
    goalId: '',
    comment: '',
    observations: '',
    feedbackId: ''
  }
  objectiveOptions: any[] = [];
  isFetchingObjectiveOptions: boolean = false;
  objectiveAssociated: any;
  objectiveAssociatedCopy: any;
  isLoadingGoalsToAssociate: boolean = false;
  getGoalsToAssociateToEvidence$: Subscription = new Subscription();
  getEvidenceById$: Subscription = new Subscription();
  createEvidence$: Subscription = new Subscription();
  updateEvidence$: Subscription = new Subscription();
  isImpersonate: boolean = false;

  constructor(
    private subordinatesService: Subordinateservice,
    public translate: TranslateService,
    public peopleAppraisalService: PeopleAppraisalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {

    this.store.select(fromApp.getShowImpesonificaitonBanner)
      .subscribe((showImpersonateBanner: boolean) => {
        this.isImpersonate = showImpersonateBanner;
      })

    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.userId = params.userId;
              this.goalId = params.goalId;
              this.evidenceId = params.evidenceId;
              // Recupero la persona selezionata, se è presente nello store
              this.personDetails$ = this.store.select(fromApp.getSubordinatesWithPhaseStatuses).subscribe((subordinatesList) => {
                let subordinates = subordinatesList?.filter((x: any) => {
                  return !x.structureUser
                })
                this.personDetails = subordinates && subordinates.filter((person: GetSubordinatesWithPhaseStatusesForManagerResponse) => {
                  return person.userId === this.userId;
                })[0];


                // Se non ho trovato il dato in redux, allora chiamo un apposito servizio per avere il dettaglio della persona
                if (!this.personDetails) {
                  this.chargeSelectedUser = false;
                  this.getPersonDetails();
                } else if (this.chargeSelectedUser) {
                  this.getGoalsToAssociate();
                }
              });
            }
          );
      }
    });
  }

  ngOnInit() {
    this.translate.get(
      [
        'generic.INFO',
        'evidences.ASSOCIATE_TO_OBJECTIVE',
        'evidences.ASSOCIATE_TO_OBJECTIVE_INFO',
        'evidences.CONTEST',
        'evidences.CONTEST_INFO',
        'evidences.OBSERVATIONS',
        'evidences.OBSERVATIONS_INFO',
      ])
      .subscribe(translations => {
        this.objectiveToAssociateTooltipModal = {
          modalId: "d1005",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.ASSOCIATE_TO_OBJECTIVE'],
          text: translations['evidences.ASSOCIATE_TO_OBJECTIVE_INFO']
        };
        this.contestTooltipModal = {
          modalId: "d1006",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.CONTEST'],
          text: translations['evidences.CONTEST_INFO']
        };
        this.osservationsTooltipModal = {
          modalId: "d1007",
          title: translations['generic.INFO'],
          subtitle: translations['evidences.OBSERVATIONS'],
          text: translations['evidences.OBSERVATIONS_INFO']
        };
        this.formatModalCreateUpdateEvidence();
      })
  }

  // Recupera gli obiettivi da associare all'evidenza
  getGoalsToAssociate() {
    this.isLoadingGoalsToAssociate = true;
    if (this.getGoalsToAssociateToEvidence$) {
      this.getGoalsToAssociateToEvidence$.unsubscribe();
    }
    this.getGoalsToAssociateToEvidence$ = this.peopleAppraisalService.getGoalsToAssociateWithEvidence(this.userId)
      .subscribe((data: SenecaResponse<ListValidGoalsToAssociateForEvidenceFeedbackForManagerResponse[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response && data.response.length) {
          this.objectiveOptions = data.response;

          if (this.goalId && this.goalId.length && !this.evidenceId) {
            let indexOfAssociatedObjective = 0;
            for (let i = 0; i < this.objectiveOptions.length; i++) {
              if (this.goalId == this.objectiveOptions[i].goalId) {
                indexOfAssociatedObjective = i;
              }
            }
            this.objectiveAssociated = this.objectiveOptions[indexOfAssociatedObjective];

            this.objectiveAssociatedCopy = JSON.parse(JSON.stringify(this.objectiveAssociated));
          }

          if (this.personDetails && this.personDetails.userId && this.evidenceId) {
            this.getEvidenceById();
          }
        } else {
          this.objectiveOptions.length = 0;
        }
        this.isLoadingGoalsToAssociate = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingGoalsToAssociate = false;
      });
  }

  // Chekka se i dati sono cambiati
  checkIfDataIsChanged() {
    return ((this.objectiveAssociated && this.objectiveAssociated.goalId) != (this.objectiveAssociatedCopy && this.objectiveAssociatedCopy.goalId)) || this.evidence.comment != this.evidenceCopy.comment
      || this.evidence.observations != this.evidenceCopy.observations;
  }

  // Formatta i testi della modale per la creazione/aggiornamento dell'evidenza
  formatModalCreateUpdateEvidence(isBack?: boolean) {
    this.modalCreateUpdateEvidenceId = this.getModalId(isBack);
    this.modalCreateUpdateEvidenceTitle = this.getModalSaveTitle(isBack);
    this.modalCreateUpdateEvidenceSubtitle = this.getModalSaveSubtitle();
    this.modalCreateUpdateEvidenceText = this.getModalSaveDescription(isBack);
    this.modalCreateUpdateEvidenceTitleConfirmTextButton = this.getConfirmLabelCreateUpdateEvidenceModal(isBack);
    this.modalCreateUpdateEvidenceTextCloseTextButton = this.getCancelLabelCreateUpdateEvidenceModal();
  }

  // Recupera il modalId per la modale di crea/aggiorna evidenza
  getModalId(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return 'd1008';
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return 'd1009';
        } else {
          return 'd1010';
        }
      } else {
        if (this.evidenceId) {
          return 'd1011';
        } else {
          return 'd1012';
        }
      }
    }
  }

  // Recupera un booleano per sapere se tutti i dati obbligatori sono stati aggiunti
  isObbligatoryDataInserted() {
    return true;
  }

  // Testo per il modale crea/aggiorna evidenza
  getModalSaveTitle(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED');
    } else {
      if (isBack) {
        return this.translate.instant('evidences.GO_BACK');
      } else {
        if (this.evidenceId) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE');
        }
      }
    }
  }

  // Sottotitolo per il modale crea/aggiorna evidenza
  getModalSaveSubtitle() {
    if (!this.evidenceId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.YOU_ARE_INSERTING_AN_EVIDENCE');
    } else if (this.evidenceId && this.isObbligatoryDataInserted()) {
      return this.translate.instant('evidences.YOU_ARE_UPDATING_AN_EVIDENCE');
    } else {
      return '';
    }
  }

  // Descrizione per il modale crea/aggiorna evidenza
  getModalSaveDescription(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      if (this.evidenceId && this.evidenceId.length) {
        return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION_UPDATE');
      } else {
        return this.translate.instant('evidences.OBBLIGATORY_DATA_NOT_INSERTED_DESCRIPTION');
      }
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.YOU_ARE_UPDATING') + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_UPDATE_THIS_EVIDENCE');
        } else {
          return this.translate.instant('evidences.YOU_ARE_INSERTING_TO') + this.personDetails.forename + ' ' + this.personDetails.surname + ' ' + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' + this.translate.instant('evidences.EVIDENCE_RULES_IN_MODAL') + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_INSERT_THIS_EVIDENCE');
        }
      } else {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.YOU_ARE_UPDATING') + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_UPDATE_THIS_EVIDENCE');
        } else {
          return this.translate.instant('evidences.YOU_ARE_INSERTING_TO') + this.personDetails.forename + ' ' + this.personDetails.surname + ' ' + this.translate.instant('evidences.THIS_EVIDENCE') + '<br><br>' +
            this.translate.instant('evidences.CONTEST_IN_MODAL') + this.evidence.comment + '<br><br>' + this.translate.instant('evidences.OSSERVATION_IN_MODAL') + this.evidence.observations + '<br><br>'
            + this.translate.instant('evidences.REFEREMENT_OBJECTIVES_IN_MODAL') + this.objectiveAssociated.title + '<br><br>' + this.translate.instant('evidences.EVIDENCE_RULES_IN_MODAL') + '<br><br>' +
            this.translate.instant('evidences.YOU_WANT_INSERT_THIS_EVIDENCE');
        }
      }
    }
  }

  // Testo modale pulsante close
  getCancelLabelCreateUpdateEvidenceModal() {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('goalSetting.createGoal.GO_BACK_YES');
    } else {
      return this.translate.instant('generic.ANNULL');
    }
  }

  // Testo modale pulsante crea/aggiorna
  getConfirmLabelCreateUpdateEvidenceModal(isBack?: boolean) {
    if (!this.isObbligatoryDataInserted()) {
      return this.translate.instant('generic.NO_REMAIN_IN_PAGE');
    } else {
      if (isBack) {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE_BACK');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE_BACK');
        }
      } else {
        if (this.evidenceId && this.evidenceId.length) {
          return this.translate.instant('evidences.UPDATE_EVIDENCE');
        } else {
          return this.translate.instant('evidences.INSERT_EVIDENCE');
        }
      }
    }
  }

  // Recupara l'utente dal suo userId
  getPersonDetails() {
    // this.getPersonDetails$ = this.sharedService.getMySubordinateUserData(this.userId, this.runningYear)
    //   .subscribe((personDetailsData: any ) => {
    //     if (personDetailsData.error || !personDetailsData.response) {
    //       const messageObj: ApplicationModalMessage = {
    //         modalId: "d009",
    //         text: this.translate.instant("errors." + (personDetailsData.error || 'SUBORDINATE_NOT_FOUND')),
    //         title: this.translate.instant("generic.WARNING")
    //       }
    //       this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //     } else {
    //       this.personDetails = personDetailsData.response;
    //       this.getGoalsToAssociate();
    //     }
    //   }, (err?: any) => {
    //     const messageObj: ApplicationModalMessage = {
    //       modalId: "d010",
    //       text: this.translate.instant("errors." + ((err && err.message) || err)),
    //       title: this.translate.instant("generic.WARNING")
    //     }
    //     this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    //   });
  }

  // Recupera l'evidenza dal evidenceId
  getEvidenceById() {
    this.isFetchingGetEvidence = true;

    this.getEvidenceById$ = this.peopleAppraisalService.getEvidenceFeedback(this.userId, this.runningYear.year.toString(), this.evidenceId)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "d011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data && data.response) {
          this.evidence = data.response;

          this.evidenceCopy = JSON.parse(JSON.stringify(this.evidence));

          let indexOfAssociatedObjective = 0;
          for (let i = 0; i < this.objectiveOptions.length; i++) {
            if (this.evidence.goalId == this.objectiveOptions[i].goalId) {
              indexOfAssociatedObjective = i;
            }
          }
          this.objectiveAssociated = this.objectiveOptions[indexOfAssociatedObjective];

          this.objectiveAssociatedCopy = JSON.parse(JSON.stringify(this.objectiveAssociated));
        }
        this.isFetchingGetEvidence = false;
      },
        (err: string) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "d012",
            text: this.translate.instant("errors." + err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isFetchingGetEvidence = false;
        })
  }

  // Gestisce quando crei/aggiorni un'evidenza
  goToUpdateEvidence(isBack?: boolean) {
    if (!this.checkIfDataIsChanged() && isBack) {
      this.goBackBrowser();
    } else {
      this.formatModalCreateUpdateEvidence(isBack);
      this.openModalForCreateUpdateEvidence();
    }
  }

  updateDevelopAction() {
    this.closeModalCreateUpdateEvidence();
  }

  // Crea evidenza
  createEvidence() {
    this.closeModalCreateUpdateEvidence();
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateEvidence();
    } else {
      this.isFetchingCreateEvidence = true;
      this.closeModalCreateUpdateEvidence();

      let evidence: evidenceObjectForCreate = {
        goalId: this.objectiveAssociated.goalId,
        comment: this.evidence.comment,
        observations: this.evidence.observations,
        recipientUserId: this.personDetails.userId
      }

      this.createEvidence$ = this.peopleAppraisalService.createEvidenceFeedback(evidence)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d013",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d014",
              title: this.translate.instant("evidences.EVIDENCE_CREATED"),
              text: this.translate.instant("evidences.EVIDENCE_CREATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          }
          if (data.obtainedBadges && data.obtainedBadges.length) {
            let badges: any[] = [];
            if (data.obtainedBadges.length > 1) {
              badges.push(data.obtainedBadges[data.obtainedBadges.length - 1])
            } else {
              badges = data.obtainedBadges;
            }

            let tmpBadgesList: BadgeObject[] = [];
            if (badges && badges.length) {
              tmpBadgesList = BadgeUtils.formatBadges(badges, this.translate);
              this.store.dispatch(CoreActions.SetApplicationModalBadge({ payload: tmpBadgesList }));
            }
          }
          this.isFetchingCreateEvidence = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d015",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingCreateEvidence = false;
          })
    }
  }

  // Aggiorna evidenza
  updateEvidence() {
    if (!this.isObbligatoryDataInserted()) {
      this.closeModalCreateUpdateEvidence();
    } else {
      this.isFetchingUpdateEvidence = true;
      this.closeModalCreateUpdateEvidence();

      let evidenceToUpdate = {
        feedbackId: this.evidence.feedbackId,
        comment: this.evidence.comment,
        observations: this.evidence.observations,
        goalId: this.objectiveAssociated && this.objectiveAssociated.goalId,
        recipientUserId: this.personDetails.userId
      }


      this.updateEvidence$ = this.peopleAppraisalService.updateEvidenceFeedback(evidenceToUpdate)
        .subscribe((data: SenecaResponse<UpdateEvidenceFeedbackForManagerResponse>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "d016",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else if (data && data.response) {
            const messageObj = {
              modalId: "d017",
              title: this.translate.instant("evidences.EVIDENCE_UPDATED"),
              text: this.translate.instant("evidences.EVIDENCE_UPDATED_DESCRIPTION")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.goBackBrowser();
          }
          this.isFetchingUpdateEvidence = false;
        },
          (err: string) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "d018",
              text: this.translate.instant("errors." + err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isFetchingUpdateEvidence = false;
          })
    }
  }

  // Apre la modale per la creazione/aggiornamento dell'evidenza
  openModalForCreateUpdateEvidence() {
    this.modalService.open('modalCreateUpdateEvidenceForPeopleAppraisal');
  }

  // Vai indietro back browser
  goBackBrowser() {
    this.redirectService.goBackBrowser();
  }

  // Chiude la modale per la creazione/aggiornamento dell'evidenza
  closeModalCreateUpdateEvidence() {
    this.modalService.close('modalCreateUpdateEvidenceForPeopleAppraisal');
  }

  changeAssociatedObjective(objective: any) {
    this.objectiveAssociated = objective;
  }

  // Il contesto è cambiato
  evidenceContestChanged(text: string) {
    this.evidence.comment = text;
  }

  // La osservazione è cambiata
  osservationChanged(text: string) {
    this.evidence.observations = text;
  }

  ngOnDestroy() {
    if (this.getPersonDetails$) {
      this.getPersonDetails$.unsubscribe();
    }
    if (this.subordinatesWithPhaseStatuses$) {
      this.subordinatesWithPhaseStatuses$.unsubscribe();
    }
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
    if (this.getEvidenceById$) {
      this.getEvidenceById$.unsubscribe();
    }
    if (this.createEvidence$) {
      this.createEvidence$.unsubscribe();
    }
    if (this.updateEvidence$) {
      this.updateEvidence$.unsubscribe();
    }
  }
}