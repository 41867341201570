<div class="banner-home" [ngClass]="{'narrow': isNarrow, 'final-evaluation': phaseName == 'FINAL_EVALUATION' }">
  <!--Banner maschera immagine-->
  <div class="img-banner-mask" [ngStyle]="{'background-image': 'url(' + backgroundSrc + ')'}"></div>
  <!--Banner testo banner-->
  <div id="bigBannerTag" class="text-banner-mask orange-bg">

    <!--Decorazione Banner-->
    <svg fxHide.xs class="decoration-img" preserveAspectRatio="none" width="33px" height="100%" viewBox="0 0 33 400"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 274.643 12.1388 343.713 32.7478 400V0C12.1388 56.2869 0 125.357 0 200Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M0 200C0 125.357 12.1388 56.2869 32.7478 0V400C12.1388 343.713 0 274.643 0 200Z" />
    </svg>


    <!-- Contenuto "default" del banner -->
    <ng-container *ngIf="phaseClosed">
      <div class="text-banner">
        <div class="banner-header">
          <!-- Data odierna -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="alloy-logo"></svg-icon>
            <div class="footer-container">
              <p class="footer-title" translate="banner.WELCOME_TO_YOUR_AREA"></p></div>
          </div>
        </div>
        <!-- Testo Banner -->
        <h2 class="title-page full-width">{{ ('banner.CLOSED' | translate)}}</h2>
      </div>
    </ng-container>


    <ng-container *ngIf="!phaseClosed">
      <div class="text-banner">
        <div class="banner-header">
          <!-- Data e colloqui -->
          <div class="banner-header-text">
            <h1 fxShow fxHide.xs>{{ today }}</h1>
            <h1 fxShow.xs fxHide>{{ mobileToday }}</h1>
          </div>
          <div class="alloy-logo-wrapper">
            <svg-icon src="/assets/img/icons/app-logo-white.svg" class="alloy-logo"></svg-icon>
          </div>
        </div>
        <!-- Testo Banner -->
        <h2 class="title-page full-width" *ngIf="!isAdminBanner"
          [translate]="('bannerNew.WELCOME') | translate"></h2>
        <div class="admin-container" *ngIf="isAdminBanner">
          <h2 class="title-page full-width" [translate]="'bannerNew.ADMIN_TITLE' | translate">
          </h2>
        </div>
        <!-- Contenuto banner -->
        <!-- <div class="banner-content" *ngIf="!isAdminBanner"> -->
          <!-- <h3 class="text-small"
            [translate]="(!isSelfAssessment ? 'bannerNew.PEOPLE_DESCR' : 'bannerNew.SELF_DESCR') | translate"></h3> -->


          <!-- <div class="support-help-container" *ngIf="pasData?.perfYear || hasHighlight">
            <p class="text-title" translate="bannerNew.HIGH"></p>
            <div class="divisor"></div> -->

            <!-- <div class="items-container"> -->
              <!-- PAS -->
              <!-- <ng-container *ngIf="pasData && pasData.perfYear && pasData.perfYearCode">
                <div class="row-container" [ngClass]="{'last' : pasData?.perfYear && !hasHighlight}"> -->
                  <!-- self assessment -->
                  <!-- <div class="link-row">
                    <div class="left">
                      <svg-icon src="/assets/img/icons/contact.svg"
                        class="new-icon small-size black-primary margin-right4" [applyClass]="true"></svg-icon>
                      <p class="text-description">{{ ('bannerNew.PAS' | translate) + pasData.perfYearCode + ' '+
                        pasData.perfYear}}</p>
                    </div>
                    <div class="center">
                      <p *ngIf="pasData.isNewPas" class="new-badge" translate="bannerNew.NEWS"></p>
                      <p *ngIf="pasData.endDate && !pasData.isNewPas" class="date">{{('bannerNew.END_DATE' | translate) + pasData.endDate}}
                      </p>
                    </div>
                    <div class="right">
                      <p class="link" translate="adminTable.ACCESS" (click)="pasClicked()"></p>
                    </div>
                  </div>
                </div> -->
              <!-- </ng-container> -->
              <!-- Self  -->
              <!-- <div class="row-container last" *ngIf="hasHighlight"> -->
                <!-- Link self assessment -->
                <!-- <div class="link-row" *ngIf="isSelfAssessment">
                  <div class="left">
                    <svg-icon src="/assets/img/icons/message-square.svg"
                      class="new-icon small-size black-primary margin-right4" [applyClass]="true"></svg-icon>
                    <p class="text-description" translate="upwardFeedback.TITLE"></p>
                  </div>
                  <div class="center">
                    <p *ngIf="isNew" class="new-badge" translate="bannerNew.NEWS"></p>
                  </div>
                  <div class="right">
                    <p class="link" translate="adminTable.ACCESS" (click)="onSelfAssessmentClicked()"></p>
                  </div>
                </div>

                <ng-container *ngIf="!isSelfAssessment && (self || team)"> -->
                  <!-- UpwardFeeback per te -->
                  <!-- <div class="link-row" *ngIf="self">
                    <div class="left">
                      <svg-icon src="/assets/img/icons/message-square.svg"
                        class="new-icon small-size black-primary margin-right4" [applyClass]="true"></svg-icon>
                      <p class="text-description" translate="bannerNew.UPF_FOR_YOU"></p>
                    </div>
                    <div class="center">
                      <p *ngIf="selfIsNew" class="new-badge" translate="bannerNew.NEWS"></p>
                    </div>
                    <div class="right">
                      <p class="link" translate="adminTable.ACCESS" (click)="onSelfClicked.emit()"></p>
                    </div>
                  </div>

                  <div class="divisor-black" *ngIf="self && team"></div> -->
                  <!-- Upward Feedback per i tuoi -->
                  <!-- <div class="link-row" *ngIf="team">
                    <div class="left">
                      <svg-icon src="/assets/img/icons/message-square.svg"
                        class="new-icon small-size black-primary margin-right4" [applyClass]="true"></svg-icon>
                      <p class="text-description" translate="bannerNew.UPF_FOR_YOURS"></p>
                    </div>
                    <div class="center">
                      <p *ngIf="secondLevelIsNew" class="new-badge" translate="bannerNew.NEWS"></p>
                    </div>
                    <div class="right">
                      <p class="link" translate="adminTable.ACCESS" (click)="onSecondLevelClicked.emit()"></p>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </ng-container>
  </div>
</div>