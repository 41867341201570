import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'card-notes',
  templateUrl: 'card-notes.component.html',
  styleUrls: ['./card-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardNotesComponent implements OnInit {
  @Input() note: any;
  @Input() loggedUser?: any;
  @Input() isHistory: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  constructor(public translate: TranslateService) {
  }

  ngOnInit() {
  }

  emitOnEditNote(id: string) {
    this.onEdit.emit(id);
  }

  emitOnDeleteNote(id: string) {
    this.onDelete.emit(id)
  }
}