import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { PerformanceAssessmentAlloyStatuses } from 'atfcore-commonclasses';

@Component({
  selector: 'app-admin-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss']
})
export class MonitoringComponent implements OnDestroy {
  isLoadingProcessData: boolean = false;
  getProcessesData$: Subscription = new Subscription;
  processData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  selectedRows: { id: number, title: number };
  translations: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Lista dei processi");

    this.selectedRows = this.rowPerPageOptions[0];
  }

  ngOnInit() {
    this.translate.get([
      'calibration.menu.ACCESS',
      'calibration.menu.SHOW',
      'process.status.STANDBY',
      'process.status.ACTIVE',
      'process.status.CLOSED',
    ]).subscribe((translations) => {
      this.translations = translations;
      this.getProcessesData();
    })
  }


  getProcessesData() {
    this.isLoadingProcessData = true;
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
    this.getProcessesData$ = this.adminService.countPerformanceProcesses()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.processData.counter = counter.response;

              // Calcolo la paginazione
              this.processData.fromRecord = (this.processData.page - 1) * this.processData.numRecords;

              if (this.processData.counter) {
                return this.adminService.listPerformanceProcesses(this.processData.fromRecord, this.processData.numRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingProcessData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            for (let i = 0; i < data.response.length; i++) {
              let process = data.response[i];
              process.isMenuOpen = false;

              process.menuOptions = [{ id: 'access', title: this.translations['calibration.menu.ACCESS'] }];
              if (process.status == PerformanceAssessmentAlloyStatuses.STANDBY) {
                process.statusType = 'inProgress';
                process.statusMessage = this.translations['process.status.STANDBY'];
              } else if (process.status == PerformanceAssessmentAlloyStatuses.RUNNING) {
                process.statusType = 'completed';
                process.statusMessage = this.translations['process.status.ACTIVE'];
              } else {
                process.statusType = 'disabled';
                process.statusMessage = this.translations['process.status.CLOSED'];
              }
              /* if (i % 3 == 0) {
                 process.status = 'active';
                 process.menuOptions = [{ id: 'access', title: this.translations['calibration.menu.ACCESS'] }]
               } else if (i % 3 == 1) {
                 process.status = 'stand-by';
                 process.menuOptions = [{ id: 'show', title: this.translations['calibration.menu.SHOW'] }]
               } else if (i % 3 == 1) {
                 process.status = 'disabled';
                 process.menuOptions = null;
               } */
            }
            this.processData.list = data.response || [];
          }
          this.isLoadingProcessData = false;
        }
        , (err: any) => {
          this.isLoadingProcessData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  processPageChanged(page: number) {
    this.processData.page = page;
    this.processData.list = [];
    this.getProcessesData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.processData.numRecords = item.id;
    this.processData.list = [];
    this.processData.fromRecord = 0;
    this.getProcessesData();
  }

  getStatus(phase: any) {
    if (phase && phase.status) {
      switch (phase.status) {
        case 'TO_START': {
          return {
            status: 'toStart',
            statusMessage: this.translate.instant('itemListTeamStatus.toStart')
          }
        }
        case 'STARTED': {
          return {
            status: 'inProgress',
            statusMessage: this.translate.instant('itemListTeamStatus.inProgress') + ' ' + (phase.percentage.toFixed(1)) + '%'
          }
        }
        case 'COMPLETED': {
          return {
            status: 'completed',
            statusMessage: this.translate.instant('itemListTeamStatus.completed')
          }
        }
        default:
          return {
            status: 'disabled',
            statusMessage: this.translate.instant('itemListTeamStatus.disabled')
          }
      }
    } else {
      return {
        status: 'disabled',
        statusMessage: this.translate.instant('itemListTeamStatus.disabled')
      }
    }

  }

  // Apre il menù con le opzioni per il processo
  openOptionsMenu(process: any) {
    process.isMenuOpen = !process.isMenuOpen;
  }

  goToProcessDetails(process: any) {
    this.router.navigate(['admin/monitoringUserList/', process.processYear, process.processYearCode]);
  }

  // onMenuOptionsClicked(options: any, process: any) {
  //   this.router.navigate(['admin/calibrationUserList/', process.processYear, process.processYearCode]);
  //   // if (options.id == 'access') {
  //   // } else if (options.id == 'edit') {
  //   //   this.router.navigate(['admin/createUpdateProcess/' + process.year])
  //   // } else {
  //   //   // this.processToDelete = process;
  //   //   // this.modalService.open('deleteProcess');
  //   // }
  // }

  ngOnDestroy() {
    if (this.getProcessesData$) {
      this.getProcessesData$.unsubscribe();
    }
  }
}
