import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { DateTimeUtils, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as CoreActions from "../../core/ngrx/core.actions";
import { UrlService } from 'src/app/shared/services/url.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SidebarService } from 'src/app/shared/services/sidebar.service';
import * as moment from 'moment';
import { parseDate } from 'devextreme/localization';
import { DateTimeAdapter } from '@busacca/ng-pick-datetime';

@Component({
  selector: 'admin-process-details',
  templateUrl: './processDetails.component.html',
  styleUrls: ['./processDetails.component.scss']
})
export class ProcessDetailsComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];

  processYear: number = 0;
  processYearCode: string = '';

  usersFromRecord: number = 0;
  usersNumRecords: number = 5;
  usersCurrentPage: number = 0;
  usersCounter: number = 0;
  isLoadingUsers: boolean = false;
  selectedUserList: any[] = []
  searchedText: string = '';

  getCountersData$: Subscription = new Subscription();
  isLoadingCounters: boolean = false;
  counterData: any;

  usersList: any[] = [];
  translations: any;
  allChecked: boolean = false;
  selectedFilters: any = {};

  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  isLoadingTranslations: boolean = false;
  runningPhase: any;
  selectedFiltersNumber: number = 0;
  impersonateUserForAdmin$: any;
  loggedUser: any;

  tabList: { id: string; name: any; }[] = [];
  currentContentTab: any
  importExcludeFile: any;
  resetIncludeUpload: boolean = false;
  sidebarData: any;
  syncModalData: any;
  massiveModalData: any;
  singleModalData: any;
  modalSearchedText: string = '';
  isLoadingModalData: boolean = false;
  modalData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      counter: 0,
      list: []
    }


  // tab fasi
  isLoadingPhasesData: boolean = false;
  people: any;
  self: any;
  phasesMenuOption: { id: string; title: any; icon: string; }[] = [];
  phases: any;
  processId: string = '';
  getSidebarData$: Subscription = new Subscription;
  isLoadingSidebarData: boolean = false;
  getModalData$: Subscription = new Subscription;
  snackbarText: string = '';
  importIncludeFile: any;
  includeImportDescr: any;
  excludeImportDescr: any;
  isLoadingImport: boolean = false;
  blockInclude: boolean = false;
  blockExclude: boolean = false;
  isDownloadingList: boolean = false;
  downloadCurrentList$: Subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private modalService: ModalService,
    private urlService: UrlService,
    private analyticsService: AnalyticsService,
    private sidebarService: SidebarService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Lista degli utenti");

    this.selectedRows = this.rowPerPageOptions[2];
    this.usersNumRecords = this.selectedRows.id;

    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$])
      .subscribe(
        ([loggedUser]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }

          this.route.params
            .subscribe(
              (params: Params) => {
                this.processId = params.processId;
                this.processYear = params.processYear;
                this.processYearCode = params.processYearCode;
              })
        });
  }

  ngOnInit() {
    sessionStorage.setItem('peopleAppraisal', '');
    sessionStorage.setItem('userIdList', '');
    this.isLoadingTranslations = true;

    this.translate.get([
      'process.anag.TITLE',
      'process.phases.TITLE',
      'process.anag.actions.INCLUDE',
      'process.anag.actions.EXCLUDE',
      'process.anag.actions.EDIT',
      'process.anag.actions.ANAG',
      'process.anag.import.INCLUDE_TITLE',
      'process.anag.import.INCLUDE_TEXT',
      'process.anag.import.EXCLUDE_TITLE',
      'process.anag.import.EXCLUDE_TEXT',
      'process.anag.massive.INCLUDE_TITLE',
      'process.anag.massive.INCLUDE_TEXT',
      'process.anag.massive.EXCLUDE_TITLE',
      'process.anag.massive.EXCLUDE_TEXT',
      'process.anag.single.INCLUDE_TITLE',
      'process.anag.single.INCLUDE_TEXT',
      'process.anag.single.EXCLUDE_TITLE',
      'process.anag.single.EXCLUDE_TEXT',
      'process.phases.actions.EDIT',
      'process.anag.single.INCLUDE_SUCCESSFUL',
      'process.anag.single.EXCLUDE_SUCCESSFUL',
      'process.anag.massive.INCLUDE_SUCCESSFUL',
      'process.anag.massive.EXCLUDE_SUCCESSFUL',
      'process.anag.actions.CHOOSE_EVALUATOR_SUCCESS',
      'process.anag.CONFIRM_IMPORT_1',
      'process.anag.CONFIRM_IMPORT_2',
      'process.anag.EXCLUDE_CONFIRM_IMPORT_1',
      'process.anag.EXCLUDE_CONFIRM_IMPORT_2',
      'process.anag.NO_USER_FOUND',
    ]).subscribe((translations) => {
      this.translations = translations;

      this.menuOptions = [
        // {
        //   id: 'include',
        //   title: translations['process.anag.actions.INCLUDE'],
        //   icon: '/assets/img/icons/user-plus.svg',
        // },
        // {
        //   id: 'exclude',
        //   title: translations['process.anag.actions.EXCLUDE'],
        //   icon: '/assets/img/icons/user-minus.svg',
        // },
        {
          id: 'edit',
          title: translations['process.anag.actions.EDIT'],
          icon: '/assets/img/icons/wand.svg',
        },
        {
          id: 'anag',
          title: translations['process.anag.actions.ANAG'],
          icon: '/assets/img/icons/contact.svg',
        },
      ]

      this.phasesMenuOption = [
        {
          id: 'date-edit',
          title: translations['process.phases.actions.EDIT'],
          icon: '/assets/img/icons/edit.svg',
        },
      ]

      this.tabList = [
        {
          id: 'anag',
          name: translations['process.anag.TITLE']
        },
        {
          id: 'phases',
          name: translations['process.phases.TITLE']
        }
      ]

      if (window.location.href.includes('/phases')) {
        this.currentContentTab = this.tabList[1];
        this.getPhasesData();
      } else {
        this.currentContentTab = this.tabList[0];
        this.getCountersData();
      }

      this.isLoadingTranslations = false;
    })
  }

  onContentTabChanged(selected: any) {
    this.currentContentTab = this.tabList.find((tab: any) => tab.id == selected);
    let url = 'admin/processDetails/' + this.processId + '/' + this.processYear + '/' + this.processYearCode;
    if (this.currentContentTab.id == 'anag') {
      url += '/anagraphics';
    } else if (this.currentContentTab.id == 'phases') {
      url += '/phases';
    }
    this.router.navigate([url])
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  // menu utente
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = !this.isMenuOptionValid(this.menuOptions[i], user)
    }
    user.isMenuOpen = !user.isMenuOpen
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any, isConfirm?: boolean) {
    user.isMenuOpen = false;
    if (!options.disabled) {
      if (options.id == 'include') {
        this.singleModalData = {};
        this.singleModalData.isInclude = true;
        this.singleModalData.title = this.translations['process.anag.single.INCLUDE_TITLE'];
        this.singleModalData.text = this.translations['process.anag.single.INCLUDE_TEXT'];
        this.singleModalData.user = user;
        this.modalService.open('modal-single');
      } else if (options.id == 'exclude') {
        this.singleModalData = {};
        this.singleModalData.isInclude = false;
        this.singleModalData.title = this.translations['process.anag.single.EXCLUDE_TITLE'];
        this.singleModalData.text = this.translations['process.anag.single.EXCLUDE_TEXT'];
        this.singleModalData.user = user;
        this.modalService.open('modal-single');
      } else if (options.id == 'edit') {
        // TODO 
      } else if (options.id == 'anag') {
        this.isLoadingSidebarData = true;
        this.sidebarData = {};
        this.sidebarData.userId = user.userId;
        this.sidebarData.forename = user.forename;
        this.sidebarData.surname = user.surname;
        this.getSidebarData();
        this.toggleSidebar();
      }
    }
  }


  closeSingleModal(confirm?: boolean) {
    this.modalService.close('modal-single')
    if (confirm) {
      this.isLoadingUsers = true;
      if (this.singleModalData.isInclude) {
        this.adminService.includeUserInProcess(this.processYear, this.processYearCode, [this.singleModalData.user.userId])
          .subscribe((response: any) => {
            let data = response.body;
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.snackbarText = this.translations['process.anag.single.INCLUDE_SUCCESS'];
              // toaster 
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
            this.getCountersData();
            this.getUsersList();
          }, (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingUsers = false;
            }
          });
      } else {
        this.adminService.excludeUserFromProcess(this.processYear, this.processYearCode, [this.singleModalData.user.userId])
          .subscribe((response: any) => {
            let data = response.body;
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.snackbarText = this.translations['process.anag.single.EXCLUDE_SUCCESS'];
              // toaster 
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
            this.getCountersData();
            this.getUsersList();
          }, (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingUsers = false;
            }
          });
      }
    }
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    if (this.selectedUserList && this.selectedUserList.length) {
      this.selectedUserList.length = 0;
    }
    if (this.usersList && this.usersList.length > 0) {
      for (let i = 0; i < this.usersList.length; i++) {
        this.usersList[i].isChecked = this.allChecked;
        if (this.allChecked) {
          this.addRemoveUser(this.usersList[i]);
        }
      }
    }
  }

  // ricerca
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.resetUserData();
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.usersList = [];
  }

  // Recupera una lista di utenti
  getUsersList(fromSearch?: any) {
    if (fromSearch) {
      this.resetUserData();
    }
    sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    // Avvio il loader
    if (this.searchedText && this.searchedText.length) {
      this.selectedFilters.searchedText = this.searchedText;
    }
    let filtersForService = this.formatFilters();

    this.isLoadingUsers = true;
    this.adminService.countAnagUser(this.processYear, this.processYearCode, filtersForService) //this.searchedText,
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.usersCounter) {
                return this.adminService.listAnagUser(this.processYear, this.processYearCode, fromRecord, this.usersNumRecords, filtersForService);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let user = data.response[i];
              user.dataAssunzione = user.dataAssunzione ? moment(user.dataAssunzione.toString().split('T')[0]).format('DD/MM/YYYY') : '--';
              user.codFisc = user.codFisc ? user.codFisc : '--';
              let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);
              if (selected) {
                user.isChecked = true;
              }
            }
            this.usersList = data.response;
          }
          this.isLoadingUsers = false;
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista 
  usersPageChanged(newPage: any) {
    this.usersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsersList(false);
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }
  }


  // Conversione stato valutazione finale utente
  getFinalEvaluationStatus(status: string) {
    switch (status) {
      case 'TO_COMPLETE':
        return {
          label: this.translations['calibration.statuses.TO_COMPLETE'],
          status: 'toStart'
        };
      case 'COMPLETED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  // Conversione stato calibration utente
  getCalibrationStatus(status: string) {
    switch (status) {
      case 'CALIBRATION_TODO':
        return {
          label: this.translations['calibration.statuses.TO_START'],
          status: 'toStart'
        };
      case 'CALIBRATION_TO_CONFIRM':
        return {
          label: this.translations['calibration.statuses.TO_CONFIRM'],
          status: 'inProgress'
        };
      case 'CALIBRATION_CONFIRMED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  // ritorna i dati dei contatori relativi agli stati degli utenti nel processo
  getCountersData() {
    this.isLoadingCounters = true;

    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }

    this.getCountersData$ = this.adminService.getAnagCountersData(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<number>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "cal007",
            title: this.translate.instant("generic.WARNING"),
            text: this.translate.instant("errors." + data.error)
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.counterData = data.response;
        }
        this.isLoadingCounters = false;

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "c008",
          title: this.translate.instant("generic.WARNING"),
          text: this.translate.instant("errors." + ((err && err.message) || err))
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingCounters = false;
      });
  }

  isMenuOptionValid(option: any, user: any) {
    if (this.loggedUser.userId == user.userId) {
      return false;
    } else if (option.id == 'include') {
      if (user && user.isInProcess) {
        return false
      }
      return true;
    } else if (option.id == 'exclude') {
      if (user && !user.isInProcess) {
        return false
      }
      return true;
    } else if (option.id == 'edit') { // TODO aggiornare con condizioni di modifica scheda, parte in sviluppo successivamente
      return false;
    } else if (option.id == 'anag') {
      return true;
    }
    return false;
  }

  canGoToMatrix() {
    for (let i = 0; i < this.selectedUserList.length; i++) {
      if (this.selectedUserList[i].postCalibrationStatus == "WAITING") {
        return false
      }
    }
    return true;
  }

  canConfrimUserList() {
    for (let i = 0; i < this.selectedUserList.length; i++) {
      if (this.selectedUserList[i].postCalibrationStatus == "WAITING" || this.selectedUserList[i].postCalibrationStatus == "SENT") {
        return false
      }
    }
    return true;
  }


  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadProcessParticipantsReportForAdmin(this.processYear, this.processYearCode, this.selectedFiltersNumber == 0, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  goToEditProcess() {
    this.router.navigate(['admin/createUpdateProcess/', this.processId])
  }

  downloadCurrentList() {
    this.isDownloadingList = true;
    if (this.downloadCurrentList$) {
      this.downloadCurrentList$.unsubscribe();
    }
    this.downloadCurrentList$ = this.adminService.downloadAllUsersForAdmin(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingList = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingList = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingList = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingList = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingList = false;
      })
  }

  openIncludeModalImport() {
    this.blockInclude = true;
    this.modalService.open('import-modal-include')
  }

  closeModalImportInclude(confirm?: boolean) {
    this.modalService.close('import-modal-include');
    if (confirm) {
      this.adminService.importIncludeUserInProcess(this.processYear, this.processYearCode, this.importIncludeFile, false)
        .subscribe((response: any) => {
          let data = response.body;
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.snackbarText = this.translations['process.anag.massive.INCLUDE_SUCCESS'];
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.getCountersData();
          this.getUsersList();
        }, (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingUsers = false;
          }
        });
    }
  }


  openExcludeModalImport(confirm?: boolean) {
    this.blockExclude = true;
    this.modalService.open('import-modal-exclude');
  }

  closeExcludeModalImport(confirm?: boolean) {
    this.modalService.close('import-modal-exclude');
    if (confirm) {
      this.adminService.importExcludeUserFromProcess(this.processYear, this.processYearCode, this.importExcludeFile, false)
        .subscribe((response: any) => {
          let data = response.body;
          if (data && data.error || data && !data.response) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            this.snackbarText = this.translations['process.anag.massive.EXCLUDE_SUCCESS'];
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.isLoadingImport = false;
        }, (err: any) => {
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingImport = false;
          }
        });
    }
  }

  assignExcludeFile(file: any) {
    this.importExcludeFile = file;
    this.isLoadingImport = true;
    this.adminService.importExcludeUserFromProcess(this.processYear, this.processYearCode, this.importExcludeFile, true)
      .subscribe((response: any) => {
        let data = response.body;
        if (data && data.error || data && !data.response) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data && data.response && data.response.length) {
            this.excludeImportDescr = this.translations['process.anag.EXCLUDE_CONFIRM_IMPORT_1'] + data.response.length + this.translations['process.anag.EXCLUDE_CONFIRM_IMPORT_2'];
            this.blockExclude = false;
          } else {
            this.excludeImportDescr = this.translations['process.anag.NO_USER_FOUND']

          }
        }
        this.isLoadingImport = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        }
      });
  }

  assignIncludeFile(file: any) {
    this.isLoadingImport = true;
    this.importIncludeFile = file;
    this.adminService.importIncludeUserInProcess(this.processYear, this.processYearCode, this.importIncludeFile, true)
      .subscribe((response: any) => {
        let data = response.body;
        if (data && data.error || data && !data.response) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data && data.response && data.response.length) {
            this.includeImportDescr = this.translations['process.anag.CONFIRM_IMPORT_1'] + data.response.length + this.translations['process.anag.CONFIRM_IMPORT_2'];
            this.blockInclude = false;
          } else {
            this.includeImportDescr = this.translations['process.anag.NO_USER_FOUND']
          }
        }
        this.isLoadingImport = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingImport = false;
        }
      });
  }

  downloadTrack(isInclude?: boolean) {
    let filePath = '';
    if (isInclude) {
      filePath = '/assets/docs/TracciatoInclusione.xlsx';
    } else {
      filePath = '/assets/docs/TracciatoEsclusione.xlsx';
    }
    let link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    link.click();

  }


  // Toggle della sidebar
  toggleSidebar() {
    this.sidebarService.setShowNav(!this.sidebarService.isNavOpen());
  }

  getSidebarData() {
    this.isLoadingSidebarData = true;

    if (this.getSidebarData$) {
      this.getSidebarData$.unsubscribe();
    }

    this.getSidebarData$ = this.adminService.getUserAnagraphicById(this.sidebarData.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "sd001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          data.response.dataAssunzione = moment(data.response.dataAssunzione).format('dd/MM/YYYY') || '--';
          data.response.deleteData = moment(data.response.deleteData).format('dd/MM/YYYY') || '--';
          //this.sidebarData = Object.assign(this.sidebarData, data.response);
          this.sidebarData = {
            ...this.sidebarData,
            ...data.response
          }
        };
        this.isLoadingSidebarData = false;
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "sd002",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingSidebarData = false;
        return throwError(new Error(err.message));
      });
  }


  toggleSync(turnOn?: boolean) {
    this.syncModalData = {};
    if (turnOn) {
      this.syncModalData.title = this.translations['process.anag.import.INCLUDE_TITLE'];
      this.syncModalData.text = this.translations['process.anag.import.INCLUDE_TEXT'];
      this.syncModalData.turnOn = true;
    } else {
      this.syncModalData.title = this.translations['process.anag.import.EXCLUDE_TITLE'];
      this.syncModalData.text = this.translations['process.anag.import.EXCLUDE_TEXT'];
      this.syncModalData.turnOn = false;
    }

    this.modalService.open('sync-toggle-modal')
  }

  closeToggleSyncModal(confirm?: boolean) {
    this.modalService.close('sync-toggle-modal');
    if (confirm) {
      this.isLoadingSidebarData = true;
      let serviceToCall;
      if (this.syncModalData.turnOn) {
        serviceToCall = this.adminService.setPersonAsSync(this.sidebarData.userId);
      } else {
        serviceToCall = this.adminService.setPersonAsAsync(this.sidebarData.userId);
      }

      serviceToCall.subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.getSidebarData();
      }, (err: any) => {
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "076",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.isLoadingSidebarData = false;
        return throwError(new Error(err.message));
      });
    }
  }

  // TODO aprire select o modale dove scegliere il valutatore?
  onEditEvaluator() {
    this.getModalData();
    this.modalService.open('change-evaluator');
  }

  closeEditEvaluator() {
    this.modalService.close('change-evaluator');
  }

  canIncludeMassive() {
    if (this.selectedUserList && this.selectedUserList.length) {
      const anyAlreadyIncluded = this.selectedUserList.find((x: any) => x.isInProcess);
      if (anyAlreadyIncluded) {
        return false
      }
    }
    return true;
  }


  onSidebarCommentChanged(text: string) {
    this.sidebarData.comments = text;
  }

  canExcludeMassive() {
    if (this.selectedUserList && this.selectedUserList.length) {
      const anyAlreadyExcluded = this.selectedUserList.find((x: any) => !x.isInProcess);
      if (anyAlreadyExcluded) {
        return false
      }
    }
    return true;
  }

  openModalMassive(isInclude?: boolean) {
    this.massiveModalData = {};
    if (isInclude) {
      this.massiveModalData.isInclude = true;
      this.massiveModalData.title = this.translations['process.anag.massive.INCLUDE_TITLE'];
      this.massiveModalData.text = this.translations['process.anag.massive.INCLUDE_TEXT'];
    } else {
      this.massiveModalData.title = this.translations['process.anag.massive.EXCLUDE_TITLE'];
      this.massiveModalData.text = this.translations['process.anag.massive.EXCLUDE_TEXT'];
    }
    this.modalService.open('modal-massive')
  }

  closeModalMassive(confirm?: boolean) {
    this.modalService.close('modal-massive')
    let userIds = this.selectedUserList.map((user: any) => user.userId);
    if (confirm) {
      this.isLoadingUsers = true;
      if (this.massiveModalData.isInclude) {
        this.adminService.includeUserInProcess(this.processYear, this.processYearCode, userIds)
          .subscribe((response: any) => {
            let data = response.body;
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.snackbarText = this.translations['process.anag.massive.INCLUDE_SUCCESS'];
              // toaster 
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
            this.getCountersData();
            this.getUsersList();
          }, (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingUsers = false;
            }
          });
      } else {
        this.adminService.excludeUserFromProcess(this.processYear, this.processYearCode, userIds)
          .subscribe((response: any) => {
            let data = response.body;
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              this.snackbarText = this.translations['process.anag.massive.EXCLUDE_SUCCESS'];
              // toaster 
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
            this.getCountersData();
            this.getUsersList();
          }, (err: any) => {
            if (err && err.message) {
              const messageObj: ApplicationModalMessage = {
                modalId: "076",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingUsers = false;
            }
          });
      }
    }
  }

  getModalData() {
    // Avvio il loader
    this.isLoadingModalData = true;
    let filters: any = undefined;
    if (this.modalSearchedText) {
      filters = {
        searchedText: this.modalSearchedText
      }
    }
    this.getModalData$ = this.adminService.countValidUsersForSetManager(this.processYear, this.processYearCode, filters)
      .subscribe((counterResponse: SenecaResponse<number>) => {
        if (counterResponse.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "count002",
            text: this.translate.instant("errors." + counterResponse.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingModalData = false;
        } else {
          // contatore manager valutatori
          this.modalData.counter = counterResponse.response;

          this.adminService.getValidUsersForSetManager(this.processYear, this.processYearCode, 0, 150, filters)
            .subscribe((data: SenecaResponse<any>) => {
              if (data.error) {
                // Vedo se c'è la traduzione dell'errore
                const messageObj: ApplicationModalMessage = {
                  modalId: "076",
                  text: this.translate.instant("errors." + data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              } else {
                for (let i = 0; i < data.response.length; i++) {
                  let user = data.response[i];
                  let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);
                  if (selected) {
                    user.isChecked = true;
                  }
                }
                this.modalData.list = data.response;
              }
              this.isLoadingModalData = false;
            }
              , (err: any) => {
                this.isLoadingModalData = false;
                if (err && err.message) {
                  const messageObj: ApplicationModalMessage = {
                    modalId: "076",
                    text: this.translate.instant("errors." + ((err && err.message) || err)),
                    title: this.translate.instant("generic.WARNING")
                  }
                  this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                }
              }
            );
        }
      }
        , (err: any) => {
          this.isLoadingModalData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "count001",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        }
      );

  }

  modalSearchChanged(text: string) {
    this.modalSearchedText = text;
  }

  modalPageChanged(page: number) {
    this.modalData.page = page;
    this.modalData.fromRecord = page * this.modalData.numRecords;
    this.modalData.list = [];
    this.getModalData();
  }

  changeModalNumRecords(item: any) {
    this.selectedRows = item;
    this.modalData.numRecords = item.id;
    this.modalData.list = [];
    this.modalData.fromRecord = 0;
    this.getModalData();
  }


  chooseEvaluator(newEvaluator: any) {
    this.closeEditEvaluator();
    this.isLoadingSidebarData = true;
    this.adminService.setUserManagerByUserId(this.sidebarData.userId, newEvaluator.userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
          this.isLoadingSidebarData = false;
        } else {
          this.searchedText = this.translations['process.anag.actions.CHOOSE_EVALUATOR_SUCCESS']
        }
        this.getSidebarData();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingSidebarData = false;
      });
  }


  getPhasesData() {
    this.isLoadingPhasesData = true;

    this.adminService.getProcess(this.processId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          this.phases = {};
          this.phases.people = {};
          this.phases.people.startDate = data.response.peopleAppraisalStartDate;
          this.phases.people.endDate = data.response.peopleAppraisalEndDate;
          let today = new Date();
          let start = new Date(this.phases.people.startDate);
          let end = new Date(this.phases.people.endDate);
          if (today >= start && today <= end) {
            this.phases.people.status = 'ACTIVE'
          } else {
            this.phases.people.status = 'INACTIVE'
          }
          this.phases.self = {};
          this.phases.self.startDate = data.response.selfAssessmentStartDate;
          this.phases.self.endDate = data.response.selfAssessmentEndDate;
          let startSelf = new Date(this.phases.self.startDate);
          let endSelf = new Date(this.phases.self.endDate);
          if (today >= startSelf && today <= endSelf) {
            this.phases.self.status = 'ACTIVE'
          } else {
            this.phases.self.status = 'INACTIVE'
          }

        }
        this.isLoadingPhasesData = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingPhasesData = false;
      });
  }


  openClosePhasesMenu(key: string) {
    this.phases[key].isMenuOpen = !this.phases[key].isMenuOpen;
  }

  closePhasesMenu(key: string) {
    this.phases[key].isMenuOpen = false;
  }

  onPhaseMenuOptionsClicked(option: any, key: any) {
    this.phases[key].isMenuOpen = false;
    if (!option.disabled) {
      if (option.id == 'date-edit') {
        this.router.navigate(['admin/editDates/' + key + '/' + this.processId + '/' + this.processYear + '/' + this.processYearCode])
      }
    }
  }

  formatFilters() {
    let tempFilters: any = {};
    if (this.selectedFilters) {
      if (this.selectedFilters.subcompany && this.selectedFilters.subcompany.value) {
        tempFilters['socDistacco'] = this.selectedFilters.subcompany.value;
      }
      if (this.selectedFilters.cdcfinance && this.selectedFilters.cdcfinance.value) {
        tempFilters['cdcFinance'] = this.selectedFilters.cdcfinance.value;
      }
      if (this.selectedFilters.areaReference && this.selectedFilters.areaReference.value) {
        tempFilters['ramo'] = this.selectedFilters.areaReference.value;
      } 0
      if (this.selectedFilters.department && this.selectedFilters.department.value) {
        tempFilters['defTpDip'] = this.selectedFilters.department.value;
      }
      if (this.selectedFilters.team && this.selectedFilters.team.value) {
        tempFilters['team'] = this.selectedFilters.team.value;
      }
      if (this.selectedFilters.subTeam && this.selectedFilters.subTeam.value) {
        tempFilters['subTeam'] = this.selectedFilters.subTeam.value;
      }
      if (this.selectedFilters.position && this.selectedFilters.position.value) {
        tempFilters['chief'] = this.selectedFilters.position.value;
      }
      if (this.selectedFilters.supervisor && this.selectedFilters.supervisor.value) {
        tempFilters['supervisor'] = this.selectedFilters.supervisor.value;
      }
      if (this.selectedFilters.contractual && this.selectedFilters.contractual.value) {
        tempFilters['tipologiaContratto'] = this.selectedFilters.contractual.value;
      }
      if (this.selectedFilters.office && this.selectedFilters.office.value) {
        tempFilters['sedeLavoro'] = this.selectedFilters.office.value;
      }
      if (this.searchedText) {
        tempFilters['searchedText'] = this.searchedText;
      }
    }
    return tempFilters;
  }

  ngOnDestroy() {
    if (this.getCountersData$) {
      this.getCountersData$.unsubscribe();
    }
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.impersonateUserForAdmin$) {
      this.impersonateUserForAdmin$.unsubscribe();
    }
    if (this.getSidebarData$) {
      this.getSidebarData$.unsubscribe();
    }
    if (this.getModalData$) {
      this.getModalData$.unsubscribe();
    }
    if (this.downloadCurrentList$) {
      this.downloadCurrentList$.unsubscribe();
    }
  }
}


