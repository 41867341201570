import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../../ngrx/app.reducers";
import * as CoreActions from "../../../core/ngrx/core.actions";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'admin-create-update-competence',
  templateUrl: './createUpdateCompetence.component.html',
  styleUrls: ['./createUpdateCompetence.component.scss']
})
export class CreateUpdateCompetenceComponent implements OnInit, OnDestroy {
  competenceId: string = '';
  referenceValueList: any[] = [];
  processYearCode: string = '';
  processYear: number = 0;
  isLoadingMacro: boolean = false;
  getMacroBehaviorList$: Subscription = new Subscription;
  competenceData: any = {
    title: '',
    description: '',
    referenceValue: null
  };
  isLoadingCompetenceData: boolean = false;
  getCompetenceData$: Subscription = new Subscription;
  isBehavior: boolean = false;
  sidenavTitle: string = '';
  sidenavDescr: string = '';
  saveLabel: string = '';
  translations: any;
  confirmModalTitle: any;
  confirmModalNotice: any;
  roleList: any[] = [];

  constructor(
    public redirectService: RedirectService,
    public modalService: ModalService,
    private adminService: AdminService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
  ) {
    this.route.params.subscribe((params: Params) => {
      this.processYear = params.processYear;
      this.processYearCode = params.processYearCode;
      if (params.competenceId) {
        this.competenceId = params.competenceId;
      }
      this.isLoadingCompetenceData = true
      this.translate.get(
        [
          'setupCompetences.createUpdate.SIDENAV_TITLE_EDIT',
          'setupCompetences.createUpdate.SIDENAV_DESCR_EDIT',
          'setupCompetences.createUpdate.SIDENAV_TITLE_NEW',
          'setupCompetences.createUpdate.SIDENAV_DESCR_NEW',
          'setupCompetences.createUpdate.EDIT_BEHAVIOR',
          'setupCompetences.createUpdate.CREATE_BEHAVIOR',
          'setupCompetences.createUpdate.EDIT_COMPTENCE',
          'setupCompetences.createUpdate.CREATE_COMPETENCE',
          'setupCompetences.createUpdate.SIDENAV_TITLE_COMPETENCE_EDIT',
          'setupCompetences.createUpdate.SIDENAV_DESCR_COMPETENCE_EDIT',
          'setupCompetences.createUpdate.SIDENAV_TITLE_COMPETENCE_NEW',
          'setupCompetences.createUpdate.SIDENAV_DESCR_COMPETENCE_NEW',
          "setupCompetences.modals.CREATE_BEHAVIOR",
          "setupCompetences.modals.EDIT_BEHAVIOR",
          "setupCompetences.modals.CREATE_SKILL",
          "setupCompetences.modals.EDIT_SKILL",
          "setupCompetences.modals.WARNING_NOTICE_DESCR_BEHAVIOR",
          "setupCompetences.modals.WARNING_NOTICE_DESCR_SKILL",
        ]
      ).subscribe((translations) => {
        this.translations = translations;
        if (window.location.href.includes('createUpdateBehavior')) {
          this.isBehavior = true;
          this.sidenavTitle = this.competenceId ? translations['setupCompetences.createUpdate.SIDENAV_TITLE_EDIT'] : translations['setupCompetences.createUpdate.SIDENAV_TITLE_NEW'];
          this.sidenavDescr = this.competenceId ? translations['setupCompetences.createUpdate.SIDENAV_DESCR_EDIT'] : translations['setupCompetences.createUpdate.SIDENAV_DESCR_NEW'];
          this.saveLabel = this.competenceId ? translations['setupCompetences.createUpdate.EDIT_BEHAVIOR'] : translations['setupCompetences.createUpdate.CREATE_BEHAVIOR'];
          this.getMacroBehaviorList();
        } else {
          this.sidenavTitle = this.competenceId ? translations['setupCompetences.createUpdate.SIDENAV_TITLE_COMPETENCE_EDIT'] : translations['setupCompetences.createUpdate.SIDENAV_TITLE_COMPETENCE_NEW'];
          this.sidenavDescr = this.competenceId ? translations['setupCompetences.createUpdate.SIDENAV_DESCR_COMPETENCE_EDIT'] : translations['setupCompetences.createUpdate.SIDENAV_DESCR_COMPETENCE_NEW'];
          this.saveLabel = this.competenceId ? translations['setupCompetences.createUpdate.EDIT_COMPTENCE'] : translations['setupCompetences.createUpdate.CREATE_COMPETENCE'];
          if (this.competenceId) {
            this.getCompetenceData()
          } else {
            this.isLoadingCompetenceData = false;
          }
        }
      })
    });
  }

  ngOnInit(): void {

  }

  getCompetenceData() {
    this.isLoadingCompetenceData = true;

    if (this.getCompetenceData$) {
      this.getCompetenceData$.unsubscribe();
    }

    let serviceToCall = this.isBehavior ? this.adminService.listBehavior(this.processYear, this.processYearCode, 0, 1, '', [this.competenceId]) : this.adminService.listCompetences(this.processYear, this.processYearCode, 0, 1, '', [this.competenceId])

    this.getCompetenceData$ = serviceToCall
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "a003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          if (data.response && data.response[0]) {
            if (this.isBehavior) {
              this.competenceData.title = data.response[0].title;
              this.competenceData.description = data.response[0].text;
              let referenceId = data.response[0]?.parentBehavior?.behaviorId;
              this.competenceData.referenceValue = this.referenceValueList.find((x) => x.id == referenceId) || null;
            } else {
              this.competenceData.title = data.response[0].text;
            }
            if (data.response[0] && data.response[0].associatedInRoles && data.response[0].associatedInRoles.length) {
              this.roleList = data.response[0].associatedInRoles;
            }
          } else {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("generic.DATA_NOT_FOUND"),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.redirectService.goBackBrowser();
          }
          this.isLoadingCompetenceData = false;
        }
      }, (err: any) => {
        this.isLoadingCompetenceData = false;
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
      })
  }


  getMacroBehaviorList() {
    this.isLoadingMacro = true;

    if (this.getMacroBehaviorList$) {
      this.getMacroBehaviorList$.unsubscribe();
    }

    this.getMacroBehaviorList$ = this.adminService.listMacroBehaviorForAssessment(this.processYear, this.processYearCode)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          // Vedo se c'è la traduzione dell'errore
          const messageObj: ApplicationModalMessage = {
            modalId: "a003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.referenceValueList = data.response.map((macro: any) => { return { id: macro.tagId, title: macro.title } });
          if (this.competenceId) {
            this.isLoadingCompetenceData = true;
            this.getCompetenceData();
          } else {
            this.isLoadingCompetenceData = false;
          }
          this.isLoadingMacro = false;
        }
      }, (err: any) => {
        this.isLoadingMacro = false;
        if (err && err.message) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
      })

  }


  titleChanged(text: string) {
    this.competenceData.title = text;
  }

  selectReferenceValue(value: any) {
    this.competenceData.referenceValue = value;
  }


  descriptionChanged(text: string) {
    this.competenceData.description = text;
  }

  saveCompetence() {
    this.isLoadingCompetenceData = true;

    let serviceToCall;
    let itemForService: any = {};
    itemForService.title = this.competenceData.title;
    // se è in edit
    if (this.competenceId) {
      itemForService.tagId = this.competenceId;
    }

    if (this.isBehavior) {
      itemForService.description = this.competenceData.description;
      itemForService.macroBehaviorTagId = this.competenceData.referenceValue.id;
      serviceToCall = itemForService.tagId ? this.adminService.updateBehaviorAssessment(this.processYear, this.processYearCode, itemForService) : this.adminService.createBehaviorAssessment(this.processYear, this.processYearCode, itemForService);
    } else {
      serviceToCall = itemForService.tagId ? this.adminService.updateCompetenceAssessment(this.processYear, this.processYearCode, itemForService) : this.adminService.createCompetenceAssessment(this.processYear, this.processYearCode, itemForService);
    }


    serviceToCall.subscribe((data: SenecaResponse<any>) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "beh001",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      } else {
        let x: any = document.getElementById("snackbar");
        if (x) {
          x.className = "show";
          setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
        }
        this.redirectService.goBackBrowser();
      }
    }, (err?: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "beh002",
        text: this.translate.instant("errors." + err?.message),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      this.redirectService.goBackBrowser();
    });
  }


  checkComplete() {
    if (this.isBehavior) {
      return this.competenceData && this.competenceData.title && this.competenceData?.referenceValue?.id
    } else {
      return this.competenceData && this.competenceData.title
    }
  }

  openConfirmModal() {
    if (this.isBehavior) {
      if (this.competenceId) {
        this.confirmModalTitle = this.translate.instant('setupCompetences.modals.EDIT_BEHAVIOR')
      } else {
        this.confirmModalTitle = this.translate.instant('setupCompetences.modals.CREATE_BEHAVIOR')
      }
      this.confirmModalNotice = this.translate.instant('setupCompetences.modals.WARNING_NOTICE_DESCR_BEHAVIOR')
    } else {
      if (this.competenceId) {
        this.confirmModalTitle = this.translate.instant('setupCompetences.modals.EDIT_SKILL')
      } else {
        this.confirmModalTitle = this.translate.instant('setupCompetences.modals.CREATE_SKILL')
      }
      this.confirmModalNotice = this.translate.instant('setupCompetences.modals.WARNING_NOTICE_DESCR_SKILL')
    }
    this.modalService.open('recap-competence-modal');
  }

  closeConfirmModal(confirm?: boolean) {
    this.modalService.close('recap-competence-modal');
    if (confirm) {
      this.saveCompetence();
    }
  }

  ngOnDestroy() {
    if (this.getMacroBehaviorList$) {
      this.getMacroBehaviorList$.unsubscribe();
    }

    if (this.getCompetenceData$) {
      this.getCompetenceData$.unsubscribe();
    }
  }
}