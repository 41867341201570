<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="goBack()" [isBackButton]="true"
      [title]="('technicalAssessment.selfAssessment.SIDENAV_TITLE' | translate)"
      [description]="('technicalAssessment.selfAssessment.SIDENAV_DESCRIPTION' | translate)">
    </sidenav-informations>
  </div>

  <div class="main-content with-fixed-sidenav">
    <div class="content">
      <ng-container *ngIf="!isFetchingCppSurvey && currentModule">
        <div class="form-container">
          <p class="text-title">Technical Self Assessment</p>
          <p class="text-module"
            [innerHTML]="('technicalAssessment.selfAssessment.MODULE' | translate) + ' <b>' + (currentModule.index + 1) + ' di ' + currentModule.totalModules">
          </p>
          <p class="text-advancement" translate="technicalAssessment.selfAssessment.ADVANCEMENT_SELF_ASSESSMENT"></p>
          <div fxLayout="row" fxLayoutAlign="center center" class="full-width">
            <div class="progress-module-percentage">
              <div class="bar" [ngStyle]="{'width': currentModule.totalPercentage + '%'}"></div>
            </div>
            <p>{{currentModule.totalPercentage + '%'}}</p>
          </div>
          <p class="module-title">{{currentModule.title}}</p>
          <div class="question-container" *ngFor="let question of currentModule.questions; let i = index">
            <div fxLayout="row" fxLayoutAlign="start end">
              <p class="position-relative">
                <span class="text-question" [innerHTML]="question.text"></span>
                <svg-icon src="assets/img/icons/info.svg" class="info-icon" [applyClass]="true"
                  (click)="openInstructionsModal()">
                </svg-icon>
              </p>
            </div>
            <alloy-radio [tabIndex]="0" (onItemChange)="onRadioSelect($event, question.questionId)" fxLayout="column"
              fxLayoutAlign="start start" [items]="question.answersRadioList" [bindValue]="question.selectedAnswer"
              radioName="question-cpp-radio-{{i}}"></alloy-radio>
          </div>
          <div class="action-container">
            <alloy-button (onButtonClicked)="goPrecModule()" [disabled]="!currentModule.prevModuleId"
              type="secondary bordless black" size="large" [label]="'generic.BACK' | translate"
              iconLeft="/assets/img/icons/arrow-left.svg">
            </alloy-button>
            <alloy-button [tabIndex]="0" class="margin-bottom0" (onButtonClicked)="saveCurrentModule()" type="success default"
              size="large" [label]="'generic.UPDATE_AND_CONTINUE' | translate">
            </alloy-button>
          </div>
        </div>
      </ng-container>
      <!-- Loader caricamento cpp -->
      <ng-container *ngIf="isFetchingCppSurvey || !currentModule">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </div>
</page-container>

<modal id="cpp-instructions-modal" (onClose)="closeInstructionsModal()" [confirmLabel]="'generic.CLOSE' | translate"
  (onConfirm)="closeInstructionsModal()">
  <modal-text-content [modalId]="'ta003'"
    [title]="'technicalAssessment.selfAssessment.INSTRUCTIONS_MODAL_TITLE' | translate"
    [text]="'technicalAssessment.selfAssessment.INSTRUCTIONS_MODAL_DESC' | translate">
  </modal-text-content>
</modal>

<modal id="confirm-technical-assessment-modal" (onClose)="closeConfirmTechnicalAssessmentModal()"
  [confirmLabel]="'technicalAssessment.selfAssessment.CONFIRM_AND_SEND' | translate"
  (onConfirm)="closeConfirmTechnicalAssessmentModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  (onCancel)="closeConfirmTechnicalAssessmentModal()">
  <modal-text-content [title]="'technicalAssessment.selfAssessment.CONFIRM_AND_SEND' | translate"
    [subtitle]="'technicalAssessment.selfAssessment.CONFIRM_AND_SEND_SUBTITLE' | translate"
    [text]="'technicalAssessment.selfAssessment.CONFIRM_AND_SEND_DESC' | translate"></modal-text-content>
</modal>

<modal id="not-continue-assessment-modal" (onClose)="closeNotContinueAssessmentModal()"
  [confirmLabel]="'generic.GOT_IT' | translate" (onConfirm)="closeNotContinueAssessmentModal()">
  <modal-text-content [modalId]="'ta004'" [title]="'technicalAssessment.selfAssessment.SAVE_AND_GO' | translate"
    [subtitle]="'technicalAssessment.selfAssessment.CANNOT_CONTINUE_MODAL_SUBTITLE' | translate"
    [text]="'technicalAssessment.selfAssessment.CANNOT_CONTINUE_MODAL_DESC' | translate">
  </modal-text-content>
</modal>

<modal id="open-prev-module-modal" (onClose)="closePrevModuleModal()"
  [confirmLabel]="'technicalAssessment.selfAssessment.OK_CONTINUE_WITHOUT_SAVE' | translate"
  (onConfirm)="closePrevModuleModal(true)" [cancelLabel]="'generic.ANNULL' | translate"
  (onCancel)="closePrevModuleModal()">
  <modal-text-content [title]="'technicalAssessment.selfAssessment.ATTENTION' | translate"
    [subtitle]="'technicalAssessment.selfAssessment.GO_TO_PREV_MODULE_SUBTITLE' | translate"
    [text]="'technicalAssessment.selfAssessment.GO_TO_PREV_MODULE_DESC' | translate"></modal-text-content>
</modal>

<modal id="open-go-back-modal" (onClose)="closeGoBackModal()"
  [confirmLabel]="'technicalAssessment.selfAssessment.OK_GO_BACK' | translate" (onConfirm)="closeGoBackModal(true)"
  [cancelLabel]="'generic.ANNULL' | translate" (onCancel)="closeGoBackModal()">
  <modal-text-content [title]="'technicalAssessment.selfAssessment.ATTENTION' | translate"
    [subtitle]="'technicalAssessment.selfAssessment.GO_TO_BACK_SUBTITLE' | translate"
    [text]="'technicalAssessment.selfAssessment.GO_TO_BACK_DESC' | translate"></modal-text-content>
</modal>