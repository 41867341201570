<div class="page-content-wrapper">
  <!-- Toaster -->
  <div id="snackbar">
    <p translate="generic.SAVE_SUCCESSFUL"></p>
  </div>

  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('cppAdmin.MANAGE_CPP' | translate)" [adminBack]="true"
    [hasTabs]="false" [adminPageSubtitle]="('cppAdmin.ROUND_NAME' | translate)"
    [adminActionLabel]="('calibration.DOWNLOAD_REPORT' | translate)" (onAdminAction)="downloadReport()"
    [adminActionDisabled]="isDownloadingReport" adminActionLabel="assets/img/icons/file/xls.svg"
    adminActionLabelType="success"></header-dossier>

  <div class="main-content-wrapper">
    <div *ngIf="!isLoadingCppRound" class="admin-table-container column-spacing32 margin-bottom32">

      <p class="section-title">{{ ('cppAdmin.ROUND_ACTIVE_AT' | translate) + (cppRound?.startDate | date:
        'dd/MM/YYYY')}}</p>

      <p class="template-cpp-desc" [innerHTML]="'cppAdmin.ROUND_CPP_ACTIVE_DESC' | translate"></p>
      <div class="download-update-cpp-container">
        <alloy-button [label]="'cppAdmin.DOWNLOAD_CPP_ACTUAL' | translate" type="primary black-link"
          (onButtonClicked)="getCppTemplate()" iconLeft="assets/img/icons/file/xls.svg"
          [disabled]="isLoadingCppTemplate">
        </alloy-button>
        <alloy-button [label]="'cppAdmin.UPDATE_CPP' | translate" type="black-border"
          (onButtonClicked)="openImportModal()"
          [disabled]="isLoadingCppTemplate || isPassedCppRoundStartDate || cppRound?.roundStatus == 'CLOSED'"
          size="medium">
        </alloy-button>
      </div>
    </div>

    <!-- loader -->
    <ng-container *ngIf="isLoadingCppRound">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>

    <div class="admin-table-container column-spacing">
      <div fxLayout="column" fxLayoutGap="10px">
        <p *ngIf="!isLoadingCppRound" class="section-title">{{ ('cppAdmin.PERSON_LIST_AT' | translate) +
          (cppRound?.startDate | date: 'dd/MM/YYYY') }}
        </p>
        <p class="subtitle">{{'cppAdmin.PERSON_LIST_SUBTITLE' | translate}}</p>
      </div>
      <!-- Filtri -->
      <anag-filters (searchedTextChanged)="searchedTextChanged($event)" [isLoadingUsers]="isLoadingUsers"
        (updateSelectedFilters)="updateSelectedFilters($event)" [isCpp]="true" [roundId]="roundId"
        (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getUsersList($event)">
      </anag-filters>
      <!-- <div class="mail-container">
        Invia solleciti self Assessement
        <div class="send-mail">
          <svg-icon src="../../../assets/img/icons/mail.svg"></svg-icon>
          <p translate="cppAdmin.SEND_REMINDERS_SELF"></p>
        </div>
        <div class="send-mail">
          <svg-icon src="../../../assets/img/icons/mail.svg"></svg-icon>
          <p translate="cppAdmin.SEND_REMINDERS_PEOPLE_APPRAISAL"></p>
        </div>
      </div> -->
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingUsers && usersList && usersList.length">

        <div class="table-content">
          <table>
            <thead>
              <tr>
                <th style="width: 1%;" *ngIf="cppRound?.roundStatus != 'CLOSED'">
                  <alloy-check (onCheck)="selectAll()"></alloy-check>
                </th>
                <th translate="{{ ('adminTable.NAME' | translate)}}" style="width: 20%;"></th>
                <th>CF</th>
                <th translate="{{ ('cppAdmin.COMPILATION_DATE' | translate)}}" style="width: 20%;"></th>
                <!-- <th translate="adminTable.DEV_PLAN" style="width: 17%;"></th> -->
                <th translate="adminTable.STATUS" style="width: 10%;"></th>
                <th *ngIf="cppRound?.roundStatus != 'CLOSED'" translate="{{ ('adminTable.ACTIONS' | translate)}}"
                  style="width: 1%;">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of usersList | 
                          slice:0:usersNumRecords | 
                          paginate: { id: 'cppUsersList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                <td *ngIf="cppRound?.roundStatus != 'CLOSED'">
                  <alloy-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></alloy-check>
                </td>
                <td>{{ user.surname + ' ' + user.forename }}</td>
                <td>{{user.codFisc}}</td>
                <td *ngIf="user.surveyCompletionDate">
                  <p>{{('cppAdmin.COMPILED_AT' | translate) + (user.surveyCompletionDate | date: 'dd/MM/YYYY')}}</p>
                  <p class="link-underlined" (click)="downloadReport(user.userId)">
                    {{'dashboard.DOWNLOAD_REPORT' | translate}}</p>
                </td>
                <td *ngIf="!user.surveyCompletionDate">
                  {{'cppAdmin.TO_COMPILE' | translate}}
                </td>
                <!-- <td>
                  <div class="status-cpp">
                    <ng-container *ngIf="!user.isInRound">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'cppAdmin.SHARED' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.isInRound">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="toStart"
                        [message]="'cppAdmin.TO_CREATE' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.isInRound && this.usersCurrentPage == 2">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="inProgress"
                        [message]="'cppAdmin.IN_PROGRESS  ' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </div>
                </td> -->
                <!-- Stato -->
                <td>
                  <div class="status-cpp">
                    <ng-container *ngIf="!user.isInRound">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="disabled"
                        [message]="'cppAdmin.EXCLUDED' | translate">
                      </item-list-team-status>
                    </ng-container>
                    <ng-container *ngIf="user.isInRound">
                      <item-list-team-status customContainerClass="min-width-unset" styleItem="border" type="completed"
                        [message]="'cppAdmin.INCLUDED' | translate">
                      </item-list-team-status>
                    </ng-container>
                  </div>
                </td>
                <td *ngIf="cppRound?.roundStatus != 'CLOSED'">
                  <p class="link-underlined"
                    (click)="user.isInRound ? excludeUsers(user.userId) : includeUsers(user.userId)">
                    {{user.isInRound ? ('cppAdmin.EXCLUDE' | translate) : ('cppAdmin.INCLUDE' | translate)}}</p>
                </td>
                <!-- Menu attualmete rimosso da inseirire in futuro -->
                <!-- <td class="more-icon"> <svg-icon src="../../../assets/img/icons/more-horizontal.svg"></svg-icon></td> -->
              </tr>

            </tbody>
          </table>
        </div>

        <div class="bottom-actions">
          <table-pagination id="cppUsersList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </alloy-select>
          </div>
        </div>

        <!-- <div class="mail-container">
          <div class="send-mail">
            <svg-icon src="../../../assets/img/icons/mail.svg"></svg-icon>
            <p translate="cppAdmin.SEND_REMINDERS_SELF"></p>
          </div>
          <div class="send-mail">
            <svg-icon src="../../../assets/img/icons/mail.svg"></svg-icon>
            <p translate="cppAdmin.SEND_REMINDERS_PEOPLE_APPRAISAL"></p>
          </div>
        </div> -->
      </ng-container>
      <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
        <div class="admin-no-data-wrapper">
          <p translate="generic.NO_DATA_FOUND"></p>
        </div>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsers">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="button-actions sticky"
  *ngIf="selectedUserList && selectedUserList.length > 1 && cppRound?.roundStatus != 'CLOSED'">
  <div class="selected-container">
    <p class="selected-count">{{ selectedUserList.length || 0}}</p>
    <p class="selected-count-label" translate="generic.SELECTEDS"></p>
  </div>
  <!-- Includi/Escludi -->
  <alloy-button [label]="'cppAdmin.EDIT_STATUS_INCLUDED_EXCLUDED' | translate" type="success"
    [disabled]="!selectedUserList || (selectedUserList && selectedUserList.length < 2)"
    (onButtonClicked)="openIncludeExcludeUsers()"></alloy-button>
</div>


<!-- Modale aggiorna cpp template -->
<modal id="import-modal" (onClose)="closeImportModal()" (onConfirm)="closeImportModal(true)"
  [confirmLabel]="'generic.IMPORT' | translate" [isDisabled]="!importFile">
  <div class="sti-modal-container">
    <p class="title" translate="cppAdmin.UPDATE_CPP">
    </p>
    <div class="divisor-line"></div>

    <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
      [title]="'cppAdmin.UPDATE_CPP_DESCR' | translate">
      <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"
        [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></alloy-upload>
    </input-container>

    <ng-container *ngIf="importFile">
      <ng-container *ngIf="isLoadingCppTemplate">
        <div class="loader spinner-medium"></div>
      </ng-container>
    </ng-container>
  </div>
</modal>

<modal id="include-exclude-modal" (onClose)="closeIncludeExcludeUsers()" (onCancel)="closeIncludeExcludeUsers()"
  (onConfirm)="closeIncludeExcludeUsers(true)" [cancelLabel]="'generic.CANCEL' | translate"
  [confirmLabel]="'generic.CONFIRM' | translate">
  <modal-text-content [title]="'generic.CONFIRM' | translate" [text]="'cppAdmin.YOU_ARE_CONFIRMING_CHANGE_STATUS_CPP_ROUND_DESC' | translate: {
      'value1': selectedUsersToExclude || 0,
      'value2': selectedUsersToInclude || 0,
      'value3': selectedUsersToExclude == 1 ? ('cppAdmin.PERSON_SWITCH_ONE' | translate) : ('cppAdmin.PERSON_SWITCH_MULTI' | translate),
      'value4': selectedUsersToInclude == 1 ? ('cppAdmin.PERSON_SWITCH_ONE_OTHER' | translate) : ('cppAdmin.PERSON_SWITCH_MULTI_OTHER' | translate)
      }" [subtitle]="'cppAdmin.YOU_ARE_CONFIRMING_CHANGE_STATUS_CPP_ROUND' | translate">
  </modal-text-content>
</modal>