<ng-container *ngIf="!isFromMidTermReview && !isForFinalEvaluation">
  <div class="card-row-container">
    <div class="care-card-wrapper" *ngFor="let card of items; trackBy cardId"
      [ngClass]="{'active': card.isChecked, 'disabled': disabled, 'readonly': readonly, 'from-mid-term': isFromMidTermReview}"
      (click)="emitOnCheck(card)">
      <div class="letter-wrapper">
        <p class="title-page">{{ card.id }}</p>
      </div>
      <p class="text-small" [translate]="card.title"></p>
      <div class="check-wrapper">
        <svg-icon *ngIf="card.isChecked" src="assets/img/icons/check.svg" class="checkmark" [applyClass]="true">
        </svg-icon>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isFromMidTermReview || isForFinalEvaluation">
  <div class="card-row-container">
    <div class="care-card-wrapper" *ngFor="let card of items; trackBy cardId"
      [ngClass]="{'active': card.isChecked, 'disabled': disabled, 'readonly': readonly, 'from-mid-term': isFromMidTermReview, 'for-final-evaluation': isForFinalEvaluation}"
      (click)="emitOnCheck(card)">
      <div>
        <svg-icon class="mid-term-icon" src="{{card.icon}}" [applyClass]="true"></svg-icon>
      </div>
      <p class="text-mid-term-title" [translate]="card.title"></p>
      <div class="radio-empty">
        <div *ngIf="card.isChecked" class="checked"></div>
      </div>
    </div>
  </div>
</ng-container>