<div class="performer-evaluator-info-modal-content" *ngIf="performersArray && performersArray.length">
  <div class="performer-info-modal-title">
    <p translate="performerEvaluators.PAS_PROFILES"></p>
  </div>

  <div class="performer-tab-container">
    <div class="tab" *ngFor="let performerObj of performersArray" (click)="changeInfoTab(performerObj.key)"
      [ngClass]="{ 'active': performerObj.key === _selectedInfoTabPerformerKey }">
      <p>{{ performerObj.abbreviation }}</p>
    </div>
  </div>

  <!-- Livello selezionato -->
  <performer-evaluator [performerKey]="_selectedInfoTabPerformerKey"></performer-evaluator>
</div>