import { createAction, props } from '@ngrx/store';
import { UserAcknowledges } from '../../../commonclasses';

export const DoLogin = createAction(
  '[auth] do login',
  props<{
    email?: string, password?: string, isLdap?: boolean,
    oauthCode?: string
  }>()
);

export const Login = createAction(
  '[auth] login'
);

export const SetUserAcknowledges = createAction(
  '[auth] set user ack',
  props<{ payload: UserAcknowledges | null }>()
);

export const UpdateUserAcknowledges = createAction(
  '[auth] update user ack',
  props<{ payload: UserAcknowledges }>()
);

export const GetUserAcknowledges = createAction(
  '[auth] get user ack',
  props<{ payload: UserAcknowledges }>()
);

export const RetrieveUserAcknowledges = createAction(
  '[auth] retrieve user ack'
);

export const Logout = createAction(
  '[auth] logout'
);

export const SessionLogout = createAction(
  '[auth] session logout'
);

export const SetUserAuthenticated = createAction(
  '[auth] set user authenticated'
);

export const SetToken = createAction(
  '[auth] set token',
  props<{ payload: string }>()
);
