import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { GetPerformanceAssessmentCurrentPhaseResponse, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../../ngrx/app.reducers";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { UrlService } from 'src/app/shared/services/url.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AlloyPerformanceUpwardFeedbackServiceResponses, UPWARD_FEEDBACK_SUBORDINATE_EVALUATION_STATUS } from 'atfcore-commonclasses';

@Component({
  selector: 'admin-upward-feedback-user-list',
  templateUrl: './upwardFeedbackUserList.component.html',
  styleUrls: ['./upwardFeedbackUserList.component.scss']
})
export class UpwardFeedbackUserListComponent implements OnDestroy {
  combinedSelected$: Subscription;
  runningYear: any;

  rowPerPageOptions = [
    {
      id: 10,
      title: 10
    },
    {
      id: 20,
      title: 20
    },
    {
      id: 50,
      title: 50
    },
    {
      id: 100,
      title: 100
    },
  ]
  selectedRows: { id: number, title: number };

  menuOptions: any[] = [];

  processYear: number = 0;
  processYearCode: string = '';

  usersFromRecord: number = 0;
  usersNumRecords: number = 5;
  usersCurrentPage: number = 0;
  usersCounter: number = 0;
  isLoadingUsers: boolean = false;
  selectedUserList: any;
  searchedText: string = '';

  usersList: any[] = [];
  translations: any;
  allChecked: boolean = false;
  selectedFilters: any = {};

  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  isLoadingTranslations: boolean = false;
  runningPhase: any;
  selectedFiltersNumber: number = 0;
  loggedUser: any;
  reopenModal: any = {};

  today: Date = new Date();
  isLoadingSurvey: boolean = false;
  roundId: any;
  resetIncludeUpload: boolean = true;
  importFile: any;
  isLoadingModalUsers: boolean = false;
  isWaitingUnshare: boolean = false;
  isWaitingReopen: boolean = false;
  modalUserListDescription: any;
  upwardFeedbackUsersToReopenSurv: any;
  selectedUpwardFeedbackUsersToReopenSurv: any;
  selectedUserForModal: any;
  isSendingReminders: boolean = false;
  shareMultipleDescription: string = '';
  sendReminderUser: any;
  getRoundData$: any;
  isLoadingRound: boolean = false;
  roundData: any;

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    private modalService: ModalService,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Lista degli utenti");

    this.selectedRows = this.rowPerPageOptions[2];
    this.usersNumRecords = this.selectedRows.id;

    const getRunningPhase$: Observable<GetPerformanceAssessmentCurrentPhaseResponse> = this.store.select(fromApp.getRunningPhase);
    const runningYear$: Observable<any> = this.store.select(fromApp.getRunningYear);
    const loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([getRunningPhase$, runningYear$, loggedUser$])
      .subscribe(
        ([runningPhase, runningYear, loggedUser]) => {
          this.runningPhase = runningPhase;
          this.runningYear = runningYear;
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;
          }
          this.route.params
            .subscribe(
              (params: Params) => {
                this.roundId = params.roundId;
                this.getRoundData();
              })
        });
  }

  ngOnInit() {
    sessionStorage.setItem('peopleAppraisal', '');
    sessionStorage.setItem('userIdList', '');
    this.isLoadingTranslations = true;

    this.translate.get([
      'upwardFeedback.admin.manage.SEND_REMINDER',
      'upwardFeedback.admin.manage.SEND_REMINDER_TO_TEAM',
      'upwardFeedback.admin.manage.CANCEL_SHARING',
      'upwardFeedback.admin.manage.REOPEN_SURVEYS',
      'upwardFeedback.admin.manage.WHO_SEND',
      'upwardFeedback.admin.manage.VERIFY_RESULT',
      'monitoring.DOWNLOAD_EVALUATION_CARD_PDF',
      'monitoring.REOPEN_SELF_ASSESSMENT',
      'monitoring.REOPEN_PEOPLE_APPRAISAL',
      'verifySend.modals.SEND_DESCR_MANY1',
      'verifySend.modals.SEND_DESCR_MANY2',
      'monitoring.modals.SELF_TITLE',
      'monitoring.modals.SELF_SUB',
      'monitoring.modals.SELF_DESCR_1',
      'monitoring.modals.SELF_DESCR_2',
      'monitoring.modals.PEOPLE_TITLE',
      'monitoring.modals.PEOPLE_SUB',
      'monitoring.modals.PEOPLE_DESCR_1',
      'monitoring.modals.PEOPLE_DESCR_2',
      'upwardFeedback.userAdminStatus.TO_CHECK',
      'upwardFeedback.userAdminStatus.WAITING',
      'upwardFeedback.userAdminStatus.CHECKED',
      'upwardFeedback.userAdminStatus.SHARED',
    ]).subscribe((translations) => {
      this.translations = translations;

      this.menuOptions = [
        {
          id: 'verify',
          title: translations['upwardFeedback.admin.manage.VERIFY_RESULT'],
          icon: '/assets/img/icons/pie-chart.svg',
        },
        {
          id: 'who',
          title: translations['upwardFeedback.admin.manage.WHO_SEND'],
          icon: '/assets/img/icons/help-circle.svg',
        },
        {
          id: 'send',
          title: translations['upwardFeedback.admin.manage.SEND_REMINDER_TO_TEAM'],
          icon: '/assets/img/icons/send.svg',
        },
        {
          id: 'unshare',
          title: translations['upwardFeedback.admin.manage.CANCEL_SHARING'],
          icon: '/assets/img/icons/wand.svg',
        },
        {
          id: 'reopen',
          title: translations['upwardFeedback.admin.manage.REOPEN_SURVEYS'],
          icon: '/assets/img/icons/wand.svg',
        }
      ]
      this.getUsersList();
      this.isLoadingTranslations = false;
    })
  }

  // Associato alla direttiva clickOutside
  closeMenu(user: any) {
    user.isMenuOpen = false;
  }

  // menu utente
  // dalla tabella apre il menu con le azioni sull'utente
  openCloseMenu(user: any) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      this.menuOptions[i].disabled = this.shouldMenuOptionBeDisabled(this.menuOptions[i], user)
    }
    user.isMenuOpen = !user.isMenuOpen
  }

  // gestisce le opzioni del menu utente
  onMenuOptionsClicked(options: any, user: any, isConfirm?: boolean) {
    user.isMenuOpen = false;
    if (!options.disabled) {
      if (options.id == 'verify') {
        this.router.navigate(['admin/manageUpwardFeedback/verify/', this.roundId, user.userId]);
      } else if (options.id == 'who') {
        this.openWhoFeedback(user)
      } else if (options.id == 'send') {
        this.openSendReminderModal(user);
      } else if (options.id == 'unshare') {
        this.openCancelSharingUpwardFeedback(user);
      } else if (options.id == 'reopen') {
        this.showReopenUpfSurveysModal(user);
      }
    }
  }

  openCancelSharingUpwardFeedback(user: any) {
    this.selectedUserForModal = user;
    this.modalService.open('cancel-sharing');
  }

  cancelSharingUpwardFeedback(confirm?: boolean) {
    this.modalService.close('cancel-sharing');
    if (confirm) {
      this.isWaitingUnshare = true;
      this.adminService.cancelSharingUpwardFeedback({ roundId: this.roundId, userIds: [this.selectedUserForModal.userId] })
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isWaitingUnshare = false;
          } else {
            // toaster
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.isWaitingUnshare = false;
            this.selectedUserForModal =
              this.getUsersList();
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isWaitingUnshare = false;
          });
    } else {

    }
  }

  reopenUpwardFeedbackSurveys() {
    this.isWaitingReopen = true;
    this.closeReopenSurveys();
    const managerId = this.selectedUserForModal.userId;
    const userIdsToReopenSurveys = this.upwardFeedbackUsersToReopenSurv.filter((user: any) => user.isChecked).map((user: any) => user.userId);
    this.adminService.reopenUpwardFeedbackSurveys({ roundId: this.roundId, managerId: managerId, userIds: userIdsToReopenSurveys })
      .subscribe((data: SenecaResponse<boolean>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isWaitingReopen = false;
        } else {
          // toaster
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
          this.isWaitingReopen = false;
          this.upwardFeedbackUsersToReopenSurv = [];
          this.getUsersList();
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isWaitingReopen = false;
        });
  }

  openSendReminderModal(user: any) {
    this.sendReminderUser = user;
    this.modalService.open('send-reminders');
  }

  closeSendRemindersModal(confirm?: boolean) {
    this.modalService.close('send-reminders');
    if (confirm) {
      this.sentRemainingReminders(this.sendReminderUser);
    }
  }


  getRoundData() {
    this.isLoadingRound = true;

    if (this.getRoundData$) {
      this.getRoundData$.unsubscribe();
    }

    this.getRoundData$ = this.adminService.getUpwardFeedbackRounds(0, 1, [this.roundId], false)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          if (data.response && data.response[0]) {
            this.roundData = data.response[0];
          }
        }
        this.isLoadingRound = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRound = false;
      });
  }

  sentRemainingReminders(user: any) {
    this.isSendingReminders = true;
    this.adminService.sendReminderToRemainingEvaluators({ roundId: this.roundId, managerId: user.userId })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf013",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // toaster
          let x: any = document.getElementById("snackbar-reminder");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        this.isSendingReminders = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf014",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isSendingReminders = false;
      });
  }

  // Seleziona tutti nell'header della tabella
  selectAll() {
    this.allChecked = !this.allChecked;
    if (this.selectedUserList && this.selectedUserList.length) {
      this.selectedUserList.length = 0;
    }
    if (this.usersList && this.usersList.length > 0) {
      for (let i = 0; i < this.usersList.length; i++) {
        this.usersList[i].isChecked = this.allChecked;
        if (this.allChecked) {
          this.addRemoveUser(this.usersList[i]);
        }
      }
    }
  }

  // ricerca
  searchedTextChanged(text: string) {
    this.searchedText = text;
  }

  // cambia il numero di record recuperati dal getUserList
  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.usersNumRecords = item.id;
    this.resetUserData();
    this.getUsersList(true);
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.usersList = [];
  }

  // Recupera una lista di utenti
  getUsersList(fromSearch?: any) {
    if (fromSearch) {
      this.resetUserData();
    }
    sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    // Avvio il loader
    this.isLoadingUsers = true;
    this.adminService.countManagersInUpwardFeedbackRound(this.roundId, this.searchedText, this.selectedFilters)
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.usersCounter = counter.response;
              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.usersCurrentPage && this.usersNumRecords) {
                fromRecord = (this.usersCurrentPage - 1) * this.usersNumRecords;
              } else {
                fromRecord = 0;
              }

              if (this.usersCounter) {
                return this.adminService.getManagersInUpwardFeedbackRound(this.roundId, this.searchedText, fromRecord, this.usersNumRecords, this.selectedFilters);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingUsers = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            for (let i = 0; i < data.response.length; i++) {
              let user = data.response[i];
              if (this.selectedUserList) {
                let selected = this.selectedUserList.find((tmp: any) => tmp.userId == user.userId);
                if (selected) {
                  user.isChecked = true;
                }
              }
              user.statusMessage = this.translations['upwardFeedback.userAdminStatus.' + user.managerStatus];
              if (user.managerStatus == 'TO_CHECK') {
                user.statusType = 'toStart';
              } else if (user.managerStatus == 'WAITING') {
                user.statusType = 'disabled';
              } else if (user.managerStatus == 'CHECKED') {
                user.statusType = 'inProgress';
              } else if (user.managerStatus == 'SHARED') {
                user.statusType = 'completed';
              }
            }
            this.usersList = data.response;
          }
          this.isLoadingUsers = false;
        }
        , (err: any) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "076",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  // Cambia la paginazione alla lista
  usersPageChanged(newPage: any) {
    this.usersCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.getUsersList(false);
  }

  // Check singolo utente
  selectUser(user: any) {
    user.isChecked = !user.isChecked;
    this.addRemoveUser(user);
    this.selectedUserList = [];
    for (let i = 0; i < this.usersList.length; i++) {
      if (this.usersList[i].isChecked) {
        this.selectedUserList.push(this.usersList[i]);
      }
    }
  }

  addRemoveUser(user: any) {
    if (this.selectedUserList && this.selectedUserList.length) {
      if (!user.isChecked) {
        this.selectedUserList = this.selectedUserList.filter((tmp: any) => tmp.userId != user.userId);
      } else {
        this.selectedUserList.push(user);
      }
    } else {
      this.selectedUserList = [user];
    }

  }


  // Conversione stato calibration utente
  getCalibrationStatus(status: string) {
    switch (status) {
      case 'CALIBRATION_TODO':
        return {
          label: this.translations['calibration.statuses.TO_START'],
          status: 'toStart'
        };
      case 'CALIBRATION_TO_CONFIRM':
        return {
          label: this.translations['calibration.statuses.TO_CONFIRM'],
          status: 'inProgress'
        };
      case 'CALIBRATION_CONFIRMED':
        return {
          label: this.translations['calibration.statuses.COMPLETED'],
          status: 'completed'
        };
      default:
        return {
          label: this.translations['calibration.statuses.DISABLED'],
          status: 'disabled'
        };
    }
  }

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  shouldMenuOptionBeDisabled(option: any, user: any) {
    if (option.id == 'verify' && user.managerStatus && !['WAITING'].includes(user.managerStatus)) {
      return false;
    } else if (option.id == 'who') {
      return false;
    } else if (option.id == 'send' && user.managerStatus != 'SHARED' && user.numberOfFeedbackReceived < user.numberOfEvaluators) {
      return false;
    } else if (option.id == 'unshare' && user.managerStatus == 'SHARED') {
      return false;
    } else if (option.id == 'reopen') {
      return false;
    }
    return true;
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadUpwardFeedbackReport(this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }


  // Scarica survey attuale
  downloadCurrentSurvey() {
    this.isLoadingSurvey = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.getUpwardFeedbackTemplate(this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSurvey = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isLoadingSurvey = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isLoadingSurvey = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingSurvey = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingSurvey = false;
      })
  }



  // aggiorna survey
  openUpdateSurveyModal() {
    this.resetIncludeUpload = true;
    this.importFile = null;
    this.modalService.open('update-survey');
  }

  assignFile(file: any) {
    this.importFile = file;
  }

  closeUpdateSurveyModal(confirm?: boolean) {
    this.modalService.close('update-survey');
    if (confirm) {
      this.isLoadingSurvey = true;
      this.adminService.updateUpwardFeedbackSurveyTemplate(this.roundId, this.importFile)
        .subscribe((data: any) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "da001",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingSurvey = false;
          } else {
            // toaster
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
            this.isLoadingSurvey = false;
          }
        },
          (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "da002",
              text: this.translate.instant("errors." + err?.message || err),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isLoadingSurvey = false;
          });
    }
  }

  checkVerifiedStatus() {
    if (this.selectedUserList && this.selectedUserList.length) {
      for (let i = 0; i < this.selectedUserList.length; i++) {
        if (this.selectedUserList[i].status != 'VERIFIED') {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }

  checkShareDisabled() {
    if (this.selectedUserList && this.selectedUserList.length) {
      for (let i = 0; i < this.selectedUserList.length; i++) {
        if (!['TO_CHECK', 'CHECKED'].includes(this.selectedUserList[i].managerStatus)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  }


  openShareVerifiedResults() {
    this.shareMultipleDescription = this.getUserListDescription(this.selectedUserList);
    this.modalService.open('share-verified')
  }

  getUserListDescription(data: any[], isWhoModal?: boolean) {
    if (data) {

      let str = '';
      if (!isWhoModal) {
        str += this.translate.instant('upwardFeedback.admin.modals.SHARING_WITH');
        str += '<br><br>';
      }
      str += '<ul>';
      for (let i = 0; i < data.length; i++) {
        str += '<li>' + data[i].surname + ' ' + data[i].forename + '</li>';
      }
      str += '</ul><br>';
      if (!isWhoModal) {
        str += this.translate.instant('upwardFeedback.admin.modals.CONFIRM_QUESTION');
      }
      return str;
    } else {
      return 'Not found';
    }
  }

  closeShareVerifiedResults(confirm?: boolean) {
    this.modalService.close('share-verified')
    if (confirm) {
      this.shareVerifiedResults();
    }
  }

  // Condividi risultati verificati
  shareVerifiedResults() {
    this.isLoadingUsers = true;
    let userIds = this.selectedUserList.map((user: any) => user.userId);
    this.adminService.shareUpwardFeedbackResultsWithManager({ roundId: this.roundId, userIds: userIds })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf013",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        }
        this.selectedUserList = [];
        this.getUsersList();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf014",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingUsers = false;
      });
  }

  showReopenUpfSurveysModal(user: any) {
    this.selectedUserForModal = user;
    this.getUserListModalData(true);
    this.modalService.open('reopen-surveys');
  }


  // Recupero utenti che possono valutare
  openWhoFeedback(user: any) {
    this.selectedUserForModal = user;
    this.modalService.open('who-feedback');
    this.getUserListModalData();
  }

  getUserListModalData(includeSurveyStatus?: boolean) {
    this.isLoadingModalUsers = true;
    this.adminService.getUpwardFeedbackEvaluatorsOfManager({ roundId: this.roundId, userId: this.selectedUserForModal.userId, includeSurveyStatus: !!includeSurveyStatus })
      .subscribe((data: SenecaResponse<AlloyPerformanceUpwardFeedbackServiceResponses.GetUpwardFeedbackEvaluatorsForAdmin>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf007",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingModalUsers = false;
        } else {
          if (includeSurveyStatus) {
            // sto chiedendo altri dati, e mostrerò la modale di selezione degli utenti

            const upwardFeedbackData = data.response;
            if (upwardFeedbackData.length) {
              this.upwardFeedbackUsersToReopenSurv = [];
              for (let i = 0; i < upwardFeedbackData.length; i++) {
                let item = upwardFeedbackData[i];
                this.upwardFeedbackUsersToReopenSurv.push({
                  ...item,
                  cardStatus: item.surveyCompleted ? 'COMPLETED' : 'TO_COMPILE',
                  available: item.surveyCompleted,
                  avatar: item?.userOptions?.avatarImage ? item.userOptions.avatarImage : null
                });
              }
              //this.upwardFeedbackUsersToReopenSurv = this.upwardFeedbackUsersToReopenSurv.filter((user: any) => user.resultAvailable)
              this.isLoadingModalUsers = false;
            }

          } else {
            this.modalUserListDescription = this.getUserListDescription(data.response, true);
            this.isLoadingModalUsers = false;
          }


        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf008",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingModalUsers = false;
        });
  }

  closeWhoFeedback() {
    this.modalService.close('who-feedback');
  }

  closeReopenSurveys() {
    this.modalService.close('reopen-surveys');
    //this.selectedUserForModal = null;
  }

  isRoundActive() {
    return this.roundData && this.roundData.roundStatus == "RUNNING";
  }

  openSendReminderMassive() {
    this.modalService.open('send-reminders-massive');
  }

  closeSendReminderMassive(confirm?: boolean) {
    this.modalService.close('send-reminders-massive');
    if (confirm) {
      this.isSendingReminders = true;
      this.adminService.sendReminderToRemainingEvaluatorsForUpwardFeedback({ roundId: this.roundId })
        .subscribe((data: SenecaResponse<boolean>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "upf009",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar-reminder");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.isSendingReminders = false;
        }, (err?: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf010",
            text: this.translate.instant("errors." + err?.message),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isSendingReminders = false;
        })
    }
  }

  checkIfUserSelectedForCardReopen() {
    if (this.upwardFeedbackUsersToReopenSurv && this.upwardFeedbackUsersToReopenSurv.length) {
      return this.upwardFeedbackUsersToReopenSurv.some((user: any) => user.isChecked == true);
    }
    return false;
  }

  ngOnDestroy() {
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.getRoundData$) {
      this.getRoundData$.unsubscribe();
    }

  }
}


