import { createAction, props } from '@ngrx/store';
import { GetSubordinatesWithPhaseStatusesForManagerResponse } from 'src/commonclasses';

export const RetrieveSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] retrieve subordinates with phase statuses from service',
  props<{
    getStrucureSubordinates?: boolean
  }>()
);

export const SetSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] set subordinates with phase statuses',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
);

export const RetrieveCppSuboardinates = createAction(
  '[peopleAppraisal] retrieve cpp subordinates',
  props<{
    roundId?: string
  }>()
);

export const RetrieveRound = createAction(
  '[peopleAppraisal] retrieve round'
);

export const SetCppSuboardinates = createAction(
  '[peopleAppraisal] set cpp subordinates',
  props<{ payload: any[] }>()
);

export const SetRound = createAction(
  '[peopleAppraisal] set round',
  props<{ payload: any }>()
);

export const SetStructureSuboardinatesWithPhaseStatuses = createAction(
  '[peopleAppraisal] set structure subordinates with phase statuses',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
);

export const SetIsLoadingSubordinates = createAction(
  '[peopleAppraisal] set is loading subordinates',
  props<{ payload: boolean }>()
);

export const UpdateSelfAssessmentUserStatusType = createAction(
  '[peopleAppraisal] update selfAssessment user status type',
  props<{
    payload: {
      userId: string,
      userStatus: any
    }
  }>()
);

export const UpdateSelfAssessmentData = createAction(
  '[peopleAppraisal] update selfAssessment status'
);

export const SetUpdatedSelfAssessmentData = createAction(
  '[peopleAppraisal] set selfAssessment updated data',
  props<{ payload: GetSubordinatesWithPhaseStatusesForManagerResponse[] }>()
)

export const SetHeaderTeamClickedButton = createAction(
  '[peopleAppraisal] set header team clicked button',
  props<{ payload: boolean }>()
);

export const SetActiveUserId = createAction(
  '[peopleAppraisal] set active user id',
  props<{ payload: string }>()
)

export const RemoveActiveUserId = createAction(
  '[peopleAppraisal] remove active user id',
)