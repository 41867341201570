<div class="main-container" *ngIf="objectMap?.comment">
    <!-- Peerfeedback -->
    <ng-container>
        <div class="rectangle-container">
            <!-- Card-->
            <div class="card-container">
                <!-- response del peerfeedback -->

                <!-- [title]="userIdSelected == object.senderUserId ? ('feedbackSection.peerFeedback.PEER_FEEDBACK_OF' | translate) + '<b> ' + object.senderUser?.surname + ' '+ object.senderUser?.forename
            :('feedbackSection.peerFeedback.PEER_FEEDBACK_SUB' | translate) + '<b> ' + object.recipientUser?.surname + ' '+ object.recipientUser?.forename" -->
                <ng-container *ngIf="objectMap?.comment">

                    <!-- Peer feedback -->
                    <card-header [type]="type" [hasAvatar]="true" [disabled]="disabled"
                        [user]="objectMap.creationUser" (onMenuClicked)="emitOnOptionClicked($event)" [avatarRight]="(objectMap?.creationUser?.userId == loggedUser?.userId) || (object?.creationUserId == loggedUser?.userId)"
                        [title]="updateSideNav?('<b>Aggiornamento del </b>' + (objectMap?.date | date: 'dd/MM/YYYY')) :('<b>Commento del </b>' + (objectMap?.date | date: 'dd/MM/YYYY'))">
                    </card-header>

                    <!-- Linea -->
                    <div class=" divisor-line">
                    </div>

                    <div class="information-container">
                        <div class="information-text-container">
                            <!-- Text -->
                            <div class="margin-bottom-20">
                                <p class="information-title">{{objectMap.comment}}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>