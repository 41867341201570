import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfCareCareAttributesAreaTypes } from 'src/commonclasses';


export class CardElement {
  id: string = '';
  icon: string = '';
  title: string = '';
}

@Component({
  selector: 'card-radio',
  templateUrl: 'card-radio.component.html',
  styleUrls: ['./card-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardRadioComponent implements OnInit {
  items: CardElement[] = [
    {
      id: PerfCareCareAttributesAreaTypes.IMPROVEMENT_AREA,
      icon: '/assets/img/icons/radio/get-better.svg',
      title: 'competenceComfort.GET_BETTER'
    },
    {
      id: PerfCareCareAttributesAreaTypes.COMFORT_ZONE_AREA,
      icon: '/assets/img/icons/radio/comfort.svg',
      title: 'competenceComfort.COMFORT_ZONE'
    },
    {
      id: PerfCareCareAttributesAreaTypes.STRENGTH_AREA,
      icon: '/assets/img/icons/radio/feel-strong.svg',
      title: 'competenceComfort.STRONG'
    },
  ]
  @Input() bindValue: string = '';
  @Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
  }

  setValue(item: any) {
    this.bindValue = item.id;
    this.cdr.detectChanges();
    this.emitOnItemChange();
  }


  emitOnItemChange() {
    this.onItemChange.emit(this.bindValue);
  }

}