import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { TabDossier } from 'src/app/utils/classes.utils';

@Component({
  selector: 'tab-dossier',
  templateUrl: 'tab-dossier.component.html',
  styleUrls: ['./tab-dossier.component.scss']
  // tolto il change on push per problemi con l'attivazione delle tab
})
export class TabDossierComponent implements OnInit {
  @Input() tab?: TabDossier;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }


  emitOnModelChanged(data?: any) {
    this.onClick.emit(data);
  }
}