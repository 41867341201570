/*
 * Interceptor dell'autenticazione che, se l'utente è correttamente loggato e autenticato, setta il Token ad ogni richiesta ai servizi
*/

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, switchMap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import * as fromAuth from '../../auth/ngrx/auth.reducers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store<fromApp.AppState>) {
  }

  // Il metodo che esegue lo slice dallo State torna un Observable, il quale è un oggetto asincrono.
  // Per tale motivo dobbiamo concatenare la chiamata con lo switchMap
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('auth')
      // Con il select() eseguiamo una subscription allo State, e quindi si trigghera ogni volta che ci sono delle modifiche; per
      // ovviare a tale comportamento sfruttiamo il take(1). (Il metodo pipe() invece è richiesto da RxJS6+)
      .pipe(take(1),
        // switchMap() al posto di map() poiché, altrimenti, wrapperebbe il return "next.hanlde()" in un nuovo Observable.
        // Lo switchMap(), invece, utilizza direttamente il valore tornato, che di per sé è già un Observable
        switchMap((authState: fromAuth.AuthState) => {
          // Potrebbe essere stato fatto un refresh, di conseguenza non c'è più il dato all'interno dello State. Guardo quindi se ho il token in sessione, perché se ce l'avessi significa che l'utente è già autenticato
          let sessionStorageToken = sessionStorage.getItem('token');

          const headerSettings: any = {};

          for (const key of req.headers.keys()) {
            headerSettings[key] = req.headers.getAll(key);
          }
          if (authState.token || sessionStorageToken) {
            headerSettings['Authorization'] = 'Bearer ' + (authState.token ? authState.token : sessionStorageToken);
          }
          // Se il body è un form data sto eseguendo l'upload di uno o più file. Pertanto, non setto il content-type perché setterà da solo il content-type 'multipart/form-data;'
          if (!(req.body instanceof FormData)) {
            headerSettings['Content-Type'] = 'application/json';
          }
          const newHeader = new HttpHeaders(headerSettings);

          // Clono la richiesta iniziale per modificarla inserendoci il Token, e possiamo farlo in quanto siamo dentro la gestione dell'Observable, ed è tutto sincrono
          const changedReq = req.clone({
            headers: newHeader
          });

          // e la passo alla richiesta
          return next.handle(changedReq);
        }));
  }
}
