import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';

@Component({
  selector: 'app-admin-verify-upward-feedback',
  templateUrl: './verifyUpwardFeedback.component.html',
  styleUrls: ['./verifyUpwardFeedback.component.scss']
})
export class VerifyUpwardFeedbackComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  roundId: string = '';
  userId: string = '';
  isLoadingManagerData: boolean = false;
  getManagerData$: Subscription = new Subscription;
  listData: any;
  ratingData: any;
  globalAverage: number = 0;
  modulesData: any[] = [];
  chartInstances: any = {};
  showAllAccordion: boolean = false;
  isSendingReminders: boolean = false;
  isSharingManagerResult: boolean = false;
  isSettingManagerStatus: boolean = false;
  titleData?: { managerName: string; completed: any; total: any; };
  managerStatus: string = '';
  isShared: boolean = false;
  isVerified: boolean = false;


  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.roundId = params.roundId;
              this.userId = params.userId;

              this.getManagerData();
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  getManagerData() {
    this.isLoadingManagerData = true;

    if (this.getManagerData$) {
      this.getManagerData$.unsubscribe();
    }

    this.getManagerData$ = this.adminService.getUpwardFeedbackManagerResults({ roundId: this.roundId, managerId: this.userId })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf011",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.titleData = {
            managerName: data.response.surname + ' ' + data.response.forename,
            completed: data.response.numberOfFeedbackReceived,
            total: data.response.numberOfEvaluators
          }
          if (data.response.managerStatus) {
            this.managerStatus = data.response.managerStatus;
            this.isShared = this.managerStatus == 'SHARED';
            this.isVerified = this.managerStatus == 'CHECKED';
          }
          let aggregatedData = data.response?.aggregateData;
          if (aggregatedData) {
            this.listData = {};
            this.listData.strengths = [];
            if (aggregatedData?.openAnswers[0]) {
              this.listData.strengths = aggregatedData.openAnswers[0].answersText;
            }
            this.listData.development = [];
            if (aggregatedData?.openAnswers[1]) {
              this.listData.development = aggregatedData.openAnswers[1].answersText;
            }
            this.listData.notes = [];
            if (aggregatedData?.openAnswers[2]) {
              for (let k = 0; k < aggregatedData.openAnswers[2].answersText.length; k++) {
                if (aggregatedData.openAnswers[2].answersText[k].length > 0) {
                  this.listData.notes = aggregatedData.openAnswers[2].answersText;
                }
              }
            }
            this.listData.notes = this.listData.notes.filter((x: string) => x.length > 0)
            // recupero dati per il grafo
            this.ratingData = []
            if (aggregatedData && aggregatedData.modulesAverage && aggregatedData.modulesAverage.modules) {
              for (let i = 0; i < aggregatedData.modulesAverage.modules.length; i++) {
                this.ratingData.push({
                  id: i + '',
                  title: aggregatedData.modulesAverage.modules[i].moduleTitle,
                  value: aggregatedData.modulesAverage.modules[i].moduleAverage
                })
              }
            }
            this.globalAverage = aggregatedData.modulesAverage.globalAverage || 0;

            this.modulesData = aggregatedData.closedAnswers || [];
            for (let i = 0; i < this.modulesData.length; i++) {
              for (let j = 0; j < this.modulesData[i].questions.length; j++) {
                this.modulesData[i].questions[j].graphData = this.getDataForGraph(this.modulesData[i].questions[j].answersByScore)
              }
            }
          }
          this.isLoadingManagerData = false;
        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf012",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingManagerData = false;
      })
  }

  getSurveyStatusTitle() {
    if (!this.isLoadingManagerData && this.titleData) {
      return this.translate.instant('upwardFeedback.admin.verify.SURVEY_COMPLETED') + this.titleData.completed + this.translate.instant('upwardFeedback.admin.verify.OF') + this.titleData.total;
    } else {
      return this.translate.instant('upwardFeedback.admin.verify.SURVEY_COMPLETED');
    }
  }

  getDataForGraph(data: any) {
    let tmp = [];
    for (let i = 0; i < data.length || i < 5; i++) {
      tmp.push({
        title: i + 1,
        value: data[i] || 0
      })
    }
    return tmp;
  }

  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
    setTimeout(() => {
      this.chartInstances[name].render();
    }, 500);
  }

  redrawGraph(module: string) {
    let keys = Object.keys(this.chartInstances);
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes(module)) {
        setTimeout(() => {
          this.chartInstances[keys[i]].render();
        }, 100);
      }
    }
  }

  toggleAccordion() {
    this.showAllAccordion = !this.showAllAccordion;
  }


  openSendReminderModal() {
    this.modalService.open('send-reminders');
  }

  closeSendRemindersModal(confirm?: boolean) {
    this.modalService.close('send-reminders');
    if (confirm) {
      this.sentRemainingReminders();
    }
  }

  sentRemainingReminders() {
    this.isSendingReminders = true;
    this.adminService.sendReminderToRemainingEvaluators({ roundId: this.roundId, managerId: this.userId })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf013",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // toaster
          let x: any = document.getElementById("snackbar-reminder");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        this.isSendingReminders = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf014",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isSendingReminders = false;
      });
  }

  openSetManagerAsVerifiedModal() {
    this.modalService.open('set-as-verified');
  }

  closeSetAsVerifiedModal(confirm?: boolean) {
    this.modalService.close('set-as-verified');
    if (confirm) {
      this.setManagerAsVerified();
    }
  }

  setManagerAsVerified() {
    this.isSettingManagerStatus = true;

    this.adminService.changeUpwardFeedbackManagerStatus({ roundId: this.roundId, userId: this.userId, status: 'CHECKED' })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf013",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          this.redirectService.goBackBrowser();
        }
        this.isSettingManagerStatus = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf014",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isSettingManagerStatus = false;
      });
  }

  shareManagerResults() {
    this.isSharingManagerResult = true;

    this.adminService.shareUpwardFeedbackResultsWithManager({ roundId: this.roundId, userIds: this.userId })
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf013",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          const messageObj: ApplicationModalMessage = {
            modalId: "upf017",
            title: this.translate.instant("upwardFeedback.admin.modals.CONFIRM_SHARE_TITLE"),
            text: this.translate.instant("upwardFeedback.admin.modals.CONFIRM_SHARE_DESCR"),
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        }
        this.isSharingManagerResult = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "upf014",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isSharingManagerResult = false;
      });
  }

  openShareManagerResults() {
    this.modalService.open('confirm-share-results');
  }

  closeShareManagerResults(confirm?: boolean) {
    this.modalService.close('confirm-share-results');
    if (confirm) {
      this.shareManagerResults();
    }
  }

  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}