import { ChangeDetectionStrategy, Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'input-container',
  templateUrl: 'input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContainerComponent implements OnInit {
  @Input() title: string = '';
  @Input() isLoading: boolean = false;
  @Input() tooltipModalMessage?: ApplicationModalMessage;
  @Input() isObbligatory: boolean = false;
  @Input() isWithoutInfoIcon: boolean = false;
  @Input() instructions: string = '';
  @Input() disabled: boolean = false;
  @Input() errorText: string = '';
  @Input() readonly: boolean = false;
  @Input() readonlyPlatform: boolean = false;
  @Input() readonlyPlatformInput: boolean = false;
  @Input() readonlyPlatformSelect: boolean = false;
  @Input() isValued: any = false;
  @Input() isFromTextArea: boolean = false;
  @Input() numberCharsTextArea: number = 0;
  @Input() isCpp:boolean = false;
  @Input() fromEuropUpload: boolean = false;
  @Input() isCreateObjective: boolean = false;
  @Input() moreActions: any;
  @Input() chargeButtonDisabled: boolean = false;
  @Input() isUpwardFeedback: boolean = false;
  @Input() upwardDisabled: boolean = false;
  @Input() whiteBackground: boolean = false;
  @Output() renderInputEditable: EventEmitter<any> = new EventEmitter<any>();
  @Output() renderSelectEditable: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteObjective: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
  }

  // Apre la modale info
  openModalMessage() {
    if (this.tooltipModalMessage) {
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.tooltipModalMessage }));
    }
  }

  // Rende l'input editabile
  inputToEdit() {
    this.readonlyPlatformInput = false;
    this.renderInputEditable.emit();
  }

  // Rende la select editabile
  selectToEdit() {
    this.readonlyPlatformSelect = false;
    this.renderSelectEditable.emit();
  }

  onDeleteObjective() {
    this.deleteObjective.emit();
  }

  onEmitAction(id: any) {
    this.emitAction.emit(id);
  }

}
