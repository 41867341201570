<page-container [ngClass]="{'impersonate-page-container-height': isImpersonate}">

  <div class="sidenav-information">
    <sidenav-informations (onBackFunction)="redirectService.goToHome()" [isBackButton]="true"
      [title]="('profile.TITLE' | translate)" [description]="('profile.DESCR' | translate)">
    </sidenav-informations>
  </div>
  <div class="main-content with-fixed-sidenav profile">

    <ng-container *ngIf="!isLoadingUser && loggedUser">
      <div class="profile-content-wrapper">
        <div class="avatar-container">
          <avatar-img [user]="loggedUser" size="profile"></avatar-img>
          <p class="change-image" (click)="onChangeImage()" translate="profile.CHANGE_IMAGE"></p>
          <p class="username">{{ loggedUser.forename + ' ' + loggedUser.surname }}</p>
          <p class="mail">{{ loggedUser.email }}</p>
          <!--p class="account-type" [innerHTML]="('profile.MANAGER_ACCOUNT' | translate)"></p-->
          <!--ng-container *ngIf="isSelfAssessment"-->
          <ng-container *ngIf="false">
            <div class="hole bottom-sx"></div>
            <div class="hole bottom-dx"></div>
            <div class="link sx"></div>
            <div class="link dx"></div>
          </ng-container>
        </div>
        <!--ng-container *ngIf="isSelfAssessment"-->
        <ng-container *ngIf="false">
          <div class="badge-container">
            <div class="hole top-sx"></div>
            <div class="hole top-dx"></div>
            <p class="title" translate="{{ 'profile.MY_BADGES' | translate }}"></p>
            <p class="how-works" (click)="openBadgeInfoModal()" translate="{{ 'profile.HOW_WORKS' | translate }}"></p>
            <ng-container *ngIf="!isLoadingBadges">
              <div class="section">
                <p class="section-title" translate="profile.FIRST_LEVEL"></p>
                <div class="section-content" *ngIf="badgeObject && badgeObject[1]">
                  <badge-wrapper class="margin-bottom32" *ngFor="let badge of badgeObject[1]" [badge]="badge"
                    (onClick)="openBadgeDetails(badge)"></badge-wrapper>
                </div>
              </div>
              <div class="section">
                <p class="section-title" translate="profile.SECOND_LEVEL"></p>
                <div class="section-content" *ngIf="badgeObject && badgeObject[2]">
                  <badge-wrapper class="margin-bottom32" *ngFor="let badge of badgeObject[2]" [badge]="badge"
                    (onClick)="openBadgeDetails(badge)"></badge-wrapper>
                </div>
              </div>
              <div class="section">
                <p class="section-title" translate="profile.THIRD_LEVEL"></p>
                <div class="section-content" *ngIf="badgeObject && badgeObject[3]">
                  <badge-wrapper class="margin-bottom32" *ngFor="let badge of badgeObject[3]" [badge]="badge"
                    (onClick)="openBadgeDetails(badge)"></badge-wrapper>
                </div>
              </div>
              <div class="section">
                <p class="section-title" translate="profile.GOLDEN"></p>
                <div class="section-content" *ngIf="badgeObject && badgeObject[100]">
                  <badge-wrapper class="margin-bottom32" *ngFor="let badge of badgeObject[100]" [badge]="badge"
                    (onClick)="openBadgeDetails(badge)"></badge-wrapper>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isLoadingBadges">
              <div class="loader spinner-big"></div>
            </ng-container>
          </div>
          <div class="bottom-margin"></div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadingUser">
      <div class="loader-container">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>
  </div>
</page-container>

<!-- Modale aggiunta/modifica file e link -->
<modal id="changeImage" [isDisabled]="!fileObject || !fileObject.fileExtension" (onClose)="closeChangeImageModal()"
  (onConfirm)="closeChangeImageModal(true)" [confirmLabel]="('generic.CONFIRM' | translate)">
  <div class="file-upload-modal-wrapper">
    <p class="title center" translate="profile.modals.CHANGE_PROFILE_IMAGE"></p>
    <input-container [instructions]="'profile.modals.FILES_ACCEPTED' | translate" [isValued]="fileObject"
      [isWithoutInfoIcon]="true" [isObbligatory]="true" title="{{'profile.modals.UPLOAD_FILE_IN_AREA' | translate}}">
      <alloy-upload [isProfileUpload]="true" [isToReset]="newImage" (onUpload)="assignFile($event)">
      </alloy-upload>
    </input-container>
  </div>
</modal>

<modal id="badge-details" (onClose)="closeBadgeDetails()" (onConfirm)="closeBadgeDetails()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <modal-text-content *ngIf="badgeForModal" [badge]="badgeForModal"></modal-text-content>
</modal>

<!-- Modale aggiunta/modifica file e link -->
<modal id="badge-info" (onClose)="closeBadgeInfoModal()" (onConfirm)="closeBadgeInfoModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <div class="modal-badge-info-wrapper">
    <p class="title center" translate="{{'badges.infoModal.TITLE' | translate }}"></p>
    <div class="level-wrapper">
      <div class="level">
        <div class="title-wrapper">
          <p class="level-title" translate="{{'badges.infoModal.FIRST_LEVEL_TITLE' | translate}}"></p>
          <div class="star-container">
            <svg-icon src="assets/img/icons/star-filled.svg" [applyClass]="true" class="star-icon">
            </svg-icon>
          </div>
        </div>
        <p class="level-description" [innerHTML]="('badges.infoModal.firstLevel' | translate)"></p>
      </div>
      <div class="level">
        <div class="title-wrapper">
          <p class="level-title" translate="{{('badges.infoModal.SECOND_LEVEL_TITLE' | translate)}}"></p>
          <div class="star-container">
            <svg-icon *ngFor="let number of [0,1]" src="assets/img/icons/star-filled.svg" [applyClass]="true"
              class="star-icon">
            </svg-icon>
          </div>
        </div>
        <p class="level-description" [innerHTML]="('badges.infoModal.secondLevel' | translate)"></p>
      </div>
      <div class="level">
        <div class="title-wrapper">
          <p class="level-title" translate="{{('badges.infoModal.THIRD_LEVEL_TITLE' | translate)}}"></p>
          <div class="star-container">
            <svg-icon *ngFor="let number of [0,1,2]" src="assets/img/icons/star-filled.svg" [applyClass]="true"
              class="star-icon">
            </svg-icon>
          </div>
        </div>
        <p class="level-description" [innerHTML]="('badges.infoModal.thirdLevel' | translate)"></p>
      </div>
      <div class="level">
        <div class="title-wrapper">
          <p class="level-title" translate="{{('badges.infoModal.GOLDEN_BADGE' | translate)}}"></p>
          <div class="star-container">
            <svg-icon *ngFor="let number of [0,1,2,3,4]" src="assets/img/icons/star-filled.svg" [applyClass]="true"
              class="star-icon">
            </svg-icon>
          </div>
        </div>
        <p class="level-description" [innerHTML]="('badges.infoModal.goldenLevel' | translate)"></p>
      </div>
    </div>
  </div>
</modal>