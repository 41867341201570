import { createAction, props } from '@ngrx/store';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { ApplicationModalMessage } from './core.reducers';
import { PerfCareNotification } from 'src/commonclasses';
import { BadgeObject } from 'src/app/profilePage/profilePage.component';

export const SetCoreApplicationData = createAction(
  '[core] update user',
  props<{ payload: GlobalApplicationData }>()
);

export const SaveRedirectUrl = createAction(
  '[core] save redirect url',
  props<{ payload: string }>()
);
export const SetApplicationLang = createAction(
  '[core] set application lang',
  props<{ payload: string }>()
);
export const SetDefaultLang = createAction(
  '[core] set default lang',
  props<{ payload: string }>()
);
export const OpenMainMenuSidebar = createAction(
  '[core] open main menu sidebar'
);
export const CloseMainMenuSidebar = createAction(
  '[core] close main menu sidebar'
);
export const GetAvailableLangs = createAction(
  '[core] get available langs'
);
export const RemoveApplicationLang = createAction(
  '[core] remove application lang'
);
export const GetAvailableLangsFinished = createAction(
  '[core] get available langs finished'
);
export const StartRenewTokenPolling = createAction(
  '[core] start renew token polling',
  props<{ payload?: { redirectUrl?: string, forceRefreshUser?: boolean } }>()
);
export const RemoveCoreApplicationData = createAction(
  '[core] remove core application data'
);
export const RemoveRedirectUrl = createAction(
  '[core] remove redirect url'
);
export const ShowApplicationLoader = createAction(
  '[core] show application loader'
);
export const HideApplicationLoader = createAction(
  '[core] hide application loader'
);
export const SetNotificationsCounter = createAction(
  '[core] set notifications counter',
  props<{ payload: number }>()
);
export const SetNotificationsInitials = createAction(
  '[core] set notifications initials',
  props<{ payload: PerfCareNotification[] }>()
);
export const StartCountNotifications = createAction(
  '[core] start count notifications'
);
export const StartGetNotificationsInitials = createAction(
  '[core] start get notifications initials'
);
export const SetApplicationModalMessage = createAction(
  '[core] set application modal message',
  props<{ payload: ApplicationModalMessage }>()
);
export const DeleteApplicationModalMessage = createAction(
  '[core] delete application modal message'
);
export const SetRunningYear = createAction(
  '[core] set running year',
  props<{ payload: any }>()
);
export const SetRound = createAction(
  '[core] set round',
  props<{ payload: any }>()
);
export const GetRunningYear = createAction(
  '[core] get running year'
);
export const SetRunningPhase = createAction(
  '[core] set running phase',
  props<{ payload: string }>()
);
export const GetRunningPhase = createAction(
  '[core] get running phase'
);
export const GetRound = createAction(
  '[core] get round'
);
export const SetApplicationModalBadge = createAction(
  '[core] set application modal badge',
  props<{ payload: BadgeObject[] }>()
);
export const DeleteApplicationModalBadge = createAction(
  '[core] delete application modal badge',
  props<{ payload: string }>()
);
export const SetImpersonificationBanner = createAction(
  '[core] set impersonate banner',
  props<{ payload: boolean }>()
);