import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from 'src/app/ngrx/util';
import { RequiredAuth } from 'src/commonclasses';

const USERS_AUTH = [
  RequiredAuth.PERFORMANCECARE_USER_COMMON,
  RequiredAuth.PERFORMANCECARE_USER_STAFF,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_PROFESSIONAL,
  RequiredAuth.PERFORMANCECARE_USER_STAFF_STI_RETAIL,
  RequiredAuth.PERFORMANCECARE_USER_OPERATIONS
]

@Injectable()
export class SelfAssessmentGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => {
          return this.store.select(fromApp.getIsSelfAssessment);
        }),
        onceIfNotEmpty(),
        map(isSelfAssessment => {
          if (isSelfAssessment) {
            return true;
          } else {
            this.router.navigate(["/403"]);
            return false;
          }
        })
      );
  }
}


/*


    */