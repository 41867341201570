import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { AdminService } from 'src/app/shared/services/admin.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { JwtPayload, SenecaResponse } from 'src/commonclasses';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UrlService } from 'src/app/shared/services/url.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { SlowBuffer } from 'buffer';

@Component({
  selector: 'app-admin-monitoring-matrix',
  templateUrl: './monitoring-matrix.component.html',
  styleUrls: ['./monitoring-matrix.component.scss']
})
export class MonitoringMatrixComponent implements OnDestroy {
  combinedSelected$: Subscription;
  loggedUser: any;
  currentYear = new Date().getFullYear();

  menuOptions: any[] = [];
  translations: any;

  searchedText: string = '';
  usersNumRecords: number = 100;
  usersFromRecord: number = 0;
  usersCurrentPage: number = 1;
  usersCounter: number = 0;

  isLoadingUsers: boolean = false;

  peopleAppraisalIds: any = [];

  matrixData: any = {};
  keyList = [
    "LOW",
    "DEVELOPING",
    "SOLID",
    "ADVANCE",
    "EXCEPTIONAL"
  ]
  selectedUser: any;
  peopleAppraisal: any;
  userList: any;
  confirmCalibration$: any;
  noData: boolean = false;
  totalUsers: any;
  grayBox: number = 0;
  yellowBox: number = 0;
  greenBox: number = 0;
  isDownloadingReport: boolean = false;
  downloadReport$: Subscription = new Subscription;
  isDownloadingSimulationReport: boolean = false;
  downloadSimulationReport$: Subscription = new Subscription;
  filtersText: string = '';
  processYear: number = 0;
  processYearCode: string = '';
  totalUserIds: any[] = [];
  userIdList: any;
  payoutResponse: any;
  isLoadingPayoutOptions: boolean = false;
  getUsersList$: Subscription = new Subscription;
  saveSchedule$: any;
  selectedUserList: any[] = [];
  massiveModalText: string = '';
  teamsOfUserIds: string[] = [];

  constructor(
    private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router,
    public redirectService: RedirectService,
    private authService: AuthService,
    private adminService: AdminService,
    public route: ActivatedRoute,
    public modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private urlService: UrlService,
    private analyticsService: AnalyticsService
  ) {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    //this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Monitoraggio - Matrice di comparazione");

    const filtersText$: Observable<string> = this.store.select(fromApp.getFiltersText);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    this.combinedSelected$ = combineLatest([loggedUser$, filtersText$])
      .subscribe(
        ([loggedUser, filtersText]) => {
          if (loggedUser && loggedUser.user) {
            this.loggedUser = loggedUser && loggedUser.user;

            const sessionPeopleAppraisal = sessionStorage.getItem('peopleAppraisal');
            if (sessionPeopleAppraisal) {
              const tmpIdList = JSON.parse(sessionPeopleAppraisal || '');
              // recupero la lista utenti (selezione libera)
              if (tmpIdList && tmpIdList.length) {
                this.peopleAppraisalIds = tmpIdList;
              } else {
                this.peopleAppraisalIds = null;
              }
            }
            const sessionIds = sessionStorage.getItem('userIdList');
            if (sessionIds) {
              const tmpIdList = JSON.parse(sessionIds || '');
              // recupero la lista utenti (selezione libera)
              if (tmpIdList && tmpIdList.length) {
                this.userIdList = tmpIdList;
              } else {
                this.userIdList = null;
              }
            }

            this.filtersText = filtersText;

            this.matrixData = {
              "LOW": [],
              "DEVELOPING": [],
              "SOLID": [],
              "ADVANCE": [],
              "EXCEPTIONAL": [],
            }
            this.route.params.subscribe((params: Params) => {
              this.processYear = params.processYear
              this.processYearCode = params.processYearCode
              this.getUsersList();
              // TODO sostituire se serve 
              // this.getPayoutOptions()
            })
          }
        });
  }

  ngOnInit() {
    this.translate.get([
      'calibration.menu.ACCESS',
      'calibration.menu.SHOW',
      'calibration.EDIT_PL',
      'verifySend.modals.SEND_DESCR_MANY1',
      'verifySend.modals.SEND_DESCR_MANY2',
      'calibration.ACCESS_USER_DATA'
    ]).subscribe((translations) => {
      this.translations = translations;
      this.menuOptions = [
        {
          id: 'edit',
          title: translations['calibration.EDIT_PL'],
          icon: '/assets/img/icons/edit.svg',
        },
        {
          id: 'access',
          title: translations['calibration.ACCESS_USER_DATA'],
          icon: '/assets/img/icons/clipboard.svg',
        }
      ]
      this.cdr.detectChanges();
    })
  }

  trackByUserId(index: number, el: any) {
    return el.userId;
  }

  // Apre il menù con le opzioni per il processo
  openOptionsMenu(process: any) {
    process.isMenuOpen = !process.isMenuOpen;
  }

  // ricerca
  searchedTextChange(text: string) {
    this.searchedText = text;
  }

  onSearch() {
    this.filterList();
  }

  filterList() {
    this.isLoadingUsers = true;
    let tempMatrix: any = {
      "LOW": [],
      "DEVELOPING": [],
      "SOLID": [],
      "ADVANCE": [],
      "EXCEPTIONAL": [],
    }
    if (this.searchedText && this.searchedText.length) {
      let tempSearch = this.searchedText.toLowerCase();
      if (this.userList && this.userList.length) {
        this.totalUsers = 0;
        for (let i = 0; i < this.userList.length; i++) {
          let user = this.userList[i];
          if (user.forename.toLowerCase().includes(tempSearch) || user.surname.toLowerCase().includes(tempSearch)) {
            this.totalUsers++;
            this.totalUserIds.push(user.userId)
            let ratingKey = user.managerEvaluation.pasProfile.split('_')[0];
            user.status = this.translate.instant("evaluations.UNCHANGED");
            user.originalRatingKey = ratingKey;
            user.appraisalValue = (Math.floor(user.managerEvaluation.pasAvgEvaluation * 100) / 100).toFixed(2);
            user.selfAssessmentlValue = (Math.floor(user.selfEvaluation.pasAvgEvaluation * 100) / 100).toFixed(2);
            user.deltaValue = (user.selfAssessmentlValue - user.appraisalValue).toFixed(1) || "0.0";
            user.deltaValueAbs = Math.abs(user.deltaValue).toFixed(1) || 0.0;
            tempMatrix[ratingKey].push(user);
          }
        }
        this.noData = true;
        let check = this.userList.map((user: any) => user.postCalibrationStatus != 'WAITING');
        for (let i = 0; i < check.length; i++) {
          if (check[i]) {
            this.noData = false;
          }
        }
      }
      this.matrixData = tempMatrix;
    } else {
      this.formatMatrixData();
    }
    this.isLoadingUsers = false;
  }

  // Ripristina i dai della lista utenti
  resetUserData() {
    this.usersFromRecord = 0;
    this.usersCurrentPage = 1;
    this.usersCounter = 0;
    this.matrixData = {
      "LOW": [],
      "DEVELOPING": [],
      "SOLID": [],
      "ADVANCE": [],
      "EXCEPTIONAL": [],
    }
  }

  getUsersList(fromSearch?: boolean) {
    this.isLoadingUsers = true;

    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }

    let serviceCall;
    if (!this.userIdList || !this.userIdList.length) {
      serviceCall = this.adminService.getMonitoringMatrixUsersForAdmin(this.processYear, this.processYearCode, [], this.peopleAppraisalIds)
    } else {
      serviceCall = this.adminService.getMonitoringMatrixUsersForAdmin(this.processYear, this.processYearCode, this.userIdList)
    }

    this.getUsersList$ = serviceCall
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "matrix001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          this.userList = data.response;
          this.formatMatrixData()
        }

      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "matrix002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      });
  }

  // Formatta i dati per la matrice e controlla se ci sono utenti validi
  formatMatrixData() {
    this.totalUsers = 0;
    this.totalUserIds = [];
    this.matrixData = {
      "LOW": [],
      "DEVELOPING": [],
      "SOLID": [],
      "ADVANCE": [],
      "EXCEPTIONAL": [],
    }
    if (this.userList && this.userList.length) {
      for (let i = 0; i < this.userList.length; i++) {
        let user = this.userList[i];
        this.totalUsers++;
        this.totalUserIds.push(user.userId)
        let ratingKey = user.managerEvaluation.pasProfile.split('_')[0];
        user.status = this.translate.instant("evaluations.UNCHANGED");
        user.originalRatingKey = ratingKey;
        user.appraisalValue = (Math.floor(user.managerEvaluation.pasAvgEvaluation * 100) / 100).toFixed(2);
        user.selfAssessmentlValue = (Math.floor(user.selfEvaluation.pasAvgEvaluation * 100) / 100).toFixed(2);
        user.deltaValue = (user.selfAssessmentlValue - user.appraisalValue).toFixed(1) || "0.0";
        user.deltaValueAbs = Math.abs(user.deltaValue).toFixed(1) || 0.0;
        this.matrixData[ratingKey].push(user);
      }

      this.grayBox = Math.round(((this.matrixData["LOW"].length + this.matrixData["DEVELOPING"].length) / this.totalUsers) * 100) || 0;
      this.yellowBox = Math.round((this.matrixData["SOLID"].length / this.totalUsers) * 100) || 0;
      this.greenBox = Math.round(((this.matrixData["ADVANCE"].length + this.matrixData["EXCEPTIONAL"].length) / this.totalUsers) * 100) || 0;

      this.noData = true;
      let check = this.userList.map((user: any) => user.postCalibrationStatus != 'WAITING');
      for (let i = 0; i < check.length; i++) {
        if (check[i]) {
          this.noData = false;
        }
      }
    }
    this.isLoadingUsers = false;
  }

  // Gestisce lo spostamento dell'utente da una colonna all'altra
  // Nel caso in cui venga spostato in una colonna diversa dall'originale
  // salvo la nuova valutazione
  drop(event: CdkDragDrop<any[]>, key: string) {
    if (this.selectedUser.originalRatingKey != key) {
      this.selectedUser.edited = true;
      this.selectedUser.status = this.translate.instant("evaluations.CHANGED");
    } else {
      this.selectedUser.edited = false;
      this.selectedUser.status = this.translate.instant("evaluations.UNCHANGED");
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.grayBox = Math.round(((this.matrixData["LOW"].length + this.matrixData["DEVELOPING"].length) / this.totalUsers) * 100) || 0;
      this.yellowBox = Math.round((this.matrixData["SOLID"].length / this.totalUsers) * 100) || 0;
      this.greenBox = Math.round(((this.matrixData["ADVANCE"].length + this.matrixData["EXCEPTIONAL"].length) / this.totalUsers) * 100) || 0;
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.grayBox = Math.round(((this.matrixData["LOW"].length + this.matrixData["DEVELOPING"].length) / this.totalUsers) * 100) || 0;
      this.yellowBox = Math.round((this.matrixData["SOLID"].length / this.totalUsers) * 100) || 0;
      this.greenBox = Math.round(((this.matrixData["ADVANCE"].length + this.matrixData["EXCEPTIONAL"].length) / this.totalUsers) * 100) || 0;
      // let calibration = {
      //   calibrationId: this.selectedUser.calibrationId,
      //   userId: this.selectedUser.userId,
      //   adminObservation: this.selectedUser.peopleAppraisalObservation,
      //   adminObservationLevel: key,
      //   payout: payout,
      //   confirmed: false
      // }

      return;
    }
  }

  // Ritorna le liste collegate alla colonna attuale
  getConnectedList(listKey: string) {
    let connectedList: any = [];
    for (let i = 0; i < this.keyList.length; i++) {
      if (this.keyList[i] != listKey) {
        connectedList.push('column-' + this.keyList[i]);
      }
    }
    return connectedList;
  }

  // Quando comincio a trascinare un utente lo salvo, in questo modo posso salvare le modifiche
  setSelected(user: any) {
    this.selectedUser = user;
  }

  // Salvo ad ogni spostamento degli utenti, quindi il conferma sessione di fatto è un back
  saveSchedule() {
    if (this.saveSchedule$) {
      this.saveSchedule$.unsubscribe();
    }

    let validUserIds = [];
    for (let i = 0; i < this.userList.length; i++) {
      if (!this.userList[i].postCalibrationSent) {
        validUserIds.push(this.userList[i].userId);
      }
    }
    this.saveSchedule$ = this.adminService.savePostCalibrationFormForAdmin(validUserIds, null, false)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "save001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          // toaster 
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
        }
        this.redirectService.goBackBrowser();
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "save002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    let idList = this.userList.map((user: any) => user.userId);
    if (this.userList.length) {
      let idList = this.userList.map((user: any) => user.userId);
      this.downloadReport$ = this.adminService.downloadMatrixReport(this.processYear, this.processYearCode, idList)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          } else {
            let filename = data.response;
            this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
              if (data && data.response) {
                let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
                setTimeout(() => {
                  window.open(downloadUrl, '_blank');
                }, 500)
                this.isDownloadingReport = false;
              } else {
                const messageObj: ApplicationModalMessage = {
                  modalId: "a007",
                  text: this.translate.instant("errors." + data && data.error),
                  title: this.translate.instant("generic.WARNING")
                }
                this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
                this.isDownloadingReport = false;
              }
            }, (err: any) => {
              const messageObj: ApplicationModalMessage = {
                modalId: "a008",
                text: this.translate.instant("errors." + ((err && err.message) || err)),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            })

          }
        }, (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a005",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        })
    }
  }

  downloadSimulationReport() {
    this.isDownloadingSimulationReport = true;
    if (this.downloadSimulationReport$) {
      this.downloadSimulationReport$.unsubscribe();
    }

    let simulationData = [];
    let keys = Object.keys(this.matrixData);

    for (let i = 0; i < keys.length; i++) {
      if (this.matrixData[keys[i]] && this.matrixData[keys[i]].length) {
        for (let j = 0; j < this.matrixData[keys[i]].length; j++) {
          simulationData.push({
            userId: this.matrixData[keys[i]][j].userId,
            pasProfile: keys[i] + '_PERFORMER'
          })
        }
      }
    }

    this.downloadSimulationReport$ = this.adminService.downloadSimulatedMatrixReportForAdmin(this.processYear, this.processYearCode, simulationData)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingSimulationReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingSimulationReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            }
            this.isDownloadingSimulationReport = false;
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingSimulationReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingSimulationReport = false;
      })
  }

  openBoxDistributionTooltip() {
    this.modalService.open('box-distribution-tooltip');
  }

  closeBoxDistributionTooltip() {
    this.modalService.close('box-distribution-tooltip');
  }

  // per gestire clickOutside
  closeUserMenu(user: any) {
    user.isMenuOpen = false;
  }

  // Apre menu utente
  openUserMenu(user: any) {
    user.isMenuOpen = !user.isMenuOpen;
  }

  impersonate(userId: string) {
    this.isLoadingUsers = true;
    this.adminService.impersonateUserForAdmin(userId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a012",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else {
          let url = this.urlService.getApplicationUrl();
          // const redirectUrl = 
          let redirectUrl = '';
          if (window.location.href.indexOf('localhost') >= 0) {
            redirectUrl = 'http://localhost:4200/#/impersonateRedirect?token=' + data.response;
          } else {
            redirectUrl = url.url + '/#/impersonateRedirect?token=' + data.response;
          }

          window.open(redirectUrl, '_blank');
        }
        this.isLoadingUsers = false;
      },
        (err) => {
          this.isLoadingUsers = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a013",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
        })
  }

  goToVerifySend(userId: string) {
    this.router.navigate(['admin/verifySendSchedule/', this.processYear, this.processYearCode, userId]);
  }

  openSaveScheduleModal() {
    this.modalService.open('save-schedule-modal');
  }

  closeSaveScheduleModal(confirm?: boolean) {
    this.modalService.close('save-schedule-modal');
    if (confirm) {
      this.saveSchedule();
    }
  }


  openSendScheduleModal() {
    this.selectedUserList = [];
    for (let i = 0; i < this.userList.length; i++) {
      if (!this.userList[i].postCalibrationSent) {
        this.selectedUserList.push(this.userList[i].userId);
      }
    }
    if (this.selectedUserList && this.selectedUserList.length > 1) {
      this.massiveModalText = this.translations['verifySend.modals.SEND_DESCR_MANY1'] + this.selectedUserList.length + this.translations['verifySend.modals.SEND_DESCR_MANY2'];
    }
    this.modalService.open('massive-send');
  }

  closeSendScheduleModal(confirm?: boolean) {
    this.modalService.close('massive-send');
    if (confirm) {
      this.sendSchedule();
    }
  }


  // Salvo ad ogni spostamento degli utenti, quindi il conferma sessione di fatto è un back
  sendSchedule() {
    if (this.saveSchedule$) {
      this.saveSchedule$.unsubscribe();
    }

    let validUserIds = [];
    for (let i = 0; i < this.userList.length; i++) {
      if (!this.userList[i].postCalibrationSent) {
        validUserIds.push(this.userList[i].userId);
      }
    }

    this.saveSchedule$ = this.adminService.savePostCalibrationFormForAdmin(validUserIds, null, true)
      .subscribe((data: any) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "save001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.redirectService.goBackBrowser();
        } else {
          // toaster 
          let x: any = document.getElementById("snackbar");
          if (x) {
            x.className = "show";
            setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
          }
          this.redirectService.goBackBrowser();
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "save002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.redirectService.goBackBrowser();
      });
  }

  ngOnDestroy() {
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    if (this.downloadSimulationReport$) {
      this.downloadSimulationReport$.unsubscribe();
    }
    if (this.confirmCalibration$) {
      this.confirmCalibration$.unsubscribe()
    }
    if (this.getUsersList$) {
      this.getUsersList$.unsubscribe();
    }
    if (this.combinedSelected$) {
      this.combinedSelected$.unsubscribe()
    }
  }
}
