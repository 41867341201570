<div class="competence-evaluation-container" id="{{ id + '-container' }}">
  <ng-container *ngIf="competenceAvg.category">
    <p class="competence-category">{{ competenceAvg?.category || '' }}</p>
  </ng-container>

  <ng-container>
    <div class="competence-data-container" id="{{ 'competenceAvgIndex' + id + '-container' }}">
      <!-- Lato sinistro con titolo e descrizione -->
      <div class="data-content">
        <ng-container *ngIf="competenceAvg.title">
          <div class="title-container">
            <p class="title">{{ competenceAvg.title }}</p>
          </div>
        </ng-container>
        <p class="description">{{competenceAvg.subtitle}}</p>
      </div>
      <div class="divisor-vertical"></div>

      <div class="right-content">
        <!-- Titoli categorie -->
        <ng-container *ngIf="titlesRightSection && competenceAvg.selfRatingStars && competenceAvg.ratingStars">
          <div class="main-title-container">
            <div class="title-section-container">
              <!-- <div class="divisor-vertical"></div> -->
              <div class="title-section left">
                <p class="title-left"> {{titlesRightSection.left}} </p>
              </div>
              <div class="divisor-vertical"></div>
              <div class="title-section right">
                <p class="title-left"> {{titlesRightSection.right}} </p>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- Rating competenza -->
        <ng-container *ngIf="!showSelfRating()">
          <star-rating id="{{ id + '-stars' }}" [forceRating]="competenceAvg.ratingStars" [scale]="ratingScale"
            [isRecapAverage]="true" [disabled]="disabled" [showFullNumber]="true"
            [fullNumber]="competenceAvg.ratingAvgString">
          </star-rating>
        </ng-container>


        <!-- Rating competenza (con recap)-->
        <ng-container *ngIf="showSelfRating()">
          <div class="rating-star-content">
            <star-rating [forceRating]="competenceAvg.selfRatingStars" customContainerClass="small-star-size"
              [grayColor]="true" [avgForceGray]="true" [scale]="ratingScale" [disabled]="true" [showFullNumber]="true"
              [fullNumber]="competenceAvg.selfRatingAvgString" [isRecapAverage]="true">
            </star-rating>

            <div class="divisor-vertical"></div>
            <star-rating [forceRating]="competenceAvg.ratingStars" customContainerClass="small-star-size"
              [scale]="ratingScale" [disabled]="disabled" [showFullNumber]="true" [isRecapAverage]="true"
              [fullNumber]="competenceAvg.ratingAvgString">
            </star-rating>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>
</div>