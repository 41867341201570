import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as fromApp from "../../../ngrx/app.reducers";
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Params, ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import { AdminService } from 'src/app/shared/services/admin.service';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { PerformanceAssessmentAlloyStatuses, SenecaResponse } from 'atfcore-commonclasses';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import moment = require('moment');

@Component({
  selector: 'app-admin-create-update-upward-feedback',
  templateUrl: './createUpdateUpwardFeedbackRound.component.html',
  styleUrls: ['./createUpdateUpwardFeedbackRound.component.scss']
})
export class CreateUpdateUpwardFeedbackComponent implements OnInit {
  runningYear$: Subscription = new Subscription();
  runningYear: any;
  roundId: string = '';
  roundData: any;
  isLoadingRound: boolean = false;
  statusOptions: any;
  getRoundData$: Subscription = new Subscription;
  isUpdate: boolean = false;
  // mezznotte di oggi per problema datapicker su giorno stesso
  today: Date = new Date(new Date().setHours(0, 0, 0, 0));

  constructor(
    public translate: TranslateService,
    public adminService: AdminService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    // Salvo l'anno corrente
    this.runningYear$ = this.store.select(fromApp.getRunningYear).subscribe((runningYear) => {
      this.runningYear = runningYear;
      if (this.runningYear) {
        this.route.params
          .subscribe(
            (params: Params) => {
              this.roundId = params.roundId;
              this.translate.get(
                [
                  'process.status.ACTIVE',
                  'process.status.STANDBY',
                  'process.status.CLOSED'
                ])
                .subscribe(translations => {
                  this.statusOptions = [
                    {
                      id: 'RUNNING',
                      title: translations['process.status.ACTIVE'],
                      disabled: true
                    },
                    {
                      id: 'STANDBY',
                      title: translations['process.status.STANDBY']
                    },
                    {
                      id: 'CLOSED',
                      title: translations['process.status.CLOSED']
                    }
                  ];
                  if (!this.roundId) {
                    this.roundData = {};
                    this.roundData.selectedStatus = this.statusOptions[1];
                    this.isLoadingRound = false;
                  } else {
                    this.isUpdate = true;
                    this.statusOptions[0].disabled = true;
                    this.statusOptions[1].disabled = true;
                    this.getRoundData();
                  }
                })
            }
          );
      }
    });
  }

  ngOnInit() {
  }

  nameChanged(text: string) {
    this.roundData.name = text;
  }

  startDateChanged(newDate: Date) {
    this.roundData.startDate = newDate;
  }

  endDateChanged(newDate: Date) {
    this.roundData.endDate = newDate;
  }

  changeStatus(statusItem: any) {
    this.roundData.selectedStatus = statusItem;
  }

  getRoundData() {
    this.isLoadingRound = true;

    if (this.getRoundData$) {
      this.getRoundData$.unsubscribe();
    }

    this.getRoundData$ = this.adminService.getUpwardFeedbackRounds(0, 0, [this.roundId])
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "057",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }))
        } else {
          if (data.response && data.response[0]) {
            this.roundData = data.response[0];
            this.roundData.selectedStatus = this.statusOptions.find((status: any) => status.id == this.roundData.roundStatus);
            if (moment().isSameOrAfter(this.roundData.startDate) && moment().isSameOrBefore(this.roundData.endDate)) {
              // Abilita l'attivazione del round
              this.statusOptions[0].disabled = false;
            }
          }
        }
        this.isLoadingRound = false;
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "058",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isLoadingRound = false;
      });
  }

  createUpdate() {
    if (this.roundId) {
      this.modalService.open('update-round');
    } else {
      this.modalService.open('create-round');
    }
  }

  closeCreateRound(confirm?: boolean) {
    this.modalService.close('create-round');
    if (confirm) {
      if (this.roundData.startDate && this.roundData.endDate) {
        this.isLoadingRound = true;
        this.adminService.createUpwardFeedbackRound(this.roundData.name, this.roundData.startDate, this.roundData.endDate)
          .subscribe((data: SenecaResponse<any>) => {
            if (data && data.error) {
              // Vedo se c'è la traduzione dell'errore
              const messageObj: ApplicationModalMessage = {
                modalId: "a003",
                text: this.translate.instant("errors." + data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            } else {
              // toaster 
              let x: any = document.getElementById("snackbar");
              if (x) {
                x.className = "show";
                setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
              }
            }
            this.redirectService.goBackBrowser();
          }, (err) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          })
      }
    }
  }

  closeUpdateRound(confirm?: boolean) {
    this.modalService.close('update-round');
    if (confirm) {
      this.isLoadingRound = true;
      this.adminService.updateUpwardFeedbackRound(this.roundData.roundId, this.roundData.name, this.roundData.startDate, this.roundData.endDate, this.roundData.selectedStatus.id)
        .subscribe((data: SenecaResponse<any>) => {
          if (data && data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // toaster 
            let x: any = document.getElementById("snackbar");
            if (x) {
              x.className = "show";
              setTimeout(() => { x.className = x.className.replace("show", ""); }, 2000);
            }
          }
          this.redirectService.goBackBrowser();
        }, (err) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + ((err && err.message) || err)),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        })
    }
  }

  onCancel() {
    this.redirectService.goBackBrowser();
  }


  ngOnDestroy() {
    if (this.runningYear$) {
      this.runningYear$.unsubscribe();
    }
  }
}