<div class="main">
    <div class="type-container">
        <svg-icon [src]="srcIcon" class="small-size" [applyClass]="true"></svg-icon>
        <p class="title">{{title}}</p>
        <item-list-team-status *ngIf="status" [type]="status == 'TO_START'?'inProgress':status == 'COMPLETED'? 'completed':''" [styleItem]="'fill'"
            [message]="('developmentPlan.'  + status)| translate"></item-list-team-status>
        <!-- Menu edit -->
        <ng-container>
            <div class="menu-btn" *ngIf="!disabled">
                <svg-icon src="assets/img/icons/more-horizontal.svg" [applyClass]="true" class="menu-icon"
                    (click)="openMenu(true)" (clickOutside)="openMenu(false)">
                </svg-icon>
                <!-- Dropdown -->
                <ng-container *ngIf="isMenuOpen">
                    <div class="dropdown-menu">
                        <ng-container *ngFor="let option of menuObject">
                            <alloy-dropdown-item (click)="disabledForUser?'':emitOnOptionClicked(option)"
                                class="full-width" [item]="option" [forceDisable]="option.disabled">
                            </alloy-dropdown-item>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div class="content">
        <div class="text-container">
            <!-- Tipologia -->
            <p class="text"> {{('developmentPlanCpp.TYPE' | translate) + ': '}}
                <span>{{type}}</span>
            </p>
            <!-- Scopo -->
            <p class="text"> {{('developmentPlanCpp.SCOPE' | translate) + ': '}}
                <span>{{scope}}</span>
            </p>
            <!-- Data fine -->
            <p class="text"> {{('developmentPlanCpp.DATE_END' | translate) + ': '}}
                <span>{{date | date: 'dd/MM/YYYY'}}</span>
            </p>
        </div>
        <div class="rowActions">
            <div class="position-relative" (click)="emitOnUpdatesClicked()">
                <alloy-button iconRight="assets/img/icons/message-circle.svg" label="Aggiornamenti" onButtonClicked=""
                    type="primary blu-link" size="medium">
                </alloy-button>
                <div class="rowActions__notify" [ngClass]="{'news' :updatesNumber > 0}">
                    <p>{{updatesNumber}}</p>
                </div>
            </div>
        </div>
    </div>
</div>