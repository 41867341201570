<!-- Barra di ricerca -->
<div class="search-filters-container">
  <ng-container *ngIf="searchEnabled">
    <div class="admin-search-container">
      <div class="search-wrapper">
        <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
          (onModelChanged)="emitSearchedTextChanged($event)" iconRight="assets/img/icons/search.svg"
          [placeholder]="searchByTextPlaceholder || ('generic.SEARCH_BY' | translate)" [isSearchBar]="true">
        </alloy-input>
      </div>
    </div>
  </ng-container>
  <!-- Recap filtri -->
  <div class="filters-recap-container">
    <div class="left-column">
      <ng-container *ngIf="!selectedFiltersNumber">
        <p class="placeholder">{{ noActiveFilterText || ('adminSearch.NO_FILTER_ACTIVE' | translate) }}</p>
      </ng-container>
      <ng-container *ngIf="!selectedFiltersNumber && searchedText && searchedText.length">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber === 1">
        <p class="placeholder" [innerHTML]="oneFilterText || ('adminSearch.ONE_FILTER' | translate)"></p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber > 1">
        <p class="placeholder"><b>{{selectedFiltersNumber}}</b>{{ moreFiltersText || (' ' + ('adminSearch.MORE_FILTERS'
          |
          translate)) }}</p>
      </ng-container>
      <ng-container *ngIf="selectedFiltersNumber >= 1">
        <p class="delete-filters" (click)="clearFilters(true)">{{ deleteFiltersText || ('adminSearch.DELETE_FILTERS' |
          translate) }}</p>
      </ng-container>
      <!--p class="placeholder" translate="adminSearch.ADVANCED_FILTER"></p-->
    </div>
    <div class="right-column" (click)="openFiltersSection()">
      <p class="manage-filters">{{ manageFiltersText || ('adminSearch.MANAGE_FILTERS' |
        translate) }}</p>
      <ng-container *ngIf="!isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-down.svg" [applyClass]="true"></svg-icon>
      </ng-container>
      <ng-container *ngIf="isFilterOpened">
        <svg-icon class="chevron" src="assets/img/icons/chevron-up.svg" [applyClass]="true"></svg-icon>
      </ng-container>
    </div>
  </div>
  <!-- contenitore filtri -->
  <ng-container *ngIf="isFilterOpened && filtersObject">
    <div class="filters-container">
      <div *ngIf="!isLoadingFilters" class="filters-content">
        <div class="generic-container" *ngIf="showSubCompany || showCdc">
          <!-- Subcompany -->
          <input-container *ngIf="showSubCompany" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.socDistacco"
            title="{{'monitoring.filters.SUBCOMPANY' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'socDistacco')" [selectedItem]="selectedFilters.socDistacco"
              [items]="filtersObject.socDistacco" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- CdcFinane -->
          <input-container *ngIf="showCdc" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.cdcFinance"
            title="{{'monitoring.filters.CDCFINANCE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'cdcFinance')" [selectedItem]="selectedFilters.cdcFinance"
              [items]="filtersObject.cdcFinance" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showAreaRef || showDepartment">
          <!-- Area reference -->
          <input-container *ngIf="showAreaRef" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.chief"
            title="{{'monitoring.filters.AREAREFERENCE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'chief')" [selectedItem]="selectedFilters.chief"
              [items]="filtersObject.chief" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- office-->
          <input-container *ngIf="isCpp && showOffice" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.sedeLavoro"
            title="{{'monitoring.filters.OFFICE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'sedeLavoro')" [selectedItem]="selectedFilters.sedeLavoro"
              [items]="filtersObject.sedeLavoro" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- Department -->
          <input-container *ngIf="!isCpp && showDepartment" [isWithoutInfoIcon]="true"
            [isValued]="selectedFilters.ramo" title="{{'monitoring.filters.DEPARTMENT' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'ramo')" [selectedItem]="selectedFilters.ramo"
              [items]="filtersObject.ramo" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showTeam || showPosition">
          <!-- team -->
          <input-container *ngIf="showTeam" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.team"
            title="{{'monitoring.filters.TEAM' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'team')" [selectedItem]="selectedFilters.team"
              [items]="filtersObject.team" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- position -->
          <input-container *ngIf="showPosition" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.ruolo"
            title="{{'monitoring.filters.POSITION' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'ruolo')" [selectedItem]="selectedFilters.ruolo"
              [items]="filtersObject.ruolo" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="showSupervisor || showContractual">
          <!-- supervisor -->
          <input-container *ngIf="showSupervisor" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.supervisor"
            title="{{'monitoring.filters.SUPERVISOR' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'supervisor')" [selectedItem]="selectedFilters.supervisor"
              [items]="filtersObject.supervisor" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- contractual framework -->
          <input-container *ngIf="showContractual" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.tipologiaContratto"
            title="{{'monitoring.filters.CONTRACTUAL' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'tipologiaContratto')" [selectedItem]="selectedFilters.tipologiaContratto"
              [items]="filtersObject.tipologiaContratto" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container" *ngIf="isCppDashboard">
          <!-- team -->
          <input-container [isWithoutInfoIcon]="true" [isValued]="selectedFilters.team"
            title="{{'monitoring.filters.TEAM' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'team')" [selectedItem]="selectedFilters.team"
              [items]="filtersObject.team" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- valutatore -->
          <input-container [isWithoutInfoIcon]="true" [isValued]="selectedFilters.supervisor"
            title="{{'monitoring.filters.SUPERVISOR' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'supervisor')" [selectedItem]="selectedFilters.supervisor"
              [items]="filtersObject.supervisor" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container"
          *ngIf="!this.isUpwardFeedback && !isCpp && !isCppDashboard && (showOffice || showSelfAssessmentStatus)">
          <!-- office-->
          <input-container *ngIf="showOffice" [isWithoutInfoIcon]="true" [isValued]="selectedFilters.sedeLavoro"
            title="{{'monitoring.filters.OFFICE' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'sedeLavoro')" [selectedItem]="selectedFilters.sedeLavoro"
              [items]="filtersObject.sedeLavoro" [searchable]="true" bindLabel="id">
            </alloy-select>
          </input-container>

          <!-- Stato self assessment -->
          <input-container *ngIf="showSelfAssessmentStatus" [isWithoutInfoIcon]="true"
            [isValued]="selectedFilters.selfStatus" title="{{'monitoring.filters.SELF_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'selfStatus')" [selectedItem]="selectedFilters.selfStatus"
              [items]="filtersObject.selfStatus" [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container"
          *ngIf="!this.isUpwardFeedback && !isCpp && !isCppDashboard && (showAppraisalStatus || showCalibrationStatus)">
          <!-- Stato appraisal -->
          <input-container *ngIf="showAppraisalStatus" [isWithoutInfoIcon]="true"
            [isValued]="selectedFilters.peopleStatus" title="{{'monitoring.filters.PEOPLE_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'peopleStatus')" [selectedItem]="selectedFilters.peopleStatus"
              [items]="filtersObject.peopleStatus" [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
          <!-- Stato calibration -->
          <input-container [isWithoutInfoIcon]="true" [isValued]="selectedFilters.calibrationStatus"
            title="{{'monitoring.filters.CALIBRATION_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'calibrationStatus')"
              [selectedItem]="selectedFilters.calibrationStatus" [items]="filtersObject.calibrationStatus"
              [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>

        <div class="generic-container">
          <!-- Stato upwardFeedback -->
          <input-container *ngIf="isUpwardFeedback" [isWithoutInfoIcon]="true" [ngStyle.gt-sm]="{'max-width': '49%'}"
            [isValued]="selectedFilters.upwardEvaluationStatus" title="{{'upwardFeedback.UPWARD_STATUS' | translate}}">
            <alloy-select [disabled]="isLoadingFilters" [inMinWidthForIe]="true"
              (onSelectedItem)="selectFilter($event, 'upwardEvaluationStatus')"
              [selectedItem]="selectedFilters.upwardEvaluationStatus" [items]="filtersObject.upwardEvaluationStatus"
              [searchable]="false" bindLabel="id">
            </alloy-select>
          </input-container>
        </div>
        <div class="button-actions filters">
          <alloy-button [label]="applyFiltersText || ('generic.APPLY_FILTERS' | translate)" type="primary"
            (onButtonClicked)="applyFilters()" [disabled]="isLoadingUsers || isLoadingFilters">
          </alloy-button>
        </div>
      </div>

      <ng-container *ngIf="isLoadingFilters">
        <div class="loader spinner-big"></div>
      </ng-container>
    </div>
  </ng-container>
</div>