import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'linear-rating',
  templateUrl: './linear-rating.component.html',
  styleUrls: ['./linear-rating.component.scss']
})
export class LinearRatingComponent implements OnInit {
  @Input() ratings: Array<{ id: string, title: string, value: number }> = [];
  @Input() average: number = 0;;
  @Input() scale: number = 5;
  @Input() scaleArray: Array<number> = [];
  @Output() onClose = new EventEmitter<any>();

  constructor() {
    //[...Array(this.scale).keys()].map(x => ++x);
    for (let i = 0; i <= this.scale; i++) {
      this.scaleArray.push(i);
    }
  }

  ngOnInit(): void {

  }

  getRatingPercentage(rating: { id: string, title: string, value: number }) {
    return (rating.value / this.scale) * 100;
  }

  getAverage() {
    let sum = 0;
    for (let i = 0; i < this.ratings.length; i++) {
      sum += this.ratings[i].value;
    }
    return sum / this.ratings.length;
  }

}
