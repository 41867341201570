<div class="table-container">
  <table [ngClass]="profileReducedView ? 'reduce-size' : ''">
    <thead>
      <tr>
        <th>
          <div class="searchbar-container">
            <ng-container *ngIf="!compactView">
              <svg-icon src="/assets/img/icons/search.svg" class="regular-size blue-primary" [applyClass]="true"
                (click)="listTeamMembers()">
              </svg-icon>
              <p class="text-default"></p>
              <input class="text-default" [placeholder]="'generic.SEARCH_BY_NAME' | translate"
                [(ngModel)]="searchedText" (keydown.enter)="listTeamMembers()">
            </ng-container>
            <ng-container *ngIf="compactView">
              <p translate="teamList.PAGE_TITLE"></p>
            </ng-container>
          </div>
        </th>
        <th *ngFor="let phase of menuTable; let i = index">
          <div class="header-object">
            <ng-container *ngIf="!compactView">
              <p>{{ phase.title }}</p>
              <!--svg-icon (click)="openFilterModal($event, i)" src="/assets/img/icons/filter-filled.svg"
                [applyClass]="true">
              </svg-icon-->
            </ng-container>
            <ng-container *ngIf="compactView">
              <p class="title-click" (click)="openFilterModal($event, i)">{{ phase.titleCompact }}</p>
            </ng-container>
          </div>
        </th>
        <th>
          <ng-container *ngIf="!compactView">
            <p translate="phases.FEEDBACK"></p>
          </ng-container>
          <ng-container *ngIf="compactView">
            <p translate="phases.initials.FEEDBACK"></p>
          </ng-container>
        </th>
      </tr>
      <tr>
        <!-- [ngClass]="{'reduced': profileReducedView}" -->
        <td class="divider" colspan="6">
          <div class="row-divider reversed">
            <div class="top"></div>
            <div class="bottom"></div>
          </div>
        </td>
      </tr>
    </thead>
    <ng-container *ngIf="!isLoadingTeamMembers && teamList && teamList.length">
      <tbody>
        <ng-container *ngFor="let userObject of teamList; let i = index">
          <tr [ngClass]="{'data-row': i % 2 != 0}">
            <td>
              <ng-container *ngIf="!profileReducedView">
                <div class="data-user">
                  <avatar-img [user]="userObject.user" size="medium"></avatar-img>
                  <div class="user-info">
                    <p class="user-info-text">{{userObject.user.forename}}
                      {{userObject.user.surname}}
                    </p>
                    <a class="detail-text" translate="goalSetting.DETAIL"
                      (click)="goToPersonDetails(userObject.user)"></a>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="profileReducedView">
                <div class="data-user reduced">
                  <avatar-img [user]="userObject.user" size="table-reduced"></avatar-img>
                  <div class="user-info">
                    <p class="user-info-text" (click)="goToPersonDetails(userObject.user)">
                      {{userObject.user.forename}} {{userObject.user.surname}}</p>
                  </div>
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="!compactView">
                <item-list-team-status [styleItem]="'border'" [type]="userObject.goalSettingStatus"
                  (click)="openPhaseInfoModal('goalSetting', userObject.goalSettingStatus)">
                </item-list-team-status>
              </ng-container>
              <ng-container *ngIf="compactView">
                <div class="square-status" [ngClass]="userObject.goalSettingStatus"
                  (click)="openPhaseInfoModal('goalSetting', userObject.goalSettingStatus)">
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="!compactView">
                <item-list-team-status [styleItem]="'border'" [type]="userObject.developmentPlanStatus"
                  (click)="openPhaseInfoModal('developmentPlan', userObject.developmentPlanStatus)">
                </item-list-team-status>
              </ng-container>
              <ng-container *ngIf="compactView">
                <div class="square-status" [ngClass]="userObject.developmentPlanStatus"
                  (click)="openPhaseInfoModal('developmentPlan', userObject.developmentPlanStatus)">
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="!compactView">
                <item-list-team-status [styleItem]="'border'" [type]="userObject.midTermReviewStatus"
                  (click)="openPhaseInfoModal('midTerm', userObject.midTermReviewStatus)">
                </item-list-team-status>
              </ng-container>
              <ng-container *ngIf="compactView">
                <div class="square-status" [ngClass]="userObject.midTermReviewStatus"
                  (click)="openPhaseInfoModal('midTerm', userObject.midTermReviewStatus)">
                </div>
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="!compactView">
                <item-list-team-status [styleItem]="'border'" [type]="userObject.finalEvaluationStatus"
                  (click)="openPhaseInfoModal('final', userObject.finalEvaluationStatus)">
                </item-list-team-status>
              </ng-container>
              <ng-container *ngIf="compactView">
                <div class="square-status" [ngClass]="userObject.finalEvaluationStatus"
                  (click)="openPhaseInfoModal('final', userObject.finalEvaluationStatus)">
                </div>
              </ng-container>
            </td>
            <td>
              <a class="feedback-number" (click)="openFeedbackDetailsModal(userObject.modalData)">
                {{ userObject.feedback && userObject.feedback.totalFeedbackCount || '0' }}</a>
            </td>
          </tr>
          <tr>
            <td class="divider" colspan="6">
              <div class="row-divider" [ngClass]="{'reversed': i % 2 != 0}">
                <div class="top"></div>
                <div class="bottom"></div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </ng-container>
  </table>
  <ng-container *ngIf="isLoadingTeamMembers">
    <div class="team-loader-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>
  <!-- placeholder nessun risultato -->
  <ng-container *ngIf="!isLoadingTeamMembers && (!teamList || !teamList.length)">
    <div class="team-loader-container">
      <p class="no-data-placeholder">{{ ('teamList.NO_DATA' | translate)}}</p>
    </div>
  </ng-container>
</div>


<!-- Modale per l'aggiornamento di un'obiettivo -->
<modal id="feedbackDetails" (onClose)="closeFeedbackDetailsModal()" (onConfirm)="closeFeedbackDetailsModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <ng-container *ngIf="userFeedbackModal">
    <modal-text-content [modalId]="userFeedbackModal.modalId" [title]="userFeedbackModal.title"
      [subtitle]="userFeedbackModal.subtitle" [text]="userFeedbackModal.description">
    </modal-text-content>
  </ng-container>
</modal>


<modal id="filterModal" *ngIf="menuTable && menuTable[phaseIndex]" (onConfirm)="closeFilterModal()"
  (onClose)="closeFilterModal()" [confirmLabel]="('generic.CLOSE' | translate)">
  <div class="filter-modal-wrapper">
    <div class="title-container">
      <p>{{('teamList.modals.FILTER_TITLE' | translate) + ('phases.' + menuTable[phaseIndex].phaseId |
        translate)}}</p>
    </div>
    <div class="subtitle">{{('teamList.modals.FILTER_SUBTITLE' | translate) }}</div>
    <ng-container *ngIf="!reloadingFilters">
      <alloy-dropdown-item class="full-width" [item]="menuTable[phaseIndex].filterAll" [filterModal]="true"
        (onItemCheck)="checkFilters($event, menuTable[phaseIndex])" [isCheckbox]="true">
      </alloy-dropdown-item>
      <alloy-dropdown-item class="full-width" [item]="menuTable[phaseIndex].filterToStart" [filterModal]="true"
        (onItemCheck)="checkFilters($event, menuTable[phaseIndex])" [isCheckbox]="true">
      </alloy-dropdown-item>
      <alloy-dropdown-item class="full-width" [item]="menuTable[phaseIndex].filterInProgress" [filterModal]="true"
        (onItemCheck)="checkFilters($event, menuTable[phaseIndex])" [isCheckbox]="true">
      </alloy-dropdown-item>
      <alloy-dropdown-item class="full-width" [item]="menuTable[phaseIndex].filterCompleted" [filterModal]="true"
        (onItemCheck)="checkFilters($event, menuTable[phaseIndex])" [isCheckbox]="true">
      </alloy-dropdown-item>
    </ng-container>
    <ng-container *ngIf="reloadingFilters">
      <div class="team-loader-container modal">
        <div class="loader spinner-big"></div>
      </div>
    </ng-container>
  </div>
</modal>

<modal id="phaseInfo" (onClose)="closePhaseInfoModal()" (onConfirm)="closePhaseInfoModal()"
  [confirmLabel]="('generic.CLOSE' | translate)">
  <div class="filter-modal-wrapper" *ngIf="phasesInfoData && phaseIdForModal">
    <div class="title-container">
      <p>{{('teamList.info.TITLE' | translate) + ' '
        + ('teamList.info.' + phaseIdForModal + '.TITLE' | translate)}}</p>
    </div>
    <div class="phase-info-content-wrapper">
      <!-- Se la fase è disabilitata -->
      <ng-container *ngIf="isPhaseDisabled">
        <p translate="teamList.info.INACTIVE"></p>
      </ng-container>
      <!-- Se fase attiva -->
      <ng-container *ngIf="!isPhaseDisabled">
        <div class="section">
          <item-list-team-status type="toStart" styleItem="border"></item-list-team-status>
          <div class="phase-status-info" [innerHTML]="phasesInfoData[phaseIdForModal].toStart"></div>
        </div>
        <div class="section">
          <item-list-team-status type="inProgress" styleItem="border"></item-list-team-status>
          <div class="phase-status-info" [innerHTML]="phasesInfoData[phaseIdForModal].inProgress"></div>
        </div>
        <div class="section">
          <item-list-team-status type="completed" styleItem="border"></item-list-team-status>
          <div class="phase-status-info" [innerHTML]="phasesInfoData[phaseIdForModal].completed"></div>
        </div>
      </ng-container>
    </div>
  </div>
</modal>