<div class="file">
  <svg-icon [src]="srcIcon" class="regular-size" [applyClass]="true"
    [ngClass]="{'blue-primary': srcIcon.indexOf('link') >= 0}"></svg-icon>
  <div class="container-file-name" [ngClass]="{'to-end': toEndParagraph}">
    <div class="full-width">
      <a (click)="emitOnDownload()">
        {{fileName}}
      </a>
    </div>
    <p class="charged-time">{{('feedback.CHARGED_AT' | translate) + ' ' + (chargedTime || '')}}</p>
  </div>
  <div class="link-actions" *ngIf="canDoActions">
    <a translate="generic.DELETE" (click)="emitOnDelete()">
    </a>
    <a *ngIf="isLink" translate="generic.EDIT" (click)="emitOnEdit()">
    </a>
  </div>
</div>