import { Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import * as CoreActions from "../../../core/ngrx/core.actions";
import { Store } from '@ngrx/store';
import * as fromApp from "../../../ngrx/app.reducers";

@Component({
  selector: 'mid-term-box-home',
  templateUrl: 'mid-term-box-home.component.html',
  styleUrls: ['./mid-term-box-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MidTermBoxHomeComponent implements OnInit {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() midTermData: any;
  @Input() isPeopleAppraisalBox: boolean = false;
  // midTermData: {
  //    dateDescription: testo "Disponibile dal .. al .."
  //    isPhaseActive: true / false
  //    completedDescr: test "revisione effettuata il ..."
  //    status: se attiva, da completare / completo
  //    // Contatori
  //    goalCount: 
  //    feedbackNumber
  //    evidenceNumber
  //    observationCount
  //  }  
  @Input() imgPath: string = '';
  @Input() isDisabled: boolean = false;
  @Input() disabledModalMessage?: ApplicationModalMessage;
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  MID_STATUS_SRC = {
    'LATE': "assets/img/icons/red-face.svg",
    'ON_LINE': "assets/img/icons/yellow-face.svg",
    'WELL_ADVANCED': "assets/img/icons/green-face.svg"
  }

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.imgPath = this.imgPath || "../../../../assets/img/icons/homeIcons/placeholder-img.png";
  }

  emitOnButtonClicked(data?: any) {
    if (this.isDisabled) {
      if (this.disabledModalMessage) {
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: this.disabledModalMessage }));
      }
    } else {
      this.onButtonClicked.emit(data);
    }
  }
}