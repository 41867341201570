/*
*    Modulo per le traduzioni
*/

import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateDirective } from '@ngx-translate/core';
import { TranslatePipe } from '@ngx-translate/core';

// La compilazione Ahead of Time richiede una funzione esportata
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

// Classe per la gestione delle traduzioni mancanti
export class AppMissingHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    return;
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingHandler
      }
    })
  ],
  exports: [
    TranslateDirective,
    TranslatePipe
  ]
})
export class AppTranslateModule {
  static forRoot(config?: any): ModuleWithProviders<AppTranslateModule> {
    return {
      ngModule: AppTranslateModule,
    };
  }

}