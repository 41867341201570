<!-- Toaster -->
<div id="snackbar">
  <p translate="generic.SAVE_SUCCESSFUL"></p>
</div>

<div id="snackbar-reminder">
  <p translate="upwardFeedback.admin.verify.REMINDERS_SENT"></p>
</div>

<div class="page-content-wrapper">

  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('upwardFeedback.admin.MANAGE_TITLE' | translate)"
    [adminBack]="true" [hasTabs]="false" [adminPageSubtitle]="!isLoadingRound && roundData ? (roundData.name) : ''"
    [adminActionLabel]="('calibration.DOWNLOAD_REPORT' | translate)" (onAdminAction)="downloadReport()"
    [adminActionDisabled]="isDownloadingReport" adminActionLabel="assets/img/icons/file/xls.svg"
    adminActionLabelType="success"></header-dossier>
  <div class="main-content-wrapper upward-feedback">


    <!-- Azioni survey -->
    <div class="admin-table-container column-spacing">
      <div class="header-section">
        <p class="section-title">{{ ('upwardFeedback.admin.manage.ACTIVE_SINCE' | translate) + (!isLoadingRound &&
          roundData ? (roundData.startDate | date: 'dd/MM/YY') : '')}}</p>
        <p class="section-subtitle" [innerHTML]="'upwardFeedback.admin.manage.ACTIVE_SINCE_DESCR' | translate"></p>
      </div>
      <div class="survey-action-container">
        <alloy-button [label]="'upwardFeedback.admin.manage.DOWNLOAD_CURRENT_SURVEY' | translate" size="medium"
          type="primary black-link" (onButtonClicked)="downloadCurrentSurvey()" iconLeft="assets/img/icons/file/xls.svg"
          [disabled]="isLoadingSurvey">
        </alloy-button>
        <alloy-button [label]="'upwardFeedback.admin.manage.UPDATE_SURVEY' | translate" type="black-border bordless"
          [disabled]="isRoundActive()" size="medium" (onButtonClicked)="openUpdateSurveyModal()">
        </alloy-button>
      </div>
    </div>


    <!-- Filtri e lista utenti -->
    <div class="admin-table-container column-spacing">
      <div class="header-section">
        <p class="section-title">{{
          ('upwardFeedback.admin.manage.PARTICIPANT_MANAGER' | translate) + (!isLoadingRound &&
          roundData ? (roundData.startDate | date: 'dd/MM/YY') : '') +
          ('upwardFeedback.admin.manage.ROUND' | translate)
          }}</p>
        <p class="section-subtitle" translate="upwardFeedback.admin.manage.PARTICIPANT_MANAGER_SUB"></p>
      </div>


      <!-- Filtri -->
      <!-- Valutare se fare nuovi servizi filtri-->
      <anag-filters [isUpwardFeedback]="true" [roundId]="roundId" (searchedTextChanged)="searchedTextChanged($event)"
        [isLoadingUsers]="isLoadingUsers" (updateSelectedFilters)="updateSelectedFilters($event)"
        (updateSelectedFiltersNumber)="updateSelectedFiltersNumber($event)" (startSearch)="getUsersList($event)">
      </anag-filters>

      <div class="action-row">
        <div class="actions-container" (click)="openSendReminderMassive()"
          [ngClass]="{'disabled': isLoadingTranslations || isLoadingUsers || isSendingReminders}">
          <svg-icon [src]="'assets/img/icons/mail.svg'"></svg-icon>
          <p translate="upwardFeedback.admin.manage.SEND_REMINDERS_MASSIVE"></p>
        </div>
      </div>

      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingTranslations && !isLoadingUsers && usersList && usersList.length">

        <div class="table-content ">
          <table>
            <thead>
              <tr>
                <th>
                  <alloy-check (onCheck)="selectAll()"></alloy-check>
                </th>
                <th translate="{{ ('adminTable.FULL_NAME' | translate)}}"></th>
                <th translate="{{ ('adminTable.CF' | translate)}}"></th>
                <th translate="{{ ('adminTable.COMPILED_SENT' | translate)}}"></th>
                <th class="center" translate="{{ ('adminTable.STATUS' | translate)}}"></th>
                <th translate="{{ ('adminTable.ACTIONS' | translate)}}"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of usersList |
                          slice:0:usersNumRecords |
                          paginate: { id: 'userList',
                                      itemsPerPage: usersNumRecords,
                                      currentPage: usersCurrentPage,
                                      totalItems: usersCounter }">
                <td>
                  <alloy-check id="user.userId" [checked]="user.isChecked" (click)="selectUser(user)"></alloy-check>
                </td>
                <td>{{ user.surname + ' ' + user.forename }}</td>
                <td>
                  {{ user.codFisc }}
                </td>
                <td>
                  {{ user.numberOfFeedbackReceived || 0 }}/{{ user.numberOfEvaluators || 0 }}
                </td>
                <td>
                  <div class="status-evaluation">
                    <item-list-team-status customContainerClass="min-width-unset" styleItem="border"
                      [type]="user.statusType" [message]="user.statusMessage">
                    </item-list-team-status>
                  </div>
                </td>
                <td class="action">
                  <svg-icon class="more-icon" [applyClass]="true" (click)="openCloseMenu(user)"
                    (clickOutside)="closeMenu(user)" src="/assets/img/icons/more-horizontal.svg">
                  </svg-icon>
                  <ng-container *ngIf="user.isMenuOpen">
                    <div class="dropdown-menu-table">
                      <ng-container *ngFor="let item of menuOptions">
                        <alloy-dropdown-item (click)="onMenuOptionsClicked(item, user)" class="full-width"
                          [item]="item">
                        </alloy-dropdown-item>
                      </ng-container>
                    </div>
                  </ng-container>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="bottom-actions">
          <table-pagination id="userList" (pageChanged)="usersPageChanged($event)" [noPadding]="true">
          </table-pagination>
          <div class="per-page-selector">
            <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
            <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
              [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
              [tablePagination]="true">
            </alloy-select>
          </div>
        </div>
        <div class="button-actions sticky" *ngIf="selectedUserList && selectedUserList.length > 1">
          <div class="selected-container">
            <p class="selected-count">{{ selectedUserList.length || 0}}</p>
            <p class="selected-count-label" translate="generic.SELECTEDS"></p>
          </div>
          <alloy-button [disabled]="false" [label]="'upwardFeedback.admin.manage.SHARE_VERIFIED' | translate"
            type="success" size="medium" (onButtonClicked)="openShareVerifiedResults()"
            [disabled]="checkShareDisabled()">
          </alloy-button>
        </div>

      </ng-container>

      <ng-container *ngIf="!isLoadingUsers && (!usersList || !usersList.length)">
        <div class="admin-no-data-wrapper">
          <p translate="generic.NO_DATA_FOUND"></p>
        </div>
      </ng-container>
      <!-- loader -->
      <ng-container *ngIf="isLoadingUsers || isLoadingTranslations">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Condividi risultati verificati -->
<modal id="share-verified" (onClose)="closeShareVerifiedResults()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeShareVerifiedResults(true)" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeShareVerifiedResults()">
  <modal-text-content *ngIf="shareMultipleDescription"
    [title]="'upwardFeedback.admin.manage.SHARE_VERIFIED' | translate"
    [subtitle]="'upwardFeedback.admin.modals.CONFIRMING_STATUS_CHANGES' | translate" [text]="shareMultipleDescription">
  </modal-text-content>
</modal>


<!-- Chi può inviare Upward Feedback -->
<modal id="who-feedback" (onClose)="closeWhoFeedback()" [confirmLabel]="'generic.CLOSE' | translate"
  (onConfirm)="closeWhoFeedback()">
  <ng-container *ngIf="isLoadingModalUsers">
    <div class="sti-modal-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoadingModalUsers && selectedUserForModal">
    <modal-text-content [title]="'upwardFeedback.admin.manage.WHO_SEND' | translate"
      [subtitle]="('upwardFeedback.admin.modals.LIST_SENDING' | translate) + selectedUserForModal.surname + ' ' + selectedUserForModal.forename"
      [text]="modalUserListDescription" [isScrollable]="true">
    </modal-text-content>
  </ng-container>
</modal>

<!-- Survey Upward Feedback da riaprire -->
<modal id="reopen-surveys" (onClose)="closeReopenSurveys()"
  [confirmLabel]="'upwardFeedback.admin.manage.CONFIRM_REOPEN' | translate" [cancelLabel]="'generic.CANCEL' | translate"
  (onConfirm)="reopenUpwardFeedbackSurveys()" (onCancel)="closeReopenSurveys()"
  [isDisabled]="!checkIfUserSelectedForCardReopen()">
  <ng-container *ngIf="isLoadingModalUsers">
    <div class="sti-modal-container">
      <div class="loader spinner-big"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoadingModalUsers && selectedUserForModal">
    <modal-text-content [title]="'upwardFeedback.admin.manage.REOPEN_SURVEYS' | translate"
      [subtitle]="'upwardFeedback.admin.modals.WHICH_CARD_TO_REOPEN' | translate"
      [text]="'upwardFeedback.admin.modals.WHICH_CARD_TO_REOPEN_DESCR' | translate" [isScrollable]="true">
    </modal-text-content>
    <div class="user-list" *ngIf="upwardFeedbackUsersToReopenSurv && upwardFeedbackUsersToReopenSurv.length">
      <user-card *ngFor="let user of upwardFeedbackUsersToReopenSurv" [user]="user" [isChecked]="user.isChecked"
        [radio]="false" [fromCppCollabs]="true" [disabled]="!user.available"></user-card>
    </div>
  </ng-container>
</modal>

<!-- Modale di aggiornamento della survey -->
<modal id="update-survey" (onClose)="closeUpdateSurveyModal()" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeUpdateSurveyModal()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeUpdateSurveyModal(true)">
  <div class="sti-modal-container">
    <p class="title" translate="upwardFeedback.admin.manage.UPDATE_SURVEY">
    </p>
    <div class="divisor-line"></div>

    <input-container [isWithoutInfoIcon]="true" [isValued]="importFile" [isObbligatory]="true"
      [title]="'upwardFeedback.admin.manage.UPDATE_SURVEY_INPUT' | translate">
      <alloy-upload [isToReset]="resetIncludeUpload" (onUpload)="assignFile($event)"
        [acceptedMimeTypes]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></alloy-upload>
    </input-container>

    <ng-container *ngIf="importFile">
      <ng-container *ngIf="isLoadingSurvey">
        <div class="loader spinner-medium"></div>
      </ng-container>
    </ng-container>
  </div>
</modal>


<!-- Conferma invio solleciti -->
​<modal id="send-reminders" (onClose)="closeSendRemindersModal()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeSendRemindersModal(true)" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeSendRemindersModal()">
  <modal-text-content *ngIf="sendReminderUser"
    [title]="'upwardFeedback.admin.modals.SEND_REMINDERS_TO_TEAM' | translate" [subtitle]="('upwardFeedback.admin.modals.SEND_REMINDERS_SUB_1' | translate) 
    + (sendReminderUser.numberOfEvaluators - sendReminderUser.numberOfFeedbackReceived) 
    + ('upwardFeedback.admin.modals.SEND_REMINDERS_SUB_2' | translate)"
    [text]="'upwardFeedback.admin.modals.SEND_REMINDERS_DESCR' | translate">
  </modal-text-content>
</modal>

<modal id="send-reminders-massive" (onClose)="closeSendReminderMassive()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="closeSendReminderMassive(true)" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="closeSendReminderMassive()">
  <modal-text-content [title]="'upwardFeedback.admin.modals.SEND_REMINDERS_MASSIVE_TITLE' | translate"
    [text]="'upwardFeedback.admin.modals.SEND_REMINDERS_MASSIVE_DESCR' | translate">
  </modal-text-content>
</modal>

<!-- Conferma riapertura scheda manager -->
<modal id="cancel-sharing" (onClose)="cancelSharingUpwardFeedback()" [confirmLabel]="'generic.CONFIRM' | translate"
  (onConfirm)="cancelSharingUpwardFeedback(true)" [cancelLabel]="'generic.CANCEL' | translate"
  (onCancel)="cancelSharingUpwardFeedback()">
  <modal-text-content *ngIf="selectedUserForModal" [title]="'upwardFeedback.admin.manage.CANCEL_SHARING' | translate"
    [subtitle]="'upwardFeedback.admin.modals.CANCEL_SHARING_SUB' | translate" [text]="('upwardFeedback.admin.modals.CANCEL_SHARING_DESCR_1' | translate) + this.selectedUserForModal.forename + ' ' + this.selectedUserForModal.surname +
    ('upwardFeedback.admin.modals.CANCEL_SHARING_DESCR_2' | translate)">
  </modal-text-content>
</modal>
