import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subscription, throwError } from 'rxjs';
import { ModalService } from 'src/app/shared/components/modal/modal.service';
import * as fromApp from "../../ngrx/app.reducers";
import * as CoreActions from "../../core/ngrx/core.actions";
import { SenecaResponse } from 'src/commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ApplicationModalMessage } from 'src/app/core/ngrx/core.reducers';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdminService } from 'src/app/shared/services/admin.service';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AlloyPerformanceCpp, AlloyPerformanceCppServiceResponses, PerformanceAssessmentAlloyStatuses } from 'atfcore-commonclasses';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-admin-cpp-dashboard',
  templateUrl: './admin-cpp-dashboard.component.html',
  styleUrls: ['./admin-cpp-dashboard.component.scss']
})
export class AdminCppDashboardComponent implements OnInit, OnDestroy {
  loggedUser: any;
  selectedFilters: any = {};

  // selfAssessment
  selfAssessmentPercentage: number = 0;
  selfAssessmentOpen: boolean = true;
  selfAssessmentProgress: any;
  getSelfAssessmentData$: Subscription = new Subscription;
  isLoadingSelfAssessmentData: boolean = false;
  selfAssessmentNoData: boolean = false;

  // Piano di sviluppo
  developmentPlan: number = 0;
  developmentPlanOpen: boolean = true;
  developmentPlanData: any;
  getDevelopmentPlanData$: Subscription = new Subscription;
  isLoadingDevelopmentPlanData: boolean = false;
  developmentPlanNoData: boolean = false;

  isDownloadingReport: boolean = false;
  competenceList: Array<any> = [];

  careColors: string[] = ["#FF7C41", "#FF6600", "#B1381D"];

  chartInstances: any = {};
  allowedFormats = ['PNG', 'PDF', 'JPEG'];

  // lista rounds
  isLoadingCppRoundsData: boolean = false;
  getCppRoundsData$: Subscription = new Subscription;
  cppRoundData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 5,
      page: 1,
      counter: 0,
      list: []
    }

  userData: {
    fromRecord: number,
    numRecords: number,
    page: number,
    counter: number,
    list: any[]

  } = {
      fromRecord: 0,
      numRecords: 10,
      page: 1,
      counter: 0,
      list: []
    }
  rowPerPageOptions = [
    {
      id: 5,
      title: 5
    },
    {
      id: 10,
      title: 10
    },
    {
      id: 15,
      title: 15
    },
    {
      id: 20,
      title: 20
    }
  ]
  processYear!: number;
  selectedRows: any;

  downloadReport$: Subscription = new Subscription;
  processYearCode: any;
  selectedFiltersNumber: any;
  translations: any;

  roundId!: string;
  profileId!: string | undefined;
  profileName!: string | undefined;
  GetCppProfileDashboardDataForAdmin!: AlloyPerformanceCppServiceResponses.GetCppProfileDashboardDataForAdmin | undefined;
  cppRoundName!: string;
  dashboardTabs: Tab[] = [];
  selectedTab!: Tab;
  userDetail!: AlloyPerformanceCppServiceResponses.ListCppUsersWithAdequacyForAdmin | undefined;

  rolesList: Array<{ id: string, name: string, icon: string, isChecked: boolean }> = [];
  selectedRole: { id: string, label: string, icon: string, isChecked: boolean } = { id: '', label: '', icon: '', isChecked: false };
  showAllCompentences = false;
  // lista che verrà utilizzata per la modale
  adequacyModalList: Array<string> = ['min', 'medium', 'max', 'anomalies'];
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.redrawGraphs();
  }

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private adminService: AdminService,
    private router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    public redirectService: RedirectService,
    private viewportScroller: ViewportScroller) {

    this.route.params
      .subscribe((params: Params) => {
        if (params.roundId) {
          this.roundId = params.roundId;
        }

        this.rolesList = [];

        this.store.select(fromApp.getLoggedUser)
          .subscribe((loggedUser: any) => {
            this.loggedUser = loggedUser && loggedUser.user;

            if (this.roundId) {
              this.isLoadingSelfAssessmentData = true;
              this.isLoadingDevelopmentPlanData = true;
              this.getCppRound();
            } else {
              this.getCppRoundsData();
            }
          }
          );
      });
  }

  ngOnInit(): void {
    this.translate.get([
      'dashboard.feedbackTypes.SENT_SMART_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_SMART_FEEDBACK',
      'dashboard.feedbackTypes.SENT_PEER_FEEDBACK',
      'dashboard.feedbackTypes.RECEIVED_PEER_FEEDBACK',
      'dashboard.feedbackTypes.EVIDENCES',
      'dashboard.feedbackTypes.INTERVIEWS',
      'dashboard.FILTERED',
      'dashboard.TO_START',
      'dashboard.STARTED',
      'dashboard.COMPILED',
      'dashboard.SHARED',
      'dashboard.accordion.SELF_ASSESSMENT',
      'dashboardCpp.accordion.DEV_PLAN',
      'dashboard.POST',
      'dashboard.ENTIRE_COMPANY',
      'process.status.STANDBY',
      'process.status.ACTIVE',
      'process.status.CLOSED',
      'dashboardCpp.AVANCEMENT_DASHBOARD',
      'dashboardCpp.ANALYTIC_DASHBOARD'
    ]).subscribe((translations: any) => {
      this.translations = translations;

      this.dashboardTabs = [
        {
          id: 'avancement-dashboard',
          title: this.translations['dashboardCpp.AVANCEMENT_DASHBOARD'],
          isDisabled: false
        },
        {
          id: 'analytic-dashboard',
          title: this.translations['dashboardCpp.ANALYTIC_DASHBOARD'],
          isDisabled: true
        }
      ]

      this.selectedTab = this.dashboardTabs[0];

      this.selectedRows = this.rowPerPageOptions[1];


      this.competenceList = [
        {
          user: { forename: 'Prova', surname: 'Rossi' },
          role: 'Software Development Engineer',
          evaluator: { forename: 'Giuseppe', surname: 'Martello' },
          lastUpdate: '23/02/2022',
          status: 'min'
        }
      ]

      this.getCPPProfileList();
    })
  }

  getCppRound() {
    this.adminService.getCppRounds('0', '1', [this.roundId])
      .subscribe((data: SenecaResponse<AlloyPerformanceCpp.CppRound[]>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "save001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        } else if (data.response?.length) {
          this.cppRoundName = data.response[0].name;
        }
      }, (err?: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "save002",
          text: this.translate.instant("errors." + err?.message),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      });
  }

  openCompetenceLevelModal() {
    this.modalService.open('competence-level');
  }

  openCompetenceDetailModal(user: any) {
    if (!user.surveyCompleted) {
      return;
    }

    this.userDetail = user;
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor('user-detail');
    }, 0)
  }

  closeCompetenceDetailModal() {
    this.userDetail = undefined;
    this.viewportScroller.scrollToAnchor('user-list');
  }

  getCppRoundsData() {
    this.isLoadingCppRoundsData = true;
    if (this.getCppRoundsData$) {
      this.getCppRoundsData$.unsubscribe();
    }
    this.getCppRoundsData$ = this.adminService.countCppRounds()
      .pipe(
        switchMap(
          (counter: SenecaResponse<number>) => {
            if (counter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(counter.error, null))
            } else {
              // Salvo il counter
              this.cppRoundData.counter = counter.response;

              // Calcolo la paginazione
              this.cppRoundData.fromRecord = (this.cppRoundData.page - 1) * this.cppRoundData.numRecords;

              if (this.cppRoundData.counter) {
                return this.adminService.getCppRounds(this.cppRoundData.fromRecord?.toString(), this.cppRoundData.numRecords?.toString());
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        ), catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a002",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          this.isLoadingCppRoundsData = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return throwError(new Error(err.message));
        }),
        take(1)
      ).subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            // Vedo se c'è la traduzione dell'errore
            const messageObj: ApplicationModalMessage = {
              modalId: "a003",
              text: this.translate.instant("errors." + data.error),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          } else {
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            for (let i = 0; i < data.response.length; i++) {
              if (data.response[i].roundStatus == PerformanceAssessmentAlloyStatuses.STANDBY) {
                data.response[i].statusType = 'inProgress';
                data.response[i].statusMessage = this.translations['process.status.STANDBY'];
              } else if (data.response[i].roundStatus == PerformanceAssessmentAlloyStatuses.RUNNING) {
                data.response[i].statusType = 'completed';
                data.response[i].statusMessage = this.translations['process.status.ACTIVE'];
              } else {
                data.response[i].statusType = 'disabled';
                data.response[i].statusMessage = this.translations['process.status.CLOSED'];
              }
            }
            this.cppRoundData.list = data.response || [];
          }
          this.isLoadingCppRoundsData = false;
        }
        , (err: any) => {
          this.isLoadingCppRoundsData = false;
          if (err && err.message) {
            const messageObj: ApplicationModalMessage = {
              modalId: "a004",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          }
          return throwError(new Error(err.message));
        }
      );
  }

  isCppDashboardView() {
    return this.redirectService.isThisCurrentPage('/admin/cppDashboard/');
  }
  roundPageChanged(page: number) {
    this.cppRoundData.page = page;
    this.cppRoundData.list = [];
    this.getCppRoundsData();
  }

  changeNumRecords(item: any) {
    this.selectedRows = item;
    this.cppRoundData.numRecords = item.id;
    this.cppRoundData.list = [];
    this.cppRoundData.fromRecord = 0;
    this.getCppRoundsData();
  }

  goToRoundDetails(round: any) {
    this.router.navigate(['/admin/cppDashboard/', round.roundId]);
  }

  // Personalizza le label delle tabelle
  customizePercentageText(arg: any) {
    return `${arg.valueText} %`;
  }

  customizeSeries(valueFromNameField: string) {
    return valueFromNameField === 'Azienda intera'
      ? { type: 'spline', label: { visible: true }, color: '#B1381D' } : {};
  }


  saveInstance(chart: any, name: string) {
    this.chartInstances[name] = chart.component;
  }

  redrawGraphs(graphSection?: any) {
    let graphToRedraw: string[] = [];
    switch (graphSection) {
      case 'self-assessment': {
        graphToRedraw = ["selfAssessmentGauge", "selfAssessmentChart"];
        break;
      }
      case 'dev-plan': {
        graphToRedraw = ["developmentPlanGauge", "developmentPlanChart"];
        break;
      }
      default:
        graphToRedraw = Object.keys(this.chartInstances);
        break;
    }
    if (graphToRedraw && graphToRedraw.length) {
      setTimeout(() => {
        for (let i = 0; i < graphToRedraw.length; i++) {
          if (this.chartInstances[graphToRedraw[i]]) {
            this.chartInstances[graphToRedraw[i]].render();
          }
        }
      }, 100);
    }
  }


  // inizializza i dati dei grafici
  getGraphData(event?: any) {
    this.selfAssessmentNoData = false;
    this.developmentPlanNoData = false;
    // Non serve più salvare nessun filtri al di fuori 
    // if (this.selectedFiltersNumber > 0) {
    //   sessionStorage.setItem('savedFilters', JSON.stringify(this.selectedFilters));
    //   sessionStorage.setItem('savedFiltersNumber', this.selectedFiltersNumber?.toString() || '0');
    // }
    this.getSelfAssessmentData();
    this.getDevelopmentPlanData();
    this.userDetail = undefined;
    this.GetCppProfileDashboardDataForAdmin = undefined;
    this.profileId = undefined;
    this.getCPPProfileList();
  }


  // controlla se sto caricando qualcosa
  isLoading() {
    return this.isLoadingSelfAssessmentData || this.isLoadingDevelopmentPlanData;
  }

  getSelfAssessmentData() {
    this.isLoadingSelfAssessmentData = true;
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }

    this.getSelfAssessmentData$ = this.adminService.getCppDashboardSelfAssessment(this.roundId, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da001",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        } else {
          this.selfAssessmentProgress = {};
          this.selfAssessmentPercentage = Math.round(data.response.progress * 100) || 0;
          this.selfAssessmentProgress.completedPercentage = this.selfAssessmentPercentage;
          if (data.response.noUsersFound) {
            this.selfAssessmentNoData = true;
          }
          this.selfAssessmentProgress.total = data.response.total;
          this.selfAssessmentProgress.sent = data.response.totalSent;
          this.selfAssessmentProgress.chartData = [
            {
              attribute: this.translations['dashboard.TO_START'],
              number: data.response.totalToStart,
              color: '#FF7C41',
            },
            {
              attribute: this.translations['dashboard.STARTED'],
              number: data.response.totalStarted,
              color: '#FF6600',
            },
            {
              attribute: this.translations['dashboard.COMPILED'],
              number: data.response.totalSent,
              color: '#B1381D',
            }
          ]

          this.isLoadingSelfAssessmentData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da002",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingSelfAssessmentData = false;
        });
  }


  getDevelopmentPlanData() {
    this.isLoadingDevelopmentPlanData = true;
    if (this.getDevelopmentPlanData$) {
      this.getDevelopmentPlanData$.unsubscribe()
    }

    this.getDevelopmentPlanData$ = this.adminService.getCppDashboardDevelopmentplanForAdmin(this.roundId, this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "da003",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDevelopmentPlanData = false;
        } else {
          this.developmentPlanData = {};
          this.developmentPlan = Math.round(data.response.progress * 100) || 0;
          this.developmentPlanData.completedPercentage = this.developmentPlan;
          if (data.response.noUsersFound) {
            this.developmentPlanNoData = true;
          }
          this.developmentPlanData.total = data.response.total;
          this.developmentPlanData.sent = data.response.totalSent;
          this.developmentPlanData.chartData = [
            {
              attribute: this.translations['dashboard.TO_START'],
              number: data.response.totalToStart,
              color: '#B1381D',
            },
            {
              attribute: this.translations['dashboard.STARTED'],
              number: data.response.totalStarted,
              color: '#FF6600',
            },
            {
              attribute: this.translations['dashboard.SHARED'],
              number: data.response.totalSent,
              color: '#FF7C41',
            }
          ]

          this.isLoadingDevelopmentPlanData = false;
        }
      },
        (err: any) => {
          const messageObj: ApplicationModalMessage = {
            modalId: "da004",
            text: this.translate.instant("errors." + err?.message || err),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isLoadingDevelopmentPlanData = false;
        });
  }

  downloadReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.downloadDashboardReport(2023, 'H1', this.selectedFilters)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  exportCppDashboardReport() {
    this.isDownloadingReport = true;
    if (this.downloadReport$) {
      this.downloadReport$.unsubscribe();
    }
    this.downloadReport$ = this.adminService.exportCppDashboardReportForAdmin(this.roundId)
      .subscribe((data: SenecaResponse<any>) => {
        if (data && data.error) {
          const messageObj: ApplicationModalMessage = {
            modalId: "a004",
            text: this.translate.instant("errors." + data.error),
            title: this.translate.instant("generic.WARNING")
          }
          this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
          this.isDownloadingReport = false;
        } else {
          let filename = data.response;
          this.authService.crateRetrieveTokenAfterLogin().subscribe((data: SenecaResponse<any>) => {
            if (data && data.response) {
              let downloadUrl = this.authService.getDownloadTempFileUrl(filename, data.response);
              setTimeout(() => {
                window.open(downloadUrl, '_blank');
              }, 500)
              this.isDownloadingReport = false;
            } else {
              const messageObj: ApplicationModalMessage = {
                modalId: "a007",
                text: this.translate.instant("errors." + data && data.error),
                title: this.translate.instant("generic.WARNING")
              }
              this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
              this.isDownloadingReport = false;
            }
          }, (err: any) => {
            const messageObj: ApplicationModalMessage = {
              modalId: "a008",
              text: this.translate.instant("errors." + ((err && err.message) || err)),
              title: this.translate.instant("generic.WARNING")
            }
            this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
            this.isDownloadingReport = false;
          })

        }
      }, (err: any) => {
        const messageObj: ApplicationModalMessage = {
          modalId: "a005",
          text: this.translate.instant("errors." + ((err && err.message) || err)),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
        this.isDownloadingReport = false;
      })
  }

  customizeTooltip = (info: any) => (
    {
      html: `<div><div class='tooltip-header'>${this.translate.instant('dashboard.NUM_PAS')}</div>`
        + '<div class=\'tooltip-body\'><div class=\'series-name\'>'
        + `<span class=\'top-series-name\'>${info.points[0].point.data.value + '% - ' + info.points[0].point.data.tooltip + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[0].point.data.tooltipAllUser + ' ' + this.translate.instant('dashboard.TOTAL')}</span><br>`
        + `${info.points[0].point.data.selectedFiltersNumber > 0 ? `<span class=\'top-series-name\'>${info.points[1].point.data.value + '% - ' + info.points[1].point.data.tooltip + ' ' + this.translate.instant('dashboard.EVALUATED_ON') + info.points[1].point.data.tooltipAllUser + ' ' + this.translate.instant('dashboard.TOTAL_FILTERED')}</span>` : ``}`
        + `</div></div></div>`
    }
  );

  updateSelectedFilters(newSelectedFilters: any) {
    this.selectedFilters = newSelectedFilters;
  }

  updateSelectedFiltersNumber(newNumber: number) {
    this.selectedFiltersNumber = newNumber;
  }

  changeCurrentTab(tab: Tab) {
    this.selectedTab = tab;

    if (this.selectedTab.id == 'avancement-dashboard') {
      this.dashboardTabs[1].isDisabled = true;
      this.dashboardTabs[0].isDisabled = false;
      this.profileId = undefined;
      this.userDetail = undefined;
      this.GetCppProfileDashboardDataForAdmin = undefined;
    } else {
      this.dashboardTabs[1].isDisabled = false;
      this.dashboardTabs[0].isDisabled = true;
    }
    this.redrawGraphs();
  }


  ngOnDestroy(): void {
    if (this.getSelfAssessmentData$) {
      this.getSelfAssessmentData$.unsubscribe()
    }
    if (this.getDevelopmentPlanData$) {
      this.getDevelopmentPlanData$.unsubscribe()
    }
  }

  emitOnSelectedItem(item: { id: string, name: string, icon: string, isChecked: boolean }) {
    this.selectedRows = this.rowPerPageOptions[1];
    this.userData.numRecords = this.rowPerPageOptions[1].id;
    this.userData.fromRecord = 0;
    this.userData.page = 1;
    this.showAllCompentences = false;
    this.userDetail = undefined;
    this.profileId = item.id;
    this.profileName = item.name;
    this.getUserList();
  }

  setWidth(level: number, type: string) {
    if (type == 'delta') {
      let numRecords: number = 0;
      numRecords = this.userData.numRecords == this.GetCppProfileDashboardDataForAdmin?.usersListWithDetails.length ? this.userData.numRecords : this.GetCppProfileDashboardDataForAdmin?.usersListWithDetails.length as number;
      return (100 / numRecords * level) + '%';
    } else {
      return level * 20 + '%';
    }
  }


  userPageChanged(page: number) {
    this.userData.page = page;
    this.userData.list = [];
    this.userData.fromRecord = (this.userData.page - 1) * this.userData.numRecords;
    this.getUserList();
  }

  changeUserNumRecords(item: any) {
    this.selectedRows = item;
    this.userData.numRecords = item.id;
    this.userData.list = [];
    this.userData.fromRecord = 0;
    this.getUserList();
  }

  getUserList() {
    this.adminService.getCPPProfileDashboardDataForAdmin(this.roundId, this.profileId as string, this.userData.fromRecord, this.userData.numRecords, this.selectedFilters).subscribe((data: any) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "user001",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        this.GetCppProfileDashboardDataForAdmin = data.response;
        this.userData.counter = data.response.usersCount;
        this.userData.fromRecord = (this.userData.page - 1) * this.userData.numRecords;
      }
    }, (err?: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "user002",
        text: this.translate.instant("errors." + err?.message),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    })
  }

  showCompentences() {
    this.showAllCompentences = !this.showAllCompentences;
    if (!this.showAllCompentences) {
      setTimeout(() => {
        this.viewportScroller.scrollToAnchor('chart-competence');
      }, 0);
    }
  }

  openRoleAlignmentModal() {
    this.modalService.open('role-alignment-modal');
  }


  getCPPProfileList() {
    this.adminService.getCPPProfileListForAdmin(this.roundId, this.selectedFilters).subscribe((data: any) => {
      if (data && data.error) {
        const messageObj: ApplicationModalMessage = {
          modalId: "list001",
          text: this.translate.instant("errors." + data.error),
          title: this.translate.instant("generic.WARNING")
        }
        this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
      } else {
        data.response = data.response.map((el: any) => {
          el.name = el.profileName;
          el.id = el.profileId;
          return el;
        })
        this.rolesList = data.response;
        this.userData.counter = data.response?.counter;
      }
    }, (err?: any) => {
      const messageObj: ApplicationModalMessage = {
        modalId: "list002",
        text: this.translate.instant("errors." + err?.message),
        title: this.translate.instant("generic.WARNING")
      }
      this.store.dispatch(CoreActions.SetApplicationModalMessage({ payload: messageObj }));
    })
  }

  closeRoleAlignmentModal() {
    this.modalService.close('role-alignment-modal');
  }


}

type Tab = { id: string; title: string; isDisabled: boolean; };
