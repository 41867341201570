<pagination-template #p="paginationApi" [id]="id" (pageChange)="onPageChanged($event)"
  class="table-pagination-container">
  <div class="pagination" [ngClass]="{'small-orange': smallOrangeLayout, 'no-padding': noPadding}"
    fxLayout="center center">
    <a [class.disabled]="p.isFirstPage() || forceDisabled" class="arrow" (click)="p.previous()">
      <ul>
        <li>
          < </li>
      </ul>
    </a>
    <a tabindex="0" attr.aria-label="{{'generic.GO_TO_PAGE' | translate}} {{page.label}}" role="button"
      *ngFor="let page of p.pages" [ngClass]="{'a-lot-of-results': aLotOfResults}"
      [class.current]="p.getCurrent() === page.value" [class.disabled]="forceDisabled">
      <ul fxLayoutAlign="center center">
        <li (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</li>
        <li *ngIf="p.getCurrent() === page.value">{{ page.label }}</li>
      </ul>
    </a>
    <a [class.disabled]="p.isLastPage() || forceDisabled" class="arrow" (click)="p.next()">
      <ul>
        <li>></li>
      </ul>
    </a>
  </div>
</pagination-template>