import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tag-competence',
  templateUrl: 'tag-competence.component.html',
  styleUrls: ['./tag-competence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagCompetenceComponent implements OnInit {
  @Input() title?: string = 'Titolo tag';
  @Input() isEditable: boolean = true;
  @Input() leftIcon?: string;
  @Input() isComplete: boolean = false;
  @Input() isChallengeCreated: boolean = false;
  @Output() menuClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOptionClicked: EventEmitter<any> = new EventEmitter<any>();

  isMenuOpen: boolean = false;
  menuObject: any = [];

  @ViewChild("tagMenu") tagMenu?: ElementRef;

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (this.isMenuOpen && this.tagMenu) {
      if (!this.tagMenu.nativeElement.contains(event.target)) {
        this.isMenuOpen = false;
      }
    } 1
  }

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (!this.isEditable) {
      this.menuObject = [
        {
          id: 'detail',
          name: this.translate.instant('tagActions.DETAILS'),
          icon: '/assets/img/icons/clipboard.svg'
        }
      ];
    } else {
      this.menuObject = [
        {
          id: 'move',
          name: this.translate.instant('tagActions.MOVE'),
          icon: '/assets/img/icons/move.svg'
        },
      ]

      if (!this.isChallengeCreated) {
        this.menuObject.push(
          {
            id: 'challenge',
            name: this.translate.instant('tagActions.SET_CHALLENGE'),
            icon: '/assets/img/icons/award.svg'
          }
        )
      }

      this.menuObject.push(
        {
          id: 'detail',
          name: this.translate.instant('tagActions.DETAILS'),
          icon: '/assets/img/icons/clipboard.svg'
        }
      )
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  emitOnOptionClicked(item: any) {
    this.isMenuOpen = false;
    this.cdr.detectChanges();
    // this.toggleMenu();
    this.onOptionClicked.emit(item);
  }
}
