<div class="page-content-wrapper">
  <!-- Se ho finito di caricare i dati dell'utente -->
  <header-dossier [isAdminHeader]="true" [adminPageTitle]="('structureAnag.PAGE_TITLE' | translate)"
    [adminPageSubtitle]="processYear ? (('structureAnag.PAGE_SUBTITLE' | translate) + processYear) : ('structureAnag.PAGE_SUBTITLE' | translate)"
    [adminBack]="true"></header-dossier>
  <div class="main-content-wrapper admin">
    <!-- Home processi -->
    <ng-container *ngIf="!processYear">
      <!-- contenitore tabella -->
      <ng-container *ngIf="!isLoadingStructureData">
        <div class="admin-table-container">
          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="{{ ('structureAnag.NAME' | translate)}}"></th>
                  <th translate="{{ ('structureAnag.PEOPLE' | translate)}}"></th>
                  <th translate="{{ ('structureAnag.ACTIONS' | translate)}}"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let structure of structureData.list | 
                            slice:0:structureData.numRecords | 
                            paginate: { id: 'structureList',
                                    itemsPerPage: structureData.numRecords,
                                    currentPage: structureData.page,
                                    totalItems: structureData.counter }">
                  <td class="uppercase">{{ structure.title}}</td>
                  <td>
                    <div class="count">
                      {{ structure.usersInGroupCount || 0 }}
                    </div>
                  </td>
                  <td class="link" (click)="goToStructureDetail(structure.year)">{{
                    ('structureAnag.MANAGE' | translate)}}</td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="bottom-actions">
            <table-pagination id="structureList" (pageChanged)="structurePageChanged($event)" [noPadding]="true">
            </table-pagination>
            <div class="per-page-selector">
              <p class="select-label" translate="generic.PER_PAGE_ROW"></p>
              <alloy-select [inMinWidthForIe]="true" (onSelectedItem)="changeNumRecords($event)"
                [selectedItem]="selectedRows" [items]="rowPerPageOptions" [searchable]="false" bindLabel="id"
                [tablePagination]="true">
              </alloy-select>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- loader -->
      <ng-container *ngIf="isLoadingStructureData">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>
    <!-- dettaglio struttura del processo -->
    <ng-container *ngIf="processYear">
      <ng-container *ngIf="!isLoadingStructureDetails">
        <div class="admin-table-container">
          <div class="structure-recap">
            <p class="structure-title">{{ ('structureAnag.details.TITLE' | translate) + ' Performance
              2021'}}</p>
            <div class="data-box-container">
              <!-- Persone attualmente nella lista -->
              <div class="data-box">
                <p class="number">350</p>
                <p class="description" translate="structureAnag.details.PRESENT_IN_STRUCTURE"></p>
                <div class="download-section" (click)="downloadList()">
                  <svg-icon src="assets/img/icons/file/xls.svg"></svg-icon>
                  <p translate="structureAnag.details.DOWNLOAD_LIST"></p>
                </div>
              </div>
              <!-- Persone aggiunte manualmente -->
              <div class="data-box">
                <p class="number">350</p>
                <p class="description" translate="structureAnag.details.MANUALLY_MOVED"></p>
                <div class="download-section" (click)="insertList()">
                  <svg-icon src="assets/img/icons/file/xls.svg"></svg-icon>
                  <p translate="structureAnag.details.INSERT_PEOPLE"></p>
                </div>
              </div>
            </div>

            <div class="search-container">
              <div class="search-wrapper">
                <alloy-input (onIconClicked)="onSearch()" typeInput="text" [bindValue]="searchedText"
                  (onModelChanged)="searchedTextChange($event)" iconRight="assets/img/icons/search.svg"
                  [placeholder]="'structureAnag.details.SEARCH_BY' | translate" [isSearchBar]="true">
                </alloy-input>
                <p class="filter-recap" translate="structureAnag.details.FILTER_BY"></p>
              </div>
            </div>
          </div>

          <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th translate="adminTable.NAME"></th>
                  <th translate="adminTable.CID"></th>
                  <th translate="adminTable.IN_STRUCTURE"></th>
                  <th translate="adminTable.INSERT_TYPE"></th>
                  <th translate="adminTable.DEFINITION"></th>
                  <th translate="adminTable.ACTIONS"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let structure of structureDetail">
                  <td>{{ structure.name }}</td>
                  <td>1235</td>
                  <td>10/03/2019</td>
                  <td>Manuale</td>
                  <td>Gestione credito</td>
                  <td class="link">
                    <svg-icon src="assets/img/icons/more-horizontal.svg"></svg-icon>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </ng-container>

      <!-- loader dettaglio -->
      <ng-container *ngIf="isLoadingStructureDetails">
        <div class="loader-container">
          <div class="loader spinner-big"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>