import { Component, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PerfAlloyPeerFeedback } from 'atfcore-commonclasses';
import * as moment from 'moment';

@Component({
  selector: 'card-comments',
  templateUrl: './card-comments.component.html',
  styleUrls: ['./card-comments.component.scss']
})
export class CardCommentsComponent implements OnInit {
  @Input() object!: any;
  @Input() loggedUser?: any;
  @Input() isPeopleAppraisal?: boolean;
  @Input() isReceived?: boolean;
  @Input() isHistory: boolean = false;
  @Input() userIdSelected: string = '';
  @Input() updateSideNav: boolean = false;
  @Input() disabled: boolean = false;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onFeedbackSend: EventEmitter<any> = new EventEmitter();
  @Output() onShowRequest: EventEmitter<any> = new EventEmitter();
  @Output() onMenuClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(public translate: TranslateService,
  ) {
  }
  creationDateText: string = '';
  commentDateText: string = '';
  items: Array<{ title: string, text?: string }> = [];
  type: 'completed' | 'inProgress' | 'pending' | 'toStart' | 'disabled' | '' = '';
  isPosted: boolean = false;
  showMore: boolean = false;
  label: string = '';
  requestTitle: string = '';
  responseTitle: string = '';
  requestIcon: string = '';
  responseIcon: string = '';

  objectMap: any;


  ngOnInit() {
    if (this.object) {
      this.objectMap = {
        date: this.object.creationDate,
        comment: this.object.description,
        creationUser: this.object?.creationUser
      }
    }
  }
  

  emitOnFeedbackSend(id: any) {
    this.onFeedbackSend.emit(id);
  }

  showMoreText() {
    this.showMore = true;
  }

  emitOnShowRequest() {
    this.onShowRequest.emit(this.object);
  }
  emitOnOptionClicked(item: any) {
    this.onMenuClicked.emit(item);
  }
}
