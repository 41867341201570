import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { DateUtil, readBooleanInput } from '../../../commonclasses';
import { PickerType, PickerMode } from '@busacca/ng-pick-datetime/lib/date-time/date-time.class';

@Component({
  selector: 'alloy-datepicker',
  templateUrl: './alloy-datepicker.component.html',
  styleUrls: ['./alloy-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EuropDatepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EuropDatepickerComponent), multi: true }
  ]
})
export class EuropDatepickerComponent implements OnInit {
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() reviewMode: boolean = false;
  @Input() options: any;
  @Input() placeholder: string = '';
  @Input() pickerType: PickerType = 'calendar';
  @Input() pickerMode: PickerMode = 'popup';
  @Input() disabled: boolean = false;
  @Input() isMin: boolean = false;
  @Input() maxDate: any;
  @Input() minDate: any;
  @Input() utcMode: boolean = false;

  @Output() dateChanged = new EventEmitter();

  @ViewChild('datePickerInput') datePickerInput: any;
  @ViewChild('dt2') owlDateTimeComp: any;

  newDate: any;
  @Input() value: Date | undefined = undefined;

  constructor(private __renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.utcMode = this.utcMode === undefined ? true : readBooleanInput(this.utcMode);

    this.pickerType = this.pickerType || 'calendar';
    this.pickerMode = this.pickerMode || 'popup';

    this.options = Object.assign({
      firstDayOfWeek: 1,
      bigBanner: true,
      timePicker: false,
      format: 'dd-MM-yy',
      defaultOpen: true
    }, this.options || {});

    this.value = this.value || undefined;
    this.placeholder = this.placeholder || '';
    this.reviewMode = readBooleanInput(this.reviewMode);

    if (this.isMin) {
      this.newDate = new Date();
      this.newDate.setDate(this.newDate.getDate() - 1);
    }
  }

  updateModel(componentRef: this, $event: any): void {
    this.value = this.utcMode ? DateUtil.convertModelToUTC($event && $event.value) : $event && $event.value;
    //     this.valueChange.emit(this.value);
    this.dateChanged.emit(this.value)
  }

  updateModelFromInputvalue(componentRef: any): void {
    let inputValue: string = componentRef && componentRef.value;
    let newValue: any;
    if (!!inputValue
      && (
        /^([1-3][0-9]|[1-9])(\-|\/| )([1-3][0-9]|[1-9])(\-|\/| )([0-9][0-9][0-9][0-9])$/.test(inputValue)
        || /^([1-3][0-9]|[1-9])(\-|\/| )([1-3][0-9]|[1-9])(\-|\/| )([0-9][0-9][0-9][0-9])(, )([0-1][0-9]|2[0-3]):[0-5][0-9]$/.test(inputValue)
      )) {
      // questo componente quando raccoglie il date e time insieme separe con la virgola
      let tks = inputValue.split(",");
      let _date = tks && tks.length ? moment(tks[0], 'L', this.owlDateTimeComp.dateTimeAdapter.locale).toISOString() : null;
      if (_date && tks && tks.length > 1) {
        let _time = moment(tks[1], 'LT', this.owlDateTimeComp.dateTimeAdapter.locale).toISOString();
        newValue = DateUtil.calculateDateTimeByDay(_date, _time);
      } else {
        newValue = _date;
      }
      this.value = newValue;
    }
  }

  cleanOpenedStyle() {
    if (this.__renderer)
      this.__renderer.removeAttribute(this.datePickerInput.nativeElement, 'aria-owns');
  }

}
