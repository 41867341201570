<div *ngIf="userObject" class="item-list-container">
  <div style="width: 25%" class="data-user">
    <avatar-img [user]="userObject.user" size="medium"></avatar-img>
    <div class="user-info">
      <p class="user-info-text">{{userObject.user.forename}} {{userObject.user.surname}}</p>
      <a class="detail-text" translate="goalSetting.DETAIL" (click)="emitOnUserDetailClicked()"></a>
    </div>
  </div>
  <div class="horizontal-line"></div>
  <!-- stati fasi desktop -->
  <ng-container *ngIf="!compactView">
    <item-list-team-status (click)="emitOpenModalInfo('goalSetting')" style="width: 15%" [styleItem]="'border'"
      [type]="userObject.goalSettingStatus">
    </item-list-team-status>
    <div class="horizontal-line"></div>
    <item-list-team-status (click)="emitOpenModalInfo('developmentPlan')" style="width: 15%" [styleItem]="'border'"
      [type]="userObject.developmentPlanStatus">
    </item-list-team-status>
    <div class="horizontal-line"></div>
    <item-list-team-status (click)="emitOpenModalInfo('midTerm')" style="width: 15%" [styleItem]="'border'"
      [type]="userObject.midTermReviewStatus">
    </item-list-team-status>
    <div class="horizontal-line"></div>
    <item-list-team-status (click)="emitOpenModalInfo('final')" style="width: 15%" [styleItem]="'border'"
      [type]="userObject.finalEvaluationStatus">
    </item-list-team-status>
    <div class="horizontal-line"></div>
  </ng-container>
  <!-- Stati in versione compatta -->
  <ng-container *ngIf="compactView">
    <div class="square-status" [ngClass]="userObject.goalSettingStatus" (click)="emitOpenModalInfo('goalSetting')">
    </div>
    <div class="horizontal-line"></div>
    <div class="square-status" [ngClass]="userObject.developmentPlanStatus"
      (click)="emitOpenModalInfo('developmentPlan')">
    </div>
    <div class="horizontal-line"></div>
    <div class="square-status" [ngClass]="userObject.midTermReviewStatus" (click)="emitOpenModalInfo('midTerm')">
    </div>
    <div class="horizontal-line"></div>
    <div class="square-status" [ngClass]="userObject.finalEvaluationStatus" (click)="emitOpenModalInfo('final')">
    </div>
    <div class="horizontal-line"></div>
  </ng-container>
  <a class="feedback-number" style="width: 10%" (click)="emitOnFeedbackClicked()">{{
    userObject.feedback.totalFeedbackCount}}</a>

</div>